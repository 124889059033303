// https://gist.github.com/srvice-temp/046126458bbed2239e0b8d6403bced18
export enum LocalCacheKey {
  EXPIRY = 'Expiry',
  REFRESH_TOKEN = 'RefreshToken',
  ACCESS_TOKEN = 'AccessToken',
}

interface LocalCacheValues {
  [LocalCacheKey.REFRESH_TOKEN]: string;
  [LocalCacheKey.EXPIRY]: number;
  [LocalCacheKey.ACCESS_TOKEN]: string;
}

interface LocalCacheUtil {
  set<T extends LocalCacheKey>(key: T, value: LocalCacheValues[T]): void;
  get<T extends LocalCacheKey>(key: T): LocalCacheValues[T] | null;
  remove(key: LocalCacheKey): void;
  removeAll(): void;
}

const cache = new Map<LocalCacheKey, any>();

export const localCache: LocalCacheUtil = {
  set<T extends LocalCacheKey>(key: T, value: LocalCacheValues[T]): void {
    localStorage.setItem(key, JSON.stringify(value));
    cache.set(key, value);
  },
  get<T extends LocalCacheKey>(key: T): LocalCacheValues[T] | null {
    const cachedValue = cache.get(key);
    if (cachedValue !== undefined) {
      return cachedValue;
    }

    const item = localStorage.getItem(key);
    if (item === null) return null;

    const parsedItem: LocalCacheValues[T] = JSON.parse(item);
    cache.set(key, parsedItem);
    return parsedItem;
  },
  remove(key: LocalCacheKey): void {
    localStorage.removeItem(key);
    cache.delete(key);
  },
  removeAll(): void {
    localStorage.clear();
    cache.clear();
  },
};
