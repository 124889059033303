import { create } from 'zustand';
import { TPaymentProduct } from 'components/dashboard/payment/queries';

interface UpgradeWarningModalStoreState {
  isUpgradeModalOpen: boolean;
  requiredPlan: TPaymentProduct['type'] | null;
  featureDescription: string;
  showUpgradeModal: (plan: TPaymentProduct['type'], description: string) => void;
  closeUpgradeModal: () => void;
}

export const useUpgradeWarningModalStore = create<UpgradeWarningModalStoreState>((set) => ({
  isUpgradeModalOpen: false,
  requiredPlan: null,
  featureDescription: '',
  showUpgradeModal: (plan: TPaymentProduct['type'], description: string) =>
    set({ isUpgradeModalOpen: true, requiredPlan: plan, featureDescription: description }),
  closeUpgradeModal: () =>
    set({ isUpgradeModalOpen: false, requiredPlan: null, featureDescription: '' }),
}));
