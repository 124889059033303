import { Button, ButtonProps } from 'components/ui/button';
import { fabric } from 'fabric';
import { FC } from 'react';

interface AddTextProps {
  canvasInstance: fabric.Canvas | null;
}
export const AddText: FC<AddTextProps & ButtonProps> = ({ canvasInstance, ...props }) => {
  const addTextOnCanvas = () => {
    if (!canvasInstance) return;

    const newText = new fabric.Textbox('Your Text', {
      left: 50,
      top: 50,
      fill: '#000000',
      width: 200,
    });
    canvasInstance.add(newText);
    canvasInstance.setActiveObject(newText);
    newText.enterEditing();
    newText.selectAll();
  };

  return (
    <Button
      variant='outline'
      className='flex flex-1 gap-1.5 rounded-lg bg-slate-50 px-2.5 text-xs'
      {...props}
      onClick={addTextOnCanvas}
    >
      <span className='i-mdi-pencil-outline size-4'></span>
      <span>Add Text</span>
    </Button>
  );
};
