import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { Slider } from 'components/ui/slider';
import { useEffect } from 'react';

export const ImageNumber = ({ isDisabled }: { isDisabled: boolean }) => {
  const updateCount = useAiSceneCreateStore((state) => state.updateAiSceneCount);
  const aiSceneCount = useAiSceneCreateStore((state) => state.aiScenePromptTab.count);

  useEffect(() => {
    if (isDisabled) {
      updateCount(1);
    }
  }, [isDisabled, updateCount]);

  return (
    <div className='flex cursor-pointer items-center justify-between'>
      <span className='text-main font-semibold'>Image Number</span>
      <Slider
        className={`w-[50%] bg-crait-green-primary ${isDisabled && 'opacity-50'}`}
        disabled={isDisabled}
        max={4}
        min={1}
        step={1}
        value={[aiSceneCount]}
        defaultValue={[aiSceneCount]}
        onValueChange={(value) => updateCount(value[0])}
      />
    </div>
  );
};
