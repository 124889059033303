import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { useGetSearchResults } from 'components/ai-scene/sidebar/settings-tab/queries';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { TScene } from 'components/ai-scene/review/queries';
import { SceneAssetCard } from './SceneAssetCard';

export const SceneAssets = () => {
  const [keyword, setKeyword] = useDebounceValue('', 300);
  const { data, isError, isLoading, refetch } = useGetSearchResults<TScene>({
    keyword,
    pageId: 0,
    searchSection: 'ai_scenes',
    staleTime: 30 * 1000,
  });
  const assets = useBannerCreateStore((s) => s.assets);
  const existingScenes = useMemo(() => {
    return assets.filter((asset) => asset.type === 'aiScene');
  }, [assets]);

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load scenes.</span>
    </RefetchView>
  );

  const sceneItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.aiScenes?.items || data.aiScenes.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No scenes found.</span>
        </RefetchView>
      );
    }

    return data.aiScenes.items.map((scene) => {
      const isSelected = existingScenes.some((asset) => asset.id === scene.id);
      return <SceneAssetCard key={scene.id} scene={scene} isSelectedByDefault={isSelected} />;
    });
  }, [data, isLoading, refetch, existingScenes]);

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{existingScenes.length} Selected</span>
        <Command className='flex h-10 w-60 justify-center rounded-lg  border border-[#6b8084bb] bg-transparent'>
          <CommandInput
            placeholder='Search'
            onValueChange={(value) => {
              setKeyword(value);
            }}
          />
        </Command>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='grid grid-cols-3 gap-4 p-4'>{errorView || sceneItems}</div>
      </ScrollArea>
    </div>
  );
};
