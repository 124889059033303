import { useAiSceneBulkCreateStore } from '@/providers/ai-scene/bulk-create/hooks';
import { useEffect } from 'react';
import { parseImageWithFabric } from '../canvas/helper';
import { getScaledOptionsForChildCanvas } from './helper';

interface SyncExternalImageProps {
  mainCanvasInstance: fabric.Canvas | undefined;
  canvasInstances: fabric.Canvas[];
}
export const useSyncExternalImage = ({
  mainCanvasInstance,
  canvasInstances,
}: SyncExternalImageProps) => {
  const additionalImage = useAiSceneBulkCreateStore((state) => state.additionalImage);
  const removeAdditionalImage = useAiSceneBulkCreateStore((state) => state.removeAdditionalImage);

  useEffect(() => {
    const mainCanvas = mainCanvasInstance;
    const canvases = canvasInstances;
    if (!mainCanvas || !canvases || canvases.length < 1 || !additionalImage) return;

    const parseImagesOnCanvasInstances = async () => {
      try {
        const parsedImage = await parseImageWithFabric({
          ...additionalImage,
          maxHeight: mainCanvas.getHeight(),
          maxWidth: mainCanvas.getWidth(),
          placementBuffer: 0,
        });
        if (parsedImage.type === 'template') {
          const existingTemplate = mainCanvas
            .getObjects()
            .find((obj) => obj.imageType === 'template');
          if (existingTemplate) {
            mainCanvas.remove(existingTemplate);
          }
          mainCanvas.insertAt(parsedImage.image, 0, false);
          parsedImage.image.set({
            evented: false,
            selectable: false,
            isSelectionIgnored: true,
          });
        } else {
          parsedImage.image.set({
            evented: true,
            selectable: true,
          });
          mainCanvas.add(parsedImage.image);
        }
        mainCanvas.requestRenderAll();

        const adjustedImageOptions = getScaledOptionsForChildCanvas({
          left: parsedImage.image.left,
          top: parsedImage.image.top,
          scaleX: parsedImage.image.scaleX,
          scaleY: parsedImage.image.scaleY,
          angle: parsedImage.image.angle,
        });

        const clonedImage = await new Promise<fabric.Image>((resolve) => {
          parsedImage.image.clone((cloned: fabric.Image) => {
            cloned
              .set({
                evented: false,
                selectable: false,
                isSelectionIgnored: true,
                imageId: parsedImage.id,
                imageType: parsedImage.type,
                ...adjustedImageOptions,
              })
              .setCoords();
            resolve(cloned);
          });
        });

        canvases.forEach((canvasInstance) => {
          if (parsedImage.type === 'template') {
            const existingTemplate = canvasInstance
              .getObjects()
              .find((obj) => obj.imageType === 'template');
            if (existingTemplate) {
              canvasInstance.remove(existingTemplate);
            }
            canvasInstance.insertAt(clonedImage, 0, false);
          } else {
            canvasInstance.add(clonedImage);
          }
          canvasInstance.requestRenderAll();
        });
      } catch (error) {
        console.error(error);
      }
    };

    parseImagesOnCanvasInstances();
    removeAdditionalImage(additionalImage.id);
  }, [canvasInstances, additionalImage, mainCanvasInstance, removeAdditionalImage]);
};
