import { useSearchParams } from 'react-router-dom';
import { TAccordionSection } from '../sidebar/helpers';
import { AssetsView } from './assets/AssetsView';
import { TemplateView } from './template/TemplateView';
import { BrandKitView } from './brand-kit/BrandKitView';

export const SelectedView = () => {
  const [searchParams] = useSearchParams();
  const currentSection = searchParams.get('section') as TAccordionSection | null;

  if (!currentSection) {
    return (
      <div className='flex h-full w-full items-center justify-center text-lg'>
        No banner created yet.
      </div>
    );
  }

  switch (currentSection) {
    case 'dimensions':
    case 'style':
    case 'objective':
    case 'copy-text':
    case 'assets': {
      return <AssetsView />;
    }
    case 'template': {
      return <TemplateView />;
    }
    case 'brandkit': {
      return <BrandKitView />;
    }
    default:
      return (
        <div className='flex h-full w-full items-center justify-center text-lg'>
          Section not found.
        </div>
      );
  }
};
