import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import { useFavoriteSceneMutation } from 'components/ai-scene/review/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ImageModal } from 'components/common/ImageModal';
import { TooltipItem } from 'components/common/TooltipItem';

const STAR_WRAPPER_CL =
  'absolute right-3 top-3 rounded-full p-1 block  flex items-center justify-center cursor-pointer inline-flex cursor-pointer items-center rounded-full p-2 bg-black bg-opacity-50 rounded-full text-white';
const STAR_ICON_CL = 'size-4 select-none';

interface IHistoryCardProps {
  id: string;
  projectId: string;
  title: string;
  image: string;
  previewImage: string;
  isStarred: boolean;
  createdDate: string;
  children: ReactNode;
}

export const HistoryCard: FC<IHistoryCardProps> = ({
  id,
  projectId,
  title,
  image,
  previewImage,
  isStarred,
  createdDate,
  children,
}) => {
  const [isFavorite, setIsFavorite] = useState(isStarred);
  const favoriteScene = useFavoriteSceneMutation();
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    setIsFavorite(isStarred);
  }, [isStarred]);

  const starIcon = isFavorite ? (
    <span className={`i-mdi-bookmark size-5 ${STAR_ICON_CL} text-[#E16236]`}></span>
  ) : (
    <span className={`i-mdi-bookmark-outline size-5 ${STAR_ICON_CL}`}></span>
  );

  const addSceneToFavorites = async () => {
    const nextIsFavorite = !isFavorite;
    try {
      setIsFavorite(nextIsFavorite);
      if (favoriteScene.isPending) return;
      const favoriteAction = nextIsFavorite ? 'add' : 'remove';
      await favoriteScene.mutateAsync({ projectId, sceneId: id, action: favoriteAction });
      queryClient.invalidateQueries({
        exact: false,
        queryKey: [{ searchSection: 'ai_scenes' }],
      });

      const message = nextIsFavorite ? 'Scene is saved' : 'Scene is unsaved';
      toast.success(message);
    } catch (error) {
      setIsFavorite(isFavorite);
      toast.error('Failed to add scene to favorites.');
    }
  };

  return (
    <div className='h-[312px] w-[260px] overflow-hidden rounded-md md:h-[223px] md:w-[160px] '>
      <ImageModal
        header={
          <h4 className='flex justify-center gap-2'>
            <span>{title}</span>
          </h4>
        }
        content={<img crossOrigin='anonymous' src={image} />}
      >
        <div className='relative h-[260px] md:h-[170px] '>
          <TooltipItem
            side='bottom'
            className='focus-visible:ring-0'
            trigger={
              <div
                className={STAR_WRAPPER_CL}
                onClick={(e) => {
                  e.stopPropagation();
                  addSceneToFavorites();
                }}
              >
                {starIcon}
              </div>
            }
          >
            Save
          </TooltipItem>
          <img
            crossOrigin='anonymous'
            src={previewImage}
            className='h-full w-full cursor-zoom-in rounded-lg object-cover'
          />
        </div>
      </ImageModal>
      <div className='flex items-center justify-between px-0 py-3'>
        <div className='flex max-w-[210px] flex-col md:max-w-[85%]'>
          <div className='line-clamp-2  truncate text-main font-semibold md:text-[11px]'>
            {title}
          </div>
          <div className='text-[12px] font-normal text-[#777777] md:text-[11px]'>
            Created at {createdDate}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
