import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { useBrandSectionQueryParams } from 'components/ai-banner/create/assets/useBrandSectionQueryParams';
import { Button } from 'components/ui/button';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';

export const BrandKitContent = () => {
  const { setSection } = useBrandSectionQueryParams();
  const brandKitLogos = useBannerCreateStore((s) => s.brandKitLogos);
  const removeBrandKitLogo = useBannerCreateStore((s) => s.removeBrandKitLogo);
  const brandKitFonts = useBannerCreateStore((s) => s.brandKitFonts);
  const removeBrandKitFont = useBannerCreateStore((s) => s.removeBrandKitFont);
  const brandKitColors = useBannerCreateStore((s) => s.brandKitColors);
  const removeBrandKitColor = useBannerCreateStore((s) => s.removeBrandKitColor);

  return (
    <div>
      <Button
        className='w-full bg-crait-green-400'
        onClick={() => {
          setSection('brandkit');
        }}
      >
        Select from BrandKit Library
      </Button>
      {brandKitLogos.length > 0 && (
        <div className='pt-4'>
          <p className='text-sm'>Logos</p>
          <ScrollArea className='w-[330px] pb-4 pt-2'>
            <div className='flex gap-4'>
              {brandKitLogos.map((logo) => (
                <div
                  key={logo.id}
                  className='relative flex w-24 flex-col gap-2 overflow-hidden rounded-md'
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      removeBrandKitLogo(logo.id);
                    }}
                    className='absolute right-0.5 top-0.5 z-10 flex size-7 cursor-pointer items-center justify-center rounded-full bg-crait-dark-200'
                  >
                    <span className='i-mdi-close size-4 bg-black' />
                  </div>
                  <img crossOrigin='anonymous' src={logo.url} className='size-24 object-cover' />
                  <p className='line-clamp-1 text-ellipsis text-xs'>{logo.name}</p>
                </div>
              ))}
            </div>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        </div>
      )}
      {brandKitFonts.length > 0 && (
        <div className='pt-3'>
          <p className='text-sm'>Fonts</p>
          <ScrollArea className='w-[330px] pb-4 pt-2'>
            <div className='flex flex-wrap justify-between gap-2'>
              {brandKitFonts.map((item) => (
                <div key={item.id} className='flex w-[48%] items-center justify-between'>
                  <div className='flex w-[calc(100%-16px)] overflow-hidden'>
                    <span className='line-clamp-1 text-ellipsis'>
                      {item.name} {item.name} {item.name}
                    </span>
                  </div>
                  <div
                    className='flex cursor-pointer items-center p-1.5'
                    onClick={() => removeBrandKitFont(item.id)}
                  >
                    <span className='i-mdi-close size-4  text-red-500 hover:text-red-700' />
                  </div>
                </div>
              ))}
            </div>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        </div>
      )}
      {brandKitColors.length > 0 && (
        <div className='pt-4'>
          <p className='text-sm'>Colors</p>
          <ScrollArea className='w-[330px] pb-4 pt-2'>
            <div className='flex gap-4'>
              {brandKitColors.map((color) => (
                <div
                  key={color.id}
                  className='relative flex w-14 flex-col gap-2 overflow-hidden rounded-md'
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      removeBrandKitColor(color.id);
                    }}
                    className='absolute right-0 top-0 z-10 flex size-5 cursor-pointer items-center justify-center rounded-full bg-crait-dark-200'
                  >
                    <span className='i-mdi-close size-4 bg-black' />
                  </div>
                  <span style={{ backgroundColor: color.hex }} className='size-14 rounded-full' />
                  <p className='line-clamp-1 text-ellipsis text-center text-xs'>{color.hex}</p>
                </div>
              ))}
            </div>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        </div>
      )}
    </div>
  );
};
