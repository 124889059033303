import { TPaymentProduct } from 'components/dashboard/payment/queries';

export const PlanTypes = {
  FREE: { type: 'free', rank: 1 },
  STARTER: { type: 'starter', rank: 2 },
  PROFESSIONAL: { type: 'professional', rank: 3 },
  TEAM: { type: 'team', rank: 4 },
  ENTERPRISE: { type: 'enterprise', rank: 5 },
} as const;

export type PlanType = TPaymentProduct['type'];

export const PLAN_REQUIREMENTS = [
  { maxAllowedDimension: 1024, planType: PlanTypes.FREE.type },
  { maxAllowedDimension: 2048, planType: PlanTypes.PROFESSIONAL.type },
  { maxAllowedDimension: Infinity, planType: PlanTypes.TEAM.type },
] as const;

export const FeatureAccessRequirements = {
  PRODUCT_PAGE: {
    requiredPlan: PlanTypes.FREE.type,
    featureDescription: 'Access to basic features.',
  },
  BANNER_PAGE: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Upload as many as banners you like ready to use in your designs.',
  },
  INSPIRATION_PAGE: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Upload as many as inspirations you like ready to use in your designs.',
  },
  TEMPLATES_PAGE: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Upload as many as templates you like ready to use in your designs.',
  },
  SAVE_AS_INSPIRATION: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Save created scenes as inspiration.',
  },
  SAVE_AS_TEMPLATES: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Save created scenes as templates.',
  },
  EDITOR_RESIZE_BUTTON: {
    requiredPlan: PlanTypes.STARTER.type,
    featureDescription: 'Choose various layout sizes.',
  },
  EDITOR_RESIZE_INPUT_ABOVE_1024: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Choose larger layout sizes',
  },
  EDITOR_RESIZE_INPUT_ABOVE_2048: {
    requiredPlan: PlanTypes.TEAM.type,
    featureDescription: 'Choose larger layout sizes',
  },
  REVIEW_EDIT_MULTIRESIZE_BUTTON: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Expand your image to various sizes.',
  },
  REVIEW_EDIT_ENHANCE_QUALITY_BUTTONS: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Enhance your image with AI tools.',
  },
  REVIEW_EDIT_REPAIR_BUTTONS: {
    requiredPlan: PlanTypes.ENTERPRISE.type,
    featureDescription: 'Repair your image with AI tools.',
  },
  REVIEW_EDIT_OTHER_BUTTONS: {
    requiredPlan: PlanTypes.STARTER.type,
    featureDescription: 'Edit and enhance your image with AI tools.',
  },
  ADD_INSPIRATION_IMAGE: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Upload inspirations image.',
  },
  MAGIC_ERASE_PRO: {
    requiredPlan: PlanTypes.PROFESSIONAL.type,
    featureDescription: 'Experience enhanced deletion capabilities.',
  },
  INVITE_MEMBERS: {
    requiredPlan: PlanTypes.TEAM.type,
    featureDescription: 'Invite more members to your organization.',
  },
  ADD_PRODUCTS: {
    requiredPlan: PlanTypes.FREE.type,
    featureDescription: 'Upload more products.',
  },
  FREE: {
    requiredPlan: PlanTypes.FREE.type,
    featureDescription: '',
  },
} as const;
