import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { useCallback } from 'react';
import { PlanType } from './constants';
import { getPreviousPlanType, isPlanSufficient } from './helper';
import { useUpgradeWarningModalStore } from './useUpgradeWarningModalStore';
import { TPaymentProduct } from 'components/dashboard/payment/queries';

interface UsePlanCheckParams {
  requiredPlan: TPaymentProduct['type'];
  onClick: () => void;
  featureDescription: string;
  usePreviousPlan?: boolean;
}

export const usePlanCheck = ({
  requiredPlan,
  onClick,
  featureDescription,
  usePreviousPlan = false,
}: UsePlanCheckParams) => {
  const { plan: userPlan } = useAccountCtx();
  const { showUpgradeModal } = useUpgradeWarningModalStore();

  //for features that should not be upgraded in the trial period
  const modalRequiresPlan = usePreviousPlan ? getPreviousPlanType(requiredPlan) : requiredPlan;

  const checkPlanAndProceed = useCallback(
    (event: React.MouseEvent) => {
      if (!isPlanSufficient(userPlan, requiredPlan as PlanType)) {
        event.preventDefault();
        event.stopPropagation();
        showUpgradeModal(modalRequiresPlan, featureDescription);
      } else {
        onClick();
      }
    },
    [userPlan, requiredPlan, onClick, showUpgradeModal, featureDescription],
  );

  return checkPlanAndProceed;
};
