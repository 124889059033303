import { Button } from 'components/ui/button';
import { useUserStore } from '@/providers/user/hooks';
import { toast } from 'sonner';
import { Navigate, useNavigate } from 'react-router';
import { RoutePaths } from '@/utils/route/constants';
import { Separator } from 'components/ui/separator';
import { Input } from 'components/ui/input';
import craitImage from '@/assets/images/auth-page.png';
import Logo from 'assets/icons/coming_soon.svg?react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/form';
import { useGoogleLoginRedirect, useLogin } from './queries';
import { AnimatedCraitImage } from './AnimatedCraitImage';
import { AxiosError } from 'axios';

import GoogleIcon from 'assets/icons/google.svg?react';
import { CraitButton } from 'components/common/CraitButton';
import { AuthOverlayContent } from './AuthOverlayContent';

const loginSchema = z.object({
  username: z.string().min(3, 'Username or email is required.'),
  password: z
    .string()
    .min(1, 'Password is required.')
    .refine((val) => val === '' || val.length >= 6, {
      message: 'Password must be at least 6 characters.',
    }),
});

export const LoginPage = () => {
  const login = useLogin();
  const googleLogin = useGoogleLoginRedirect();
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  if (user) return <Navigate replace to={RoutePaths.BASE} />;

  const onLogin = async (values: z.infer<typeof loginSchema>) => {
    login.mutate(
      {
        username: values.username,
        password: values.password,
      },
      {
        onSuccess: () => {
          toast.success('Logged in successfully.');
          navigate(RoutePaths.BASE, { replace: true });
        },
        onError: (error: Error) => {
          console.error(error);
          if (error instanceof AxiosError && error.response?.data?.detail) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('An unexpected error occurred.');
          }
        },
      },
    );
  };

  return (
    <div className='flex size-full items-center justify-center bg-white'>
      <AnimatedCraitImage
        src={craitImage}
        className='absolute bottom-0 left-0 right-0 top-0  hidden h-full w-full object-cover md:block'
      />
      <div className='flex h-full w-full'>
        <div className='relative  flex  h-full  w-full flex-1 items-center justify-center px-8 md:px-4'>
          <div className='w-full max-w-[400px]  bg-white md:rounded-[20px] md:px-3 md:py-10'>
            <div className='mb-9 flex flex-col  items-center gap-8'>
              <Link to={`/${RoutePaths.LOGIN}`}>
                <Logo className='h-8 w-4' cursor='pointer' />
              </Link>
              <h4 className='text-[32px] font-medium'>Welcome Back!</h4>
            </div>
            <Button
              variant='outline'
              className='mb-4 flex w-full gap-2 rounded-lg'
              onClick={() => {
                googleLogin.mutate();
              }}
              disabled={googleLogin.isPending}
            >
              <GoogleIcon />
              <span>Continue with Google</span>
            </Button>
            <div className='mb-4 flex items-center justify-center'>
              <Separator className='w-full bg-gray-200' />
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onLogin)}>
                <div className='font-light: mb-9 flex flex-col gap-2 text-[13px]'>
                  <FormField
                    control={form.control}
                    name='username'
                    render={({ field, fieldState: { error } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder='Enter your email or username'
                            className='rounded-lg bg-white text-[13px] font-light'
                            autoCapitalize='none'
                            {...field}
                          />
                        </FormControl>
                        {error && <FormMessage>{error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='password'
                    render={({ field, fieldState: { error } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder='Enter your password'
                            type='password'
                            className='rounded-lg  bg-white text-[13px] font-light'
                            {...field}
                          />
                        </FormControl>
                        {error && <FormMessage>{error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                  <div className='flex w-full items-center justify-end'>
                    <Button
                      asChild
                      variant='link'
                      className='h-[30px] rounded-lg text-sm font-normal text-gray-500'
                    >
                      <Link to={`/${RoutePaths.PASSWORD_FORGOT}`}>Forgot Password</Link>
                    </Button>
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <CraitButton type='submit' className='rounded-lg' disabled={login.isPending}>
                    Login
                  </CraitButton>

                  <div className='mt-2 p-0 text-main font-normal'>
                    <span>Don’t you have an account?</span>
                    <Link to={`/${RoutePaths.SIGN_UP}`}>
                      <span className='ml-[4px] text-[#E16236] underline'>
                        Sign up to get 20 Free Photos
                      </span>
                    </Link>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
        <div className='relative block h-full w-full flex-1 overflow-hidden md:hidden'>
          <AnimatedCraitImage src={craitImage} className='h-full w-full object-cover ' />
          <AuthOverlayContent />
        </div>
      </div>
    </div>
  );
};
