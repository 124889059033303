import React from 'react';

import UpgradeWarningIcon from '@/assets/icons/upgareWarning.svg?react';
import { Modal } from 'components/ui/modal';
import { PlanCard } from 'components/plan-guard/PlanCard';

import { useUpgradeWarningModalStore } from './useUpgradeWarningModalStore';
import { PlanType } from './constants';

export const UpgradeWarningModal: React.FC = () => {
  const { isUpgradeModalOpen, requiredPlan, closeUpgradeModal, featureDescription } =
    useUpgradeWarningModalStore();

  if (!isUpgradeModalOpen || !requiredPlan) {
    return null;
  }

  return (
    <Modal
      titleElement={
        <div className='flex items-center space-x-2'>
          <UpgradeWarningIcon className='ml-2 inline-block align-middle' />
          <span>Upgrade to use this feature</span>
        </div>
      }
      modalContentClassName='min-h-[500px]'
      open={isUpgradeModalOpen}
      onOpenChange={(open) => {
        if (!open) {
          closeUpgradeModal();
        }
      }}
    >
      {({ closeModal }) => (
        <PlanCard
          closeModal={closeModal}
          requiredPlan={requiredPlan as PlanType}
          featureDescription={featureDescription}
        />
      )}
    </Modal>
  );
};
