import { FC, ReactNode, useRef } from 'react';
import { createUserStore } from './store';
import { TUserContext, UserContext } from './helper';

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const storeRef = useRef<TUserContext>();

  if (!storeRef.current) {
    storeRef.current = createUserStore();
  }

  return <UserContext.Provider value={storeRef.current}>{children}</UserContext.Provider>;
};
