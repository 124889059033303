import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type TSubscriptionStore = {
  subscriptionId: string | null;
  clientSecret: string | null;
  setSubscriptionState: (subscriptionId: string, clientSecret: string) => void;
};

const useSubscriptionSlice: ImmerStateCreator<TSubscriptionStore> = (set) => ({
  subscriptionId: null,
  clientSecret: null,
  setSubscriptionState: (subscriptionId, clientSecret) => {
    set((state) => {
      state.subscriptionId = subscriptionId;
      state.clientSecret = clientSecret;
    }),
      false,
      'setSubscriptionState';
  },
});

export const createSubscriptionStore = (initProps?: Partial<TSubscriptionStore>) => {
  return createStore<TSubscriptionStore>()(
    devtools(
      immer((...props) => ({
        ...useSubscriptionSlice(...props),
        ...initProps,
      })),
      {
        name: 'StripeSubscriptionStore',
        trace: true,
      },
    ),
  );
};
