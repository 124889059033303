import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

interface Usage {
  type: string;
  amount: number;
}

interface Styles {
  total: number;
  used: number;
  remaining: number;
}

interface Credit {
  total: number | null;
  used: number;
  remaining: number | null;
}

interface Product {
  total: number | null;
  used: number;
  remaining: number | null;
}

interface TrialPeriod {
  plan: Account['plan'];
  expiry_timestamp: number;
}

export interface Account {
  plan: 'free' | 'starter' | 'professional' | 'team' | 'enterprise';
  expiry_timestamp: number;
  renews: boolean;
  credit: Credit;
  products: Product;
  usage: Usage[];
  styles: Styles;
  trial_period: TrialPeriod | null;
}

const getAccountStatistics = async (organizationId: number): Promise<Account> => {
  const response = await apiClient.get(
    getAccountApiUrl(organizationId, AccountEndpoint.GET_ACCOUNT_STATISTICS),
  );
  const accountData: Account = response.data;

  if (accountData.trial_period) {
    accountData.plan = accountData.trial_period?.plan;
  }

  return accountData;
};

export const useAccountStatistics = (options?: {
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
}) => {
  const { staleTime = 0, refetchOnWindowFocus = true } = options || {};
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: AccountEndpoint.GET_ACCOUNT_STATISTICS, organizationId }],
    queryFn: ({ queryKey }) => getAccountStatistics(queryKey[0].organizationId),
    placeholderData: {
      plan: '' as any,
      expiry_timestamp: 0,
      renews: true,
      credit: { total: 0, used: 0, remaining: 0 },
      products: { total: 0, used: 0, remaining: 0 },
      usage: [],
      styles: { total: 0, used: 0, remaining: 0 },
      trial_period: null,
    },
    staleTime,
    refetchOnWindowFocus,
  });
  return result;
};

export const AccountContext = createContext<Account | undefined>(undefined);

export const useAccountCtx = () => {
  const currentUser = useContext(AccountContext);
  invariant(currentUser, 'useAccountCtx must be used within a AccountProvider');

  return currentUser;
};

export interface UserPreferences {
  theme: 'light' | 'dark';
  guiding: {
    showInspirationHowItWorks: boolean;
    showTemplatesHowItWorks: boolean;
    showImageStyleHowItWorks: boolean;
    showRestoreHowItWorks: boolean;
    showEnhanceQualityHowItWorks: boolean;
    showMagicEraseHowItWorks: boolean;
    showMagicEditHowItWorks: boolean;
    showImageCropHowItWorks: boolean;
    showImageExpandHowItWorks: boolean;
  };
  notifications: {
    mentions: boolean;
  };
}
const GET_USER_PREFERENCES_QUERY_KEY = (organizationId: number) => [
  'GET',
  AccountEndpoint.GET_ACCOUNT_PREFERENCES,
  organizationId,
];

const getUserPreferences = async (organizationId: number): Promise<UserPreferences> => {
  const response = await apiClient.get(
    getAccountApiUrl(organizationId, AccountEndpoint.GET_ACCOUNT_PREFERENCES),
  );

  return response.data;
};

export const useUserPreferences = (options?: {
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
}) => {
  const { staleTime = 0, refetchOnWindowFocus = true } = options || {};
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: AccountEndpoint.GET_ACCOUNT_PREFERENCES, organizationId }],
    queryFn: ({ queryKey }) => getUserPreferences(queryKey[0].organizationId),
    placeholderData: {
      theme: 'light',
      guiding: {
        showInspirationHowItWorks: false,
        showTemplatesHowItWorks: false,
        showImageStyleHowItWorks: false,
        showRestoreHowItWorks: false,
        showEnhanceQualityHowItWorks: false,
        showMagicEraseHowItWorks: false,
        showMagicEditHowItWorks: false,
        showImageCropHowItWorks: false,
        showImageExpandHowItWorks: false,
      },
      notifications: {
        mentions: true,
      },
    },
    staleTime,
    refetchOnWindowFocus,
  });
  return result;
};

const updateUserPreferences = async (
  organizationId: number,
  preferences: Partial<UserPreferences>,
): Promise<UserPreferences> => {
  const response = await apiClient.patch(
    getAccountApiUrl(organizationId, AccountEndpoint.PATCH_ACCOUNT_PREFERENCES),
    preferences,
  );

  return response.data;
};

export const useUpdateUserPreferences = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationFn: (preferences: Partial<UserPreferences>) =>
      updateUserPreferences(organizationId, preferences),
  });
};

export const PreferencesContext = createContext<UserPreferences | undefined>(undefined);

export const useUserPreferencesCtx = () => {
  const userPreferences = useContext(PreferencesContext);
  invariant(userPreferences, 'useUserPreferencesCtx must be used within a PreferencesContext');

  return userPreferences;
};
