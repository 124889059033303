import { cn } from '@/lib/utils';
import { Button, ButtonProps } from 'components/ui/button';
import { FC, ReactNode } from 'react';

export const RefetchView: FC<{ children: ReactNode; containerClass?: string } & ButtonProps> = ({
  children,
  containerClass,
  ...props
}) => {
  return (
    <div className={cn('flex w-full flex-col items-center gap-2 pt-6 text-sm', containerClass)}>
      {children}
      <Button {...props}>Retry</Button>
    </div>
  );
};
