import create from 'zustand';

type BottomMenuState = {
  activeSheet: string | null;
  setActiveSheet: (sheetName: string | null) => void;
  closeMobileBottomMenu: () => void;
};

export const useMobileBottomMenuStore = create<BottomMenuState>((set) => ({
  activeSheet: null,
  setActiveSheet: (sheetName) => set({ activeSheet: sheetName }),
  closeMobileBottomMenu: () => {
    set({ activeSheet: null });
  },
}));
