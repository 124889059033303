import { useStoreModal } from '../store';
import { useCompleteOnboarding } from '../queries';
import { useUserStore } from '@/providers/user/hooks';

export const CloseModalButton = () => {
  const storeModal = useStoreModal();
  const completeOnboarding = useCompleteOnboarding();
  const setOnboarding = useUserStore((state) => state.setUserOnbardingInfo);

  const handleSkip = async () => {
    try {
      await completeOnboarding.mutateAsync();
      setOnboarding({ isOnboarded: true });
      storeModal.onClose();
    } catch (error) {
      console.error('Failed to complete onboarding', error);
    }
  };

  return (
    <div
      className='mx-auto cursor-pointer text-[14px] font-medium text-[#777777]'
      onClick={handleSkip}
    >
      Skip for now
    </div>
  );
};
