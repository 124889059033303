import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { TooltipItem } from 'components/common/TooltipItem';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { useCallback } from 'react';

export const FaceCompositionSwitch = () => {
  const { faceTextComposition, updateAiScenePromptTab } = useAiSceneCreateStore((state) => ({
    faceTextComposition: state.aiScenePromptTab.faceTextComposition,
    updateAiScenePromptTab: state.updateAiScenePromptTab,
  }));

  const handleSwitchChange = useCallback(
    (isChecked: boolean) => {
      updateAiScenePromptTab({
        faceTextComposition: isChecked,
        hasUserManuallySetFaceTextComposition: true,
      });
    },
    [updateAiScenePromptTab],
  );

  return (
    <div className='mb-2 flex w-full items-center justify-between'>
      <Label htmlFor='composition-mode' className='flex cursor-pointer items-center gap-2'>
        <p className='text-sm font-medium'>Face & Text Composition</p>
        <TooltipItem
          trigger={
            <span className='i-mdi-information-slab-circle-outline cursor-pointer text-base'></span>
          }
        >
          <p className='max-w-[300px] rounded-2xl px-1 py-0.5'>
            Blend faces, hands, logos and text effortlessly while keeping them exactly as they are.
          </p>
        </TooltipItem>
      </Label>
      <Switch
        id='composition-mode'
        checked={faceTextComposition}
        onCheckedChange={handleSwitchChange}
        className='h-[22px] data-[state=checked]:bg-crait-green-primary'
        thumbClassName='data-[state=checked]:bg-c-dark'
      />
    </div>
  );
};
