import { FC, useEffect, useState } from 'react';
import Colorful from '@uiw/react-color-colorful';

const BASE_COLOR = '#000000';

interface BaseColorPickerProps {
  controlledHexa?: string;
  onColorChange: (hex: string, alpha: number, hexa: string) => void;
  onColorBlur?: () => void;
}

export const BaseColorPicker: FC<BaseColorPickerProps> = ({
  controlledHexa,
  onColorChange,
  onColorBlur,
}) => {
  const [hexa, setHexa] = useState(controlledHexa || BASE_COLOR);
  
  useEffect(() => {
    if (!controlledHexa) return;
    setHexa(controlledHexa);
  }, [controlledHexa]);

  return (
    <Colorful
      color={hexa}
      onChange={(color) => {
        setHexa(color.hexa);
        onColorChange(color.hex, color.rgba.a, color.hexa);
      }}
      onBlur={onColorBlur}
    />
  );
};
