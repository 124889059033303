import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { TObjectiveSelection } from '@/providers/ai-banner/create/store';

const ObjectiveOption = {
  CONVERSION: 'conversion',
  AWARENESS: 'awareness',
  TRAFFIC: 'traffic',
} as const;

export const ObjectiveContent = () => {
  const setObjective = useBannerCreateStore((s) => s.setObjective);

  return (
    <div>
      <RadioGroup
        onValueChange={(value: TObjectiveSelection) => {
          setObjective(value);
        }}
        className='text-sm'
      >
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={ObjectiveOption.CONVERSION}
            id={ObjectiveOption.CONVERSION}
            className='border border-white text-white'
          />
          <Label htmlFor={ObjectiveOption.CONVERSION} className='text-md'>
            Conversion
          </Label>
        </div>
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={ObjectiveOption.AWARENESS}
            id={ObjectiveOption.AWARENESS}
            className='border border-white text-white'
          />
          <Label htmlFor={ObjectiveOption.AWARENESS} className='text-md'>
            Awareness
          </Label>
        </div>
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={ObjectiveOption.TRAFFIC}
            id={ObjectiveOption.TRAFFIC}
            className='border border-white text-white'
          />
          <Label htmlFor={ObjectiveOption.TRAFFIC} className='text-md'>
            Traffic
          </Label>
        </div>
      </RadioGroup>
    </div>
  );
};
