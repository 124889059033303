import { Button } from 'components/ui/button';
import { NavLink } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div className='flex h-screen items-center justify-center bg-gray-100'>
      <div className='text-center'>
        <h1 className='mb-3 text-6xl font-bold text-gray-800'>404</h1>
        <p className='text-xl text-gray-600'>Page Not Found</p>
        <p className='mt-2 text-gray-600'>
          If you think this is an error, please
          <NavLink to='/contact' className='text-blue-600 hover:text-blue-800'>
            {' '}
            reach out to our team
          </NavLink>
          .
        </p>
        <NavLink to='/'>
          <Button className='mt-4' color='primary'>
            Go Home
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
