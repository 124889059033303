import { FC, ReactNode, useRef } from 'react';
import { createSubscriptionStore } from './store';
import { SubscriptionContext, TSubscriptionContext } from './helper';

interface SubscriptionProviderProps {
  children: ReactNode;
}

export const SubscriptionProvider: FC<SubscriptionProviderProps> = ({ children }) => {
  const storeRef = useRef<TSubscriptionContext>();

  if (!storeRef.current) {
    storeRef.current = createSubscriptionStore();
  }

  return (
    <SubscriptionContext.Provider value={storeRef.current}>{children}</SubscriptionContext.Provider>
  );
};
