import { ImageContent } from './ImageContent';
import { AiSceneBulkCreateProvider } from '@/providers/ai-scene/bulk-create/AiSceneBulkCreateProvider';
import { AiSceneSidemenu } from '../side-menu/AiSceneSidemenu';
import { Breakpoints } from '@/utils/general/constant';
import { useWindowSize } from 'usehooks-ts';
import { MobileCreateSceneBottomMenu } from '../mobile-bottom-menu/MobileCreateSceneBottomMenu';

export const AiSceneCreate = () => {
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  return (
    <div className='relative flex h-full w-full'>
      <AiSceneBulkCreateProvider>
        {isDesktop ? <AiSceneSidemenu /> : <MobileCreateSceneBottomMenu />}
        <ImageContent />
      </AiSceneBulkCreateProvider>
    </div>
  );
};
