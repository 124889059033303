import { BasicCard } from '../BasicCard';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { FC } from 'react';
import { TStyleResponse } from '../../helper/queries';
import { Modal } from 'components/ui/modal';

import ZoomIcon from '@/assets/icons/ai-scene-icons/zoom.svg?react';
import { CraitButton } from 'components/common/CraitButton';
import { Separator } from 'components/ui/separator';
import { useSyncSelectedTemplate } from '../useSyncSelectedTemplate';
import { useRemoveSelectedTemplate } from '../../helper/useRemoveSelectedTemplate';
import { useMobileBottomMenuStore } from 'components/ai-scene/mobile-bottom-menu/useMobileBottomMenuStore';

interface StyleItemsProps {
  styles: TStyleResponse[];
  returnCreateToolClick: () => void;
}

export const StyleItems: FC<StyleItemsProps> = ({ styles, returnCreateToolClick }) => {
  const addAiSceneSelectedStyles = useAiSceneCreateStore((state) => state.addAiSceneSelectedStyles);
  const styleIds = useAiSceneCreateStore((state) => state.aiSceneSettingsTab.styleIds);
  const { removeSelectedTemplate } = useRemoveSelectedTemplate();
  const [drawnTemplate] = useSyncSelectedTemplate();
  const closeMobileBottomMenu = useMobileBottomMenuStore((state) => state.closeMobileBottomMenu);

  const handleStyleSelection = (style: TStyleResponse) => {
    if (drawnTemplate) {
      removeSelectedTemplate(drawnTemplate.id);
    }
    addAiSceneSelectedStyles(style);
    returnCreateToolClick();
  };

  const hasAnyStyles = styles.length > 0;
  if (!hasAnyStyles) return <span>No styles found.</span>;

  return (
    <>
      {styleIds && styleIds.length > 0 && (
        <BasicCard
          key={styleIds[0].id}
          name={styleIds[0].name}
          image={styleIds[0].thumbnail}
          imageWrapperClass='outline outline-[4px] outline-[#80D962] hover:outline-[#80D962] hover:outline-[4px]'
        >
          <></>
        </BasicCard>
      )}
      {styles.map((style) => (
        <BasicCard
          key={style.id}
          name={style.name}
          image={style.thumbnail}
          onClick={() => {
            handleStyleSelection(style);
            closeMobileBottomMenu();
          }}
        >
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className='absolute right-1 top-1 z-10 flex size-6 cursor-pointer items-center justify-center rounded-full bg-[#212121] bg-opacity-50'
            >
              <Modal
                trigger={<ZoomIcon />}
                titleElement={style.name}
                modalContentClassName='max-w-[90%] max-h-[90%] rounded-[8px]  w-auto'
              >
                {({ closeModal }) => (
                  <div className='flex flex-col gap-3 pb-3 pt-8'>
                    <div className='px-3'>
                      <img src={style.url} className='h-[500px] w-[500px] rounded object-contain' />
                    </div>
                    <Separator />
                    <div className='mx-3'>
                      <CraitButton
                        onClick={() => {
                          handleStyleSelection(style);
                          closeModal();
                        }}
                      >
                        Select
                      </CraitButton>
                    </div>
                  </div>
                )}
              </Modal>
            </div>
          </>
        </BasicCard>
      ))}
    </>
  );
};
