import { cn } from '@/lib/utils';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { FC, ReactNode } from 'react';

interface CanvasLockViewProps {
  children: ReactNode;
}

export const ResultCanvasLockView: FC<CanvasLockViewProps> = ({ children }) => {
  const processingState = useAiSceneResultStore((state) => state.processingState);
  if (processingState === 'idle') return null;

  return (
    <div
      className={cn(
        `absolute left-1/2 z-50 h-full w-full -translate-x-1/2 transform select-none bg-white/30`,
      )}
    >
      <div className='flex h-full w-full flex-col items-center justify-center gap-3'>
        <LoadingSpinner />
      </div>
      <div className='absolute bottom-0 left-0 p-4'>{children}</div>
    </div>
  );
};
