import React from 'react';
import CommentIcon from '@/assets/icons/ai-scene-icons/comment.svg?react';
import ShareIcon from '@/assets/icons/ai-scene-icons/share.svg?react';
import DownloadIcon from '@/assets/icons/ai-scene-icons/download.svg?react';
import { toast } from 'sonner';
import { useDefinedSceneCtx } from 'pages/ai-scene/review-edit/helper';
import { useMutationState } from '@tanstack/react-query';
import { getUserEditMutationKey } from 'pages/workspace/brand-library/product-edit/helper';
import { useUserStore } from '@/providers/user/hooks';
import { TScene } from '../../result-sidebar/queries';
import { cloneAndScaleCanvasWithObjects, downloadWithAnchor } from '@/utils/helper';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { Button } from 'components/ui/button';
import { TooltipItem } from 'components/common/TooltipItem';
import { SaveSceneCanvas } from './SaveSceneCanvas';
import { DeleteSceneAction } from './DeleteSceneAction';
import { downloadSceneWithCreditCheck } from 'components/workspace/history/helper';
import { useParams } from 'react-router';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';

export const SceneActions: React.FC = () => {
  const { imageUrl, projectId, name } = useDefinedSceneCtx();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const editedSceneMutationResults = useMutationState({
    filters: { status: 'success', mutationKey: getUserEditMutationKey(organizationId) },
    select: (mutation) => mutation.state.data as TScene | undefined,
  });
  const latestMutationResult = editedSceneMutationResults[editedSceneMutationResults.length - 1];
  const outpaintData = useAiSceneOutpaintStore((state) => state.outpaintData);
  const activeSceneTitle = useAiSceneResultStore((state) => state.activeSceneTitle);
  const { sceneId } = useParams<{ sceneId: string }>();

  if (outpaintData.length > 0) return null;

  const handleCommentClick = () => {};

  const handleShareClick = async () => {
    try {
      await navigator.clipboard.writeText(latestMutationResult?.imageUrl || imageUrl);
      toast.success('Image copied to clipboard.');
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy the image.');
    }
  };

  const downloadCanvas = async () => {
    if (!canvasInstance || !sceneId || !projectId) return;

    try {
      const response = await downloadSceneWithCreditCheck({
        sceneId,
        projectId,
        organizationId,
      });

      if (response.status === 'success') {
        const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvasInstance);
        const dataURL = scaledCanvas.toDataURL({
          format: 'png',
          quality: 1,
          width: scaledCanvas.getWidth(),
          height: scaledCanvas.getHeight(),
        });
        downloadWithAnchor(dataURL, name || 'Untitled');
      }
    } catch (error: any) {
      const errorMessage =
        error.response.status == 402
          ? 'Insufficient credits'
          : 'An error occurred while attempting to download the scene.';
      toast.error(errorMessage);
    }
  };

  return (
    <div className='flex h-[40px] w-[172px] items-center justify-around gap-1 rounded-[7px] bg-white px-2'>
      <TooltipItem
        side='bottom'
        trigger={
          <Button
            variant='ghost'
            size='sm'
            className='h-full bg-inherit p-0'
            onClick={handleCommentClick}
          >
            <CommentIcon className='opacity-50' />
          </Button>
        }
      >
        Comment
      </TooltipItem>
      <TooltipItem
        side='bottom'
        trigger={
          <Button
            variant='ghost'
            size='sm'
            className='h-full bg-inherit p-0'
            onClick={handleShareClick}
          >
            <ShareIcon />
          </Button>
        }
      >
        Share
      </TooltipItem>
      <TooltipItem
        side='bottom'
        trigger={
          <Button
            variant='ghost'
            size='sm'
            className='h-full bg-inherit p-0'
            onClick={downloadCanvas}
          >
            <DownloadIcon />
          </Button>
        }
      >
        Download
      </TooltipItem>
      <SaveSceneCanvas />
      <DeleteSceneAction />
    </div>
  );
};
