import React from 'react';
import HowItWorksIcon from '@/assets/icons/ai-scene-icons/howItWorks.svg?react';
import { useUserPreferences, useUpdateUserPreferences } from 'pages/dashboard/profile/helper';

interface HowItWorksBoxProps {
  text: string;
  linkText: string;
  onLinkClick?: () => void;
  parentComponent?: keyof typeof PARENT_COMPONENT_MAPPING;
}

const PARENT_COMPONENT_MAPPING = {
  Inspiration: 'showInspirationHowItWorks',
  Templates: 'showTemplatesHowItWorks',
  ImageStyle: 'showImageStyleHowItWorks',
  ProductImageRestore: 'showProductImageRestoreHowItWorks',
  PartialDetailRestore: 'showPartialDetailRestoreHowItWorks',
  EnhanceQuality: 'showEnhanceQualityHowItWorks',
  MagicErase: 'showMagicEraseHowItWorks',
  MagicEdit: 'showMagicEditHowItWorks',
  ImageCrop: 'showImageCropHowItWorks',
  ImageExpand: 'showImageExpandHowItWorks',
};

export const HowItWorksBox: React.FC<HowItWorksBoxProps> = ({
  text,
  linkText,
  onLinkClick,
  parentComponent,
}) => {
  const {
    data: userPreferences,
    refetch,
    isLoading,
  } = useUserPreferences({
    staleTime: 300000,
  });
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();

  if (isLoading) return null;

  const shouldDisplay = () => {
    if (!parentComponent) return true;
    if (!userPreferences) return false;

    const preferenceKey = PARENT_COMPONENT_MAPPING[parentComponent];
    return preferenceKey
      ? userPreferences.guiding[preferenceKey as keyof typeof userPreferences.guiding]
      : true;
  };

  const handleUpdatePreference = () => {
    if (!parentComponent || !userPreferences) return;

    const preferenceKey = PARENT_COMPONENT_MAPPING[parentComponent];
    const updatedGuiding = {
      ...userPreferences.guiding,
      [preferenceKey]: false,
    };

    updateUserPreferences(
      { guiding: updatedGuiding },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  if (!shouldDisplay()) return null;

  return (
    <div className='how-it-works-box rounded-md bg-[#F5F5F5] p-4'>
      <div className='flex items-start'>
        <div className='mr-2'>
          <HowItWorksIcon />
        </div>
        <div className='flex flex-col items-start gap-2'>
          <h4 className='text-[12px] font-bold text-c-dark'>How it works?</h4>
          <p className='text-[12px] font-medium text-c-dark'>{text}</p>
          <button
            onClick={handleUpdatePreference}
            className='text-[12px] font-medium text-c-dark underline'
          >
            {linkText}
          </button>
        </div>
      </div>
    </div>
  );
};
