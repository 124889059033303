import { ReactNode, useRef, useState } from 'react';
import { toast } from 'sonner';
import { useWindowSize } from 'usehooks-ts';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { ModalView } from 'components/common/ModalView';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { BaseColorPicker } from './BaseColorPicker';
import { validHex } from '@uiw/color-convert';
import { hexToHexa } from '@/utils/helper';
import { formatHex, isValidAlpha } from './helper';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { TColorResponse } from '../queries';
import { CraitButton } from 'components/common/CraitButton';

const BASE_COLOR = '#000000';

interface ColorModalProps {
  children: ReactNode;
  title: string;
  onSaveClicked: (params: { name: string; hexCode: string; opacity: number }) => Promise<void>;
}

export const ColorModal = (props: Partial<TColorResponse> & ColorModalProps) => {
  const colorCircleRef = useRef<HTMLSpanElement | null>(null);
  const colorHexRef = useRef<HTMLInputElement | null>(null);
  const colorPercentRef = useRef<HTMLInputElement | null>(null);
  const colorNameRef = useRef<HTMLInputElement | null>(null);
  const [customColor, setCustomColor] = useState({
    hex: props.hexCode || BASE_COLOR,
    alpha: props.opacity || 100,
  });
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;
  const [status, setStatus] = useState<'idle' | 'pending'>('idle');

  const controlledHexa = hexToHexa(customColor.hex, customColor.alpha / 100);

  const modalContent = (closeModal: () => void) => (
    <>
      <Separator className='mb-4' />
      <div className='mx-auto w-56'>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-3'>
            <Label htmlFor='color-name'>Color Name</Label>
            <Input
              ref={colorNameRef}
              id='color-name'
              placeholder='Name the color'
              className='h-8 truncate bg-transparent text-sm'
              defaultValue={props.name}
            />
          </div>
          <div className='flex w-full gap-3'>
            <div className='flex w-2/3 flex-col gap-3'>
              <Label htmlFor='hex-color'>Hex</Label>
              <Input
                ref={colorHexRef}
                id='hex-color'
                placeholder='Enter the hex'
                className='h-8 truncate bg-transparent text-sm'
                defaultValue={customColor.hex}
                onChange={(e) => {
                  const hex = e.target.value;
                  if (!validHex(hex)) return;
                  const alpha = colorPercentRef.current?.value || 1;

                  setCustomColor({
                    hex,
                    alpha: Number(alpha),
                  });
                }}
              />
            </div>
            <div className='flex w-1/3 flex-col gap-3'>
              <Label htmlFor='opacity-color'>Opacity</Label>
              <Input
                ref={colorPercentRef}
                id='opacity-color'
                className='h-8 truncate bg-transparent text-sm'
                value={customColor.alpha}
                onChange={(e) => {
                  const alpha = e.target.value;
                  if (!isValidAlpha(alpha)) {
                    toast.error('Invalid alpha value.');
                    return;
                  }
                  const hex = colorHexRef.current?.value;
                  if (!hex || !validHex(hex)) return;

                  setCustomColor({
                    hex,
                    alpha: Number(alpha),
                  });
                }}
              />
            </div>
          </div>
          <div className='flex w-full flex-col items-center gap-2'>
            <span
              ref={colorCircleRef}
              className='size-20 rounded-full border border-slate-200'
              style={{
                backgroundColor: controlledHexa,
              }}
            />
            <BaseColorPicker
              controlledHexa={controlledHexa}
              onColorChange={(hex, alpha, hexa) => {
                if (!colorCircleRef.current || !colorHexRef.current || !colorPercentRef.current) {
                  return;
                }

                colorCircleRef.current.style.backgroundColor = hexa;
                colorHexRef.current.value = hex;
                colorPercentRef.current.value = `${Math.round(alpha * 100)}`;
              }}
            />
          </div>
          <Separator />
          <div className='flex flex-col gap-1.5'>
            <CraitButton
              className='w-full'
              onClick={async () => {
                try {
                  if (
                    status === 'pending' ||
                    !colorHexRef.current ||
                    !colorPercentRef.current ||
                    !colorNameRef.current
                  ) {
                    return;
                  }

                  const hex = colorHexRef.current.value;
                  const alpha = colorPercentRef.current.value;

                  if (!validHex(hex)) {
                    toast.error('Invalid hex value.');
                    return;
                  }

                  if (!isValidAlpha(alpha)) {
                    toast.error('Invalid alpha value.');
                    return;
                  }

                  const possiblyFormattedHex = formatHex(hex);

                  await props.onSaveClicked({
                    name: colorNameRef.current.value,
                    hexCode: possiblyFormattedHex,
                    opacity: Number(alpha),
                  });
                  closeModal();
                } catch (_) {
                  toast.error('Failed to update color');
                } finally {
                  setStatus('idle');
                }
              }}
              disabled={status === 'pending'}
            >
              Save
            </CraitButton>
            <Button variant='outline' className='w-full' onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return isDesktop ? (
    <ModalView
      trigger={props.children}
      titleElement={<span className='text-lg font-medium'>{props.title}</span>}
    >
      {({ closeModal }) => modalContent(closeModal)}
    </ModalView>
  ) : (
    <MobileMenuSheet
      triggerButton={props.children}
      contentClassName='pb-12'
      titleElement={props.title}
    >
      {({ closeModal }) => modalContent(closeModal)}
    </MobileMenuSheet>
  );
};
