import { FC, ReactNode } from 'react';
import { ScrollArea } from 'components/ui/scroll-area';
import { useOverflowGradient } from './useOverflowGradient';

export const ScrollableImages: FC<{
  backButton?: ReactNode;
  children: ReactNode;
  customElement?: ReactNode;
}> = ({ backButton, children, customElement }) => {
  const {
    gradientState: { top, bottom },
    onScroll,
  } = useOverflowGradient();

  return (
    <ScrollArea className='relative h-full w-full' onScroll={onScroll} onWheel={onScroll}>
      {customElement}
      {top && (
        <div className='absolute left-0 right-0 top-0 z-30 h-32 bg-scroll-gradient-t md:hidden'></div>
      )}
      {bottom && (
        <div className='absolute bottom-0 left-0 right-0 z-30 h-32 bg-scroll-gradient-b md:hidden'></div>
      )}
      {backButton}
      <div className='flex flex-wrap justify-center gap-3'>{children}</div>
    </ScrollArea>
  );
};
