import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import { toast } from 'sonner';
import invariant from 'tiny-invariant';
import {
  cloneAndScaleCanvasWithObjects,
  convertFabricCanvasToFile,
  filterFulfilled,
} from '@/utils/helper';
import { useOutpaintMutation } from 'pages/workspace/brand-library/product-edit/helper';
import {
  MAX_VISIBLE_CANVAS_SIZE,
  parseImageWithFabric,
} from 'components/ai-scene/create/editor/canvas/helper';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';
import { useParams } from 'react-router';
import { TScene, useLazyGetScene } from 'components/ai-scene/review/queries';
import { OutpaintCanvasLockView } from './OutpaintCanvasLockView';
import { useIsMounted } from 'usehooks-ts';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';
import { OutpaintCanvas } from './OutpaintCanvas';
import { CHILD_CANVAS_SIZE } from 'components/ai-scene/create/editor/bulk-canvas/helper';
import { fabric } from 'fabric';
import { OutpaintButton } from './OutpaintButton';

const INDEX_BUFFER = 1;

const _getCurrentSlide = (api: CarouselApi) => {
  if (!api) return INDEX_BUFFER.toString();
  return (api.selectedScrollSnap() + INDEX_BUFFER).toString();
};

const removeBackgroundAndGrid = (canvasInstance: fabric.Canvas) => {
  canvasInstance.setBackgroundColor('#ffffff', canvasInstance.renderAll.bind(canvasInstance));

  const gridObjects = canvasInstance
    .getObjects()
    .filter((obj) => obj.type === 'rect' && obj.fill instanceof fabric.Pattern);
  gridObjects.forEach((gridObj) => canvasInstance.remove(gridObj));

  canvasInstance.renderAll();
};

export const OUTPAINT_BUTTON_PORTAL_ID = 'outpaint-button-portal';

const renderOutpaintButtonInPortal = (
  outpaintAllCanvases: () => Promise<void>,
  processingState: string,
) => {
  const portalTarget = document.getElementById(OUTPAINT_BUTTON_PORTAL_ID);
  if (!portalTarget) {
    return null;
  }

  return ReactDOM.createPortal(
    <OutpaintButton outpaintAllCanvases={outpaintAllCanvases} processingState={processingState} />,
    portalTarget,
  );
};

export const OutpaintCanvasWrapper = () => {
  const outpaintData = useAiSceneOutpaintStore((state) => state.outpaintData);
  const removeMultiOutpaintData = useAiSceneOutpaintStore((state) => state.removeMultiOutpaintData);
  const [api, setApi] = useState<CarouselApi>();
  const currentSlideTextRef = useRef<HTMLSpanElement | null>(null);
  const renderedCanvasInstances = useRef<Record<string, fabric.Canvas>>({});
  const { mutateAsync: outpaintAsync, isPending } = useOutpaintMutation();
  const changeProcessingState = useAiSceneOutpaintStore((state) => state.changeProcessingState);
  const processingState = useAiSceneOutpaintStore((state) => state.processingState);
  const { sceneId, projectId } = useParams();
  const { fetchSceneById } = useLazyGetScene();
  const isMounted = useIsMounted();
  const { refetchScenes } = useRefetchGeneratedScenes();

  useEffect(() => {
    if (!api) return;
    if (!currentSlideTextRef.current) return;

    currentSlideTextRef.current.textContent = _getCurrentSlide(api);

    api.on('select', () => {
      if (!currentSlideTextRef.current) return;
      currentSlideTextRef.current.textContent = _getCurrentSlide(api);
    });
  }, [api, outpaintData]);

  useEffect(() => {
    const remainingIds = outpaintData.map((data) => data.id);
    renderedCanvasInstances.current = Object.keys(renderedCanvasInstances.current).reduce(
      (acc, key) => {
        if (remainingIds.includes(key)) {
          acc[key] = renderedCanvasInstances.current[key];
        }
        return acc;
      },
      {} as Record<string, fabric.Canvas>,
    );
  }, [outpaintData]);

  const mainCanvas = useMemo(() => {
    const firstCanvas = outpaintData[0];
    if (!firstCanvas) return null;

    return (
      <>
        <OutpaintCanvasLockView>
          <span className='text-lg'>Processing the canvas...</span>
        </OutpaintCanvasLockView>
        <OutpaintCanvas
          canvasData={firstCanvas}
          options={{
            dimensions: { width: MAX_VISIBLE_CANVAS_SIZE, height: MAX_VISIBLE_CANVAS_SIZE },
          }}
          onCanvasRendered={(canvasInstance) => {
            renderedCanvasInstances.current[firstCanvas.id] = canvasInstance;
          }}
        ></OutpaintCanvas>
      </>
    );
  }, [outpaintData]);

  const outpaintCanvases = useMemo(() => {
    return outpaintData.map((canvasData) => (
      <CarouselItem
        key={canvasData.id}
        className='relative flex basis-[180px] items-center justify-center border border-[#EAEAEA] p-0'
      >
        <div className='overflow-hidden'>
          <OutpaintCanvasLockView>
            <span className='text-lg'></span>
          </OutpaintCanvasLockView>
          <OutpaintCanvas
            canvasData={canvasData}
            options={{
              dimensions: { width: CHILD_CANVAS_SIZE, height: CHILD_CANVAS_SIZE },
            }}
            onCanvasRendered={(canvasInstance) => {
              renderedCanvasInstances.current[canvasData.id] = canvasInstance;
            }}
          />
        </div>
      </CarouselItem>
    ));
  }, [outpaintData]);

  if (outpaintData.length < 1) return null;

  const tryOutpaintAsync = async (sceneId: string, projectId: string): Promise<TScene> => {
    let timeoutId: NodeJS.Timeout;
    return new Promise((resolve, reject) => {
      const attemptOutpaint = async () => {
        try {
          if (!isMounted()) {
            clearTimeout(timeoutId);
            return;
          }

          const outpaintedScene = await fetchSceneById(sceneId, projectId);
          if (['pending', 'in_progress'].includes(outpaintedScene.status)) {
            timeoutId = setTimeout(attemptOutpaint, 1000);
          } else if (outpaintedScene.status === 'ready') {
            resolve(outpaintedScene);
          } else {
            reject(outpaintedScene);
          }
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      };

      attemptOutpaint();
    });
  };

  const outpaintAllCanvases = async () => {
    try {
      invariant(sceneId, 'Scene id not found');
      invariant(projectId, 'Project id not found');

      const canvasEntries = Object.entries(renderedCanvasInstances.current);
      if (canvasEntries.length < 1) {
        toast.error('No canvas found to outpaint');
        return;
      }

      changeProcessingState('outpainting');

      const scaledCanvasPromises = canvasEntries.map(([id, canvasInstance]) => {
        removeBackgroundAndGrid(canvasInstance);

        return cloneAndScaleCanvasWithObjects(canvasInstance).then((canvas) => ({
          id,
          canvas,
        }));
      });

      const scaledCanvases = await Promise.all(scaledCanvasPromises);
      const canvasFiles = scaledCanvases.map((scaled) => {
        const imageObject = scaled.canvas.getObjects('image')[0];
        return {
          id: scaled.id,
          file: convertFabricCanvasToFile({
            canvas: scaled.canvas,
            fileName: 'canvas-file',
            extension: 'png',
          }),
          transformation: {
            left: imageObject.left,
            top: imageObject.top,
            width: imageObject.getScaledWidth(),
            height: imageObject.getScaledHeight(),
            scale_x: imageObject.scaleX,
            scale_y: imageObject.scaleY,
          },
        };
      });

      const createdScenes = await Promise.allSettled(
        canvasFiles.map(({ id, file, transformation }) =>
          outpaintAsync({
            sceneId,
            projectId,
            mask: file,
            transformation,
          })
            .then((outpaintResult) => ({
              id,
              outpaintResult,
            }))
            .catch((error) => ({
              id,
              error,
            })),
        ),
      );
      const successfulScenes = filterFulfilled(createdScenes) as {
        id: string;
        outpaintResult: TScene;
      }[];

      const processedScenes = await Promise.allSettled(
        successfulScenes.map(({ id, outpaintResult }) =>
          tryOutpaintAsync(outpaintResult.id, projectId)
            .then((tryOutpaintResult) => ({
              id,
              tryOutpaintResult,
            }))
            .catch((error) => ({
              id,
              error,
            })),
        ),
      );

      const successfulProcessedScenes = filterFulfilled(processedScenes) as {
        id: string;
        tryOutpaintResult: TScene;
      }[];

      const addImagesToCanvasPromises = successfulProcessedScenes.map(
        async ({ id, tryOutpaintResult }) => {
          const canvasInstance = renderedCanvasInstances.current[id];
          const { image: imageObject } = await parseImageWithFabric({
            id: tryOutpaintResult.id,
            image: tryOutpaintResult.imageUrl,
            type: 'template',
            maxHeight: canvasInstance.getHeight(),
            maxWidth: canvasInstance.getWidth(),
            placementBuffer: 0,
          });
          imageObject.set({
            evented: false,
            selectable: false,
            isSelectionIgnored: true,
          });
          canvasInstance.selection = false;
          canvasInstance.forEachObject((obj) => canvasInstance.remove(obj));
          canvasInstance.add(imageObject);
          canvasInstance.requestRenderAll();
          return id;
        },
      );

      const canvasDrawingProcesses = await Promise.allSettled(addImagesToCanvasPromises);
      const successfullyUpdatedCanvasIds = filterFulfilled(canvasDrawingProcesses);
      const failedCanvasEntries = canvasEntries.filter(([id]) => {
        return !successfullyUpdatedCanvasIds.includes(id);
      });
      removeCanvasInstancesAndAdjustSlideCount(failedCanvasEntries.map(([id]) => id));
      refetchScenes();
      changeProcessingState('resolved');
      removeCanvasInstancesAndAdjustSlideCount(Object.keys(renderedCanvasInstances.current));
      toast.success('Outpaint process succesfull.');
    } catch (error) {
      console.error('Error while selecting the generated image', error);
      toast.error('An error occurred during the outpaint process. Please try again later.');
      changeProcessingState('idle');
    }
  };

  const removeCanvasInstancesAndAdjustSlideCount = (idsToRemove: string[]) => {
    removeMultiOutpaintData(idsToRemove);
    renderedCanvasInstances.current = Object.keys(renderedCanvasInstances.current).reduce(
      (acc, key) => {
        if (!idsToRemove.includes(key)) {
          acc[key] = renderedCanvasInstances.current[key];
        }
        return acc;
      },
      {} as Record<string, fabric.Canvas>,
    );

    if (currentSlideTextRef.current) {
      const outpaintLengthAfterRemoval = outpaintData.length - idsToRemove.length;
      const existingSlideNumber = Number(currentSlideTextRef.current.textContent);
      if (existingSlideNumber > outpaintLengthAfterRemoval) {
        currentSlideTextRef.current.textContent = Math.max(
          outpaintLengthAfterRemoval,
          1,
        ).toString();
      }
    }
  };

  const removeSelectedCanvas = () => {
    try {
      const currentSlide = api?.selectedScrollSnap();
      if (typeof currentSlide === 'undefined') {
        toast.error('Failed to select the generated image. Please try again.');
        return;
      }

      const selectedOutpaintData = outpaintData[currentSlide];
      invariant(selectedOutpaintData, 'No data found to select the generated image.');
      const activeCanvasInstance = renderedCanvasInstances.current[selectedOutpaintData.id];
      invariant(activeCanvasInstance, 'Canvas instance not found.');

      removeCanvasInstancesAndAdjustSlideCount([selectedOutpaintData.id]);
    } catch (error) {
      console.error('Error while selecting the generated image', error);
      toast.error('Error while selecting the generated image');
    }
  };

  const backgroundClass = outpaintData.length > 1 ? 'opacity-50' : '';

  return (
    <div className='absolute inset-0 left-1/2 z-[10] mt-5 w-[600px] -translate-x-1/2 transform '>
      {outpaintData.length === 1 && (
        <div className={`relative my-auto flex w-full justify-center  ${backgroundClass}`}>
          {mainCanvas}
        </div>
      )}
      <div className='relative'>
        {outpaintData.length > 1 && (
          <>
            <img
              className={`mb-5 flex h-[600px] w-[600px] justify-center object-contain ${backgroundClass}`}
              src={outpaintData[0].url}
            />
            <div className='absolute top-[110px] flex w-full justify-center'>
              <div
                className={`relative ${
                  outpaintData.length >= 3
                    ? 'w-[600px]'
                    : outpaintData.length === 2
                      ? 'w-[400px]'
                      : 'w-[200px]'
                }`}
              >
                <Carousel
                  className='relative m-auto mb-[5rem] max-w-full rounded-xl bg-white py-6'
                  setApi={setApi}
                  opts={{
                    dragFree: false,
                    watchDrag: false,
                  }}
                >
                  <CarouselContent className='m-auto justify-around gap-2'>
                    {outpaintCanvases}
                  </CarouselContent>
                  <div className='absolute -bottom-14 left-1/2 z-10 flex -translate-x-1/2 transform gap-2'>
                    <CarouselPrevious className='relative inset-0 h-12 w-12 transform-none' />
                    <CarouselNext className='relative inset-0 h-12 w-12 transform-none' />
                  </div>
                </Carousel>
              </div>
            </div>
          </>
        )}
        {renderOutpaintButtonInPortal(outpaintAllCanvases, processingState)}
      </div>
    </div>
  );
};
