import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'components/ui/card';
import { RoutePaths } from '@/utils/route/constants';
import Photoshoot from '@/assets/icons/cameraLens.svg?react';
import AiImage from '@/assets/icons/aiImage.svg?react';
import MagicEdit from '@/assets/icons/aiEditMenu.svg?react';
import AdCreative from '@/assets/icons/columns01.svg?react';
import Workflow from '@/assets/icons/dataflow06.svg?react';
import Insight from '@/assets/icons/barLineChart.svg?react';
import { toast } from 'sonner';
import { MobileWarningContent } from 'components/common/MobileWarningContent';

interface ProjectLink {
  title: string;
  description: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  style: string;
  path: string;
  isDisabled?: boolean;
}

const NEW_PROJECT_LINKS: ProjectLink[] = [
  {
    title: 'Start a Photoshoot',
    description: 'Generate product photos with AI',
    icon: Photoshoot,
    style: 'h-[24px] w-[24px] stroke-crait-red',
    path: RoutePaths.AI_SCENE,
  },
  {
    title: 'Create AI Image',
    description: 'Generate images with AI',
    icon: AiImage,
    style: 'h-[24px] w-[24px] stroke-crait-red',
    path: RoutePaths.AI_IMAGE,
  },
  {
    title: 'AI Edit',
    description: 'Upload and edit your images with AI',
    icon: MagicEdit,
    style: 'h-[24px] w-[24px] stroke-crait-red',
    path: RoutePaths.AI_EDIT,
  },
  {
    title: 'Design Ad Creative',
    description: 'Create ad layouts suitable for all channels',
    icon: AdCreative,
    path: RoutePaths.CREATIVE_SUITE,
    style: 'h-[19px] w-[19px]',
    isDisabled: true,
  },
  {
    title: 'Learn Insights',
    description: 'Optimize and analyze creative performance',
    icon: Insight,
    style: 'h-[24px] w-[24px] stroke-crait-gray',
    path: RoutePaths.CREATIVE_SUITE,
    isDisabled: true,
  },
  {
    title: 'Automate Workflow',
    description: 'Create bulk visuals and easily export',
    icon: Workflow,
    style: 'h-[24px] w-[24px] stroke-crait-gray',
    path: RoutePaths.CREATIVE_SUITE,
    isDisabled: true,
  },
];

interface ProjectCardProps {
  link: ProjectLink;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

const ProjectCard = forwardRef<HTMLDivElement, ProjectCardProps>(
  ({ link, onClick, children }, ref) => (
    <Card ref={ref} key={link.title} className='relative min-w-[250px] flex-shrink-0'>
      <Link
        to={`/${link.path}`}
        onClick={onClick}
        className={`flex gap-2 ${link.isDisabled ? 'cursor-not-allowed' : ''}`}
      >
        <div
          className={`relative flex min-h-[60px] w-full items-start gap-[10px] rounded-md p-3 ${
            link.isDisabled ? 'text-[#777777]' : ''
          }`}
        >
          <link.icon className={`${link.style}`} />
          <div className='flex-1 space-y-1'>
            <p className='text-[13px] font-semibold leading-none'>{link.title}</p>
            <p className='text-[12px] font-normal text-muted-foreground'>{link.description}</p>
          </div>
        </div>
      </Link>
      {children}
    </Card>
  ),
);

export const ProjectsTopLinks: React.FC = () => {
  const handleAiEditClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      toast(<MobileWarningContent />);
    }
  };

  return (
    <div className='w-full overflow-x-auto'>
      <div className='flex flex-nowrap gap-3 min-md:grid min-md:grid-cols-1 min-lg:grid-cols-2 min-xl:grid-cols-3'>
        {NEW_PROJECT_LINKS.map((link) =>
          link.title === 'AI Edit' ? (
            <ProjectCard key={link.title} link={link} onClick={handleAiEditClick} />
          ) : (
            <ProjectCard
              key={link.title}
              link={link}
              onClick={(e) => {
                if (link.isDisabled) {
                  e.preventDefault();
                }
              }}
            />
          ),
        )}
      </div>
    </div>
  );
};
