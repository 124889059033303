const MIN_ALPHA = 0;
const MAX_ALPHA = 100;
const HEX_PREFIX = '#';
const HEX_LENGTH = 7;

export const isValidAlpha = (alpha: string) => {
  const alphaValue = Number(alpha);
  return !isNaN(alphaValue) && alphaValue >= MIN_ALPHA && alphaValue <= MAX_ALPHA;
};

export const formatHex = (hex: string) => {
  let formattedHex = hex;
  if (!hex.startsWith(HEX_PREFIX)) {
    formattedHex = `${HEX_PREFIX}${hex}`;
  }
  if (formattedHex.length < HEX_LENGTH) {
    formattedHex = formattedHex.padEnd(HEX_LENGTH, '0');
  }
  return formattedHex;
};
