import { useEffect } from 'react';
import { fabric } from 'fabric';
import { serializeCanvas } from '@/utils/helper';

interface UseFabricEventHandlersProps {
  canvasInstance: React.RefObject<fabric.Canvas>;
  updateCanvasState: (state: string, caller?: string) => void;
}

export const useFabricEventHandlers = ({
  canvasInstance,
  updateCanvasState,
}: UseFabricEventHandlersProps) => {
  useEffect(() => {
    const fabricRef = canvasInstance.current;
    if (!fabricRef) return;

    const updateCanvas = () => {
      updateCanvasState(serializeCanvas(fabricRef));
    };

    fabricRef.on('object:modified', updateCanvas);
    return () => {
      fabricRef.off('object:modified', updateCanvas);
    };
  }, [canvasInstance, updateCanvasState]);
};
