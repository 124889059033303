import React from 'react';
import { MainPromptInput } from '../../side-menu/create-tool/MainPromptInput';
import { Popover, PopoverTrigger, PopoverContent } from 'components/ui/popover';
import { DisabledPromptPopover } from '../../side-menu/create-tool/DisabledPromptPopover';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useSceneImagesSrc } from '../../side-menu/useSceneImagesSrc';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from 'components/ui/sheet';

interface PromptInputWithPopoverProps {
  activeSheet: string | null;
  setActiveSheet: (sheetName: string | null) => void;
}

export const PromptInputWithPopover: React.FC<PromptInputWithPopoverProps> = ({
  activeSheet,
  setActiveSheet,
}) => {
  const prompt = useAiSceneCreateStore((state) => state.aiScenePromptTab.prompt);
  const { templateImages } = useSceneImagesSrc();

  const promptTriggerButton = (
    <div className='bg-gray flex h-[44px] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap rounded-md border px-2 text-[13px] text-[#777777]'>
      {prompt || 'Prompt'}
    </div>
  );

  return (
    <>
      {templateImages.length > 0 ? (
        <Popover>
          <PopoverTrigger asChild>{promptTriggerButton}</PopoverTrigger>
          <PopoverContent side='top'>
            <DisabledPromptPopover />
          </PopoverContent>
        </Popover>
      ) : (
        <Sheet onOpenChange={() => setActiveSheet(null)}>
          <SheetTrigger>
            <div>{promptTriggerButton}</div>
          </SheetTrigger>

          <SheetContent
            side={'top'}
            className={`max-h-[calc(100vh-140px)] overflow-y-auto rounded-bl-lg rounded-br-lg bg-white px-4 pb-4 pt-12`}
          >
            <SheetHeader>
              <SheetTitle className='absolute left-4 top-3 max-w-[84%] truncate text-[16px] font-semibold'>
                Prompt
              </SheetTitle>
            </SheetHeader>
            <MainPromptInput isDisabled={false} />
          </SheetContent>
        </Sheet>
      )}
    </>
  );
};
