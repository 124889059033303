import { useState, FC } from 'react';
import { EditStep } from './EditStep';
import { ProductCreateProvider } from '@/providers/brand-library/product-create/ProductCreateProvider';
import { CreateCanvas } from './CreateCanvas';
import { TProductResponse } from '../helper/queries';
import { ProductEditBox } from './ProductEditBox';

interface ProductPhotoEditorViewProps {
  closeModal: () => void;
  product: TProductResponse;
}

export const ProductPhotoEditorView: FC<ProductPhotoEditorViewProps> = ({
  closeModal,
  product,
}) => {
  const [processedImage, setProcessedImage] = useState<{
    opaque: string;
    transparent: string;
  }>();

  return (
    <div>
      <ProductCreateProvider>
        <EditStep
          canvasView={
            <CreateCanvas
              originalImage={product.image.original}
              transparentImage={product.image.transparent}
            />
          }
        >
          <ProductEditBox product={product} closeModal={closeModal} />
        </EditStep>
      </ProductCreateProvider>
    </div>
  );
};
