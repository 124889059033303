import React, { useState } from 'react';
import { CreateTool } from './CreateTool';
import { ProductPhotoMenu } from './product-photo/ProductPhotoMenu';
import { VisualGuidanceMenu } from './visual-guidance/VisualGuidanceMenu';
import { SizeMenu } from './size/SizeMenu';
import { CustomSizeMenu } from './size/CustomSizeMenu';
import { AdvancedSettingsMenu } from './advanced-settings/AdvancedSettingsMenu';
import { ArrowRightIcon } from './CreateToolIcons';

import CustomSizeIcon from '@/assets/icons/ai-scene-icons/customSize.svg?react';
import { ImageStyleMenu } from './image-style/ImageStyleMenu';

export const CreateToolMenus = {
  CREATE: 'create',
  PRODUCT_PHOTO: 'productPhoto',
  VISUAL_GUIDANCE: 'visualGuidance',
  SIZE: 'size',
  CUSTOM_SIZE: 'customSize',
  ADVANCED_SETTINGS: 'advancedSettings',
  IMAGE_STYLE: 'imageStyle',
} as const;

export type MenuType = (typeof CreateToolMenus)[keyof typeof CreateToolMenus];

export const CreateToolContainer: React.FC = () => {
  const [menu, setMenu] = useState<MenuType>(CreateToolMenus.CREATE);

  return (
    <div className='h-[96%]'>
      {menu === CreateToolMenus.CREATE && (
        <CreateTool
          onProductPhotoClick={() => setMenu(CreateToolMenus.PRODUCT_PHOTO)}
          onVisualGuidanceClick={() => setMenu(CreateToolMenus.VISUAL_GUIDANCE)}
          onSizeTabClick={() => setMenu(CreateToolMenus.SIZE)}
          onAdvancedSettings={() => setMenu(CreateToolMenus.ADVANCED_SETTINGS)}
          onImageStyle={() => setMenu(CreateToolMenus.IMAGE_STYLE)}
        />
      )}
      {menu === CreateToolMenus.PRODUCT_PHOTO && (
        <ProductPhotoMenu onBackClick={() => setMenu(CreateToolMenus.CREATE)} />
      )}
      {menu === CreateToolMenus.VISUAL_GUIDANCE && (
        <VisualGuidanceMenu onBackClick={() => setMenu(CreateToolMenus.CREATE)} />
      )}
      {menu === CreateToolMenus.SIZE && (
        <SizeMenu onBackClick={() => setMenu(CreateToolMenus.CREATE)}>
          <button
            onClick={() => setMenu(CreateToolMenus.CUSTOM_SIZE)}
            className='flex h-[44px] w-[96%] cursor-pointer items-center justify-between gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 text-main font-semibold hover:bg-slate-100'
          >
            <div className='flex items-center gap-2'>
              <div className='flex w-6 justify-center'>
                <CustomSizeIcon />
              </div>
              Custom Size
            </div>

            <ArrowRightIcon />
          </button>
        </SizeMenu>
      )}
      {menu === CreateToolMenus.CUSTOM_SIZE && (
        <CustomSizeMenu onBackClick={() => setMenu(CreateToolMenus.SIZE)} />
      )}
      {menu === CreateToolMenus.ADVANCED_SETTINGS && (
        <AdvancedSettingsMenu onBackClick={() => setMenu(CreateToolMenus.CREATE)} />
      )}
      {menu === CreateToolMenus.IMAGE_STYLE && (
        <ImageStyleMenu onBackClick={() => setMenu(CreateToolMenus.CREATE)} />
      )}
    </div>
  );
};
