import { ProductCreateBox } from 'components/workspace/brand-library/products-create/ProductCreateBox';
import { ProductCreateCanvas } from 'components/workspace/brand-library/products-create/ProductCreateCanvas';
import { BrandProductsEditView } from 'components/workspace/brand-library/products-edit/BrandProductsEditView';
import { ImageWrapperView } from 'components/workspace/brand-library/products-edit/ImageWrapperView';
import { useNavigate } from 'react-router';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125';

export const BrandLibraryProductCreatePage = () => {
  const navigate = useNavigate();

  return (
    <BrandProductsEditView
      backButton={
        <Button
          className='flex gap-2 bg-white text-black'
          onClick={() => {
            navigate(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`);
          }}
        >
          <IconWrapper
            wrapperProps={{
              className: ICON_WRAPPER_CL,
            }}
            iconClass='i-mdi-arrow-left size-4'
          />
          Back
        </Button>
      }
    >
      <ImageWrapperView canvasView={<ProductCreateCanvas />}>
        <ProductCreateBox />
      </ImageWrapperView>
    </BrandProductsEditView>
  );
};
