import { FC, useState } from 'react';
import Colorful from '@uiw/react-color-colorful';
import { isFabricText } from '@/utils/helper';
import './colorPickerStyles.css';

interface ColorPickerProps {
  canvasInstance: fabric.Canvas | null;
  onColorChange: (hex: string, alpha: number) => void;
  onColorBlur: () => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({
  canvasInstance,
  onColorChange,
  onColorBlur,
}) => {
  const [hexa, setHexa] = useState(() => {
    if (!canvasInstance) return '';

    const activeObject = canvasInstance.getActiveObject();
    if (!activeObject || !isFabricText(activeObject)) return '';

    return activeObject.get('fill') as string;
  });

  const setTextColor = (hexa: string) => {
    if (!canvasInstance || !hexa) return;

    const activeObject = canvasInstance.getActiveObject();
    if (!activeObject || !isFabricText(activeObject)) return;

    activeObject.set('fill', hexa);
    canvasInstance.requestRenderAll();
  };

  return (
    <Colorful
      color={hexa}
      onChange={(color) => {
        setHexa(color.hexa);
        setTextColor(color.hexa);
        onColorChange(color.hex, color.rgba.a);
      }}
      onBlur={onColorBlur}
    />
  );
};
