import { FC, ReactNode } from 'react';
import { cn } from '@/lib/utils';
import { useGetLayoutSizesQuery, useDeleteLayoutSizeMutation } from './queries';
import CustomSizeIcon from '@/assets/icons/ai-scene-icons/customSize.svg?react';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

interface CustomCanvasSizesProps {
  children?: ReactNode;
  onDimensionSelected?: (width: number, height: number) => void;
  canvasDimensions: { width?: number; height?: number };
}

export const CustomCanvasSizes: FC<CustomCanvasSizesProps> = ({
  children,
  onDimensionSelected,
  canvasDimensions,
}) => {
  const { data, refetch, isLoading } = useGetLayoutSizesQuery();
  const deleteLayoutSizeMutation = useDeleteLayoutSizeMutation();

  if (!data || !data.data.custom) return null;

  if (isLoading) {
    <LoadingSpinner />;
  }

  const customSizes = data.data.custom;

  return (
    <>
      {children}
      <div className='mb-2 flex flex-col gap-1 text-xs'>
        {customSizes.map((dimension) => (
          <div key={dimension.id + Math.random()} className='flex items-center gap-2'>
            <div
              className={cn(
                'relative flex h-[44px] w-full cursor-pointer items-center gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 hover:bg-slate-100',
                {
                  'bg-slate-200':
                    canvasDimensions.width === dimension.dimensions.x &&
                    canvasDimensions.height === dimension.dimensions.y,
                },
              )}
              onClick={() => {
                onDimensionSelected?.(dimension.dimensions.x, dimension.dimensions.y);
              }}
            >
              <div className='flex w-6 justify-center'>
                <CustomSizeIcon />
              </div>
              <div className='max-w-[200px] flex-1  overflow-hidden text-[13px] font-semibold'>
                <span className='truncate'>{dimension.name || 'Custom Size'} </span> (
                {dimension.dimensions.x} x {dimension.dimensions.y}px)
              </div>
              <div className='absolute right-1 h-4 w-4'>
                <span
                  className='i-mdi-trash-can-outline size-4 cursor-pointer text-gray-400'
                  onClick={() => {
                    deleteLayoutSizeMutation.mutate(
                      { layout_size_id: dimension.id },
                      {
                        onSuccess: () => {
                          refetch();
                        },
                        onError: (error) => {
                          console.error('Error deleting layout size:', error);
                        },
                      },
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
