import { useRef, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { Plus } from 'lucide-react';
import { useWindowSize } from 'usehooks-ts';

import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { FileUploadInput } from '../../products/upload/FileUploadInput';
import { GET_LOGOS_QUERY_KEY, useUploadLogoMutation } from '../queries';
import { Breakpoints } from '@/utils/general/constant';
import { CraitButton } from 'components/common/CraitButton';
import { Modal } from 'components/ui/modal';
import { getFileNameWithoutExtension } from '@/utils/helper';

const UPLOAD_LOGO_TITLE = 'Upload New Logo';

export const AddLogoModal = () => {
  const [file, setFile] = useState<File | null>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  const uploadLogo = useUploadLogoMutation();
  const queryClient = useQueryClient();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  useEffect(() => {
    if (file && nameInput.current) {
      const fileName = getFileNameWithoutExtension(file.name);
      nameInput.current.value = fileName;
    }
  }, [file]);

  const triggerButton = (
    <div className='flex h-[44px] w-[141px] cursor-pointer items-center justify-center gap-[6px] rounded-[10px] border border-[#EAEAEA] text-[14px] font-medium'>
      Add New <Plus size={14} />
    </div>
  );

  const uploadContent = (closeModal: () => void) => (
    <>
      <div className='flex flex-col items-center gap-6'>
        <FileUploadInput onFileUpload={setFile} />
        <div className='flex w-72 flex-col gap-3'>
          <Input ref={nameInput} placeholder='Enter logo name' />
          <CraitButton
            className='w-full'
            disabled={!file || uploadLogo.isPending}
            onClick={async () => {
              try {
                if (uploadLogo.isPending) return;
                if (!file || !nameInput.current) {
                  toast.error('Please select an image and enter a name');
                  return;
                }

                await uploadLogo.mutateAsync({ image: file, name: nameInput.current.value });
                queryClient.invalidateQueries({
                  queryKey: [{ _name: GET_LOGOS_QUERY_KEY }],
                  exact: false,
                });
                closeModal();
              } catch (_) {
                toast.error('Failed to upload logo');
              }
            }}
          >
            Upload
          </CraitButton>
          <Button variant='outline' className='w-full' onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );

  return isDesktop ? (
    <Modal
      trigger={triggerButton}
      titleElement={<span className='text-lg font-medium'>{UPLOAD_LOGO_TITLE}</span>}
      modalContentClassName='py-12'
    >
      {({ closeModal }) => uploadContent(closeModal)}
    </Modal>
  ) : (
    <MobileMenuSheet
      triggerButton={triggerButton}
      contentClassName='py-8'
      titleElement={UPLOAD_LOGO_TITLE}
    >
      {({ closeModal }) => uploadContent(closeModal)}
    </MobileMenuSheet>
  );
};
