import { Table } from '@tanstack/react-table';
import { TableViewOptions } from './TableViewOptions';
import { Input } from 'components/ui/input';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function TableToolbar<TData>({ table }: DataTableToolbarProps<TData>) {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        <Input
          placeholder='Filter settings...'
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) => table.getColumn('name')?.setFilterValue(event.target.value)}
          className='h-8 w-[230px] bg-white'
        />
      </div>
      <TableViewOptions table={table} />
    </div>
  );
}
