import { FC, ReactNode, useEffect, useRef } from 'react';
import { TCanvasOptions, useFabricCanvas } from '@/hooks/canvas/useFabricCanvas';
import {
  calculateDisplayDimensions,
  centerImageInCanvas,
  parseImageWithFabric,
} from 'components/ai-scene/create/editor/canvas/helper';
import { TOutpaintData } from '@/providers/ai-scene/outpaint/store';
import { fabric } from 'fabric';

interface AiSceneResultCanvasProps {
  children?: ReactNode;
  canvasData: TOutpaintData;
  options: TCanvasOptions;
  onCanvasRendered: (canvasInstance: fabric.Canvas) => void;
  canvasSize?: number;
}

export const OutpaintCanvas: FC<AiSceneResultCanvasProps> = ({
  canvasData,
  options,
  onCanvasRendered,
  canvasSize,
}) => {
  const { fabricCanvasRef, canvasElement } = useFabricCanvas(undefined, options);
  const isMounted = useRef(false);

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || isMounted.current) return;

    isMounted.current = true;

    fabricRef.originalDimensions = { width: canvasData.width, height: canvasData.height };
    const displayDimensions = calculateDisplayDimensions(
      canvasData.width,
      canvasData.height,
      options.dimensions?.height,
    );
    fabricRef.setDimensions({
      width: displayDimensions.width,
      height: displayDimensions.height,
    });

    fabricRef.setBackgroundColor('#F6D0C3', fabricRef.renderAll.bind(fabricRef));

    const drawGridPattern = () => {
      const gridSize = displayDimensions.width / 4;
      const gridPatternCanvas = document.createElement('canvas');
      gridPatternCanvas.width = gridPatternCanvas.height = gridSize;

      const gridPatternCtx = gridPatternCanvas.getContext('2d');
      if (gridPatternCtx) {
        gridPatternCtx.strokeStyle = '#D9D9D9';
        gridPatternCtx.lineWidth = 2;
        gridPatternCtx.beginPath();
        gridPatternCtx.moveTo(gridSize, 0);
        gridPatternCtx.lineTo(gridSize, gridSize);
        gridPatternCtx.moveTo(0, gridSize);
        gridPatternCtx.lineTo(gridSize, gridSize);
        gridPatternCtx.stroke();
      }

      const gridPatternImage = new Image();
      gridPatternImage.src = gridPatternCanvas.toDataURL('image/png');
      gridPatternImage.onload = () => {
        const gridPattern = new fabric.Pattern({
          source: gridPatternImage,
          repeat: 'repeat',
        });

        const backgroundRect = new fabric.Rect({
          left: 0,
          top: 0,
          width: fabricRef.width,
          height: fabricRef.height,
          fill: gridPattern,
          selectable: false,
          evented: false,
        });

        fabricRef.add(backgroundRect);
        backgroundRect.bringToFront();
        fabricRef.requestRenderAll();
      };
    };

    const drawImage = async () => {
      try {
        const {
          id,
          type,
          image: imageObject,
        } = await parseImageWithFabric({
          id: canvasData.id,
          image: canvasData.url,
          type: 'element',
          maxHeight: fabricRef.getHeight(),
          maxWidth: fabricRef.getWidth(),
          placementBuffer: 0,
        });
        centerImageInCanvas(imageObject, fabricRef);

        imageObject.setControlsVisibility({
          mtr: false,
        });
        imageObject.set({
          lockRotation: true,
          hasRotatingPoint: false,
        });

        imageObject.on('scaling', () => {
          const scale = Math.max(imageObject.scaleX ?? 1, imageObject.scaleY ?? 1);
          imageObject.set({
            scaleX: scale,
            scaleY: scale,
          });
        });

        fabricRef.add(imageObject);
        fabricRef.requestRenderAll();
        onCanvasRendered(fabricRef);

        drawGridPattern();
      } catch (error) {
        console.error(error);
      }
    };

    drawImage();
  }, [fabricCanvasRef, canvasData, onCanvasRendered]);

  return <div className='border border-crait-red'>{canvasElement}</div>;
};
