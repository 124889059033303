import { FC, ReactNode, useRef } from 'react';
import { createAiSceneStore } from './store';
import { AiSceneCreateContext, TAiSceneContext } from './helper';

interface AiSceneCreateProviderProps {
  children: ReactNode;
}

export const AiSceneCreateProvider: FC<AiSceneCreateProviderProps> = ({ children }) => {
  const storeRef = useRef<TAiSceneContext>();

  if (!storeRef.current) {
    storeRef.current = createAiSceneStore();
  }

  return (
    <AiSceneCreateContext.Provider value={storeRef.current}>
      {children}
    </AiSceneCreateContext.Provider>
  );
};
