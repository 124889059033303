import { ColumnDef } from '@tanstack/react-table';

import { TableCell } from './TableCell';
import { EditCell } from './EditCell';
import { getRandomImg } from '@/utils/helper';
import { Member } from 'pages/dashboard/people/helper';
import { format } from 'date-fns';

export const emailRegexPattern = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const AUTHORITY_OPTIONS = [
  { label: 'Normal', value: 'normal' },
  { label: 'Admin', value: 'admin' },
];

export const tableColumns: ColumnDef<Member>[] = [
  {
    accessorKey: 'avatar',
    header: '',
    size: 70,
    cell: ({ row }) => (
      <img
        crossOrigin='anonymous'
        src={row.original.avatar}
        className='size-9 rounded-full border border-black p-0.5'
      />
    ),
  },
  {
    accessorKey: 'user_name',
    header: 'Username',
    cell: TableCell,
    size: 180,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: 200,
    cell: TableCell,
  },
  {
    accessorKey: 'title',
    header: 'Title',
    size: 180,
    cell: TableCell,
    meta: {
      property: {
        type: 'text',
      },
    },
  },
  {
    accessorKey: 'authority',
    header: 'Authority',
    size: 180,
    cell: TableCell,
    meta: {
      property: {
        type: 'select',
        options: AUTHORITY_OPTIONS,
      },
    },
  },
  {
    accessorKey: 'participation_timestamp',
    header: 'Created At',
    cell: ({ row }) => {
      if (typeof row.original.participation_timestamp !== 'number') return null;

      const date = new Date(row.original.participation_timestamp * 1000);
      const formattedDate = format(date, 'dd.MM.yyyy');
      return <span>{formattedDate}</span>;
    },
    size: 100,
  },
  {
    accessorKey: 'edit',
    size: 120,
    header: 'Edit',
    cell: EditCell,
  },
];

const getRandomEmail = () => {
  const names = ['John', 'Jane', 'Jack', 'Jill', 'Jim', 'Jenny', 'Joe', 'Jenny'];
  const domains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
  const randomName = names[Math.floor(Math.random() * names.length)];
  const randomDomain = domains[Math.floor(Math.random() * domains.length)];
  return `${randomName}@${randomDomain}`;
};

const getRandomAuthority = () => {
  const authorities = ['Viewer', 'Editor', 'Admin'];
  const randomAuthority = authorities[Math.floor(Math.random() * authorities.length)];
  return randomAuthority as Member['authority'];
};

const getRandomTitle = () => {
  const titles = ['Manager', 'Photography', 'Team Lead', 'Intern', 'CEO', 'CTO'];
  const randomTitle = titles[Math.floor(Math.random() * titles.length)];
  return randomTitle;
};

const getRandomUsername = () => {
  const names = ['John', 'Jane', 'Jack', 'Jill', 'Jim', 'Jenny', 'Joe', 'Jenny'];
  const randomName = names[Math.floor(Math.random() * names.length)];
  return randomName;
};

export const makeRandomUserData = (size: number) => {
  const data = [];
  for (let i = 0; i < size; i++) {
    data.push({
      id: `${i}`,
      user_name: getRandomUsername(),
      title: getRandomTitle(),
      authority: getRandomAuthority(),
      email: getRandomEmail(),
      avatar: getRandomImg(40, 7),
      participation_timestamp: '03.03.2023',
    });
  }
  return data;
};
