export const moveCursorToEndOfContent = (editor: HTMLElement) => {
  const selection = window.getSelection();
  if (!selection) return;

  // Check if the last child of the contentEditable is a text node or an element
  const lastChild = editor.lastChild;
  if (!lastChild) {
    // If there are no children, simply set the range to the start of the contentEditable element
    ensureSelectionWithinElement(selection, editor);
    return;
  }

  if (lastChild.nodeType === Node.TEXT_NODE) {
    // If the last child is a text node, use it directly to set the end of the range
    moveCursorAfterNode(selection, lastChild);
  } else if (lastChild.nodeType === Node.ELEMENT_NODE && lastChild.textContent !== '') {
    addZeroWidthSpaceAfterSpan(lastChild as HTMLSpanElement);
    // If the last child is an element with text, move the cursor to its end
    moveCursorAfterNode(selection, lastChild);
  } else {
    // Append a zero-width space to ensure the cursor is visible at the end
    appendZeroWidthSpaceAndMoveCursor(editor, selection);
  }
};

const appendZeroWidthSpaceAndMoveCursor = (editor: HTMLElement, selection: Selection) => {
  const zeroWidthSpace = document.createTextNode('\u200B');
  editor.appendChild(zeroWidthSpace);
  moveCursorAfterNode(selection, zeroWidthSpace);
};

export const ensureSelectionWithinElement = (selection: Selection, element: HTMLElement) => {
  let isInside = false;
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    let container: Node | null = range.commonAncestorContainer;
    while (container) {
      if (container === element) {
        isInside = true;
        break;
      }
      container = container.parentNode;
    }
  }

  if (!isInside) {
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const moveCursorAfterSpan = (selection: Selection, className: string) => {
  const range = selection.getRangeAt(0);
  if (
    range.startContainer.parentNode instanceof HTMLElement &&
    range.startContainer.parentNode.className === className
  ) {
    moveCursorAfterNode(selection, range.startContainer.parentNode);
  }
};

export const createAndInsertSpan = (selection: Selection, spanText: string, className: string) => {
  const span = createSpan(spanText, className);
  const range = selection.getRangeAt(0);
  range.insertNode(span);
  addZeroWidthSpaceAfterSpan(span);
  moveCursorAfterNode(selection, span);
};

export const createSpan = (spanText: string, className: string): HTMLSpanElement => {
  const outerSpan = document.createElement('span');
  outerSpan.textContent = spanText;
  outerSpan.className = className;
  outerSpan.setAttribute('contenteditable', 'false');
  outerSpan.innerHTML = `
  <span>${spanText}</span>
  <span className='i-mdi-window-close'>x</span>
`;

  outerSpan.addEventListener('click', () => {
    const parentNode = outerSpan.parentNode;
    if (parentNode) parentNode.removeChild(outerSpan);
  });
  return outerSpan;
};

const moveCursorAfterNode = (selection: Selection, node: Node) => {
  const range = document.createRange();
  range.setStartAfter(node);
  range.setEndAfter(node);
  selection.removeAllRanges();
  selection.addRange(range);
};

export const addZeroWidthSpaceAfterSpan = (span: HTMLSpanElement) => {
  const parentNode = span.parentNode;
  if (!parentNode) return;

  const zeroWidthSpace = document.createTextNode('\u200B');
  if (span.nextSibling) {
    parentNode.insertBefore(zeroWidthSpace, span.nextSibling);
  } else {
    parentNode.appendChild(zeroWidthSpace);
  }

  const selection = window.getSelection();
  if (selection) {
    moveCursorAfterNode(selection, zeroWidthSpace);
  }
};

export const getElementObjectOptions = (objects: fabric.Object[]) => {
  return objects
    .filter((obj) => obj.imageType === 'element')
    .map((obj) => ({
      id: obj.imageId as string,
      position: { x: obj.left || 0, y: obj.top || 0 },
      scale: { x: obj.scaleX || 1, y: obj.scaleY || 1 },
      flip: { x: obj.flipX || false, y: obj.flipY || false },
      rotation: obj.angle || 0,
    }));
};
