import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useEffect, useMemo, useState } from 'react';
import { serializeCanvas } from '@/utils/helper';

const PROMPT_TAG_CL =
  'text-[11px] leading-none gap-1 inline flex-wrap text-wrap whitespace-pre-line';
const PROMPT_PREFIX_CL =
  'relative z-[11] border border-black cursor-pointer flex gap-1 items-center justify-center rounded-md h-5 whitespace-pre-line px-2 py-1 mr-1 mb-0.5 inline-flex leading-none z-10 cursor-pointer';

interface DrawnProductPrefixesProps {
  setProductsCount: (count: number) => void;
}

export const DrawnProductPrefixes: React.FC<DrawnProductPrefixesProps> = ({ setProductsCount }) => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  const drawnProducts = useMemo(() => {
    const productObjects =
      canvasInstance
        ?.getObjects()
        .filter((obj) => obj.imageType === 'product' && 'imageName' in obj) || [];
    const uniqueObjectsMap = productObjects.reduce(
      (acc, obj) => {
        if (!obj.imageId) return acc;
        acc[obj.imageId] = {
          imageId: obj.imageId,
          imageName: obj.imageName as string,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          imageId: string;
          imageName: string;
        }
      >,
    );
    const uniqueObjects = Object.values(uniqueObjectsMap);
    return uniqueObjects;
  }, [canvasInstance]);
  const [savedProducts, setSavedProducts] = useState(drawnProducts);

  useEffect(() => {
    setProductsCount(savedProducts.length);
  });

  useEffect(() => {
    if (!canvasInstance) return;

    const handleProductRemoved = (event: fabric.IEvent) => {
      if (!event.target || event.target.imageType !== 'product' || !('imageName' in event.target)) {
        return;
      }

      const imageId = event.target.imageId;
      if (!imageId) return;

      const hasAnotherObjectWithSameImageId = canvasInstance
        .getObjects()
        .some((obj) => obj.imageId === imageId);
      if (hasAnotherObjectWithSameImageId) return;

      setSavedProducts((prev) => prev.filter((p) => p.imageId !== imageId));
    };

    const handleProductAdded = (event: fabric.IEvent) => {
      if (!event.target || event.target.imageType !== 'product' || !('imageName' in event.target)) {
        return;
      }
      const createdObject = event.target;
      if (!createdObject.imageId) return;

      setSavedProducts((prev) => {
        const isAlreadySaved = prev.some((p) => p.imageId === createdObject.imageId);
        if (isAlreadySaved) return prev;

        return [
          ...prev,
          {
            imageId: createdObject.imageId as string,
            imageName: createdObject.imageName || 'unnamed',
          },
        ];
      });
    };

    canvasInstance.on('object:added', handleProductAdded);
    canvasInstance.on('object:removed', handleProductRemoved);
    return () => {
      canvasInstance.off('object:removed', handleProductRemoved);
      canvasInstance.off('object:added', handleProductAdded);
    };
  }, [canvasInstance]);

  return (
    <span className={PROMPT_TAG_CL}>
      {savedProducts.map((product) => (
        <span
          key={product.imageId}
          className={PROMPT_PREFIX_CL}
          onClick={() => {
            if (!canvasInstance) return;

            const productObjectsBeforeRemoval = canvasInstance
              .getObjects()
              .filter((obj) => obj.imageType === 'product' && 'imageName' in obj);
            const currentObject = productObjectsBeforeRemoval.find(
              (obj) => obj.imageId === product.imageId,
            );
            if (!currentObject) return;

            canvasInstance.remove(currentObject);
            canvasInstance.requestRenderAll();
            updateCanvasState(serializeCanvas(canvasInstance));
          }}
        >
          <span className='max-w-[120px] truncate'>{product.imageName}</span>
          <span className='i-mdi-window-close'>x</span>
        </span>
      ))}
    </span>
  );
};
