import { FC } from 'react';

import { useUploadColorMutation } from '@/components/workspace/brand-library/brand-kit/queries';
import { Button } from 'components/ui/button';
import { ColorDropdown } from './ColorDropdown';
import { Plus } from 'lucide-react';

interface CreateColorDropdownWrapper {
  refetch: () => void;
}
export const CreateColorDropdownWrapper: FC<CreateColorDropdownWrapper> = ({ refetch }) => {
  const uploadColorMutation = useUploadColorMutation();

  return (
    <div className='flex flex-col gap-2 rounded-md p-2'>
      <ColorDropdown
        onSaveClicked={async (params) => {
          uploadColorMutation.mutate(params, {
            onSuccess: () => {
              refetch();
            },
          });
        }}
        refetch={refetch}
      >
        <div className='flex flex-col justify-center gap-[4px] rounded-md'>
          <Button variant='outline' className=' h-[54px] w-[54px] rounded-lg border-[#EAEAEA]'>
            <Plus size={16} color='#777777' />
          </Button>

          <span className='text-[12px] font-normal'>Add New</span>
        </div>
      </ColorDropdown>
    </div>
  );
};
