import { useEffect, useState } from 'react';
import { FileUploadInput } from 'components/workspace/brand-library/products/upload/FileUploadInput';
import { toast } from 'sonner';
import { Progress } from 'components/ui/progress';
import {
  GET_LOGOS_QUERY_KEY,
  useUploadLogoMutation,
} from 'components/workspace/brand-library/brand-kit/queries';
import { useQueryClient } from '@tanstack/react-query';
import { getFileNameWithoutExtension } from '@/utils/helper';

interface QuickUploadLogoProps {}

const getRandomIncrement = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const ProgressComponent = ({ className }: { className?: string }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 95) {
          clearInterval(interval);
          return 95;
        }
        return prev + 1;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className={`mx-auto flex h-[128px] w-full flex-col items-center justify-center gap-3 rounded-lg border-2 border-dashed border-[#EAEAEA] bg-[#F5F5F5] px-3 ${className}`}
    >
      <Progress value={progress} className='h-[3px] rounded-none bg-white' />
    </div>
  );
};

export const QuickUploadLogo: React.FC<QuickUploadLogoProps> = () => {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadLogo = useUploadLogoMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (file) {
      uploadSingleLogo(file);
    }
  }, [file]);

  const uploadSingleLogo = async (file: File) => {
    try {
      setIsSubmitting(true);
      if (!file) {
        toast.error('Please select an image and enter a name');
        return;
      }

      await uploadLogo.mutateAsync({ image: file, name: getFileNameWithoutExtension(file.name) });
      queryClient.invalidateQueries({
        queryKey: [{ _name: GET_LOGOS_QUERY_KEY }],
        exact: false,
      });
      setIsSubmitting(false);
    } catch (_) {
      toast.error('Failed to upload logo');
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return <ProgressComponent />;
  }

  return (
    <>
      <FileUploadInput
        onFileUpload={setFile}
        titleElement=' '
        containerClass='w-full'
        className='w-full'
      />
    </>
  );
};
