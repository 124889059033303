import { motion, AnimatePresence } from 'framer-motion';
import { FC } from 'react';

type AnimatedCraitImageProps = Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'className'>;

export const AnimatedCraitImage: FC<AnimatedCraitImageProps> = ({ ...props }) => {
  return (
    <AnimatePresence>
      <motion.img
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        {...props}
      />
    </AnimatePresence>
  );
};
