import { FC } from 'react';
import { ColorModal } from './ColorModal';
import { TColorResponse, useUpdateColorMutation } from '../queries';

interface EditColorModalWrapperProps {
  refetch: () => void;
}

export const EditColorModalWrapper: FC<TColorResponse & EditColorModalWrapperProps> = ({
  refetch,
  ...colorProps
}) => {
  const updateColorMutation = useUpdateColorMutation();

  return (
    <ColorModal
      {...colorProps}
      title='Edit Color'
      onSaveClicked={async (params) => {
        await updateColorMutation.mutateAsync({
          id: colorProps.id,
          ...params,
        });
        refetch();
      }}
    >
      <div className='flex cursor-pointer flex-col justify-center gap-1'>
        <span
          className='relative m-auto size-[54px]  gap-2 overflow-hidden rounded-[8px]'
          style={{ backgroundColor: colorProps.hexCode }}
        />
        <div className='flex w-[65px] items-center justify-center gap-1'>
          <span className='overflow-hidden overflow-ellipsis whitespace-nowrap text-[12px] font-light uppercase'>
            {colorProps.name || colorProps.hexCode}
          </span>
        </div>
      </div>
    </ColorModal>
  );
};
