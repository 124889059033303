import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import {
  TTemplateResponse,
  useGetSearchResults,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';

interface TemplateProps {
  onlyOrgLibrary: boolean;
}
export const Template = ({ onlyOrgLibrary }: TemplateProps) => {
  const [keyword, setKeyword] = useDebounceValue('', 300);
  const { data, isError, isLoading, refetch } = useGetSearchResults<TTemplateResponse>({
    keyword,
    pageId: 0,
    searchSection: 'templates',
    staleTime: 30 * 1000,
    onlyOrgLibrary,
  });
  const templates = useBannerCreateStore((s) => s.templates);
  const addTemplate = useBannerCreateStore((s) => s.addTemplate);
  const removeTemplate = useBannerCreateStore((s) => s.removeTemplate);

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load templates.</span>
    </RefetchView>
  );

  const templateItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.templates?.items || data.templates.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No templates found.</span>
        </RefetchView>
      );
    }

    return data.templates.items.map((template) => {
      const isSelected = templates.some((t) => t.id === template.id);
      return (
        <div
          key={template.id}
          onClick={() => {
            if (isSelected) {
              removeTemplate(template.id);
            } else {
              addTemplate(template);
            }
          }}
          className={`hover:border-1 flex  cursor-pointer select-none flex-col items-center gap-2.5 rounded-lg p-2 hover:outline hover:outline-crait-blue ${
            isSelected ? 'bg-crait-blue opacity-70' : ''
          }`}
        >
          <div className='relative flex h-[160px] w-full items-center gap-2 overflow-hidden rounded-lg'>
            <img crossOrigin='anonymous' src={template.thumbnail} className='object-cover' />
          </div>
          <p className='line-clamp-1 text-ellipsis'>{template.name}</p>
        </div>
      );
    });
  }, [data, isLoading, refetch, templates, addTemplate, removeTemplate]);

  const filteredSelectedTemplates = templates.filter(
    (t) => t.isOrganizationAsset === onlyOrgLibrary,
  );

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{filteredSelectedTemplates.length} Selected</span>
        <Command className='flex h-10 w-60 justify-center rounded-lg  border border-[#6b8084bb] bg-transparent'>
          <CommandInput placeholder='Search' onValueChange={setKeyword} />
        </Command>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='grid grid-cols-3 gap-4 p-4'>{errorView || templateItems}</div>
      </ScrollArea>
    </div>
  );
};
