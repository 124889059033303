import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import React, { FC, ReactNode } from 'react';

interface TooltipItemProps {
  trigger: ReactNode;
  children: ReactNode;
}

export const TooltipItem: FC<
  TooltipItemProps & React.ComponentPropsWithoutRef<typeof TooltipContent>
> = ({ trigger, children, ...props }, ref) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{trigger}</TooltipTrigger>
        <TooltipContent side='right' {...props}>
          {children}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
