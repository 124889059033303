import { ModalView } from 'components/common/ModalView';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { ExcelUploadView } from './ExcelUploadView';
import { FileUploadView } from './FileUploadView';
import { UrlUploadView } from './UrlUploadView';
import { CsvUploadView } from './CsvUploadView';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { useAccountStatistics } from 'pages/dashboard/profile/helper';
import { getNextPlanType } from 'components/plan-guard/helper';
import UpgradeIcon from '@/assets/icons/upgareWarning.svg?react';
import { getValueOrFallback } from 'components/dashboard/profile/helper';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

const UploadMethods = {
  IMAGE: 'image',
  EXCEL: 'excel',
  URL: 'url',
  CSV: 'csv',
} as const;

const TAB_OPTIONS = [
  { label: 'Image File', value: UploadMethods.IMAGE, component: FileUploadView },
  { label: 'URL', value: UploadMethods.URL, component: UrlUploadView },
  { label: 'Excel', value: UploadMethods.EXCEL, component: ExcelUploadView },
  { label: 'CSV', value: UploadMethods.CSV, component: CsvUploadView },
] as const;

const UploadTabs = ({ closeModal }: { closeModal: () => void }) => (
  <Tabs defaultValue={UploadMethods.IMAGE} className='h-full w-full rounded-2xl'>
    <TabsList className='w-full gap-2 rounded-2xl bg-transparent p-0'>
      {TAB_OPTIONS.map((tab) => (
        <Button key={tab.value} value={tab.value} variant='outline' asChild>
          <TabsTrigger value={tab.value}>{tab.label}</TabsTrigger>
        </Button>
      ))}
    </TabsList>
    {TAB_OPTIONS.map(({ value, component: Component }) => (
      <TabsContent key={value} value={value} className='h-full w-full pt-2'>
        <Component>
          <Button variant='link' className='w-full' onClick={closeModal}>
            Cancel
          </Button>
        </Component>
      </TabsContent>
    ))}
  </Tabs>
);

export const AddProductModal = () => {
  const { data } = useAccountStatistics({
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const { width } = useWindowSize();
  const isDesktop = width > Breakpoints.TABLET;
  if (!data) return null;

  const productsRemaining = getValueOrFallback(data.products.remaining, 100);
  const requiredPlan = productsRemaining > 0 ? data.plan : getNextPlanType(data.plan);
  const isTrialPeriodActive = data.trial_period !== null;

  const checkPlanAndProceed = usePlanCheck({
    requiredPlan,
    onClick: () => {},
    featureDescription: FeatureAccessRequirements.ADD_PRODUCTS.featureDescription,
    usePreviousPlan: isTrialPeriodActive,
  });

  const TriggerButton = (
    <Button
      className='text-dark h-[40px] w-full items-center gap-2 rounded-lg bg-crait-green-primary'
      onClick={checkPlanAndProceed}
    >
      {productsRemaining < 1 ? (
        <UpgradeIcon />
      ) : (
        <span className='i-mdi-progress-upload h-[18px] w-[18px]'></span>
      )}
      <span className='text-sm'>Upload new product</span>
    </Button>
  );

  const ModalContent = ({ closeModal }: { closeModal: () => void }) => (
    <>
      <Separator className='mb-4' />
      <div className='flex flex-col items-center gap-6'>
        <UploadTabs closeModal={closeModal} />
      </div>
    </>
  );

  return (
    <>
      {isDesktop ? (
        <ModalView
          trigger={TriggerButton}
          titleElement={<div className='text-lg font-medium'>Upload New Products</div>}
        >
          {ModalContent}
        </ModalView>
      ) : (
        <MobileMenuSheet
          triggerButton={TriggerButton}
          titleElement='Upload New Products'
          containerClass='w-full'
        >
          {ModalContent}
        </MobileMenuSheet>
      )}
    </>
  );
};
