import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import {
  ElementEndpoint,
  ProductEndpoint,
  SearchEndpoint,
  StyleEndpoint,
  TemplateEndpoint,
} from '@/utils/fetch/constants';
import {
  getElementsApiUrl,
  getProductsApiUrl,
  getSearchApiUrl,
  getStylesApiUrl,
  getTemplatesApiUrl,
} from '@/utils/fetch/helper';
import {
  infiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { ProductProperties } from 'pages/workspace/brand-library/products/helper';

export type TStyleResponse = {
  id: string;
  name: string;
  thumbnail: string;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  isOrganizationAsset: boolean;
  inLibrary: boolean;
};

const getStyles = async (
  organizationId: number,
  pageId = 0,
): Promise<{
  styles: TStyleResponse[];
}> => {
  const baseUrl = getStylesApiUrl(organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}?page=${pageId}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useGetStyles = (pageId?: number) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: StyleEndpoint.BASE, organizationId, pageId }],
    queryFn: ({ queryKey }) => {
      return getStyles(queryKey[0].organizationId, queryKey[0].pageId);
    },
    placeholderData: {
      styles: [],
    },
  });
  return result;
};

export type TPaginatedResponse<T> = {
  itemCount: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  items: T[];
};

export type TBannerResponse = {
  id: string;
  name: string;
  thumbnail: string;
  last_update_info: { timestamp: number; user_name: string };
  url: string;
};

export type TElementResponse = {
  id: string;
  name: string;
  url: string;
  thumbnail: string;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  category: {
    id: string;
    name: string;
  };
  isOrganizationAsset: boolean;
  inLibrary: boolean;
};

type TCategoryResponse = {
  id: string;
  name: string;
};

export const convertArrayToQueryParam = ({
  prefix,
  paramKey,
  values,
}: {
  prefix: '?' | '&';
  paramKey: string;
  values: string[];
}) => {
  const query = values.map((value) => `${paramKey}=${value}`).join('&');
  return `${prefix}${query}`;
};

type TGetElementsPayload = {
  organizationId: number;
  pageId?: number;
  categoryIds?: string[];
};
const getElements = async ({
  pageId = 0,
  categoryIds,
  organizationId,
}: TGetElementsPayload): Promise<{
  elements: TElementResponse[];
  categories: TCategoryResponse[];
}> => {
  const baseUrl = getElementsApiUrl(organizationId, ElementEndpoint.BASE);
  const categoryParams =
    categoryIds && categoryIds.length > 0
      ? convertArrayToQueryParam({ paramKey: 'categoryId', values: categoryIds, prefix: '&' })
      : '';
  const url = `${baseUrl}?page=${pageId}${categoryParams}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useGetElements = ({
  pageId,
  categoryIds,
}: Omit<TGetElementsPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useQuery({
    queryKey: [{ url: ElementEndpoint.BASE, organizationId, categoryIds, pageId }],
    queryFn: ({ queryKey }) => {
      return getElements(queryKey[0]);
    },
    placeholderData: {
      elements: [],
      categories: [],
    },
  });
  return result;
};

type TElementCategoryResponse = {
  id: string;
  name: string;
};

const getElementCategories = async (
  organizationId: number,
): Promise<TElementCategoryResponse[]> => {
  const url = getSearchApiUrl(organizationId, SearchEndpoint.ELEMENT_CATEGORIES);
  const response = await apiClient.get(url);
  return response.data;
};

export const useGetElementCategories = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useQuery({
    queryKey: [{ url: SearchEndpoint.ELEMENT_CATEGORIES, organizationId }],
    queryFn: ({ queryKey }) => {
      return getElementCategories(queryKey[0].organizationId);
    },
    placeholderData: (prev) => prev,
  });
  return result;
};

export type TProductResponse = {
  id: string;
  name: string;
  image: {
    original: string;
    transparent: string;
  };
  thumbnail: {
    original: string;
    transparent: string;
  };
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  product_code: string;
  category: {
    id: string;
    name: string;
  };
  properties?: ProductProperties | null;
};

type TSearchResponseKeys =
  | Exclude<TSearchSection, 'ai_scenes' | 'saved_settings'>
  | 'aiScenes'
  | 'savedSettings';

export type TSearchResponse<T> = {
  keyword: string;
} & Record<TSearchResponseKeys, TSearchResponseItem<T>>;

export type TSearchResponseItem<T> = {
  categories?: TCategoryResponse[];
} & TPaginatedResponse<T>;

export type TSearchSection =
  | 'projects'
  | 'products'
  | 'styles'
  | 'elements'
  | 'templates'
  | 'brandkit'
  | 'banners'
  | 'ai_scenes'
  | 'saved_settings';

type TGetSearchPayload = {
  organizationId: number;
  searchSection: TSearchSection;
  keyword?: string;
  productCategoryId?: string;
  elementCategoryId?: string;
  onlyMyProjects?: boolean;
  onlyOrgLibrary?: boolean;
  onlyFavorites?: boolean;
  pageId?: number;
};

export const getSearchResults = async <T,>(
  props: TGetSearchPayload,
  pageParam?: number,
): Promise<TSearchResponse<T>> => {
  const url = getSearchApiUrl(props.organizationId, SearchEndpoint.BASE);
  const queryParams = new URLSearchParams();
  if (props.searchSection) {
    queryParams.append('searchSection', props.searchSection);
  }
  if (props.keyword) {
    queryParams.append('keyword', props.keyword);
  }
  if (props.productCategoryId) {
    queryParams.append('productCategoryId', props.productCategoryId);
  }
  if (props.elementCategoryId) {
    queryParams.append('elementCategoryId', props.elementCategoryId);
  }
  if (props.onlyMyProjects) {
    queryParams.append('onlyMyProjects', String(props.onlyMyProjects));
  }
  if (props.onlyOrgLibrary) {
    queryParams.append('onlyOrgLibrary', 'true');
  }
  if (pageParam) {
    queryParams.append('pageId', pageParam.toString());
  }
  if (props.pageId) {
    queryParams.append('pageId', props.pageId.toString());
  }
  if (props.onlyFavorites) {
    queryParams.append('onlyFavorites', 'true');
  }

  const response = await apiClient.get(`${url}?${queryParams}`);
  return response.data;
};

export const useGetSearchResults = <T,>(
  props: Omit<TGetSearchPayload, 'organizationId'> & {
    staleTime?: number;
  },
) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { staleTime, ...queryPayload } = props;

  const result = useQuery({
    queryKey: [{ ...queryPayload, organizationId, url: SearchEndpoint.BASE }],
    queryFn: ({ queryKey }) => {
      return getSearchResults<T>(queryKey[0]);
    },
    placeholderData: (prev) => prev,
    ...(typeof staleTime !== 'undefined' && { staleTime }),
  });
  return result;
};

export const useGetSearchResultsInfinite = <T,>(
  props: Omit<TGetSearchPayload, 'organizationId'> & {
    staleTime?: number;
  },
) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { staleTime, ...queryPayload } = props;

  const result = useInfiniteQuery({
    queryKey: [{ ...queryPayload, organizationId, url: SearchEndpoint.BASE }],
    queryFn: ({ queryKey, pageParam }) => {
      return getSearchResults<T>(queryKey[0], pageParam);
    },

    getNextPageParam: (lastPage, allPages, pageParams) => {
      return lastPage.projects.currentPage < lastPage.projects.totalPages
        ? pageParams + 1
        : undefined;
    },
    initialPageParam: 0,
    ...(typeof staleTime !== 'undefined' && { staleTime }),
  });
  return result;
};

export const INFINITE_QUERY_TYPE_KEY = { queryType: 'infinite' } as const;

export const useGetInfiniteSearchResults = <T,>(
  props: Omit<TGetSearchPayload, 'organizationId'> & {
    staleTime?: number;
    nextPageParam: (lastPage: TSearchResponse<T>) => number | undefined;
    refetchOnMount?: boolean;
  },
) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { nextPageParam, staleTime, ...queryPayload } = props;

  const result = useInfiniteQuery({
    queryKey: [
      { ...queryPayload, organizationId, url: SearchEndpoint.BASE, ...INFINITE_QUERY_TYPE_KEY },
    ],
    queryFn: ({ queryKey, pageParam }) => {
      return getSearchResults<T>(queryKey[0], pageParam);
    },
    getNextPageParam: nextPageParam,
    placeholderData: (prev) => prev,
    initialPageParam: 0,
    refetchOnMount: props.refetchOnMount ?? false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...(typeof staleTime !== 'undefined' && { staleTime }),
  });
  return result;
};

export const getInfiniteSearchOptions = <T,>(props: TGetSearchPayload) => {
  return infiniteQueryOptions({
    queryKey: [{ url: SearchEndpoint.BASE, ...props }],
    queryFn: async ({ queryKey, pageParam = 0 }) => {
      return getSearchResults<T>({
        ...queryKey[0],
        pageId: pageParam,
      });
    },
    placeholderData: (prev) => prev,
    initialPageParam: 0,
    staleTime: 30 * 1000,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.projects;
      return currentPage < totalPages ? currentPage : undefined;
    },
  });
};

export type TTemplateResponse = {
  id: string;
  name: string;
  thumbnail: string;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  url: string;
  isOrganizationAsset: boolean;
  inLibrary: boolean;
};

type TGetTemplatesPayload = {
  organizationId: number;
  pageId?: number;
};
const getTemplates = async ({
  pageId = 0,
  organizationId,
}: TGetTemplatesPayload): Promise<{
  templates: TTemplateResponse[];
}> => {
  const baseUrl = getTemplatesApiUrl(organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}?page=${pageId}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useGetTemplates = ({ pageId }: Omit<TGetElementsPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useQuery({
    queryKey: [{ url: TemplateEndpoint.BASE, organizationId, pageId }],
    queryFn: ({ queryKey }) => {
      return getTemplates(queryKey[0]);
    },
    placeholderData: {
      templates: [],
    },
  });
  return result;
};

type TProductCategoryResponse = {
  id: string;
  name: string;
  productCount: 0;
};

const getProductCategories = async (
  organizationId: number,
): Promise<TProductCategoryResponse[]> => {
  const url = getProductsApiUrl(organizationId, ProductEndpoint.CATEGORIES);
  const response = await apiClient.get<TProductCategoryResponse[]>(url);
  return response.data;
};

export const GET_PRODUCT_CATEGORIES_QUERY_KEY = 'getProductCategoriesQueryKey';

export const useGetProductCategories = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useQuery({
    queryKey: [
      { url: ProductEndpoint.CATEGORIES, organizationId, _name: GET_PRODUCT_CATEGORIES_QUERY_KEY },
    ],
    queryFn: ({ queryKey }) => {
      return getProductCategories(queryKey[0].organizationId);
    },
    placeholderData: (prev) => prev,
  });
  return result;
};

const createProductCategory = async (
  organizationId: number,
  name: string,
): Promise<TProductCategoryResponse> => {
  const url = getProductsApiUrl(organizationId, ProductEndpoint.CATEGORIES);
  const response = await apiClient.post<TProductCategoryResponse>(url, { name });
  return response.data;
};

export const useCreateProductCategoryMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useMutation({
    mutationKey: [ProductEndpoint.CATEGORIES],
    mutationFn: (name: string) => {
      return createProductCategory(organizationId, name);
    },
  });
  return result;
};

type ToggleLibrary = {
  id: string;
  organizationId: number;
  action: 'add' | 'remove';
};

export const toggleStyleLibrary = async ({
  id,
  organizationId,
  action,
}: ToggleLibrary): Promise<TStyleResponse> => {
  const baseUrl = getStylesApiUrl(organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}/${id}/add-to-library?action=${action}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useToggleStyleLibraryMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [StyleEndpoint.BASE, 'add-style-to-library-mutation'],
    mutationFn: (props: Omit<ToggleLibrary, 'organizationId'>) => {
      return toggleStyleLibrary({ organizationId, ...props });
    },
  });
};

export const toggleElementLibrary = async ({
  id,
  organizationId,
  action,
}: ToggleLibrary): Promise<TElementResponse> => {
  const baseUrl = getElementsApiUrl(organizationId, ElementEndpoint.BASE);
  const url = `${baseUrl}/${id}/add-to-library?action=${action}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useToggleElementLibraryMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ElementEndpoint.BASE, 'add-element-to-library-mutation'],
    mutationFn: (props: Omit<ToggleLibrary, 'organizationId'>) => {
      return toggleElementLibrary({ organizationId, ...props });
    },
  });
};

export const toggleTemplateLibrary = async ({
  id,
  organizationId,
  action,
}: ToggleLibrary): Promise<TTemplateResponse> => {
  const baseUrl = getTemplatesApiUrl(organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}/${id}/add-to-library?action=${action}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const useToggleTemplateLibraryMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [TemplateEndpoint.BASE, 'add-template-to-library-mutation'],
    mutationFn: (props: Omit<ToggleLibrary, 'organizationId'>) => {
      return toggleTemplateLibrary({ organizationId, ...props });
    },
  });
};
