import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { TAiSceneOutpaintStore } from './store';
import { AiSceneOutpaintContext } from './helper';

export const useAiSceneOutpaintStore = <T,>(
  selector: (state: TAiSceneOutpaintStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(AiSceneOutpaintContext);
  if (!store) throw new Error('Missing AiSceneResultContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
