import { useState } from 'react';
import { EditTool } from './EditTool';
import { MagicEraseMenu } from './magic-erase/MagicEraseMenu';
import { MagicEditMenu } from './magic-edit/MagicEditMenu';
import { RestoreMenu } from './restore/RestoreMenu';

import MagicEraseImage from 'src/assets/images/edit-menu/magicErase.png';
import MagicEditImage from 'src/assets/images/edit-menu/magicEdit.png';
import RestoreImage from 'src/assets/images/edit-menu/restore.png';
import ExpandImage from 'src/assets/images/edit-menu/expand.png';
import CropImage from 'src/assets/images/edit-menu/crop.png';
import EnhanceQualtyImage from 'src/assets/images/edit-menu/repair.png';
import { ExpandMenu } from './expand/ExpandMenu';
import { ArrowRightIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { CustomSize } from './expand/CustomSize';

import CustomSizeIcon from '@/assets/icons/ai-scene-icons/customSize.svg?react';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';

export const EditToolMenuTypes = {
  EDIT: 'edit',
  MAGIC_ERASE: 'magicErase',
  MAGIC_EDIT: 'magicEdit',
  RESTORE: 'restore',
  EXPAND: 'expand',
  CUSTOM_SIZE: 'customSize',
  CROP: 'crop',
  REPAIR: 'repair',
} as const;

export type EditToolMenuType = (typeof EditToolMenuTypes)[keyof typeof EditToolMenuTypes];

export const EDIT_MENU_ITEMS = [
  {
    id: 2,
    img: MagicEraseImage,
    name: 'Magic Erase',
    menuType: EditToolMenuTypes.MAGIC_ERASE,
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.requiredPlan,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.featureDescription,
  },
  {
    id: 3,
    img: MagicEditImage,
    name: 'Magic Edit',
    menuType: EditToolMenuTypes.MAGIC_EDIT,
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.requiredPlan,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.featureDescription,
  },
  {
    id: 4,
    img: RestoreImage,
    name: 'Restore',
    menuType: EditToolMenuTypes.RESTORE,
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.requiredPlan,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_OTHER_BUTTONS.featureDescription,
  },
  {
    id: 5,
    img: ExpandImage,
    name: 'Expand',
    menuType: EditToolMenuTypes.EXPAND,
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_MULTIRESIZE_BUTTON.requiredPlan,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_MULTIRESIZE_BUTTON.featureDescription,
  },
  {
    id: 6,
    img: EnhanceQualtyImage,
    name: 'Repair',
    menuType: EditToolMenuTypes.REPAIR,
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_REPAIR_BUTTONS.requiredPlan,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_REPAIR_BUTTONS.featureDescription,
  },
  {
    id: 7,
    img: CropImage,
    name: 'Crop',
    menuType: EditToolMenuTypes.CROP,
    isDisable: true,
  },
];

export const EditToolContainer = () => {
  const [currentMenu, setCurrentMenu] = useState<EditToolMenuType>(EditToolMenuTypes.EDIT);

  return (
    <div className='h-[98%]'>
      {currentMenu === EditToolMenuTypes.EDIT && <EditTool setMenu={setCurrentMenu} />}
      {currentMenu === EditToolMenuTypes.MAGIC_ERASE && (
        <MagicEraseMenu onBackClick={() => setCurrentMenu(EditToolMenuTypes.EDIT)} />
      )}
      {currentMenu === EditToolMenuTypes.MAGIC_EDIT && (
        <MagicEditMenu onBackClick={() => setCurrentMenu(EditToolMenuTypes.EDIT)} />
      )}
      {currentMenu === EditToolMenuTypes.RESTORE && (
        <RestoreMenu onBackClick={() => setCurrentMenu(EditToolMenuTypes.EDIT)} />
      )}
      {currentMenu === EditToolMenuTypes.EXPAND && (
        <ExpandMenu onBackClick={() => setCurrentMenu(EditToolMenuTypes.EDIT)}>
          <button
            onClick={() => setCurrentMenu(EditToolMenuTypes.CUSTOM_SIZE)}
            className='flex h-[44px] w-[285px] cursor-pointer items-center justify-between gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 text-main font-semibold hover:bg-slate-100'
          >
            <div className='flex h-[44px] items-center gap-2'>
              <div className='flex w-6 justify-center'>
                <CustomSizeIcon />
              </div>
              Custom Size
            </div>

            <ArrowRightIcon />
          </button>
        </ExpandMenu>
      )}

      {currentMenu === EditToolMenuTypes.CUSTOM_SIZE && (
        <CustomSize onBackClick={() => setCurrentMenu(EditToolMenuTypes.EXPAND)} />
      )}
      {currentMenu === EditToolMenuTypes.CROP && <EditTool setMenu={setCurrentMenu} />}
    </div>
  );
};
