import { getRelativeDate } from '@/utils/helper';
import { TTemplateResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { ImageModal } from 'components/common/ImageModal';
import { Input, InputProps } from 'components/ui/input';
import { FC, ReactNode, useEffect, useRef } from 'react';

const IMG_CL = `absolute inset-0 h-full w-full object-cover`;

type BaseCardProps = {
  children: ReactNode;
} & Pick<TTemplateResponse, 'name' | 'thumbnail' | 'last_update_info' | 'isOrganizationAsset'>;

export const BaseCard: FC<BaseCardProps & InputProps> = ({
  name,
  children,
  thumbnail,
  last_update_info,
  isOrganizationAsset,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.defaultValue || !inputRef.current) return;

    inputRef.current.value = props.defaultValue.toString();
  }, [props.defaultValue]);

  const isMutationEnabled = isOrganizationAsset;

  const titleElement = isMutationEnabled ? (
    <Input
      ref={inputRef}
      className='h-[28px] w-full truncate border-none bg-transparent pl-1 text-sm font-semibold'
      {...props}
    />
  ) : (
    <span className='flex h-[28px] items-center truncate pl-[4px] text-sm font-semibold'>
      {name}
    </span>
  );

  return (
    <div className='h-[225px] w-80 overflow-hidden rounded-md border-2 shadow-sm'>
      <div className='relative h-[calc(100%-65px)]'>
        <div className='relative h-full w-full bg-white'>
          <ImageModal
            header={<h4 className='flex justify-center gap-2'>{name}</h4>}
            content={<img crossOrigin='anonymous' src={thumbnail} />}
          >
            <img crossOrigin='anonymous' src={thumbnail} className={`${IMG_CL} cursor-zoom-in`} />
          </ImageModal>
        </div>
      </div>
      <div className='flex h-[65px] flex-col justify-center gap-1 bg-crait-bg px-3'>
        <div className='relative mb-0.5 pr-7 font-semibold'>
          {titleElement}
          {children}
        </div>
        <p className='pl-1 text-xs text-gray-500'>
          {getRelativeDate(last_update_info.timestamp)} by{' '}
          <span className='capitalize'>{last_update_info.user_name}</span>
        </p>
      </div>
    </div>
  );
};
