import { RoutePaths } from '@/utils/route/constants';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

import Photoshoot from '@/assets/icons/cameraLens.svg?react';
import AiImage from '@/assets/icons/aiImage.svg?react';
import AdCreative from '@/assets/icons/columns01.svg?react';
import Workflow from '@/assets/icons/dataflow06.svg?react';
import Insight from '@/assets/icons/barLineChart.svg?react';
import AiEdit from '@/assets/icons/aiEditMenu.svg?react';

export const NEW_PROJECT_OPTIONS = [
  {
    label: 'Photoshoot',
    value: 'photoshoot',
    icon: Photoshoot,
    style: 'h-[17px] w-[17px] stroke-crait-red',
    path: RoutePaths.AI_SCENE,
  },
  {
    label: 'AI Image',
    value: 'ai-image',
    icon: AiImage,
    style: 'h-[17px] w-[17px]',
    path: RoutePaths.AI_IMAGE,
  },
  {
    label: 'AI Edit',
    value: 'ai-edit',
    icon: AiEdit,
    style: 'h-[17px] w-[17px]',
    path: RoutePaths.AI_EDIT,
  },
  {
    label: 'Ad Creative',
    value: 'ad-creative',
    icon: AdCreative,
    path: RoutePaths.CREATIVE_SUITE,
    style: 'h-[15px] w-[15px]',
    isDisabled: true,
  },
  {
    label: 'Insight',
    value: 'insight',
    icon: Insight,
    style: 'h-[17px] w-[17px] stroke-crait-gray',
    path: RoutePaths.CREATIVE_SUITE,
    isDisabled: true,
  },
  {
    label: 'Workflow Automation',
    value: 'wf-automation',
    icon: Workflow,
    style: 'h-[17px] w-[17px] stroke-crait-gray',
    path: RoutePaths.CREATIVE_SUITE,
    isDisabled: true,
  },
];

export const CreateButton = () => {
  return (
    <div className='flex items-center justify-center gap-2 md:hidden'>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className='flex w-full justify-between rounded-lg bg-crait-green-primary  pl-2 text-[14px] text-c-dark'>
            Create New <PlusCircle size={'20px'} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='center' className='w-[232px]'>
          {NEW_PROJECT_OPTIONS.map((option) => (
            <DropdownMenuItem
              key={option.value}
              asChild
              disabled={option.isDisabled}
              className='cursor-pointer !text-main'
            >
              <Link to={`/${option.path}`} className={`flex gap-2`}>
                <option.icon className={`${option.style} `} /> {option.label}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
