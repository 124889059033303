import { IconWrapper } from 'components/common/IconWrapper';
import { TooltipItem } from 'components/common/TooltipItem';
import { Button } from 'components/ui/button';
import { FC } from 'react';

const ICON_WRAPPER_CL = 'inline-flex items-center rounded-md bg-gray-100 p-1';

interface UndoRedoIconsProps {
  className?: string;
  undoCanvasState: () => void;
  redoCanvasState: () => void;
  shouldDisableUndo: boolean;
  shouldDisableRedo: boolean;
}

export const UndoRedoIcons: FC<UndoRedoIconsProps> = ({
  className,
  undoCanvasState,
  redoCanvasState,
  shouldDisableUndo,
  shouldDisableRedo,
}) => {
  return (
    <Button
      className={`h-[52px] bg-white hover:bg-white ${className || ''}`}
      variant='ghost'
      asChild
    >
      <div className='flex gap-2'>
        <TooltipItem
          side='bottom'
          trigger={
            <div>
              <IconWrapper
                wrapperProps={{
                  className: `${ICON_WRAPPER_CL} ${
                    shouldDisableUndo
                      ? 'hover:none cursor-not-allowed opacity-60'
                      : 'hover:scale-125 cursor-pointer'
                  }`,
                  onClick: () => {
                    if (shouldDisableUndo) return;
                    undoCanvasState();
                  },
                }}
                iconClass='i-mdi-undo-variant size-4'
              />
            </div>
          }
        >
          Undo
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <div>
              <IconWrapper
                wrapperProps={{
                  className: `${ICON_WRAPPER_CL} ${
                    shouldDisableRedo
                      ? 'hover:none cursor-not-allowed opacity-60'
                      : 'hover:scale-125 cursor-pointer'
                  }`,
                  onClick: () => {
                    if (shouldDisableRedo) return;
                    redoCanvasState();
                  },
                }}
                iconClass='i-mdi-redo-variant size-4'
              />
            </div>
          }
        >
          Redo
        </TooltipItem>
      </div>
    </Button>
  );
};
