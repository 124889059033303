import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Separator } from 'components/ui/separator';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { ImageFileUpload } from 'components/common/ImageFileUpload';
import { PenLine } from 'lucide-react';
import { toast } from 'sonner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import { useUserStore } from '@/providers/user/hooks';
import { AxiosError } from 'axios';
import {
  useUpdateAccountInfoMutation,
  useUpdatePassword,
} from 'components/dashboard/profile/helper';
import { passwordSchema, usernameSchema } from 'pages/sign-up/SignUpPage';

const INPUT_CL = 'h-[44px]';

const profileSchema = z
  .object({
    avatarFile: z
      .instanceof(File)
      .refine((file) => /image\/(jpeg|jpg|png|webp)/.test(file.type), {
        message: 'Invalid file type. Accepted formats are JPG, JPEG, WebP and PNG.',
      })
      .optional(),
    username: usernameSchema,
    email: z
      .string()
      .email({ message: 'Invalid email address.' })
      .min(1, { message: 'Email is required.' }),
    currentPassword: z
      .string()
      .optional()
      .refine((val) => val === undefined || val === '' || val.length >= 5, {
        message: 'Current password must be at least 5 characters.',
      }),
    newPassword: z.union([passwordSchema, z.literal('')]).optional(),
    confirmPassword: z.union([passwordSchema, z.literal('')]).optional(),
  })
  .refine(
    (data) => {
      if (data.newPassword !== '' || data.confirmPassword !== '') {
        return data.newPassword === data.confirmPassword;
      }
      // If both newPassword and confirmPassword are empty, do not enforce the match check.
      return true;
    },
    {
      message: 'New password and confirm password must match',
      path: ['confirmPassword'],
    },
  );

const FMessage = ({ children }: { children: React.ReactNode }) => (
  <FormMessage className='text-[12px] text-red-500'>{children}</FormMessage>
);

const FLabel = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <FormLabel className={`flex gap-1 text-main text-crait-primary ${className}`}>
    {children}
  </FormLabel>
);

const FItem = ({ children }: { children: React.ReactNode }) => (
  <FormItem className={'mb-5'}>{children}</FormItem>
);

export const Divider = () => <Separator className=' my-[14px] border bg-slate-100' />;
7;

type ProfileEditProps = {
  onCloseModal: () => void;
};

export const ProfileEdit: FC<ProfileEditProps> = ({ onCloseModal }) => {
  const user = useUserStore((state) => state.user);
  const setUserAccountInfo = useUserStore((state) => state.setUserAccountInfo);
  const form = useForm<z.infer<typeof profileSchema>>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      avatarFile: undefined,
      username: user?.user_name,
      email: user?.email,
      currentPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined,
    },
  });
  const updateAccountInfo = useUpdateAccountInfoMutation();
  const updateUserPassword = useUpdatePassword();

  const isIdentityProvider = user?.useIdentityProvider;

  const onImageUpload = (file: File | null) => {
    if (!file) return;

    form.setValue('avatarFile', file);
  };

  const onSubmit = async (values: z.infer<typeof profileSchema>) => {
    try {
      const accountResponse = await updateAccountInfo.mutateAsync({
        userName: values.username,
        email: values.email,
        avatar: values.avatarFile,
      });
      setUserAccountInfo({
        user_name: accountResponse.user_name,
        email: accountResponse.email,
        avatar: accountResponse.avatar,
      });

      if (!values.currentPassword || !values.newPassword || !values.confirmPassword) {
        toast.success('Account info updated successfully');
        onCloseModal();
        return;
      }
      await updateUserPassword.mutateAsync({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      onCloseModal();
      toast.success('Account info and password updated successfully');
    } catch (error) {
      console.error('Error updating account info', error);
      if (error instanceof AxiosError && error.response?.data?.detail) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Error updating account info');
      }
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className='flex gap-7 px-6 pt-10 md:flex-col'>
          <FormField
            control={form.control}
            name='avatarFile'
            render={({ fieldState: { error } }) => (
              <FItem>
                <FormControl>
                  <ImageFileUpload
                    onUpload={onImageUpload}
                    className={`${INPUT_CL} hidden`}
                    labelProps={{
                      className:
                        'block size-24 rounded-full cursor-pointer text-base font-medium overflow-hidden',
                    }}
                    defaultImage={user?.avatar}
                    clickAreaElement={
                      <div className='flex h-full flex-col items-center justify-center gap-0 rounded-lg border border-[#ddd]'>
                        <span className='i-mdi-account-outline size-5'></span>
                        <span className='text-xs opacity-70'>Photo</span>
                      </div>
                    }
                  />
                </FormControl>
                <FLabel className='cursor-pointer'>
                  Edit Photo <PenLine size={14} />
                </FLabel>
                {error && <FMessage>{error.message}</FMessage>}
              </FItem>
            )}
          />

          <div className='w-full'>
            <FormField
              control={form.control}
              name='username'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Username</FLabel>
                  <FormControl>
                    <Input
                      type='text'
                      placeholder='Username'
                      className={INPUT_CL}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        form.trigger('username');
                      }}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <FormField
              control={form.control}
              name='email'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Email Address</FLabel>
                  <FormControl>
                    <Input
                      type='email'
                      placeholder='Email address'
                      className={INPUT_CL}
                      {...field}
                      disabled={isIdentityProvider}
                      onChange={(e) => {
                        field.onChange(e);
                        if (form.formState.errors.email) {
                          form.clearErrors('email');
                        }
                      }}
                      onBlur={() => {
                        form.trigger('email');
                      }}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />

            <FormField
              control={form.control}
              name='currentPassword'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Current Password</FLabel>
                  <FormControl>
                    <Input
                      type='password'
                      placeholder='******'
                      className={INPUT_CL}
                      {...field}
                      disabled={isIdentityProvider}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <FormField
              control={form.control}
              name='newPassword'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>New Password</FLabel>
                  <FormControl>
                    <Input
                      type='password'
                      placeholder=''
                      className={INPUT_CL}
                      {...field}
                      disabled={isIdentityProvider}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Confirm Password</FLabel>
                  <FormControl>
                    <Input
                      type='password'
                      placeholder=''
                      className={INPUT_CL}
                      {...field}
                      disabled={isIdentityProvider}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
          </div>
        </div>

        <Divider />

        <div className='flex w-full justify-end px-6 pb-6'>
          <Button type='submit' size='lg' className='bg-crait-green-primary text-c-dark'>
            Update
          </Button>
        </div>
      </form>
    </Form>
  );
};
