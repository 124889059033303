import { useEffect } from 'react';
import { fabric } from 'fabric';
import { useSaveUserInteraction } from './useSaveUserInteraction';

export const useSaveCanvasModification = (canvasInstance: React.RefObject<fabric.Canvas>) => {
  const { saveCanvasProgressDebounced } = useSaveUserInteraction();

  useEffect(() => {
    const fabricRef = canvasInstance.current;
    if (!fabricRef) return;

    const isSaveRequired = (
      canvas: fabric.Canvas | null,
      event: fabric.IEvent,
    ): canvas is fabric.Canvas => {
      if (!canvas) return false;
      if (!event.target || 'path' in event.target) return false;
      if (!event.target.imageId || event.target.imageType === 'template') {
        return false;
      }
      return true;
    };

    const onObjectModified = (event: fabric.IEvent) => {
      const canvas = canvasInstance.current;
      if (!canvas) return;

      saveCanvasProgressDebounced(canvas);
    };

    const onObjectAdded = (event: fabric.IEvent) => {
      const canvas = canvasInstance.current;
      if (!isSaveRequired(canvas, event)) return;

      saveCanvasProgressDebounced(canvas);
    };

    const onObjectRemoved = (event: fabric.IEvent) => {
      const canvas = canvasInstance.current;
      if (!isSaveRequired(canvas, event)) return;

      saveCanvasProgressDebounced(canvas);
    };

    const onTextCleared = (event: fabric.IEvent) => {
      const canvas = canvasInstance.current;
      if (!canvas) return;
      if (!event.deselected || event.deselected.length < 1 || !('text' in event.deselected[0])) {
        return;
      }
      saveCanvasProgressDebounced(canvas);
    };

    fabricRef.on('object:modified', onObjectModified);
    fabricRef.on('object:added', onObjectAdded);
    fabricRef.on('object:removed', onObjectRemoved);
    fabricRef.on('selection:cleared', onTextCleared);

    return () => {
      fabricRef.off('object:modified', onObjectModified);
      fabricRef.off('object:added', onObjectAdded);
      fabricRef.off('object:removed', onObjectRemoved);
      fabricRef.off('selection:cleared', onTextCleared);
    };
  }, [canvasInstance, saveCanvasProgressDebounced]);
};
