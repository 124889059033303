import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Separator } from 'components/ui/separator';
import { ReactNode, useState } from 'react';

interface ImageModalProps {
  children: ReactNode;
  content: ReactNode;
  header: ReactNode;
  isClickDisabled?: boolean;
}
export const ImageModal = ({ children, header, content, isClickDisabled }: ImageModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <div
        onClick={() => {
          if (isClickDisabled) return;
          setIsOpen(true);
        }}
      >
        {children}
      </div>
      <DialogContent className='w-full rounded-lg bg-white p-6'>
        <DialogHeader>
          <DialogTitle className='text-lg font-medium'>{header}</DialogTitle>
        </DialogHeader>
        {!isClickDisabled && isOpen && (
          <>
            <Separator className='mb-4' />
            <div className='flex flex-col items-center gap-6'>
              {content}
              <div className='flex w-72 flex-col gap-3'>
                <Button variant='outline' className='w-full' onClick={() => setIsOpen(false)}>
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
