import { AiBannerSidebar } from 'components/ai-banner/sidebar/AiBannerSidebar';
import { SelectedView } from './SelectedView';

export const AiBannerCreate = () => {
  return (
    <div className='relative flex h-[calc(100%-60px)] w-full'>
      <AiBannerSidebar />
      <SelectedView />
    </div>
  );
};
