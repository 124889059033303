import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { BannerEndpoint } from '@/utils/fetch/constants';
import { getBannersApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';
import { TBannerResponse } from 'components/ai-scene/sidebar/settings-tab/queries';

type TBannerUpdatePayload = {
  id: string;
  name: string;
  file: File;
  organizationId: number;
};

const updateBannerDetail = async (props: TBannerUpdatePayload) => {
  const baseUrl = getBannersApiUrl(props.organizationId, BannerEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('file', props.file);
  return apiClient.put<TBannerResponse>(url, formData);
};

export const useUpdateBannerDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BannerEndpoint.BASE, organizationId, 'updateBannerDetail'],
    mutationFn: (props: Omit<TBannerUpdatePayload, 'organizationId'>) => {
      return updateBannerDetail({ ...props, organizationId });
    },
  });
};

type TBannerPartialUpdatePayload = {
  id: string;
  name?: string;
  file?: File;
  organizationId: number;
};

const updatePartialBannerDetail = async (props: TBannerPartialUpdatePayload) => {
  const baseUrl = getBannersApiUrl(props.organizationId, BannerEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  if (props.name) formData.append('name', props.name);
  if (props.file) formData.append('image', props.file);

  return apiClient.patch<TBannerResponse>(url, formData);
};

export const usePartialUpdateBannerDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BannerEndpoint.BASE, organizationId, 'updatePartialBannerDetail'],
    mutationFn: (props: Omit<TBannerPartialUpdatePayload, 'organizationId'>) => {
      return updatePartialBannerDetail({ ...props, organizationId });
    },
  });
};

type TBannerUploadPayload = Omit<TBannerUpdatePayload, 'id'>;

const uploadBanner = async (props: TBannerUploadPayload) => {
  const baseUrl = getBannersApiUrl(props.organizationId, BannerEndpoint.BASE);
  const url = `${baseUrl}/`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('file', props.file);
  return apiClient.post<TBannerResponse>(url, formData);
};

export const useUploadBanner = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BannerEndpoint.BASE, organizationId, 'uploadBanner'],
    mutationFn: (props: Omit<TBannerUploadPayload, 'organizationId'>) => {
      return uploadBanner({ ...props, organizationId });
    },
  });
};

type TGetBannerPayload = {
  id: string;
  organizationId: number;
};

const getBannerById = async (props: TGetBannerPayload) => {
  const baseUrl = getBannersApiUrl(props.organizationId, BannerEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  return apiClient.get<TBannerResponse>(url);
};

export const useGetBannerById = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BannerEndpoint.BASE, organizationId, 'getBannerById'],
    mutationFn: (props: Omit<TGetBannerPayload, 'organizationId'>) => {
      return getBannerById({ ...props, organizationId });
    },
  });
};

const deleteBannerById = async (props: TGetBannerPayload) => {
  const baseUrl = getBannersApiUrl(props.organizationId, BannerEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  return apiClient.delete<string>(url);
};

export const useDeleteBanner = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BannerEndpoint.BASE, organizationId],
    mutationFn: (props: Omit<TGetBannerPayload, 'organizationId'>) => {
      return deleteBannerById({ ...props, organizationId });
    },
  });
};
