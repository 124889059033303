import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { BrandKitEndpoint, BrandKitSubEndpoint } from '@/utils/fetch/constants';
import { getBrandKitApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';

type TBrandVoideGuideResponse = {
  description: string;
  preferredKeywords: string;
  bannedKeywords: string;
  id: string;
};

export const getBrandVoiceGuide = async (organizationId: number) => {
  const url = getBrandKitApiUrl(organizationId, BrandKitSubEndpoint.BRAND_VOICE_GUIDE);
  const response = await apiClient.get<TBrandVoideGuideResponse>(url);
  return response.data;
};

export const useGetBrandVoiceGuideQuery = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: BrandKitEndpoint.BASE, organizationId }],
    queryFn: ({ queryKey }) => {
      return getBrandVoiceGuide(queryKey[0].organizationId);
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
  });
  return result;
};

type TBrandVoiceGuidePartialUpdatePayload = Partial<TBrandVoideGuideResponse> & {
  organizationId: number;
};
const updatePartialBrandVoiceGuide = async (props: TBrandVoiceGuidePartialUpdatePayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.BRAND_VOICE_GUIDE);

  return apiClient.patch<TBrandVoideGuideResponse>(url, {
    description: props.description || undefined,
    preferredKeywords: props.preferredKeywords || undefined,
    bannedKeywords: props.bannedKeywords || undefined,
  });
};

export const usePartialUpdateBrandVoiceGuide = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [BrandKitEndpoint.BASE, organizationId, 'updatePartialBrandVoiceGuide'],
    mutationFn: (props: Omit<Partial<TBrandVoideGuideResponse>, 'id'>) => {
      return updatePartialBrandVoiceGuide({ ...props, organizationId });
    },
  });
};
