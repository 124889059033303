import { EditLogoDropdown, LogoCard } from './LogoCard';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { useGetLogos } from '../queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useMemo } from 'react';
import { QuickUploadLogo } from 'components/ai-scene/side-menu/add-logo-tool/QuickUploadLogo';

export const LogoContent = () => {
  const { data, isError, isLoading, refetch } = useGetLogos({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load logos.</span>
    </RefetchView>
  );

  const logoItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return <QuickUploadLogo />;
    }

    return data.items.map((logo) => {
      return (
        <LogoCard key={logo.id + logo.last_update_info.timestamp} {...logo} refetch={refetch}>
          <EditLogoDropdown {...logo} refetch={refetch} />
        </LogoCard>
      );
    });
  }, [data, isLoading, refetch]);

  return (
    <ScrollArea className='md:w-[90vw] md:whitespace-nowrap'>
      <div className='flex max-h-[450px] flex-wrap gap-6 overflow-x-auto p-5 md:flex-nowrap'>
        {errorView || logoItems}
      </div>
      <ScrollBar orientation='horizontal' />
    </ScrollArea>
  );
};
