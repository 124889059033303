import { isFabricText } from '@/utils/helper';
import { Button } from 'components/ui/button';
import { FC, useEffect, useRef } from 'react';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-slate-100';
const ACTIVE_CL = 'bg-slate-100';

interface AlignTextProps {
  canvasInstance: fabric.Canvas | null;
}

export const AlignText: FC<AlignTextProps> = ({ canvasInstance }) => {
  const leftAlignRef = useRef<HTMLElement | null>(null);
  const centerAlignRef = useRef<HTMLElement | null>(null);
  const rightAlignRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!canvasInstance) return;

    const selectionHandler = () => {
      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject) || !activeObject.get('textAlign')) {
        toggleActiveSelection(leftAlignRef, false);
        toggleActiveSelection(centerAlignRef, false);
        toggleActiveSelection(rightAlignRef, false);
        return;
      }

      const activeObjectAlign = activeObject.get('textAlign');
      if (!activeObjectAlign) return;

      toggleActiveSelection(leftAlignRef, activeObjectAlign === 'left');
      toggleActiveSelection(centerAlignRef, activeObjectAlign === 'center');
      toggleActiveSelection(rightAlignRef, activeObjectAlign === 'right');
    };

    canvasInstance.on('selection:created', selectionHandler);
    canvasInstance.on('selection:updated', selectionHandler);
    canvasInstance.on('selection:cleared', selectionHandler);

    return () => {
      canvasInstance.off('selection:created', selectionHandler);
      canvasInstance.off('selection:updated', selectionHandler);
      canvasInstance.off('selection:cleared', selectionHandler);
    };
  }, [canvasInstance]);

  const toggleActiveSelection = (
    refReference: React.RefObject<HTMLElement>,
    shouldEnable: boolean,
  ) => {
    if (!refReference.current) return;

    if (shouldEnable) {
      refReference.current.classList.add(ACTIVE_CL);
      return;
    }
    refReference.current.classList.remove(ACTIVE_CL);
  };

  const alignActiveObject = (align: 'left' | 'right' | 'center') => {
    if (!canvasInstance) return;

    const activeObject = canvasInstance.getActiveObject();
    if (!activeObject || !isFabricText(activeObject)) return;

    activeObject.set('textAlign', align);
    toggleActiveSelection(leftAlignRef, align === 'left');
    toggleActiveSelection(centerAlignRef, align === 'center');
    toggleActiveSelection(rightAlignRef, align === 'right');
    canvasInstance.requestRenderAll();
  };

  return (
    <Button asChild variant='outline' className='w-2/5 rounded-lg bg-white p-1 hover:bg-white'>
      <div className='group flex gap-1'>
        <span
          ref={leftAlignRef}
          className={ICON_WRAPPER_CL}
          onClick={() => alignActiveObject('left')}
        >
          <span className='i-mdi-format-align-left size-4' />
        </span>
        <span
          ref={centerAlignRef}
          className={ICON_WRAPPER_CL}
          onClick={() => alignActiveObject('center')}
        >
          <span className='i-mdi-format-align-center size-4' />
        </span>
        <span
          ref={rightAlignRef}
          className={ICON_WRAPPER_CL}
          onClick={() => alignActiveObject('right')}
        >
          <span className='i-mdi-format-align-right size-4' />
        </span>
      </div>
    </Button>
  );
};
