import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { FC, useState } from 'react';
import { useDeleteBanner, useUpdateBannerDetail } from './queries';
import { toast } from 'sonner';
import { TBannerResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { ModalView } from 'components/common/ModalView';
import { EditNameAndImageView } from '../template/EditNameAndImageView';

export const BannerActions: FC<
  TBannerResponse & {
    refetch: () => void;
  }
> = (props) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const deleteBannerMutation = useDeleteBanner();
  const updateBannerDetailMutation = useUpdateBannerDetail();

  return (
    <>
      {isEditModalShown && (
        <ModalView
          open={isEditModalShown}
          onOpenChange={setIsEditModalShown}
          titleElement={<h4 className='text-lg font-medium'>Edit Selected Banner</h4>}
        >
          {({ closeModal }) => (
            <EditNameAndImageView
              defaultName={props.name}
              defaultImage={props.thumbnail}
              onClose={closeModal}
              isSubmitDisabled={updateBannerDetailMutation.isPending}
              onSubmit={async (name, image) => {
                try {
                  if (updateBannerDetailMutation.isPending) return;

                  await updateBannerDetailMutation.mutateAsync({ name, file: image, id: props.id });
                  props.refetch();
                  closeModal();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to update banner');
                }
              }}
            />
          )}
        </ModalView>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <span className='jusfity-center absolute right-0 top-0 flex cursor-pointer items-center px-1 py-1'>
            <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
          </span>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='w-40 p-2'>
          <DropdownMenuCheckboxItem
            className='cursor-pointer px-2 py-1 hover:bg-gray-50'
            onClick={() => {
              setIsEditModalShown(true);
            }}
          >
            Edit
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem className='cursor-pointer px-2 py-1 hover:bg-gray-50'>
            Use to Generate
          </DropdownMenuCheckboxItem>
          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem
            className='cursor-pointer px-2 py-1'
            onClick={async () => {
              try {
                if (deleteBannerMutation.isPending) return;
                await deleteBannerMutation.mutateAsync({ id: props.id });
                props.refetch();
              } catch (error) {
                console.log(error);
                toast.error('Failed to remove banner');
              }
            }}
          >
            Delete
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
