import { ModalView } from 'components/common/ModalView';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { FC, useRef, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Input } from 'components/ui/input';
import { toast } from 'sonner';
import { downloadWithAnchor, getConvertedImageUrl } from '@/utils/helper';

interface IDownloadModalProps {
  trigger?: JSX.Element;
  image: string;
  previewImage: string;
  title: string;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

const DownloadType = {
  PNG: 'png',
  JPG: 'jpeg',
  PSD: 'psd',
} as const;

type TDownloadType = (typeof DownloadType)[keyof typeof DownloadType];

export const DownloadModal: FC<IDownloadModalProps> = ({
  trigger,
  image,
  title,
  previewImage,
  isModalOpen,
  setIsModalOpen,
}) => {
  const downloadSpecificFields = useRef<{
    name: string;
    type: TDownloadType;
  }>({
    name: title,
    type: DownloadType.PNG,
  });
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      {isModalOpen && (
        <ModalView
          open={isModalOpen}
          onOpenChange={setIsModalOpen}
          titleElement={<h4 className='text-lg font-medium'>Download Your Awesome Photo</h4>}
        >
          {({ closeModal }) => (
            <div>
              <Separator className='mb-4' />
              <div className='mx-auto flex w-[270px] flex-col gap-3'>
                <div className='mb-3 h-[160px] w-full rounded-lg'>
                  <img
                    crossOrigin='anonymous'
                    src={previewImage}
                    alt='download image'
                    className='h-full w-full rounded-lg object-cover'
                  />
                </div>
                <p className='text-base font-medium'>Give a name to your scene</p>
                <Input
                  placeholder='Enter name'
                  defaultValue={title}
                  className='w-full text-sm font-medium'
                  onBlur={(e) => {
                    downloadSpecificFields.current = {
                      ...downloadSpecificFields.current,
                      name: e.target.value,
                    };
                  }}
                />
                <Select
                  defaultValue={DownloadType.PNG}
                  onValueChange={(value) => {
                    downloadSpecificFields.current = {
                      ...downloadSpecificFields.current,
                      type: value as TDownloadType,
                    };
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select image type' />
                  </SelectTrigger>
                  <SelectContent className='z-[500] max-w-[250px] p-2'>
                    <SelectItem className='cursor-pointer py-2' value={DownloadType.PNG}>
                      PNG - Image Type
                    </SelectItem>
                    <SelectItem className='cursor-pointer py-2' value={DownloadType.JPG}>
                      JPG - Image Type
                    </SelectItem>
                    <SelectItem className='cursor-pointer py-2' value={DownloadType.PSD} disabled>
                      PSD - Image Type
                    </SelectItem>
                  </SelectContent>
                </Select>
                <Separator className='my-2' />
                <div className='flex w-72 flex-col gap-3'>
                  <Button
                    className='w-full'
                    disabled={isDownloading}
                    onClick={async () => {
                      try {
                        const selectedImageType = downloadSpecificFields.current.type;
                        if (
                          selectedImageType !== DownloadType.JPG &&
                          selectedImageType !== DownloadType.PNG
                        ) {
                          toast.error('Invalid image type');
                          return;
                        }

                        setIsDownloading(true);
                        const url = await getConvertedImageUrl(
                          image,
                          downloadSpecificFields.current.type as 'png' | 'jpeg',
                        );
                        downloadWithAnchor(url, downloadSpecificFields.current.name);
                        closeModal();
                        downloadSpecificFields.current = {
                          name: title,
                          type: DownloadType.PNG,
                        };
                      } catch (error) {
                        toast.error('Failed to download image');
                      } finally {
                        setIsDownloading(false);
                      }
                    }}
                  >
                    Download
                  </Button>
                  <Button variant='outline' className='w-full' onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ModalView>
      )}
    </>
  );
};
