import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ImageProcessEndpoint, RemoveBgSubEndpoint } from '@/utils/fetch/constants';
import { getImageProcessApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TScene } from 'components/ai-scene/review/queries';

type TBulkItemRemoveBgPayload = {
  jobId: string;
  itemId: string;
  organizationId: number;
  isAlreadyProcessed?: boolean;
};

export type TBulkItemRemoveBgResponse = {
  id: string;
  image: {
    transparent: string | null;
    original: string;
  };
  status: TScene['status'];
};

const getBulkItemRemoveBgResult = async ({
  jobId,
  itemId,
  organizationId,
}: TBulkItemRemoveBgPayload) => {
  const baseUrl = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.REMOVE_BG);
  const url = `${baseUrl}${RemoveBgSubEndpoint.BULK}/${jobId}/${itemId}`;
  const response = await apiClient.get<TBulkItemRemoveBgResponse>(url);
  return response.data;
};

export const useGetBulkItemRemoveBgProcessQuery = (
  params: Omit<TBulkItemRemoveBgPayload, 'organizationId'>,
) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useQuery({
    queryKey: [{ organizationId, ...params }],
    queryFn: ({ queryKey }) => {
      return getBulkItemRemoveBgResult({
        ...queryKey[0],
        organizationId,
      });
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
    refetchInterval: (data) => {
      const loadingStatus = data?.state?.data?.status;
      return loadingStatus === 'in_progress' || loadingStatus === 'pending' ? 1000 : false;
    },
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !params.isAlreadyProcessed,
  });
};

type TBulkRemoveBackgroundPayload = {
  organizationId: number;
  products: Array<{
    id: string;
    imageUrl: string;
  }>;
};

type TBulkRemoveBackgroundResponse = {
  jobId: string;
  products: Array<{
    id: string;
    imageUrl: string;
    status: string;
  }>;
};

const removeBulkBackground = async ({ organizationId, products }: TBulkRemoveBackgroundPayload) => {
  const baseUrl = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.REMOVE_BG);
  const url = `${baseUrl}${RemoveBgSubEndpoint.BULK}`;
  return apiClient.post<TBulkRemoveBackgroundResponse>(url, { products });
};

export const useBulkRemoveBgMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [RemoveBgSubEndpoint.BULK, organizationId],
    mutationFn: (props: Omit<TBulkRemoveBackgroundPayload, 'organizationId'>) => {
      return removeBulkBackground({
        ...props,
        organizationId,
      });
    },
  });
};

type TBulkCompleteJobPayload = Omit<TBulkItemRemoveBgPayload, 'itemId'>;

const completeJob = async ({ jobId, organizationId }: TBulkCompleteJobPayload) => {
  const baseUrl = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.REMOVE_BG);
  const url = `${baseUrl}${RemoveBgSubEndpoint.BULK}/${jobId}`;
  return apiClient.get(url);
};

export const useBulkCompleteJobMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [RemoveBgSubEndpoint.BULK, organizationId],
    mutationFn: (props: Omit<TBulkCompleteJobPayload, 'organizationId'>) => {
      return completeJob({
        ...props,
        organizationId,
      });
    },
  });
};
