import { createContext, useContext } from 'react';
import { createSubscriptionStore, TSubscriptionStore } from './store';
import { useStoreWithEqualityFn } from 'zustand/traditional';

export type TSubscriptionContext = ReturnType<typeof createSubscriptionStore>;

export const SubscriptionContext = createContext<TSubscriptionContext | undefined>(undefined);

export const useSubscriptionStore = <T,>(
  selector: (state: TSubscriptionStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(SubscriptionContext);
  if (!store) throw new Error('Missing SubscriptionContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
