import { FC, ReactNode } from 'react';
import { useUserStore } from '@/providers/user/hooks';

interface AvatarViewProps {
  children?: ReactNode;
  avatarContainerClass?: string;
  onAvatarClick?: () => void;
}

export const AvatarView: FC<AvatarViewProps> = ({
  children,
  avatarContainerClass,
  onAvatarClick,
}) => {
  const user = useUserStore((state) => state.user);

  return (
    <div
      onClick={onAvatarClick}
      className={`cursor-pointer rounded-full border border-[#EAEAEA] p-1 ${
        avatarContainerClass || ''
      }`}
    >
      <img
        crossOrigin='anonymous'
        src={user?.avatar}
        className='h-8 w-8 rounded-full object-cover'
      />
    </div>
  );
};
