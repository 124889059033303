import React from 'react';
import { Button } from 'components/ui/button';
import { TPaymentProduct, TStripePriceInfo } from '../queries';
import { getButtonProps, PlanType } from '../helper';

type PlanActionButtonProps = {
  plan: TPaymentProduct;
  userPlan: PlanType;
  userPlanExpiry: number;
  paymentPeriod: TStripePriceInfo['paymentPeriod'];
  isProcessing: boolean;
  onPlanSelect: (planType: string, priceId: string | undefined) => void;
  price: number;
};

export const PlanActionButton: React.FC<PlanActionButtonProps> = ({
  plan,
  userPlan,
  userPlanExpiry,
  paymentPeriod,
  isProcessing,
  onPlanSelect,
  price,
}) => {
  const buttonProps = getButtonProps(
    plan,
    userPlan,
    userPlanExpiry,
    isProcessing,
    onPlanSelect,
    paymentPeriod,
    price,
  );

  return (
    <div className='group relative'>
      <Button
        size='lg'
        className={buttonProps.className}
        onClick={buttonProps.onClick}
        disabled={buttonProps.disabled}
      >
        {buttonProps.text}
      </Button>
    </div>
  );
};
