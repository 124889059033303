import { ProjectsView } from 'components/workspace/projects/ProjectsView';
import { ActivityProvider } from './ActivityProvider';
import { ActivityDrawer } from 'components/workspace/projects/ActivityDrawer';

export const WorkspaceProjectsPage = () => {
  return (
    <>
      {/* <ActivityProvider>
        <ActivityDrawer />
      </ActivityProvider> */}
      <ProjectsView />
    </>
  );
};
