import { useUserStore } from '@/providers/user/hooks';
import { useUpdateUserNameMutation } from 'components/dashboard/profile/helper';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { usernameSchema } from 'pages/sign-up/SignUpPage';
import { useRef } from 'react';

import Icon from '@/assets/icons/onboardingStepTwo.svg?react';
import { AxiosError } from 'axios';
import { useDebounceValue } from 'usehooks-ts';
import { ZodError } from 'zod';
import { ContinueButton } from '../_components/ContinueButton';

type ProfileNameStepProps = {
  moveToNextStep: () => void;
};

export const ProfileNameStep: React.FC<ProfileNameStepProps> = ({ moveToNextStep }) => {
  const user = useUserStore((state) => state.user);
  const setUserNameInfo = useUserStore((state) => state.setUserNameInfo);
  const userNameRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useDebounceValue<string | null>(null, 300);

  const updateUserName = useUpdateUserNameMutation();

  const handleUpdateUserName = async () => {
    const userName = userNameRef.current?.value.trim();

    if (!userName) {
      setError('Username cannot be empty');
      return;
    }

    if (userName === user?.user_name) {
      moveToNextStep();
      return;
    }

    try {
      await updateUserName.mutateAsync({
        userName,
      });
      setUserNameInfo({
        user_name: userName,
      });
      moveToNextStep();
    } catch (error) {
      console.error('Failed to update account info', error);
      if (error instanceof AxiosError && error.response?.data?.detail) {
        setError(error.response?.data?.detail as string);
        return;
      }
      setError('Failed to update account info');
    }
  };

  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='mb-5'>
          <Icon />
        </div>

        <h4 className='mb-[10px] text-xl font-bold'>What should we call you?</h4>
        <p className='mb-5 mr-[-10px] text-center text-[14px] text-[#000000] md:mr-0'>
          Your team members will see your username when you share a project.
        </p>

        <div className='flex w-full flex-col items-start gap-2'>
          <Label htmlFor='yourName' className='text-[13px]'>
            Your Name
          </Label>
          <Input
            ref={userNameRef}
            id='yourName'
            type='text'
            defaultValue={user?.user_name || ''}
            className='w-full rounded-lg border bg-white px-4 py-2'
            placeholder='Your username'
            onChange={() => {
              setError(null);
              const userName = userNameRef.current?.value.trim();
              try {
                usernameSchema.parse(userName);
              } catch (error) {
                if (error instanceof ZodError) {
                  setError(error.issues[0]?.message);
                  return;
                }
              }
              setError(null);
            }}
          />
          {error && <span className='text-sm text-red-500'>{error}</span>}
        </div>
      </div>

      <ContinueButton onClick={handleUpdateUserName} />
    </>
  );
};
