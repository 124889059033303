import { cn } from '@/lib/utils';
import { WorkspaceHeader } from 'components/workspace/header/WorkspaceHeader';
import { Outlet } from 'react-router';
import { CraitLogo } from 'components/common/CraitLogo';

export const AiBannerPage = () => {
  return (
    <section className={cn(`h-full w-full overflow-hidden`)}>
      <div className='dark'>
        <WorkspaceHeader>
          <div className={cn(`flex h-header w-56 items-center`)}>
            <CraitLogo />
          </div>
        </WorkspaceHeader>
      </div>
      <main className={cn(`h-full w-full`)}>
        <Outlet />
      </main>
    </section>
  );
};
