import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import React from 'react';
import { ProductAssets } from './ProductAssets';
import { SceneAssets } from './SceneAssets';
import { useBrandSectionQueryParams } from './useBrandSectionQueryParams';

export const AssetsView = () => {
  const contentWrapperRef = React.useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = React.useState<'products' | 'aiScenes'>('products');
  const { closeSection } = useBrandSectionQueryParams();

  return (
    <div className='relative size-full px-10 py-6'>
      <div className='absolute inset-0' onClick={closeSection} />
      <div
        ref={contentWrapperRef}
        className='relative flex h-full justify-center overflow-y-auto rounded-lg bg-white p-6'
      >
        <span
          className='i-mdi-close absolute right-6 top-6 size-6 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            closeSection();
          }}
        />
        <div className='w-full max-w-[700px]'>
          <h3 className='text-center text-xl'>Asset Library</h3>
          <Separator className='mb-8 mt-4' />
          <div className='flex justify-center gap-2'>
            <Button
              onClick={() => {
                setActiveSection('products');
              }}
              className='h-[40px] max-w-[200px] flex-1 rounded-lg'
              variant={activeSection === 'products' ? 'default' : 'outline'}
            >
              Products
            </Button>
            <Button
              onClick={() => {
                setActiveSection('aiScenes');
              }}
              className='h-[40px] max-w-[200px] flex-1 rounded-lg'
              variant={activeSection === 'aiScenes' ? 'default' : 'outline'}
            >
              Ai Scenes
            </Button>
          </div>
          {activeSection === 'products' ? <ProductAssets /> : <SceneAssets />}
          <Button className='w-full'>Continue</Button>
        </div>
      </div>
    </div>
  );
};
