import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useEffect, useState } from 'react';

export const useFaceTextCompositionActivation = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const [isFaceTextCompositionActive, setIsFaceTextCompositionActive] = useState(false);

  useEffect(() => {
    if (!canvasInstance) return;
    const checkFaceTextCompositionActivation = () => {
      const productObjects =
        canvasInstance
          ?.getObjects()
          .filter((obj) => obj.imageType === 'product' && 'imageName' in obj) || [];

      for (const obj of productObjects) {
        if (obj.imageId && obj.imageProperties !== null && obj.imageProperties !== undefined) {
          const { face_present, hand_present, text_present, logo_present } = obj.imageProperties;
          if (face_present || hand_present || text_present || logo_present) {
            setIsFaceTextCompositionActive(true);
            return;
          }
        }
      }

      setIsFaceTextCompositionActive(false);
    };

    checkFaceTextCompositionActivation();

    canvasInstance?.on('object:added', checkFaceTextCompositionActivation);
    canvasInstance?.on('object:removed', checkFaceTextCompositionActivation);

    return () => {
      canvasInstance?.off('object:added', checkFaceTextCompositionActivation);
      canvasInstance?.off('object:removed', checkFaceTextCompositionActivation);
    };
  }, [canvasInstance]);

  return isFaceTextCompositionActive;
};
