import { FC, useState } from 'react';
import { TProductResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';

interface ProductAssetCardProps {
  product: TProductResponse;
  isSelectedByDefault?: boolean;
}

export const ProductAssetCard: FC<ProductAssetCardProps> = ({ product, isSelectedByDefault }) => {
  const [imageType, setImageType] = useState<'opaque' | 'transparent'>('opaque');
  const addAsset = useBannerCreateStore((s) => s.addAsset);
  const removeAsset = useBannerCreateStore((s) => s.removeAsset);
  const updateProductUrl = useBannerCreateStore((s) => s.updateProductUrl);

  return (
    <div
      onClick={() => {
        if (isSelectedByDefault) {
          removeAsset(product.id);
        } else {
          const imageUrl =
            imageType === 'opaque' ? product.image.original : product.image.transparent;
          addAsset({
            type: 'product',
            id: product.id,
            url: imageUrl,
            name: product.name,
          });
        }
      }}
      className={`hover:border-1 flex  cursor-pointer select-none flex-col items-center gap-2.5 rounded-lg p-2 hover:outline hover:outline-crait-blue ${
        isSelectedByDefault ? 'bg-crait-blue opacity-70' : ''
      }`}
    >
      <div className='relative flex h-[160px] w-full items-center gap-2 overflow-hidden rounded-lg'>
        {product.thumbnail.transparent && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setImageType((prev) => (prev === 'opaque' ? 'transparent' : 'opaque'));
              if (isSelectedByDefault) {
                const newImageUrl =
                  imageType === 'opaque' ? product.image.transparent : product.image.original;
                updateProductUrl(product.id, newImageUrl);
              }
            }}
            className='absolute right-1 top-1 z-10 flex size-6 cursor-pointer items-center justify-center rounded-md bg-crait-dark-200'
          >
            {imageType === 'opaque' ? (
              <span className='i-mdi-image-size-select-actual size-4 bg-black' />
            ) : (
              <span className='i-mdi-image-size-select-large size-4 bg-black' />
            )}
          </div>
        )}
        <img crossOrigin='anonymous' src={product.thumbnail.original} className='object-cover' />
        {product.thumbnail.transparent && (
          <img
            crossOrigin='anonymous'
            src={product.thumbnail.transparent}
            className={`absolute inset-0 h-full w-full bg-slate-50 object-contain ${
              imageType === 'opaque' ? 'opacity-0' : 'opacity-100'
            }  transition-opacity duration-500`}
          />
        )}
      </div>
      <p className='line-clamp-1 text-ellipsis'>{product.name}</p>
    </div>
  );
};
