import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import { UndoRedoIcons } from 'components/ai-scene/create/editor/UndoRedoIcons';

export const UndoRedoProductCreateCanvas = () => {
  const redoCanvasState = useProductCreateStore((state) => state.redoCanvasState);
  const undoCanvasState = useProductCreateStore((state) => state.undoCanvasState);
  const previousSceneCanvasState = useProductCreateStore((state) => state.previousCanvasStates);
  const nextSceneCanvasState = useProductCreateStore((state) => state.nextCanvasStates);
  const shouldDisableUndo = previousSceneCanvasState.length < 1;
  const shouldDisableRedo = nextSceneCanvasState.length < 1;

  return (
    <UndoRedoIcons
      className='h-10 border'
      shouldDisableUndo={shouldDisableUndo}
      shouldDisableRedo={shouldDisableRedo}
      redoCanvasState={redoCanvasState}
      undoCanvasState={undoCanvasState}
    />
  );
};
