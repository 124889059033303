import { useRefreshToken } from './helper';
import { useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { LocalCacheKey, localCache } from '../localStorageUtility';
import { updateTokensInCache } from '../fetch/helper';
import { setAuthHeaders } from '../fetch/axiosConfig';

export const useSilentRefresh = () => {
  const refreshTokenFn = useRefreshToken();
  const accessExpiry = localCache.get(LocalCacheKey.EXPIRY);
  const currentRefreshToken = localCache.get(LocalCacheKey.REFRESH_TOKEN);

  useEffect(() => {
    if (!accessExpiry || !currentRefreshToken) return;

    const expMs = accessExpiry * 1000;
    const targetDate = new Date(expMs);
    const diffMs = differenceInMilliseconds(targetDate, new Date());
    const refreshTimeBeforeExpiry = 5 * 60 * 1000; // 5 minutes
    const delayForRefresh = diffMs - refreshTimeBeforeExpiry;

    if (delayForRefresh <= 0) return;

    const timeout = setTimeout(() => {
      if (!currentRefreshToken) return;
      refreshTokenFn.mutate(currentRefreshToken, {
        onSuccess: (data) => {
          updateTokensInCache(data);
          setAuthHeaders({ accessToken: data.access_token });
        },
        onError: (error) => {
          console.error(error);
        },
      });
    }, delayForRefresh);

    return () => {
      clearInterval(timeout);
    };
  }, [currentRefreshToken, accessExpiry, refreshTokenFn]);
};
