import { FC } from 'react';
import { TooltipItem } from 'components/common/TooltipItem';
import { useMenuStore } from 'components/ai-scene/side-menu/store';
import { AiSceneSideMenusType } from '@/components/ai-scene/side-menu/store';
import { useNavigate } from 'react-router';

interface TooltipMenuItemProps {
  icon: React.ReactNode;
  label: string;
  menuType: AiSceneSideMenusType;
  navigateLink?: string;
}

export const TooltipMenuItem: FC<TooltipMenuItemProps> = ({
  icon,
  label,
  menuType,
  navigateLink,
}) => {
  const { activeMenu, setActiveMenu } = useMenuStore((state) => ({
    activeMenu: state.activeMenu,
    setActiveMenu: state.setActiveMenu,
  }));
  const navigate = useNavigate();

  const isActive = activeMenu === menuType;

  return (
    <TooltipItem
      side='bottom'
      trigger={
        <div
          onClick={() => {
            setActiveMenu(menuType);
            if (navigateLink) {
              navigate(navigateLink, {
                state: {
                  shouldShowBack: true,
                },
              });
            }
          }}
          className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center ${
            isActive ? 'rounded-[3px] border bg-[#F5F5F5]' : ''
          }`}
        >
          {icon}
        </div>
      }
    >
      {label}
    </TooltipItem>
  );
};
