import { FC, ReactNode, useState } from 'react';
import { toast } from 'sonner';
import { useWindowSize } from 'usehooks-ts';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Modal } from 'components/ui/modal';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { ImageModal } from 'components/common/ImageModal';
import { EditNameAndImageView } from '../../template/EditNameAndImageView';
import { Breakpoints } from '@/utils/general/constant';
import {
  TLogoResponse,
  useRenameLogoMutation,
  useRemoveLogoMutation,
  useUpdateLogo,
} from '../queries';

export const LogoCard: FC<TLogoResponse & { children: ReactNode; refetch: () => void }> = ({
  children,
  ...props
}) => {
  const partialUpdate = useRenameLogoMutation();

  return (
    <div className='flex h-[200px] w-[190px] flex-col gap-[10px] overflow-hidden md:h-[190px] md:w-[156px]'>
      <div className='relative h-[160px] rounded-[8px] border border-[#EAEAEA]'>
        <div className='relative h-full w-full bg-white'>
          <ImageModal
            header={
              <span className='flex max-w-[400px] items-start gap-2 truncate sm:max-w-[300px]'>
                {props.name}
              </span>
            }
            content={<img crossOrigin='anonymous' src={props.thumbnail} />}
          >
            <img
              crossOrigin='anonymous'
              src={props.thumbnail}
              className='absolute inset-0 h-full w-full cursor-zoom-in object-contain'
            />
          </ImageModal>
        </div>
      </div>
      <div className='flex h-[20px] flex-col justify-center text-main font-semibold'>
        <div className='relative w-full truncate pr-7 font-semibold'>
          {props.name}
          {children}
        </div>
      </div>
    </div>
  );
};

export const EditLogoDropdown = (
  props: TLogoResponse & {
    refetch: () => void;
  },
) => {
  const removeLogo = useRemoveLogoMutation();
  const updateLogo = useUpdateLogo();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;
  const EDIT_LOGO_TITLE = 'Edit Selected Inspiration';

  const handleDeleteLogo = () => {
    removeLogo.mutate(
      { id: props.id },
      {
        onSuccess: () => {
          props.refetch();
        },
        onError: () => {
          toast.error('Failed to remove logo');
        },
      },
    );
  };

  const editContent = (closeModal: () => void) => (
    <EditNameAndImageView
      defaultName={props.name}
      defaultImage={props.thumbnail}
      onClose={closeModal}
      isSubmitDisabled={updateLogo.isPending}
      onSubmit={async (name, image) => {
        try {
          if (updateLogo.isPending) return;

          await updateLogo.mutateAsync({ name, image, id: props.id });
          props.refetch();
          closeModal();
        } catch (error) {
          console.log(error);
          toast.error('Failed to update logo');
        }
      }}
    />
  );

  return (
    <>
      {isEditModalShown &&
        (isDesktop ? (
          <Modal
            open={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            modalContentClassName='py-8'
            titleElement={<span className='text-lg font-medium'>{EDIT_LOGO_TITLE}</span>}
          >
            {({ closeModal }) => editContent(closeModal)}
          </Modal>
        ) : (
          <MobileMenuSheet
            isOpen={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement={EDIT_LOGO_TITLE}
            contentClassName='py-8'
          >
            {({ closeModal }) => editContent(closeModal)}
          </MobileMenuSheet>
        ))}
      {isDesktop ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className='jusfity-center absolute right-0 top-1/2 flex -translate-y-1/2 transform cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-40 p-2 font-medium'>
            <DropdownMenuCheckboxItem
              className='cursor-pointer p-2'
              onClick={() => {
                setIsEditModalShown(true);
              }}
            >
              Edit
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className='cursor-pointer p-2'
              onClick={handleDeleteLogo}
              disabled={removeLogo.isPending}
            >
              Delete
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <MobileMenuSheet
          triggerButton={
            <span className='jusfity-center absolute right-0 top-1/2 flex -translate-y-1/2 transform cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          }
          closeAfterClick={true}
        >
          <>
            <div
              className='cursor-pointer p-2'
              onClick={() => {
                setIsEditModalShown(true);
              }}
            >
              Edit
            </div>
            <div className='cursor-pointer p-2' onClick={handleDeleteLogo}>
              Delete
            </div>
          </>
        </MobileMenuSheet>
      )}
    </>
  );
};
