import { Separator } from 'components/ui/separator';
import { useMemo, useState } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useGetLogos } from 'components/workspace/brand-library/brand-kit/queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { QuickUploadLogo } from './QuickUploadLogo';
import { useMobileBottomMenuStore } from 'components/ai-scene/mobile-bottom-menu/useMobileBottomMenuStore';

type Props = {};

export const AddLogoTool = (props: Props) => {
  const addAiSceneImage = useAiSceneCreateStore((state) => state.addAiSceneImage);
  const closeMobileBottomMenu = useMobileBottomMenuStore((state) => state.closeMobileBottomMenu);

  const drawLogo = (image: string, id: string) =>
    addAiSceneImage({ id, image, type: 'logo', imageName: 'logo' });

  const { data, isError, isLoading, refetch } = useGetLogos({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load logos.</span>
    </RefetchView>
  );

  const logoItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-2 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No logos found.</span>
        </RefetchView>
      );
    }

    return (
      <div className='flex w-full flex-wrap justify-around gap-x-2.5 gap-y-3'>
        {data?.items.map((logo) => (
          <div
            key={logo.id}
            onClick={() => {
              drawLogo(logo.image_url, logo.id);
              closeMobileBottomMenu();
            }}
            className='flex cursor-pointer flex-col items-center gap-1.5 sm:w-[48%]'
          >
            <div className='h-[132px] w-[132px] overflow-hidden rounded-lg border border-[#EAEAEA] hover:border-2 hover:border-black sm:w-full'>
              <img
                crossOrigin='anonymous'
                src={logo.thumbnail}
                alt='adjust option'
                className='h-full w-full object-contain'
              />
            </div>
            <p className='max-w-[118px] truncate text-[12px] font-semibold'>{logo.name}</p>
          </div>
        ))}
      </div>
    );
  }, [data, isLoading, refetch, drawLogo]);

  return (
    <div className='w-[320px] rounded-lg bg-white p-3 shadow-md  md:w-[95vw] md:shadow-none'>
      <h2 className='text-[15px] font-semibold md:hidden'>Add Logo</h2>
      <Separator className='my-4' />

      <div className='flex flex-col gap-5'>
        <QuickUploadLogo />

        <ScrollArea className='h-[55vh]'>
          {errorView || logoItems}
          <ScrollBar orientation='vertical' />
        </ScrollArea>
      </div>
    </div>
  );
};
