import { RoutePaths } from '@/utils/route/constants';
import { useCraitStore } from '@/utils/store/store';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { EditNameAndImageView } from './EditNameAndImageView';
import { ModalView } from 'components/common/ModalView';
import { useDeleteTemplate, useUpdateTemplateDetail } from './helper';
import { toast } from 'sonner';
import {
  TTemplateResponse,
  useToggleTemplateLibraryMutation,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

export const TemplateActions: FC<
  TTemplateResponse & {
    refetch: () => void;
  }
> = (props) => {
  const navigate = useNavigate();
  const setPendingImageToDraw = useCraitStore((state) => state.setPendingImageToDraw);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const updateTemplateDetail = useUpdateTemplateDetail();
  const deleteTemplateMutation = useDeleteTemplate();
  const toggleTemplateLibraryMutation = useToggleTemplateLibraryMutation();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const isMutationEnabled = props.isOrganizationAsset;

  return (
    <>
      {isEditModalShown &&
        (isDesktop ? (
          <ModalView
            open={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement={<h4 className='text-lg font-medium'>Edit Selected Template</h4>}
          >
            {({ closeModal }) => (
              <EditNameAndImageView
                defaultName={props.name}
                defaultImage={props.url}
                onClose={closeModal}
                isSubmitDisabled={updateTemplateDetail.isPending}
                onSubmit={async (name, image) => {
                  try {
                    if (updateTemplateDetail.isPending) return;

                    await updateTemplateDetail.mutateAsync({ name, image, id: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to update template');
                  }
                }}
              />
            )}
          </ModalView>
        ) : (
          <MobileMenuSheet
            isOpen={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement='Edit Selected Template'
          >
            {({ closeModal }) => (
              <EditNameAndImageView
                defaultName={props.name}
                defaultImage={props.url}
                onClose={closeModal}
                isSubmitDisabled={updateTemplateDetail.isPending}
                onSubmit={async (name, image) => {
                  try {
                    if (updateTemplateDetail.isPending) return;

                    await updateTemplateDetail.mutateAsync({ name, image, id: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to update template');
                  }
                }}
              />
            )}
          </MobileMenuSheet>
        ))}

      {isDesktop ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className='jusfity-center flex cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-40 p-2'>
            {isMutationEnabled && (
              <DropdownMenuCheckboxItem
                className='cursor-pointer px-2 py-1 hover:bg-gray-50'
                onClick={() => {
                  setIsEditModalShown(true);
                }}
              >
                Edit
              </DropdownMenuCheckboxItem>
            )}
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1 hover:bg-gray-50'
              onClick={() => {
                setPendingImageToDraw({
                  title: props.name,
                  id: props.id,
                  url: props.url,
                  type: 'template',
                });
                navigate(`/${RoutePaths.AI_SCENE}`);
              }}
            >
              Use to Generate
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
            {isMutationEnabled ? (
              <>
                <DropdownMenuCheckboxItem
                  className='cursor-pointer px-2 py-1'
                  onClick={async () => {
                    try {
                      if (deleteTemplateMutation.isPending) return;

                      await deleteTemplateMutation.mutateAsync({ id: props.id });
                      props.refetch();
                    } catch (error) {
                      console.log(error);
                      toast.error('Failed to remove template');
                    }
                  }}
                >
                  Delete
                </DropdownMenuCheckboxItem>
              </>
            ) : (
              <DropdownMenuCheckboxItem
                className='cursor-pointer px-2 py-1'
                onClick={async () => {
                  try {
                    if (toggleTemplateLibraryMutation.isPending) return;

                    await toggleTemplateLibraryMutation.mutateAsync({
                      id: props.id,
                      action: 'remove',
                    });
                    props.refetch();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to remove template from library');
                  }
                }}
              >
                Remove From Library
              </DropdownMenuCheckboxItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <MobileMenuSheet
          triggerButton={
            <span className='jusfity-center flex cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          }
          titleElement={props.name}
          closeAfterClick={true}
        >
          {isMutationEnabled && (
            <div
              className='cursor-pointer px-2 py-1 hover:bg-gray-50'
              onClick={() => {
                setIsEditModalShown(true);
              }}
            >
              Edit
            </div>
          )}{' '}
          <div
            className='cursor-pointer px-2 py-1 hover:bg-gray-50'
            onClick={() => {
              setPendingImageToDraw({
                title: props.name,
                id: props.id,
                url: props.url,
                type: 'template',
              });
              navigate(`/${RoutePaths.AI_SCENE}`);
            }}
          >
            Use to Generate
          </div>
          {isMutationEnabled ? (
            <>
              <div
                className='cursor-pointer px-2 py-1'
                onClick={async () => {
                  try {
                    if (deleteTemplateMutation.isPending) return;

                    await deleteTemplateMutation.mutateAsync({ id: props.id });
                    props.refetch();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to remove template');
                  }
                }}
              >
                Delete
              </div>
            </>
          ) : (
            <div
              className='cursor-pointer px-2 py-1'
              onClick={async () => {
                try {
                  if (toggleTemplateLibraryMutation.isPending) return;

                  await toggleTemplateLibraryMutation.mutateAsync({
                    id: props.id,
                    action: 'remove',
                  });
                  props.refetch();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to remove template from library');
                }
              }}
            >
              Remove From Library
            </div>
          )}
        </MobileMenuSheet>
      )}
    </>
  );
};
