import { useLocation, useNavigate, useParams } from 'react-router';
import { AiSceneResultCanvas } from './canvas/AiSceneResultCanvas';
import { SketchingResults } from './canvas/SketchingResults';
import { OutpaintCanvasWrapper } from './canvas/OutpaintCanvasWrapper';
import { AiSceneSideMenus, useMenuStore } from '../side-menu/store';
import { SceneActions } from './scene-action/SceneActions';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';

export const EditContent = () => {
  const navigate = useNavigate();
  const projectId = useParams().projectId;
  const { setActiveMenu } = useMenuStore((state) => ({
    setActiveMenu: state.setActiveMenu,
  }));

  const basePath = useAISceneOrImagePath();

  const navigateToProjectCreatePage = () => {
    navigate(`/${basePath}/${projectId}`);
    setActiveMenu(AiSceneSideMenus.CREATE_TOOL);
  };

  return (
    <section
      className='container relative h-full overflow-auto bg-c-ground pt-4'
      onClick={navigateToProjectCreatePage}
    >
      <div className='flex w-full flex-col items-center gap-2'>
        <div onClick={(e) => e.stopPropagation()} className='flex flex-col gap-3'>
          <SceneActions />
          <AiSceneResultCanvas>
            <SketchingResults />
            <OutpaintCanvasWrapper />
          </AiSceneResultCanvas>
        </div>
      </div>
    </section>
  );
};
