import { AiSceneSideMenus, useMenuStore } from 'components/ai-scene/side-menu/store';
import React, { useEffect } from 'react';
import { TextTool } from './text-tool/TextTool';
import { EditToolContainer } from './edit-tool/EditToolContainer';
import { FiltersTool } from './filters-tool/FiltersTool';
import { AddLogoTool } from './add-logo-tool/AddLogoTool';

export const AiSceneResultTools: React.FC = () => {
  const { activeMenu, setActiveMenu } = useMenuStore((state) => ({
    activeMenu: state.activeMenu,
    setActiveMenu: state.setActiveMenu,
  }));

  useEffect(() => {
    if (activeMenu === AiSceneSideMenus.CREATE_TOOL) {
      setActiveMenu(AiSceneSideMenus.EDIT_TOOL);
    }
  }, [activeMenu, setActiveMenu]);

  return (
    <div className='h-full bg-c-ground'>
      {activeMenu === AiSceneSideMenus.EDIT_TOOL && <EditToolContainer />}
      {activeMenu === AiSceneSideMenus.TEXT_TOOL && <TextTool />}
      {activeMenu === AiSceneSideMenus.FILTERS && <FiltersTool />}
      {activeMenu === AiSceneSideMenus.ADD_LOGO && <AddLogoTool />}
    </div>
  );
};
