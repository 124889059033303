import { RoutePaths } from '@/utils/route/constants';
import { useCraitStore } from '@/utils/store/store';
import { Row, Table } from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { ProjectTypes } from 'pages/workspace/projects/helper';
import { useNavigate } from 'react-router';

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  table: Table<TData>;
}

export function RowActions<TData>({ row, table }: DataTableRowActionsProps<TData>) {
  const meta = table.options.meta;
  const isEditMode = meta?.editedRows[row.id];
  const navigate = useNavigate();
  const setPendingSettingsToUse = useCraitStore((state) => state.setPendingSettingsToUse);

  const deleteRow = (event: React.MouseEvent) => {
    event.stopPropagation();
    meta?.deleteRow(row.index, row.original);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant='ghost'
          disabled={isEditMode}
          className={`flex h-8 w-8 p-0 data-[state=open]:bg-muted ${
            isEditMode ? 'bg-muted' : 'bg-transparent'
          }`}
        >
          <span className='i-mdi-dots-horizontal size-5'></span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' className='w-[160px]'>
        <DropdownMenuItem
          onClick={(event) => {
            event.stopPropagation();
            const originalRow = row.original as any;
            setPendingSettingsToUse({
              summary: originalRow.summary,
              parameters: originalRow.parameters,
            });
            const baseUrl =
              originalRow.parent_project_type === ProjectTypes.AI_SCENE
                ? RoutePaths.AI_SCENE
                : RoutePaths.AI_IMAGE;
            navigate(`/${baseUrl}`);
          }}
        >
          Use Settings
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={deleteRow}>Delete</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
