import Star06Icon from 'assets/icons/star006.svg?react';
import StyleMenuIcon from 'assets/icons/styleMenu.svg?react';
import SettingsIcon from 'assets/icons/settings.svg?react';
import TextTypeIcon from '@/assets/icons/ai-scene-icons/type01.svg?react';
import ColorsIcon from '@/assets/icons/ai-scene-icons/colors.svg?react';
import ImageUpIcon from '@/assets/icons/ai-scene-icons/imageUp.svg?react';

import { VisualGuidanceMenu } from 'components/ai-scene/side-menu/create-tool/visual-guidance/VisualGuidanceMenu';
import { ImageStyleMenu } from 'components/ai-scene/side-menu/create-tool/image-style/ImageStyleMenu';
import { CreateToolContainer } from 'components/ai-scene/side-menu/create-tool/CreateToolContainer';
import { TextTool } from 'components/ai-scene/side-menu/text-tool/TextTool';
import { AddLogoTool } from 'components/ai-scene/side-menu/add-logo-tool/AddLogoTool';
import { FiltersTool } from 'components/ai-scene/side-menu/filters-tool/FiltersTool';

export const MENU_SHEET_NAMES = {
  GUIDANCE: 'guidance',
  STYLE: 'style',
  SETTINGS: 'settings',
  TEXT: 'text',
  FILTER: 'filter',
  LOGO: 'logo',
  PRODUCT: 'product',
  PROMPT: 'prompt',
};

export const menuItemsConfig = [
  {
    label: 'Guidance',
    icon: Star06Icon,
    sheetName: MENU_SHEET_NAMES.GUIDANCE,
    component: <VisualGuidanceMenu onBackClick={() => {}} />,
    title: 'Visual Guidance',
  },
  {
    label: 'Style',
    icon: StyleMenuIcon,
    sheetName: MENU_SHEET_NAMES.STYLE,
    component: <ImageStyleMenu onBackClick={() => {}} />,
    title: 'Image Style',
  },
  {
    label: 'Settings',
    icon: SettingsIcon,
    sheetName: MENU_SHEET_NAMES.SETTINGS,
    component: <CreateToolContainer />,
    title: 'Settings',
  },
  {
    label: 'Text',
    icon: TextTypeIcon,
    sheetName: MENU_SHEET_NAMES.TEXT,
    component: <TextTool />,
    title: 'Text',
  },
  {
    label: 'Filter',
    icon: ColorsIcon,
    sheetName: MENU_SHEET_NAMES.FILTER,
    component: <FiltersTool />,
    title: 'Filter',
  },
  {
    label: 'Logo',
    icon: ImageUpIcon,
    sheetName: MENU_SHEET_NAMES.LOGO,
    component: <AddLogoTool />,
    title: 'Add Logo',
  },
];
