import { ScrollArea } from 'components/ui/scroll-area';
import { AddTemplateModal } from './AddTemplateModal';
import { Command, CommandInput } from 'components/ui/command';
import { TemplateActions } from './TemplateActions';
import { useDebounceValue } from 'usehooks-ts';
import {
  TTemplateResponse,
  useGetInfiniteSearchResults,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { toast } from 'sonner';
import { usePartialUpdateTemplateDetail } from './helper';
import { appendPluralSuffixByNumber, timestampToDate, triggerBlurOnEnter } from '@/utils/helper';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useResetInfiniteSearchCache } from 'components/ai-scene/sidebar/settings-tab/styles/useResetInfiniteSearchCache';
import { BrandTemplateCard } from './BrandTemplateCard';
import { AnimatePresence } from 'framer-motion';

const MAX_PAGES_IN_CACHE = 2;

interface BrandTemplateViewProps {
  children?: ReactNode;
}
export const BrandTemplateView: FC<BrandTemplateViewProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TTemplateResponse>({
    keyword: searchValue,
    onlyOrgLibrary: true,
    searchSection: 'templates',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.templates;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 0,
    refetchOnMount: true,
  });
  useResetInfiniteSearchCache<TTemplateResponse>('templates', MAX_PAGES_IN_CACHE);
  const updatePartialTemplateDetail = usePartialUpdateTemplateDetail();

  const isLoadingView = isLoading && (
    <div className='mx-auto mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load templates.</span>
    </RefetchView>
  );

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allTemplates = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.templates.items) || [];
  }, [infiniteData]);

  const renameTemplate = async ({
    id,
    newName,
    existingName,
  }: {
    id: string;
    newName: string;
    existingName: string;
  }) => {
    try {
      if (existingName === newName) return;
      await updatePartialTemplateDetail.mutateAsync({ id, name: newName });
      refetch();
      toast.success('Template name updated successfully.');
    } catch (error) {
      console.log(error);
      toast.error('Failed to update template name.');
    }
  };

  const hasAnyTemplates = allTemplates.length > 0;
  const templateItems =
    !hasAnyTemplates && !isLoading ? (
      <span>No templates found.</span>
    ) : (
      allTemplates.map((template) => (
        <BrandTemplateCard
          key={template.id}
          id={template.id}
          title={template.name}
          image={template.url}
          previewImage={template.thumbnail}
          updatedDate={timestampToDate(template.last_update_info.timestamp)}
        >
          <TemplateActions {...template} refetch={refetch} />
        </BrandTemplateCard>
      ))
    );

  const totalItemCount = infiniteData?.pages[0]?.templates.totalItems || 0;

  return (
    <div className='h-full'>
      {children}
      <div className='flex items-center justify-between gap-2 md:flex-col lg:flex-col lg:items-start'>
        <div className='flex items-center gap-3'>
          <h4 className='mb-1 flex items-center text-[26px] font-medium'>Template</h4>
          <span className='text-sm font-medium'>
            {totalItemCount} template{appendPluralSuffixByNumber(totalItemCount)}
          </span>
        </div>
        <div className='flex gap-2 md:w-full   md:flex-col-reverse'>
          <Command className='flex h-10 max-w-[200px] justify-center rounded-lg border border-crait-dark-300 bg-transparent md:max-w-full'>
            <CommandInput placeholder='Search template name' onValueChange={setSearchValue} />
          </Command>
          <AddTemplateModal />
        </div>
      </div>
      <ScrollArea className={`-mr-2 mt-6 h-[calc(100%-80px)] pr-2`}>
        {isLoadingView || errorView}
        <div className='mt-10 grid max-w-[1440px] grid-cols-2  gap-6 gap-y-10 sm:gap-3 min-sm:grid-cols-3 min-md:grid-cols-2 min-lg:grid-cols-3 min-xl:grid-cols-4  min-3xl:grid-cols-5'>
          <AnimatePresence>{templateItems}</AnimatePresence>
        </div>
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
