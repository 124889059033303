import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type TOutpaintData = { id: string; url: string; width: number; height: number };

export type TAiSceneOutpaintStore = {
  processingState: 'idle' | 'outpainting' | 'resolved';
  changeProcessingState: (state: TAiSceneOutpaintStore['processingState']) => void;
  outpaintData: TOutpaintData[];
  addOutpaintData: (data: TOutpaintData) => void;
  removeMultiOutpaintData: (ids: string[]) => void;
  clearOutpaintData: () => void;
};

const useAiSceneOutpaintSlice: ImmerStateCreator<TAiSceneOutpaintStore> = (set) => ({
  outpaintData: [],
  addOutpaintData: (data) => {
    set((state) => {
      state.outpaintData.push(data);
    }),
      false,
      'addOutpaintData';
  },
  removeMultiOutpaintData: (ids) => {
    set((state) => {
      state.outpaintData = state.outpaintData.filter((data) => !ids.includes(data.id));
    }),
      false,
      'removeMultiOutpaintData';
  },
  clearOutpaintData: () => {
    set((state) => {
      state.outpaintData = [];
    }),
      false,
      'clearOutpaintData';
  },
  processingState: 'idle',
  changeProcessingState: (state) => {
    set((s) => {
      s.processingState = state;
    }),
      false,
      'changeProcessingState';
  },
});

export const createAiSceneOutpaintStore = (initProps?: Partial<TAiSceneOutpaintStore>) => {
  return createStore<TAiSceneOutpaintStore>()(
    immer((...props) => ({
      ...useAiSceneOutpaintSlice(...props),
      ...initProps,
    })),
  );
};
