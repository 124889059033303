import { Breakpoints } from '@/utils/general/constant';
import { MAX_CANVAS_HISTORY, TImageType } from '@/utils/store/constants';
import { ImmerStateCreator } from '@/utils/store/store';
import { ProductProperties } from 'pages/workspace/brand-library/products/helper';
import { TLastGenerationSummary } from 'pages/workspace/projects/helper';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type TAiSceneImage = {
  id: string;
  image: string;
  type: TImageType;
  imageName: string;
  imageProperties?: ProductProperties | null;
};

export type TAiSceneCreateStore = TAiSceneCreateState & TAiSceneCreateAction;

type TAiSceneCreateState = {
  aiScenePromptTab: {
    prompt: TLastGenerationSummary['prompt'];
    products: TLastGenerationSummary['products'];
    promptSuffixes: string[];
    inspirationalPhoto: File | null;
    faceTextComposition: boolean;
    hasUserManuallySetFaceTextComposition: boolean;
    enhancePrompt: boolean;
    negativePrompt: string;
    recentlyUsedPrompts: string[];
    count: number;
    brandStyleId: TLastGenerationSummary['brand_style'];
    lightingStyleId: TLastGenerationSummary['lighting_style'];
  };
  aiSceneSettingsTab: {
    styleIds: TLastGenerationSummary['styles'];
  };
  aiSceneUploadedImages: Array<TAiSceneImage>;
  canvasState: string;
  aiSceneCanvasInstance: fabric.Canvas | null;
  previousSceneCanvasStates: string[];
  nextSceneCanvasStates: string[];
  activeProjectTitle: string | undefined;
};

type TAiSceneCreateAction = {
  setAiSceneCanvasInstance: (canvas: fabric.Canvas | null) => void;
  addAiSceneImage: (imageInfo: TAiSceneImage) => void;
  removeAiSceneImages: (ids: TAiSceneImage['id'][]) => void;
  resetAiSceneUploadedImages: () => void;
  updateSceneCanvasState: (newState: string, callerId?: string) => void;
  undoSceneCanvasState: () => void;
  redoSceneCanvasState: () => void;
  resetSceneCanvasState: () => void;
  updateAiScenePromptTab: (newState: Partial<TAiSceneCreateState['aiScenePromptTab']>) => void;
  updateAiSceneCount: (newCount: number) => void;
  addPromptSuffix: (suffix: string) => void;
  addAiSceneSelectedStyles: (
    styleIds:
      | NonNullable<TLastGenerationSummary['styles']>
      | NonNullable<TLastGenerationSummary['styles']>[0],
  ) => void;
  removeAiSceneSelectedStyles: (styleId: string) => void;
  updateActiveProjectTitle: (title: TAiSceneCreateState['activeProjectTitle']) => void;
  resetAll: () => void;
};

const MOCK_PROMPTS = [
  'Inside a modern, minimalist living room with a painting on the background.',
  'Transform the setting of the image to an enchanted forest theme, complete with mystical fog and glowing fairy lights.',
  'Alter the backdrop of the image to an underwater oasis, showcasing a vibrant coral reef teeming with colorful marine life',
  'Revise the scene of the image to depict a serene Zen garden at dusk, featuring softly lit lanterns and a tranquil koi pond.',
];

const initialState: TAiSceneCreateState = {
  aiSceneCanvasInstance: null,
  canvasState: '',
  previousSceneCanvasStates: [],
  nextSceneCanvasStates: [],
  aiSceneUploadedImages: [],
  aiScenePromptTab: {
    prompt: null,
    products: null,
    promptSuffixes: [],
    inspirationalPhoto: null,
    faceTextComposition: false,
    hasUserManuallySetFaceTextComposition: false,
    enhancePrompt: false,
    negativePrompt: '',
    recentlyUsedPrompts: MOCK_PROMPTS,
    count: window.innerWidth<Breakpoints.TABLET ? 1 : 4,
    brandStyleId: null,
    lightingStyleId: null,
  },
  aiSceneSettingsTab: {
    styleIds: null,
  },
  activeProjectTitle: undefined,
};

const useAiSceneCreateSlice: ImmerStateCreator<TAiSceneCreateStore> = (set) => ({
  ...initialState,
  setAiSceneCanvasInstance: (canvas) => {
    set((state) => {
      state.aiSceneCanvasInstance = canvas as any;
    }),
      false,
      'setAiSceneCanvasInstance';
  },

  updateActiveProjectTitle: (title) => {
    set((state) => {
      state.activeProjectTitle = title;
    }),
      false,
      'updateActiveProjectTitle';
  },
  addAiSceneSelectedStyles: (styles) => {
    set(
      (state) => {
        const newStyles = Array.isArray(styles) ? styles : [styles];
        state.aiSceneSettingsTab.styleIds = newStyles;
      },
      false,
      'addAiSceneSelectedStyles',
    );
  },
  addPromptSuffix: (suffix) => {
    set((state) => {
      if (!state.aiScenePromptTab.promptSuffixes.includes(suffix)) {
        state.aiScenePromptTab.promptSuffixes.push(suffix);
      }
    }),
      false,
      'addPromptSuffix';
  },
  updateAiScenePromptTab: (newState) => {
    set((state) => {
      state.aiScenePromptTab = { ...state.aiScenePromptTab, ...newState };
    }),
      false,
      'updateAiScenePromptTab';
  },
  updateAiSceneCount: (newCount) => {
    set((state) => {
      if (newCount >= 1 && newCount <= 4) {
        state.aiScenePromptTab.count = newCount;
      }
    }),
      false,
      'updateCount';
  },
  removeAiSceneSelectedStyles: (styleId) => {
    set((state) => {
      const currentStyles = state.aiSceneSettingsTab.styleIds;
      if (currentStyles) {
        state.aiSceneSettingsTab.styleIds = currentStyles.filter((style) => style.id !== styleId);
      }
    }),
      false,
      'removeAiSceneSelectedStyles';
  },
  addAiSceneImage: (imagePayload) => {
    set((state) => {
      state.aiSceneUploadedImages = [...state.aiSceneUploadedImages, imagePayload];
    }),
      false,
      'addAiSceneImage';
  },
  removeAiSceneImages: (ids) => {
    set((state) => {
      state.aiSceneUploadedImages = state.aiSceneUploadedImages.filter(
        (image) => !ids.includes(image.id),
      );
    }),
      false,
      'removeAiSceneImages';
  },
  resetAiSceneUploadedImages: () => {
    set((state) => {
      state.aiSceneUploadedImages = [];
    }),
      false,
      'resetAiSceneUploadedImages';
  },
  resetSceneCanvasState: () => {
    set(
      (state) => {
        state.canvasState = '';
        state.previousSceneCanvasStates = [];
        state.nextSceneCanvasStates = [];
      },
      false,
      'resetSceneCanvasState',
    );
  },
  updateSceneCanvasState: (newState, callerId) => {
    set(
      (state) => {
        if (!newState) {
          state.canvasState = newState;
          state.previousSceneCanvasStates = [];
          state.nextSceneCanvasStates = [];
          return;
        }

        // The current state must be saved to the history before state update,
        // otherwise the prev state will be same as the latest state.
        if (state.canvasState) {
          const newPrev = [...state.previousSceneCanvasStates, state.canvasState].slice(
            -MAX_CANVAS_HISTORY,
          );
          // Add the current state to history only if it's not the initial setup
          state.previousSceneCanvasStates = newPrev;
        }

        state.canvasState = newState;
        state.nextSceneCanvasStates = [];
      },
      false,
      'updateSceneCanvasState',
    );
  },
  undoSceneCanvasState: () => {
    set(
      (state) => {
        if (state.previousSceneCanvasStates.length === 0) return;

        // Add the current state to the start of next states before changing it.
        state.nextSceneCanvasStates = [state.canvasState, ...state.nextSceneCanvasStates].slice(
          0,
          MAX_CANVAS_HISTORY,
        );

        const previousState =
          state.previousSceneCanvasStates[state.previousSceneCanvasStates.length - 1];
        const newPreviousSceneCanvasStates = state.previousSceneCanvasStates.slice(0, -1);

        state.canvasState = previousState;
        state.previousSceneCanvasStates = newPreviousSceneCanvasStates;
      },
      false,
      'undoSceneCanvasState',
    );
  },
  redoSceneCanvasState: () => {
    set(
      (state) => {
        if (state.nextSceneCanvasStates.length === 0) return;

        // Add the current state to the end of previous states before changing it
        state.previousSceneCanvasStates = [
          ...state.previousSceneCanvasStates,
          state.canvasState,
        ].slice(-MAX_CANVAS_HISTORY);

        const nextState = state.nextSceneCanvasStates[0];
        const newNextSceneCanvasStates = state.nextSceneCanvasStates.slice(1);

        state.canvasState = nextState;
        state.nextSceneCanvasStates = newNextSceneCanvasStates;
      },
      false,
      'redoSceneCanvasState',
    );
  },
  resetAll: () => {
    set(initialState), false, 'resetAll';
  },
});

export const createAiSceneStore = (initProps?: Partial<TAiSceneCreateStore>) => {
  return createStore<TAiSceneCreateStore>()(
    devtools(
      immer((...props) => ({
        ...useAiSceneCreateSlice(...props),
        ...initProps,
      })),
      { name: 'AiSceneStore', trace: true },
    ),
  );
};
