import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { ReactNode } from 'react';
import { MultiResizeLayout } from './MultiResizeLayout';
import { HowItWorksBox } from 'components/ai-scene/side-menu/create-tool/visual-guidance/HowItWorksBox';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';

export const ExpandMenu = ({
  onBackClick,
  children,
}: {
  onBackClick: () => void;
  children: ReactNode;
}) => {
  const clearOutpaintData = useAiSceneOutpaintStore((state) => state.clearOutpaintData);

  return (
    <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md'>
      <button
        onClick={() => {
          onBackClick();
          clearOutpaintData();
        }}
        className='flex gap-2 text-[15px] font-semibold'
      >
        <ArrowLeftIcon />
        Expand
      </button>
      <Separator />
      <HowItWorksBox
        text='Expands your image by filling the empty areas based on the chosen dimensions.'
        linkText="Don't show this again"
        parentComponent='ImageExpand'
      />
      {children}
      <MultiResizeLayout />
    </div>
  );
};
