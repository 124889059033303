import { FC, ReactNode, useRef } from 'react';
import { createAiSceneOutpaintStore } from './store';
import { AiSceneOutpaintContext, TAiSceneOutpaintContext } from './helper';
import { useUnmountWithResetContext } from '@/providers/store-resetter/StoreResetterProvider';

interface AiSceneOutpaintProviderProps {
  children: ReactNode;
}

export const AiSceneOutpaintProvider: FC<AiSceneOutpaintProviderProps> = ({ children }) => {
  const storeRef = useRef<TAiSceneOutpaintContext>();
  useUnmountWithResetContext(() => {
    storeRef.current = undefined;
  });

  if (!storeRef.current) {
    storeRef.current = createAiSceneOutpaintStore();
  }

  return (
    <AiSceneOutpaintContext.Provider value={storeRef.current}>
      {children}
    </AiSceneOutpaintContext.Provider>
  );
};
