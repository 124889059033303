import PlanIcon from '@/assets/icons/planElement.svg?react';
import { CraitLogo } from 'components/common/CraitLogo';

export const AuthOverlayContent = () => {
  return (
    <div className='absolute left-1/2 top-1/2 flex  w-[80%] max-w-[560px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-10 rounded-xl bg-white px-10 py-20 shadow-lg'>
      <CraitLogo />
      <h3 className='mb-4 flex flex-col text-[36px] font-semibold'>
        <span>Effortless Creativity,</span>
        <span> AI-Driven Excellence</span>
      </h3>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center gap-2'>
          <PlanIcon className='size-7' />
          Create quality product photography in seconds
        </div>
        <div className='flex items-center gap-2'>
          <PlanIcon className='size-7' />
          Customize visuals with your brand's style
        </div>
        <div className='flex items-center gap-2'>
          <PlanIcon className='size-7' />
          Drive creative discussions with AI-insight
        </div>
      </div>
    </div>
  );
};
