import { cn } from '@/lib/utils';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { FC, ReactNode } from 'react';

interface CanvasLockViewProps {
  children: ReactNode;
}
export const OutpaintCanvasLockView: FC<CanvasLockViewProps> = ({ children }) => {
  const processingState = useAiSceneOutpaintStore((state) => state.processingState);

  if (processingState !== 'outpainting') return null;

  return (
    <div
      className={cn(
        `absolute left-1/2 top-0 z-50 h-full w-full -translate-x-1/2 transform select-none bg-white/30`,
      )}
    >
      <div className='flex h-full w-full flex-col items-center justify-center gap-3'>
        <LoadingSpinner />
        {children}
      </div>
    </div>
  );
};
