import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { FileUploadInput } from 'components/workspace/brand-library/products/upload/FileUploadInput';
import { useUploadStyle } from 'components/workspace/brand-library/style/helper';
import { ProgressComponent } from 'components/ai-scene/side-menu/add-logo-tool/QuickUploadLogo';
import UploadIcon from '@/assets/icons/ai-scene-icons/upload.svg?react';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { useUpgradeWarningModalStore } from 'components/plan-guard/useUpgradeWarningModalStore';
import { isPlanSufficient } from 'components/plan-guard/helper';
import { FeatureAccessRequirements, PlanType } from 'components/plan-guard/constants';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { getFileNameWithoutExtension } from '@/utils/helper';

interface AddInspirationModalProps {
  selectOnlyLibraryCategory: () => void;
}

export const AddInspirationModal: React.FC<AddInspirationModalProps> = ({
  selectOnlyLibraryCategory,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const uploadStyleRequest = useUploadStyle();
  const queryClient = useQueryClient();

  const { plan: userPlan } = useAccountCtx();
  const { showUpgradeModal } = useUpgradeWarningModalStore();

  const isSufficient = isPlanSufficient(
    userPlan,
    FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.requiredPlan as PlanType,
  );

  const checkUserPlanAndShowModal = useCallback((): boolean => {
    if (!isSufficient) {
      showUpgradeModal(
        FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.requiredPlan,
        FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.featureDescription,
      );
    }

    return isSufficient;
  }, [userPlan, showUpgradeModal]);

  const checkPlanAndProceed = usePlanCheck({
    requiredPlan: FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.requiredPlan,
    onClick: () => {},
    featureDescription: FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.featureDescription,
  });

  useEffect(() => {
    if (file) {
      handleUploadStyle(file);
    }
  }, [file]);

  const handleUploadStyle = async (file: File) => {
    try {
      if (uploadStyleRequest.isPending) return;
      if (!file) {
        toast.error('Please select a file');
        return;
      }

      await uploadStyleRequest.mutateAsync({
        image: file,
        name: getFileNameWithoutExtension(file.name),
      });
      queryClient.invalidateQueries({
        queryKey: [{ searchSection: 'styles' }],
        exact: false,
      });
      toast.success('Quick upload successful.');
      selectOnlyLibraryCategory();
    } catch (error) {
      console.log(error);
      toast.error('Failed to upload style');
    }
  };

  return (
    <div className='flex flex-col justify-between gap-1 sm:w-[48%]' onClick={checkPlanAndProceed}>
      {uploadStyleRequest.isPending ? (
        <ProgressComponent className='m-0 !h-[136px] !w-[136px] p-0 sm:!w-full' />
      ) : (
        <FileUploadInput
          onFileUpload={setFile}
          titleElement={<span className='hidden'></span>}
          dropAreaContent={<UploadIcon />}
          className='h-[136px] w-[136px] border-none bg-background sm:w-full'
          checkExternalValidation={checkUserPlanAndShowModal}
        />
      )}
      <span className='text-center text-[12px] font-semibold'>Add new</span>
    </div>
  );
};
