import { Separator } from 'components/ui/separator';
import React from 'react';

import { getRandomImg, serializeCanvas } from '@/utils/helper';
import { FILTERS_DATA } from 'components/ai-scene/create/edit-drawer/filters.data';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  applyNewFilter,
  getUniqueFilterName,
  updateFilterValue,
} from 'components/ai-scene/create/edit-drawer/helper';
import { fabric } from 'fabric';
import 'components/ai-scene/create/edit-drawer/filters.css';
import { Slider } from 'components/ui/slider';
import { useDebounceCallback } from 'usehooks-ts';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import MOCK_IMAGE from '@/assets/images/filter_menu.png';

const DEFAULT_FILTER_VALUE = 0.5;

type Props = {};

export const FiltersTool = (props: Props) => {
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);

  const lastClickedRef = useRef<HTMLElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<
    | ((typeof FILTERS_DATA)[number] & {
        value: number;
      })
    | undefined
  >(undefined);

  const onSliderValueChange = useCallback(
    (values: number[]) => {
      if (!selectedFilter || !canvasInstance) return;

      const activeObject = canvasInstance?.getActiveObject();
      if (!activeObject || !(activeObject instanceof fabric.Image)) return;

      updateFilterValue({
        imgObject: activeObject,
        filterType: selectedFilter.filterType,
        valueKey: selectedFilter.valueKey as keyof fabric.IBaseFilter,
        value: values[0],
      });
      canvasInstance.requestRenderAll();
    },
    [canvasInstance, selectedFilter, updateCanvasState],
  );

  const debouncedSliderChange = useDebounceCallback(onSliderValueChange, 300);

  useEffect(() => {
    if (!canvasInstance) return;

    const selectionHandler = () => {
      const activeObject = canvasInstance.getActiveObject();
      if (
        !activeObject ||
        !(activeObject instanceof fabric.Image) ||
        !activeObject.filters ||
        activeObject.filters.length < 1
      ) {
        if (lastClickedRef.current) {
          lastClickedRef.current.classList.remove('active');
          lastClickedRef.current = null;
          setSelectedFilter(undefined);
        }
        return;
      }

      const activeFilter = activeObject.filters[0];
      if (!activeFilter) return;

      FILTERS_DATA.forEach((filterData) => {
        const filterElement = document.getElementById(getUniqueFilterName(filterData.name));
        if (!filterElement) return;

        // @ts-expect-error - type mismatch
        if (filterData.filterType.prototype.type === activeFilter.type) {
          // @ts-expect-error - type mismatch
          const activeFilterValue = activeFilter[filterData.valueKey] || DEFAULT_FILTER_VALUE;
          filterElement.classList.add('active');
          lastClickedRef.current = filterElement;
          setSelectedFilter({
            ...filterData,
            value: activeFilterValue,
          });
        } else {
          filterElement.classList.remove('active');
        }
      });
    };

    canvasInstance.on('selection:created', selectionHandler);
    canvasInstance.on('selection:updated', selectionHandler);
    canvasInstance.on('selection:cleared', selectionHandler);

    return () => {
      canvasInstance.off('selection:created', selectionHandler);
      canvasInstance.off('selection:updated', selectionHandler);
      canvasInstance.off('selection:cleared', selectionHandler);
    };
  }, [canvasInstance]);

  const toggleSelectedItemBorder = (event: React.MouseEvent<HTMLElement>) => {
    const clickedElement = event.currentTarget;

    // If there's a previously clicked element, remove its 'active' class
    if (lastClickedRef.current && lastClickedRef.current !== clickedElement) {
      lastClickedRef.current.classList.remove('active');
    }

    const isAlreadyActive = lastClickedRef.current === clickedElement;
    // Toggle the 'active' class only if the element wasn't already active
    if (!isAlreadyActive) {
      clickedElement.classList.add('active');
      lastClickedRef.current = clickedElement;
    } else {
      // If the same element was clicked again, remove its 'active' class and update the ref
      clickedElement.classList.remove('active');
      lastClickedRef.current = null;
    }
  };

  const applyFilterToSelectedObject = (
    event: React.MouseEvent<HTMLDivElement>,
    filter: (typeof FILTERS_DATA)[0],
  ) => {
    const activeObject = canvasInstance?.getActiveObject();
    if (!canvasInstance || !(activeObject instanceof fabric.Image)) return;

    toggleSelectedItemBorder(event);
    const eventType = applyNewFilter({
      imgObject: activeObject,
      filterType: filter.filterType,
      valueKey: filter.valueKey as keyof fabric.IBaseFilter,
      value: DEFAULT_FILTER_VALUE,
    });
    canvasInstance.requestRenderAll();
    updateCanvasState(serializeCanvas(canvasInstance));
    const newFilter = eventType === 'add' ? { ...filter, value: DEFAULT_FILTER_VALUE } : undefined;
    setSelectedFilter(newFilter);
  };

  const filterItems = useMemo(
    () =>
      FILTERS_DATA.map((filter) => (
        <div
          id={getUniqueFilterName(filter.name)}
          key={filter.name}
          onClick={(e) => {
            applyFilterToSelectedObject(e, filter);
          }}
          className={`group flex cursor-pointer flex-col items-center justify-between`}
        >
          <div className='relative h-[132px] w-[132px] overflow-hidden rounded-lg group-hover:border-2 group-hover:border-black group-[.active]:border-2 group-[.active]:border-black'>
            <img
              src={MOCK_IMAGE}
              alt='adjust option'
              className={`absolute left-0 top-0 h-full w-full object-cover ${filter.filterClass}`}
            />
          </div>
          <p className='text-[12px] font-semibold'>{filter.name}</p>
        </div>
      )),
    [],
  );

  return (
    <div className='w-[320px] rounded-lg bg-white p-4 shadow-md'>
      <h2 className='text-[15px] font-semibold'>Filters</h2>
      <Separator className='my-4' />
      <div
        className='z-10 flex w-full items-center justify-center gap-6 px-1'
        onBlur={() => {
          setSelectedFilter(undefined);
        }}
      >
        <label className='text-[12px] font-semibold'>Intensity</label>
        <Slider
          disabled={selectedFilter === undefined}
          key={selectedFilter?.name}
          min={0.1}
          step={0.1}
          max={1}
          defaultValue={[selectedFilter?.value || 0]}
          className='mx-auto h-14 w-[90%] border-none bg-transparent text-xl font-bold'
          onValueChange={debouncedSliderChange}
        />
      </div>

      <div>
        <div className='flex w-full flex-wrap justify-around gap-x-2.5 gap-y-3'>{filterItems}</div>
      </div>
    </div>
  );
};
