import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import MarkAreaIcon from '@/assets/icons/ai-scene-icons/addBrush.svg?react';
import RemoveMarkIcon from '@/assets/icons/ai-scene-icons/removeBrush.svg?react';
import { Slider } from 'components/ui/slider';
import { CraitButton } from 'components/common/CraitButton';
import { useEffect, useRef, useState } from 'react';
import {
  DrawingCursorSize,
  applyCustomCursor,
  generateDrawingCursorUrl,
} from 'components/ai-scene/review-edit/actions/helper';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import {
  removeOtherPaths,
  removeTargetedPaths,
} from 'components/ai-scene/review-edit/canvas/helper';
import { toast } from 'sonner';
import {
  cancelDrawingMode,
  useEscToCancelDrawingMode,
} from '@/hooks/canvas/useEscToCancelDrawingMode';
import {
  usePartialDetailMutation,
  useGetEditProcessStatusMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import {
  cloneAndScaleCanvasWithObjects,
  convertFabricCanvasToFile,
  mutateObjectToNonSelectable,
  serializeCanvas,
} from '@/utils/helper';
import { fabric } from 'fabric';
import { TooltipItem } from 'components/common/TooltipItem';
import { PROCESSING_STATUSES } from 'components/ai-scene/review/queries';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white w-[142px] !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

export const PartialDetailTab = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const brushSize = useRef<number>(DrawingCursorSize.DEFAULT);
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const promptContainerRef = useRef<HTMLDivElement>(null);
  useEscToCancelDrawingMode(canvasInstance);
  const partialDetailMutation = usePartialDetailMutation();
  const { mutate: getEditProcessStatusMutate } = useGetEditProcessStatusMutation();
  const { projectId, sceneId } = useParams();
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const { refetchScenes } = useRefetchGeneratedScenes();

  const cancelPrompt = () => {
    if (!canvasInstance) return;
    canvasInstance.isDrawingMode = false;
    canvasInstance.freeDrawingCursor = 'default';

    removeTargetedPaths({
      canvasInstance,
      drawingType: 'freeSelect',
      shouldRenderAfterwards: true,
    });
    setIsButtonDisabled(true);
  };

  const sendPrompt = async () => {
    try {
      if (!canvasInstance) return;
      invariant(projectId, 'Project id not found.');
      invariant(sceneId, 'Scene id not found.');

      changeProcessingState('pending');
      const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvasInstance);
      const offscreenCanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
        width: scaledCanvas.getWidth(),
        height: scaledCanvas.getHeight(),
        renderOnAddRemove: false,
      });
      const scaledDrawnPaths = scaledCanvas
        .getObjects('path')
        .filter((path) => (path as any).drawingType === 'freeSelect');
      const clonedPathPromises = scaledDrawnPaths.map(
        (path) =>
          new Promise((resolve) => {
            path.clone((clonedPath: typeof path) => {
              clonedPath.set({
                fill: undefined,
                stroke: 'white',
              });
              offscreenCanvas.add(clonedPath);
              resolve(true);
            });
          }),
      );
      const setBgColorPromise = new Promise((resolve) => {
        offscreenCanvas.setBackgroundColor('black', () => {
          offscreenCanvas.renderAll();
          resolve(true);
        });
      });
      await Promise.all([...clonedPathPromises, setBgColorPromise]);
      const pathBlackWhiteMaskFile = convertFabricCanvasToFile({
        canvas: offscreenCanvas,
        fileName: 'path-mask',
        extension: 'jpeg',
      });
      const result = await partialDetailMutation.mutateAsync({
        sceneId,
        projectId,
        mask: pathBlackWhiteMaskFile,
      });
      const pollPartialDetailStatus = async () => {
        getEditProcessStatusMutate(
          {
            sceneId,
            projectId,
            versionId: result[0].versionId,
          },
          {
            onSuccess: async (data) => {
              if (PROCESSING_STATUSES.includes(data.status)) {
                setTimeout(pollPartialDetailStatus, 1000);
                return;
              }
              if (data.status !== 'ready') {
                toast.error('Failed to apply partial detail. Please try again.');
                changeProcessingState('idle');
                return;
              }

              const templateImage = canvasInstance
                .getObjects()
                .find((obj) => obj.imageType === 'template') as fabric.Image;
              invariant(templateImage, 'No base image found to revert magic erase.');
              templateImage.animate('opacity', 0.5, {
                duration: 200,
                onChange: canvasInstance.renderAll.bind(canvasInstance),
                onComplete: () => {
                  templateImage.setSrc(
                    data.imageUrl,
                    () => {
                      templateImage.animate('opacity', 1, {
                        duration: 200,
                        onChange: canvasInstance.renderAll.bind(canvasInstance),
                      });
                    },
                    { crossOrigin: 'anonymous' },
                  );
                },
              });
              refetchScenes();
              updateCanvasState(serializeCanvas(canvasInstance));
              changeProcessingState('idle');
              cancelPrompt();
            },
            onError: (err) => {
              console.error(err);
              toast.error('Failed to apply partial detail. Please try again.');
              changeProcessingState('idle');
            },
          },
        );
      };
      pollPartialDetailStatus();
    } catch (e) {
      console.error(e);
      toast.error('Failed to apply partial detail');
      changeProcessingState('idle');
    }
  };

  useEffect(() => {
    if (!canvasInstance) return;

    const handlePathCreated = (event: fabric.IEvent) => {
      if (!('path' in event) || !event.path) return;

      const currentDrawingType = canvasInstance.currentDrawingType;
      if (currentDrawingType !== 'freeSelect') return;

      (event.path as any).drawingType = currentDrawingType;
      mutateObjectToNonSelectable(event.path as fabric.Object);
      setIsButtonDisabled(false);
    };

    const handlePathRemoved = (event: fabric.IEvent) => {
      if (event.target && event.target.type === 'path') {
        updateCanvasState(serializeCanvas(canvasInstance));
      }
    };

    canvasInstance.on('path:created', handlePathCreated);
    canvasInstance.on('object:removed', handlePathRemoved);
    return () => {
      canvasInstance.off('path:created', handlePathCreated);
      canvasInstance.off('object:removed', handlePathRemoved);
    };
  }, [canvasInstance, updateCanvasState]);

  useEffect(() => {
    enableFreeSelect();

    return () => {
      cancelPrompt();
    };
  }, [canvasInstance]);

  const enableFreeSelect = () => {
    try {
      if (!canvasInstance) return;

      canvasInstance.isDrawingMode = true;
      canvasInstance.currentDrawingType = 'freeSelect';
      removeOtherPaths({ canvasInstance, drawingType: 'freeSelect', shouldRenderAfterwards: true });

      canvasInstance.freeDrawingBrush.color = 'rgba(169, 246, 173, 0.7)';
      canvasInstance.freeDrawingBrush.width = brushSize.current;
      const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
      applyCustomCursor(canvasInstance, cursorUrl);
    } catch (error) {
      console.error('Error while enabling free select', error);
      toast.error('Error while enabling free select');
    }
  };

  return (
    <div className='relative flex flex-col gap-5'>
      <div className='flex flex-col gap-7'>
        <div className='flex flex-col gap-2'>
          <h3 className='text-main font-semibold'>Select Tool</h3>
          <Tabs defaultValue='mark-area' className='w-[400px] '>
            <TabsList className='roundet-[6px] border border-[#F0F0F0] bg-white'>
              <TabsTrigger
                className={TAB_TRIGGER_CLASS}
                value='mark-area'
                onClick={enableFreeSelect}
              >
                <MarkAreaIcon />
              </TabsTrigger>
              <TooltipItem
                side='bottom'
                trigger={
                  <TabsTrigger disabled={true} className={TAB_TRIGGER_CLASS} value='remove-mark'>
                    <RemoveMarkIcon />
                  </TabsTrigger>
                }
              >
                Coming Soon
              </TooltipItem>
            </TabsList>
          </Tabs>
        </div>

        <div className='flex cursor-pointer items-center justify-between'>
          <h3 className=' whitespace-nowrap text-main font-semibold'>Brush Size</h3>
          <Slider
            className='w-[50%] bg-crait-green-primary'
            step={5}
            min={DrawingCursorSize.MIN}
            max={DrawingCursorSize.MAX}
            defaultValue={[brushSize.current]}
            onValueChange={(values) => {
              if (!canvasInstance) return;

              brushSize.current = values[0];
              canvasInstance.freeDrawingBrush.width = brushSize.current;
              const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
              applyCustomCursor(canvasInstance, cursorUrl);
            }}
          />
        </div>
        <div ref={promptContainerRef} className=' flex  transform flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <CraitButton className='w-full' onClick={sendPrompt} disabled={isButtonDisabled}>
              Restore
            </CraitButton>
          </div>
        </div>
      </div>
    </div>
  );
};
