import { CraitButton } from 'components/common/CraitButton';
import { Separator } from 'components/ui/separator';
import { Textarea } from 'components/ui/textarea';
import { Button } from 'components/ui/button';
import { useCallback, useMemo, useState } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useGetLogos } from 'components/workspace/brand-library/brand-kit/queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { QuickUploadLogo } from './QuickUploadLogo';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { parseImageWithFabric } from 'components/ai-scene/create/editor/canvas/helper';
import { nanoid } from 'nanoid';
import { serializeCanvas } from '@/utils/helper';

type Props = {};

const OBJECT_PLACEMENT_BUFFER = 20;

export const AddLogoTool = (props: Props) => {
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);

  const drawLogo = useCallback(
    async (image: string) => {
      const fabricRef = canvasInstance;
      if (!fabricRef) return;

      const parsedImage = await parseImageWithFabric({
        image,
        id: nanoid(),
        maxHeight: fabricRef.getHeight() - OBJECT_PLACEMENT_BUFFER * 2,
        maxWidth: fabricRef.getWidth() - OBJECT_PLACEMENT_BUFFER * 2,
        placementBuffer: OBJECT_PLACEMENT_BUFFER,
      });
      parsedImage.image.imageId = parsedImage.id;
      fabricRef.add(parsedImage.image);
      fabricRef.requestRenderAll();
      updateCanvasState(serializeCanvas(fabricRef));
    },
    [canvasInstance, updateCanvasState],
  );

  const { data, isError, isLoading, refetch } = useGetLogos({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load logos.</span>
    </RefetchView>
  );

  const logoItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-2 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No logos found.</span>
        </RefetchView>
      );
    }

    return (
      <div className='flex w-full flex-wrap justify-around gap-x-2.5 gap-y-3'>
        {data?.items.map((logo) => (
          <div
            key={logo.id}
            onClick={() => {
              drawLogo(logo.image_url);
            }}
            className='flex cursor-pointer  flex-col items-center gap-1.5'
          >
            <div className='h-[132px] w-[132px] overflow-hidden rounded-lg border border-[#EAEAEA] hover:border-2 hover:border-black'>
              <img
                crossOrigin='anonymous'
                src={logo.thumbnail}
                alt='adjust option'
                className='h-full w-full object-contain'
              />
            </div>
            <p className='max-w-[118px] truncate text-[12px] font-semibold'>{logo.name}</p>
          </div>
        ))}
      </div>
    );
  }, [data, isLoading, refetch, drawLogo]);

  return (
    <div className='w-[320px] rounded-lg bg-white p-3 shadow-md'>
      <h2 className='text-[15px] font-semibold'>Add Logo</h2>
      <Separator className='my-4' />

      <div className='flex flex-col gap-5'>
        <QuickUploadLogo />

        <ScrollArea className='h-[55vh]'>
          {errorView || logoItems}
          <ScrollBar orientation='vertical' />
        </ScrollArea>
      </div>
    </div>
  );
};
