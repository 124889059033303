import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import {
  BrandLibraryPaths,
  AccountPaths,
  RoutePaths,
  WorkspacePaths,
  CreativeSuitePaths,
} from './constants';
import { HomePage } from 'pages/home/HomePage';
import { LayoutPage } from 'pages/layout/LayoutPage';
import { DashboardProfilePage } from 'pages/dashboard/profile/DashboardProfilePage';
import { DashboardPage } from 'pages/dashboard/DashboardPage';
import { DashboardPeoplePage } from 'pages/dashboard/people/DashboardPeoplePage';
import { DashboardPaymentPage } from 'pages/dashboard/payment/DashboardPaymentPage';
import { PlansView } from 'components/dashboard/payment/PlansView';
import { NotFound } from 'components/not-found/NotFound';
import { WorkspacePage } from 'pages/workspace/WorkspacePage';
import { WorkspaceProjectsPage } from 'pages/workspace/projects/WorkspaceProjectsPage';
import { WorkspaceBrandLibraryPage } from 'pages/workspace/brand-library/WorkspaceBrandLibraryPage';
import { WorkspaceHistoryPage } from 'pages/workspace/history/WorkspaceHistoryPage';
import { WorkspaceSavedSettingsPage } from 'pages/workspace/saved-settings/WorkspaceSavedSettingsPage';
import { BrandLibraryBannerPage } from 'pages/workspace/brand-library/banner/BrandLibraryBannerPage';
import { BrandLibraryTemplatePage } from 'pages/workspace/brand-library/layout/BrandLibraryTemplatePage';
import { BrandLibraryStylesPage } from 'pages/workspace/brand-library/styles/BrandLibraryStylesPage';
import { BrandLibraryElementsPage } from 'pages/workspace/brand-library/elements/BrandLibraryElementsPage';
import { BrandLibraryKitPage } from 'pages/workspace/brand-library/brand-kit/BrandLibraryKitPage';
import { BrandLibraryProductsPage } from 'pages/workspace/brand-library/products/BrandLibraryProductsPage';
import { AiScenePage } from 'pages/ai-scene/AiScenePage';
import { BrandLibraryProductsEditPage } from 'pages/workspace/brand-library/product-edit/BrandLibraryProductsEditPage';
import { BrandLibraryProductsEditTablePage } from 'pages/workspace/brand-library/bulk-products/table/BrandLibraryProductsEditTablePage';
import { AiSceneReviewPage } from 'pages/ai-scene/review/AiSceneReviewPage';
import { AiSceneReviewEditPage } from 'pages/ai-scene/review-edit/AiSceneReviewEditPage';
import { AiBannerPage } from 'pages/ai-banner/AiBannerPage';
import { AiBannerCreatePage } from 'pages/ai-banner/create/AiBannerCreatePage';
import { AiBannerEditPage } from 'pages/ai-banner/edit/AiBannerEditPage';
import { AiBannerReviewPage } from 'pages/ai-banner/review/AiBannerReviewPage';
import { AiSceneCreateProvider } from '@/providers/ai-scene/create/AiSceneCreateProvider';
import { AiSceneResultProvider } from '@/providers/ai-scene/result/AiSceneResultProvider';
import { ProductCreateProvider } from '@/providers/brand-library/product-create/ProductCreateProvider';
import { ProtectedRoute } from './ProtectedRoute';
import { LoginPage } from 'pages/login/LoginPage';
import { SignUpPage } from 'pages/sign-up/SignUpPage';
import { PasswordForgotPage } from 'pages/password-forgot/PasswordForgotPage';
import { AccountProvider } from 'pages/dashboard/profile/AccountProvider';
import { AiSceneWithoutProjectIdPath } from 'pages/ai-scene/AiSceneWithoutProjectIdPath';
import { AiSceneCreate } from 'components/ai-scene/create/AiSceneCreate';
import { ProjectProvider } from 'pages/ai-scene/create/ProjectProvider';
import { BrandLibraryProductCreatePage } from 'pages/workspace/brand-library/product-create/BrandLibraryProductCreatePage';
import { AiSceneOutpaintProvider } from '@/providers/ai-scene/outpaint/AiSceneOutpaintProvider';
import { ToneOfVoicePage } from 'pages/workspace/brand-library/brand-kit/ToneOfVoicePage';
import { BannerCreateProvider } from '@/providers/ai-banner/create/BannerCreateProvider';
import { GoogleVerifyPage } from 'pages/google-verify/GoogleVerifyPage';
import { CreativeSuitePage } from 'pages/creative-suite/CreativeSuitePage';
import { CustomizeProductPage } from 'pages/workspace/brand-library/bulk-products/edit/CustomizeProductPage';
import { BulkProductsProvider } from 'pages/workspace/brand-library/bulk-products/provider/BulkProductsProvider';
import { BulkProductsPage } from 'pages/workspace/brand-library/bulk-products/BulkProductsPage';
import { SubscriptionProvider } from '@/providers/stripe/subscription/SubscriptionProvider';
import { ResultProviderResetWrapper } from '@/providers/ai-scene/result/ResultProviderResetWrapper';
import { EmailConfirmPage } from 'pages/email-confirm/EmailConfirmPage';
import { PasswordResetPage } from 'pages/password-reset/PasswordResetPage';
import { EmailUnsubscribePage } from 'pages/email-unsubscribe/EmailUnsubscribePage';
import { AiEditCreate } from 'components/ai-edit/create/AiEditCreate';

const createAiSceneRoute = (basePath: string) => ({
  path: basePath,
  element: (
    <AiSceneCreateProvider>
      <AiScenePage />
    </AiSceneCreateProvider>
  ),
  children: [
    {
      index: true,
      element: <AiSceneWithoutProjectIdPath />,
    },
    {
      path: ':projectId',
      element: (
        <ProjectProvider>
          <AiSceneCreate />
        </ProjectProvider>
      ),
    },
    {
      path: ':projectId/generation',
      element: <AiSceneReviewPage />,
    },
    {
      path: ':projectId/generation/:sceneId',
      element: (
        <ResultProviderResetWrapper>
          <AiSceneResultProvider>
            <AiSceneOutpaintProvider>
              <AiSceneReviewEditPage />
            </AiSceneOutpaintProvider>
          </AiSceneResultProvider>
        </ResultProviderResetWrapper>
      ),
    },
  ],
});

const routes: RouteObject[] = [
  { path: RoutePaths.LOGIN, element: <LoginPage /> },
  {
    path: RoutePaths.GOOGLE_VERIFY,
    element: <GoogleVerifyPage />,
  },
  { path: RoutePaths.EMAIL_UNSUBSCRIBE, element: <EmailUnsubscribePage /> },
  { path: RoutePaths.SIGN_UP, element: <SignUpPage /> },
  { path: RoutePaths.EMAIL_CONFIRM, element: <EmailConfirmPage /> },
  { path: RoutePaths.PASSWORD_FORGOT, element: <PasswordForgotPage /> },
  { path: RoutePaths.PASSWORD_RESET, element: <PasswordResetPage /> },
  {
    path: RoutePaths.BASE,
    element: (
      <ProtectedRoute>
        <AccountProvider>
          <LayoutPage />
        </AccountProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <WorkspaceProjectsPage />,
      },
      {
        path: RoutePaths.ACCOUNT,
        element: <DashboardPage />,
        children: [
          {
            index: true,
            element: <DashboardProfilePage />,
          },
          {
            path: AccountPaths.PEOPLE,
            element: <DashboardPeoplePage />,
          },
          {
            path: AccountPaths.PAYMENT,
            element: (
              <SubscriptionProvider>
                <DashboardPaymentPage />
              </SubscriptionProvider>
            ),
            children: [
              {
                index: true,
                element: <PlansView />,
              },
            ],
          },
        ],
      },
      {
        path: RoutePaths.WORKSPACE,
        element: <WorkspacePage />,
        children: [
          {
            index: true,
            element: <Navigate to={WorkspacePaths.BRAND_KIT} replace />,
          },
          {
            path: WorkspacePaths.BRAND_KIT,
            element: <WorkspaceBrandLibraryPage />,
            children: [
              {
                index: true,
                element: <BrandLibraryKitPage />,
              },
              {
                path: BrandLibraryPaths.TONE_OF_VOICE,
                element: <ToneOfVoicePage />,
              },
            ],
          },
          {
            path: WorkspacePaths.BRAND_LIBRARY,
            element: <WorkspaceBrandLibraryPage />,
            children: [
              {
                index: true,
                element: <BrandLibraryProductsPage />,
              },
              {
                path: `create`,
                element: (
                  <ProductCreateProvider>
                    <BrandLibraryProductCreatePage />
                  </ProductCreateProvider>
                ),
              },
              {
                path: `:productId`,
                element: (
                  <ProductCreateProvider>
                    <BrandLibraryProductsEditPage />
                  </ProductCreateProvider>
                ),
              },
              {
                path: `edit/:jobId`,
                element: (
                  <BulkProductsProvider>
                    <BulkProductsPage />
                  </BulkProductsProvider>
                ),
                children: [
                  {
                    index: true,
                    element: <BrandLibraryProductsEditTablePage />,
                  },
                  {
                    path: `:productId`,
                    element: (
                      <ProductCreateProvider>
                        <CustomizeProductPage />
                      </ProductCreateProvider>
                    ),
                  },
                ],
              },
              {
                path: BrandLibraryPaths.BANNER,
                element: <BrandLibraryBannerPage />,
              },
              {
                path: BrandLibraryPaths.LAYOUT,
                element: <BrandLibraryTemplatePage />,
              },
              {
                path: BrandLibraryPaths.STYLES,
                element: <BrandLibraryStylesPage />,
              },
              {
                path: BrandLibraryPaths.ELEMENTS,
                element: <BrandLibraryElementsPage />,
              },
            ],
          },
          {
            path: WorkspacePaths.SAVED_SETTINGS,
            element: <WorkspaceSavedSettingsPage />,
          },
        ],
      },
      {
        path: RoutePaths.CREATIVE_SUITE,
        element: <CreativeSuitePage />,
        children: [
          {
            index: true,
            element: <WorkspaceProjectsPage />,
          },
          {
            path: CreativeSuitePaths.HISTORY,
            element: <WorkspaceHistoryPage />,
          },
        ],
      },
      {
        path: RoutePaths.AI_EDIT,
        element: (
          <AiSceneCreateProvider>
            <AiScenePage />
          </AiSceneCreateProvider>
        ),
        children: [
          {
            index: true,
            element: <AiSceneWithoutProjectIdPath />,
          },
          {
            path: ':projectId',
            element: (
              <ProjectProvider>
                <AiEditCreate />
              </ProjectProvider>
            ),
          },
          {
            path: ':projectId/generation',
            element: <AiSceneReviewPage />,
          },
          {
            path: ':projectId/generation/:sceneId',
            element: (
              <ResultProviderResetWrapper>
                <AiSceneResultProvider>
                  <AiSceneOutpaintProvider>
                    <AiSceneReviewEditPage />
                  </AiSceneOutpaintProvider>
                </AiSceneResultProvider>
              </ResultProviderResetWrapper>
            ),
          },
        ],
      },
      createAiSceneRoute(RoutePaths.AI_SCENE),
      createAiSceneRoute(RoutePaths.AI_IMAGE),
      {
        path: RoutePaths.AI_BANNER,
        element: <AiBannerPage />,
        children: [
          {
            index: true,
            element: (
              <BannerCreateProvider>
                <AiBannerCreatePage />
              </BannerCreateProvider>
            ),
          },
          {
            path: ':id',
            element: <AiBannerEditPage />,
          },
          {
            path: ':id/edit',
            element: <AiBannerReviewPage />,
          },
        ],
      },
      {
        path: RoutePaths.NOT_FOUND,
        element: <NotFound />,
      },
    ],
  },
];

const routerConfig = createBrowserRouter(routes, {
  future: {
    v7_normalizeFormMethod: true,
  },
});

export const Router = () => <RouterProvider router={routerConfig} />;
