import { useUserStore } from '@/providers/user/hooks';
import { RoutePaths } from '@/utils/route/constants';
import { Navigate, useNavigate } from 'react-router';
import Logo from 'assets/icons/coming_soon.svg?react';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { useForgotPassword } from './helper';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/form';
import { toast } from 'sonner';
import craitImage from '@/assets/images/auth-page.png';
import { AnimatedCraitImage } from 'pages/login/AnimatedCraitImage';
import { Link } from 'react-router-dom';
import { AuthOverlayContent } from 'pages/login/AuthOverlayContent';
import { CraitButton } from 'components/common/CraitButton';

const ICON_WRAPPER_CL = 'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1';

const formSchema = z.object({
  email: z.string().email('Invalid email address').min(1, 'Email is required'),
});
type TFormSchema = z.infer<typeof formSchema>;

export const PasswordForgotPage = () => {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const form = useForm({ resolver: zodResolver(formSchema), defaultValues: { email: '' } });
  const sendEmail = useForgotPassword();

  if (user) return <Navigate replace to={RoutePaths.BASE} />;

  const onSendEmail = async (values: TFormSchema) => {
    sendEmail.mutate(values.email, {
      onSuccess: () => {
        toast.success('Reset email is sent to your email address.');
      },
      onError: (error: any) => {
        const errorMessage = error.response.data.detail;
        toast.error(errorMessage);
      },
    });
  };

  return (
    <div className='flex size-full items-center justify-center bg-white'>
      <AnimatedCraitImage
        src={craitImage}
        className='absolute bottom-0 left-0 right-0 top-0  hidden h-full w-full object-cover md:block'
      />
      <div className='flex h-full w-full'>
        <div className='relative  flex  h-full flex-1 items-center justify-center px-8 md:px-4'>
          <div className='flex w-full max-w-[400px] flex-col items-center gap-6  bg-white md:rounded-[20px] md:px-5 md:pb-10 md:pt-2'>
            <div className='flex w-full justify-start'>
              <Button
                variant='outline'
                className='flex gap-2 border-none'
                onClick={() => {
                  navigate(`/${RoutePaths.LOGIN}`);
                }}
              >
                <IconWrapper
                  wrapperProps={{
                    className: ICON_WRAPPER_CL,
                  }}
                  iconClass='i-mdi-arrow-left size-4'
                />
                Back
              </Button>
            </div>
            <Link to={`/${RoutePaths.LOGIN}`}>
              <Logo className='h-8 w-4' cursor='pointer' />
            </Link>

            <h4 className='text-[32px] font-medium'>Forgot Password</h4>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSendEmail)} className='w-full'>
                <FormField
                  control={form.control}
                  name='email'
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className='relative'>
                      <FormControl>
                        <Input
                          type='email'
                          placeholder='Enter your email address'
                          autoCapitalize='none'
                          className='bg-background'
                          {...field}
                        />
                      </FormControl>
                      {error && (
                        <FormMessage className='absolute -bottom-6 left-0 text-red-500'>
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <CraitButton
                  disabled={sendEmail.isPending || sendEmail.isSuccess}
                  type='submit'
                  className='mt-4 w-full rounded-lg'
                >
                  Send mail
                </CraitButton>
              </form>
            </Form>
          </div>
        </div>
        <div className='relative block h-full w-full flex-1 overflow-hidden md:hidden'>
          <AnimatedCraitImage src={craitImage} className='h-full w-full object-cover ' />
          <AuthOverlayContent />
        </div>
      </div>
    </div>
  );
};
