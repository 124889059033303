import { useMutation } from '@tanstack/react-query';

import { AuthEndpoint } from '@/utils/fetch/constants';
import { useUserStore } from '@/providers/user/hooks';
import { User } from '@/providers/user/store';
import { updateTokensInCache } from '@/utils/fetch/helper';
import { apiClient, setAuthHeaders } from '@/utils/fetch/axiosConfig';

export const useConfirmEmail = () => {
  const setUser = useUserStore((state) => state.setUser);

  return useMutation({
    mutationKey: [AuthEndpoint.EMAIL_CONFIRM],
    mutationFn: ({ token }: { token: string }) => {
      return apiClient.post<User>(`${AuthEndpoint.EMAIL_CONFIRM}?token=${token}`);
    },
    onSuccess: ({ data }) => {
      setUser(data);
      updateTokensInCache(data);
      setAuthHeaders({ accessToken: data.access_token });
    },
  });
};
