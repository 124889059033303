import React from 'react';

interface Settings02IconProps {
  size?: number;
  color?: string;
}

const Settings02Icon: React.FC<Settings02IconProps> = ({ size = 18, color = '#777777' }) => {
  return (
    <svg
      width={size}
      height={size + 2} // Yükseklik 2 piksel artırıldı
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.99999 12.5C10.3807 12.5 11.5 11.3807 11.5 10C11.5 8.61931 10.3807 7.50002 8.99999 7.50002C7.61928 7.50002 6.49999 8.61931 6.49999 10C6.49999 11.3807 7.61928 12.5 8.99999 12.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.82924 16.1426L7.31628 17.238C7.46106 17.564 7.69734 17.8411 7.99647 18.0355C8.29559 18.23 8.6447 18.3334 9.00146 18.3334C9.35823 18.3334 9.70734 18.23 10.0065 18.0355C10.3056 17.8411 10.5419 17.564 10.6866 17.238L11.1737 16.1426C11.3471 15.7539 11.6387 15.4299 12.007 15.2167C12.3777 15.0029 12.8065 14.9118 13.232 14.9565L14.4237 15.0834C14.7784 15.1209 15.1364 15.0547 15.4543 14.8928C15.7721 14.7309 16.0362 14.4803 16.2144 14.1713C16.3929 13.8625 16.4779 13.5086 16.4592 13.1524C16.4405 12.7963 16.3188 12.4532 16.1089 12.1648L15.4033 11.1954C15.1521 10.8476 15.0178 10.429 15.02 10C15.0199 9.57218 15.1554 9.15531 15.407 8.80928L16.1126 7.83984C16.3225 7.55148 16.4442 7.20841 16.4629 6.85225C16.4816 6.49608 16.3966 6.14214 16.2181 5.83335C16.0399 5.52438 15.7758 5.27376 15.458 5.11188C15.1401 4.94999 14.7821 4.8838 14.4274 4.92132L13.2357 5.04817C12.8102 5.09286 12.3814 5.00179 12.0107 4.78798C11.6417 4.57356 11.35 4.24782 11.1774 3.85743L10.6866 2.76206C10.5419 2.436 10.3056 2.15895 10.0065 1.96452C9.70734 1.77009 9.35823 1.66663 9.00146 1.66669C8.6447 1.66663 8.29559 1.77009 7.99647 1.96452C7.69734 2.15895 7.46106 2.436 7.31628 2.76206L6.82924 3.85743C6.65668 4.24782 6.36497 4.57356 5.99591 4.78798C5.62526 5.00179 5.19647 5.09286 4.77091 5.04817L3.57554 4.92132C3.22081 4.8838 2.86282 4.94999 2.54497 5.11188C2.22711 5.27376 1.96305 5.52438 1.7848 5.83335C1.60632 6.14214 1.52128 6.49608 1.54002 6.85225C1.55876 7.20841 1.68046 7.55148 1.89035 7.83984L2.59591 8.80928C2.84753 9.15531 2.98302 9.57218 2.98295 10C2.98302 10.4279 2.84753 10.8447 2.59591 11.1908L1.89035 12.1602C1.68046 12.4486 1.55876 12.7916 1.54002 13.1478C1.52128 13.504 1.60632 13.8579 1.7848 14.1667C1.96323 14.4755 2.22732 14.726 2.54513 14.8878C2.86294 15.0497 3.22084 15.116 3.57554 15.0787L4.76721 14.9519C5.19276 14.9072 5.62155 14.9983 5.99221 15.2121C6.36265 15.4259 6.65571 15.7517 6.82924 16.1426Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Settings02Icon;
