import React, { useState, cloneElement, forwardRef } from 'react';
import { Modal } from 'components/ui/modal';
import { PlanCard } from 'components/plan-guard/PlanCard';
import UpgradeWarningIcon from '@/assets/icons/upgareWarning.svg?react';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { TPaymentProduct } from 'components/dashboard/payment/queries';
import { isPlanSufficient } from './helper';

interface PlanGuardProps {
  requiredPlan: TPaymentProduct['type'];
  children: React.ReactElement;
  showUpgradeIcon?: boolean;
  featureDescription: string;
  contentClassName?: string;
}

export const PlanGuard = forwardRef<HTMLDivElement, PlanGuardProps>(
  ({ requiredPlan, children, showUpgradeIcon, featureDescription, contentClassName }, ref) => {
    const { plan: userPlan } = useAccountCtx();
    const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);

    const checkPlanAndShowUpgradeModal = (event: React.MouseEvent) => {
      if (!isPlanSufficient(userPlan, requiredPlan)) {
        event.preventDefault();
        event.stopPropagation();
        setIsUpgradeModalVisible(true);
      }
    };

    const mergeClickHandlers = (originalOnClick: any) => (event: React.MouseEvent) => {
      checkPlanAndShowUpgradeModal(event);
      if (!event.defaultPrevented && originalOnClick) {
        originalOnClick(event);
      }
    };

    return (
      <>
        <div ref={ref} className={`relative w-full ${contentClassName}`}>
          {cloneElement(children, {
            onClick: mergeClickHandlers(children.props.onClick),
          })}
          {showUpgradeIcon && !isPlanSufficient(userPlan, requiredPlan) && (
            <UpgradeWarningIcon className='absolute right-2 top-1/2 z-20 -translate-y-1/2 transform' />
          )}
        </div>
        {isUpgradeModalVisible && (
          <Modal
            titleElement={
              <div className='flex items-center space-x-2'>
                <UpgradeWarningIcon className='ml-2 inline-block align-middle' />
                <span>Upgrade to use this feature</span>
              </div>
            }
            modalContentClassName='min-h-[500px]'
            open={isUpgradeModalVisible}
            onOpenChange={setIsUpgradeModalVisible}
          >
            {({ closeModal }) => (
              <PlanCard
                closeModal={closeModal}
                requiredPlan={requiredPlan}
                featureDescription={featureDescription}
              />
            )}
          </Modal>
        )}
      </>
    );
  },
);

PlanGuard.displayName = 'PlanGuard';
