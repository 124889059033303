import { Column, Row, RowData, Table } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import { Input } from 'components/ui/input';
import { TooltipItem } from 'components/common/TooltipItem';
import { Button } from 'components/ui/button';
import { TProject } from 'pages/workspace/projects/helper';

interface TableCellProps<TData> {
  getValue: () => unknown;
  row: Row<TData>;
  column: Column<TData>;
  table: Table<TData>;
}

export const ProjectNameCell = <TData extends RowData>({
  getValue,
  row,
  column,
  table,
}: TableCellProps<TData>) => {
  const tableMeta = table.options.meta;
  const inputRef = useRef<HTMLInputElement>(null);
  const isEditMode = tableMeta?.editedRows[row.id];
  const [isInputFocused, setIsInputFocused] = useState(false);

  const lastUpdatedInfo = row.getValue('last_update_info') as TProject['last_update_info'];
  const defaultName = row.getValue('name') as string;

  return (
    <div className='relative flex space-x-2'>
      <Input
        key={lastUpdatedInfo?.timestamp + defaultName}
        ref={inputRef}
        className='truncate'
        defaultValue={row.getValue('name')}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => setIsInputFocused(true)}
      />
      {isInputFocused && (
        <div className='absolute left-[98%] z-10 flex'>
          <TooltipItem
            side='bottom'
            className='mr-2'
            trigger={
              <Button
                size='sm'
                variant='outline'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsInputFocused(false);
                  if (!inputRef.current) return;
                  tableMeta?.saveRow(row.index, row.original, inputRef.current.value);
                }}
                className='rounded-full px-2 py-1'
              >
                <span className='i-mdi-check h-5 w-5 text-green-600'></span>
              </Button>
            }
          >
            Save
          </TooltipItem>
          <TooltipItem
            side='bottom'
            className='mr-2'
            trigger={
              <Button
                size='sm'
                variant='outline'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsInputFocused(false);
                }}
                className='rounded-full px-2 py-1'
              >
                <span className='i-mdi-close h-5 w-5'></span>
              </Button>
            }
          >
            Cancel
          </TooltipItem>
        </div>
      )}
    </div>
  );
};
