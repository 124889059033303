import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { FC, ReactElement, ReactNode, useState } from 'react';
import { PopoverContentProps } from '@radix-ui/react-popover';

interface IPopoverViewProps {
  trigger: ReactNode;
  children: ReactNode | ((props: { closePopover: () => void }) => ReactElement | null);
  popoverContentProps?: PopoverContentProps;
}

export const PopoverView: FC<
  IPopoverViewProps & Omit<React.ComponentPropsWithoutRef<typeof Popover>, 'children'>
> = ({ trigger, children, popoverContentProps, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const childrenEl =
    typeof children === 'function' ? children({ closePopover: () => setIsOpen(false) }) : children;

  return (
    <Popover
      open={isOpen}
      {...props}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        props.onOpenChange?.(isOpen);
      }}
    >
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent {...popoverContentProps}>{childrenEl}</PopoverContent>
    </Popover>
  );
};
