import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';

import { Progress } from 'components/ui/progress';
import { Separator } from 'components/ui/separator';
import { CField } from './ProfileInfoCard';

import { useAccountStatistics } from 'pages/dashboard/profile/helper';
import { AccountPaths, RoutePaths } from '@/utils/route/constants';
import { Modal } from 'components/ui/modal';
import { CancelPlan } from './CancelPlan';
import { calculateRemainingPercentage } from '../helper';
import { getPreviousPlanType } from 'components/plan-guard/helper';
import { Breakpoints } from '@/utils/general/constant';
import { useWindowSize } from 'usehooks-ts';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

export const AccountPlanCard = () => {
  const { data, refetch } = useAccountStatistics();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  if (!data) return null;

  const userPlan = data.trial_period ? getPreviousPlanType(data.plan) : data.plan;
  const packageText = data.trial_period ? `${data.plan} Package Trial` : userPlan;

  const formattedExpiryDate =
    userPlan === 'free'
      ? '-'
      : data?.expiry_timestamp
        ? format(fromUnixTime(data.expiry_timestamp), 'dd.MM.yyyy')
        : '';
  const remainingCreditText =
    data.credit.remaining || data.credit.remaining === 0
      ? `${data.credit.remaining} / ${data.credit.total}`
      : 'Unlimited';
  const remainingProductsText =
    data.products.remaining || data.products.remaining === 0
      ? `${data.products.remaining} / ${data.products.total}`
      : 'Unlimited';
  return (
    <div className='flex w-full flex-col items-start justify-between gap-5 rounded-lg border p-4'>
      <div className='mb-4 flex w-full items-center justify-between'>
        <h2 className='text-[14px] font-bold'>Plan</h2>
        <div className='flex gap-4'>
          {userPlan !== 'free' &&
            (isDesktop ? (
              <Modal
                trigger={
                  <div
                    className={`h-min p-0 text-start text-[13px] font-semibold text-[#777777] underline underline-offset-[1px]`}
                  >
                    Cancel Plan
                  </div>
                }
                titleElement='Cancel Plan?'
                modalContentClassName='max-w-[460px]'
                isDisabled={!data.renews}
              >
                {({ closeModal }) => <CancelPlan onCloseModal={closeModal} refetch={refetch} />}
              </Modal>
            ) : (
              <MobileMenuSheet
                triggerButton={
                  <div
                    className={`h-min p-0 text-start text-[13px] font-semibold text-[#777777] underline underline-offset-[1px]`}
                  >
                    Cancel Plan
                  </div>
                }
                titleElement='Cancel Plan?'
                isDisabled={!data.renews}
              >
                {({ closeModal }) => <CancelPlan onCloseModal={closeModal} refetch={refetch} />}
              </MobileMenuSheet>
            ))}
          <Link
            to={`/${RoutePaths.ACCOUNT}/${AccountPaths.PAYMENT}`}
            className='text-[13px] font-semibold text-[#E16236] underline'
          >
            Manage Plans
          </Link>
        </div>
      </div>
      <div className='flex w-full items-start gap-7'>
        <div className=' flex w-full flex-col gap-4'>
          <CField label='Subscription' value={packageText} className='capitalize' />
          <Separator />
          <CField
            label={data.renews ? 'Renews' : 'Ends'}
            value={formattedExpiryDate}
            className='capitalize'
          />
          <Separator />
          <div className='flex md:flex-col md:gap-4'>
            <label className='block flex-1 text-main font-semibold text-c-dark'>Credits</label>
            <div className='flex flex-1 flex-col gap-5'>
              <div className='flex flex-col gap-3'>
                <div className='flex justify-between'>
                  <label className='block text-main font-medium text-c-dark'>Image Credits</label>
                  <p className='text-[14px] font-normal'>{remainingCreditText}</p>
                </div>
                <Progress
                  value={calculateRemainingPercentage(data.credit.total, data.credit.remaining)}
                  className='h-[6px] rounded-lg bg-[#D9D9D9]'
                />
              </div>
              <div className='flex flex-col gap-3'>
                <div className='flex justify-between'>
                  <label className='block text-main font-medium text-c-dark'>Product assets</label>
                  <p className='text-[14px] font-normal'>{remainingProductsText}</p>
                </div>
                <Progress
                  value={calculateRemainingPercentage(data.products.total, data.products.remaining)}
                  className='h-[6px] rounded-lg bg-[#D9D9D9]'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
