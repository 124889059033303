import { useUserStore } from '@/providers/user/hooks';
import { AiSceneGenerationEndpoint } from '@/utils/fetch/constants';
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { TScene, getSceneById } from 'components/ai-scene/review/queries';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

const GET_SCENE_QUERY_NAME = 'aiSceneResultScene';

export const getSceneQueryKey = (
  projectId: string | undefined,
  organizationId: number,
  sceneId: string | undefined,
) => {
  return [
    {
      url: AiSceneGenerationEndpoint.BASE,
      organizationId,
      projectId,
      sceneId,
      _name: GET_SCENE_QUERY_NAME,
    },
  ];
};

export const useGetScene = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId, sceneId } = useParams();

  return useQuery({
    queryKey: getSceneQueryKey(projectId, organizationId, sceneId),
    queryFn: ({ queryKey }) => {
      invariant(queryKey[0].sceneId, 'sceneId is required');
      invariant(queryKey[0].projectId, 'projectId is required');

      return getSceneById({
        sceneId: queryKey[0].sceneId,
        projectId: queryKey[0].projectId,
        organizationId: queryKey[0].organizationId,
      });
    },
    retry: 0,
    staleTime: 0,
    gcTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    // If this is not provided, new scene will always fall into loading state first.
    // This causes flickering especially when user select scene from the bottom navigation.
    placeholderData: (prev) => prev,
  });
};

export const SceneContext = createContext<DefinedUseQueryResult<TScene, Error> | undefined>(
  undefined,
);

export const useDefinedSceneCtx = () => {
  const ctx = useContext(SceneContext);
  invariant(ctx, 'useDefinedSceneCtx must be used within a SceneProvider');

  return ctx.data;
};

export const useSceneIsPlaceholderCtx = () => {
  const ctx = useContext(SceneContext);
  invariant(ctx, 'useSceneIsPlaceholderCtx must be used within a SceneProvider');

  return ctx.isPlaceholderData;
};
