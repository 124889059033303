import { useCraitStore } from '@/utils/store/store';
import React, { useEffect } from 'react';
import { fabric } from 'fabric';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { serializeCanvas } from '@/utils/helper';
import { parseImageWithFabric } from './helper';

export const useDrawPendingImageFromWorkspace = (
  fabricCanvasRef: React.MutableRefObject<fabric.Canvas | null>,
) => {
  const pendingImageToDraw = useCraitStore((state) => state.pendingImageToDraw);
  const setPendingImageToDraw = useCraitStore((state) => state.setPendingImageToDraw);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  const updateActiveProjectTitle = useAiSceneCreateStore((state) => state.updateActiveProjectTitle);

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || !pendingImageToDraw) return;

    const updateOrDrawImage = async () => {
      try {
        const {
          id,
          type,
          image: imageObject,
        } = await parseImageWithFabric({
          id: pendingImageToDraw.id,
          image: pendingImageToDraw.url,
          type: pendingImageToDraw.type,
          imageName: pendingImageToDraw.title,
          maxHeight: fabricRef.getHeight(),
          maxWidth: fabricRef.getWidth(),
          placementBuffer: 0,
        });
        if (type === 'template') {
          const existingTemplate = fabricRef
            .getObjects()
            .find((obj) => obj.imageType === 'template');
          if (existingTemplate) {
            fabricRef.remove(existingTemplate);
          }
          imageObject.set({
            evented: false,
            selectable: false,
            isSelectionIgnored: true,
          });
          fabricRef.insertAt(imageObject, 0, false);
        } else {
          fabricRef.add(imageObject);
        }

        fabricRef.requestRenderAll();
        updateActiveProjectTitle(pendingImageToDraw.title);
        updateCanvasState(serializeCanvas(fabricRef));
        setPendingImageToDraw(undefined);
      } catch (error) {
        console.error(error);
      }
    };

    updateOrDrawImage();
  }, [
    fabricCanvasRef,
    pendingImageToDraw,
    updateCanvasState,
    setPendingImageToDraw,
    updateActiveProjectTitle,
  ]);
};
