import { apiClient } from '@/utils/fetch/axiosConfig';
import { AuthEndpoint } from '@/utils/fetch/constants';
import { useMutation } from '@tanstack/react-query';

const sendForgotPasswordEmail = async (email: string) => {
  return apiClient.post(AuthEndpoint.FORGOT_PASSWORD, { email });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationKey: [AuthEndpoint],
    mutationFn: sendForgotPasswordEmail,
  });
};
