import React, { useEffect } from 'react';
import { useSyncCanvasState } from '@/hooks/canvas/useSyncCanvasState';
import { useFabricCanvas } from '@/hooks/canvas/useFabricCanvas';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { serializeCanvas } from '@/utils/helper';
import { OverlayWidget } from 'components/ai-scene/create/editor/canvas/OverlayWidget';
import {
  applyCanvasDimensionChange,
  parseImageWithFabric,
} from 'components/ai-scene/create/editor/canvas/helper';
import { useRemoveSelectedObject } from '@/hooks/canvas/useRemoveSelectedObject';
import { useFabricEventHandlers } from '@/hooks/canvas/useFabricEventHandlers';
import { useDefinedSceneCtx, useSceneIsPlaceholderCtx } from 'pages/ai-scene/review-edit/helper';
import { ResultCanvasLockView } from './ResultCanvasLockView';
import { OutpaintCanvasSoftHide } from './OutpaintCanvasSoftHide';
import { useSaveCanvasModification } from './useSaveCanvasModification';
import { useParams } from 'react-router';
import { useCanvasImageLoadingStatus } from '@/hooks/useCanvasImageLoadingStatus';

import { SceneFavoriteAction } from '../scene-action/SceneFavoriteAction';

interface AiSceneResultCanvasProps {
  children: React.ReactNode;
}

export const AiSceneResultCanvas: React.FC<AiSceneResultCanvasProps> = ({ children }) => {
  const { sceneId } = useParams();
  const setCanvasInstance = useAiSceneResultStore((state) => state.setAiSceneResultCanvasInstance);
  const processingState = useAiSceneResultStore((state) => state.processingState);
  const { fabricCanvasRef, canvasElement } = useFabricCanvas(setCanvasInstance, undefined, sceneId);
  const canvasState = useAiSceneResultStore((state) => state.canvasState);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const resetSceneCanvasState = useAiSceneResultStore(
    (state) => state.resetAiSceneResultCanvasState,
  );
  const sceneData = useDefinedSceneCtx();
  const isPlaceholderData = useSceneIsPlaceholderCtx();
  useSyncCanvasState(fabricCanvasRef, canvasState);
  useFabricEventHandlers({
    updateCanvasState,
    canvasInstance: fabricCanvasRef,
  });
  const [setCanvasLoadingStatus, imageLoadingView] = useCanvasImageLoadingStatus();

  useRemoveSelectedObject({
    fabricCanvasRef,
    resetSceneCanvasState,
    updateCanvasState,
  });
  useSaveCanvasModification(fabricCanvasRef);

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || !sceneData.id || !sceneData.imageUrl || isPlaceholderData) return;

    const updateOrDrawImage = async () => {
      try {
        setCanvasLoadingStatus(true);
        const {
          id,
          type,
          image: imageObject,
        } = await parseImageWithFabric({
          id: sceneData.id,
          image: sceneData.imageUrl,
          type: 'template',
          maxHeight: fabricRef.getHeight(),
          maxWidth: fabricRef.getWidth(),
          placementBuffer: 0,
        });
        applyCanvasDimensionChange({
          width: imageObject.width || imageObject.getScaledWidth(),
          height: imageObject.height || imageObject.getScaledHeight(),
          canvas: fabricRef,
        });

        const existingTemplate = fabricRef.getObjects().find((obj) => obj.imageType === 'template');
        if (existingTemplate) {
          fabricRef.remove(existingTemplate);
        }
        imageObject.set({
          evented: false,
          selectable: false,
          isSelectionIgnored: true,
          left: 0,
          top: 0,
        });
        fabricRef.insertAt(imageObject, 0, false);
        fabricRef.requestRenderAll();
        updateCanvasState(serializeCanvas(fabricRef));
      } catch (error) {
        console.error(error);
      } finally {
        setCanvasLoadingStatus(false);
      }
    };

    updateOrDrawImage();
  }, [
    fabricCanvasRef,
    sceneData.id,
    sceneData.imageUrl,
    updateCanvasState,
    isPlaceholderData,
    setCanvasLoadingStatus,
  ]);

  const processingText = processingState === 'erasing' ? 'Erasing...' : 'Generating...';
  return (
    <div className='relative'>
      <ResultCanvasLockView>
        <span className='text-[14px] font-semibold text-white'>{processingText}</span>
      </ResultCanvasLockView>
      <OutpaintCanvasSoftHide>
        {imageLoadingView}
        <div className='group relative'>
          {canvasElement}
          <div className='absolute right-0 top-0 p-2 opacity-0 transition-opacity group-hover:opacity-100'>
            <SceneFavoriteAction isStarred={sceneData.isFavorited} />
          </div>
        </div>
      </OutpaintCanvasSoftHide>
      <OverlayWidget
        key={sceneId}
        fabricRef={fabricCanvasRef.current}
        updateCanvasState={updateCanvasState}
      />
      {children}
    </div>
  );
};
