import { RoutePaths } from '@/utils/route/constants';
import { useCraitStore } from '@/utils/store/store';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { ModalView } from 'components/common/ModalView';
import { EditNameAndImageView } from '../template/EditNameAndImageView';
import { useDeleteElement, useUpdateElementDetail } from './helper';
import { toast } from 'sonner';
import {
  TTemplateResponse,
  useToggleElementLibraryMutation,
} from 'components/ai-scene/sidebar/settings-tab/queries';

export const ElementActions: FC<
  TTemplateResponse & {
    refetch: () => void;
  }
> = (props) => {
  const navigate = useNavigate();
  const setPendingImageToDraw = useCraitStore((state) => state.setPendingImageToDraw);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const updateElementDetail = useUpdateElementDetail();
  const deleteElementMutation = useDeleteElement();
  const toggleElementLibraryMutation = useToggleElementLibraryMutation();

  const isMutationEnabled = props.isOrganizationAsset;

  return (
    <>
      {isEditModalShown && (
        <ModalView
          open={isEditModalShown}
          onOpenChange={setIsEditModalShown}
          titleElement={<h4 className='text-lg font-medium'>Edit Selected Element</h4>}
        >
          {({ closeModal }) => (
            <EditNameAndImageView
              defaultName={props.name}
              defaultImage={props.url}
              onClose={closeModal}
              isSubmitDisabled={updateElementDetail.isPending}
              onSubmit={async (name, image) => {
                try {
                  if (updateElementDetail.isPending) return;

                  await updateElementDetail.mutateAsync({ name, image, id: props.id });
                  props.refetch();
                  closeModal();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to update element');
                }
              }}
            />
          )}
        </ModalView>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <span className='jusfity-center absolute right-0 top-0 flex cursor-pointer items-center px-1 py-1'>
            <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
          </span>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='w-40 p-2'>
          {isMutationEnabled && (
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1 hover:bg-gray-50'
              onClick={() => {
                setIsEditModalShown(true);
              }}
            >
              Edit
            </DropdownMenuCheckboxItem>
          )}
          <DropdownMenuCheckboxItem
            className='cursor-pointer px-2 py-1 hover:bg-gray-50'
            onClick={() => {
              setPendingImageToDraw({
                title: props.name,
                id: props.id,
                url: props.url,
                type: 'element',
              });
              navigate(`/${RoutePaths.AI_SCENE}`);
            }}
          >
            Use to Generate
          </DropdownMenuCheckboxItem>
          <DropdownMenuSeparator />
          {isMutationEnabled ? (
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1'
              onClick={async () => {
                try {
                  if (deleteElementMutation.isPending) return;

                  await deleteElementMutation.mutateAsync({ id: props.id });
                  props.refetch();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to remove element');
                }
              }}
            >
              Delete
            </DropdownMenuCheckboxItem>
          ) : (
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1'
              onClick={async () => {
                try {
                  if (toggleElementLibraryMutation.isPending) return;

                  await toggleElementLibraryMutation.mutateAsync({
                    id: props.id,
                    action: 'remove',
                  });
                  props.refetch();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to remove element from library');
                }
              }}
            >
              Remove From Library
            </DropdownMenuCheckboxItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
