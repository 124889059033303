import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type TBulkProductItem = {
  id: string;
  sku: string;
  name: string;
  originalImage: string;
  category: string | null;
  transparentImage: string | null;
};

export type TBulkProductsStore = {
  products: TBulkProductItem[];
  setProducts: (products: TBulkProductItem[]) => void;
  updateProduct: (product: Partial<TBulkProductItem>) => void;
  selectedProduct: TBulkProductItem | null;
  setSelectedProduct: (product: TBulkProductsStore['selectedProduct']) => void;
};

const useBulkProductsSlice: ImmerStateCreator<TBulkProductsStore> = (set) => ({
  products: [],
  selectedProduct: null,
  setSelectedProduct: (product) => {
    set((state) => {
      state.selectedProduct = product;
    });
  },
  setProducts: (products: TBulkProductItem[]) => {
    set((state) => {
      state.products = products;
    });
  },
  updateProduct: (product: Partial<TBulkProductItem>) => {
    set((state) => {
      const updatedProducts = state.products.map((p) => {
        if (p.id !== product.id) return p;

        return {
          ...p,
          ...product,
        };
      });
      state.products = updatedProducts;
    });
  },
  removeProduct: (id: string) => {
    set((state) => {
      state.products = state.products.filter((p) => p.id !== id);
    });
  },
});

export const createBulkProductsStore = (initProps?: Partial<TBulkProductsStore>) => {
  return createStore<TBulkProductsStore>()(
    devtools(
      immer((...props) => ({
        ...useBulkProductsSlice(...props),
        ...initProps,
      })),
      {
        name: 'BulkProductsStore',
        trace: true,
      },
    ),
  );
};
