import { Button } from 'components/ui/button';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';
import { ImageUrlUploadInput } from './ImageUrlUploadInput';
import { TDerivedCreateState } from '../../products-create/ProductCreateBox';
import { toast } from 'sonner';
import {
  useGetRemoveBgStatusMutation,
  useRemoveBgMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { PROCESSING_STATUSES } from 'components/ai-scene/review/queries';

type TUrlUploadViewProps = {
  children: ReactNode;
};

export const UrlUploadView: FC<TUrlUploadViewProps> = ({ children }) => {
  const [file, setFile] = useState<string | null>(null);
  const navigate = useNavigate();
  const removeImageBackground = useRemoveBgMutation();
  const { mutate: getRemoveBgStatusMutate } = useGetRemoveBgStatusMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const uploadSingleProduct = async () => {
    try {
      if (!file) return;

      setIsSubmitting(true);
      const originalImage = file;
      const removeBgResponse = await removeImageBackground.mutateAsync({
        action: 'remove_background',
        imageUrl: originalImage,
      });
      const pollRemoveBgStatus = () => {
        getRemoveBgStatusMutate(removeBgResponse.jobId, {
          onSuccess: (data) => {
            if (PROCESSING_STATUSES.includes(data.status)) {
              setTimeout(pollRemoveBgStatus, 1000);
              return;
            }
            if (data.status !== 'ready') {
              toast.error('Failed to upload image.');
              setIsSubmitting(false);
              return;
            }

            navigate('create', {
              state: { originalImage, transparentImage: data.maskUrl } as TDerivedCreateState,
            });
            setIsSubmitting(false);
          },
          onError: (err) => {
            console.error(err);
            toast.error('Failed to apply enhance. Please try again.');
            setIsSubmitting(false);
          },
        });
      };
      pollRemoveBgStatus();
    } catch (error) {
      toast.error('Failed to upload image.');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <ImageUrlUploadInput onImageUrlSubmit={setFile} />
      <div className='mt-2.5 flex w-full flex-col gap-3'>
        <Button className='w-full' disabled={!file || isSubmitting} onClick={uploadSingleProduct}>
          Upload
        </Button>
        {children}
      </div>
    </div>
  );
};
