import { Button } from 'components/ui/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { ProductItem } from './ProductItem';
import { useBulkProductsStore } from 'pages/workspace/brand-library/bulk-products/provider/helper';
import { useRef } from 'react';
import { TBulkProductItem } from 'pages/workspace/brand-library/bulk-products/provider/store';
import { useBulkCompleteJobMutation } from './queries';
import {
  TProductUploadPayload,
  useUploadProduct,
} from 'pages/workspace/brand-library/products/helper';
import { toast } from 'sonner';

const HEAD_CL =
  'border border-gray-200 border-collapse rounded-lg text-crait-primary font-medium h-16';

export const BulkProductsTable = () => {
  const navigate = useNavigate();
  const bulkProducts = useBulkProductsStore((state) => state.products);
  const mutableData = useRef(
    bulkProducts.reduce(
      (acc, product) => {
        acc[product.id] = product;
        return acc;
      },
      {} as Record<string, TBulkProductItem>,
    ),
  );
  const completeJobMutation = useBulkCompleteJobMutation();
  const uploadProduct = useUploadProduct();
  const { jobId } = useParams() as { jobId: string };

  return (
    <>
      <Table className='mx-auto max-w-[925px] rounded-lg bg-white p-2 text-crait-primary'>
        <TableHeader>
          <TableRow>
            <TableHead className={HEAD_CL}></TableHead>
            <TableHead className={HEAD_CL}>Product Photo</TableHead>
            <TableHead className={HEAD_CL}>Transparent</TableHead>
            <TableHead className={HEAD_CL}>SKU</TableHead>
            <TableHead className={HEAD_CL}>Product Name</TableHead>
            <TableHead className={HEAD_CL}>Category</TableHead>
            <TableHead className={HEAD_CL}></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {bulkProducts.map((product) => (
            <ProductItem
              key={product.id}
              {...product}
              jobId={jobId}
              mutableData={mutableData.current}
            />
          ))}
        </TableBody>
      </Table>
      <div className='flex w-[295px] flex-col gap-2 rounded-lg bg-white p-3'>
        <Button
          className='flex h-[40px]'
          onClick={async () => {
            const products = Object.values(mutableData.current);
            if (!mutableData.current || products.some((product) => !product.transparentImage)) {
              toast.error('Please wait for all images to be processed');
              return;
            }
            const missingCategories = products.filter((product) => !product.category);
            if (missingCategories.length > 0) {
              toast.error('Please select a category for all products.');
              return;
            }

            const formattedProducts = products
              .map((product) => ({
                productCode: product.sku || null,
                productName: product.name,
                originalImage: product.originalImage,
                transparentImage: product.transparentImage,
                categoryId: product.category || null,
              }))
              .filter((product): product is Omit<TProductUploadPayload, 'organizationId'> =>
                Boolean(product.transparentImage),
              );
            const uploadPromises = formattedProducts.map((product) =>
              uploadProduct.mutateAsync(product),
            );
            await Promise.allSettled(uploadPromises);
            completeJobMutation.mutate({ jobId });
            navigate(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`);
          }}
          disabled={uploadProduct.isPending}
        >
          Save
        </Button>
        <Button
          className='flex h-[40px]'
          variant='outline'
          onClick={() => {
            completeJobMutation.mutate({ jobId });
            navigate(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`);
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
