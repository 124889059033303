import { useState } from 'react';
import { useUserStore } from '@/providers/user/hooks';
import { Modal } from 'components/ui/modal';
import { Separator } from 'components/ui/separator';
import { ProfileEdit } from './ProfileEdit';
import { Button } from 'components/ui/button';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

export const CField = ({
  label,
  value,
  className,
}: {
  label: string;
  value: string | undefined;
  className?: string;
}) => {
  return (
    <div className='flex justify-between'>
      <label className='block text-main font-semibold text-c-dark'>{label}</label>
      <p className={`${className} text-[14px] font-normal`}>{value}</p>
    </div>
  );
};

export const ProfileInfoCard = () => {
  const user = useUserStore((state) => state.user);
  const [isModalShown, setIsModalShown] = useState(false);
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const handleOpenChange = (isOpen: boolean) => {
    setIsModalShown(isOpen);
  };

  return (
    <div className='flex w-full flex-col items-start justify-between gap-5 rounded-lg border p-4'>
      <div className='mb-4 flex w-full items-center justify-between'>
        <h2 className='text-[14px] font-bold'>Profile</h2>
        <Button
          variant='link'
          onClick={() => setIsModalShown(true)}
          className='h-min p-0 text-[13px] font-semibold text-[#E16236] underline underline-offset-[1px]'
        >
          Edit
        </Button>
      </div>
      <div className='flex w-full items-start gap-7'>
        <div className='mb-4 flex items-center'>
          <div
            onClick={() => setIsModalShown(true)}
            className='flex h-[96px] w-[96px] cursor-pointer items-center justify-center rounded-full bg-gray-200'
          >
            <img
              src={user?.avatar}
              alt='Profile'
              className='h-full w-full rounded-full object-cover'
            />
          </div>
        </div>
        <div className='flex w-full flex-col gap-4'>
          <CField label='Username' value={user?.user_name} />
          <Separator />
          <CField label='Email' value={user?.email} />
          <Separator />
          <CField label='Password' value='*******' />
        </div>
      </div>

      {isModalShown && (
        <>
          {isDesktop ? (
            <Modal
              open={isModalShown}
              onOpenChange={handleOpenChange}
              titleElement='Edit Profile'
              modalContentClassName='max-w-[640px]'
            >
              {({ closeModal }) => <ProfileEdit onCloseModal={closeModal} />}
            </Modal>
          ) : (
            <MobileMenuSheet
              isOpen={isModalShown}
              onOpenChange={handleOpenChange}
              titleElement='Edit Profile'
              contentClassName='bg-white'
            >
              {({ closeModal }) => <ProfileEdit onCloseModal={closeModal} />}
            </MobileMenuSheet>
          )}
        </>
      )}
    </div>
  );
};
