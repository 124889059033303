import { ScrollArea } from 'components/ui/scroll-area';
import { AddBannerModal } from './AddBannerModal';
import { Command, CommandInput } from 'components/ui/command';
import { BaseCard } from './BaseCard';
import { BannerActions } from './BannerActions';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { useDebounceValue } from 'usehooks-ts';
import {
  TTemplateResponse,
  useGetInfiniteSearchResults,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { appendPluralSuffixByNumber, triggerBlurOnEnter } from '@/utils/helper';
import { usePartialUpdateBannerDetail } from './queries';
import { toast } from 'sonner';
import { useInView } from 'react-intersection-observer';
import { useResetInfiniteSearchCache } from 'components/ai-scene/sidebar/settings-tab/styles/useResetInfiniteSearchCache';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

const MAX_PAGES_IN_CACHE = 2;
interface BrandBannerViewProps {
  children?: ReactNode;
}
export const BrandBannerView: FC<BrandBannerViewProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TTemplateResponse>({
    keyword: searchValue,
    onlyOrgLibrary: true,
    searchSection: 'banners',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.banners;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
    refetchOnMount: true,
  });
  useResetInfiniteSearchCache<TTemplateResponse>('banners', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allBanners = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.banners.items) || [];
  }, [infiniteData]);
  const partialUpdateBannerDetailMutation = usePartialUpdateBannerDetail();

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load banners.</span>
    </RefetchView>
  );

  const hasAnyBanner = allBanners.length > 0;
  const bannerItems =
    !hasAnyBanner && !isLoading ? (
      <span>No banners found.</span>
    ) : (
      allBanners.map((banner) => (
        <BaseCard
          key={banner.id}
          {...banner}
          defaultValue={banner.name}
          onBlur={(e) => {
            if (e.target.value === banner.name) return;

            partialUpdateBannerDetailMutation.mutate(
              {
                id: banner.id,
                name: e.target.value,
              },
              {
                onSuccess: () => {
                  refetch();
                },
                onError: () => {
                  toast.error('Failed to update banner name.');
                },
              },
            );
          }}
          onKeyDown={triggerBlurOnEnter}
        >
          <BannerActions {...banner} refetch={refetch} />
        </BaseCard>
      ))
    );
  const totalItemCount = infiniteData?.pages[0]?.banners.totalItems || 0;

  return (
    <div className='h-full'>
      {children}
      <div className='mt-2 flex items-center justify-between gap-2'>
        <span className='text-sm font-medium'>
          {totalItemCount} banner{appendPluralSuffixByNumber(totalItemCount)} listed
        </span>
        <div className='flex gap-2'>
          <Command className='flex h-10 max-w-[200px] justify-center rounded-lg border border-crait-dark-300 bg-transparent'>
            <CommandInput placeholder='Search banner name' onValueChange={setSearchValue} />
          </Command>
          <AddBannerModal />
        </div>
      </div>
      <ScrollArea className={`mt-6 h-[calc(100%-40px)]`}>
        <div className='flex flex-wrap gap-6 pb-28'>{errorView || bannerItems}</div>
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
