import { ProfileInfoCard } from './_components/ProfileInfoCard';
import { AccountPlanCard } from './_components/AccountPlanCard';
import { ThemeCard } from './_components/ThemeCard';
import { DeleteAccountCard } from './_components/DeleteAccountCard';
import { useNavigate } from 'react-router';
import { WorkInfoCard } from './_components/WorkInfoCard';

export const DashboardProfile = () => {
  const navigate = useNavigate();
  return (
    <div className='m-auto flex max-w-[636px] flex-col items-start gap-6 pt-3'>
      <div
        className='text-[24px] font-bold md:text-[18px]'
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className='i-mdi-arrow-left min-md:hidden '></span> Account Settings
      </div>
      <ProfileInfoCard />
      <WorkInfoCard />
      <AccountPlanCard />
      <ThemeCard />
      <DeleteAccountCard />
    </div>
  );
};
