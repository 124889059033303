import { FC, ReactNode, useRef } from 'react';
import { createAiSceneBulkStore } from './store';
import { AiSceneBulkCreateContext, TAiSceneBulkContext } from './helper';

interface AiSceneBulkCreateProviderProps {
  children: ReactNode;
}

export const AiSceneBulkCreateProvider: FC<AiSceneBulkCreateProviderProps> = ({ children }) => {
  const storeRef = useRef<TAiSceneBulkContext>();

  if (!storeRef.current) {
    storeRef.current = createAiSceneBulkStore();
  }

  return (
    <AiSceneBulkCreateContext.Provider value={storeRef.current}>
      {children}
    </AiSceneBulkCreateContext.Provider>
  );
};
