import { DEFAULT_FABRIC_FRACTION_DIGITS } from '@/utils/fabric.config';
import { Breakpoints } from '@/utils/general/constant';
import { fabric } from 'fabric';
import { ProductProperties } from 'pages/workspace/brand-library/products/helper';

interface ParseImageWithFabricSpec {
  id: string;
  image: string | File;
  imageName?: string;
  type?: fabric.Object['imageType'];
  isSelectionIgnored?: fabric.Object['isSelectionIgnored'];
  maxWidth: number;
  maxHeight: number;
  placementBuffer?: number;
  imageProperties?: ProductProperties | null;
}

export const parseImageWithFabric = ({
  id,
  image,
  imageName,
  type,
  maxWidth,
  maxHeight,
  placementBuffer,
  isSelectionIgnored,
  imageProperties,
}: ParseImageWithFabricSpec): Promise<
  Pick<ParseImageWithFabricSpec, 'id' | 'type'> & {
    image: fabric.Image;
  }
> => {
  return new Promise((resolve, reject) => {
    try {
      const url = typeof image === 'string' ? image : URL.createObjectURL(image);
      fabric.Image.fromURL(
        url,
        // @ts-expect-error - loadImage isError is not part of the types
        (parsedImage, isError: boolean) => {
          if (isError) {
            reject(new Error(`Failed to load image from URL: ${url}`));
            return;
          }

          // Disable updating the cache for image scaling to improve performance.
          parsedImage.noScaleCache = true;
          URL.revokeObjectURL(url);
          const imageWidth = parsedImage.width || MAX_VISIBLE_CANVAS_SIZE;
          const imageHeight = parsedImage.height || MAX_VISIBLE_CANVAS_SIZE;
          const originalDimensions = { width: imageWidth, height: imageHeight };
          const maxDimensions = { width: maxWidth, height: maxHeight };
          const imageScale = calculateScaleToFit(originalDimensions, maxDimensions);
          parsedImage.scale(imageScale);
          if (typeof placementBuffer !== 'undefined') {
            parsedImage.set({
              left: placementBuffer,
              top: placementBuffer,
            });
          }
          if (type === 'template') {
            const centerX = (maxWidth - parsedImage.getScaledWidth()) / 2;
            const centerY = (maxHeight - parsedImage.getScaledHeight()) / 2;
            parsedImage.set({
              left: centerX,
              top: centerY,
            });
          }
          parsedImage.set({
            imageName,
            isSelectionIgnored,
            imageId: id,
            imageType: type,
            imageProperties: imageProperties,
          });
          resolve({ id, type, image: parsedImage });
        },
        { crossOrigin: 'anonymous' },
      );
    } catch (err) {
      console.error('Failed to parse image with fabric', err);
      reject(err);
    }
  });
};

const MOBILE_CANVAS_SIZE = 350;
const DESKTOP_CANVAS_SIZE = 600;

// the max visible canvas size based on the screen width
export const MAX_VISIBLE_CANVAS_SIZE =
  window.innerWidth <= Breakpoints.TABLET ? MOBILE_CANVAS_SIZE : DESKTOP_CANVAS_SIZE;

export const MAX_ORIGINAL_CANVAS_SIZE = 1024;
export const CanvasResolutionLimit = {
  MIN: 256,
  MAX: 10240,
} as const;

type TDimensions = {
  width: number;
  height: number;
  customCanvasSize?: number;
};

export const calculateDisplayDimensions = (
  width: number,
  height: number,
  customCanvasSize?: number,
): TDimensions => {
  let displayWidth: number;
  let displayHeight: number;
  const aspectRatio: number = width / height;

  // Set maximum canvas size, using custom size if provided, otherwise default to MAX_VISIBLE_CANVAS_SIZE
  const MAX_CANVAS_SIZE = customCanvasSize || MAX_VISIBLE_CANVAS_SIZE;

  if (aspectRatio >= 1) {
    // Compare img width with MAX_CANVAS_SIZE, take the smaller value, and adjust height to maintain aspect ratio
    displayWidth = Math.min(width, MAX_CANVAS_SIZE);
    displayHeight = displayWidth / aspectRatio;
  } else {
    // Compare img height with MAX_CANVAS_SIZE, take the smaller value, and adjust width to maintain aspect ratio
    displayHeight = Math.min(height, MAX_CANVAS_SIZE);
    displayWidth = displayHeight * aspectRatio;
  }

  return { width: displayWidth, height: displayHeight };
};

// Fabric.js uses a fixed number of fraction digits for serialization.
// This function converts a number to a fixed number of fraction digits if needed.
const toFabricFixed = (value: number) => {
  return fabric.util.toFixed(value, DEFAULT_FABRIC_FRACTION_DIGITS);
};

export const calculateScaleToFit = (
  originalDimensions: TDimensions,
  maxDimensions: TDimensions,
) => {
  const { width: originalWidth, height: originalHeight } = originalDimensions;
  const { width: maxWidth, height: maxHeight } = maxDimensions;
  if (originalWidth <= maxWidth && originalHeight <= maxHeight) {
    // Image is already within the maximum dimensions, no need to scale.
    return 1;
  }
  // Calculate the scale to fit the image while maintaining the aspect ratio
  return Math.min(maxWidth / originalWidth, maxHeight / originalHeight);
};

export const applyCanvasDimensionChange = ({
  canvas,
  width,
  height,
}: {
  canvas: fabric.Canvas;
  width: number;
  height: number;
}) => {
  // Store the original dimensions of the canvas to redraw the canvas with the original aspect ratio on demand.
  canvas.originalDimensions = { width, height };
  const displayDimensions = calculateDisplayDimensions(width, height);
  canvas.setDimensions({
    width: displayDimensions.width,
    height: displayDimensions.height,
  });
};

export const isCanvasResolutionOutOfBounds = (width: number, height: number) => {
  return (
    width < CanvasResolutionLimit.MIN ||
    width > CanvasResolutionLimit.MAX ||
    height < CanvasResolutionLimit.MIN ||
    height > CanvasResolutionLimit.MAX
  );
};

export const centerImageInCanvas = (image: fabric.Image, canvas: fabric.Canvas) => {
  const centerX = (canvas.getWidth() - image.getScaledWidth()) / 2;
  const centerY = (canvas.getHeight() - image.getScaledHeight()) / 2;
  image.set({
    left: centerX,
    top: centerY,
  });
};
