import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { RoutePaths } from '@/utils/route/constants';
import { PlanType } from './constants';
import { isPlanSufficient } from './helper';

interface PlanProtectedRouteProps {
  children: ReactNode;
  requiredPlan?: PlanType;
}

export const PlanProtectedRoute: FC<PlanProtectedRouteProps> = ({ children, requiredPlan }) => {
  const { plan: userPlan } = useAccountCtx();

  if (!userPlan) return null;

  const userHasSufficientPlan = requiredPlan ? isPlanSufficient(userPlan, requiredPlan) : true;

  if (!userHasSufficientPlan) {
    return <Navigate to={RoutePaths.BASE} replace={true} />;
  }

  return <>{children}</>;
};
