import { useQueryClient } from '@tanstack/react-query';
import { Breakpoints } from '@/utils/general/constant';
import { ModalView } from 'components/common/ModalView';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { CraitButton } from 'components/common/CraitButton';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';
import { useRef, useState } from 'react';
import { toast } from 'sonner';
import { useWindowSize } from 'usehooks-ts';
import { FileUploadInput } from '../products/upload/FileUploadInput';
import { useUploadStyle } from './helper';

const useUploadForm = (closeModal: () => void, queryKey: string) => {
  const [file, setFile] = useState<File | null>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  const uploadStyle = useUploadStyle();
  const queryClient = useQueryClient();

  const handleUpload = async () => {
    try {
      if (uploadStyle.isPending) return;
      if (!file || !nameInput.current?.value) {
        toast.error('Please select a file and enter a name');
        return;
      }

      await uploadStyle.mutateAsync({
        image: file,
        name: nameInput.current.value,
      });
      queryClient.invalidateQueries({
        queryKey: [{ searchSection: queryKey }],
        exact: false,
      });
      closeModal();
    } catch (error) {
      console.log(error);
      toast.error('Failed to upload style');
    }
  };

  return {
    file,
    setFile,
    nameInput,
    handleUpload,
    isPending: uploadStyle.isPending,
  };
};

const UploadForm = ({ onClose, queryKey }: { onClose: () => void; queryKey: string }) => {
  const { file, setFile, nameInput, handleUpload, isPending } = useUploadForm(onClose, queryKey);

  return (
    <>
      <Separator className='mb-4' />
      <div className='flex flex-col items-center gap-6'>
        <FileUploadInput onFileUpload={setFile} />
        <div className='flex w-72 flex-col gap-3'>
          <Input ref={nameInput} placeholder='Enter inspiration name' />
          <CraitButton className='w-full' disabled={!file || isPending} onClick={handleUpload}>
            Upload
          </CraitButton>
          <Button variant='outline' className='w-full' onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

const TriggerButton = ({ children }: { children: React.ReactNode }) => (
  <Button className='text-dark h-[40px] w-full items-center gap-2 rounded-lg bg-crait-green-primary'>
    <span className='i-mdi-progress-upload h-[18px] w-[18px]'></span>
    <span className='text-sm'>{children}</span>
  </Button>
);

export const AddStyleModal = () => {
  const { width } = useWindowSize();
  const isDesktop = width > Breakpoints.TABLET;

  return (
    <>
      {isDesktop ? (
        <ModalView
          trigger={<TriggerButton>Upload new inspiration</TriggerButton>}
          titleElement={<div className='text-lg font-medium'>Upload New Inspiration</div>}
        >
          {({ closeModal }) => <UploadForm onClose={closeModal} queryKey='styles' />}
        </ModalView>
      ) : (
        <MobileMenuSheet
          triggerButton={<TriggerButton>Upload new inspiration</TriggerButton>}
          titleElement='Upload New Inspiration'
        >
          {({ closeModal }) => <UploadForm onClose={closeModal} queryKey='styles' />}
        </MobileMenuSheet>
      )}
    </>
  );
};
