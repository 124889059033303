import { isFabricText } from '@/utils/helper';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { fabric } from 'fabric';
import { ChangeEvent, FC, useEffect, useRef } from 'react';

interface AdjustLineHeightProps {
  canvasInstance: fabric.Canvas | null;
}

export const AdjustLineHeight: FC<AdjustLineHeightProps> = ({ canvasInstance }) => {
  const lineHeightInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!canvasInstance) return;

    const selectionHandler = () => {
      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject) || !activeObject.lineHeight) {
        lineHeightInputRef.current?.blur();
        return;
      }

      if (!lineHeightInputRef.current) return;

      lineHeightInputRef.current.value = activeObject.lineHeight.toString();
    };

    canvasInstance.on('selection:created', selectionHandler);
    canvasInstance.on('selection:updated', selectionHandler);
    canvasInstance.on('selection:cleared', selectionHandler);

    return () => {
      canvasInstance.off('selection:created', selectionHandler);
      canvasInstance.off('selection:updated', selectionHandler);
      canvasInstance.off('selection:cleared', selectionHandler);
    };
  }, [canvasInstance]);

  const changeLineHeight = (newLineHeight: string) => {
    if (!canvasInstance) return;

    const activeObject = canvasInstance.getActiveObject();
    if (!activeObject || !(activeObject instanceof fabric.IText)) return;

    const numericLineHeight = parseFloat(newLineHeight);
    if (isNaN(numericLineHeight)) return;

    activeObject.set('lineHeight', numericLineHeight);
    canvasInstance.requestRenderAll();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue) return;

    changeLineHeight(newValue);
  };

  return (
    <Button asChild variant='outline' className='rounded-lg bg-slate-50 px-2'>
      <div className='flex items-center gap-1.5'>
        <Label htmlFor='line-height' className='flex items-center gap-1.5'>
          <span className='i-mdi-format-line-height size-4'></span>
        </Label>
        <Input
          ref={lineHeightInputRef}
          id='line-height'
          type='number'
          min='0'
          defaultValue={1}
          onChange={onInputChange}
          className='h-full w-7 border-none bg-transparent px-1 text-center text-xs'
        />
      </div>
    </Button>
  );
};
