import { useContext, createContext, ReactNode, FC, useEffect } from 'react';
import { useEventCallback } from 'usehooks-ts';

type TResetContextType = string | null | undefined;

const ResetContext = createContext<TResetContextType | undefined>(undefined);

interface ResetProviderProps {
  children: ReactNode;
  resetKeyToForceUnmount: string;
}

export const StoreResetterProvider: FC<ResetProviderProps> = ({
  children,
  resetKeyToForceUnmount,
}) => {
  return <ResetContext.Provider value={resetKeyToForceUnmount}>{children}</ResetContext.Provider>;
};

const useResetContext = () => {
  const context = useContext(ResetContext);
  return context;
};

export const useUnmountWithResetContext = (cleanupFn: () => void) => {
  const resetKey = useResetContext();
  const stableCleanup = useEventCallback(cleanupFn);

  useEffect(() => {
    return () => {
      stableCleanup();
    };
  }, [resetKey]);
};
