import { create } from 'zustand';

export const AiSceneSideMenus = {
  BASE: '',
  CREATE_TOOL: 'createTool',
  TEXT_TOOL: 'textTool',
  FILTERS: 'filters',
  ADD_LOGO: 'addLogo',
  EDIT_TOOL: 'editTool',
} as const;

export type AiSceneSideMenusType = (typeof AiSceneSideMenus)[keyof typeof AiSceneSideMenus];

interface MenuState {
  activeMenu: AiSceneSideMenusType;
  setActiveMenu: (menu: AiSceneSideMenusType) => void;
  goBack: () => void;
}

const secondaryMenus: AiSceneSideMenusType[] = [
  AiSceneSideMenus.TEXT_TOOL,
  AiSceneSideMenus.FILTERS,
  AiSceneSideMenus.ADD_LOGO,
];

export const useMenuStore = create<MenuState>((set, get) => ({
  activeMenu: AiSceneSideMenus.CREATE_TOOL,
  setActiveMenu: (menu) => set({ activeMenu: menu }),
  goBack: () => {
    const currentMenu: AiSceneSideMenusType = get().activeMenu;
    if (secondaryMenus.includes(currentMenu)) {
      set({ activeMenu: AiSceneSideMenus.CREATE_TOOL });
    }
  },
}));
