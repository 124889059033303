import React, { useState, useEffect } from 'react';
import { OnboardingModal } from './OnboardingModal';
import { useStoreModal } from './store';
import { useUserStore } from '@/providers/user/hooks';
import { WelcomeStep } from './step-one/OnboardingWelcomeStep';
import { ProfileNameStep } from './step-two/OnboardingProfileNameStep';
import { BrandSetupStep } from './step-four/OnboardingBrandSetupStep';

import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { WorkInfoStep } from './step-three/OnboardingWorkInfoStep';

const STEP_INDICATOR_CLASS = 'h-1 w-1/3 rounded-[10px]';
const STEPS = [
  { id: 1, component: WelcomeStep },
  { id: 2, component: ProfileNameStep },
  { id: 3, component: WorkInfoStep },
  { id: 4, component: BrandSetupStep },
];

export const OnboardingMultiStepForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const storeModal = useStoreModal();
  const isOnboarded = useUserStore((state) => state.user?.isOnboarded);
  const { width } = useWindowSize();
  const isDesktop = width > Breakpoints.TABLET;

  useEffect(() => {
    if (!isOnboarded) storeModal.onOpen();
  }, [isOnboarded]);

  if (isOnboarded) return null;

  const moveToNextStep = () => setCurrentStep((prev) => prev + 1);

  const renderStep = () => {
    const StepComponent = STEPS.find((step) => step.id === currentStep)?.component;
    return StepComponent ? <StepComponent moveToNextStep={moveToNextStep} /> : null;
  };

  const renderStepIndicator = () =>
    STEPS.map((step) => (
      <div
        key={step.id}
        className={`${STEP_INDICATOR_CLASS} ${currentStep >= step.id ? 'bg-black' : 'bg-gray-300'}`}
      />
    ));

  const OnboardingContent = (
    <div className='mx-auto mb-5 flex h-full w-full max-w-md flex-col justify-between rounded-lg bg-white p-0 md:gap-5'>
      <div className='mb-6 flex justify-between gap-3 px-[30px]'>{renderStepIndicator()}</div>
      {renderStep()}
    </div>
  );

  return isDesktop ? (
    <OnboardingModal isOpen={storeModal.isOpen} onClose={storeModal.onClose}>
      {OnboardingContent}
    </OnboardingModal>
  ) : (
    <MobileMenuSheet contentClassName='max-h-[90vh]' isOpen={storeModal.isOpen}>
      {OnboardingContent}
    </MobileMenuSheet>
  );
};
