import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AiSceneGenerationEndpoint, HistoryEndpoint } from '@/utils/fetch/constants';
import { getAiSceneGenerationApiUrl, getHistoryApiUrl } from '@/utils/fetch/helper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TScene } from 'components/ai-scene/review/queries';
import { TPaginatedResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { TLastGenerationSummary } from 'pages/workspace/projects/helper';

export type TActivity = {
  id: string;
  isRead: boolean;
  timestamp: number;
  memberId: number;
  avatar: string;
  content: string;
  url: string;
};

type TGetSceneHistoryPayload = {
  organizationId: number;
  onlyFavorites?: boolean;
  pageId?: number;
};

const getSceneHistory = async (
  props: TGetSceneHistoryPayload,
): Promise<TPaginatedResponse<TScene>> => {
  const queryParams = new URLSearchParams();
  if (props.onlyFavorites) {
    queryParams.append('onlyFavorites', 'true');
  }
  if (props.pageId) {
    queryParams.append('pageId', props.pageId.toString());
  }
  const baseUrl = getHistoryApiUrl(props.organizationId, HistoryEndpoint.BASE);
  const hasAnyQueryParams = queryParams.toString().length > 0;
  const urlSuffix = hasAnyQueryParams ? `?${queryParams.toString()}` : '';
  const response = await apiClient.get(baseUrl + urlSuffix);
  return response.data;
};

const GET_HISTORY_SCENE_QUERY_KEY = 'getHistoryScene';

export const useGetSceneHistory = (props: Omit<TGetSceneHistoryPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [
      { url: HistoryEndpoint.BASE, organizationId, _name: GET_HISTORY_SCENE_QUERY_KEY, ...props },
    ],
    queryFn: ({ queryKey }) => {
      return getSceneHistory(queryKey[0]);
    },
    placeholderData: (prev) => prev,
  });
  return result;
};

type TGetGenerationDataSummaryPayload = {
  organizationId: number;
  projectId: string;
  sceneId: string;
};

export const getSceneSummary = async ({
  sceneId,
  projectId,
  organizationId,
}: TGetGenerationDataSummaryPayload): Promise<TLastGenerationSummary> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}/generation-summary`;

  const response = await apiClient.get<TLastGenerationSummary>(url, {
    params: {
      scene_id: sceneId,
      project_id: projectId,
      organization_id: organizationId,
    },
  });
  return response.data;
};

interface SceneSummaryQueryKey {
  url: string;
  projectId: string;
  organizationId: number;
  sceneId: string;
}

export const useGetSceneSummary = (sceneId: string, projectId: string, staleTime?: number) => {
  const queryClient = useQueryClient();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const result = useQuery({
    queryKey: [{ url: AiSceneGenerationEndpoint.BASE, projectId, organizationId, sceneId }],
    queryFn: ({ queryKey }: { queryKey: SceneSummaryQueryKey[] }) => {
      return getSceneSummary({
        sceneId: queryKey[0].sceneId,
        projectId: queryKey[0].projectId,
        organizationId: queryKey[0].organizationId,
      });
    },
    placeholderData: (prev) => prev,
    ...(typeof staleTime !== 'undefined' && { staleTime }),
  });

  return result;
};

type TDownloadSceneWithCreditCheck = {
  organizationId: number;
  projectId: string;
  sceneId: string;
};

export const downloadSceneWithCreditCheck = async ({
  sceneId,
  projectId,
  organizationId,
}: TDownloadSceneWithCreditCheck): Promise<{ status: string; error?: string }> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}/download-request`;
  const response = await apiClient.get<{ status: string }>(url, {
    params: {
      scene_id: sceneId,
      project_id: projectId,
      organization_id: organizationId,
    },
  });
  return response.data;
};
