import React, { useRef, useState } from 'react';
import { Textarea } from 'components/ui/textarea';
import { CloseModalButton } from '../_components/CloseModalButton';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ScrollArea } from 'components/ui/scroll-area';
import { useUploadLogoMutation } from 'components/workspace/brand-library/brand-kit/queries';
import { usePartialUpdateBrandVoiceGuide } from 'components/workspace/brand-library/brand-kit/tone-of-voice/queries';
import { ColorContent } from './_components/ColorContent';
import { useStoreModal } from '../store';
import { useUserStore } from '@/providers/user/hooks';
import { useCompleteOnboarding } from '../queries';
import { toast } from 'sonner';
import Icon from '@/assets/icons/uploadArrow.svg?react';
import { ContinueButton } from '../_components/ContinueButton';

const TEXTAREA_CLASSNAMES =
  'bg-white text-xs focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0';

export const BrandSetupStep = () => {
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const preferredKeywordsRef = useRef<HTMLTextAreaElement>(null);
  const bannedKeywordsRef = useRef<HTMLTextAreaElement>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const uploadLogo = useUploadLogoMutation();
  const updateBrandVoiceGuide = usePartialUpdateBrandVoiceGuide();
  const storeModal = useStoreModal();
  const completeOnboarding = useCompleteOnboarding();
  const setOnboarding = useUserStore((state) => state.setUserOnbardingInfo);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLogo(file);
    }
  };

  const handleUploadClick = async () => {
    try {
      const logoUploadPromise = logo
        ? uploadLogo.mutateAsync({ image: logo, name: 'Logo' })
        : Promise.resolve();

      const updateBrandVoiceGuidePromise = updateBrandVoiceGuide.mutateAsync({
        description: descriptionRef.current?.value || '',
        preferredKeywords: preferredKeywordsRef.current?.value || '',
        bannedKeywords: bannedKeywordsRef.current?.value || '',
      });

      const completeOnboardingPromise = completeOnboarding.mutateAsync();

      await Promise.all([
        logoUploadPromise,
        updateBrandVoiceGuidePromise,
        completeOnboardingPromise,
      ]);

      setOnboarding({ isOnboarded: true });
      storeModal.onClose();
      toast.success('Onboarding completed successfully');
    } catch (error) {
      console.error('Failed to complete the onboarding', error);
      toast.error('Failed to complete the onboarding. Please try again.');
    }
  };

  return (
    <>
      <ScrollArea className='h-[530px] w-[480px] pr-[30px] md:h-[60vh] md:w-auto md:pr-0'>
        <div className='flex flex-col items-center gap-[26px]'>
          <div className='relative mx-auto flex h-[120px] w-[120px] items-center justify-center rounded-full bg-gray-200'>
            {logo ? (
              <img
                src={URL.createObjectURL(logo)}
                alt='logo'
                className='h-full w-full rounded-full object-contain'
              />
            ) : (
              <span className='text-xl text-gray-500'>your logo</span>
            )}
            <label className='absolute bottom-0 right-0 flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-orange-500'>
              <Icon />
              <input type='file' className='hidden' onChange={handleLogoUpload} />
            </label>
          </div>
          <div className='flex flex-col items-center'>
            <h4 className='mx-auto mb-[10px] text-xl font-bold'>Add your brand</h4>
            <p className='text-center text-[14px] text-[#000000]'>
              Tell us about your brand to train our AI with your brand identity.
            </p>
          </div>
          {/* 
          **disabled until the backend is completed**
          
          <div className='flex w-full flex-col items-start'>
            <div className='mb-1 flex w-full justify-between'>
              <label className='text-[13px] font-semibold text-c-dark'>Fonts</label>
              <label className='text-[13px] font-semibold text-[#E16236] underline'>
                Upload a font
              </label>
            </div>
            <Select>
              <SelectTrigger className='bg-white text-[13px] text-[#777777] focus:outline-none focus:ring-0 focus:ring-offset-0'>
                <SelectValue placeholder='Select a font' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Fonts</SelectLabel>
                  <SelectItem value='arial'>Arial</SelectItem>
                  <SelectItem value='times-new-roman'>Times New Roman</SelectItem>
                  <SelectItem value='courier-new'>Courier New</SelectItem>
                  <SelectItem value='georgia'>Georgia</SelectItem>
                  <SelectItem value='verdana'>Verdana</SelectItem>
                  <SelectItem value='helvetica'>Helvetica</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          */}
          <ColorContent />

          <div className='flex w-full flex-col items-start gap-1'>
            <label htmlFor='brandDescription' className=' text-[13px] font-semibold text-c-dark'>
              Brand description & tone example
            </label>
            <Textarea
              ref={descriptionRef}
              id='brandDescription'
              className='bg-white text-xs focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0'
              placeholder='Describe your brand in a few words'
            />
          </div>

          <div className='flex w-full flex-col items-start gap-1'>
            <label htmlFor='preferredKeywords' className='text-[13px] font-semibold text-c-dark'>
              Always use these keywords
            </label>
            <Textarea
              ref={preferredKeywordsRef}
              id='preferredKeywords'
              className={TEXTAREA_CLASSNAMES}
              placeholder='Describe your brand in a few words'
            />
          </div>

          <div className='flex w-full flex-col items-start gap-1'>
            <label htmlFor='bannedKeywords' className=' text-[13px] font-semibold text-c-dark'>
              Never use these keywords
            </label>
            <Textarea
              ref={bannedKeywordsRef}
              id='bannedKeywords'
              className={TEXTAREA_CLASSNAMES}
              placeholder='Describe your brand in a few words'
            />
          </div>
        </div>
      </ScrollArea>

      <ContinueButton onClick={handleUploadClick} />
      <CloseModalButton />
    </>
  );
};
