export const FONTS_DATA = [
  {
    id: 1,
    name: 'Roboto',
  },
  {
    id: 3,
    name: 'Calibre',
  },
  {
    id: 6,
    name: 'Inter Mono',
  },
  {
    id: 7,
    name: 'Helvetica',
  },
  {
    id: 9,
    name: 'Arial',
  },
  {
    id: 10,
    name: 'Arial Black',
  },
  {
    id: 11,
    name: 'Courier New',
  },
  {
    id: 12,
    name: 'Times New Roman',
  },
  {
    id: 13,
    name: 'Verdana',
  },
  {
    id: 18,
    name: 'Comic Sans MS',
  },
  {
    id: 19,
    name: 'Trebuchet MS',
  },
  {
    id: 20,
    name: 'Arial Narrow',
  },
  {
    id: 21,
    name: 'Impact',
  },
  {
    id: 22,
    name: 'Tahoma',
  },
  {
    id: 26,
    name: 'Lucida Sans',
  },
  {
    id: 27,
    name: 'Geneva',
  },
  {
    id: 28,
    name: 'Optima',
  },
  {
    id: 29,
    name: 'Avant Garde',
  },
  {
    id: 30,
    name: 'Copperplate',
  },
] as const;

export const FONT_SIZES_DATA = [
  {
    id: 11,
    size: '24',
  },
  {
    id: 12,
    size: '26',
  },
  {
    id: 13,
    size: '28',
  },
  {
    id: 14,
    size: '34',
  },
  {
    id: 15,
    size: '40',
  },
  {
    id: 16,
    size: '48',
  },
  {
    id: 17,
    size: '72',
  },
  {
    id: 18,
    size: '96',
  },
  {
    id: 19,
    size: '112',
  },
  {
    id: 20,
    size: '120',
  },
] as const;
