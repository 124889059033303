import { PlanProtectedRoute } from 'components/plan-guard/PlanProtectedRoute';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { BrandBannerView } from 'components/workspace/brand-library/banner/BrandBannerView';

export const BrandLibraryBannerPage = () => {
  return (
    <PlanProtectedRoute requiredPlan={FeatureAccessRequirements.BANNER_PAGE.requiredPlan}>
      <BrandBannerView />
    </PlanProtectedRoute>
  );
};
