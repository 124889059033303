import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { BASE_API_URL, INTERCEPTOR_BYPASSED_AUTH_ENDPOINTS } from './constants';
import { LocalCacheKey, localCache } from '../localStorageUtility';
import { isPast } from 'date-fns';
import { createTokenRefresher, updateTokensInCache } from './helper';
import invariant from 'tiny-invariant';
import { toast } from 'sonner';

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
});

const SID_HEADER = 'SID';

const { tokenRefresher } = createTokenRefresher();

apiClient.interceptors.request.use(async (request) => {
  try {
    if (shouldBypassInterceptor(request.url)) return request;

    const accessTokenInHeaders = request.headers.Authorization;
    // If access token is not in headers, try to get it from local storage.
    if (!accessTokenInHeaders) {
      const accessToken = localCache.get(LocalCacheKey.ACCESS_TOKEN);
      invariant(accessToken, 'Access token is missing.');
      setAuthHeaders({ accessToken, currentRequest: request });
    }

    await validateAndRefreshToken(request);
  } catch (error) {
    console.error('Error refreshing token', error);
    redirectToLoginWithToast();
  }

  return request;
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    if (shouldBypassInterceptor(originalRequest.url)) {
      return Promise.reject(error);
    }

    const isAuthorizationError = error.response?.status === 401 || error.response?.status === 403;
    if (isAuthorizationError && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await validateAndRefreshToken(originalRequest);
        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token', refreshError);
        redirectToLoginWithToast();
      }
    } else if (isAuthorizationError) {
      redirectToLoginWithToast();
    }
    return Promise.reject(error);
  },
);

export const setAuthHeaders = ({
  sid,
  accessToken,
  currentRequest,
}: {
  sid?: string;
  accessToken: string | null;
  currentRequest?: InternalAxiosRequestConfig<any>;
}): void => {
  if (sid) {
    apiClient.defaults.headers.common[SID_HEADER] = sid;
    if (currentRequest) currentRequest.headers[SID_HEADER] = sid;
  }

  if (accessToken) {
    const bearer = `Bearer ${accessToken}`;
    apiClient.defaults.headers.common.Authorization = bearer;
    if (currentRequest) currentRequest.headers.Authorization = bearer;
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

const validateAndRefreshToken = async (request: InternalAxiosRequestConfig<any>): Promise<void> => {
  const expiryTsInSeconds = localCache.get(LocalCacheKey.EXPIRY);
  invariant(expiryTsInSeconds, 'Expiry timestamp is missing.');

  const date = new Date(expiryTsInSeconds * 1000);
  const isExpired = isPast(date);
  if (!isExpired) return;

  const refreshToken = localCache.get(LocalCacheKey.REFRESH_TOKEN);
  invariant(refreshToken, 'Refresh token is missing.');

  const refreshedTokenData = await tokenRefresher(refreshToken);
  updateTokensInCache(refreshedTokenData);
  setAuthHeaders({ accessToken: refreshedTokenData.access_token, currentRequest: request });
};

const redirectToLoginWithToast = () => {
  localCache.removeAll();
  window.location.replace('/');
  toast.error('Your session has expired. Please log in again.');
};

const shouldBypassInterceptor = (url: string | undefined): boolean => {
  if (!url) return false;

  return INTERCEPTOR_BYPASSED_AUTH_ENDPOINTS.some((endpoint) => url.startsWith(endpoint));
};
