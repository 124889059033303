export const RoutePaths = {
  BASE: '/',
  LOGIN: 'login',
  GOOGLE_VERIFY: 'google-verify',
  SIGN_UP: 'sign-up',
  EMAIL_CONFIRM: 'sign-up/confirm',
  PASSWORD_FORGOT: 'password-forgot',
  PASSWORD_RESET: 'password-reset',
  ACCOUNT: 'account',
  WORKSPACE: 'home',
  CREATIVE_SUITE: 'creative-suite',
  CREATIVE_INSIGHTS: 'creative-insights',
  AI_SCENE: 'ai-scene',
  AI_BANNER: 'ai-banner',
  AI_IMAGE: 'ai-image',
  AI_EDIT: 'ai-edit',
  EMAIL_UNSUBSCRIBE: 'manage-consent',
  NOT_FOUND: '*',
} as const;

export const AccountPaths = {
  PEOPLE: 'people',
  PROFILE: 'profile',
  PAYMENT: 'payment',
} as const;

export const WorkspacePaths = {
  BRAND_KIT: 'brand-kit',
  BRAND_LIBRARY: 'assets-library',
  SAVED_SETTINGS: 'saved-settings',
} as const;

export const CreativeSuitePaths = {
  PROJECTS: 'projects',
  HISTORY: 'history',
} as const;

export const BrandLibraryPaths = {
  PRODUCTS: 'products',
  BANNER: 'banner',
  LAYOUT: 'layout',
  STYLES: 'styles',
  ELEMENTS: 'elements',
  BRAND_KIT: 'brand-kit',
  TONE_OF_VOICE: 'tone-of-voice',
} as const;
