import { GeneratedContents } from './result-sidebar/GeneratedContents';
import { useParams } from 'react-router';

export const AiSceneResultsSidebar = () => {
  const projectId = useParams().projectId;

  if (!projectId) return null;

  return <GeneratedContents />;
};
