import { ImageType } from '@/utils/store/constants';
import { fabric } from 'fabric';
import invariant from 'tiny-invariant';

export const restoreCreatedPath = async (path: fabric.Path, canvas: fabric.Canvas) => {
  return new Promise((resolve, reject) => {
    try {
      path.clone((clonedPath: fabric.Path) => {
        clonedPath.set({
          fill: undefined,
          stroke: 'white',
          strokeWidth: canvas.freeDrawingBrush.width,
          selectable: false,
          evented: false,
        });

        const objects = canvas.getObjects();
        const alphaObj = objects.find(
          (obj: any) => obj.imageType === ImageType.ALPHA,
        ) as fabric.Image;
        const transparentObj = objects.find(
          (obj: any) => obj.imageType === ImageType.TRANSPARENT,
        ) as fabric.Image;
        const opaqueObj = objects.find(
          (obj: any) => obj.imageType === ImageType.OPAQUE,
        ) as fabric.Image;
        invariant(opaqueObj, 'Opaque image not found in canvas.');
        invariant(alphaObj, 'Alpha image not found in canvas.');
        invariant(transparentObj, 'Transparent image not found in canvas.');

        const offscreenCanvas = document.createElement('canvas');
        // It is important to set the canvas dimensions to the scaled dimensions of the opaque image.
        // Otherwise, there will be flickering when bg is toggled because of cloned alphaObj.
        offscreenCanvas.width = opaqueObj.getScaledWidth();
        offscreenCanvas.height = opaqueObj.getScaledHeight();
        const ctx = offscreenCanvas.getContext('2d');
        invariant(ctx, 'Canvas context not found.');

        alphaObj.set({ visible: true });
        ctx!.drawImage(alphaObj.getElement(), 0, 0, offscreenCanvas.width, offscreenCanvas.height);

        const pathOffsetX = clonedPath.left! - (transparentObj.left || 0);
        const pathOffsetY = clonedPath.top! - (transparentObj.top || 0);
        clonedPath.set({
          left: pathOffsetX,
          top: pathOffsetY,
        });

        clonedPath.render(ctx!);
        const newAlphaObj = new fabric.Image(offscreenCanvas);
        newAlphaObj.set({
          imageId: alphaObj.imageId,
          imageType: ImageType.ALPHA,
          visible: false,
          selectable: false,
          evented: false,
        });
        canvas.add(newAlphaObj);
        canvas.remove(alphaObj);

        const clonedOffscreenCanvas = document.createElement('canvas');
        clonedOffscreenCanvas.width = offscreenCanvas.width;
        clonedOffscreenCanvas.height = offscreenCanvas.height;
        const clonedCtx = clonedOffscreenCanvas.getContext('2d');
        invariant(clonedCtx, 'Canvas context not found.');

        clonedCtx!.drawImage(offscreenCanvas, 0, 0);
        clonedCtx!.globalCompositeOperation = 'source-in';
        clonedCtx!.drawImage(
          opaqueObj.getElement(),
          0,
          0,
          clonedOffscreenCanvas.width,
          clonedOffscreenCanvas.height,
        );

        const newTransparentObj = new fabric.Image(clonedOffscreenCanvas);
        newTransparentObj.set({
          imageId: transparentObj.imageId,
          imageType: ImageType.TRANSPARENT,
          isSelectionIgnored: true,
          left: transparentObj.left,
          top: transparentObj.top,
          selectable: false,
          evented: false,
        });
        canvas.remove(transparentObj);
        canvas.add(newTransparentObj);
        canvas.remove(path);
        canvas.requestRenderAll();
        resolve(true);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const removeCreatedPath = async (path: fabric.Path, canvas: fabric.Canvas) => {
  return new Promise((resolve, reject) => {
    try {
      path.clone((clonedPath: fabric.Path) => {
        clonedPath.set({
          fill: undefined,
          stroke: 'rgba(0,0,0,1)',
          strokeWidth: canvas.freeDrawingBrush.width,
          selectable: false,
          evented: false,
          globalCompositeOperation: 'destination-out',
        });
        const objects = canvas.getObjects();
        const alphaObj = objects.find((obj) => obj.imageType === ImageType.ALPHA) as fabric.Image;
        const transparentObj = objects.find(
          (obj) => obj.imageType === ImageType.TRANSPARENT,
        ) as fabric.Image;
        const opaqueObj = objects.find((obj) => obj.imageType === ImageType.OPAQUE) as fabric.Image;

        if (!alphaObj || !transparentObj || !opaqueObj) {
          throw new Error('Required images not found on canvas.');
        }

        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = alphaObj.getScaledWidth();
        offscreenCanvas.height = alphaObj.getScaledHeight();
        const ctx = offscreenCanvas.getContext('2d');
        if (!ctx) {
          throw new Error('Failed to get 2D context.');
        }

        alphaObj.set({ visible: true });
        ctx.drawImage(alphaObj.getElement(), 0, 0, offscreenCanvas.width, offscreenCanvas.height);
        // Ensure path properties are set for removal, e.g., using 'destination-out' composite operation
        ctx.globalCompositeOperation = 'destination-out';
        const pathOffsetX = clonedPath.left! - (transparentObj.left || 0);
        const pathOffsetY = clonedPath.top! - (transparentObj.top || 0);
        clonedPath.set({
          left: pathOffsetX,
          top: pathOffsetY,
        });
        clonedPath.render(ctx);
        const newAlphaObj = new fabric.Image(offscreenCanvas);
        newAlphaObj.set({
          imageId: alphaObj.imageId,
          imageType: ImageType.ALPHA,
          visible: false,
          selectable: false,
          evented: false,
        });
        alphaObj.set({ visible: false });
        canvas.add(newAlphaObj);
        canvas.remove(alphaObj);

        // Reset composite operation to default for next operations
        ctx.globalCompositeOperation = 'source-over';

        const clonedOffscreenCanvas = document.createElement('canvas');
        clonedOffscreenCanvas.width = offscreenCanvas.width;
        clonedOffscreenCanvas.height = offscreenCanvas.height;
        const clonedCtx = clonedOffscreenCanvas.getContext('2d');
        if (!clonedCtx) {
          throw new Error('Failed to get 2D context for cloned canvas.');
        }

        clonedCtx.drawImage(offscreenCanvas, 0, 0);
        // Set 'source-in' to draw only where both images overlap
        clonedCtx.globalCompositeOperation = 'source-in';
        clonedCtx.drawImage(
          opaqueObj.getElement(),
          0,
          0,
          clonedOffscreenCanvas.width,
          clonedOffscreenCanvas.height,
        );
        const newTransparentObj = new fabric.Image(clonedOffscreenCanvas);
        newTransparentObj.set({
          imageId: transparentObj.imageId,
          imageType: ImageType.TRANSPARENT,
          left: transparentObj.left,
          top: transparentObj.top,
          selectable: false,
          evented: false,
        });

        canvas.add(newTransparentObj);
        canvas.remove(transparentObj);
        canvas.remove(path);
        canvas.requestRenderAll();
        resolve(true);
      });
    } catch (e) {
      reject(e);
    }
  });
};
