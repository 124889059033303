import { ApplyDimension } from './ApplyDimension';
import { FC, ReactNode } from 'react';
import { CustomCanvasSizes } from './CustomCanvasSizes';
import { ScrollArea } from 'components/ui/scroll-area';
import { useCanvasDimensionHandler } from './useCanvasDimensionHandler';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { PlanGuard } from 'components/plan-guard/PlanGuard';

import SquareIcon from '@/assets/icons/ai-scene-icons/square.svg?react';
import LandscapeIcon from '@/assets/icons/ai-scene-icons/landspace.svg?react';
import PortraitIcon from '@/assets/icons/ai-scene-icons/portrail.svg?react';
import BannerIcon from '@/assets/icons/ai-scene-icons/banner.svg?react';
import InstagramIcon from '@/assets/icons/ai-scene-icons/instagram.svg?react';
import { cn } from '@/lib/utils';

export const COMMON_DIMENSIONS = [
  {
    id: 'square',
    text: 'Square (1:1) (768*768)',
    width: 768,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: SquareIcon,
  },
  {
    id: 'square-pro',
    text: 'Large Square (1:1) (1024*1024)',
    width: 1024,
    height: 1024,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: SquareIcon,
  },
  {
    id: 'landscape',
    text: 'Landscape (4:3) (768*576)',
    width: 768,
    height: 576,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: LandscapeIcon,
  },
  {
    id: 'landscape-pro',
    text: 'Large Landscape (4:3) (1024*768)',
    width: 1024,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: LandscapeIcon,
  },
  {
    id: 'portrait',
    text: 'Portrait (3:4) (576*768)',
    width: 576,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: PortraitIcon,
  },
  {
    id: 'portrait-pro',
    text: 'Large Portrait (3:4) (768*1024)',
    width: 768,
    height: 1024,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: PortraitIcon,
  },
  {
    id: 'banner',
    text: 'Banner (4:1) (2048*512)',
    width: 2048,
    height: 512,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.requiredPlan,
    icon: BannerIcon,
  },
  {
    id: 'widescreen',
    text: 'Widescreen (16:9): 1280x720',
    width: 1280,
    height: 720,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
    icon: LandscapeIcon,
  },
  {
    id: 'ig-post',
    text: 'Instagram Post (4:5) (928*1160)',
    width: 928,
    height: 1160,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_BUTTON.requiredPlan,
    icon: InstagramIcon,
  },
  {
    id: 'ig-story',
    text: 'Instagram Story (9:16) (720*1280)',
    width: 720,
    height: 1280,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_BUTTON.requiredPlan,
    icon: InstagramIcon,
  },
];

interface ResizeLayoutProps {
  children?: ReactNode;
}

export const ResizeLayout: FC<ResizeLayoutProps> = ({ children }) => {
  const { onDimensionSelected, canvasDimensions } = useCanvasDimensionHandler();

  return (
    <div className='relative flex h-[80%] flex-col gap-3 overflow-hidden'>
      <ScrollArea viewportClassName='w-[96%] '>
        <CustomCanvasSizes
          onDimensionSelected={onDimensionSelected}
          canvasDimensions={canvasDimensions}
        />
        <div className='flex flex-col gap-2 text-main font-semibold'>
          {COMMON_DIMENSIONS.map((size) => (
            <PlanGuard
              requiredPlan={size.requiredPlan}
              featureDescription={
                FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.featureDescription
              }
              key={size.id}
            >
              <div
                className={cn(
                  'flex h-[44px] cursor-pointer items-center gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 hover:bg-slate-100',
                  {
                    'bg-slate-200':
                      canvasDimensions.width === size.width &&
                      canvasDimensions.height === size.height,
                  },
                )}
                onClick={() => {
                  onDimensionSelected(size.width, size.height);
                }}
              >
                <div className='flex w-6 justify-center'>
                  <size.icon />
                </div>
                <span>{size.text}</span>
              </div>
            </PlanGuard>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};
