import { useEffect } from 'react';
import { fabric } from 'fabric';
import { serializeCanvas } from '@/utils/helper';
import { applyCanvasDimensionChange } from 'components/ai-scene/create/editor/canvas/helper';

export const useSyncCanvasState = (
  canvasInstance: React.RefObject<fabric.Canvas>,
  canvasState: string | null,
): void => {
  useEffect(() => {
    const fabricRef = canvasInstance.current;
    if (!fabricRef) return;

    const currentFabricState = serializeCanvas(fabricRef);
    if (canvasState && canvasState !== currentFabricState) {
      const parsedState = JSON.parse(canvasState) as fabric.Canvas;
      const shouldCalculateDimensions =
        parsedState.originalDimensions &&
        (parsedState.originalDimensions.width !== fabricRef.originalDimensions?.width ||
          parsedState.originalDimensions.height !== fabricRef.originalDimensions?.height);
      fabricRef.loadFromJSON(canvasState, () => {
        if (shouldCalculateDimensions) {
          // This should only happen once when user came back to an existing project.
          applyCanvasDimensionChange({
            width: parsedState.originalDimensions!.width,
            height: parsedState.originalDimensions!.height,
            canvas: fabricRef,
          });
        }
        fabricRef.requestRenderAll();
      });
    }
  }, [canvasInstance, canvasState]);
};
