import { TPaymentProduct, useGetPaymentPlans } from 'components/dashboard/payment/queries';
import PlanIcon from '@/assets/icons/planElement.svg?react';
import React from 'react';
import { Separator } from 'components/ui/separator';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '@/utils/route/constants';
import { AccountPaths } from '@/utils/route/constants';
import { CraitButton } from 'components/common/CraitButton';
import { ScrollArea } from 'components/ui/scroll-area';

interface PlanCardProps {
  closeModal: () => void;
  requiredPlan: TPaymentProduct['type'];
  featureDescription: string;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  closeModal,
  requiredPlan,
  featureDescription,
}) => {
  const { data: paymentPlansData, isLoading } = useGetPaymentPlans();
  const navigate = useNavigate();

  const selectedPlan = paymentPlansData?.find((plan) => plan.type === requiredPlan);

  if (isLoading) {
    return (
      <div className='flex justify-center'>
        <LoadingSpinner />
      </div>
    );
  }

  const handleUpgradeClick = () => {
    navigate(`/${RoutePaths.ACCOUNT}/${AccountPaths.PAYMENT}`);
    closeModal();
  };

  return (
    <>
      <div className='mt-5 flex flex-col gap-5 p-5'>
        <p className='text-[13px] font-normal'>{featureDescription}</p>

        <h3 className='text-[13px] font-bold'>
          Here’s what you can do with <span className='capitalize'>{selectedPlan?.name} </span>Plan:
        </h3>
        <ScrollArea className='max-h-[60vh]'>
          <div className='flex flex-col gap-3'>
            {selectedPlan ? (
              selectedPlan.benefits.existingBenefits.map((feature, index) => (
                <div key={index} className='flex items-center gap-2'>
                  <PlanIcon />
                  <p className='text-[13px] font-medium'>{feature}</p>
                </div>
              ))
            ) : (
              <p>Plan type {requiredPlan} not found.</p>
            )}
          </div>
        </ScrollArea>
      </div>
      <Separator className='m-0' />
      <div className='p-4 pt-0'>
        <CraitButton onClick={handleUpgradeClick}>Upgrade</CraitButton>
      </div>
    </>
  );
};
