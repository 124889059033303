import { StoreResetterProvider } from '@/providers/store-resetter/StoreResetterProvider';
import { FC, ReactNode } from 'react';
import { useParams } from 'react-router';

interface ResultProviderResetWrapperProps {
  children: ReactNode;
}

export const ResultProviderResetWrapper: FC<ResultProviderResetWrapperProps> = ({ children }) => {
  const { sceneId } = useParams();

  return (
    <StoreResetterProvider resetKeyToForceUnmount={sceneId || ''}>{children}</StoreResetterProvider>
  );
};
