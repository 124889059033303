import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { HowItWorksBox } from '../visual-guidance/HowItWorksBox';
import { BrandStyleSection } from './brand-style/BrandStyleSection';
import { LightStyleSection } from './light-style/LightStyleSection';

export const ImageStyleMenu = ({ onBackClick }: { onBackClick: () => void }) => (
  <div className='flex  h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md md:w-[98vw] md:shadow-none'>
    <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold md:hidden'>
      <ArrowLeftIcon /> Image Style
    </button>
    <Separator className='md:hidden' />
    <div className='flex flex-col gap-5'>
      <HowItWorksBox
        text='Select styles to give the direction of your scene.'
        linkText="Don't show this again"
        parentComponent='ImageStyle'
      />
      <BrandStyleSection />
      <LightStyleSection />
    </div>
  </div>
);
