import { PlanProtectedRoute } from 'components/plan-guard/PlanProtectedRoute';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { BrandStyleView } from 'components/workspace/brand-library/style/BrandStyleView';

export const BrandLibraryStylesPage = () => {
  return (
    <PlanProtectedRoute requiredPlan={FeatureAccessRequirements.INSPIRATION_PAGE.requiredPlan}>
      <BrandStyleView />
    </PlanProtectedRoute>
  );
};
