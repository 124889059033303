import { DimensionsContent } from './dimensions/DimensionsContent';
import { AssetsContent } from './assets/AssetsContent';
import { StyleContent } from './style/StyleContent';
import { CopyTextContent } from './copy-text/CopyTextContent';
import { TemplateContent } from './template/TemplateContent';
import { ObjectiveContent } from './objective/ObjectiveContent';
import { BrandKitContent } from './brand-kit/BrandKitContent';

export type TAccordionSection = (typeof ACCORDION_ITEMS)[number]['value'];

export const ACCORDION_ITEMS = [
  {
    title: 'Dimensions *',
    value: 'dimensions',
    content: <DimensionsContent />,
  },
  {
    title: 'Assets *',
    value: 'assets',
    content: <AssetsContent />,
  },
  {
    title: 'Brand Kit',
    value: 'brandkit',
    content: <BrandKitContent />,
  },
  {
    title: 'Style',
    value: 'style',
    content: <StyleContent />,
  },
  {
    title: 'Copy Text',
    value: 'copy-text',
    content: <CopyTextContent />,
  },
  {
    title: 'Template',
    value: 'template',
    content: <TemplateContent />,
  },
  {
    title: 'Objective',
    value: 'objective',
    content: <ObjectiveContent />,
  },
] as const;
