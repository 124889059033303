export const DrawingCursorSize = {
  MIN: 10,
  DEFAULT: 50,
  MAX: 100,
} as const;

// Note that it only supports up to 130px size, and it's around 250 bytes of base64.
// Alternatively, a custom circle object can be used to replace the cursor, with necessary listeners.
// Bkz: https://jsfiddle.net/Fidel90/7kmf3jz2/
export const generateDrawingCursorUrl = (size: number, circleStroke: string): string => {
  const strokeWidth = size / 25;
  const svgCursor = `
    <svg height="${size}"
    fill="${size}"
    viewBox="0 0 ${size * 2} ${size * 2}"
    width="${size}"
    xmlns="http://www.w3.org/2000/svg">
    <circle
				cx="50%"
				cy="50%"
        r="${size - strokeWidth / 2}" 
        stroke="${circleStroke}" stroke-width="${strokeWidth}"
        fill="white"
			/>
    </svg>`;
  const cursorUrl = `data:image/svg+xml;base64,${btoa(svgCursor)}`;
  return `url(${cursorUrl}) ${size / 2} ${size / 2}, auto`;
};

export const applyCustomCursor = (canvas: fabric.Canvas, cursorUrl: string): void => {
  canvas.freeDrawingCursor = cursorUrl;
  canvas.setCursor(cursorUrl);
};
