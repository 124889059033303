import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';
import { useState } from 'react';
import { SelectAuthority } from './table/editable/SelectAuthority';
import { AUTHORITY_OPTIONS } from './table/table.data';
import { TAddUserForm, addUserFormSchema, useAddMember } from './helper';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';
import { GET_PEOPLE_QUERY_KEY } from 'pages/dashboard/people/helper';

const INPUT_CL = 'h-[48px]';

const FMessage = ({ children }: { children: React.ReactNode }) => (
  <FormMessage className='text-red-500'>{children}</FormMessage>
);

const FLabel = ({ children }: { children: React.ReactNode }) => (
  <FormLabel className='text-sm text-crait-primary'>{children}</FormLabel>
);

const FItem = ({ children }: { children: React.ReactNode }) => (
  <FormItem className={'mb-2'}>{children}</FormItem>
);

export const AddPeopleDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const addMember = useAddMember();
  const queryClient = useQueryClient();

  const form = useForm<TAddUserForm>({
    resolver: zodResolver(addUserFormSchema),
    defaultValues: {
      email: '',
      title: '',
      authority: 'normal',
    },
  });

  function onSubmit(values: TAddUserForm) {
    addMember.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [{ _name: GET_PEOPLE_QUERY_KEY }],
          exact: false,
        });
        setIsOpen(false);
        toast.success('Member added successfully');
      },
      onError: (error) => {
        console.error(error);
        toast.error('Failed to add member. Please try again.');
      },
    });
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className='btn btn-primary'>
        <Button size='lg'>Add New Member</Button>
      </DialogTrigger>
      <DialogContent className='w-full rounded-lg bg-white p-6'>
        <DialogHeader>
          <DialogTitle className='text-lg font-medium'>Add New Member</DialogTitle>
        </DialogHeader>
        <Separator className='my-1 border bg-slate-50' />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='mx-auto w-80'>
            <FormField
              control={form.control}
              name='email'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Email Address</FLabel>
                  <FormControl>
                    <Input
                      type='email'
                      placeholder='Email address'
                      className={INPUT_CL}
                      {...field}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <FormField
              control={form.control}
              name='title'
              render={({ field, fieldState: { error } }) => (
                <FItem>
                  <FLabel>Title</FLabel>
                  <FormControl>
                    <Input type='text' placeholder='Title' className={INPUT_CL} {...field} />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <FormField
              control={form.control}
              name='authority'
              render={({ fieldState: { error } }) => (
                <FItem>
                  <FLabel>Authority</FLabel>
                  <FormControl>
                    <SelectAuthority
                      triggerProps={{
                        className: INPUT_CL,
                      }}
                      options={AUTHORITY_OPTIONS}
                      onValueChange={(value) => {
                        form.setValue('authority', value as TAddUserForm['authority']);
                      }}
                    />
                  </FormControl>
                  {error && <FMessage>{error.message}</FMessage>}
                </FItem>
              )}
            />
            <Button type='submit' size='lg' className='mt-2 w-full'>
              Create new member
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
