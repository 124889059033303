import { UndoRedoIcons } from './UndoRedoIcons';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';

export const UndoRedoSceneCanvas = () => {
  const redoCanvasState = useAiSceneCreateStore((state) => state.redoSceneCanvasState);
  const undoCanvasState = useAiSceneCreateStore((state) => state.undoSceneCanvasState);
  const previousSceneCanvasState = useAiSceneCreateStore(
    (state) => state.previousSceneCanvasStates,
  );
  const nextSceneCanvasState = useAiSceneCreateStore((state) => state.nextSceneCanvasStates);
  const shouldDisableUndo = previousSceneCanvasState.length < 1;
  const shouldDisableRedo = nextSceneCanvasState.length < 1;

  return (
    <UndoRedoIcons
      className='h-10 border'
      shouldDisableUndo={shouldDisableUndo}
      shouldDisableRedo={shouldDisableRedo}
      redoCanvasState={redoCanvasState}
      undoCanvasState={undoCanvasState}
    />
  );
};
