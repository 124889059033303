import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { TAiSceneResultStore } from './store';
import { AiSceneResultContext } from './helper';

export const useAiSceneResultStore = <T,>(
  selector: (state: TAiSceneResultStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(AiSceneResultContext);
  if (!store) throw new Error('Missing AiSceneResultContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
