import { differenceInDays, fromUnixTime } from 'date-fns';
import { TPaymentProduct } from './queries';

export const TERMS_OF_USE_LINKS = {
  'starter-monthly': 'https://www.crait.it/terms-of-use/starter-package-monthly',
  'starter-yearly': 'https://www.crait.it/terms-of-use/starter-package-annual',
  'professional-monthly': 'https://www.crait.it/terms-of-use/professional-package-monthly',
  'professional-yearly': 'https://www.crait.it/terms-of-use/professional-package-annual',
  'team-monthly': 'https://www.crait.it/terms-of-use/team-package-monthly',
  'team-yearly': 'https://www.crait.it/terms-of-use/team-package-annual',
  'try-free': 'https://www.crait.it/terms-of-use/try-free-package',
} as const;

interface TermsOfUseParams {
  plan: string;
  period: string;
}

export const getTermsOfUseLink = ({ plan, period }: TermsOfUseParams): string => {
  const key = `${plan}-${period}`;
  return TERMS_OF_USE_LINKS[key as keyof typeof TERMS_OF_USE_LINKS] || '#';
};

export const PaymentTabOption = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
} as const;

export function getPlanPeriod(
  timestamp: number,
): (typeof PaymentTabOption)[keyof typeof PaymentTabOption] {
  const expiryDate = fromUnixTime(timestamp);
  const today = new Date();
  const daysDifference = differenceInDays(expiryDate, today);

  return daysDifference > 32 ? PaymentTabOption.YEARLY : PaymentTabOption.MONTHLY;
}

interface PlanDetails {
  planType: string;
  description: string;
  period: string;
  priceId: string | null;
  price: string;
  priceInCents: number | null;
}
interface GetPlanDetailsParams {
  userPlan: string;
  planPeriod: string;
  paymentData: TPaymentProduct[];
}

export const getPlanDetails = ({
  userPlan,
  planPeriod,
  paymentData,
}: GetPlanDetailsParams): PlanDetails | null => {
  const currentPlanData = paymentData.find((plan) => plan.type === userPlan);

  if (!currentPlanData) {
    return null;
  }

  const priceDetails = currentPlanData.prices
    .filter((price) => price.paymentPeriod === planPeriod)
    .map((price) => ({
      period: price.paymentPeriod,
      priceId: price.priceId || null,
      price: price.price,
      priceInCents: price.priceInCents !== undefined ? price.priceInCents : null,
    }))[0];

  if (!priceDetails) {
    console.error('Price details for the specified period not found');
    return null;
  }

  return {
    planType: currentPlanData.type,
    description: currentPlanData.description,
    ...priceDetails,
  };
};

export const PLAN_RANKS = {
  free: 1,
  starter: 2,
  professional: 3,
  team: 4,
  enterprise: 5,
} as const;

export type PlanType = keyof typeof PLAN_RANKS;

const BASE_CLASS = 'relative w-full rounded-[10px] font-semibold text-[14px]';

const getButtonClass = (BASE_CLASS: string, additionalClasses: string) =>
  `${BASE_CLASS} ${additionalClasses}`;

export const planButtonStateMap = {
  freeFreePlan: {
    text: 'Current Plan',
    disabled: true,
    className: getButtonClass(BASE_CLASS, 'bg-ground border border-c-dark text-c-dark'),
  },
  freeHigherPlan: {
    text: 'Upgrade Now',
    disabled: false,
    className: getButtonClass(BASE_CLASS, 'bg-crait-green-primary text-c-dark'),
  },
  lowerPlan: {
    text: (userPlan: string) =>
      `Included in ${userPlan.charAt(0).toUpperCase() + userPlan.slice(1)}`,
    disabled: true,
    className: getButtonClass(BASE_CLASS, 'bg-[#F5F5F5] text-[#777777]'),
  },
  samePlan: {
    'yearly-yearly': {
      text: 'Current Plan',
      disabled: true,
      className: getButtonClass(BASE_CLASS, 'bg-ground border border-c-dark text-c-dark'),
    },
    'monthly-monthly': {
      text: 'Current Plan',
      disabled: true,
      className: getButtonClass(BASE_CLASS, 'bg-ground border border-c-dark text-c-dark'),
    },
    'yearly-monthly': {
      text: 'Upgrade Now',
      disabled: false,
      className: getButtonClass(BASE_CLASS, 'bg-crait-green-primary text-c-dark'),
    },
    'monthly-yearly': {
      text: (userPlan: string) =>
        `Included in ${userPlan.charAt(0).toUpperCase() + userPlan.slice(1)}`,
      disabled: true,
      className: getButtonClass(
        BASE_CLASS,
        'bg-[#F5F5F5] text-[#777777] border-c-dark text-c-dark',
      ),
    },
  },
  enterprisePlan: {
    text: 'Contact Us',
    disabled: false,
    className: getButtonClass(BASE_CLASS, 'bg-crait-green-primary text-c-dark'),
  },
  default: {
    text: 'Upgrade Now',
    disabled: false,
    className: getButtonClass(BASE_CLASS, 'bg-crait-green-primary text-c-dark'),
  },
};

export const getButtonProps = (
  plan: TPaymentProduct,
  userPlan: PlanType,
  userPlanExpiry: number,
  isProcessing: boolean,
  onPlanSelect: (planType: string, priceId: string | undefined) => void,
  paymentPeriod: 'monthly' | 'yearly',
  price: number,
) => {
  const priceId = plan.prices.find((price) => price.paymentPeriod === paymentPeriod)?.priceId;
  const userPlanPeriod = getPlanPeriod(userPlanExpiry);
  const isFreePlan = userPlan === 'free';

  let buttonProps;

  switch (true) {
    case isFreePlan && plan.type === 'free':
      buttonProps = planButtonStateMap.freeFreePlan;
      break;
    case isFreePlan && PLAN_RANKS[plan.type as PlanType] > PLAN_RANKS[userPlan]:
      buttonProps = planButtonStateMap.freeHigherPlan;
      break;
    case PLAN_RANKS[plan.type as PlanType] < PLAN_RANKS[userPlan]:
      buttonProps = planButtonStateMap.lowerPlan;
      break;
    case PLAN_RANKS[plan.type as PlanType] === PLAN_RANKS[userPlan]:
      buttonProps = planButtonStateMap.samePlan[`${paymentPeriod}-${userPlanPeriod}`];
      break;
    case plan.type === 'enterprise':
      buttonProps = planButtonStateMap.enterprisePlan;
      break;
    default:
      buttonProps = planButtonStateMap.default;
      break;
  }

  if (
    userPlanPeriod === 'yearly' &&
    paymentPeriod === 'monthly' &&
    PLAN_RANKS[plan.type as PlanType] > PLAN_RANKS[userPlan]
  ) {
    buttonProps = {
      ...buttonProps,
      disabled: true,
    };
  }

  return {
    ...buttonProps,
    text: typeof buttonProps.text === 'function' ? buttonProps.text(userPlan) : buttonProps.text,
    onClick: () => onPlanSelect(plan.type, priceId),
    disabled: buttonProps.disabled || isProcessing,
  };
};

export const formatCentsToDollars = (cents: number): string => {
  return (cents / 100).toFixed(2);
};
