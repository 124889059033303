import { ModalView } from 'components/common/ModalView';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { useRef, useState } from 'react';
import { FileUploadInput } from '../products/upload/FileUploadInput';
import { Input } from 'components/ui/input';

export const AddBannerModal = () => {
  const [file, setFile] = useState<File | null>(null);
  const nameInput = useRef<HTMLInputElement>(null);

  return (
    <ModalView
      trigger={
        <Button className='h-[40px] items-center gap-2 rounded-lg'>
          <span className='i-mdi-progress-upload h-[18px] w-[18px]'></span>
          <span className='text-sm'>Upload new banner</span>
        </Button>
      }
      titleElement={<h4 className='text-lg font-medium'>Upload New Banner</h4>}
    >
      {({ closeModal }) => (
        <>
          <Separator className='mb-4' />
          <div className='flex flex-col items-center gap-6'>
            <FileUploadInput onFileUpload={setFile} />
            <div className='flex w-72 flex-col gap-3'>
              <Input ref={nameInput} placeholder='Enter banner name' />
              <Button className='w-full' disabled={!file}>
                Upload
              </Button>
              <Button variant='outline' className='w-full' onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
    </ModalView>
  );
};
