import { GTM } from '@/utils/gtm';
import React, { useState } from 'react';
import { Button } from 'components/ui/button';
import { RoutePaths } from '@/utils/route/constants';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'sonner';
import { getTermsOfUseLink } from '../helper';
import { Link, useNavigate } from 'react-router-dom';

interface CheckoutFormProps {
  plan: string;
  period: string;
}

interface TermsOfUseParams {
  plan: string;
  period: string;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ plan, period }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!stripe || !elements) return;

      const cardElement = elements.getElement(PaymentElement);
      if (!cardElement) return;

      setIsProcessing(true);
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/${RoutePaths.ACCOUNT}`,
        },
      });
      const error = result.error;
      if (error) {
        toast.error(error.message);
        return;
      }
      const paymentIntent = result.paymentIntent;
      if (paymentIntent) {
        GTM.sendPurchaseEvent(paymentIntent.id, paymentIntent.amount / 100);
      }
      navigate(`/${RoutePaths.ACCOUNT}`);
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form
      id='payment-form'
      onSubmit={onSubmit}
      className='flex w-full max-w-[600px] flex-col justify-center rounded-md border border-slate-200 bg-white p-6 shadow-sm'
    >
      <PaymentElement />
      <div className='mt-4'>
        <label className='flex items-center gap-1 text-[12px]'>
          <input
            type='checkbox'
            className='mr-2'
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          I have read and agree to the{' '}
          <Link
            to={getTermsOfUseLink({ plan, period })}
            target='_blank'
            className='text-[12px] underline'
          >
            Terms of Use
          </Link>
          .
        </label>
      </div>
      <div className='flex justify-center'>
        <Button
          type='submit'
          size='lg'
          className='mt-4 w-full rounded-lg bg-crait-green-primary px-4 py-2 font-bold text-c-dark'
          disabled={isProcessing || !isChecked}
        >
          Pay
        </Button>
      </div>
    </form>
  );
};
