import { BgToggleView } from 'components/workspace/brand-library/products-edit/canvas/BgToggleView';
import { BrushView } from 'components/workspace/brand-library/products-edit/canvas/BrushView';
import { UndoRedoProductCreateCanvas } from 'components/workspace/brand-library/products-edit/canvas/UndoRedoProductCreateCanvas';
import { FC, ReactNode } from 'react';

interface EditStepProps {
  canvasView: ReactNode;
  children: ReactNode;
}

export const EditStep: FC<EditStepProps> = ({ children, canvasView }) => {
  return (
    <section className='container relative h-full'>
      <div className='flex h-full flex-col items-center gap-6 overflow-y-auto px-4 pb-4'>
        <div className='flex items-center justify-between gap-4'>
          <UndoRedoProductCreateCanvas />
          <BgToggleView />
          <div className='flex gap-2'>
            <BrushView />
          </div>
        </div>
        <div className='relative flex max-h-[650px] w-full items-center justify-center'>
          {canvasView}
        </div>
        {children}
      </div>
    </section>
  );
};
