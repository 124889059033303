import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useShallow } from 'zustand/react/shallow';
import invariant from 'tiny-invariant';
import { toast } from 'sonner';
import { useProjectDataCtx } from 'pages/ai-scene/create/helpers';
import {
  cloneAndScaleCanvasWithObjects,
  getFabricObjectsAsFile,
  isFabricText,
  loadCanvasFromJson,
} from '@/utils/helper';
import { ImageType } from '@/utils/store/constants';
import { useCreateAiSceneMutation } from '../helper';
import { MAX_ORIGINAL_CANVAS_SIZE } from 'components/ai-scene/create/editor/canvas/helper';
import { useAiSceneBulkCreateStore } from '@/providers/ai-scene/bulk-create/hooks';
import { fabric } from 'fabric';
import { getElementObjectOptions } from './helper';
import {
  useRefetchGeneratedScenes,
  useRefetchProject,
} from 'components/ai-scene/result-sidebar/queries';
import { CraitButton } from 'components/common/CraitButton';
import { useGenerationSceneStatusStore } from './helper/useGenerationSceneStatusStore';
import { useFaceCompositionLogic } from './advanced-settings/useFaceCompositionLogic ';

export const GenerationButton = () => {
  const { refetchScenes } = useRefetchGeneratedScenes();
  const { refetchProject } = useRefetchProject();
  const isGenerating = useGenerationSceneStatusStore((state) => state.isGenerating);
  const setIsGenerating = useGenerationSceneStatusStore((state) => state.setIsGenerating);
  const { aiScenePromptTab, aiSceneSettingsTab, canvasState, canvasInstance } =
    useAiSceneCreateStore(
      useShallow((s) => ({
        aiScenePromptTab: s.aiScenePromptTab,
        aiSceneSettingsTab: s.aiSceneSettingsTab,
        canvasState: s.canvasState,
        canvasInstance: s.aiSceneCanvasInstance,
      })),
    );
  const bulkMainCanvasState = useAiSceneBulkCreateStore((state) => state.mainCanvasState);
  const bulkProductImages = useAiSceneBulkCreateStore((state) => state.productImages);
  const createAiSceneMutation = useCreateAiSceneMutation();
  const project = useProjectDataCtx();
  const hasTemplateImage = canvasInstance?.getObjects().some((obj) => obj.imageType === 'template');
  const hasAnyStyles = aiSceneSettingsTab.styleIds && aiSceneSettingsTab.styleIds.length > 0;
  const hasInspirationPhoto = Boolean(aiScenePromptTab.inspirationalPhoto);
  const hasAnyBrandStyles = Boolean(aiScenePromptTab.brandStyleId);
  const hasAnyLightStyles = Boolean(aiScenePromptTab.lightingStyleId);
  const shouldEnableCrait = Boolean(
    hasTemplateImage ||
      aiScenePromptTab.prompt ||
      hasAnyStyles ||
      hasInspirationPhoto ||
      bulkMainCanvasState ||
      hasAnyBrandStyles ||
      hasAnyLightStyles ||
      bulkProductImages.length > 0,
  );

  useFaceCompositionLogic();

  const onCreate = async () => {
    try {
      invariant(project, 'Project info is required to create an AI scene');
      setIsGenerating(true);
      // Bulk scene create
      if (bulkMainCanvasState) {
        const staticCanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
          width: MAX_ORIGINAL_CANVAS_SIZE,
          height: MAX_ORIGINAL_CANVAS_SIZE,
        });
        await loadCanvasFromJson(staticCanvas, bulkMainCanvasState);
        const scaledCanvas = await cloneAndScaleCanvasWithObjects(staticCanvas);
        const allObjects = scaledCanvas.getObjects();
        const drawnImageObjects = allObjects.filter((obj) => obj.imageType && obj.imageId);
        const usedProduct = drawnImageObjects.find((obj) => obj.imageType === 'product');
        const usedProductIds = bulkProductImages.map((img) => img.id);
        const useBrandStyleId = aiScenePromptTab.brandStyleId
          ? aiScenePromptTab.brandStyleId.id
          : null;
        const useLightingStyleId = aiScenePromptTab.lightingStyleId
          ? aiScenePromptTab.lightingStyleId.id
          : null;
        const productPlacementImage = usedProduct
          ? await getFabricObjectsAsFile({
              canvasInstance: scaledCanvas,
              objects: [usedProduct],
              fileName: 'productPlacementImage',
            })
          : null;
        const usedTemplateId = drawnImageObjects.find((obj) => obj.imageType === 'template')
          ?.imageId;
        const usedStyleIds = aiSceneSettingsTab.styleIds?.map((style) => style.id);
        const usedElements = getElementObjectOptions(drawnImageObjects);
        const textAndLogoImages = allObjects?.filter(
          (obj) => isFabricText(obj) || obj.imageType === 'logo',
        );
        const staticEditsImage =
          textAndLogoImages && textAndLogoImages.length > 0
            ? await getFabricObjectsAsFile({
                canvasInstance: scaledCanvas,
                objects: textAndLogoImages,
                fileName: 'staticEditsImage',
              })
            : null;

        await createAiSceneMutation.mutateAsync({
          projectId: project.id,
          payload: {
            productPlacementImage,
            staticEditsImage,
            prompt: aiScenePromptTab.prompt || undefined,
            templateId: usedTemplateId,
            styleIds: usedStyleIds,
            brandStyleId: useBrandStyleId,
            lightingStyleId: useLightingStyleId,
            productIds: usedProductIds,
            elements: usedElements,
            serializedCanvasData: bulkMainCanvasState,
            inspirationalImages: aiScenePromptTab.inspirationalPhoto
              ? [aiScenePromptTab.inspirationalPhoto]
              : null,
            negativePrompt: aiScenePromptTab.negativePrompt,
            sceneDimensions: {
              x: scaledCanvas.getWidth(),
              y: scaledCanvas.getHeight(),
            },
            faceTextComposition: aiScenePromptTab.faceTextComposition,
            enhancePrompt: aiScenePromptTab.enhancePrompt,
          },
        });
        return;
      }

      // Single scene create without any canvas state
      if (!canvasInstance) {
        await createAiSceneMutation.mutateAsync({
          projectId: project.id,
          payload: {
            prompt: aiScenePromptTab.prompt || undefined,
            serializedCanvasData: null,
            inspirationalImages: aiScenePromptTab.inspirationalPhoto
              ? [aiScenePromptTab.inspirationalPhoto]
              : null,
            negativePrompt: aiScenePromptTab.negativePrompt,
            sceneDimensions: {
              x: MAX_ORIGINAL_CANVAS_SIZE,
              y: MAX_ORIGINAL_CANVAS_SIZE,
            },
            faceTextComposition: aiScenePromptTab.faceTextComposition,
            enhancePrompt: aiScenePromptTab.enhancePrompt,
          },
        });
        return;
      }

      const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvasInstance);
      const allObjects = scaledCanvas.getObjects();
      const drawnImageObjects = allObjects.filter((obj) => obj.imageType && obj.imageId);
      const usedProducts = drawnImageObjects.filter((obj) => obj.imageType === 'product');
      const usedProductIds = usedProducts.map((obj) => obj.imageId as string);
      const uploadedProductsByUser = drawnImageObjects.filter(
        (obj) => obj.imageType === ImageType.USER_PRODUCT,
      );
      const productImageObjects = [...usedProducts, ...uploadedProductsByUser];
      const productPlacementImage =
        productImageObjects.length > 0
          ? await getFabricObjectsAsFile({
              canvasInstance: scaledCanvas,
              objects: productImageObjects,
              fileName: 'productPlacementImage',
            })
          : null;
      const usedTemplateId = drawnImageObjects?.find((obj) => obj.imageType === 'template')
        ?.imageId;
      const usedStyleIds = aiSceneSettingsTab.styleIds?.map((style) => style.id);
      const useBrandStyleId = aiScenePromptTab.brandStyleId
        ? aiScenePromptTab.brandStyleId.id
        : null;
      const useLightingStyleId = aiScenePromptTab.lightingStyleId
        ? aiScenePromptTab.lightingStyleId.id
        : null;
      const usedElements = getElementObjectOptions(drawnImageObjects);
      const textAndLogoImages = allObjects?.filter(
        (obj) => isFabricText(obj) || obj.imageType === 'logo',
      );
      const staticEditsImage =
        textAndLogoImages && textAndLogoImages.length > 0
          ? await getFabricObjectsAsFile({
              canvasInstance: scaledCanvas,
              objects: textAndLogoImages,
              fileName: 'staticEditsImage',
            })
          : null;

      await createAiSceneMutation.mutateAsync({
        projectId: project.id,
        payload: {
          productPlacementImage,
          staticEditsImage,
          prompt: aiScenePromptTab.prompt || undefined,
          templateId: usedTemplateId,
          styleIds: usedStyleIds,
          brandStyleId: useBrandStyleId,
          lightingStyleId: useLightingStyleId,
          productIds: usedProductIds,
          elements: usedElements,
          serializedCanvasData: canvasState || null,
          inspirationalImages: aiScenePromptTab.inspirationalPhoto
            ? [aiScenePromptTab.inspirationalPhoto]
            : null,
          negativePrompt: aiScenePromptTab.negativePrompt,
          sceneDimensions: {
            x: scaledCanvas.getWidth(),
            y: scaledCanvas.getHeight(),
          },
          faceTextComposition: aiScenePromptTab.faceTextComposition,
          enhancePrompt: aiScenePromptTab.enhancePrompt,
          count: aiScenePromptTab.count,
        },
      });
    } catch (error: any) {
      const errorMessage =
        error.response.status == 402 ? 'Not enough credits' : 'Failed to create AI scene';
      console.error(errorMessage, error);
      toast.error(errorMessage);
    } finally {
      refetchScenes();
      await new Promise((resolve) => setTimeout(resolve, 1500));
      await refetchProject();
    }
  };

  return (
    <CraitButton
      onClick={onCreate}
      disabled={!shouldEnableCrait || isGenerating}
      className='w-full bg-c-primary text-black'
    >
      Crait
    </CraitButton>
  );
};
