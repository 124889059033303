import { FC, ReactNode, useRef } from 'react';
import { createBulkProductsStore } from './store';
import { BulkProductsContext, TBulkProductsContext } from './helper';

interface BulkProductsProviderProps {
  children: ReactNode;
}

export const BulkProductsProvider: FC<BulkProductsProviderProps> = ({ children }) => {
  const storeRef = useRef<TBulkProductsContext>();

  if (!storeRef.current) {
    storeRef.current = createBulkProductsStore();
  }

  return (
    <BulkProductsContext.Provider value={storeRef.current}>{children}</BulkProductsContext.Provider>
  );
};
