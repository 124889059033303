import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { ProductsTab } from './ProductsTab';
import { QuickUploadProduct } from './QuickUploadProduct';

export const ProductPhotoMenu = ({ onBackClick }: { onBackClick?: () => void }) => (
  <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md md:w-[94vw] md:shadow-none'>
    <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold md:hidden'>
      <ArrowLeftIcon /> Product Photo
    </button>
    <Separator className='md:hidden' />

    <QuickUploadProduct />

    <Separator className='bg-black' />
    <div className='h-full overflow-y-hidden'>
      <ProductsTab />
    </div>
  </div>
);
