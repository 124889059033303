import { SCROLL_CONTENT_CL } from 'components/common/layout/constants';
import { SavedSettingsTable } from './table/SavedSettingsTable';

export const SavedSettingsView = () => {
  return (
    <section
      className={`${SCROLL_CONTENT_CL} relative w-[calc(100%-110px)] max-w-[1440px] pr-2 md:w-[100vw]`}
    >
      <aside className='mb-3 flex items-center justify-between'>
        <h4 className='mb-1 text-[26px] font-medium'>Saved Settings</h4>
      </aside>
      <SavedSettingsTable />
    </section>
  );
};
