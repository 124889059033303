import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import React from 'react';
import { Template } from './Template';
import { useBrandSectionQueryParams } from '../assets/useBrandSectionQueryParams';

export const TemplateView = () => {
  const contentWrapperRef = React.useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = React.useState<'assetLibrary' | 'craitLibrary'>(
    'assetLibrary',
  );
  const { closeSection } = useBrandSectionQueryParams();

  return (
    <div className='relative size-full px-10 py-6'>
      <div className='absolute inset-0' onClick={closeSection} />
      <div
        ref={contentWrapperRef}
        className='relative flex h-full justify-center overflow-y-auto rounded-lg bg-white p-6'
      >
        <span
          className='i-mdi-close absolute right-6 top-6 size-6 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            closeSection();
          }}
        />
        <div className='w-full max-w-[700px]'>
          <h3 className='text-center text-xl'>Template Library</h3>
          <Separator className='mb-8 mt-4' />
          <div className='flex justify-center gap-2'>
            <Button
              onClick={() => {
                setActiveSection('assetLibrary');
              }}
              className='h-[40px] max-w-[200px] flex-1 rounded-lg'
              variant={activeSection === 'assetLibrary' ? 'default' : 'outline'}
            >
              Asset Library
            </Button>
            <Button
              onClick={() => {
                setActiveSection('craitLibrary');
              }}
              className='h-[40px] max-w-[200px] flex-1 rounded-lg'
              variant={activeSection === 'craitLibrary' ? 'default' : 'outline'}
            >
              Crait{"'"}s Templates
            </Button>
          </div>
          <Template onlyOrgLibrary={activeSection === 'assetLibrary'} />
          <Button className='w-full'>Continue</Button>
        </div>
      </div>
    </div>
  );
};
