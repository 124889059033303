import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { ColorPicker } from './ColorPicker';
import { isFabricText, serializeCanvas } from '@/utils/helper';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Input } from 'components/ui/input';

const ICON_WRAPPER_CL = 'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-white';

const BASE_COLOR = '#000000';

interface FillColorAreaProps {
  canvasInstance: fabric.Canvas | null;
  updateCanvasState: (canvasState: string) => void;
}

export const FillColorArea: FC<FillColorAreaProps> = ({ canvasInstance, updateCanvasState }) => {
  const visibleColorRef = useRef<HTMLSpanElement | null>(null);
  const visibleColorCircleRef = useRef<HTMLSpanElement | null>(null);
  const colorPercentRef = useRef<HTMLSpanElement | null>(null);

  const [customColor, setCustomColor] = useState({
    hex: BASE_COLOR,
    alpha: 100,
  });

  useEffect(() => {
    if (!canvasInstance) return;

    const updateColor = (event: fabric.IEvent) => {
      const activeObject = event.selected?.[0];
      if (!activeObject || !isFabricText(activeObject)) return;
      if (!visibleColorRef.current || !visibleColorCircleRef.current) return;

      const color = activeObject.get('fill') as string;
      const fromHexaToHex = color.slice(0, 7);
      visibleColorRef.current.style.backgroundColor = fromHexaToHex;
      visibleColorCircleRef.current.innerText = fromHexaToHex;
    };

    canvasInstance.on('selection:created', updateColor);
    canvasInstance.on('selection:updated', updateColor);

    return () => {
      canvasInstance.off('selection:created', updateColor);
      canvasInstance.off('selection:updated', updateColor);
    };
  }, [canvasInstance]);

  return (
    <div className='relative'>
      <div className='flex flex-col gap-2'>
        <ColorPicker
          canvasInstance={canvasInstance}
          onColorChange={(hex, alpha) => {
            if (!visibleColorRef.current || !visibleColorCircleRef.current) return;

            visibleColorRef.current.style.backgroundColor = hex;
            visibleColorCircleRef.current.innerText = hex;
            colorPercentRef.current!.innerText = `${Math.round(alpha * 100)}`;
            setCustomColor({ hex: hex, alpha: Math.round(alpha * 100) });
          }}
          onColorBlur={() => {
            if (!canvasInstance) return;
            updateCanvasState(serializeCanvas(canvasInstance));
          }}
        />
        <div className='flex  gap-1'>
          <Button
            variant='ghost'
            className='flex w-24 items-center justify-start gap-2 rounded-lg bg-background px-2.5 text-black'
          >
            <span
              ref={visibleColorRef}
              className='size-[18px] rounded-full'
              style={{
                backgroundColor: BASE_COLOR,
              }}
            />
            <span className='text-xs' ref={visibleColorCircleRef}>
              {BASE_COLOR}
            </span>
          </Button>
          <Button
            asChild
            variant='outline'
            className='w-[54px] cursor-pointer rounded-[10px] border-[#eaeaea] bg-white px-2.5'
          >
            <div className='flex items-center gap-1'>
              <span ref={colorPercentRef} className='text-xs'>
                100
              </span>
              <span>%</span>
            </div>
          </Button>
          {/* <Input
            className='w-[54px] cursor-pointer rounded-[10px] border-[#eaeaea] bg-white px-2.5'
            value={colorPercentRef.current.}
          >
            <div className='flex items-center gap-1'>
              <span ref={colorPercentRef} className='text-xs'>
                100
              </span>
              <span>%</span>
            </div>
          </Input> */}
        </div>
        {/* <Popover>
          <PopoverTrigger asChild>
            <div className='flex gap-1'>
              <Button
                variant='ghost'
                className='flex w-24 items-center justify-start gap-2 rounded-lg bg-background px-2.5 text-black'
              >
                <span
                  ref={visibleColorRef}
                  className='size-[18px] rounded-full'
                  style={{
                    backgroundColor: BASE_COLOR,
                  }}
                />
                <span className='text-xs' ref={visibleColorCircleRef}>
                  {BASE_COLOR}
                </span>
              </Button>
              <Button
                asChild
                variant='ghost'
                className='w-[54px] cursor-pointer rounded-lg bg-background px-2.5'
              >
                <div className='flex items-center gap-1'>
                  <span ref={colorPercentRef} className='text-xs'>
                    100
                  </span>
                  <span>%</span>
                </div>
              </Button>
            </div>
          </PopoverTrigger>
          <PopoverContent className='relative w-auto p-0'>
            <ColorPicker
              canvasInstance={canvasInstance}
              onColorChange={(hex, alpha) => {
                if (!visibleColorRef.current || !visibleColorCircleRef.current) return;

                visibleColorRef.current.style.backgroundColor = hex;
                visibleColorCircleRef.current.innerText = hex;
                colorPercentRef.current!.innerText = `${Math.round(alpha * 100)}`;
              }}
              onColorBlur={() => {
                if (!canvasInstance) return;
                updateCanvasState(serializeCanvas(canvasInstance));
              }}
            />
          </PopoverContent>
        </Popover> */}
        {/*         <Button asChild variant='ghost' className='rounded-lg bg-white px-2 py-0'>
          <div className='relative flex'>
            <IconWrapper
              wrapperProps={{
                className: ICON_WRAPPER_CL,
              }}
              iconClass='i-mdi-eyedropper-variant size-4 hover:scale-125'
            />
          </div>
        </Button> */}
      </div>
    </div>
  );
};
