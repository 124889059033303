import { useEffect } from 'react';
import { useSyncCanvasState } from '@/hooks/canvas/useSyncCanvasState';
import { useFabricCanvas } from '@/hooks/canvas/useFabricCanvas';
import { serializeCanvas } from '@/utils/helper';
import { useRemoveSelectedObject } from '@/hooks/canvas/useRemoveSelectedObject';
import { useFabricEventHandlers } from '@/hooks/canvas/useFabricEventHandlers';
import {
  applyCanvasDimensionChange,
  parseImageWithFabric,
} from 'components/ai-scene/create/editor/canvas/helper';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import { ZoomView } from './ZoomView';
import { fabric } from 'fabric';
import { ImageType } from '@/utils/store/constants';
import { useProductDataCtx } from 'pages/workspace/brand-library/products/helper';
import { useCanvasImageLoadingStatus } from '@/hooks/useCanvasImageLoadingStatus';

export const ProductEditCanvas = () => {
  const setCanvasInstance = useProductCreateStore((state) => state.setCanvasInstance);
  const { fabricCanvasRef, canvasElement } = useFabricCanvas(setCanvasInstance);
  const canvasState = useProductCreateStore((state) => state.canvasState);
  const updateCanvasState = useProductCreateStore((state) => state.updateCanvasState);
  const resetSceneCanvasState = useProductCreateStore((state) => state.resetCanvasState);
  const product = useProductDataCtx();
  const [setCanvasLoadingStatus, imageLoadingView] = useCanvasImageLoadingStatus();

  useSyncCanvasState(fabricCanvasRef, canvasState);
  useFabricEventHandlers({ updateCanvasState, canvasInstance: fabricCanvasRef });
  useRemoveSelectedObject({
    fabricCanvasRef,
    resetSceneCanvasState,
    updateCanvasState,
  });

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || !product) return;

    const parseImage = async () => {
      try {
        setCanvasLoadingStatus(true);
        const [parsedOpaque, parsedTransparent] = await Promise.all([
          parseImageWithFabric({
            id: product.id + '-opaque',
            image: product.image.original,
            type: ImageType.OPAQUE,
            maxHeight: fabricRef.getHeight(),
            maxWidth: fabricRef.getWidth(),
            placementBuffer: 0,
            isSelectionIgnored: true,
          }),
          parseImageWithFabric({
            id: product.id + '-transparent',
            image: product.image.transparent,
            type: ImageType.TRANSPARENT,
            maxHeight: fabricRef.getHeight(),
            maxWidth: fabricRef.getWidth(),
            placementBuffer: 0,
            isSelectionIgnored: true,
          }),
        ]);
        applyCanvasDimensionChange({
          canvas: fabricRef,
          width: parsedOpaque.image.width || parsedOpaque.image.getScaledWidth(),
          height: parsedOpaque.image.height || parsedOpaque.image.getScaledHeight(),
        });

        parsedTransparent.image.clone((clonedObj: fabric.Image) => {
          parsedOpaque.image.set({
            visible: false,
            objectCaching: true,
            cacheKey: ImageType.OPAQUE,
            selectable: false,
            evented: false,
          });
          parsedTransparent.image.set({
            imageType: ImageType.TRANSPARENT,
            selectable: false,
            evented: false,
          });
          clonedObj.set({
            imageId: product.id + '-alpha',
            imageType: ImageType.ALPHA,
            visible: false,
            selectable: false,
            evented: false,
            isSelectionIgnored: true,
          });
          fabricRef.add(parsedOpaque.image);
          fabricRef.add(parsedTransparent.image);
          fabricRef.add(clonedObj);
          fabricRef.requestRenderAll();
          updateCanvasState(serializeCanvas(fabricRef));
        });
      } catch (error) {
        console.error(error);
      } finally {
        setCanvasLoadingStatus(false);
      }
    };

    parseImage();
  }, [fabricCanvasRef, updateCanvasState, product]);

  return (
    <div className='relative'>
      {imageLoadingView}
      <div>{canvasElement}</div>
      {/* <ZoomView /> */}
    </div>
  );
};
