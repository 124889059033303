import { Textarea } from 'components/ui/textarea';
import { useEffect, useRef, useState } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { Switch } from 'components/ui/switch';

export const NegativePrompt = () => {
  const negativePrompt = useAiSceneCreateStore((state) => state.aiScenePromptTab.negativePrompt);
  const updateAiScenePromptTab = useAiSceneCreateStore((state) => state.updateAiScenePromptTab);
  const savedNegativePrompt = useRef(negativePrompt);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [textValue, setTextValue] = useState(negativePrompt);

  useEffect(() => {
    if (negativePrompt) {
      setIsSwitchOn(true);
    }
  }, []);

  const handleSwitchChange = (checked: boolean) => {
    setIsSwitchOn(checked);
    if (!checked) {
      setTextValue('');
      savedNegativePrompt.current = '';
      updateAiScenePromptTab({ negativePrompt: '' });
    }

    setTextValue(savedNegativePrompt.current);
  };

  const handleTextareaInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setTextValue(value);
    savedNegativePrompt.current = value;
  };

  const handleTextareaBlur = () => {
    updateAiScenePromptTab({ negativePrompt: savedNegativePrompt.current });
  };

  return (
    <div className='flex h-[112px] flex-col items-start gap-4 rounded-lg'>
      <div className='flex w-full justify-between'>
        <p className='text-main font-semibold'>Exclude from background</p>
        <Switch
          className='h-[22px] data-[state=checked]:bg-crait-green-primary'
          thumbClassName='data-[state=checked]:bg-c-dark'
          onCheckedChange={handleSwitchChange}
          checked={isSwitchOn}
        />
      </div>
      <div className='flex w-full flex-col gap-2'>
        <p className={`text-main font-semibold ${!isSwitchOn ? 'text-gray-400' : ''}`}>
          Describe what do you want to avoid
        </p>
        <Textarea
          value={textValue}
          onInput={handleTextareaInput}
          onBlur={handleTextareaBlur}
          placeholder={`Describe what do you want to avoid`}
          className='h-full w-full rounded-lg bg-white text-xs'
          rows={7}
          disabled={!isSwitchOn}
        />
      </div>
    </div>
  );
};
