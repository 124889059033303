import { cn } from '@/lib/utils';
import { Button } from 'components/ui/button';
import { useNavigate, useParams } from 'react-router';
import { IconWrapper } from 'components/common/IconWrapper';
import { BulkProductsTable } from './BulkProductsTable';
import { useBulkProductsStore } from 'pages/workspace/brand-library/bulk-products/provider/helper';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { useBulkCompleteJobMutation } from './queries';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125';

export const BrandProductsEditTableView = () => {
  const navigate = useNavigate();
  const bulkProducts = useBulkProductsStore((state) => state.products);
  const { jobId } = useParams() as { jobId: string };
  const completeJobMutation = useBulkCompleteJobMutation();

  return (
    <main className={cn(`relative flex h-full w-full flex-col items-center gap-6`)}>
      <Button
        variant='ghost'
        className='absolute left-0 flex w-max gap-2 bg-white text-black'
        onClick={() => {
          completeJobMutation.mutate({ jobId });
          navigate(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`);
        }}
      >
        <IconWrapper
          wrapperProps={{
            className: ICON_WRAPPER_CL,
          }}
          iconClass='i-mdi-arrow-left size-4'
        />
        Back
      </Button>
      <h4 className='text-2xl'>
        Before saving, edit your photo as you wish
      </h4>
      {bulkProducts.length > 0 ? (
        <BulkProductsTable />
      ) : (
        <div className='flex flex-col gap-2'>
          <LoadingSpinner />
        </div>
      )}
    </main>
  );
};
