import { cn } from '@/lib/utils';
import { FC, ReactNode } from 'react';

interface BrandProductsEditViewProps {
  children: ReactNode;
  backButton: ReactNode;
}

export const BrandProductsEditView: FC<BrandProductsEditViewProps> = ({ children, backButton }) => {
  return (
    <main className={cn(`h-full w-full`)}>
      <div className='flex h-full justify-between md:flex-col md:items-start'>
        {backButton}
        {children}
      </div>
    </main>
  );
};
