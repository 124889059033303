import { Button, ButtonProps } from 'components/ui/button';
import React from 'react';

interface CraitButtonProps extends ButtonProps {}

const BUTTON_CLASS =
  'text-[14px] relative w-full bg-crait-green-primary font-semibold text-c-dark rounded-[10px]';

export const CraitButton: React.FC<CraitButtonProps> = ({ className = '', ...props }) => {
  return (
    <Button className={`${BUTTON_CLASS} ${className}`} {...props}>
      {props.children}
    </Button>
  );
};
