import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { ColorContent } from './color/ColorContent';
import { LogoContent } from './logo/LogoContent';
import { AddLogoModal } from './logo/AddLogoModal';
import { FC, ReactNode } from 'react';
import { ToneOfVoiceView } from './tone-of-voice/ToneOfVoiceView';

const ACCORDION_VALUES = {
  LOGOS: 'logos-section',
  COLOR_PALETTE: 'color-palette-section',
  BRAND_VOICE: 'brand-voice-section',
};
const ACCORDION_TRIGGER_CLASSES =
  'relative flex-row-reverse justify-end gap-3 text-[24px] font-semibold';

interface BrandKitViewProps {
  children?: ReactNode;
}

export const BrandKitView: FC<BrandKitViewProps> = ({ children }) => {
  return (
    <>
      <div className='h-full overflow-auto pb-10'>
        <div className='flex flex-col gap-5'>
          <div className='flex items-center gap-3'>
            <h4 className='mb-1 flex items-center text-[26px] font-medium'>Brand Kit</h4>
          </div>
          <Accordion
            type='multiple'
            defaultValue={[
              ACCORDION_VALUES.LOGOS,
              ACCORDION_VALUES.COLOR_PALETTE,
              ACCORDION_VALUES.BRAND_VOICE,
            ]}
          >
            <AccordionItem value={ACCORDION_VALUES.LOGOS} className='border-none'>
              <AccordionTrigger className={ACCORDION_TRIGGER_CLASSES}>
                Logos
                <div
                  className='absolute right-3'
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <AddLogoModal />
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <LogoContent />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value={ACCORDION_VALUES.COLOR_PALETTE} className='border-none'>
              <AccordionTrigger className={ACCORDION_TRIGGER_CLASSES}>
                Color Palette
              </AccordionTrigger>
              <AccordionContent>
                <ColorContent />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value={ACCORDION_VALUES.BRAND_VOICE} className='border-none'>
              <AccordionTrigger className={ACCORDION_TRIGGER_CLASSES}>Brand Voice</AccordionTrigger>
              <AccordionContent>
                <ToneOfVoiceView />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>
  );
};
