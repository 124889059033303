import React from 'react';

type ContinueButtonProps = {
  onClick: () => void;
};

export const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='mx-[-20px] h-10 rounded-lg bg-crait-green-primary px-4 py-2 text-[14px] font-semibold text-c-dark md:m-0'
    >
      Continue
    </button>
  );
};
