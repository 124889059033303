import { BrandProductsEditView } from 'components/workspace/brand-library/products-edit/BrandProductsEditView';
import { ProductProvider } from './ProductProvider';
import { ProductEditBox } from 'components/workspace/brand-library/products-edit/ProductEditBox';
import { ImageWrapperView } from 'components/workspace/brand-library/products-edit/ImageWrapperView';
import { ProductEditCanvas } from 'components/workspace/brand-library/products-edit/canvas/ProductEditCanvas';
import { useNavigate } from 'react-router';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125';

export const BrandLibraryProductsEditPage = () => {
  const navigate = useNavigate();

  return (
    <ProductProvider>
      <BrandProductsEditView
        backButton={
          <Button
            className='flex gap-2 bg-white text-black'
            onClick={() => {
              navigate(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`);
            }}
          >
            <IconWrapper
              wrapperProps={{
                className: ICON_WRAPPER_CL,
              }}
              iconClass='i-mdi-arrow-left size-4'
            />
            Back
          </Button>
        }
      >
        <ImageWrapperView canvasView={<ProductEditCanvas />}>
          <ProductEditBox />
        </ImageWrapperView>
      </BrandProductsEditView>
    </ProductProvider>
  );
};
