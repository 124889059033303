import { FC, ReactNode } from 'react';
import { cn } from '@/lib/utils';
import { ScrollArea } from 'components/ui/scroll-area';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Separator } from 'components/ui/separator';

interface EditDrawerProps {
  children: ReactNode;
  logoContent: ReactNode;
  filterContent: ReactNode;
  colorContent: ReactNode;
}

export const EditDrawer: FC<EditDrawerProps> = ({
  children,
  logoContent,
  filterContent,
  colorContent,
}) => {
  return (
    <aside
      className={cn(
        `relative h-full w-[310px] overflow-hidden bg-white	 px-4 py-4 text-xs shadow-lg`,
      )}
    >
      <ScrollArea className='h-full w-full'>
        {children}
        <Accordion type='multiple' className='flex w-full flex-col gap-2'>
          <AccordionItem value='logo' className='w-full border-none'>
            <AccordionTrigger className='h-12 font-bold'>Logo</AccordionTrigger>
            <AccordionContent className='w-[270px] overflow-hidden text-xs'>
              {logoContent}
            </AccordionContent>
          </AccordionItem>
          <Separator className='w-full' />
          <AccordionItem value='filters' className='w-full border-none'>
            <AccordionTrigger className='h-12 font-bold'>Filters</AccordionTrigger>
            <AccordionContent className='w-[270px] overflow-hidden text-xs'>
              {filterContent}
            </AccordionContent>
          </AccordionItem>
          <Separator className='w-full' />
        </Accordion>
        {colorContent}
      </ScrollArea>
    </aside>
  );
};
