import { fabric } from 'fabric';

export const FILTERS_DATA = [
  // {
  //   name: 'Grayscale',
  //   filterType: fabric.Image.filters.Grayscale,
  //   valueKey: 'grayscale',
  //   filterClass: 'grayscale-filter',
  //   hasAlphaControl: false,
  // },
  // {
  //   name: 'Brightness',
  //   filterType: fabric.Image.filters.Brightness,
  //   valueKey: 'brightness',
  //   filterClass: 'brightness-filter',
  //   hasAlphaControl: true,
  // },
  {
    name: 'Contrast',
    filterType: fabric.Image.filters.Contrast,
    valueKey: 'contrast',
    filterClass: 'contrast-filter',
    hasAlphaControl: true,
  },
  {
    name: 'Saturation',
    filterType: fabric.Image.filters.Saturation,
    valueKey: 'saturation',
    filterClass: 'saturation-filter',
    hasAlphaControl: true,
  },
  {
    name: 'Hue Rotation',
    filterType: fabric.Image.filters.HueRotation,
    valueKey: 'rotation',
    filterClass: 'hue-rotate-filter',
    hasAlphaControl: true,
  },
  {
    name: 'Blur',
    filterType: fabric.Image.filters.Blur,
    valueKey: 'blur',
    filterClass: 'blur-filter',
    hasAlphaControl: true,
  },
  {
    name: 'Pixelate',
    filterType: fabric.Image.filters.Pixelate,
    valueKey: 'blocksize',
    // Not supported via CSS.
    filterClass: '',
    hasAlphaControl: true,
  },
  {
    name: 'Sepia',
    filterType: fabric.Image.filters.Sepia,
    valueKey: 'sepia',
    filterClass: 'sepia-filter',
    hasAlphaControl: false,
  },
];
