import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@/lib/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, onValueChange, ...props }, ref) => {
  const trackIndicatorRef = React.useRef<HTMLDivElement>(null);

  const handleValueChange = React.useCallback(
    (value: number[]) => {
      onValueChange?.(value);

      if (!trackIndicatorRef.current) return;
      trackIndicatorRef.current.textContent = value[0].toString();
    },
    [onValueChange],
  );

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn('relative flex w-full touch-none select-none items-center', className)}
      onValueChange={handleValueChange}
      {...props}
    >
      <SliderPrimitive.Track className='relative h-1 w-full grow overflow-hidden rounded-full bg-[#EAEAEA]'>
        <SliderPrimitive.Range className='absolute h-full bg-[#c7f1b9]' />{' '}
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className='block size-4 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'>
        <span ref={trackIndicatorRef} className='absolute -top-4 right-0 text-xs'>
          {props.defaultValue ? props.defaultValue[0] : props.min}
        </span>
      </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
