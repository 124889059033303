import { Column, Row, RowData, Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { SelectAuthority } from './editable/SelectAuthority';
import { Input } from 'components/ui/input';

interface TableCellProps<TData> {
  getValue: () => unknown;
  row: Row<TData>;
  column: Column<TData>;
  table: Table<TData>;
}

export const TableCell = <TData extends RowData>({
  getValue,
  row,
  column,
  table,
}: TableCellProps<TData>) => {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);
  const typedValue = value as unknown as string;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (value: string) => {
    setValue(value);
    tableMeta?.updateData(row.index, column.id, value);
  };

  if (!tableMeta?.editedRows[row.id] || !columnMeta?.property) {
    if (columnMeta?.property?.type === 'select') {
      return (
        <div className='min-w-0'>
          {columnMeta.property.options.find((option) => option.value === value)?.label}
        </div>
      );
    }
    return <>{value}</>;
  }

  return columnMeta.property.type === 'select' ? (
    <SelectAuthority
      options={columnMeta.property.options}
      value={typedValue}
      onValueChange={onSelectChange}
      contentProps={{
        className: 'min-w-0',
      }}
    />
  ) : columnMeta.property.type === 'email' ? (
    <Input
      type='email'
      value={typedValue}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      className='h-9 min-w-0'
    />
  ) : (
    <Input
      type='text'
      value={typedValue}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      className='h-9 min-w-0'
    />
  );
};
