import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { StyleEndpoint } from '@/utils/fetch/constants';
import { getStylesApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';

type TStyleUpdatePayload = {
  id: string;
  name: string;
  image: File;
  organizationId: number;
};

const updateStyleDetail = async (props: TStyleUpdatePayload) => {
  const baseUrl = getStylesApiUrl(props.organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('image', props.image);
  return apiClient.put<string>(url, formData);
};

export const useUpdateStyleDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [StyleEndpoint.BASE, organizationId, 'updateStyleDetail'],
    mutationFn: (props: Omit<TStyleUpdatePayload, 'organizationId'>) => {
      return updateStyleDetail({ ...props, organizationId });
    },
  });
};

type TStylePartialUpdatePayload = {
  id: string;
  name?: string;
  image?: File;
  organizationId: number;
};

const updatePartialStyleDetail = async (props: TStylePartialUpdatePayload) => {
  const baseUrl = getStylesApiUrl(props.organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  if (props.name) formData.append('name', props.name);
  if (props.image) formData.append('image', props.image);

  return apiClient.patch<string>(url, formData);
};

export const usePartialUpdateStyleDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [StyleEndpoint.BASE, organizationId, 'updatePartialStyleDetail'],
    mutationFn: (props: Omit<TStylePartialUpdatePayload, 'organizationId'>) => {
      return updatePartialStyleDetail({ ...props, organizationId });
    },
  });
};

type TStyleUploadPayload = Omit<TStyleUpdatePayload, 'id'>;

const uploadStyle = async (props: TStyleUploadPayload) => {
  const baseUrl = getStylesApiUrl(props.organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}/`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('image', props.image);
  return apiClient.post<string>(url, formData);
};

export const useUploadStyle = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [StyleEndpoint.BASE, organizationId, 'uploadStyle'],
    mutationFn: (props: Omit<TStyleUploadPayload, 'organizationId'>) => {
      return uploadStyle({ ...props, organizationId });
    },
  });
};

type TDeleteStylePayload = {
  id: string;
  organizationId: number;
};

const deleteStyleById = async (props: TDeleteStylePayload) => {
  const baseUrl = getStylesApiUrl(props.organizationId, StyleEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  return apiClient.delete<string>(url);
};

export const useDeleteStyle = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [StyleEndpoint.BASE, organizationId],
    mutationFn: (props: Omit<TDeleteStylePayload, 'organizationId'>) => {
      return deleteStyleById({ ...props, organizationId });
    },
  });
};
