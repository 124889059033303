import { Button } from 'components/ui/button';
import { Modal } from 'components/ui/modal';
import { DeleteAccountForm } from './DeleteAccountForm';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

export const DeleteAccountCard = () => {
  const { width } = useWindowSize();
  const isMobile = width <= Breakpoints.TABLET;
  return (
    <div className='flex w-full  items-center justify-center gap-5 rounded-lg border p-4'>
      <div className='mb-4 flex  w-full flex-1 flex-col items-start justify-start gap-[6px]'>
        <h2 className='text-[14px] font-bold'>Delete</h2>
        <p className='text-[12px]'>
          You and your team else will lose access to all content created by you. This cannot be
          undone.
        </p>
      </div>
      <div className=' flex  flex-col gap-3'>
        {isMobile ? (
          <MobileMenuSheet
            triggerButton={
              <Button variant='outline' className='rounded-[8px] border-[#E16236] text-[#E16236]'>
                Delete
              </Button>
            }
            titleElement='Delete Account?'
            contentClassName='pb-5 bg-white'
          >
            {({ closeModal }) => <DeleteAccountForm onCloseModal={closeModal} />}
          </MobileMenuSheet>
        ) : (
          <Modal
            trigger={
              <Button variant='outline' className='rounded-[8px] border-[#E16236] text-[#E16236]'>
                Delete
              </Button>
            }
            titleElement='Delete Account?'
            modalContentClassName='max-w-[400px]'
          >
            {({ closeModal }) => <DeleteAccountForm onCloseModal={closeModal} />}
          </Modal>
        )}
      </div>
    </div>
  );
};
