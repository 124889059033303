import { cn } from '@/lib/utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { buttonVariants } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { Switch } from 'components/ui/switch';
import { COOKIE_SETTING, CookiePreferences } from './helper';

interface CookiePreferencesModalProps {
  preferences: CookiePreferences;
  setPreferences: React.Dispatch<React.SetStateAction<CookiePreferences>>;
  children?: React.ReactNode;
}

export const CookiePreferencesModal: React.FC<CookiePreferencesModalProps> = ({
  preferences,
  setPreferences,
  children,
}) => {
  const handleSwitchChange = (type: keyof CookiePreferences) => {
    const isMandatoryCookieType = type === 'necessary';
    if (isMandatoryCookieType) return;

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [type]: !prevPreferences[type],
    }));
  };

  return (
    <>
      <div className='p-4'>
        <p className='py-5 text-[12px]'>
          We use four types of cookies as described below. You can decide which categories of
          cookies you wish to accept to improve your experience on our website. To learn more about
          the cookies we use on our site, please read our Cookie Policy.
        </p>

        <Accordion
          type='single'
          defaultValue='necessary'
          collapsible
          className='flex w-full flex-col gap-2'
        >
          {COOKIE_SETTING.map((setting) => (
            <AccordionItem
              key={setting.key}
              value={setting.key}
              className='rounded-md border px-[10px] py-1'
            >
              <AccordionTrigger
                className={cn(
                  buttonVariants({ variant: 'secondary' }),
                  'group relative h-9 flex-row-reverse justify-end gap-2 rounded-lg p-2 pl-0 !text-main duration-200 hover:no-underline',
                )}
              >
                <div className='flex w-full items-center justify-between'>
                  {setting.title}{' '}
                  <Switch
                    checked={preferences[setting.key]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSwitchChange(setting.key);
                    }}
                    disabled={setting.key === 'necessary'}
                    className='data-[state=checked]:bg-crait-green-primary'
                    thumbClassName='data-[state=checked]:bg-c-dark'
                  />
                </div>
              </AccordionTrigger>
              <AccordionContent className='max-h-[200px] overflow-y-auto'>
                <p className='text-[12px] font-normal'>{setting.description}</p>
                <ul className='mt-2 space-y-1'>
                  {setting.cookies.map((cookie) => (
                    <li
                      key={cookie.name}
                      className='flex justify-between border-b py-2 text-[13px] md:flex-col md:gap-2'
                    >
                      <div className='flex-1 font-semibold'>
                        Name: <span className='font-normal'>{cookie.name}</span>
                      </div>
                      <div className='flex-1 font-semibold'>
                        Provider: <span className='font-normal'>{cookie.provider}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <Separator />

      {children}
    </>
  );
};
