import { ColumnDef } from '@tanstack/react-table';
import { RowActions } from './RowActions';
import { ProjectNameCell } from 'components/dashboard/people/table/ProjectNameCell';
import { TLastGenerationSummary } from 'pages/workspace/projects/helper';
import { TSavedSettingsResponse } from '../helper';

export const settingsColumns: ColumnDef<TSavedSettingsResponse>[] = [
  {
    id: 'thumbnail',
    accessorFn: (row) => (row?.summary as any).thumbnail,
    header: '',
    cell: ({ row, getValue }) => {
      const imageUrl = (getValue() as string) || '';
      return (
        <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-lg bg-gray-200'>
          {imageUrl ? (
            <img
              crossOrigin='anonymous'
              src={imageUrl}
              alt=''
              className='h-full w-full object-cover'
            />
          ) : (
            <div className='text-xs text-gray-500'></div>
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
    minSize: 50,
  },
  {
    accessorKey: 'name',
    header: 'Settings Name',
    cell: ProjectNameCell,
    enableSorting: false,
    minSize: 220,
  },
  {
    id: 'prompt',
    accessorFn: (row) => row?.summary.prompt,
    header: 'Prompt',
    cell: ({ row, getValue }) => {
      const prompt = (getValue() as TLastGenerationSummary['prompt']) || '';
      return (
        <div className='flex space-x-2'>
          <span className='max-w-[220px] truncate'>{prompt}</span>
        </div>
      );
    },
    enableSorting: false,
    minSize: 200,
  },
  {
    id: 'styles',
    accessorFn: (row) => row?.summary.styles,
    header: 'Inspiration',
    cell: ({ row, getValue }) => {
      const styles = (getValue() as TLastGenerationSummary['styles']) || [];

      return (
        <div className='flex gap-3'>
          {styles.map((style) => (
            <div key={style.id} className='flex h-full max-w-14 flex-col items-center gap-1'>
              <img
                src={style.thumbnail}
                alt={style.name}
                className='size-8 rounded-full border border-gray-300 object-cover'
              />
              <span className='max-w-full truncate text-xs capitalize'>{style.name}</span>
            </div>
          ))}
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'brand_style',
    accessorFn: (row) => row?.summary.brand_style,
    header: 'Brand Style',
    cell: ({ row, getValue }) => {
      const brand_style = getValue() as TLastGenerationSummary['brand_style'];
      if (!brand_style) return null;

      return (
        <div className='m-auto flex max-w-14 flex-col items-center gap-1'>
          <img
            src={brand_style.thumbnail}
            alt={brand_style?.name}
            className='size-8 rounded border border-gray-300 object-cover'
          />
          <span className='max-w-full truncate text-xs capitalize'>{brand_style?.name}</span>
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    minSize: 120,
  },
  {
    id: 'lighting_style',
    accessorFn: (row) => row?.summary.lighting_style,
    header: 'Lighting Style',
    cell: ({ row, getValue }) => {
      const lighting_style = getValue() as TLastGenerationSummary['lighting_style'];
      if (!lighting_style) return null;

      return (
        <div className='m-auto flex max-w-14 flex-col items-center gap-1'>
          <img
            src={lighting_style.thumbnail}
            alt={lighting_style?.name}
            className='size-8 rounded border border-gray-300 object-cover'
          />
          <span className='max-w-full truncate text-xs capitalize'>{lighting_style?.name}</span>
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    minSize: 140,
  },
  {
    id: 'template',
    accessorFn: (row) => row?.summary.template,
    header: 'Template',
    cell: ({ row, getValue }) => {
      const template = getValue() as TLastGenerationSummary['template'];
      if (!template) return null;

      return (
        <div className='m-auto flex max-w-14 flex-col items-center gap-1'>
          <img
            src={template?.thumbnail}
            alt={template?.name}
            className='size-8 rounded border border-gray-300 object-cover'
          />
          <span className='max-w-full truncate text-xs capitalize'>{template?.name}</span>
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'actions',
    header: '',
    cell: ({ row, table }) => <RowActions row={row} table={table} />,
  },
];
