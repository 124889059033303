import { Label } from 'components/ui/label';
import { Separator } from 'components/ui/separator';
import { Textarea } from 'components/ui/textarea';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { useGetBrandVoiceGuideQuery, usePartialUpdateBrandVoiceGuide } from './queries';
import { toast } from 'sonner';
import { CraitButton } from 'components/common/CraitButton';

const MAX_CHARACTERS = 300;
const _setCharCount = (ref: React.RefObject<HTMLSpanElement>, value: string | undefined) => {
  if (!ref.current) return;

  ref.current.textContent = `${value?.length || 0}/${MAX_CHARACTERS}`;
};

interface ToneOfVoiceViewProps {
  children?: ReactNode;
}

export const ToneOfVoiceView: FC<ToneOfVoiceViewProps> = ({ children }) => {
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const preferredKeywordsRef = useRef<HTMLTextAreaElement>(null);
  const bannedKeywordsRef = useRef<HTMLTextAreaElement>(null);
  const descriptionCharRef = useRef<HTMLSpanElement>(null);
  const preferredKeywordsCharRef = useRef<HTMLSpanElement>(null);
  const bannedKeywordsCharRef = useRef<HTMLSpanElement>(null);
  const { data } = useGetBrandVoiceGuideQuery();
  const updateBrandVoiceGuide = usePartialUpdateBrandVoiceGuide();

  useEffect(() => {
    if (!data) return;

    if (descriptionRef.current) {
      descriptionRef.current.value = data.description;
      _setCharCount(descriptionCharRef, data.description);
    }
    if (preferredKeywordsRef.current) {
      preferredKeywordsRef.current.value = data.preferredKeywords;
      _setCharCount(preferredKeywordsCharRef, data.preferredKeywords);
    }
    if (bannedKeywordsRef.current) {
      bannedKeywordsRef.current.value = data.bannedKeywords;
      _setCharCount(bannedKeywordsCharRef, data.bannedKeywords);
    }
  }, [data]);

  return (
    <div className='h-full w-full max-w-[1440px] px-5'>
      <div className='my-6'>
        <Label
          htmlFor='brandDescription'
          className='text-md mb-2 flex items-center justify-between gap-1'
        >
          <span>Brand description & tone example</span>
          <span className='text-xs' ref={descriptionCharRef}>
            0/{MAX_CHARACTERS}
          </span>
        </Label>
        <Textarea
          ref={descriptionRef}
          id='brandDescription'
          placeholder='Describe your brand in a few words'
          className='h-full max-h-[200px] min-h-[130px] w-full rounded-lg bg-white text-xs'
          maxLength={MAX_CHARACTERS}
          onChange={(e) => {
            _setCharCount(descriptionCharRef, e.currentTarget.value);
          }}
        />
      </div>
      <div className='flex gap-6 md:flex-col'>
        <div className='flex-1'>
          <Label
            htmlFor='preferredKeywords'
            className='text-md mb-2 flex items-center justify-between gap-1.5'
          >
            <span>Always use these keywords</span>
            <span className='text-xs' ref={preferredKeywordsCharRef}>
              0/{MAX_CHARACTERS}
            </span>
          </Label>
          <Textarea
            ref={preferredKeywordsRef}
            id='preferredKeywords'
            placeholder='Describe your brand in a few words'
            className='h-[130px] w-full resize-none rounded-lg bg-white text-xs'
            maxLength={MAX_CHARACTERS}
            onChange={(e) => {
              _setCharCount(preferredKeywordsCharRef, e.currentTarget.value);
            }}
          />
        </div>
        <div className='flex-1'>
          <Label
            htmlFor='bannedKeywords'
            className='text-md mb-2 flex items-center justify-between gap-1.5'
          >
            <span>Never use these keywords</span>
            <span className='text-xs' ref={bannedKeywordsCharRef}>
              0/{MAX_CHARACTERS}
            </span>
          </Label>
          <Textarea
            ref={bannedKeywordsRef}
            id='bannedKeywords'
            placeholder='Describe your brand in a few words'
            className='h-[130px] w-full resize-none rounded-lg bg-white text-xs'
            maxLength={MAX_CHARACTERS}
            onChange={(e) => {
              _setCharCount(bannedKeywordsCharRef, e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <Separator className='my-4' />
      <CraitButton
        className='h-[40px] w-full max-w-[200px] rounded-lg md:max-w-full'
        onClick={async () => {
          try {
            await updateBrandVoiceGuide.mutateAsync({
              description: descriptionRef.current?.value,
              preferredKeywords: preferredKeywordsRef.current?.value,
              bannedKeywords: bannedKeywordsRef.current?.value,
            });
            toast.success('Changed saved successfully');
          } catch (error) {
            toast.error('Failed to update brand voice guide');
          }
        }}
      >
        Save Changes
      </CraitButton>
    </div>
  );
};
