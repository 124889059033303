import { FC } from 'react';
import ZoomInIcon from '@/assets/icons/ai-scene-icons/zoomIn.svg?react';
import ZoomOutIcon from '@/assets/icons/ai-scene-icons/zoomOut.svg?react';
import { Input } from 'components/ui/input';

export const ZoomControl: FC = () => {
  return (
    <div className='flex items-center gap-3'>
      <ZoomOutIcon />
      <Input
        className='w-[55px] border border-[#eaeaea] bg-white text-[12px]'
        value='50%'
        onChange={() => {}}
      />
      <ZoomInIcon />
    </div>
  );
};
