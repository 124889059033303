import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { TooltipItem } from 'components/common/TooltipItem';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';

export const EnhancePromptSwitch = () => {
  const isEnhancePromptChecked = useAiSceneCreateStore(
    (state) => state.aiScenePromptTab.enhancePrompt,
  );
  const updateAiScenePromptTab = useAiSceneCreateStore((s) => s.updateAiScenePromptTab);

  return (
    <div className='mb-2 flex w-full items-center justify-between'>
      <Label htmlFor='prompt-enhancement-mode' className='flex cursor-pointer items-center gap-2'>
        <p className='text-main font-semibold'>Enhance My Prompt</p>
        <TooltipItem
          trigger={
            <span className='i-mdi-information-slab-circle-outline cursor-pointer text-base'></span>
          }
        >
          <p className='max-w-[300px] rounded-2xl px-1 py-0.5'>
            Use AI to enhance your prompt. This feature improves the quality of results (especially
            for shorter prompts) but can decrease the prompt adherence.
          </p>
        </TooltipItem>
      </Label>
      <Switch
        id='prompt-enhancement-mode'
        checked={isEnhancePromptChecked}
        onCheckedChange={(isChecked) => {
          updateAiScenePromptTab({ enhancePrompt: isChecked });
        }}
        className='h-[22px] data-[state=checked]:bg-crait-green-primary'
        thumbClassName='data-[state=checked]:bg-c-dark'
      />
    </div>
  );
};
