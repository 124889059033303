import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

interface IModalProps {
  trigger?: ReactNode;
  titleElement: ReactNode;
  children: ReactNode | ((props: { closeModal: () => void }) => ReactElement | null);
  modalContentClassName?: string;
  triggerClassName?: string;
  isDisabled?: boolean;
}

export const Modal: FC<
  IModalProps & Omit<React.ComponentPropsWithoutRef<typeof Dialog>, 'children'>
> = ({
  trigger,
  children,
  titleElement,
  modalContentClassName,
  triggerClassName,
  isDisabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(props.open || false);

  useEffect(() => {
    setIsOpen(props.open || false);
  }, [props.open]);

  const childrenProp =
    typeof children === 'function'
      ? children({
          closeModal: () => {
            setIsOpen(false);
            props.onOpenChange?.(false);
          },
        })
      : children;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isShown) => {
        setIsOpen(isShown);
        props.onOpenChange?.(isShown);
      }}
    >
      {trigger && (
        <DialogTrigger
          className={cn('btn btn-primary', triggerClassName, isDisabled && 'cursor-not-allowed')}
          onClick={(e) => isDisabled && e.preventDefault()}
          asChild
        >
          <div>{trigger}</div>
        </DialogTrigger>
      )}
      <DialogContent className={`w-full rounded-[20px] bg-white p-0 ${modalContentClassName}`}>
        <DialogHeader>
          <DialogTitle className='absolute left-4 top-4 text-[16px] font-semibold'>
            {titleElement}
          </DialogTitle>
        </DialogHeader>
        {isOpen && childrenProp}
      </DialogContent>
    </Dialog>
  );
};
