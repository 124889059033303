import { Button } from 'components/ui/button';
import { FC } from 'react';

const _getStatusText = (status: TUploadStatus) =>
  status === 'loading' ? (
    'Loading...'
  ) : status === 'success' ? (
    'File content is validated correctly.'
  ) : (
    <span className='flex w-full flex-col text-xs text-red-400'>
      The following errors have been found:
    </span>
  );

export type TUploadStatus = 'loading' | 'success' | 'error' | 'idle';

interface IExcelUploadInfoProps {
  status: TUploadStatus;
  fileName: string;
  clearFile: () => void;
}
export const ExcelUploadInfo: FC<IExcelUploadInfoProps> = ({ fileName, status, clearFile }) => {
  if (status === 'idle') return null;

  const statusText = _getStatusText(status);

  return (
    <div className='flex w-full flex-col gap-1 text-center'>
      {status !== 'loading' && (
        <div className='flex items-center justify-center gap-2'>
          <span className='text-sm'>{fileName}</span>
          <Button
            size='sm'
            variant='ghost'
            className='h-6 w-6 rounded-full border bg-white p-0'
            onClick={clearFile}
          >
            <span className='i-mdi-window-close size-4'></span>
          </Button>
        </div>
      )}
      <span>{statusText}</span>
    </div>
  );
};
