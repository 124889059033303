import React from 'react';
import { CardAction } from './ProjectCardAction';
import { TProject } from 'pages/workspace/projects/helper';
import { getRedirectUrlAccordingToType, getRedirectUrlToCanvas } from '../helper';
import { NavLink } from 'react-router-dom';
import { timestampToDate } from '@/utils/helper';

interface ProjectCardProps {
  item: TProject;
  refetch: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ item, refetch }) => {
  const redirectUrl = getRedirectUrlAccordingToType(item.id, item.type);
  const formattedDate = timestampToDate(item.last_update_info.timestamp);

  return (
    <div className='bg-grayscale-700 max-w-[260px] rounded-md' key={item.id}>
      <div className='group h-full overflow-hidden rounded-lg transition hover:shadow-sm'>
        <div className='relative w-full overflow-hidden rounded-t-md'>
          <NavLink to={redirectUrl}>
            <img
              src={item.thumbnail}
              alt={item.name}
              className='h-[260px] w-full object-cover md:h-[170px]'
            />
          </NavLink>
        </div>
        <div className='flex items-center justify-between px-0 py-3'>
          <div className='flex flex-col'>
            <div className='line-clamp-2 text-main font-semibold md:text-[11px]'>{item.name}</div>
            <div className='text-[12px] font-normal text-[#777777] md:text-[10px]'>
              Last Edited at {formattedDate}
            </div>
          </div>
          <CardAction {...item} refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
