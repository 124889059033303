import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import DarkModeIcon from '@/assets/icons/darkMode.svg?react';
import LightModeIcon from '@/assets/icons/lightMode.svg?react';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] stroke-[#777777] w-full flex justify-center gap-[6px] bg-white !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:font-semibold data-[state=active]:text-c-dark  data-[state=active]:stroke-c-dark';

export const ThemeCard = () => {
  return (
    <div className='flex w-full flex-col items-start justify-between gap-5 rounded-lg border p-4'>
      <div className='mb-4 flex w-full items-start justify-between'>
        <h2 className='text-[14px] font-bold'>Theme</h2>
        <Tabs defaultValue='light'>
          <TabsList className='roundet-[6px] w-[182px] border border-[#F0F0F0] bg-white'>
            <TabsTrigger className={TAB_TRIGGER_CLASS} value='light'>
              <LightModeIcon />
              Light
            </TabsTrigger>
            <TabsTrigger className={TAB_TRIGGER_CLASS} value='dark'>
              <DarkModeIcon />
              Dark
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
    </div>
  );
};
