import { Row, RowData, Table } from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import { PopoverView } from 'components/common/PopoverView';

interface EditCellProps<TData> {
  row: Row<TData>;
  table: Table<TData>;
}

export const EditCell = <TData extends RowData>({ row, table }: EditCellProps<TData>) => {
  const meta = table.options.meta;
  const isEditMode = meta?.editedRows[row.id];

  const setEditedRows = () => {
    meta?.setEditedRows((prev) => ({
      ...prev,
      [row.id]: true,
    }));
  };

  return isEditMode ? (
    <div className='flex'>
      <Button
        size='sm'
        variant='ghost'
        onClick={() => {
          meta?.revertData(row.index);
        }}
        className='rounded-full px-2.5 py-1'
      >
        <span className='i-mdi-close size-5'></span>
      </Button>
      <Button
        size='sm'
        variant='ghost'
        onClick={() => {
          meta?.saveRow(row.index, row.original);
        }}
        className='rounded-full px-2.5 py-1'
      >
        <span className='i-mdi-check size-5 text-green-600'></span>
      </Button>
    </div>
  ) : (
    <div className='flex'>
      <Button
        size='sm'
        variant='ghost'
        onClick={setEditedRows}
        className='rounded-full px-2.5 py-1'
      >
        <span
          className='i-mdi-pencil
        size-4
        text-[#656464]'
        />
      </Button>
      <PopoverView
        popoverContentProps={{
          className: 'p-2',
          align: 'end',
        }}
        trigger={
          <Button size='sm' variant='ghost' className='rounded-full px-2.5 py-1'>
            <span className='i-mdi-trash-can-outline size-5 text-[#df565bcb]'></span>
          </Button>
        }
      >
        {(props) => (
          <div className='relative flex flex-col gap-3 p-3'>
            <Button
              onClick={() => {
                props.closePopover();
              }}
              size='sm'
              variant='ghost'
              className='absolute right-1 top-1 rounded-full px-2.5 py-1'
            >
              <span className='i-mdi-window-close size-5' />
            </Button>
            <p className='pr-6 text-sm font-medium text-gray-600'>
              Are you sure you want to delete this user?
            </p>
            <Button
              size='sm'
              className='h-8 rounded-xl bg-[#DF565B] py-1'
              onClick={() => {
                props.closePopover();
                meta?.deleteRow(row.index, row.original);
              }}
            >
              Yes, delete
            </Button>
          </div>
        )}
      </PopoverView>
    </div>
  );
};
