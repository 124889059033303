type TStatusCode = 200 | 201 | 400 | 403 | 404 | 500;

interface IErrorProps {
  code: TStatusCode;
  message: string;
  customCause?: string;
}

export class CustomError extends Error {
  __proto__ = Error;
  code;
  customCause;

  constructor({ message, customCause, code }: IErrorProps) {
    super(message);
    this.code = code;
    this.customCause = customCause;
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

// Code snippet from: https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = { message: string };
// Check if the error has the same shape as Error object.
const _isErrorWithMessage = (error: unknown): error is ErrorWithMessage =>
  typeof error === 'object' &&
  error !== null &&
  'message' in error &&
  typeof (error as Record<string, unknown>).message === 'string';

// Transform error cases to Error object shape where 'message' will be available.
const _toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (_isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

interface IParseErrorResult {
  cause?: string | unknown;
  message: string;
  stack?: string;
}
export const parseError = (error: any): IParseErrorResult => ({
  cause: error?.customCause || error?.cause,
  message: _toErrorWithMessage(error).message,
  stack: error?.stack,
});
