import { MAX_CANVAS_HISTORY } from '@/utils/store/constants';
import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { fabric } from 'fabric';

export type TAiSceneImage = { id: string; image: string };

export type TAiSceneResultStore = {
  canvasState: string;
  aiSceneResultCanvasInstance: fabric.Canvas | null;
  aiSceneResultPreviousCanvasStates: string[];
  aiSceneResultNextCanvasStates: string[];
  setAiSceneResultCanvasInstance: (canvas: fabric.Canvas | null) => void;
  updateAiSceneResultCanvasState: (newState: string) => void;
  undoAiSceneResultCanvasState: () => void;
  redoAiSceneResultCanvasState: () => void;
  resetAiSceneResultCanvasState: () => void;
  activeSceneTitle: string;
  updateActiveSceneTitle: (title: string) => void;
  processingState:
    | 'idle'
    | 'pending'
    | 'sketching'
    | 'enhancing'
    | 'erasing'
    | 'repairing'
    | 'removingBackground';
  changeProcessingState: (state: TAiSceneResultStore['processingState']) => void;
};

const useAiSceneResultSlice: ImmerStateCreator<TAiSceneResultStore> = (set) => ({
  processingState: 'idle',
  changeProcessingState: (state) => {
    set((s) => {
      s.processingState = state;
    }),
      false,
      'changeProcessingState';
  },
  aiSceneResultCanvasInstance: null,
  setAiSceneResultCanvasInstance: (canvas) => {
    set((state) => {
      state.aiSceneResultCanvasInstance = canvas as any;
    }),
      false,
      'setAiSceneResultCanvasInstance';
  },
  canvasState: '',
  activeSceneTitle: '',
  updateActiveSceneTitle: (title) => {
    set((state) => {
      state.activeSceneTitle = title;
    }),
      false,
      'updateActiveSceneTitle';
  },
  aiSceneResultPreviousCanvasStates: [],
  aiSceneResultNextCanvasStates: [],
  updateAiSceneResultCanvasState: (newState) => {
    set(
      (state) => {
        if (!newState) {
          state.canvasState = newState;
          state.aiSceneResultPreviousCanvasStates = [];
          state.aiSceneResultNextCanvasStates = [];
          return;
        }

        // The current state must be saved to the history before state update,
        // otherwise the prev state will be same as the latest state.
        if (state.canvasState) {
          const newPrev = [...state.aiSceneResultPreviousCanvasStates, state.canvasState].slice(
            -MAX_CANVAS_HISTORY,
          );
          // Add the current state to history only if it's not the initial setup
          state.aiSceneResultPreviousCanvasStates = newPrev;
        }

        state.canvasState = newState;
        state.aiSceneResultNextCanvasStates = [];
      },
      false,
      'updateAiSceneResultCanvasState',
    );
  },
  undoAiSceneResultCanvasState: () => {
    set(
      (state) => {
        if (state.aiSceneResultPreviousCanvasStates.length === 0) return;

        // Add the current state to the start of next states before changing it.
        state.aiSceneResultNextCanvasStates = [
          state.canvasState,
          ...state.aiSceneResultNextCanvasStates,
        ].slice(0, MAX_CANVAS_HISTORY);

        const previousState =
          state.aiSceneResultPreviousCanvasStates[
            state.aiSceneResultPreviousCanvasStates.length - 1
          ];
        const newPreviousSceneCanvasStates = state.aiSceneResultPreviousCanvasStates.slice(0, -1);

        state.canvasState = previousState;
        state.aiSceneResultPreviousCanvasStates = newPreviousSceneCanvasStates;
      },
      false,
      'undoAiSceneResultCanvasState',
    );
  },
  redoAiSceneResultCanvasState: () => {
    set(
      (state) => {
        if (state.aiSceneResultNextCanvasStates.length === 0) return;

        // Add the current state to the end of previous states before changing it
        state.aiSceneResultPreviousCanvasStates = [
          ...state.aiSceneResultPreviousCanvasStates,
          state.canvasState,
        ].slice(-MAX_CANVAS_HISTORY);

        const nextState = state.aiSceneResultNextCanvasStates[0];
        const newNextSceneCanvasStates = state.aiSceneResultNextCanvasStates.slice(1);

        state.canvasState = nextState;
        state.aiSceneResultNextCanvasStates = newNextSceneCanvasStates;
      },
      false,
      'redoAiSceneResultCanvasState',
    );
  },
  resetAiSceneResultCanvasState: () => {
    set(
      (state) => {
        state.canvasState = '';
        state.aiSceneResultPreviousCanvasStates = [];
        state.aiSceneResultNextCanvasStates = [];
      },
      false,
      'resetAiSceneResultCanvasState',
    );
  },
});

export const createAiSceneResultStore = (initProps?: Partial<TAiSceneResultStore>) => {
  return createStore<TAiSceneResultStore>()(
    devtools(
      immer((...props) => ({
        ...useAiSceneResultSlice(...props),
        ...initProps,
      })),
    ),
  );
};
