import { AccountPaths, RoutePaths } from '@/utils/route/constants';
import { calculateRemainingPercentage } from 'components/dashboard/profile/helper';
import { Progress } from 'components/ui/progress';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { Link } from 'react-router-dom';

export const CreaditViews = () => {
  const data = useAccountCtx();

  const packageText = `${data.plan} Package ${data.trial_period ? 'Trial' : ''}`;

  const remainingCreditText =
    data.credit.remaining !== null
      ? `${data?.credit.remaining}/${data.credit.total}`
      : 'Unlimited Credits';

  return (
    <div className='flex flex-col gap-3 rounded-[10px] bg-[#F0F0F0] p-2 '>
      <div className='flex flex-col'>
        <div className='text-dark text-main font-semibold capitalize'>{packageText}</div>
      </div>
      <Progress
        value={calculateRemainingPercentage(data.credit.total, data?.credit.remaining)}
        className='h-[6px] rounded-lg bg-[#D9D9D9]'
      />
      <div className='flex justify-between'>
        <span className='text-[12px] font-medium text-[#777777]'>{remainingCreditText}</span>
        <Link
          to={`/${RoutePaths.ACCOUNT}/${AccountPaths.PAYMENT}`}
          className='text-[12px] font-medium text-[#777777] underline underline-offset-4'
        >
          Plans & Pricing
        </Link>
      </div>
    </div>
  );
};
