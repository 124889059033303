export interface CookiePreferences {
  necessary: boolean;
  preferences: boolean;
  analytics: boolean;
  targeting: boolean;
}

interface Cookie {
  name: string;
  provider: string;
}

interface CookieSetting {
  key: keyof CookiePreferences;
  title: string;
  description: string;
  cookies: Cookie[];
}

export const COOKIE_SETTING: CookieSetting[] = [
  {
    key: 'necessary',
    title: 'Essential Cookies',
    description:
      "Essential Cookies are needed for our website to function as expected. You cannot turn off Essential Cookies because they are required to deliver security, enable core site functionality, and help you use our site's features and services as you would expect (including remembering your cookie consent preferences).",
    cookies: [
      { name: 'AccessToken', provider: 'crait.it' },
      { name: 'RefreshToken', provider: 'crait.it' },
      { name: 'UserStorePersist', provider: 'crait.it' },
      { name: 'Expiry', provider: 'crait.it' },
      { name: '_stripe_mid', provider: 'crait.it' },
      { name: '_stripe_sid', provider: 'crait.it' },
    ],
  },
  {
    key: 'preferences',
    title: 'Functional Cookies',
    description:
      'Functional cookies enhance the functionality of our website by storing your preferences and settings. You can choose to turn these cookies on or off.',
    cookies: [
      { name: 'cookiePreferences', provider: 'crait.it' },
      { name: 'framerCookiesConsentMode', provider: 'crait.it' },
      { name: 'framerCookiesDismissed', provider: 'crait.it' },
    ],
  },
  {
    key: 'analytics',
    title: 'Performance Cookies',
    description:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. It helps us know which pages are most popular and which are least popular, and to see how visitors move around the site. All data collected by these cookies is anonymous because it is collected in the aggregate. However, Google Analytics, the third parties that provide these services, process your personal data to provide the collected data.',
    cookies: [
      { name: 'sid', provider: 'crait.it' },
      { name: '_ga', provider: 'crait.it' },
      { name: '_ga_XXXXX', provider: 'crait.it' },
      { name: '_hjSessionUser_XXXXX', provider: 'crait.it' },
      { name: '_hjUserAttributesHash', provider: 'crait.it' },
      { name: '_hjActiveViewportIds', provider: 'crait.it' },
      { name: '_hjHasCachedUserAttributes', provider: 'crait.it' },
      { name: '_hjViewportId', provider: 'crait.it' },
      { name: '_hjSession_XXXXX', provider: 'crait.it' },
      { name: '_hjCookieTest', provider: 'crait.it' },
      { name: '_hjLocalStorageTest', provider: 'crait.it' },
      { name: '_hjSessionStorageTest', provider: 'crait.it' },
      { name: '_hjTLDTest', provider: 'crait.it' },
      { name: 'sentryReplaySession', provider: 'crait.it' },
      { name: '__cflb', provider: 'crait.it' },
      { name: '__cf_bm', provider: 'crait.it' },
      { name: 'cf_ob_info', provider: 'crait.it' },
      { name: 'cf_use_ob', provider: 'crait.it' },
      { name: '__cfuid', provider: 'crait.it' },
      { name: '_gid', provider: 'crait.it' },
    ],
  },
  {
    key: 'targeting',
    title: 'Marketing Cookies',
    description:
      'Marketing cookies are used to deliver advertisements more relevant to you and your interests. You can choose to turn these cookies on or off.',
    cookies: [
      { name: '_gads', provider: 'crait.it' },
      { name: '_gac_gb_<wpid>', provider: 'crait.it' },
      { name: 'NID', provider: 'google.com' },
      { name: 'DSID', provider: 'doubleclick.net' },
      { name: 'IDE', provider: 'doubleclick.net' },
      { name: 'ANID', provider: 'google.com' },
      { name: 'FLC', provider: 'doubleclick.net' },
      { name: 'AID', provider: 'google.com/ads' },
      { name: 'TAID', provider: 'google.com/ads' },
      { name: 'Conversion', provider: 'www.googleadservices.com/pagead' },
      { name: 'test_cookie', provider: 'doubleclick.net' },
      { name: 'fr', provider: 'facebook.com' },
      { name: '_fbc', provider: 'crait.it' },
      { name: '_fbp', provider: 'crait.it' },
      { name: 'personalization_id', provider: 'twitter.com' },
      { name: '_ttp', provider: 'crait.it' },
      { name: '_pangle', provider: 'analytics.pangle-ads.com' },
    ],
  },
];
