import { useMutation } from '@tanstack/react-query';
import { BASE_API_URL, MiscEndpoint } from '@/utils/fetch/constants';
import axios from 'axios';

export const useUnsubscribeEmail = () => {
  return useMutation({
    mutationKey: [MiscEndpoint.EMAIL_UNSUBSCRIBE],
    mutationFn: ({ token }: { token: string }) => {
      return axios.post(`${BASE_API_URL}/${MiscEndpoint.EMAIL_UNSUBSCRIBE}?token=${token}`);
    },
  });
};
