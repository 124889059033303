import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { AccountContext, useAccountStatistics } from './helper';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { RoutePaths } from '@/utils/route/constants';

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountProvider: FC<AccountProviderProps> = ({ children }) => {
  const account = useAccountStatistics();

  if (account.data) {
    return <AccountContext.Provider value={account.data}>{children}</AccountContext.Provider>;
  }

  if (account.isError) {
    return <Navigate to={`/${RoutePaths.LOGIN}`} />;
  }

  return (
    <div className='mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );
};
