import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';

import { serializeCanvas } from '@/utils/helper';
import { useCallback } from 'react';
import { useSyncSelectedTemplate } from '../visual-guidance/useSyncSelectedTemplate';

export const useRemoveSelectedTemplate = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const removeAiSceneImages = useAiSceneCreateStore((state) => state.removeAiSceneImages);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  const [_, setDrawnTemplate] = useSyncSelectedTemplate();

  const removeSelectedTemplate = useCallback(
    (id: string) => {
      if (!canvasInstance) return;

      const templateImageOnCanvas = canvasInstance
        .getObjects()
        .find((obj) => obj.imageType === 'template' && 'imageId' in obj && obj.imageId === id);
      if (!templateImageOnCanvas) return;

      removeAiSceneImages([id]);
      setDrawnTemplate(null);

      const isActiveObj = templateImageOnCanvas === canvasInstance.getActiveObject();
      canvasInstance.remove(templateImageOnCanvas);
      if (
        'dispose' in templateImageOnCanvas &&
        typeof templateImageOnCanvas.dispose === 'function'
      ) {
        templateImageOnCanvas.dispose();
      }
      if (isActiveObj) canvasInstance.discardActiveObject();
      canvasInstance.requestRenderAll();
      updateCanvasState(serializeCanvas(canvasInstance));
    },
    [canvasInstance, removeAiSceneImages, setDrawnTemplate, updateCanvasState],
  );

  return { removeSelectedTemplate };
};
