import { useMutation } from '@tanstack/react-query';
import { apiClient } from '../fetch/axiosConfig';
import { AuthEndpoint } from '../fetch/constants';
import { User } from '@/providers/user/store';

export const getRefreshToken = async (refreshToken: string) => {
  const response = await apiClient.post<
    Pick<User, 'access_token' | 'refresh_token' | 'token_type'>
  >(AuthEndpoint.REFRESH_TOKEN, {
    refreshToken,
  });
  return response.data;
};

export const useRefreshToken = () => {
  return useMutation({
    mutationKey: ['refreshToken'],
    mutationFn: getRefreshToken,
  });
};
