import { useMemo } from 'react';
import 'components/ai-scene/create/edit-drawer/filters.css';

import { EnhanceCanvasImageQuality } from './enhance-quality/EnhanceCanvasImageQuality';
import { EDIT_MENU_ITEMS, EditToolMenuType, EditToolMenuTypes } from './EditToolContainer';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { TooltipItem } from 'components/common/TooltipItem';
import { useIsAiImageType } from 'pages/ai-scene/useIsAiImageType';
import { PlanGuard } from 'components/plan-guard/PlanGuard';
import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { useNavigate, useParams } from 'react-router';
import { AiSceneSideMenus, useMenuStore } from 'components/ai-scene/side-menu/store';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';
import { useGetScene } from 'pages/ai-scene/review-edit/helper';
import { RepairCanvasImageQuality } from './repair/RepairCanvasImageQuality';
import { ScrollArea } from 'components/ui/scroll-area';
import { BackgroundRemoveCanvasImage } from './background-remove/BackgroundRemoveCanvasImage';

type EditToolProps = {
  setMenu: (menu: EditToolMenuType) => void;
};

export const EDIT_TOOLS_CONTAINER_CLASS =
  'flex h-full w-[260px] flex-col gap-[6px] rounded-lg bg-white p-[10px] pb-0 shadow-md';
export const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white w-full !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

export const EditTool = ({ setMenu }: EditToolProps) => {
  const processingState = useAiSceneResultStore((state) => state.processingState);
  const isAiImageType = useIsAiImageType();
  const scene = useGetScene();
  const isProcessing = processingState !== 'idle';

  const sceneProducts = scene.data?.generationParameters.productIds.length || 0;
  const hasProduct = sceneProducts > 0;

  const navigate = useNavigate();
  const projectId = useParams().projectId;
  const { setActiveMenu } = useMenuStore((state) => ({
    setActiveMenu: state.setActiveMenu,
  }));

  const basePath = useAISceneOrImagePath();

  const navigateToProjectCreatePage = () => {
    navigate(`/${basePath}/${projectId}`);
    setActiveMenu(AiSceneSideMenus.CREATE_TOOL);
  };

  const filterItems = useMemo(
    () =>
      EDIT_MENU_ITEMS.map((menuItem) => {
        const isDisabled =
          menuItem?.isDisable ||
          isProcessing ||
          (menuItem.menuType === EditToolMenuTypes.RESTORE && !hasProduct);
        if (menuItem.menuType === EditToolMenuTypes.REPAIR) {
          return <RepairCanvasImageQuality key={menuItem.id} />;
        }
        const menuItemContent = (
          <PlanGuard
            key={menuItem.id}
            requiredPlan={menuItem.requiredPlan || 'free'}
            featureDescription={menuItem.featureDescription || ''}
            contentClassName='!w-auto'
          >
            <div
              key={menuItem.name}
              className={`group flex flex-col items-center justify-between gap-2 rounded-[9px] border-2 p-[6px] transition-colors duration-200 ${
                isDisabled
                  ? '!cursor-not-allowed opacity-50'
                  : 'cursor-pointer hover:border-black/60'
              }`}
              onClick={
                !isDisabled
                  ? () => menuItem.menuType && setMenu(menuItem.menuType as EditToolMenuType)
                  : undefined
              }
            >
              <div className='h-[120px] w-[120px] overflow-hidden rounded-[3px]'>
                <img
                  src={menuItem.img}
                  alt={menuItem.name}
                  className='h-full w-full object-cover'
                />
              </div>
              <p className='text-[13px] font-semibold'>{menuItem.name}</p>
            </div>
          </PlanGuard>
        );

        if (menuItem.menuType === EditToolMenuTypes.CROP) {
          return (
            <TooltipItem side='bottom' trigger={menuItemContent} key={menuItem.name}>
              Coming Soon
            </TooltipItem>
          );
        }

        return menuItemContent;
      }),
    [setMenu, isProcessing, isAiImageType, hasProduct],
  );

  return (
    <div className='flex h-full w-[320px] flex-col gap-4 rounded-lg bg-white p-4 pb-0 shadow-md'>
      <h2
        onClick={navigateToProjectCreatePage}
        className='flex h-9 cursor-pointer gap-2 border-b border-[#EAEAEA] text-[15px] font-semibold'
      >
        <ArrowLeftIcon /> Edit
      </h2>
      <ScrollArea className='-mr-2 pr-3'>
        <div className='flex w-full flex-wrap justify-between gap-2 pb-2'>
          <EnhanceCanvasImageQuality />
          {filterItems}
          <BackgroundRemoveCanvasImage />
        </div>
      </ScrollArea>
    </div>
  );
};
