import { AiSceneReview } from 'components/ai-scene/review/AiSceneReview';
import { Navigate, useParams } from 'react-router';
import { useAISceneOrImagePath } from '../useAISceneOrImagePath';

export const AiSceneReviewPage = () => {
  const projectId = useParams().projectId;
  const basePath = useAISceneOrImagePath();

  return <Navigate to={`/${basePath}/${projectId}`} replace />;

  return <AiSceneReview />;
};
