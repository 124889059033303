import { createContext, useContext } from 'react';
import { createBulkProductsStore, TBulkProductsStore } from './store';
import { useStoreWithEqualityFn } from 'zustand/traditional';

export type TBulkProductsContext = ReturnType<typeof createBulkProductsStore>;

export const BulkProductsContext = createContext<TBulkProductsContext | undefined>(undefined);

export const useBulkProductsStore = <T,>(
  selector: (state: TBulkProductsStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(BulkProductsContext);
  if (!store) throw new Error('Missing BulkProductsContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
