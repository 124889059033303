import { parseError } from '@/utils/error/error';
import {
  read as XLSX_read,
  utils as XLSX_utils,
  Range,
  WorkSheet,
  writeFileXLSX as XLSX_writeFileXLSX,
} from 'xlsx';

export interface IFileUploadError {
  fileName: string;
  message: string;
}

export interface IFileReaderUploadSuccess {
  file: File;
  data: ArrayBuffer;
}

interface IFileReaderUploadSpec {
  file: File;
  onSuccess: ({ data, file }: IFileReaderUploadSuccess) => void;
  onError: ({ fileName, message }: IFileUploadError) => void;
}

export const isXlsxFile = new RegExp(/\.(xlsx)$/i);

export const getExcelFileArrayBuffer = ({ file, onSuccess, onError }: IFileReaderUploadSpec) => {
  const fileName = file.name;
  if (!isXlsxFile.test(fileName))
    return onError({
      fileName,
      message: 'Only .xlsx files are supported.',
    });

  const reader = new FileReader();
  reader.onerror = (err) =>
    onError({
      fileName,
      message: `Error occurred reading file. Error: ${err}.`,
    });
  reader.onload = ({ target }) => {
    if (!target) {
      return onError({
        fileName,
        message:
          'Input value might be resetted while file was still loading, please contact the team if it happens again.',
      });
    }
    if (!target.result || typeof target.result == 'string') {
      return onError({
        fileName,
        message: `File type is not ArrayBuffer. File: ${target.result}`,
      });
    }

    onSuccess({
      file,
      data: target.result,
    });
  };
  reader.readAsArrayBuffer(file);
};

export const BULK_PRODUCT_EXCEL_TITLES = ['SKU', 'Name', 'Image'] as const;
export type TBulkProductExcel = Record<(typeof BULK_PRODUCT_EXCEL_TITLES)[number], string>;

const isHeaderRow = (row: any) =>
  BULK_PRODUCT_EXCEL_TITLES.every((column) => row[column] === column);

export const separateProductRecordsFromExcelHeader = (
  rows: any[],
): {
  records: TBulkProductExcel[];
  headerIndex: number;
  headerRow: Record<string, any> | undefined;
} => {
  const headerIndex = rows.findIndex(isHeaderRow);
  const records = rows.slice(headerIndex + 1);

  return {
    records,
    headerIndex,
    headerRow: rows[headerIndex],
  };
};

export const hasCorrectHeaderRow = (
  currentHeaders: readonly string[],
  newHeaderRow: Record<string, any> | undefined,
) => {
  if (!newHeaderRow) return false;

  const expectedHeaders = currentHeaders
    .filter((h) => h)
    .sort()
    .toString();
  const newHeaders = Object.values(newHeaderRow)
    .filter((h) => h)
    .sort()
    .toString();

  return JSON.stringify(expectedHeaders) === JSON.stringify(newHeaders);
};

// Note: XLSX stopped publishing on NPM since v0.18.5. https://github.com/SheetJS/sheetjs/issues/2667
// To further improve performance and bug fixes, consider using the latest version from the official repository.
export const convertExcelBufferToRecords = (
  data: ArrayBuffer,
  orderedHeaders: string[] | readonly string[],
) => {
  try {
    const workbook = XLSX_read(data, {
      type: 'buffer',
    });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = XLSX_utils.sheet_to_json(sheet, {
      // To keep column names with empty values. They're removed by default.
      defval: '',
      // It's needed to avoid preceding/trailing spaces (/t) around the cell for some users.
      raw: true,
      header: orderedHeaders as string[],
    }) as any[];
    // Remove rows with empty values manually since "blankrows" option is not working.
    const rowsWithAtLeastOneValue = rows.filter((row) => Object.values(row).some(Boolean));
    return rowsWithAtLeastOneValue;
  } catch (err) {
    throw new Error(`Failed to convert the file. ${err}`);
  }
};

type ExcelMismatchCauseType = 'mandatoryField' | 'invalidNumber';
const _getMismatchMessage = (
  lineNo: string,
  causeType: ExcelMismatchCauseType,
  customMessage?: string,
) => {
  const customText = customMessage || '';
  switch (causeType) {
    case 'mandatoryField': {
      return `Line ${lineNo} is missing mandatory values.${customText}`;
    }
    default:
      throw new Error('Invalid mismatch cause type.');
  }
};

const _getMismatchLineNo = (index: number, headerIndex: number) => {
  // Add buffer for the header and for actual row given indices start from 0.
  // E.g., header is found at 0th index -> row idx + 0(header) + 2(buffer for both indices)
  return (index + headerIndex + 2).toString().padStart(2, '0');
};

const _hasMandatoryValues = (mandatoryValues: string[]) =>
  mandatoryValues.every((v) => typeof v !== 'undefined' && v !== '');

const MAX_MISMATCH_COUNT_TO_SHOW = 20;

export const getProductExcelMismatchCauses = ({
  records,
  headerIndex,
}: {
  records: TBulkProductExcel[];
  headerIndex: number;
}) => {
  const mismatchCauses: string[] = [];
  const recordsLength = records.length;

  for (let idx = 0; idx < recordsLength; idx++) {
    // Limit the number of mismatch causes to keep the processing fast.
    if (mismatchCauses.length > MAX_MISMATCH_COUNT_TO_SHOW) break;

    const lineNo = _getMismatchLineNo(idx, headerIndex);
    const record = records[idx];

    if (!_hasMandatoryValues([record['SKU'], record['Name'], record['Image']])) {
      mismatchCauses.push(
        _getMismatchMessage(lineNo, 'mandatoryField', ` Expected values are SKU, Name, and Image.`),
      );
    }
  }

  return mismatchCauses;
};

interface IExportToExcelProps<T> {
  data: T[][];
  boilerplateData?: T[][];
  excelTitle: string;
  fileName?: string;
  mergedCells?: Range[];
}

interface IExportResultSpec {
  onSuccess?: () => void;
  onError?: (message: string) => void;
}

export const exportAsXLSX = <T>({
  data,
  excelTitle,
  fileName,
  boilerplateData,
  mergedCells,
  onSuccess,
  onError,
}: IExportToExcelProps<T> & IExportResultSpec) => {
  try {
    let worksheet: WorkSheet;

    if (boilerplateData && boilerplateData.length > 0) {
      worksheet = XLSX_utils.aoa_to_sheet(boilerplateData, {
        sheetStubs: true,
      });
      XLSX_utils.sheet_add_aoa(worksheet, data, {
        // Append to bottom of worksheet starting on first column
        origin: -1,
        sheetStubs: true,
      });
    } else {
      worksheet = XLSX_utils.aoa_to_sheet(data, { sheetStubs: true });
    }

    if (mergedCells) {
      worksheet['!merges'] = worksheet['!merges'] || [];
      mergedCells.forEach((m) => worksheet['!merges'] && worksheet['!merges'].push(m));
    }

    const workbook = XLSX_utils.book_new();
    // TODO: Add a way to change the sheet name.
    XLSX_utils.book_append_sheet(workbook, worksheet, excelTitle);
    XLSX_writeFileXLSX(workbook, fileName || 'pricelist.xlsx');
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError(parseError(error).message);
  }
};
