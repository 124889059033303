import { applyCustomCursor } from 'components/ai-scene/review-edit/actions/helper';
import { useEffect } from 'react';

export const cancelDrawingMode = (canvasInstance: fabric.Canvas | null) => {
  if (!canvasInstance) return;

  (canvasInstance as any).isDrawingMode = false;
  applyCustomCursor(canvasInstance, 'default');
};

export const useEscToCancelDrawingMode = (canvasInstance: fabric.Canvas | null) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        cancelDrawingMode(canvasInstance);
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [canvasInstance]);
};
