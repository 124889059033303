import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import React from 'react';
import { LogoView } from './LogoView';
import { FontsView } from './FontsView';
import { ColorsView } from './ColorsView';
import { useBrandSectionQueryParams } from '../assets/useBrandSectionQueryParams';

const BrandKitSection = {
  Logo: 'logo',
  Fonts: 'fonts',
  Colors: 'colors',
} as const;

export const BrandKitView = () => {
  const contentWrapperRef = React.useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = React.useState<'logo' | 'fonts' | 'colors'>('logo');
  const { closeSection } = useBrandSectionQueryParams();

  const visibleSection =
    activeSection === 'logo' ? (
      <LogoView />
    ) : activeSection === 'fonts' ? (
      <FontsView />
    ) : (
      <ColorsView />
    );

  return (
    <div className='relative size-full px-10 py-6'>
      <div className='absolute inset-0' onClick={closeSection} />
      <div
        ref={contentWrapperRef}
        className='relative flex h-full justify-center overflow-y-auto rounded-lg bg-white p-6'
      >
        <span
          className='i-mdi-close absolute right-6 top-6 size-6 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            closeSection();
          }}
        />
        <div className='w-full max-w-[700px]'>
          <h3 className='text-center text-xl'>BrandKit Library</h3>
          <Separator className='mb-8 mt-4' />
          <div className='flex justify-center gap-2'>
            {Object.values(BrandKitSection).map((section) => (
              <Button
                key={section}
                onClick={() => {
                  setActiveSection(section);
                }}
                className='h-[40px] max-w-[200px] flex-1 rounded-lg capitalize'
                variant={activeSection === section ? 'default' : 'outline'}
              >
                {section}
              </Button>
            ))}
          </div>
          {visibleSection}
          <Button className='w-full'>Continue</Button>
        </div>
      </div>
    </div>
  );
};
