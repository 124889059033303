import { cn } from '@/lib/utils';
import { useLocation, useNavigate } from 'react-router';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { BrandLibraryPaths, RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { Table, TableBody, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { REVIEW_DATA } from './review.data';
import { getPreviousPath } from '@/utils/helper';

const HEAD_CL =
  'border border-gray-200 border-collapse rounded-lg text-crait-primary font-medium h-16 whitespace-nowrap';
const CELL_CL =
  'border border-gray-200 border-collapse rounded-lg text-crait-primary font-normal h-14 text-sm';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125';

export const AiBannerReview = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <main className={cn(`h-full w-full p-6`)}>
      <div className='mb-3'>
        <div className='flex justify-between'>
          <Button
            className='flex gap-2 bg-white text-black'
            onClick={() => {
              const previousPath = getPreviousPath(pathname);
              navigate(previousPath);
            }}
          >
            <IconWrapper
              wrapperProps={{
                className: ICON_WRAPPER_CL,
              }}
              iconClass='i-mdi-arrow-left h-5 w-5'
            />
            Back
          </Button>
          <div className='flex gap-2'>
            <Button className='rounded-xl text-xs' variant='ghost'>
              Download Excel Format
            </Button>
            <Button className='rounded-xl px-8 text-xs'>Import File</Button>
          </div>
        </div>
      </div>
      <Table className='mx-auto max-w-[725px] rounded-lg bg-white p-2 text-crait-primary'>
        <TableHeader>
          <TableRow>
            <TableHead className={HEAD_CL}>Banner Template</TableHead>
            <TableHead className={HEAD_CL}>Layout Size</TableHead>
            <TableHead className={HEAD_CL}>Main Text</TableHead>
            <TableHead className={HEAD_CL}>Body Text</TableHead>
            <TableHead className={HEAD_CL}>Image 1</TableHead>
            <TableHead className={HEAD_CL}>Image 2</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {REVIEW_DATA.map((product) => (
            <TableRow key={product.id}>
              <TableHead className={CELL_CL}>{product.bannerTemplate}</TableHead>
              <TableHead className={CELL_CL}>{product.layoutSize}</TableHead>
              <TableHead className={CELL_CL}>{product.mainText}</TableHead>
              <TableHead className={CELL_CL}>{product.bodyText}</TableHead>
              <TableHead className={CELL_CL}>{product.image1}</TableHead>
              <TableHead className={CELL_CL}>{product.image2}</TableHead>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className='flex justify-center'>
        <Button
          className='mt-12 flex w-[164px] rounded-xl text-sm'
          onClick={() => {
            navigate(
              `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}/${BrandLibraryPaths.BANNER}`,
            );
          }}
        >
          Generate
        </Button>
      </div>
    </main>
  );
};
