import { getRandomImg } from '@/utils/helper';

export const REVIEW_DATA = [
  {
    id: 1,
    bannerTemplate: 'review',
    layoutSize: 'small',
    mainText: 'Main Text',
    bodyText: 'Body Text',
    image1: getRandomImg(66, 15),
    image2: getRandomImg(66, 15),
  },
  {
    id: 2,
    bannerTemplate: 'review',
    layoutSize: 'medium',
    mainText: 'Main Text',
    bodyText: 'Body Text',
    image1: getRandomImg(66, 15),
    image2: getRandomImg(66, 15),
  },
  {
    id: 3,
    bannerTemplate: 'review',
    layoutSize: 'large',
    mainText: 'Main Text',
    bodyText: 'Body Text',
    image1: getRandomImg(66, 15),
    image2: getRandomImg(66, 15),
  },
  {
    id: 4,
    bannerTemplate: 'review',
    layoutSize: 'small',
    mainText: 'Main Text',
    bodyText: 'Body Text',
    image1: getRandomImg(66, 15),
    image2: getRandomImg(66, 15),
  },
  {
    id: 5,
    bannerTemplate: 'review',
    layoutSize: 'medium',
    mainText: 'Main Text',
    bodyText: 'Body Text',
    image1: getRandomImg(66, 15),
    image2: getRandomImg(66, 15),
  },
] as const;
