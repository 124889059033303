import { Input } from 'components/ui/input';
import { ReactNode, useRef, useState } from 'react';
import {
  TColorResponse,
  useRemoveColorMutation,
} from '@/components/workspace/brand-library/brand-kit/queries';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { BaseColorPicker } from 'components/workspace/brand-library/brand-kit/color/BaseColorPicker';
import { toast } from 'sonner';
import { validHex } from '@uiw/color-convert';
import { hexToHexa } from '@/utils/helper';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import TrashIcon from '@/assets/icons/trash01.svg?react';
import './colorPickerStyles.css';
import { formatHex, isValidAlpha } from 'components/workspace/brand-library/brand-kit/color/helper';

interface ColorDropdownProps {
  children: ReactNode;
  onSaveClicked: (params: { name: string; hexCode: string; opacity: number }) => Promise<void>;
  refetch: () => void;
}

const BASE_COLOR = '#000000';

export const ColorDropdown = (props: Partial<TColorResponse> & ColorDropdownProps) => {
  const colorCircleRef = useRef<HTMLSpanElement | null>(null);
  const colorHexRef = useRef<HTMLInputElement | null>(null);
  const colorPercentRef = useRef<HTMLInputElement | null>(null);
  const colorNameRef = useRef<HTMLInputElement | null>(null);
  const [customColor, setCustomColor] = useState({
    hex: props.hexCode || BASE_COLOR,
    alpha: props.opacity || 100,
  });
  const [status, setStatus] = useState<'idle' | 'pending'>('idle');
  const removeColorMutation = useRemoveColorMutation();

  const controlledHexa = hexToHexa(customColor.hex, customColor.alpha / 100);

  const handleDeleteClick = async () => {
    if (!props.id) {
      return;
    }

    try {
      await removeColorMutation.mutateAsync({ id: props.id });
      props.refetch();
      toast.success('Color removed successfully');
    } catch (error) {
      console.error('Failed to remove color', error);
      toast.error('Failed to remove color');
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='flex flex-col gap-1 outline-none' asChild>
        <div>{props.children}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className=' w-[284px] p-4' align='end' side='right'>
        <div className='w-full'>
          <div className='flex flex-col gap-5'>
            <div className='flex items-center justify-center gap-3'>
              <Input
                ref={colorNameRef}
                id='color-name'
                placeholder='Name the color'
                className='h-8 truncate bg-transparent text-sm'
                defaultValue={props.name}
              />
              <DropdownMenuItem className='p-0'>
                <TrashIcon
                  className='cursor-pointer fill-white stroke-[#777777]'
                  onClick={handleDeleteClick}
                />
              </DropdownMenuItem>
            </div>

            <div className='flex w-full flex-col items-center gap-2'>
              <span
                ref={colorCircleRef}
                className='size-0 rounded-full border border-slate-200'
                style={{
                  backgroundColor: controlledHexa,
                }}
              />
              <BaseColorPicker
                controlledHexa={controlledHexa}
                onColorChange={(hex, alpha, hexa) => {
                  if (!colorCircleRef.current || !colorHexRef.current || !colorPercentRef.current) {
                    return;
                  }

                  colorCircleRef.current.style.backgroundColor = hexa;
                  colorHexRef.current.value = hex;
                  colorPercentRef.current.value = `${Math.round(alpha * 100)}`;
                }}
              />
            </div>
            <div className='flex w-full gap-3'>
              <div className='flex w-2/3 flex-col gap-3'>
                <Label htmlFor='hex-color'>Hex</Label>
                <Input
                  ref={colorHexRef}
                  id='hex-color'
                  placeholder='Enter the hex'
                  className='h-8 truncate bg-transparent text-sm'
                  defaultValue={customColor.hex}
                  onChange={(e) => {
                    const hex = e.target.value;
                    if (!validHex(hex)) return;
                    const alpha = colorPercentRef.current?.value || 1;

                    setCustomColor({
                      hex,
                      alpha: Number(alpha),
                    });
                  }}
                />
              </div>
              <div className='flex w-1/3 flex-col gap-3'>
                <Label htmlFor='opacity-color'>Opacity</Label>
                <Input
                  ref={colorPercentRef}
                  id='opacity-color'
                  className='h-8 truncate bg-transparent text-sm'
                  value={customColor.alpha}
                  onChange={(e) => {
                    const alpha = e.target.value;
                    if (!isValidAlpha(alpha)) {
                      toast.error('Invalid alpha value.');
                      return;
                    }
                    const hex = colorHexRef.current?.value;
                    if (!hex || !validHex(hex)) return;

                    setCustomColor({
                      hex,
                      alpha: Number(alpha),
                    });
                  }}
                />
              </div>
            </div>
            <Separator />
            <DropdownMenuItem className='w-full px-0 hover:bg-inherit focus:bg-inherit'>
              <div className='flex w-full flex-col gap-1.5 '>
                <Button
                  className='w-full rounded-lg'
                  onClick={async () => {
                    try {
                      if (
                        status === 'pending' ||
                        !colorHexRef.current ||
                        !colorPercentRef.current ||
                        !colorNameRef.current
                      ) {
                        return;
                      }

                      const hex = colorHexRef.current.value;
                      const alpha = colorPercentRef.current.value;

                      if (!validHex(hex)) {
                        toast.error('Invalid hex value.');
                        return;
                      }

                      if (!isValidAlpha(alpha)) {
                        toast.error('Invalid alpha value.');
                        return;
                      }

                      const possiblyFormattedHex = formatHex(hex);

                      await props.onSaveClicked({
                        name: colorNameRef.current.value,
                        hexCode: possiblyFormattedHex,
                        opacity: Number(alpha),
                      });
                    } catch (_) {
                      toast.error('Failed to update color');
                    } finally {
                      setStatus('idle');
                    }
                  }}
                  disabled={status === 'pending'}
                >
                  Save
                </Button>
              </div>
            </DropdownMenuItem>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
