import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';

interface Onboarding {
  status: string;
}

const completeOnboarding = async (orgId: number) => {
  return apiClient.post<Onboarding>(
    getAccountApiUrl(orgId, AccountEndpoint.POST_COMPLETE_ONBOARDING),
  );
};

export const useCompleteOnboarding = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.POST_COMPLETE_ONBOARDING, organizationId }],
    mutationFn: () => {
      return completeOnboarding(organizationId);
    },
  });
};
