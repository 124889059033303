import { Button } from 'components/ui/button';
import { ModalView } from 'components/common/ModalView';
import { Separator } from 'components/ui/separator';
import { useSendContactUsMessage } from '../queries';
import { toast } from 'sonner';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';

const contactFormSchema = z.object({
  name: z
    .string()
    .max(63, 'Name must be at most 63 characters long.')
    .min(1, 'Name is required.')
    .trim(),
  email: z.string().email('Invalid email address.').min(1, 'Email is required.'),
  message: z.string().min(1, 'Message is required.').trim(),
  companyName: z.string().trim().optional(),
  phoneNumber: z
    .string()
    .max(50, 'Phone number must be at most 50 characters long.')
    .trim()
    .optional()
    .refine((value) => !value || /^\+?[1-9]\d{1,14}$/.test(value), {
      message: 'Invalid phone number format.',
    }),
});

const CRequired = () => {
  return <span className='text-red-500'>*</span>;
};

export const ContactUsModal = () => {
  const contactUsMutation = useSendContactUsMessage();
  const form = useForm<z.infer<typeof contactFormSchema>>({
    resolver: zodResolver(contactFormSchema),
  });

  const onSubmitMessage = async (
    values: z.infer<typeof contactFormSchema>,
    closeModal: () => void,
  ) => {
    try {
      await contactUsMutation.mutateAsync(values);
      closeModal();
      toast.success('Message sent successfully. We will get back to you soon!');
    } catch (error) {
      toast.error('Failed to send message');
    }
  };

  return (
    <ModalView
      triggerClassName='w-auto'
      trigger={
        <Button
          size='sm'
          className='relative h-[44px] w-full rounded-[10px] bg-crait-green-primary text-[14px]  font-semibold text-c-dark'
        >
          Contact us
        </Button>
      }
      titleElement={<h4 className='text-lg font-medium'>Contact Us</h4>}
      onOpenChange={(isOpen) => {
        if (!isOpen) form.reset();
      }}
    >
      {({ closeModal }) => (
        <Form {...form}>
          <form onSubmit={form.handleSubmit((values) => onSubmitMessage(values, closeModal))}>
            <Separator className='mb-4' />
            <div className='mx-auto mb-6 flex w-96 flex-col gap-2'>
              <FormField
                control={form.control}
                name='name'
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormLabel className='text-sm text-crait-primary'>
                      Full Name
                      <CRequired />
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder='Enter your full name'
                        className='rounded-lg bg-background'
                        {...field}
                      />
                    </FormControl>
                    {error && <FormMessage>{error.message}</FormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='email'
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormLabel className='text-sm text-crait-primary'>
                      Email
                      <CRequired />
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder='Enter your email'
                        className='rounded-lg bg-background'
                        autoCapitalize='none'
                        {...field}
                      />
                    </FormControl>
                    {error && <FormMessage>{error.message}</FormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='companyName'
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormLabel className='text-sm text-crait-primary'>Company Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder='Enter your company name'
                        className='rounded-lg bg-background'
                        {...field}
                      />
                    </FormControl>
                    {error && <FormMessage>{error.message}</FormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='phoneNumber'
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormLabel className='text-sm text-crait-primary'>Phone Number</FormLabel>
                    <FormControl>
                      <Input
                        placeholder='Enter your phone number'
                        className='rounded-lg bg-background'
                        {...field}
                      />
                    </FormControl>
                    {error && <FormMessage>{error.message}</FormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='message'
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormLabel className='text-sm text-crait-primary'>
                      Message
                      <CRequired />
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder='Enter your message'
                        className='h-full w-full resize-none rounded-lg bg-background'
                        {...field}
                      />
                    </FormControl>
                    {error && <FormMessage>{error.message}</FormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className='mx-auto flex w-72 flex-col gap-1'>
              <Button
                className=' w-full bg-crait-green-primary text-c-dark'
                disabled={contactUsMutation.isPending}
              >
                Send
              </Button>
              <Button variant='outline' className='w-full' onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </form>
        </Form>
      )}
    </ModalView>
  );
};
