import { DashboardPeople } from 'components/dashboard/people/DashboardPeople';
import { PeopleProvider } from './PeopleProvider';
import { PlanProtectedRoute } from 'components/plan-guard/PlanProtectedRoute';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';

export const DashboardPeoplePage = () => {
  return (
    <PlanProtectedRoute requiredPlan={FeatureAccessRequirements.INVITE_MEMBERS.requiredPlan}>
      <PeopleProvider>
        <DashboardPeople />
      </PeopleProvider>
    </PlanProtectedRoute>
  );
};
