import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { useEffect, useRef, useState, forwardRef } from 'react';
import {
  createAndInsertSpan,
  ensureSelectionWithinElement,
  moveCursorToEndOfContent,
  moveCursorAfterSpan,
} from './helper';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useAutoCompleteMutation } from '../helper';
import { DrawnProductPrefixes } from './DrawnProductPrefixes';
import { toast } from 'sonner';

import CompletePromptIcon from '@/assets/icons/ai-scene-icons/completePrompt.svg?react';
import RegeneratePrompIcon from '@/assets/icons/ai-scene-icons/regeneratePropmt.svg?react';
import DeletePromptIcon from '@/assets/icons/ai-scene-icons/deletePrompt.svg?react';
import AcceptPromptIcon from '@/assets/icons/ai-scene-icons/acceptPrompt.svg?react';

const PROMPT_PREFIX_CL =
  'relative z-[11] cursor-pointer flex gap-1 items-center justify-center rounded-md h-5 whitespace-pre-line border border-black px-2 py-1 mr-1 mb-0.5 inline-flex leading-none z-10 cursor-pointer';

const UNDERLINED_PROMPT_CL = 'underline';

export const MainPromptInput = forwardRef<HTMLDivElement, { isDisabled: boolean }>(
  ({ isDisabled }, ref) => {
    const prompt = useAiSceneCreateStore((state) => state.aiScenePromptTab.prompt);
    const updateAiScenePromptTab = useAiSceneCreateStore((state) => state.updateAiScenePromptTab);
    const editorRef = useRef<HTMLDivElement | null>(null);
    const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
    const { mutateAsync } = useAutoCompleteMutation();
    const [isGenerating, setIsGenerating] = useState(false);
    const [editorContent, setEditorContent] = useState(prompt || '');
    const [productCount, setProductCount] = useState(0);
    const [promtAction, setPromptAction] = useState(false);
    const [addedPromptNodes, setAddedPromptNodes] = useState<Node[]>([]);

    const insertSpanAtCursor = (spanText: string) => {
      const contentEle = editorRef.current;
      if (!contentEle || !contentEle.contentEditable) return;

      const selection = window.getSelection();
      if (!selection) return;

      ensureSelectionWithinElement(selection, contentEle);
      moveCursorAfterSpan(selection, PROMPT_PREFIX_CL);
      createAndInsertSpan(selection, spanText, PROMPT_PREFIX_CL);
      saveContent();
    };

    const createUnderlinedTextNode = (text: string) => {
      const span = document.createElement('span');
      span.className = UNDERLINED_PROMPT_CL;
      span.textContent = text;
      return span;
    };

    const generateSurprisePrompt = async () => {
      const editor = editorRef.current;
      if (!editor) return;
      setIsGenerating(true);

      const drawedProductIds =
        canvasInstance
          ?.getObjects()
          .filter((obj) => obj.imageType === 'product' && 'imageId' in obj)
          .map((obj) => obj.imageId as string) || [];

      try {
        const newPrompt = await mutateAsync({
          prompt: editor.textContent || '', // Only send text content
          productIds: drawedProductIds,
        });

        editor.contentEditable = 'false';
        editor.style.pointerEvents = 'none';
        const words = newPrompt.split(' ');
        let wordIndex = 0;
        const newNodes: Node[] = [];

        const intervalId = setInterval(() => {
          if (wordIndex >= words.length) {
            clearInterval(intervalId);
            editor.contentEditable = 'true';
            editor.style.pointerEvents = 'auto';
            saveContent();
            setIsGenerating(false); // Enable button
            setAddedPromptNodes(newNodes);
            return;
          }

          const word = words[wordIndex++] + ' ';
          const selection = window.getSelection();

          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const underlinedTextNode = createUnderlinedTextNode(word);

            // If the selection is within the editor, insert the word at the cursor. Otherwise, append to the end.
            if (editor.contains(range.commonAncestorContainer)) {
              range.insertNode(underlinedTextNode);
              range.collapse(false);
            } else {
              editor.appendChild(underlinedTextNode);
            }

            // Ensure the editor is focused and the selection is updated
            editor.focus();
            selection.removeAllRanges();
            selection.addRange(range);

            newNodes.push(underlinedTextNode);
          } else {
            const underlinedTextNode = createUnderlinedTextNode(word);
            editor.appendChild(underlinedTextNode);
            newNodes.push(underlinedTextNode);
          }
        }, 150);
        setPromptAction(true);
      } catch (error) {
        toast.error('Failed generating prompt');
        setIsGenerating(false);
      }
    };

    const deleteAddedPrompt = () => {
      const editor = editorRef.current;
      if (!editor) return;

      addedPromptNodes.forEach((node) => {
        if (editor.contains(node)) {
          editor.removeChild(node);
        }
      });
      setAddedPromptNodes([]);
      saveContent();
      setPromptAction(false);
    };

    const saveContent = () => {
      const editor = editorRef.current;
      if (!editor) return;

      const newContent = editor.innerText; // Save only the text content
      if (newContent === prompt) return;
      updateAiScenePromptTab({ prompt: newContent });
      setEditorContent(newContent);
    };

    // Save the prompt when the component unmounts instead of on every change.
    useEffect(() => {
      const editor = editorRef.current;

      return () => {
        if (!editor) return;
        updateAiScenePromptTab({ prompt: editor.innerText }); // Save only the text content
      };
    }, [updateAiScenePromptTab]);

    // Sync prompt with store when the prompt changes by other means. (i.e. Recommended Prompt)
    useEffect(() => {
      const editor = editorRef.current;
      if (!editor || editor.innerText === prompt) return;

      editor.innerText = prompt || ''; // Load only the text content
      setEditorContent(prompt || '');

      const attachEventListeners = () => {
        const spans = editor.querySelectorAll('span');
        spans.forEach((span) => {
          span.addEventListener('click', () => {
            const parentNode = span.parentNode;
            if (parentNode) parentNode.removeChild(span);
            saveContent();
          });
        });
      };
      attachEventListeners();
    }, [prompt, updateAiScenePromptTab]);

    return (
      <div
        className={`relative mb-3 flex h-[172px] flex-col gap-2 rounded-md border p-2 md:h-[260px] ${
          isDisabled ? 'cursor-no-drop opacity-50' : ''
        }`}
      >
        <Label
          className={`absolute  right-0 top-0 z-[1] h-full w-full bg-transparent text-xs leading-none  ${
            isDisabled ? 'cursor-no-drop opacity-50' : ''
          }`}
          onClick={() => {
            if (isDisabled) {
              return;
            }
            const editor = editorRef.current;
            if (!editor) return;

            editor.focus();
            moveCursorToEndOfContent(editor);
          }}
        />
        <div
          className={`relative h-full overflow-hidden overflow-y-auto ${
            isDisabled ? 'cursor-no-drop opacity-50' : ''
          }`}
        >
          <DrawnProductPrefixes setProductsCount={setProductCount} />
          <div
            ref={editorRef}
            onInput={() => {
              setEditorContent(editorRef.current?.innerHTML || '');
              saveContent();
            }}
            contentEditable={!isDisabled || isGenerating}
            role='textbox'
            aria-multiline='true'
            className={`selectable-text relative z-10 inline min-h-16 w-full resize-none overflow-y-auto whitespace-pre-wrap text-wrap break-all border border-none bg-transparent p-0 text-xs leading-5 outline-none focus-visible:ring-0 focus-visible:ring-offset-0 ${
              isGenerating ? 'cursor-no-drop opacity-50' : ''
            }`}
          />
        </div>
        {(editorContent.trim() === '' && productCount === 0) || promtAction ? null : (
          <div className='flex justify-end'>
            <Button
              onClick={generateSurprisePrompt}
              disabled={isDisabled || isGenerating}
              size='sm'
              className='rounded-1 z-10 flex w-full items-center justify-start gap-1.5 border bg-white p-2 text-main leading-none'
            >
              <CompletePromptIcon />
              <span className='text-nowrap'>Complete my prompt</span>
            </Button>
          </div>
        )}
        {promtAction === false ? null : (
          <div className='-mb-6 flex flex-col justify-end gap-1 rounded-[10px] border border-[#EAEAEA] bg-white p-1'>
            <Button
              onClick={() => {
                setPromptAction(false);
                const editor = editorRef.current;
                if (!editor) return;

                editor.innerText = prompt || '';
                moveCursorToEndOfContent(editor);
              }}
              disabled={isDisabled || isGenerating}
              size='sm'
              className='rounded-1 z-10 flex w-full items-center justify-start gap-1.5 border bg-white p-2 text-main leading-none'
            >
              <AcceptPromptIcon />
              <span className='text-nowrap'>Accept Prompt</span>
            </Button>{' '}
            <Button
              onClick={() => {
                setPromptAction(false);
                deleteAddedPrompt();
                generateSurprisePrompt();
              }}
              disabled={isDisabled || isGenerating}
              size='sm'
              className='rounded-1 z-10 flex w-full items-center justify-start gap-1.5 border bg-white p-2 text-main leading-none'
            >
              <RegeneratePrompIcon />
              <span className='text-nowrap'>Regenerate with AI</span>
            </Button>{' '}
            <Button
              onClick={deleteAddedPrompt}
              disabled={isDisabled || isGenerating}
              size='sm'
              className='rounded-1 z-10 flex w-full items-center justify-start gap-1.5 border bg-white p-2 text-main leading-none'
            >
              <DeletePromptIcon />
              <span className='text-nowrap'>Delete Prompt</span>
            </Button>
          </div>
        )}
      </div>
    );
  },
);

MainPromptInput.displayName = 'MainPromptInput';
