import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import {
  useEnhanceSceneQualityMutation,
  useGetEditProcessStatusMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { parseImageWithFabric } from 'components/ai-scene/create/editor/canvas/helper';
import { nanoid } from 'nanoid';
import { serializeCanvas } from '@/utils/helper';
import { toast } from 'sonner';
import { PROCESSING_STATUSES } from 'components/ai-scene/review/queries';
import { useParams } from 'react-router';
import { useSaveUserInteraction } from 'components/ai-scene/review-edit/canvas/useSaveUserInteraction';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import EnhanceQualtyImage from 'src/assets/images/edit-menu/enhanceQualty.png';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';

export const EnhanceCanvasImageQuality = () => {
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const { mutateAsync: enhanceSceneQualityMutateAsync } = useEnhanceSceneQualityMutation();
  const { mutate: getEnhanceSceneStatusMutate } = useGetEditProcessStatusMutation();
  const { projectId, sceneId } = useParams<{ projectId: string; sceneId: string }>();
  const { saveCanvasProgressImmidiately } = useSaveUserInteraction();
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const processingState = useAiSceneResultStore((state) => state.processingState);
  const { refetchScenes } = useRefetchGeneratedScenes();

  const enhanceQuality = async () => {
    try {
      if (!canvasInstance) return;
      if (!sceneId || !projectId) {
        toast.error('Failed to enhance image quality. Scene or project not found.');
        return;
      }
      changeProcessingState('enhancing');
      await saveCanvasProgressImmidiately(canvasInstance);
      const enhanceResponse = await enhanceSceneQualityMutateAsync({
        sceneId,
        projectId,
      });
      const pollEnhanceStatus = async () => {
        getEnhanceSceneStatusMutate(
          {
            sceneId,
            projectId,
            versionId: enhanceResponse.versionId,
          },
          {
            onSuccess: async (data) => {
              if (PROCESSING_STATUSES.includes(data.status)) {
                setTimeout(pollEnhanceStatus, 1000);
                return;
              }
              if (data.status !== 'ready') {
                toast.error('Failed to enhance scene. Please try again.');
                changeProcessingState('idle');
                return;
              }

              const { image: imageObject } = await parseImageWithFabric({
                id: nanoid(),
                image: data.imageUrl,
                type: 'template',
                imageName: 'enhanced-scene',
                maxHeight: canvasInstance.getHeight(),
                maxWidth: canvasInstance.getWidth(),
                placementBuffer: 0,
              });

              imageObject.set({
                evented: false,
                selectable: false,
                isSelectionIgnored: true,
              });
              canvasInstance.clear();
              canvasInstance.insertAt(imageObject, 0, false);
              canvasInstance.requestRenderAll();
              updateCanvasState(serializeCanvas(canvasInstance));
              changeProcessingState('idle');
              refetchScenes();
            },
            onError: (err) => {
              console.error(err);
              toast.error('Failed to apply enhance. Please try again.');
              changeProcessingState('idle');
            },
          },
        );
      };
      pollEnhanceStatus();
    } catch (error) {
      console.error(error);
      toast.error('Failed to enhance image quality');
      changeProcessingState('idle');
    }
  };

  const checkPlanAndProceed = usePlanCheck({
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_ENHANCE_QUALITY_BUTTONS.requiredPlan,
    onClick: enhanceQuality,
    featureDescription:
      FeatureAccessRequirements.REVIEW_EDIT_ENHANCE_QUALITY_BUTTONS.featureDescription,
  });

  const isProcessing = processingState !== 'idle';

  return (
    <div
      className={`group flex  flex-col items-center justify-between gap-2 rounded-[9px] border-2 p-2 transition-colors duration-200 hover:border-black/60 ${
        isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={!isProcessing ? checkPlanAndProceed : undefined}
    >
      <div
        className={`relative h-[120px] w-[120px] overflow-hidden rounded-[3px] ${
          isProcessing ? 'opacity-50' : ''
        }`}
      >
        {processingState == 'enhancing' && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <LoadingSpinner className='text-crait-green-600' />
          </div>
        )}
        <img
          src={EnhanceQualtyImage}
          alt='adjust option'
          className={`h-full w-full object-cover`}
        />
      </div>
      <p className={`text-[13px] font-semibold ${isProcessing ? 'opacity-50' : ''}`}>
        Enhance Quality
      </p>
    </div>
  );
};
