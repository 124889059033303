import { Input, InputProps } from 'components/ui/input';
import { FC, useMemo, useRef, useState } from 'react';

interface ImageFileUploadProps {
  onUpload: (file: File | null) => void;
  clickAreaElement?: React.ReactNode;
  labelProps?: React.HTMLAttributes<HTMLLabelElement>;
  defaultImage?: string;
}

export const ImageFileUpload: FC<ImageFileUploadProps & InputProps> = ({
  onUpload,
  clickAreaElement,
  labelProps,
  defaultImage,
  ...inputProps
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      setSelectedImage(e.target?.result as string);
      onUpload(file);
      resetInput();
    };
    reader.readAsDataURL(file);
  };

  const enhancedInputProps = {
    ...inputProps,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      onFileChange(e);
      inputProps.onChange?.(e);
    },
  };

  const inputId = useMemo(
    () => enhancedInputProps.id || Math.random().toString(36).substring(2),
    [enhancedInputProps.id],
  );

  const imageSrc = selectedImage || defaultImage;

  return (
    <label htmlFor={inputId} {...labelProps}>
      <Input id={inputId} type='file' className='hidden' accept='image/*' {...enhancedInputProps} />
      {imageSrc ? (
        <img
          crossOrigin='anonymous'
          src={imageSrc}
          alt='selected image'
          className='h-full w-full object-cover'
        />
      ) : (
        clickAreaElement
      )}
    </label>
  );
};
