import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useDeleteAccountMutation } from '../helper';
import { useUserStore } from '@/providers/user/hooks';
import { localCache } from '@/utils/localStorageUtility';

import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { toast } from 'sonner';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Divider } from './ProfileEdit';

type DeleteAccountFormProps = {
  onCloseModal: () => void;
};

const loginSchema = z.object({
  password: z.string().min(6, 'Password is required.'),
});

export const DeleteAccountForm: FC<DeleteAccountFormProps> = ({ onCloseModal }) => {
  const setUser = useUserStore((state) => state.setUser);

  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  });

  const deleteAccount = useDeleteAccountMutation();

  const onSubmit = (data: z.infer<typeof loginSchema>) => {
    deleteAccount.mutate(
      { password: data.password },
      {
        onSuccess: () => {
          setUser(null);
          localCache.removeAll();
          toast.success('Account successfully deleted.');
          onCloseModal();
        },
        onError: () => {
          toast.error(`Error deleting account`);
        },
      },
    );
  };
  return (
    <div className='py-4'>
      <p className='px-4 pb-5 pt-6 text-[12px] font-normal'>
        You and your team will lose access to all content created by you. This cannot be undone.
      </p>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-2 px-4'>
            <FormField
              control={form.control}
              name='password'
              render={({ field, fieldState: { error } }) => (
                <FormItem>
                  <FormLabel>Enter your password to continue:</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Your password'
                      type='password'
                      className='h-[44px] rounded-lg bg-background'
                      {...field}
                    />
                  </FormControl>
                  {error && <FormMessage>{error.message}</FormMessage>}
                </FormItem>
              )}
            />
          </div>
          <Divider />
          <div className='flex gap-[10px] px-4'>
            <Button
              onClick={onCloseModal}
              variant='outline'
              className='h-[44px] flex-1 rounded-[10px] border-[#EAEAEA]'
            >
              Keep
            </Button>
            <Button
              type='submit'
              variant='outline'
              className='h-[44px] flex-1 rounded-[10px] border-[#E16236] text-[#E16236]'
              disabled={deleteAccount.isPending}
            >
              {deleteAccount.isPending ? 'Deleting' : 'Delete'}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
