import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { VisualGuidanceMenuContent } from './VisualGuidanceMenuContent';

export const VisualGuidanceMenu = ({ onBackClick }: { onBackClick: () => void }) => (
  <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md md:w-auto md:p-0 md:shadow-none'>
    <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold md:hidden'>
      <ArrowLeftIcon /> Visual Guidance
    </button>
    <Separator className='md:hidden' />
    <VisualGuidanceMenuContent returnCreateToolClick={onBackClick} />
  </div>
);
