import { useEffect } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { parseImageWithFabric } from './helper';
import { serializeCanvas } from '@/utils/helper';
import { fabric } from 'fabric';

const OBJECT_PLACEMENT_BUFFER = 20;

export const useDrawUploadedImage = (
  fabricCanvasRef: React.MutableRefObject<fabric.Canvas | null>,
) => {
  const uploadedImages = useAiSceneCreateStore((state) => state.aiSceneUploadedImages);
  const removeUploadedImages = useAiSceneCreateStore((state) => state.removeAiSceneImages);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || uploadedImages.length < 1) return;

    const parsedImgPromises = uploadedImages.map((img) => {
      const placementBuffer = img.type === 'template' ? 0 : OBJECT_PLACEMENT_BUFFER;
      return parseImageWithFabric({
        ...img,
        placementBuffer,
        maxHeight: fabricRef.getHeight() - placementBuffer * 2,
        maxWidth: fabricRef.getWidth() - placementBuffer * 2,
      });
    });

    const parseImages = async () => {
      try {
        const parsedImages = await Promise.all(parsedImgPromises);
        parsedImages.forEach((item) => {
          if (item.type !== 'template') {
            fabricRef.add(item.image);
            return;
          }

          const existingTemplate = fabricRef
            .getObjects()
            .find((obj) => obj.imageType === 'template');
          if (existingTemplate) {
            fabricRef.remove(existingTemplate);
          }
          item.image.set({
            evented: false,
            selectable: false,
            isSelectionIgnored: true,
          });
          fabricRef.insertAt(item.image, 0, false);
        });
        fabricRef.requestRenderAll();
        updateCanvasState(serializeCanvas(fabricRef));
        removeUploadedImages(parsedImages.map((item) => item.id));
      } catch (error) {
        console.error(error);
      }
    };

    parseImages();
  }, [fabricCanvasRef, uploadedImages, updateCanvasState, removeUploadedImages]);
};
