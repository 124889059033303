import { FC, ReactNode } from 'react';
import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { AddText } from './text/AddText';
import { AlignText } from './text/AlignText';
import { FormatTextStyle } from './text/FormatTextStyle';
import { AdjustLineHeight } from './text/AdjustLineHeight';
import { FontDetail } from './text/FontDetail';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-slate-50';

interface TextSettingsProps {
  children: ReactNode;
  canvasInstance: fabric.Canvas | null;
}

export const TextSettings: FC<TextSettingsProps> = ({ children, canvasInstance }) => {
  return (
    <div className='flex w-full flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <p className='font-bold'>Text</p>
        {children}
      </div>
      <div className='flex w-full gap-2'>
        <AddText canvasInstance={canvasInstance} />
        <AdjustLineHeight canvasInstance={canvasInstance} />
        <Button asChild variant='outline' className='rounded-lg bg-white px-1 hover:bg-white'>
          <div className='flex gap-1'>
            <IconWrapper
              wrapperProps={{
                className: `${ICON_WRAPPER_CL} flex-1`,
              }}
              iconClass='i-mdi-arrow-left-right h-4 w-3.5'
            />
            <IconWrapper
              wrapperProps={{
                className: `${ICON_WRAPPER_CL} flex-1`,
              }}
              iconClass='i-mdi-format-align-left h-4 w-3.5'
            />
            <IconWrapper
              wrapperProps={{
                className: `${ICON_WRAPPER_CL} flex-1`,
              }}
              iconClass='i-mdi-square-rounded-outline h-4 w-3.5'
            />
          </div>
        </Button>
      </div>
      <FontDetail canvasInstance={canvasInstance} />
      <div className='flex gap-2'>
        <AlignText canvasInstance={canvasInstance} />
        <FormatTextStyle canvasInstance={canvasInstance} />
      </div>
    </div>
  );
};
