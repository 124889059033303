import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { useUserStore } from '@/providers/user/hooks';
import { useMutationState } from '@tanstack/react-query';
import {
  TAiSketchingResult,
  getSketchingMutationKey,
  useGetEditProcessQueries,
  useSaveSceneMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import 'swiper/css';
import 'swiper/css/navigation';
import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'components/ui/button';
import { Skeleton } from 'components/ui/skeleton';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { toast } from 'sonner';
import invariant from 'tiny-invariant';
import { serializeCanvas } from '@/utils/helper';
import { useParams } from 'react-router';

const INDEX_BUFFER = 1;

export const SketchingResults = () => {
  const processingState = useAiSceneResultStore((state) => state.processingState);
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const data = useMutationState({
    filters: { status: 'success', mutationKey: getSketchingMutationKey(organizationId) },
    select: (mutation) => mutation.state.data as TAiSketchingResult[],
  });
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const currentSlideTextRef = useRef<HTMLSpanElement | null>(null);
  const [api, setApi] = useState<CarouselApi>();
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const saveSceneMutation = useSaveSceneMutation();
  const { projectId, sceneId } = useParams();

  const latestMutationResults = data?.[data.length - 1];
  const isSketchingView = processingState === 'sketching';
  const sketchingResults = useGetEditProcessQueries(
    latestMutationResults?.map((item) => item.versionId) || [],
    isSketchingView,
  );

  useEffect(() => {
    if (!api) return;
    if (!currentSlideTextRef.current) return;

    const getCurrentSlide = () => (api.selectedScrollSnap() + INDEX_BUFFER).toString();

    currentSlideTextRef.current.textContent = getCurrentSlide();

    api.on('select', () => {
      if (!currentSlideTextRef.current) return;
      currentSlideTextRef.current.textContent = getCurrentSlide();
    });
  }, [api]);

  if (!isSketchingView || !sketchingResults || sketchingResults.length < 1) {
    return null;
  }

  const selectGeneratedScene = () => {
    try {
      invariant(canvasInstance, 'No canvas instance found to select the generated scene.');
      const currentSlide = api?.selectedScrollSnap();
      if (typeof currentSlide === 'undefined') {
        toast.error('Failed to select the generated scene. Please try again.');
        return;
      }

      const templateImage = canvasInstance
        .getObjects()
        .find((obj) => obj.imageType === 'template') as fabric.Image;
      invariant(templateImage, 'No base image found to revert magic erase.');
      const selectedSketch = sketchingResults[currentSlide].data;
      invariant(selectedSketch, 'No image found to select the generated scene.');

      templateImage.setSrc(
        selectedSketch.imageUrl,
        () => {
          canvasInstance.requestRenderAll();
          updateCanvasState(serializeCanvas(canvasInstance));
          cancelSceneSelection();
          invariant(sceneId, 'No scene id found to save the generated result.');
          invariant(projectId, 'No project id found to save the generated result.');

          saveSceneMutation.mutate(
            {
              sceneId,
              projectId,
              versionId: selectedSketch.versionId,
            },
            {
              onError: () => {
                toast.error('Failed to save the generated result.');
              },
            },
          );
        },
        { crossOrigin: 'anonymous' },
      );
    } catch (error) {
      console.error(error);
      toast.error('Failed to select the generated scene. Please try again.');
    }
  };

  const cancelSceneSelection = () => {
    changeProcessingState('idle');
  };

  const hasAnyLoadingItem = sketchingResults.some((result) => result.isLoading);
  const availableResults = sketchingResults.filter((result) => result.data);

  return (
    <div className='absolute inset-0 z-[999] h-full w-full bg-white'>
      <Carousel className='relative h-full w-full' setApi={setApi}>
        <CarouselContent wrapperClassName='h-full' className='h-full'>
          {availableResults.map((result) => (
            <CarouselItem key={result.data?.versionId} className='h-full w-full'>
              <SketchItem data={result.data} isError={result.isError} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className='absolute bottom-5 left-1/2 flex -translate-x-1/2 transform gap-4 rounded-lg border-gray-300 bg-white px-3 py-5'>
          <div className='flex items-center justify-center gap-2'>
            <CarouselPrevious className='relative inset-0 transform-none rounded-lg bg-crait-green-100' />
            <span className='flex gap-1 text-nowrap text-lg font-normal tracking-wider'>
              <span ref={currentSlideTextRef}>1</span>/<span>{availableResults.length}</span>
            </span>
            <CarouselNext className='relative inset-0 transform-none rounded-lg bg-crait-green-100' />
          </div>
          <div className='flex gap-1'>
            <Button
              className='w-20 rounded-lg bg-crait-green-400'
              size='sm'
              onClick={selectGeneratedScene}
              disabled={hasAnyLoadingItem}
            >
              Use
            </Button>
            <Button
              size='sm'
              className='w-full flex-1 rounded-lg'
              variant='outline'
              onClick={cancelSceneSelection}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

interface SketchItemProps {
  data?: TAiSketchingResult;
  isError: boolean;
}

const SketchItem: FC<SketchItemProps> = ({ data, isError }) => {
  const imageView = data?.imageUrl ? (
    <img crossOrigin='anonymous' src={data.imageUrl} className='h-full w-full object-cover' />
  ) : (
    <div className='flex h-full w-full items-center justify-center bg-gray-300'>
      <div className='absolute z-10 flex size-full flex-col items-center justify-center gap-2'>
        <LoadingSpinner />
        <span>Generating image...</span>
      </div>
      <Skeleton className='size-full rounded-none' />
    </div>
  );

  const errorView = isError && (
    <div className='flex size-full items-center justify-center bg-gray-300'>
      <span>Failed to generate image. Please try again.</span>
    </div>
  );

  return <div className={`flex size-full`}>{errorView || imageView}</div>;
};
