import { FC, ReactNode, useRef } from 'react';
import { createBannerStore } from './store';
import { BannerCreateContext, TBannerCreateContext } from './helper';

interface BannerCreateProviderProps {
  children: ReactNode;
}

export const BannerCreateProvider: FC<BannerCreateProviderProps> = ({ children }) => {
  const storeRef = useRef<TBannerCreateContext>();

  if (!storeRef.current) {
    storeRef.current = createBannerStore();
  }

  return (
    <BannerCreateContext.Provider value={storeRef.current}>{children}</BannerCreateContext.Provider>
  );
};
