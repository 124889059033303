import React from 'react';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import {
  ArrowRightIcon,
  ImageStyleIcon,
  ProductPhotoIcon,
  VisualGuidanceIcon,
} from './CreateToolIcons';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { MainPromptInput } from './MainPromptInput';
import { GenerationButton } from './GenerationButton';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { DisabledPromptPopover } from './DisabledPromptPopover';
import { ImageNumber } from './ImageNumber';
import { useIsAiImageType } from 'pages/ai-scene/useIsAiImageType';
import { SizeButton } from './SizeButton';
import { useSceneImagesSrc } from '../useSceneImagesSrc';

const BUTTON_COMMON_CLASSES =
  'flex h-[48px] w-full items-center justify-between rounded-md border border-gray-200 p-[6px] hover:bg-gray-50';

interface CreateToolProps {
  onProductPhotoClick: () => void;
  onVisualGuidanceClick: () => void;
  onSizeTabClick: () => void;
  onAdvancedSettings: () => void;
  onImageStyle: () => void;
}

export const CreateTool: React.FC<CreateToolProps> = ({
  onProductPhotoClick,
  onVisualGuidanceClick,
  onSizeTabClick,
  onAdvancedSettings,
  onImageStyle,
}) => {
  const { visualGuidanceImages, productImages, templateImages } = useSceneImagesSrc();
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const lightingStyle =
    useAiSceneCreateStore((state) => state.aiScenePromptTab.lightingStyleId) || null;
  const brandStyle = useAiSceneCreateStore((state) => state.aiScenePromptTab.brandStyleId) || null;
  const isAiImageType = useIsAiImageType();

  const promptDescriptionForProjectType = isAiImageType
    ? 'Describe your desired image'
    : 'Describe your desired background';

  const combinedStyles = [lightingStyle, brandStyle].filter(Boolean);

  return (
    <div className='w-[320px] rounded-lg bg-white p-3 shadow-md md:w-[90%] md:shadow-none'>
      <div className='md:hidden'>
        <h2 className='text-[15px] font-semibold'>Create</h2>
        <Separator className='my-4' />
        <div className='flex flex-col gap-2'>
          {/*Product Button*/}
          {!isAiImageType && (
            <Button
              variant='outline'
              className={BUTTON_COMMON_CLASSES}
              onClick={onProductPhotoClick}
            >
              <div className='flex items-center gap-2'>
                {productImages.length > 0 ? (
                  <div className='relative'>
                    <img
                      src={productImages[0]}
                      alt='Product'
                      className='h-8 w-8 rounded-[6px] bg-[#F5F5F5] object-contain'
                    />
                    {productImages.length > 1 && (
                      <span className='absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-c-dark text-[9px] text-white'>
                        {productImages.length}
                      </span>
                    )}
                  </div>
                ) : (
                  <ProductPhotoIcon />
                )}
                <span>Product Photo</span>
              </div>
              <ArrowRightIcon />
            </Button>
          )}

          {/*Template or Inspiration Button*/}
          <Button
            variant='outline'
            className={BUTTON_COMMON_CLASSES}
            onClick={onVisualGuidanceClick}
          >
            <div className='flex items-center gap-2'>
              {visualGuidanceImages.length > 0 ? (
                <div className='relative'>
                  <img
                    src={visualGuidanceImages[0]}
                    alt='Template'
                    className='h-8 w-8 rounded-[6px] bg-[#F5F5F5] object-contain'
                  />
                </div>
              ) : (
                <VisualGuidanceIcon />
              )}
              <span>Visual Guidance</span>
            </div>
            <ArrowRightIcon />
          </Button>

          {/*Styles Button*/}
          <Button variant='outline' className={BUTTON_COMMON_CLASSES} onClick={onImageStyle}>
            <div className='flex items-center gap-2'>
              {combinedStyles.length > 0 ? (
                <div className='relative'>
                  <img
                    src={combinedStyles[0]?.thumbnail}
                    alt={combinedStyles[0]?.name}
                    className='h-8 w-8 rounded-[6px] bg-[#F5F5F5] object-contain'
                  />
                  {combinedStyles.length > 1 && (
                    <span className='absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-c-dark text-[9px] text-white'>
                      {combinedStyles.length}
                    </span>
                  )}
                </div>
              ) : (
                <ImageStyleIcon />
              )}
              <span>Image Style</span>
            </div>
            <ArrowRightIcon />
          </Button>
        </div>

        {/*Prompt Button*/}
        <div className='mt-4 flex flex-col gap-2'>
          <span className='text-main font-semibold'>{promptDescriptionForProjectType}</span>
          <Popover>
            <PopoverTrigger className='text-left' asChild={templateImages.length == 0}>
              <MainPromptInput isDisabled={templateImages.length > 0} />
            </PopoverTrigger>
            {templateImages.length > 0 && (
              <PopoverContent side='right'>
                <DisabledPromptPopover />
              </PopoverContent>
            )}
          </Popover>
        </div>
      </div>

      <div className='flex flex-col gap-3 py-4'>
        <SizeButton
          onSizeTabClick={onSizeTabClick}
          canvasWidth={canvasInstance?.originalDimensions?.width}
          canvasHeight={canvasInstance?.originalDimensions?.height}
        />
        <ImageNumber isDisabled={templateImages.length > 0} />
        <div
          onClick={onAdvancedSettings}
          className='flex w-full cursor-pointer items-center justify-between'
        >
          <span className='text-[13px] font-semibold'>Advanced Settings</span>
          <ArrowRightIcon />
        </div>
      </div>
      <div className='md:hidden'>
        <GenerationButton />
      </div>
    </div>
  );
};
