import { Checkbox } from 'components/ui/checkbox';
import { Label } from 'components/ui/label';
import { useEffect, useMemo, useState } from 'react';
import {
  TTemplateResponse,
  useGetInfiniteSearchResults,
  useGetTemplatesCategories,
} from '../../helper/queries';
import { RefetchView } from '../RefetchView';
import { Command, CommandInput } from 'components/ui/command';
import { useDebounceValue } from 'usehooks-ts';
import { TemplateItems } from './TemplateItems';
import { useInView } from 'react-intersection-observer';
import { useResetInfiniteSearchCache } from '../styles/useResetInfiniteSearchCache';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { HowItWorksBox } from '../HowItWorksBox';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';

const MAX_PAGES_IN_CACHE = 2;

export const TemplatesTab = ({ returnCreateToolClick }: { returnCreateToolClick: () => void }) => {
  const [selectedCategory, setSelectedCategory] = useState<
    { name: string; id: string } | undefined
  >(undefined);
  const [isOnlyLibrary, setIsOnlyLibrary] = useState(false);
  const [keyword, setKeyword] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TTemplateResponse>({
    keyword,
    onlyOrgLibrary: isOnlyLibrary,
    categoryId: selectedCategory?.id,
    searchSection: 'templates',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.templates;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 0,
    refetchOnMount: true,
  });
  useResetInfiniteSearchCache<TTemplateResponse>('templates', MAX_PAGES_IN_CACHE);

  const {
    data: categories,
    isError: categoriesError,
    isLoading: categoriesLoading,
    refetch: refetchCategories,
  } = useGetTemplatesCategories();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allTemplates = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.templates.items) || [];
  }, [infiniteData]);

  if (isError) {
    return (
      <RefetchView
        disabled={isLoading}
        onClick={() => {
          refetch();
        }}
      >
        <span>Failed to load templates.</span>
      </RefetchView>
    );
  }

  return (
    <div className='flex h-full flex-col gap-3 pt-3'>
      <HowItWorksBox
        text='Select a pre-made scene to place your product inside.'
        linkText="Don't show this again"
        onLinkClick={() => {}}
        parentComponent='Templates'
      />
      <Command className='flex h-10 justify-center rounded-lg bg-background'>
        <CommandInput placeholder='Search Template Categories' onValueChange={setKeyword} />
      </Command>
      <ScrollArea className='h-[36px] w-[260px] md:w-[86vw]'>
        <div className='flex gap-2'>
          <div
            className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-3 py-1 text-[12px] font-semibold ${
              !selectedCategory && !isOnlyLibrary ? 'bg-crait-green-primary' : ''
            }`}
            onClick={() => {
              setSelectedCategory(undefined);
              setIsOnlyLibrary(false);
            }}
          >
            All
          </div>
          <div
            id='styleFromLibrary'
            className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-2 py-1 text-[12px] font-semibold ${
              isOnlyLibrary ? 'bg-crait-green-primary' : ''
            }`}
            onClick={(e) => {
              setSelectedCategory(undefined);
              setIsOnlyLibrary(true);
            }}
          >
            My Templates
          </div>
          {categories?.map((category) => (
            <div
              key={category.id}
              className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-2 py-1 text-[12px] font-semibold ${
                selectedCategory?.id === category.id ? 'bg-crait-green-primary' : ''
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category.name}
            </div>
          ))}
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <TemplateItems templates={allTemplates} returnCreateToolClick={returnCreateToolClick}>
        {hasNextPage && (
          <div className='relative flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </TemplateItems>
    </div>
  );
};
