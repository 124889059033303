import { useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useCreateProjectMutation } from './create/helpers';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { toast } from 'sonner';
import { RoutePaths } from '@/utils/route/constants';
import { ProjectTypes } from 'pages/workspace/projects/helper';

const RouteProjectMap = {
  [RoutePaths.AI_SCENE]: ProjectTypes.AI_SCENE,
  [RoutePaths.AI_IMAGE]: ProjectTypes.AI_IMAGE,
  [RoutePaths.AI_BANNER]: ProjectTypes.AI_BANNER,
  [RoutePaths.AI_EDIT]: ProjectTypes.AI_EDIT,
} as const;

export const AiSceneWithoutProjectIdPath = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const location = useLocation();
  const { mutateAsync: createProjectAsync } = useCreateProjectMutation();
  const resetAll = useAiSceneCreateStore((state) => state.resetAll);
  const fetchedProjectId = useRef('');

  const determineProjectType = (path: string) => {
    const projectType =
      Object.entries(RouteProjectMap).find(([key]) => path.includes(key))?.[1] ??
      ProjectTypes.AI_IMAGE;

    return projectType;
  };

  const type = determineProjectType(location.pathname);

  useEffect(() => {
    if (projectId) return;

    const createNewProject = async () => {
      try {
        const project = await createProjectAsync({ type });
        resetAll();
        if (fetchedProjectId.current) return;

        fetchedProjectId.current = project.id;
        navigate(project.id, {
          replace: true,
        });
      } catch (error) {
        console.error('error', error);
        toast.error('Failed to create project. Please try again.');
      }
    };
    createNewProject();
  }, [projectId, createProjectAsync, navigate, fetchedProjectId, resetAll, type]);

  return null;
};
