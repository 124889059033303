import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea } from 'components/ui/scroll-area';
import { useGetColorsQuery } from 'components/workspace/brand-library/brand-kit/queries';
import { useMemo } from 'react';

export const ColorsView = () => {
  const addBrandKitColor = useBannerCreateStore((s) => s.addBrandKitColor);
  const removeBrandKitColor = useBannerCreateStore((s) => s.removeBrandKitColor);
  const brandKitColors = useBannerCreateStore((s) => s.brandKitColors);
  const { data, isError, isLoading, refetch } = useGetColorsQuery({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load colors.</span>
    </RefetchView>
  );

  const colorItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      );

    return data?.items.map((color) => {
      const isSelected = brandKitColors.some((c) => c.id === color.id);

      return (
        <div
          key={color.id}
          onClick={() => {
            if (isSelected) {
              removeBrandKitColor(color.id);
            } else {
              addBrandKitColor({
                id: color.id,
                hex: color.hexCode,
                percent: color.opacity,
              });
            }
          }}
          className={`flex w-32 cursor-pointer flex-col items-center gap-2.5 rounded-lg p-2 hover:outline hover:outline-crait-blue ${
            isSelected ? 'bg-crait-blue opacity-70' : ''
          }`}
        >
          <span
            className='relative flex size-[54px] items-center gap-2 overflow-hidden rounded-full'
            style={{ backgroundColor: color.hexCode }}
          />
          <div className='flex flex-col gap-1 text-center'>
            <span className='line-clamp-1 text-ellipsis'>{color.name}</span>
            <span className='line-clamp-1 text-ellipsis text-xs uppercase text-slate-500'>
              {color.hexCode}
            </span>
          </div>
        </div>
      );
    });
  }, [data, isLoading, brandKitColors, addBrandKitColor, removeBrandKitColor]);

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{brandKitColors.length} Selected</span>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='mt-2 flex flex-col gap-3 py-1'>
          <div className='flex flex-wrap gap-2 px-3 py-2'>{errorView || colorItems}</div>
        </div>
      </ScrollArea>
    </div>
  );
};
