import { Button } from 'components/ui/button';
import { TColorResponse, usePartialUpdateColorMutation, useRemoveColorMutation } from '../queries';
import { toast } from 'sonner';
import { ReactNode } from 'react';

export const ColorItem = (
  props: TColorResponse & {
    refetch: () => void;
    children: ReactNode;
  },
) => {
  const removeColorMutation = useRemoveColorMutation();
  const partialUpdateColor = usePartialUpdateColorMutation();

  return (
    <div className='relative flex w-auto flex-col items-center gap-1.5 rounded-[8px] py-2'>
      <Button
        variant='ghost'
        className='absolute right-0 top-0 z-10 h-5 w-5 rounded-[8px] bg-gray-100 p-1'
        onClick={() => {
          removeColorMutation.mutate(
            { id: props.id },
            {
              onSuccess: () => {
                props.refetch();
              },
              onError: () => {
                toast.error('Failed to remove color');
              },
            },
          );
        }}
      >
        <span className='i-mdi-close size-full'></span>
      </Button>
      {props.children}
    </div>
  );
};
