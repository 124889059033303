import { useInView } from 'react-intersection-observer';

import { useGetSearchResultsInfinite } from 'components/ai-scene/sidebar/settings-tab/queries';
import { TProject } from 'pages/workspace/projects/helper';
import React, { FC, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

import EmptyProject from '@/assets/icons/emptyProject.svg?react';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { toast } from 'sonner';
import { AxiosError } from 'axios';

interface ProjectListProps {
  keyword: string;
  isOnlyMyProjects: boolean;
}

export const ProjectList: FC<ProjectListProps> = ({ keyword, isOnlyMyProjects }) => {
  const { data, status, error, fetchNextPage, isLoading, hasNextPage, refetch } =
    useGetSearchResultsInfinite<TProject>({
      keyword,
      searchSection: 'projects',
      onlyMyProjects: isOnlyMyProjects,
    });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    refetch();
  }, []);

  const projects = data ? data.pages.flatMap((page) => page.projects.items) : [];

  if (status == 'pending') {
    return (
      <div className='mt-10 flex justify-center'>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    const err = error as any;
    toast.error(err.response?.data?.detail || 'An unknown error occurred');
    return (
      <RefetchView
        disabled={isLoading}
        onClick={() => {
          refetch();
        }}
      >
        <h1 className='my-8 text-center text-3xl font-bold text-gray-400'>Error</h1>
      </RefetchView>
    );
  }

  if (projects.length == 0) {
    return (
      <div className='mt-32 flex flex-col items-center justify-center gap-5'>
        <EmptyProject />
        <div className='text-center '>
          <p className='text-5 mb-2 font-bold'>Your blank canvas for creativity</p>
          <p className='text-[14px] font-normal'>
            Start by adding your first project and watch your ideas come to life.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='mt-10 grid max-w-[1440px] grid-cols-2  gap-6 gap-y-10 min-sm:grid-cols-3 min-md:grid-cols-2 min-lg:grid-cols-3 min-xl:grid-cols-4  min-3xl:grid-cols-5 '>
        {projects.map((item) => (
          <ProjectCard item={item} refetch={refetch} key={item.id} />
        ))}
      </div>
      {hasNextPage && (
        <div className='mt-10 flex justify-center' ref={ref}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
