import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { Textarea } from 'components/ui/textarea';
import { toast } from 'sonner';
import {
  ProductProperties,
  useGetProductProperties,
  useGetProductPropertiesStatusMutation,
} from 'pages/workspace/brand-library/products/helper';
import { TooltipItem } from 'components/common/TooltipItem';
import { Label } from 'components/ui/label';
import { PROCESSING_STATUSES } from 'components/ai-scene/result-sidebar/queries';

interface ProductCaptionInputProps {
  transparentImage: string;
  caption: string;
  onCaptionChange: (caption: string) => void;
  isEditMode?: boolean;
  setProductProperties?: (properties: ProductProperties) => void;
}

export const ProductCaptionInput: FC<ProductCaptionInputProps> = ({
  transparentImage,
  caption,
  onCaptionChange,
  isEditMode,
  setProductProperties,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const isComponentMounted = useRef(true);
  const isPolling = useRef(false);
  const pollingTimeout = useRef<NodeJS.Timeout | null>(null);

  const startPropertyProcess = useGetProductProperties();
  const getProcessStatus = useGetProductPropertiesStatusMutation();

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
    };
  }, []);

  const handleStartProcess = useCallback(async () => {
    if (!transparentImage || isProcessing || isPolling.current || isEditMode) return;

    setIsProcessing(true);
    isPolling.current = true;

    try {
      const response = await startPropertyProcess.mutateAsync({ transparentImage });
      const processId = response.processId;

      const pollStatus = async () => {
        try {
          const statusResponse = await getProcessStatus.mutateAsync(processId);

          if (!isComponentMounted.current) return;

          if (statusResponse.status === 'ready' && statusResponse.properties?.caption) {
            onCaptionChange(statusResponse.properties.caption);
            setProductProperties && setProductProperties(statusResponse.properties);
            setIsProcessing(false);
            isPolling.current = false;
          } else if (PROCESSING_STATUSES.includes(statusResponse.status)) {
            pollingTimeout.current = setTimeout(pollStatus, 1000);
          } else {
            throw new Error('Process failed or did not return the expected result.');
          }
        } catch (error) {
          toast.error('Failed to retrieve process status, please try again.');
          setIsProcessing(false);
          isPolling.current = false;
        }
      };

      pollStatus();
    } catch (error) {
      toast.error('Failed to start the process, please try again.');
      setIsProcessing(false);
      isPolling.current = false;
    }
  }, []);

  useEffect(() => {
    if (!isEditMode) {
      handleStartProcess();
    }
  }, [handleStartProcess, isEditMode]);

  return (
    <div className='flex flex-col gap-2'>
      <Label htmlFor='product-caption' className='flex cursor-pointer items-center gap-2'>
        <p className='text-xs font-medium'>Product Caption</p>
        <TooltipItem
          trigger={
            <span className='i-mdi-information-slab-circle-outline cursor-pointer text-base'></span>
          }
        >
          <p className='max-w-[300px] rounded-2xl px-1 py-0.5'>
            Only edit if the description doesn’t match the actual product.
          </p>
        </TooltipItem>
      </Label>
      <Textarea
        id='product-caption'
        className='text-[12px] font-medium'
        value={caption}
        onChange={(e) => onCaptionChange(e.target.value)}
        disabled={isProcessing}
      />
    </div>
  );
};
