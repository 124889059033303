import React, { useEffect, useMemo } from 'react';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { TablePagination } from './TablePagination';
import { TableToolbar } from './TableToolbar';
import { settingsColumns } from './settings.columns';
import { toast } from 'sonner';
import { useNavigate } from 'react-router';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { TSavedSettingsResponse } from '../helper';
import {
  getRedirectUrlAccordingToType,
  useDeleteSettingsMutation,
  useRenameSettingsMutation,
} from 'components/workspace/projects/helper';
import { useGetSearchResults } from 'components/ai-scene/sidebar/settings-tab/queries';
import { useDebounceValue } from 'usehooks-ts';
import { useCraitStore } from '@/utils/store/store';
const _getSettingNameSearchValue = (columnFilters: ColumnFiltersState) =>
  (columnFilters.find((f) => f.id === 'name')?.value as string) || '';

type TEditedRows = Record<string, boolean>;

export const SavedSettingsTable = () => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [keyword, setKeyword] = useDebounceValue('', 200);
  const { data, isError, isLoading, refetch, isPlaceholderData } =
    useGetSearchResults<TSavedSettingsResponse>({
      keyword,
      pageId: pagination.pageIndex,
      searchSection: 'saved_settings',
      staleTime: 0,
    });
  const navigate = useNavigate();
  const [tableData, setTableData] = React.useState(() => data?.savedSettings.items || []);
  const originalData = React.useRef(data?.savedSettings.items || []);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [editedRows, setEditedRows] = React.useState<TEditedRows>({});
  const deleteSettingsMutation = useDeleteSettingsMutation();
  const renameSettingsMutation = useRenameSettingsMutation();
  const setPendingSettingsToUse = useCraitStore((state) => state.setPendingSettingsToUse);
  const isFullTableLoading = isPlaceholderData && tableData.length < 1;

  const table = useReactTable({
    data: tableData,
    columns: settingsColumns,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount: data?.savedSettings?.totalPages || 0,
    manualFiltering: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: (filtersOrUpdaterFn) => {
      const newFilters =
        typeof filtersOrUpdaterFn === 'function'
          ? filtersOrUpdaterFn(columnFilters)
          : filtersOrUpdaterFn;

      setColumnFilters(filtersOrUpdaterFn);
      const searchValue = _getSettingNameSearchValue(newFilters);
      if (searchValue !== keyword) setKeyword(searchValue);
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      editedRows,
      setEditedRows,
      saveRow: (rowIndex, originalRow, newValue) => {
        if (!newValue) return;

        renameSettingsMutation.mutate(
          {
            name: newValue,
            settingsId: originalRow.id,
          },
          {
            onSuccess: () => {
              originalData.current = originalData.current.map((row, index) =>
                index === rowIndex ? tableData[rowIndex] : row,
              );
              setEditedRows((prev) => ({
                ...prev,
                [rowIndex]: false,
              }));
              toast.success('Setting name updated successfully');
            },
            onError: () => {
              toast.error('Failed to update setting name. Please try again.');
            },
          },
        );
      },
      revertData: (rowIndex: number) => {
        setTableData((prev) => {
          return prev.map((row, index) => {
            if (index === rowIndex) {
              return originalData.current[rowIndex];
            }
            return row;
          });
        });
        setEditedRows((prev) => ({
          ...prev,
          [rowIndex]: false,
        }));
      },
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          }),
        );
      },
      deleteRow: (rowIndex, row) => {
        deleteSettingsMutation.mutate(row.id, {
          onSuccess: () => {
            originalData.current = originalData.current.filter((r) => r.id !== row.id);
            setTableData((prev) => prev.filter((r) => r.id !== row.id));
            toast.success('Settings deleted successfully');
          },
          onError: () => {
            toast.error('Failed to delete settings. Please try again.');
          },
        });
      },
    },
  });

  useEffect(() => {
    setTableData(data?.savedSettings.items || []);
    originalData.current = data?.savedSettings.items || [];
  }, [data?.savedSettings.items]);

  const fullTableLoadingView = isFullTableLoading && (
    <div className='flex h-24 w-full justify-center'>
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
        <LoadingSpinner />
      </div>
    </div>
  );

  const tableRows = table.getRowModel().rows;
  const tableContent = useMemo(() => {
    if (!tableRows?.length)
      return (
        <TableRow>
          <TableCell colSpan={settingsColumns.length} className='h-24 text-center'>
            No results.
          </TableCell>
        </TableRow>
      );

    return tableRows.map((row) => (
      <TableRow
        key={row.original.id}
        tabledata-state={row.getIsSelected() ? 'selected' : ''}
        className='cursor-pointer'
        onClick={() => {
          const originalRow = row.original;
          setPendingSettingsToUse({
            summary: originalRow.summary,
            parameters: originalRow.parameters,
          });
          const redirectUrl = getRedirectUrlAccordingToType(
            originalRow.parent_project_id,
            originalRow.parent_project_type,
          );
          navigate(`${redirectUrl}/generation/${originalRow.id}`);
        }}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            style={{
              minWidth: cell.column.columnDef.minSize,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [tableRows, navigate, data?.savedSettings.items]);

  return (
    <div className='space-y-4'>
      <TableToolbar table={table} />
      <Table className='w-full rounded-xl border bg-white'>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className='relative'>{fullTableLoadingView || tableContent}</TableBody>
      </Table>
      <TablePagination table={table} />
    </div>
  );
};
