import { BasicCard, BookmarkIcon } from '../BasicCard';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { FC, ReactNode } from 'react';
import {
  TSearchResponse,
  TTemplateResponse,
  useToggleTemplateLibraryMutation,
} from 'components/ai-scene/side-menu/create-tool/helper/queries';
import { useSyncSelectedTemplate } from '../useSyncSelectedTemplate';
import { useIsBulkSceneCreated } from 'components/ai-scene/side-menu/useIsBulkSceneCreated';
import { useAiSceneBulkCreateStore } from '@/providers/ai-scene/bulk-create/hooks';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ScrollArea } from 'components/ui/scroll-area';
import { nanoid } from 'nanoid';
import { useRemoveSelectedTemplate } from '../../helper/useRemoveSelectedTemplate';
import { useMobileBottomMenuStore } from 'components/ai-scene/mobile-bottom-menu/useMobileBottomMenuStore';

interface TemplateItemsProps {
  templates: TTemplateResponse[];
  children: ReactNode;
  returnCreateToolClick: () => void;
}

export const TemplateItems: FC<TemplateItemsProps> = ({
  templates,
  children,
  returnCreateToolClick,
}) => {
  const addAiSceneImage = useAiSceneCreateStore((state) => state.addAiSceneImage);
  const [drawnTemplate, setDrawnTemplate] = useSyncSelectedTemplate();
  const isBulkSceneCreated = useIsBulkSceneCreated();
  const addAdditionalBulkImage = useAiSceneBulkCreateStore((state) => state.addAdditionalImage);
  const styleIds = useAiSceneCreateStore((state) => state.aiSceneSettingsTab.styleIds);
  const removeStyleId = useAiSceneCreateStore((state) => state.removeAiSceneSelectedStyles);
  const removeAdditionalBulkImage = useAiSceneBulkCreateStore(
    (state) => state.removeAdditionalImage,
  );
  const queryClient = useQueryClient();
  const toggleTemplateLibraryMutation = useToggleTemplateLibraryMutation();
  const { removeSelectedTemplate } = useRemoveSelectedTemplate();
  const closeMobileBottomMenu = useMobileBottomMenuStore((state) => state.closeMobileBottomMenu);

  const handleSelectionForSingleScene = (template: TTemplateResponse) => {
    if (drawnTemplate?.id === template.id) {
      removeSelectedTemplate(template.id);
      return;
    }

    addAiSceneImage({
      image: template.url,
      id: template.id,
      type: 'template',
      imageName: template.name,
    });
    setDrawnTemplate({ id: template.id, name: template.name });
    returnCreateToolClick();
  };

  const handleSelectionForBulkScene = (template: TTemplateResponse) => {
    addAdditionalBulkImage({
      image: template.url,
      id: template.id,
      type: 'template',
      imageName: template.name,
      bulkImageId: nanoid(),
    });
    setDrawnTemplate({ id: template.id, name: template.name });
  };

  const hasAnyTemplates = templates.length > 0;
  if (!hasAnyTemplates) return <span>No templates found.</span>;

  const selectedTemplate = drawnTemplate
    ? templates.find((template) => template.id === drawnTemplate.id)
    : null;

  return (
    <div className='size-full'>
      {drawnTemplate && (
        <div
          onClick={() => {
            removeSelectedTemplate(drawnTemplate.id);
          }}
          className='mb-2 inline-flex cursor-pointer items-center justify-center gap-1 rounded-md border border-black p-1 px-2 leading-none'
        >
          <span className='line-clamp-1 max-w-[380px] text-ellipsis'>{drawnTemplate.name}</span>
          <span className='i-mdi-window-close'>x</span>
        </div>
      )}
      <ScrollArea className='h-full w-full'>
        <div className='flex flex-wrap gap-x-2.5 gap-y-3 p-1 md:justify-center'>
          {selectedTemplate && (
            <BasicCard
              key={selectedTemplate.id}
              name={selectedTemplate.name}
              image={selectedTemplate.thumbnail}
              imageWrapperClass='outline outline-[4px] outline-[#80D962] hover:outline-[#80D962] hover:outline-[4px]'
            >
              <></>
            </BasicCard>
          )}
          {templates.map((template) => {
            if (!template || !template.id) return null;

            return (
              <BasicCard
                key={template.id}
                name={template.name}
                image={template.thumbnail}
                imageWrapperClass={
                  drawnTemplate?.id === template.id ? 'outline outline-2 outline-black' : undefined
                }
                onClick={() => {
                  closeMobileBottomMenu();
                  if (isBulkSceneCreated) {
                    handleSelectionForBulkScene(template);
                    return;
                  }
                  handleSelectionForSingleScene(template);

                  if (styleIds !== null && styleIds.length > 0) {
                    removeStyleId(styleIds[0]?.id);
                  }
                }}
              >
                {!template.isOrganizationAsset && (
                  <BookmarkIcon
                    isBookmarked={template.inLibrary}
                    onBookmark={async (shouldBookmark) => {
                      try {
                        const mutationResponse = await toggleTemplateLibraryMutation.mutateAsync({
                          id: template.id,
                          action: shouldBookmark ? 'add' : 'remove',
                        });
                        queryClient.setQueriesData<
                          InfiniteData<TSearchResponse<TTemplateResponse>>
                        >(
                          {
                            queryKey: [{ searchSection: 'templates' }],
                            exact: false,
                          },
                          (existingData) => {
                            if (!existingData) return existingData;

                            const updatedPages = existingData.pages.map((page) => {
                              const updatedItems = page.templates.items.map((item) => {
                                if (item.id !== mutationResponse.id) return item;

                                return {
                                  ...item,
                                  inLibrary: mutationResponse.inLibrary,
                                };
                              });

                              return {
                                ...page,
                                templates: {
                                  ...page.templates,
                                  items: updatedItems,
                                },
                              };
                            });

                            return {
                              ...existingData,
                              pages: updatedPages,
                            };
                          },
                        );
                      } catch (error) {
                        toast.error('Failed to update template library.');
                      }
                    }}
                  />
                )}
              </BasicCard>
            );
          })}
        </div>
        {children}
      </ScrollArea>
    </div>
  );
};
