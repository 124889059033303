import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AiSceneGenerationEndpoint } from '@/utils/fetch/constants';
import { getAiSceneGenerationApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';
import { TScene } from 'components/ai-scene/result-sidebar/queries';

type TCreateAiEditScenePayload = {
  projectId: string;
  organizationId: number;
  image: File;
  name: string;
  sceneDimensions: string;
};

export const createAiEditScene = async ({
  projectId,
  organizationId,
  image,
  name,
  sceneDimensions,
}: TCreateAiEditScenePayload): Promise<TScene> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}ai-edit-scene`;
  const formData = new FormData();
  formData.append('image', image);
  formData.append('name', name);
  formData.append('sceneDimensions', sceneDimensions);
  const response = await apiClient.post<TScene>(url, formData);
  return response.data;
};

export const useCreateAiEditSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE, 'ai-edit-scene'],
    mutationFn: (props: Omit<TCreateAiEditScenePayload, 'organizationId'>) => {
      return createAiEditScene({ organizationId, ...props });
    },
  });
};
