import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const TabOption = {
  SINGLE: 'single',
  BULK: 'bulk',
} as const;

type TTabOption = (typeof TabOption)[keyof typeof TabOption];

export const useSceneTypeParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sceneType = searchParams.get('sceneType') as TTabOption;

  return useMemo(() => {
    return [
      sceneType || TabOption.SINGLE,
      (type: TTabOption) => {
        setSearchParams({ sceneType: type });
      },
    ] as const;
  }, [sceneType, setSearchParams]);
};
