import { FC, useState } from 'react';
import Arrow from '@/assets/icons/arrowRight.svg?react';
import Trash from '@/assets/icons/trash01.svg?react';
import Rename from '@/assets/icons/textInput.svg?react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';

import { TProject } from 'pages/workspace/projects/helper';
import { useNavigate } from 'react-router';
import {
  getRedirectUrlAccordingToType,
  useDeleteProjectMutation,
  useRenameProjectMutation,
} from '../helper';
import { RenameProjectNameView } from './RenameProjectNameView';
import { ProjectModalView } from './ProjectModalView';
import { toast } from 'sonner';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';

interface CardActionProps extends TProject {
  refetch: () => void;
}

const SHEET_BUTTON_CLASS = 'flex justify-start gap-2 bg-white';

export const CardAction: FC<CardActionProps> = (props) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const renameProject = useRenameProjectMutation();
  const deleteProjectMutation = useDeleteProjectMutation();
  const navigate = useNavigate();
  const redirectUrl = getRedirectUrlAccordingToType(props.id, props.type);
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const handleDeleteProject = async () => {
    try {
      await deleteProjectMutation.mutateAsync(props.id);
      props.refetch();
      toast.success('Project deleted successfully');
    } catch (error) {
      toast.error('An error occurred while deleting the project');
    }
  };

  return (
    <>
      {isEditModalShown &&
        (isDesktop ? (
          <ProjectModalView
            open={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement={<div className='text-lg font-medium'>Rename Project</div>}
          >
            {({ closeModal }) => (
              <RenameProjectNameView
                defaultName={props.name}
                onClose={closeModal}
                isSubmitDisabled={renameProject.isPending}
                onSubmit={async (name) => {
                  try {
                    if (renameProject.isPending) return;
                    await renameProject.mutateAsync({ name, projectId: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    toast.error('Failed to update project');
                  }
                }}
              />
            )}
          </ProjectModalView>
        ) : (
          <MobileMenuSheet
            isOpen={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement='Rename'
            contentClassName='py-10'
          >
            {({ closeModal }) => (
              <RenameProjectNameView
                defaultName={props.name}
                onClose={closeModal}
                isSubmitDisabled={renameProject.isPending}
                onSubmit={async (name) => {
                  try {
                    if (renameProject.isPending) return;
                    await renameProject.mutateAsync({ name, projectId: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    toast.error('Failed to update project');
                  }
                }}
              />
            )}
          </MobileMenuSheet>
        ))}

      <MobileMenuSheet
        titleElement={props.name}
        triggerButton={
          <Button
            variant='ghost'
            className='h-8 w-8 rounded-sm bg-inherit p-0 focus-visible:ring-0'
          >
            <MoreHorizontal size={20} color='#D9D9D9' className='h-4 w-4' />
          </Button>
        }
        contentClassName='pl-0'
        closeAfterClick={true}
      >
        <div className='mt-4 flex flex-col justify-start gap-[6px] '>
          <Button
            className={SHEET_BUTTON_CLASS}
            variant='ghost'
            onClick={() => navigate(redirectUrl)}
          >
            <Arrow className='h-5 w-5 stroke-[#777777]' /> Open Projects
          </Button>
          <Button
            className={SHEET_BUTTON_CLASS}
            variant='ghost'
            onClick={() => setIsEditModalShown(true)}
          >
            <Rename className='h-5 w-5 stroke-[#777777]' /> Rename
          </Button>
          <Button
            variant='ghost'
            className={`${SHEET_BUTTON_CLASS} text-[#E16236]`}
            onClick={handleDeleteProject}
            disabled={deleteProjectMutation.isPending}
          >
            <Trash className='h-5 w-5 stroke-[#E16236]' />
            Delete
          </Button>
        </div>
      </MobileMenuSheet>

      <div className='block md:hidden'>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 rounded-sm bg-inherit p-0 focus-visible:ring-0'
            >
              <MoreHorizontal size={20} color='#D9D9D9' className='h-4 w-4 ' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent side='top' align='end' className='rounded-[10px]'>
            <DropdownMenuItem
              className='flex w-[172px] cursor-pointer gap-2 p-2 '
              onClick={() => navigate(redirectUrl)}
            >
              <Arrow className='h-5 w-5 stroke-[#777777]' /> Open Projects
            </DropdownMenuItem>
            <DropdownMenuItem
              className='flex w-[172px] cursor-pointer gap-2 p-2 '
              onClick={() => setIsEditModalShown(true)}
            >
              <Rename className='h-5 w-5 stroke-[#777777]' /> Rename
            </DropdownMenuItem>
            <DropdownMenuItem
              className='flex w-[172px] cursor-pointer gap-2 p-2 text-[#E16236] hover:!text-[#E16236]'
              onClick={handleDeleteProject}
              disabled={deleteProjectMutation.isPending}
            >
              <Trash className='h-5 w-5 stroke-[#E16236]' /> Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
