import { fabric } from 'fabric';
import { CUSTOM_OBJECT_PROPERTIES } from './store/constants';

// https://github.com/fabricjs/fabric.js/issues/4447
// https://github.com/fabricjs/fabric.js/discussions/9438
export const DEFAULT_FABRIC_FRACTION_DIGITS = 8;

export const applyFabricConfig = () => {
  (fabric as any).perfLimitSizeTotal = 225000000;
  (fabric as any).maxCacheSideLimit = 11000;
  // https://github.com/fabricjs/fabric.js/issues/6576#issuecomment-688513187
  const originalToObject = fabric.Object.prototype.toObject;
  fabric.Object.prototype.toObject = function (additionalProperties) {
    return originalToObject.call(
      this,
      (CUSTOM_OBJECT_PROPERTIES as string[]).concat(additionalProperties || []),
    );
  };

  const originalCanvasToJSON = fabric.Canvas.prototype.toJSON;
  fabric.Canvas.prototype.toJSON = function (propertiesToInclude) {
    const json = originalCanvasToJSON.call(this, propertiesToInclude);
    return json;
  };
  fabric.Object.NUM_FRACTION_DIGITS = DEFAULT_FABRIC_FRACTION_DIGITS;
};
