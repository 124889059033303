import {
  TStyleResponse,
  useToggleStyleLibraryMutation,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { ModalView } from 'components/common/ModalView';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { FC, useState } from 'react';
import { toast } from 'sonner';
import { EditNameAndImageView } from '../template/EditNameAndImageView';
import { useDeleteStyle, useUpdateStyleDetail } from './helper';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

export const StyleActions: FC<
  TStyleResponse & {
    refetch: () => void;
  }
> = (props) => {
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const updateStyleDetailMutation = useUpdateStyleDetail();
  const deleteStyleMutation = useDeleteStyle();
  const toggleStyleLibraryMutation = useToggleStyleLibraryMutation();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const isMutationEnabled = props.isOrganizationAsset;

  return (
    <>
      {isEditModalShown &&
        (isDesktop ? (
          <ModalView
            open={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement={<div className='text-lg font-medium'>Edit Selected Inspiration</div>}
          >
            {({ closeModal }) => (
              <EditNameAndImageView
                defaultName={props.name}
                defaultImage={props.thumbnail}
                onClose={closeModal}
                isSubmitDisabled={updateStyleDetailMutation.isPending}
                onSubmit={async (name, image) => {
                  try {
                    if (updateStyleDetailMutation.isPending) return;

                    await updateStyleDetailMutation.mutateAsync({ name, image, id: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to update style');
                  }
                }}
              />
            )}
          </ModalView>
        ) : (
          <MobileMenuSheet
            isOpen={isEditModalShown}
            onOpenChange={setIsEditModalShown}
            titleElement='Edit Selected Inspiration'
          >
            {({ closeModal }) => (
              <EditNameAndImageView
                defaultName={props.name}
                defaultImage={props.thumbnail}
                onClose={closeModal}
                isSubmitDisabled={updateStyleDetailMutation.isPending}
                onSubmit={async (name, image) => {
                  try {
                    if (updateStyleDetailMutation.isPending) return;

                    await updateStyleDetailMutation.mutateAsync({ name, image, id: props.id });
                    props.refetch();
                    closeModal();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to update style');
                  }
                }}
              />
            )}
          </MobileMenuSheet>
        ))}

      {isDesktop ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className='jusfity-center flex cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-40 p-2'>
            {isMutationEnabled ? (
              <>
                <DropdownMenuCheckboxItem
                  className='cursor-pointer px-2 py-1 hover:bg-gray-50'
                  onClick={() => {
                    setIsEditModalShown(true);
                  }}
                >
                  Edit
                </DropdownMenuCheckboxItem>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem
                  className='cursor-pointer px-2 py-1'
                  onClick={async () => {
                    try {
                      if (deleteStyleMutation.isPending) return;

                      await deleteStyleMutation.mutateAsync({ id: props.id });
                      props.refetch();
                    } catch (error) {
                      console.log(error);
                      toast.error('Failed to remove inspiration');
                    }
                  }}
                >
                  Delete
                </DropdownMenuCheckboxItem>
              </>
            ) : (
              <DropdownMenuCheckboxItem
                className='cursor-pointer px-2 py-1'
                onClick={async () => {
                  try {
                    if (toggleStyleLibraryMutation.isPending) return;

                    await toggleStyleLibraryMutation.mutateAsync({
                      id: props.id,
                      action: 'remove',
                    });
                    props.refetch();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to remove inspiration from library');
                  }
                }}
              >
                Remove From Library
              </DropdownMenuCheckboxItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <MobileMenuSheet
          triggerButton={
            <span className='jusfity-center flex cursor-pointer items-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          }
          titleElement={props.name}
          closeAfterClick={true}
        >
          {isMutationEnabled ? (
            <>
              <div
                className='cursor-pointer px-2 py-1 hover:bg-gray-50'
                onClick={() => {
                  setIsEditModalShown(true);
                }}
              >
                Edit
              </div>
              <div
                className='cursor-pointer px-2 py-1'
                onClick={async () => {
                  try {
                    if (deleteStyleMutation.isPending) return;

                    await deleteStyleMutation.mutateAsync({ id: props.id });
                    props.refetch();
                  } catch (error) {
                    console.log(error);
                    toast.error('Failed to remove inspiration');
                  }
                }}
              >
                Delete
              </div>
            </>
          ) : (
            <div
              className='cursor-pointer px-2 py-1'
              onClick={async () => {
                try {
                  if (toggleStyleLibraryMutation.isPending) return;

                  await toggleStyleLibraryMutation.mutateAsync({
                    id: props.id,
                    action: 'remove',
                  });
                  props.refetch();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to remove inspiration from library');
                }
              }}
            >
              Remove From Library
            </div>
          )}
        </MobileMenuSheet>
      )}
    </>
  );
};
