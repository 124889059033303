import React from 'react';
import { Link } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import { AccountPaths, RoutePaths } from '@/utils/route/constants';
import { useAccountCtx } from 'pages/dashboard/profile/helper';

interface TrialPackageBannerProps {
  children: React.ReactNode;
}

const BANNER_HEIGHT = '32px';

export const TrialPackageBanner: React.FC<TrialPackageBannerProps> = ({ children }) => {
  const { trial_period } = useAccountCtx();

  if (!trial_period) {
    return <div className='h-[100vh] max-h-[100vh] overflow-hidden'>{children}</div>;
  }

  const daysLeft = differenceInDays(trial_period.expiry_timestamp * 1000 || 0, new Date()) + 1;

  return (
    <section className='h-full w-full'>
      <div
        className={`flex h-[${BANNER_HEIGHT}] items-center justify-center rounded-t-lg bg-[#F4FCF1]`}
      >
        <div className='text-[12px] font-normal text-c-dark'>
          <span className='font-bold'>{daysLeft} days</span> left on your{' '}
          <span className='capitalize'>{trial_period.plan} Package Trial.</span>{' '}
          <Link
            to={`/${RoutePaths.ACCOUNT}/${AccountPaths.PAYMENT}`}
            className='underline underline-offset-[3px]'
          >
            Upgrade Now
          </Link>
        </div>
      </div>
      <div className={`h-[calc(100vh-32px)] w-full overflow-hidden`}>{children}</div>
    </section>
  );
};
