import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Label } from 'components/ui/label';
import { useMemo, useRef, useState } from 'react';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import invariant from 'tiny-invariant';
import { toast } from 'sonner';
import { ImageType } from '@/utils/store/constants';
import { scaleAndConvertFabricImageObjectToFile, getPreviousPath } from '@/utils/helper';
import { useUploadFileMutation } from 'pages/workspace/brand-library/product-edit/helper';
import {
  useCreateProductCategoryMutation,
  useGetProductCategories,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { ListSearchWithCreateButton } from 'components/workspace/brand-library/products-edit/ListSearchWithCreateButton';
import { useBulkProductsStore } from '../provider/helper';

const ProductField = {
  SKU: 'productCode',
  TITLE: 'productName',
} as const;

export const CustomizeProductBox = () => {
  const navigate = useNavigate();
  const skuRef = useRef<HTMLInputElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>();
  const canvasInstance = useProductCreateStore((state) => state.canvasInstance);
  const uploadFile = useUploadFileMutation();
  const { data: categories, refetch: refetchCategories } = useGetProductCategories();
  const createProductCategoryMutation = useCreateProductCategoryMutation();
  const updateProduct = useBulkProductsStore((state) => state.updateProduct);
  const [status, setStatus] = useState<'idle' | 'pending'>('idle');
  const selectedProduct = useBulkProductsStore((state) => state.selectedProduct);
  const { pathname } = useLocation();
  const previousPath = getPreviousPath(pathname);

  const categoryOptions = useMemo(() => {
    return categories?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categories]);

  if (!selectedProduct) {
    return <Navigate to={previousPath} replace />;
  }

  const navigateBack = () => {
    navigate(-1);
  };

  const updateProductInfo = async () => {
    try {
      if (!canvasInstance) return;
      if (!skuRef.current?.value || !titleRef.current?.value) {
        toast.error('Please fill all "Name" and "SKU" fields.');
        return;
      }

      setStatus('pending');
      const transparentImage = canvasInstance
        .getObjects()
        .find((obj) => obj.imageType === ImageType.TRANSPARENT);
      invariant(transparentImage, 'Transparent image not found');
      const transparentImageAsFile = await scaleAndConvertFabricImageObjectToFile(
        transparentImage as fabric.Image,
        'transparent.png',
      );
      const transparentImageUrl = await uploadFile.mutateAsync({ file: transparentImageAsFile });
      updateProduct({
        id: selectedProduct.id,
        sku: skuRef.current.value,
        name: titleRef.current.value,
        category: selectedCategoryId,
        transparentImage: transparentImageUrl,
      });
      toast.success('Product updated');
      navigateBack();
    } catch (error) {
      console.error('Error updating product', error);
      toast.error('Failed to upload product');
    } finally {
      setStatus('idle');
    }
  };

  const handleCategoryCreate = async (name: string) => {
    try {
      const newCategory = await createProductCategoryMutation.mutateAsync(name);
      toast.success('Category created');
      refetchCategories();
      setSelectedCategoryId(newCategory.id);
    } catch (error) {
      console.error('Error creating category', error);
      toast.error('Failed to create category');
      throw error;
    }
  };

  return (
    <div className='flex w-[300px] gap-2 rounded-lg bg-white px-3 pb-2 pt-3'>
      <div className='flex flex-col gap-2'>
        <div className='flex gap-2'>
          <div className='flex w-1/3 flex-col gap-2'>
            <Label htmlFor={ProductField.SKU} className='text-xs'>
              SKU
            </Label>
            <Input
              ref={skuRef}
              id={ProductField.SKU}
              name={ProductField.SKU}
              className='text-xs'
              defaultValue={selectedProduct.sku}
            />
          </div>
          <div className='flex w-2/3 flex-col gap-2 '>
            <Label htmlFor={ProductField.TITLE} className='text-xs'>
              Product Name
            </Label>
            <Input
              ref={titleRef}
              id={ProductField.TITLE}
              name={ProductField.TITLE}
              className='text-xs'
              defaultValue={selectedProduct.name}
            />
          </div>
        </div>
        <p className='text-xs font-medium'>Category name</p>
        <ListSearchWithCreateButton
          controlledValue={
            categoryOptions?.find((category) => category.value === selectedProduct.category)?.value
          }
          options={categoryOptions || []}
          onItemSelect={(option) => {
            setSelectedCategoryId(option.value);
          }}
          onCategoryCreate={handleCategoryCreate}
        />
        <Separator />
        <div className='flex flex-col gap-2'>
          <Button className='flex' onClick={updateProductInfo} disabled={status === 'pending'}>
            Save
          </Button>
          <Button className='flex' variant='outline' onClick={navigateBack}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
