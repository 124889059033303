import { EditContent } from './EditContent';
import { SceneProvider } from 'pages/ai-scene/review-edit/SceneProvider';
import { AiSceneResultTools } from './side-menu/AiSceneResultTools';
import { MobileSceneEditPageBackButton } from '../mobile-bottom-menu/_components/MobileSceneEditPageBackButton';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';

export const AiSceneReviewEdit = () => {
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;
  return (
    <div className='relative flex h-full w-full'>
      {isDesktop ? (
        <div className='h-full bg-c-ground p-4 md:hidden'>
          <AiSceneResultTools />
        </div>
      ) : (
        <MobileSceneEditPageBackButton />
      )}

      <div className='flex h-full w-full flex-col justify-between'>
        <SceneProvider>
          <div className='flex h-[100%]'>
            <EditContent />
          </div>
        </SceneProvider>
      </div>
    </div>
  );
};
