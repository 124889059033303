import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';
import { Member } from 'pages/dashboard/people/helper';

export type TUpdateUser = Pick<Member, 'email' | 'title' | 'authority'>;

const updateMember = async (orgId: number, userPayload: TUpdateUser) => {
  return apiClient.patch<Member>(
    getAccountApiUrl(orgId, AccountEndpoint.PATCH_ACCOUNT_MEMBER),
    userPayload,
  );
};

export const useUpdateMember = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.PATCH_ACCOUNT_MEMBER, organizationId }],
    mutationFn: (userPayload: TUpdateUser) => {
      return updateMember(organizationId, userPayload);
    },
  });
};

const deleteMember = async (orgId: number, email: string) => {
  const baseUrl = getAccountApiUrl(orgId, AccountEndpoint.DELETE_ACCOUNT_MEMBER);
  const queryParam = new URLSearchParams({ email });
  const url = `${baseUrl}?${queryParam.toString()}`;
  return apiClient.delete(url);
};

export const useDeleteMember = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.DELETE_ACCOUNT_MEMBER, organizationId }],
    mutationFn: (email: string) => deleteMember(organizationId, email),
  });
};
