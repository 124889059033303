import { AiSceneBulkCreateProvider } from '@/providers/ai-scene/bulk-create/AiSceneBulkCreateProvider';

import { Separator } from 'components/ui/separator';
import { QuickUploadAiEditImage } from './QuickUploadAiEditImage';

export const AiEditCreate = () => {
  return (
    <div className='relative flex h-full w-full bg-[#F5F5F5] md:justify-center'>
      <AiSceneBulkCreateProvider>
        <div className='m-4 h-[235px] w-[320px] rounded-lg bg-white p-3 shadow-md'>
          <h2 className='text-[15px] font-semibold'>Upload Image</h2>
          <Separator className='my-4' />
          <QuickUploadAiEditImage />
        </div>
      </AiSceneBulkCreateProvider>
    </div>
  );
};
