import { createContext } from 'react';
import { createAiSceneStore } from './store';

export type TAiSceneContext = ReturnType<typeof createAiSceneStore>;

export const AiSceneCreateContext = createContext<TAiSceneContext | undefined>(undefined);

export const updateOrMergeState =
  <T>(update: Partial<T> | ((currentState: T) => T)) =>
  (currentState: T): T => {
    // If 'update' is a function, invoke it with 'currentState' and return the result
    if (typeof update === 'function') {
      return update(currentState);
    }

    // If 'currentState' is an array and 'update' is not a function, attempt to add 'update' to the array
    if (Array.isArray(currentState)) {
      return [...currentState, update as any] as T;
    }

    // If 'currentState' is an object, merge 'update' assuming it's a Partial<T> of the same object type
    if (typeof currentState === 'object' && currentState !== null && !Array.isArray(update)) {
      return { ...currentState, ...(update as Partial<T>) };
    }

    // If none of the above conditions match, just return 'update'
    // This will effectively replace 'currentState' with 'update'
    return update as T;
  };
