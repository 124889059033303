import { useEditUserMutation } from 'pages/workspace/brand-library/product-edit/helper';
import { useCallback, useMemo } from 'react';
import { fabric } from 'fabric';
import { useDebounceCallback } from 'usehooks-ts';
import { cloneAndScaleCanvasWithObjects, convertFabricCanvasToFile } from '@/utils/helper';
import { useIsMounted } from 'usehooks-ts';

export const useSaveUserInteraction = () => {
  const { mutate } = useEditUserMutation();
  const isMounted = useIsMounted();

  const saveCanvasProgress = useCallback(
    async (canvas: fabric.Canvas) => {
      if (!canvas) return;
      if (!isMounted()) {
        // User might've navigated away from the page before the save operation completed.
        return;
      }

      const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvas);
      const file = convertFabricCanvasToFile({
        canvas: scaledCanvas,
        fileName: 'canvas-file',
        extension: 'png',
      });
      return await new Promise((resolve, reject) => {
        mutate(file, {
          onSuccess: resolve,
          onError: (error) => {
            console.error('Failed to save canvas progress', error);
            reject(error);
          },
        });
      });
    },
    [mutate, isMounted],
  );

  const debouncedSaveCanvasProgress = useDebounceCallback(saveCanvasProgress, 3500);
  return useMemo(
    () =>
      ({
        saveCanvasProgressDebounced: debouncedSaveCanvasProgress,
        saveCanvasProgressImmidiately: saveCanvasProgress,
      }) as const,
    [debouncedSaveCanvasProgress, saveCanvasProgress],
  );
};
