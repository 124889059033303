import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { ResizeLayout } from './ResizeLayout';
import { ReactNode } from 'react';

export const SizeMenu = ({
  onBackClick,
  children,
}: {
  onBackClick: () => void;
  children: ReactNode;
}) => (
  <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md md:w-[98vw] md:shadow-none'>
    <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold'>
      <ArrowLeftIcon />
      Size
    </button>
    <Separator />
    {children}
    <ResizeLayout />
  </div>
);
