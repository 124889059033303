import { FC } from 'react';
import { TooltipMenuItem } from './TooltipMenuItem';
import { AiSceneSideMenus } from 'components/ai-scene/side-menu/store';
import CursorIcon from '@/assets/icons/ai-scene-icons/cursor02.svg?react';
import TextTypeIcon from '@/assets/icons/ai-scene-icons/type01.svg?react';
import ColorsIcon from '@/assets/icons/ai-scene-icons/colors.svg?react';
import ImageUpIcon from '@/assets/icons/ai-scene-icons/imageUp.svg?react';
import { useParams } from 'react-router';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';
import { useIsAiEditType } from 'pages/ai-scene/useIsAiEditType';

export const NavSection: FC = () => {
  const projectId = useParams().projectId;
  const sceneId = useParams().sceneId;
  const basePath = useAISceneOrImagePath();
  const isAiEditType = useIsAiEditType();

  return (
    <div className='flex gap-5 md:hidden'>
      <TooltipMenuItem
        icon={<CursorIcon />}
        label='Create'
        menuType={AiSceneSideMenus.CREATE_TOOL}
        navigateLink={`/${basePath}/${projectId}`}
      />
      {(!isAiEditType || (!!isAiEditType && !!sceneId)) && (
        <>
          <TooltipMenuItem
            icon={<TextTypeIcon className='stroke-black' />}
            label='Text'
            menuType={AiSceneSideMenus.TEXT_TOOL}
          />
          <TooltipMenuItem
            icon={<ColorsIcon className='stroke-black' />}
            label='Filters'
            menuType={AiSceneSideMenus.FILTERS}
          />
          <TooltipMenuItem
            icon={<ImageUpIcon className='stroke-black' />}
            label='Add Logo'
            menuType={AiSceneSideMenus.ADD_LOGO}
          />
        </>
      )}
    </div>
  );
};
