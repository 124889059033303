import { Outlet, useLocation } from 'react-router';
import { OnboardingMultiStepForm } from 'components/onboarding/OnbordingView';
import { UpgradeWarningModal } from 'components/plan-guard/UpgradeWarningModal';
import { MainSidebar } from 'components/layout/MainSidebar';
import { BrandLibraryPaths, RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { TrialPackageBanner } from 'components/plan-guard/TrialPackageBanner';
import { MainHeader } from 'components/layout/MainHeader';
import { MobileBottomMenu } from 'components/layout/MobileBottomMenu';

const MAX_SUPPORTED_SCREEN_SIZE = 992;

const hasSidebarEnabled = (path: string) => {
  if (Object.values(BrandLibraryPaths).some((p) => path.includes(p))) return true;
  const isPhotoEditMode =
    path.includes(`${WorkspacePaths.BRAND_LIBRARY}/`) ||
    path.includes(`${RoutePaths.AI_SCENE}/`) ||
    path.includes(`${RoutePaths.AI_IMAGE}/`) ||
    path.includes(`${RoutePaths.AI_EDIT}/`);
  return !isPhotoEditMode;
};

export const LayoutPage: React.FC = () => {
  const { pathname } = useLocation();
  const isSidebarEnabled = hasSidebarEnabled(pathname);

  return (
    <>
      <OnboardingMultiStepForm />
      <UpgradeWarningModal />

      <TrialPackageBanner>
        {isSidebarEnabled && <MainHeader />}
        <div className={`flex h-[calc(100%-var(--header-height))] w-full md:pb-[70px]`}>
          {isSidebarEnabled && <MainSidebar />}
          <main className={` ${isSidebarEnabled ? 'w-main' : 'w-full'} md:w-full`}>
            <Outlet />
          </main>
        </div>
      </TrialPackageBanner>
      {isSidebarEnabled && <MobileBottomMenu />}
    </>
  );
};
