import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AiSceneGenerationEndpoint, AiSceneSuggestionsEndpoint } from '@/utils/fetch/constants';
import { getAiSceneGenerationApiUrl, getAiSceneSuggestionsApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TScene } from '../review/queries';

type ElementInfo = {
  id: string;
  position: { x: number; y: number };
  scale: { x: number; y: number };
  flip: { x: boolean; y: boolean };
  rotation: number;
};

export type TGenerationPayload = {
  sceneDimensions: {
    x: number;
    y: number;
  };
  productIds?: string[] | null;
  prompt?: string;
  negativePrompt?: string;
  productPlacementImage?: File | null;
  productPlacementImageUrl?: string | null;
  staticEditsImage?: File | null;
  staticEditsImageUrl?: string | null;
  serializedCanvasData: string | null;
  inspirationalImages?: File[] | null;
  inspirationalImageUrls?: string[] | null;
  styleIds?: string[];
  templateId?: string;
  brandStyleId?: string | null;
  lightingStyleId?: string | null;
  elements?: ElementInfo[] | null;
  faceTextComposition?: boolean;
  enhancePrompt?: boolean;
  count?: number;
};
type TGenerationRawResult = {
  results: TScene[];
};

const createAiScene = async ({
  projectId,
  organizationId,
  payload,
}: {
  organizationId: number;
  projectId: string;
  payload: TGenerationPayload;
}) => {
  const formData = new FormData();
  formData.append('sceneDimensions', JSON.stringify(payload.sceneDimensions));
  if (payload.productIds) formData.append('productIds', JSON.stringify(payload.productIds));
  if (payload.prompt) formData.append('prompt', payload.prompt);
  if (payload.negativePrompt) formData.append('negativePrompt', payload.negativePrompt);
  if (payload.styleIds) formData.append('styleIds', JSON.stringify(payload.styleIds));
  if (payload.templateId) formData.append('templateId', payload.templateId);
  if (payload.brandStyleId) formData.append('brandStyleId', payload.brandStyleId);
  if (payload.lightingStyleId) formData.append('lightingStyleId', payload.lightingStyleId);
  if (payload.elements) formData.append('elements', JSON.stringify(payload.elements));
  if (payload.productPlacementImage) {
    formData.append('productPlacementImage', payload.productPlacementImage);
  }
  if (payload.productPlacementImageUrl) {
    formData.append('productPlacementImageUrl', payload.productPlacementImageUrl);
  }
  if (payload.staticEditsImage) {
    formData.append('staticEditsImage', payload.staticEditsImage);
  }
  if (payload.staticEditsImageUrl) {
    formData.append('staticEditsImageUrl', payload.staticEditsImageUrl);
  }
  payload.inspirationalImages?.forEach((image) => {
    formData.append('inspirationalImages', image);
  });
  if (payload.inspirationalImageUrls)
    formData.append('inspirationalImageUrls', JSON.stringify(payload.inspirationalImageUrls));
  if (payload.faceTextComposition) {
    formData.append('faceTextComposition', payload.faceTextComposition.toString());
  }
  if (payload.enhancePrompt) {
    formData.append('enhancePrompt', payload.enhancePrompt.toString());
  }
  if (payload.serializedCanvasData) {
    formData.append('serializedCanvasData', payload.serializedCanvasData);
  }
  if (payload.count) formData.append('count', payload.count.toString());

  const url = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  return apiClient.post<TGenerationRawResult>(url, formData);
};

export const useCreateAiSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE, organizationId],
    mutationFn: ({ projectId, payload }: { projectId: string; payload: TGenerationPayload }) => {
      return createAiScene({
        projectId,
        payload,
        organizationId,
      });
    },
  });
};

export type TGetRecentlyUsedPromptsResponse = {
  sceneDimensions: {
    x: number;
    y: number;
  };
  productIds: Array<string>;
  rawPrompt: string;
  prompt: string;
  negativePrompt: string;
  inspirationalImageUrls: Array<string>;
  styleIds: Array<string>;
  templateId: string;
  elements: Array<{
    id: string;
    position: {
      x: string;
      y: string;
    };
    scale: {
      x: string;
      y: string;
    };
    flip: {
      x: string;
      y: string;
    };
    rotation: number;
  }>;
};

const getRecentlyUsedPrompts = async (organizationId: number) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.RECENT,
  });
  return apiClient.get<{ results: TGetRecentlyUsedPromptsResponse[] }>(url);
};

export const useGetRecentlyUsedPromptsQuery = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useQuery({
    queryKey: [AiSceneSuggestionsEndpoint.RECENT, organizationId],
    queryFn: () => {
      return getRecentlyUsedPrompts(organizationId);
    },
    retry: false,
  });
};

export type TRecommended = {
  dimensions: {
    x: string;
    y: string;
  };
  prompt: string;
  negativePrompt: string;
  productIds: string[];
};

type TGetRecommendedPayload = {
  organizationId: number;
  dimensions: {
    x: string;
    y: string;
  };
  prompt: string;
  negativePrompt: string;
  productIds: string[];
};

const getRecommendedParameters = async (props: TGetRecommendedPayload) => {
  const { organizationId, ...payload } = props;
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.RECOMMENDED,
  });
  const response = await apiClient.post<TRecommended>(url, payload);
  return response.data;
};

export const useGetRecommendedQuery = (props: Omit<TGetRecommendedPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useQuery({
    queryKey: [{ url: AiSceneSuggestionsEndpoint.RECOMMENDED, organizationId, ...props }],
    queryFn: ({ queryKey }) => {
      return getRecommendedParameters(queryKey[0]);
    },
    placeholderData: (prev) => prev,
  });
};

const autoCompletePrompt = async ({
  prompt,
  productIds,
  organizationId,
}: {
  organizationId: number;
  prompt: string;
  productIds: string[];
}) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.PROMPT,
  });
  const response = await apiClient.post<{ result: string }>(url, { prompt, productIds });
  return response.data.result;
};

export const useAutoCompleteMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneSuggestionsEndpoint.PROMPT, organizationId],
    mutationFn: ({ productIds, prompt }: { productIds: string[]; prompt: string }) => {
      return autoCompletePrompt({
        prompt,
        productIds,
        organizationId,
      });
    },
  });
};

type TKeywordSuggestPayload = {
  prompt: string;
  productIds: Array<string>;
  currentSuggestions: Array<string>;
  requestedSuggestionCount: number;
  organizationId: number;
};

const suggestKeywords = async ({
  prompt,
  productIds,
  currentSuggestions,
  requestedSuggestionCount,
  organizationId,
}: TKeywordSuggestPayload) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.KEYWORD,
  });
  const response = await apiClient.post<{ results: string[] }>(url, {
    prompt,
    productIds,
    currentSuggestions,
    requestedSuggestionCount,
  });
  return response.data.results;
};

export const useKeywordSuggestionMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneSuggestionsEndpoint.PROMPT, organizationId],
    mutationFn: (props: Omit<TKeywordSuggestPayload, 'organizationId'>) => {
      return suggestKeywords({ ...props, organizationId });
    },
  });
};
