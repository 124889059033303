import { useEffect } from 'react';
import { toast } from 'sonner';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { convertImageUrlToFile } from '@/utils/helper';
import { useCraitStore } from '@/utils/store/store';

export const useSyncSelectedSettings = () => {
  const pendingSettings = useCraitStore((state) => state.pendingSettingsToUse);
  const setPendingSettingsToUse = useCraitStore((state) => state.setPendingSettingsToUse);
  const updateAiScenePromptTab = useAiSceneCreateStore((s) => s.updateAiScenePromptTab);
  const addAiSceneSelectedStyles = useAiSceneCreateStore((state) => state.addAiSceneSelectedStyles);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);

  useEffect(() => {
    if (!pendingSettings) return;

    const updateLocalStore = async () => {
      try {
        const inspirationalPhoto = pendingSettings.parameters.inspirationalImageUrls[0];
        const inspirationalPhotoAsFile = inspirationalPhoto
          ? await convertImageUrlToFile(inspirationalPhoto, 'inspirationalPhoto')
          : undefined;

        updateAiScenePromptTab({
          prompt: pendingSettings.parameters.prompt,
          negativePrompt: pendingSettings.parameters.negativePrompt || undefined,
          inspirationalPhoto: inspirationalPhotoAsFile,
          faceTextComposition: pendingSettings.parameters.faceTextComposition || false,
          enhancePrompt: pendingSettings.parameters.enhancePrompt || false,
          brandStyleId: pendingSettings.summary.brand_style || null,
          lightingStyleId: pendingSettings.summary.lighting_style || null,
        });
        addAiSceneSelectedStyles(pendingSettings.summary.styles || []);
        setPendingSettingsToUse(undefined);
      } catch (e) {
        toast.error('Failed to update prompt tab with last generation data');
      }
    };
    updateLocalStore();
  }, [pendingSettings, updateAiScenePromptTab, addAiSceneSelectedStyles, updateCanvasState]);
};
