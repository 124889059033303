import React from 'react';
import { CheckoutForm } from './CheckoutForm';
import { useSubscriptionStore } from '@/providers/stripe/subscription/helper';
import { StripeElements } from '@/providers/stripe/StripeElements';
import { useGetPaymentPlans } from '../queries';
import { toast } from 'sonner';

type DashboardPaymentProps = {
  closeModal: () => void;
  planType: string;
  period: string;
  children?: React.ReactNode;
};

export const DashboardPayment: React.FC<DashboardPaymentProps> = ({
  closeModal,
  planType,
  period,
  children,
}) => {
  const subId = useSubscriptionStore((state) => state.subscriptionId);
  const clientSecret = useSubscriptionStore((state) => state.clientSecret);
  const { data: paymentPlans } = useGetPaymentPlans();

  if (!subId || !clientSecret || !paymentPlans) {
    toast.error('Subscription ID, Client Secret, or Payment Plans not found.');
    closeModal();
    return null;
  }

  return (
    <div className='flex items-start gap-4 md:flex-col'>
      <div className='w-[320px] pt-5 md:h-[220px] md:w-full'>{children}</div>
      <StripeElements clientSecret={clientSecret}>
        <CheckoutForm plan={planType} period={period} />
      </StripeElements>
    </div>
  );
};
