import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command';
import { Button, ButtonProps } from 'components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ScrollArea } from 'components/ui/scroll-area';
import { PopoverContentProps } from '@radix-ui/react-popover';

type ScrollAreaProps = React.ComponentPropsWithoutRef<typeof ScrollArea>;
export type TMenuOption = { value: string; label: string };

interface MenuItemViewProps {
  options: TMenuOption[];
  selectMessage: string;
  containerClass?: string;
  inputPlaceholder?: string;
  triggerProps?: ButtonProps;
  popoverContentProps?: PopoverContentProps;
  defaultValue?: string;
  emptyMessage?: string;
  onItemSelect?: (option: TMenuOption) => void;
  controlledValue?: string;
  children?: React.ReactNode;
  footerButton?: React.ReactNode;
}

export const MenuItemView: FC<MenuItemViewProps & ScrollAreaProps> = ({
  triggerProps,
  options,
  containerClass,
  inputPlaceholder,
  emptyMessage,
  selectMessage,
  defaultValue,
  popoverContentProps,
  onItemSelect,
  controlledValue,
  children,
  footerButton,
  ...scrollAreaProps
}) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(controlledValue || defaultValue || '');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (controlledValue === undefined) return;

    setSelectedValue(controlledValue);
  }, [controlledValue]);

  const handleItemSelect = (props: TMenuOption) => {
    const newValue = props.value === selectedValue ? '' : props.value;
    const newOption = options.find((option) => option.value === newValue);
    setOpen(false);
    onItemSelect?.(newOption || { value: '', label: '' });
    setSelectedValue(newValue);
  };

  const activeOptionLabel = useMemo(
    () => options.find((option) => option.value === selectedValue)?.label,
    [options, selectedValue],
  );

  return (
    <div ref={containerRef} className={containerClass || ''}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className='emre w-[200px] justify-between'
            {...triggerProps}
          >
            {
              <span className=' overflow-hidden truncate text-ellipsis text-nowrap font-normal'>
                {activeOptionLabel || selectMessage}
              </span>
            }
            <ChevronsUpDown className='ml-2 size-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className='w-[200px] p-0 backdrop-blur-0'
          {...popoverContentProps}
          container={containerRef.current}
        >
          <ScrollArea className='h-[300px]' {...scrollAreaProps}>
            <Command>
              {inputPlaceholder && <CommandInput placeholder={inputPlaceholder} />}
              {children}
              <CommandEmpty>{emptyMessage}</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    onSelect={() => handleItemSelect(option)}
                  >
                    <Check
                      className={cn(
                        'mr-2 size-4',
                        option.value === selectedValue ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </ScrollArea>
          {footerButton}
        </PopoverContent>
      </Popover>
    </div>
  );
};
