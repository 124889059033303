import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { COMMON_DIMENSIONS } from '@/utils/store/constants';
import { Checkbox } from 'components/ui/checkbox';

export const DimensionsContent = () => {
  const selectedDimensions = useBannerCreateStore((s) => s.dimensions);
  const addDimensions = useBannerCreateStore((s) => s.addDimensions);
  const removeDimensions = useBannerCreateStore((s) => s.removeDimensions);

  const options = COMMON_DIMENSIONS.map((dimension) => ({
    ...dimension,
    checked: selectedDimensions.some((selectedDimension) => selectedDimension.id === dimension.id),
  }));
  const areAllChecked = options.every((option) => option.checked);

  const handleSelectAllChange = () => {
    if (areAllChecked) {
      const ids = options.map((option) => option.id);
      removeDimensions(ids);
    } else {
      const targetedOptions = options.filter((option) => !option.checked);
      const formattedOptions = targetedOptions.map(({ checked, ...baseOption }) => baseOption);
      addDimensions(formattedOptions);
    }
  };

  return (
    <div className='flex flex-col space-y-3'>
      <div className='flex items-center space-x-2'>
        <Checkbox
          id='selectAll'
          checked={areAllChecked}
          onCheckedChange={handleSelectAllChange}
          className='border border-white text-white'
        />
        <label htmlFor='selectAll' className='cursor-pointer text-sm font-medium leading-none'>
          Select All
        </label>
      </div>
      {options.map((option) => (
        <div key={option.id} className='flex items-center space-x-2'>
          <Checkbox
            id={option.id}
            checked={option.checked}
            onCheckedChange={(isChecked) => {
              if (isChecked) {
                const { checked, ...baseOption } = option;
                addDimensions([baseOption]);
              } else {
                removeDimensions([option.id]);
              }
            }}
            className='border border-white text-white'
          />
          <label htmlFor={option.id} className='cursor-pointer text-sm font-medium leading-none'>
            {option.text}
          </label>
        </div>
      ))}
    </div>
  );
};
