import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'components/ui/sonner';
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom/client';
import './index.css';
import { UserProvider } from './providers/user/UserProvider';
import { applyFabricConfig } from './utils/fabric.config';
import { Router } from './utils/route/Router';

import * as Sentry from '@sentry/react';
import {
  COOKIE_PREFERENCES_KEY,
  CookieConsentBanner,
} from 'components/cookie-consent/CookieConsentBanner';
import type { CookiePreferences } from 'components/cookie-consent/helper';
import { Breakpoints } from './utils/general/constant';

let isSentryAllowed = true;
const cookieConsentB64 = Cookies.get(COOKIE_PREFERENCES_KEY);
if (cookieConsentB64) {
  try {
    const cookieConsent: CookiePreferences = JSON.parse(atob(cookieConsentB64));
    if (cookieConsent.analytics === false) {
      isSentryAllowed = false;
    }
  } catch (error) {
    console.error(error);
  }
}
if (isSentryAllowed) {
  Sentry.init({
    dsn: 'https://63ad9919d3b7edda3b93a1bc6a81445f@o4507356903899136.ingest.us.sentry.io/4507356906455040',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Environment
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.crait\.it\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

applyFabricConfig();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 30 seconds
      staleTime: 1000 * 30,
      retry: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <Router />
    </UserProvider>
    <CookieConsentBanner />
    <Toaster position={window.innerWidth < Breakpoints.TABLET ? 'top-right' : 'bottom-right'} />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);
