interface ValidationBulletProps {
  status: 'success' | 'error' | 'idle';
  text: string;
}

export const ValidationBullet = ({ text, status }: ValidationBulletProps) => {
  const prefixIcon =
    status === 'error'
      ? 'i-mdi-close text-red-500'
      : status === 'success'
        ? 'i-mdi-tick text-green-500'
        : 'i-mdi-circle-outline text-gray-500';
  return (
    <div className='flex items-start gap-2'>
      <div className='size-3'>
        <span className={`${prefixIcon} size-3`} />
      </div>
      <span className='text-sm font-medium text-black'>{text}</span>
    </div>
  );
};
