import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { FC, useEffect, useRef, useState } from 'react';
import { FileUploadInput } from '../products/upload/FileUploadInput';
import { Input } from 'components/ui/input';
import { toast } from 'sonner';
import { convertImageUrlToFile } from '@/utils/helper';
import { CraitButton } from 'components/common/CraitButton';

interface EditNameAndImageViewProps {
  defaultImage: string;
  defaultName: string;
  onSubmit: (name: string, image: File) => void;
  onClose: () => void;
  isSubmitDisabled?: boolean;
}

export const EditNameAndImageView: FC<EditNameAndImageViewProps> = ({
  defaultImage,
  defaultName,
  onSubmit,
  onClose,
  isSubmitDisabled,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const nameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const syncProps = async () => {
      if (nameInput.current) {
        nameInput.current.value = defaultName;
      }
      const imageAsFile = await convertImageUrlToFile(defaultImage);
      setFile(imageAsFile);
    };
    syncProps();
  }, [defaultName, defaultImage]);

  return (
    <>
      <div className='mt-4 flex flex-col items-center gap-6'>
        <FileUploadInput defaultImage={defaultImage} onFileUpload={setFile} />
        <div className='flex w-72 flex-col gap-3'>
          <Input ref={nameInput} placeholder='Enter name' defaultValue={defaultName} />
          <CraitButton
            className='w-full'
            disabled={isSubmitDisabled || !file}
            onClick={() => {
              if (!nameInput.current || !file) {
                toast.error('Please enter a name and select an image to update the fields');
                return;
              }

              onSubmit(nameInput.current.value, file);
            }}
          >
            Update
          </CraitButton>
          <Button variant='outline' className='w-full' onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};
