import { useState, useEffect, useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useInView } from 'react-intersection-observer';
import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { RefetchView } from '../RefetchView';
import { useResetInfiniteSearchCache } from './useResetInfiniteSearchCache';
import { HowItWorksBox } from '../HowItWorksBox';
import { AddInspirationModal } from './AddNewInspiration';
import { SelectedStyleList } from './SelectedStyleList';
import { StyleItems } from './StyleItems';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import {
  TStyleResponse,
  useGetInfiniteSearchResults,
  useGetProductCategories,
  useGetStylesCategories,
} from '../../helper/queries';

const MAX_PAGES_IN_CACHE = 2;

export const StylesTab = ({ returnCreateToolClick }: { returnCreateToolClick: () => void }) => {
  const [selectedCategory, setSelectedCategory] = useState<
    { name: string; id: string } | undefined
  >(undefined);
  const [isOnlyLibrary, setIsOnlyLibrary] = useState(false);
  const [keyword, setKeyword] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TStyleResponse>({
    keyword,
    onlyOrgLibrary: isOnlyLibrary,
    categoryId: selectedCategory?.id,
    searchSection: 'styles',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.styles;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 0,
  });
  useResetInfiniteSearchCache<TStyleResponse>('styles', MAX_PAGES_IN_CACHE);

  const {
    data: categories,
    isError: categoriesError,
    isLoading: categoriesLoading,
    refetch: refetchCategories,
  } = useGetStylesCategories();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allStyles = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.styles.items) || [];
  }, [infiniteData]);

  if (isError) {
    return (
      <RefetchView
        disabled={isLoading}
        onClick={() => {
          refetch();
        }}
      >
        <span>Failed to load inspirations.</span>
      </RefetchView>
    );
  }

  const selectOnlyLibraryCategory = () => {
    setSelectedCategory(undefined);
    setIsOnlyLibrary(true);
  };

  return (
    <div className='flex h-full flex-col gap-3 pt-3'>
      <HowItWorksBox
        text='Select an inspiration or upload yours to set the vibe of your scene.'
        linkText="Don't show this again"
        parentComponent='Inspiration'
      />
      <Command className='flex h-10 justify-center rounded-lg bg-background'>
        <CommandInput placeholder='Search Style Inspirations' onValueChange={setKeyword} />
      </Command>{' '}
      <ScrollArea className='h-[36px] w-[260px] sm:w-[88vw]'>
        <div className='flex gap-2'>
          <div
            className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-3 py-1 text-[12px] font-semibold ${
              !selectedCategory && !isOnlyLibrary ? 'bg-crait-green-primary' : ''
            }`}
            onClick={() => {
              setSelectedCategory(undefined);
              setIsOnlyLibrary(false);
            }}
          >
            All
          </div>
          <div
            id='styleFromLibrary'
            className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-2 py-1 text-[12px] font-semibold ${
              isOnlyLibrary ? 'bg-crait-green-primary' : ''
            }`}
            onClick={selectOnlyLibraryCategory}
          >
            My Inspirations
          </div>
          {categories?.map((category) => (
            <div
              key={category.id}
              className={`h-[26px] cursor-pointer whitespace-nowrap rounded-[50px] border border-[#EAEAEA] px-2 py-1 text-[12px] font-semibold ${
                selectedCategory?.id === category.id ? 'bg-crait-green-primary' : ''
              }`}
              onClick={() => {
                setIsOnlyLibrary(false);
                setSelectedCategory(category);
              }}
            >
              {category.name}
            </div>
          ))}
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <SelectedStyleList />
      <ScrollArea className='h-full w-full'>
        <div className='flex flex-wrap items-start gap-x-2.5 gap-y-3 p-1 md:justify-around'>
          <AddInspirationModal selectOnlyLibraryCategory={selectOnlyLibraryCategory} />
          <StyleItems styles={allStyles || []} returnCreateToolClick={returnCreateToolClick} />
        </div>
        {hasNextPage && (
          <div className='relative flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
