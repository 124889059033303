import { FC } from 'react';

import {
  TColorResponse,
  useUpdateColorMutation,
} from '@/components/workspace/brand-library/brand-kit/queries';

import { ColorDropdown } from './ColorDropdown';

interface EditColorDropdownWrapperProps {
  refetch: () => void;
}
export const EditColorDropdownWrapper: FC<TColorResponse & EditColorDropdownWrapperProps> = ({
  refetch,
  ...colorProps
}) => {
  const updateColorMutation = useUpdateColorMutation();

  return (
    <ColorDropdown
      {...colorProps}
      onSaveClicked={async (params) => {
        updateColorMutation.mutate(
          {
            id: colorProps.id,
            ...params,
          },
          {
            onSuccess: () => {
              refetch();
            },
          },
        );
      }}
      refetch={refetch}
    >
      <span
        className='relative flex size-[54px] items-center gap-2 overflow-hidden rounded-[8px] '
        style={{ backgroundColor: colorProps.hexCode }}
      />

      <div className='flex max-w-[58px] items-center justify-center gap-1'>
        <span className='overflow-hidden text-ellipsis whitespace-nowrap text-[12px] text-c-dark'>
          {colorProps.name ? colorProps.name : colorProps.hexCode.toUpperCase()}
        </span>
      </div>
    </ColorDropdown>
  );
};
