import { FC, ReactNode } from 'react';
import { TPaymentProduct, TStripePriceInfo } from '../queries';
import { Separator } from 'components/ui/separator';
import PlanIcon from '@/assets/icons/planElement.svg?react';

import { ContactUsModal } from './ContactUsModal';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';

type PaymentPlanCardProps = {
  children?: ReactNode;
  isCurrentPlan?: boolean;
  paymentPeriod: TStripePriceInfo['paymentPeriod'];
};

export const PaymentProduct: FC<TPaymentProduct & PaymentPlanCardProps> = ({
  children,
  isCurrentPlan,
  paymentPeriod,
  ...plan
}) => {
  const isEnterprise = plan.type === 'enterprise';
  const priceByPeriod =
    plan.prices.find((price) => price.paymentPeriod === paymentPeriod)?.price || '';

  const { width } = useWindowSize();

  const isLargeDesktop = width > Breakpoints.LARGE_DESKTOP;

  return (
    <div
      className={`${
        isEnterprise && isLargeDesktop ? 'col-span-4 row-start-2 h-[194px]' : 'h-[600px]'
      } sm:h-auto `}
    >
      <div
        className={`flex   ${
          isEnterprise && isLargeDesktop ? 'w-full flex-row' : 'w-[full]  flex-col'
        }  h-full  gap-5 rounded-lg border border-slate-200 bg-white p-4 text-crait-primary shadow-sm`}
      >
        <div className='flex justify-between'>
          <div className='flex w-full flex-col gap-5'>
            <div className='flex items-center gap-2 text-[15px] font-semibold'>
              <span className='text-nowrap'>{plan.name}</span>{' '}
            </div>
            {!isEnterprise ? (
              <div className='flex flex-col items-start justify-start gap-1 '>
                <div className='flex items-center gap-1'>
                  <h6 className='text-[20px] font-bold'>{priceByPeriod}</h6>
                  <p className=' text-[13px]'>/{paymentPeriod}</p>
                </div>
                <p className='text-[13px] '>{plan.description}</p>
              </div>
            ) : (
              <div className='flex flex-col items-start gap-2'>
                <div className='text-[20px] font-bold'>Let's Talk</div>
                <p className='text-[13px] '>{plan.description}</p>
              </div>
            )}

            {isEnterprise && isLargeDesktop ? <ContactUsModal /> : children}
          </div>
        </div>

        {isEnterprise && isLargeDesktop && <Separator orientation='vertical' />}

        <ul
          className={`flex flex-col  ${
            isEnterprise && isLargeDesktop ? 'flex-1 flex-wrap gap-5 pt-3' : ' gap-3'
          }`}
        >
          {plan.benefits.existingBenefits.map((feature, index) => (
            <div key={index} className='flex items-center gap-2'>
              <PlanIcon />
              <p className='text-[13px] font-medium'>{feature}</p>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
