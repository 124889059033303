import { cn } from '@/lib/utils';
import { FC, ReactNode } from 'react';

const ICON_WRAPPER_CL =
  'absolute right-1.5 top-1.5 rounded-full p-1 block border border-slate-50/25 flex items-center justify-center cursor-pointer';
const ICON_CL = 'size-3 select-none';

interface BookmarkIconProps {
  isBookmarked: boolean;
  onBookmark?: (shouldBookmark: boolean) => Promise<void>;
}
export const BookmarkIcon: FC<BookmarkIconProps> = ({ isBookmarked, onBookmark }) => {
  const icon = isBookmarked ? (
    <span className={`i-mdi-bookmark-box-multiple ${ICON_CL} text-yellow-500`}></span>
  ) : (
    <span className={`i-mdi-bookmark-box-multiple-outline ${ICON_CL}`}></span>
  );

  return (
    <div
      className={ICON_WRAPPER_CL}
      onClick={(e) => {
        e.stopPropagation();
        onBookmark?.(!isBookmarked);
      }}
    >
      {icon}
    </div>
  );
};

interface BasicCardProps {
  name: string;
  image: string;
  imageWrapperClass?: string;
  contentClass?: string;
  children: ReactNode;
}

export const BasicCard: FC<BasicCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  name,
  image,
  imageWrapperClass,
  contentClass,
  children,
  ...props
}) => {
  return (
    <div
      className={`relative flex w-[136px] cursor-pointer flex-col items-center gap-1.5 sm:w-[48%] ${contentClass}`}
      {...props}
    >
      {children}
      <div
        className={cn(
          `h-[136px] w-full overflow-hidden rounded-md hover:outline hover:outline-2 hover:outline-black`,
          imageWrapperClass,
        )}
      >
        <img crossOrigin='anonymous' src={image} className='h-full w-full object-cover' />
      </div>
      <p className='line-clamp-2 max-w-[118px] text-ellipsis text-[12px] font-semibold sm:max-w-[90%]'>
        {name}
      </p>
    </div>
  );
};
