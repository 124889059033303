import { FC, useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFavoriteSceneMutation } from 'components/ai-scene/review/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';
import { TooltipItem } from 'components/common/TooltipItem';

const STAR_WRAPPER_CL =
  'absolute right-3 top-3 rounded-full p-1 block  flex items-center justify-center cursor-pointer inline-flex cursor-pointer items-center rounded-full p-2 bg-black bg-opacity-50 rounded-full text-white';
const STAR_ICON_CL = 'size-4 select-none';

interface ISceneFavoriteActionProps {
  isStarred: boolean;
}

export const SceneFavoriteAction: FC<ISceneFavoriteActionProps> = ({ isStarred }) => {
  const { projectId, sceneId } = useParams<{ projectId: string; sceneId: string }>();
  const { refetchScenes } = useRefetchGeneratedScenes();

  if (!projectId || !sceneId) {
    return null;
  }

  const [isFavorite, setIsFavorite] = useState(isStarred);
  const favoriteScene = useFavoriteSceneMutation();
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    setIsFavorite(isStarred);
  }, [isStarred]);

  const starIcon = isFavorite ? (
    <span className={`i-mdi-bookmark size-5 ${STAR_ICON_CL} text-[#E16236]`}></span>
  ) : (
    <span className={`i-mdi-bookmark-outline size-5 ${STAR_ICON_CL}`}></span>
  );

  const toggleFavorite = async () => {
    const nextIsFavorite = !isFavorite;
    try {
      setIsFavorite(nextIsFavorite);
      if (favoriteScene.isPending) return;
      const favoriteAction = nextIsFavorite ? 'add' : 'remove';
      await favoriteScene.mutateAsync({ projectId, sceneId, action: favoriteAction });
      queryClient.invalidateQueries({
        exact: false,
        queryKey: [{ searchSection: 'ai_scenes' }],
      });

      const message = nextIsFavorite ? 'Scene is saved.' : 'Scene is unsaved.';
      refetchScenes();
      toast.success(message);
    } catch (error) {
      setIsFavorite(isFavorite);
      toast.error('Failed to save the scene.');
    }
  };

  return (
    <TooltipItem
      side='bottom'
      className='focus-visible:ring-0'
      trigger={
        <div
          className={STAR_WRAPPER_CL}
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorite();
          }}
        >
          {starIcon}
        </div>
      }
    >
      Save
    </TooltipItem>
  );
};
