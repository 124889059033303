import { useEffect, useMemo, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import { useAiSceneBulkCreateStore } from '@/providers/ai-scene/bulk-create/hooks';
import { CanvasItem } from './CanvasItem';
import { cn } from '@/lib/utils';
import { TabOption, useSceneTypeParam } from 'components/ai-scene/sidebar/useSceneTypeParam';
import { Separator } from 'components/ui/separator';
import {
  CHILD_CANVAS_SIZE,
  MAIN_CANVAS_SIZE,
  TAdjustScaleOptions,
  getChildCanvasImageOptions,
  getScaledOptionsForChildCanvas,
  synchronizeObjectsAcrossCanvases,
  waitForImageToRenderOnCanvas,
} from './helper';
import { useIsBulkSceneCreated } from 'components/ai-scene/sidebar/useIsBulkSceneCreated';
import { useSyncExternalImage } from './useSyncExternalImage';
import { toast } from 'sonner';
import { appendPluralSuffix, serializeCanvas } from '@/utils/helper';

export const BulkCanvasWrapper = () => {
  const [, setApi] = useState<CarouselApi>();
  const renderedCanvasInstances = useRef<Record<string, fabric.Canvas>>({});
  const bulkSceneImages = useAiSceneBulkCreateStore((state) => state.productImages);
  const updateMainCanvasState = useAiSceneBulkCreateStore((state) => state.updateMainCanvasState);
  const resetAll = useAiSceneBulkCreateStore((state) => state.resetAll);
  const [sceneType] = useSceneTypeParam();
  const isBulkSceneCreated = useIsBulkSceneCreated();
  const mainCanvasInstance = useRef<fabric.Canvas>();

  useSyncExternalImage({
    mainCanvasInstance: mainCanvasInstance.current,
    canvasInstances: Object.values(renderedCanvasInstances.current),
  });

  useEffect(() => {
    if (sceneType === TabOption.SINGLE) {
      renderedCanvasInstances.current = {};
      mainCanvasInstance.current?.dispose();
      mainCanvasInstance.current = undefined;
      resetAll();
    }
  }, [sceneType, resetAll]);

  const mainCanvas = useMemo(() => {
    const firstCanvas = bulkSceneImages[0];
    if (!firstCanvas) return null;

    return (
      <CanvasItem
        options={{
          dimensions: { width: MAIN_CANVAS_SIZE, height: MAIN_CANVAS_SIZE },
        }}
        canvasData={firstCanvas}
        onCanvasRendered={(canvasInstance) => {
          mainCanvasInstance.current = canvasInstance;
          mainCanvasInstance.current.on('object:modified', (e) => {
            if (!mainCanvasInstance.current) return;

            const target = e.target as fabric.Image;
            const existingOptions: TAdjustScaleOptions = {
              left: target.left || 0,
              top: target.top || 0,
              scaleX: target.scaleX || 1,
              scaleY: target.scaleY || 1,
              angle: target.angle || 0,
              latestSavedScaling: target.latestSavedScaling || {
                scaleX: 1,
                scaleY: 1,
              },
            };
            synchronizeObjectsAcrossCanvases(
              Object.values(renderedCanvasInstances.current),
              existingOptions,
            );
            // This is important to keep scaling consistent across scaling operations (up/down).
            // This way we keep scale syncing to relative to the latest scaling operation.
            target.latestSavedScaling = {
              scaleX: target.scaleX || 1,
              scaleY: target.scaleY || 1,
            };
            updateMainCanvasState(serializeCanvas(mainCanvasInstance.current));
          });
          mainCanvasInstance.current.on('object:added', () => {
            if (!mainCanvasInstance.current) return;

            updateMainCanvasState(serializeCanvas(mainCanvasInstance.current));
          });
          updateMainCanvasState(serializeCanvas(mainCanvasInstance.current));
        }}
      />
    );
  }, [bulkSceneImages, updateMainCanvasState]);

  const bulkSceneCanvases = useMemo(() => {
    return bulkSceneImages.map((canvasData, idx) => (
      <CarouselItem key={canvasData.id} className='relative flex basis-[180px] items-center gap-8'>
        <div>
          <div className='overflow-hidden rounded-xl border border-gray-400'>
            <CanvasItem
              options={{
                dimensions: { width: CHILD_CANVAS_SIZE, height: CHILD_CANVAS_SIZE },
                selection: false,
              }}
              canvasData={canvasData}
              onCanvasRendered={async (canvasInstance) => {
                if (!mainCanvasInstance.current) return;

                const existingObjects = mainCanvasInstance.current.getObjects();
                let existingProductReference = existingObjects.find(
                  (obj) => obj.imageType === 'product',
                );
                if (!existingProductReference) {
                  existingProductReference = await waitForImageToRenderOnCanvas(
                    mainCanvasInstance.current,
                    'product',
                  );
                }

                renderedCanvasInstances.current[canvasData.id] = canvasInstance;
                const currentCanvas = renderedCanvasInstances.current[canvasData.id];
                const mainCanvasImageOptions = {
                  left: existingProductReference?.left || 0,
                  top: existingProductReference?.top || 0,
                  scaleX: existingProductReference?.scaleX || 1,
                  scaleY: existingProductReference?.scaleY || 1,
                  angle: existingProductReference?.angle || 0,
                  latestSavedScaling: existingProductReference?.latestSavedScaling || {
                    scaleX: 1,
                    scaleY: 1,
                  },
                };

                currentCanvas.selection = false;
                const currentCanvasObjects = currentCanvas.getObjects();
                const childProductObject = currentCanvasObjects.find(
                  (obj) => obj.imageType === 'product',
                );
                if (!childProductObject) {
                  toast.error('Product image is missing.');
                  return;
                }

                const adjustedChildOptions = getChildCanvasImageOptions({
                  referenceImageOptions: mainCanvasImageOptions,
                  childImageOptions: {
                    scaleX: childProductObject.scaleX || 1,
                    scaleY: childProductObject.scaleY || 1,
                  },
                });
                childProductObject.selectable = false;
                childProductObject.evented = false;
                childProductObject.dirty = true;
                childProductObject.set(adjustedChildOptions).setCoords();
                const existingObjectsExceptProduct = existingObjects.filter(
                  (obj) => obj.imageType && obj.imageType !== 'product',
                );
                const clonePromises = existingObjectsExceptProduct.map(
                  (obj) =>
                    new Promise<fabric.Image>((resolve) => {
                      obj.clone((clonedObj: fabric.Image) => {
                        const templateSpecificInitialOptions = {
                          evented: false,
                          selectable: false,
                          isSelectionIgnored: true,
                          ...clonedObj,
                        };
                        const adjustedOptions =
                          obj.imageType === 'template'
                            ? templateSpecificInitialOptions
                            : // TODO(emrerdem1): You must handle 'element' type scaling here. 'Element' is disabled for now.
                              getScaledOptionsForChildCanvas({
                                left: clonedObj.left,
                                top: clonedObj.top,
                                scaleX: clonedObj.scaleX,
                                scaleY: clonedObj.scaleY,
                                angle: clonedObj.angle,
                                selectable: false,
                                evented: false,
                              });
                        clonedObj
                          .set({
                            evented: false,
                            selectable: false,
                            isSelectionIgnored: true,
                            imageId: obj.imageId,
                            imageType: obj.imageType,
                            ...adjustedOptions,
                          })
                          .setCoords();
                        resolve(clonedObj);
                      });
                    }),
                );

                const clonedObjects = await Promise.all(clonePromises);
                clonedObjects.forEach((obj) => {
                  if (obj.imageType === 'template') {
                    canvasInstance.insertAt(obj, 0, false);
                  } else {
                    canvasInstance.add(obj);
                  }
                });
                currentCanvas.requestRenderAll();
              }}
            />
          </div>
        </div>
        {idx !== bulkSceneImages.length - 1 && (
          <Separator className='h-[60%]' orientation='vertical' />
        )}
      </CarouselItem>
    ));
  }, [bulkSceneImages]);

  if (sceneType === TabOption.SINGLE) return null;

  return (
    <div
      className={cn('relative max-w-full opacity-0 transition-opacity', {
        'opacity-100': isBulkSceneCreated,
      })}
    >
      <div className='mb-5 flex w-full justify-center'>{mainCanvas}</div>
      <div className='relative'>
        <Carousel
          className='relative mb-[5rem] w-auto max-w-full rounded-xl bg-white p-6'
          setApi={setApi}
          opts={{
            dragFree: true,
            watchDrag: true,
          }}
        >
          <CarouselContent className='gap-8'>{bulkSceneCanvases}</CarouselContent>
          <div className='absolute -bottom-14 left-1/2 z-10 flex -translate-x-1/2 transform gap-2'>
            <CarouselPrevious className='relative inset-0 h-12 w-12 transform-none' />
            <CarouselNext className='relative inset-0 h-12 w-12 transform-none' />
          </div>
          <div className='absolute -bottom-10 right-5 font-medium'>
            {bulkSceneImages.length} Bulk Product{appendPluralSuffix(bulkSceneImages)}
          </div>
        </Carousel>
      </div>
    </div>
  );
};
