import React from 'react';
import { Button } from 'components/ui/button';
import { CraitButton } from 'components/common/CraitButton';
import { PopoverClose } from 'components/ui/popover';
import { useSyncSelectedTemplate } from './visual-guidance/useSyncSelectedTemplate';
import { useRemoveSelectedTemplate } from './helper/useRemoveSelectedTemplate';

export const DisabledPromptPopover: React.FC = () => {
  const [drawnTemplate] = useSyncSelectedTemplate();
  const { removeSelectedTemplate } = useRemoveSelectedTemplate();

  return (
    <div className='flex w-[240px] flex-col gap-3 rounded-xl'>
      <p className='text-[13px]'>To use a prompt, please first deselect the chosen template.</p>
      <div className='flex gap-3'>
        <PopoverClose asChild>
          <Button variant='outline' className='rounded-[10px] border-[#EAEAEA]'>
            Cancel
          </Button>
        </PopoverClose>

        <PopoverClose asChild>
          <CraitButton
            onClick={() => {
              if (drawnTemplate) {
                removeSelectedTemplate(drawnTemplate.id);
              }
            }}
          >
            Deselect
          </CraitButton>
        </PopoverClose>
      </div>
    </div>
  );
};
