import { Input } from 'components/ui/input';
import { Checkbox } from 'components/ui/checkbox';
import { Label } from 'components/ui/label';
import { FC, useState } from 'react';
import { PlanGuard } from 'components/plan-guard/PlanGuard';
import { determineRequiredPlan } from 'components/plan-guard/helper';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { CraitButton } from 'components/common/CraitButton';

interface ApplyDimensionProps {
  onDimensionApplied: (props: {
    width: number;
    height: number;
    name: string;
    isCustomSize: boolean;
    resetInputs: () => void;
  }) => void;
}

export const ApplyDimension: FC<ApplyDimensionProps> = ({ onDimensionApplied }) => {
  const [{ width, height, name, isCustomSize }, setFields] = useState({
    width: '',
    height: '',
    name: '',
    isCustomSize: false,
  });
  const isDimensionValid = width && Number(width) > 0 && height && Number(height) > 0;

  const applyDimension = () => {
    const dimension = {
      width: Number(width),
      height: Number(height),
      name,
    };
    onDimensionApplied({
      width: dimension.width,
      height: dimension.height,
      name: dimension.name,
      isCustomSize,
      resetInputs: () => {
        setFields({ width: '', height: '', name: '', isCustomSize: false });
      },
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className='flex flex-col gap-4'>
      <span className='text-main font-semibold'>Name</span>
      <Input
        tabIndex={0}
        placeholder='Size Name'
        type='name'
        id='name'
        name='name'
        className='inline-block bg-white'
        value={name}
        onChange={handleInputChange}
      />
      <div className='flex justify-center gap-1'>
        <div className='flex flex-col gap-2'>
          <span className='text-main font-semibold'>Width</span>
          <Input
            tabIndex={0}
            placeholder='Width'
            type='number'
            id='width'
            name='width'
            className='inline-block bg-white'
            value={width}
            onChange={handleInputChange}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <span className='text-main font-semibold'>Height</span>
          <Input
            tabIndex={0}
            placeholder='Height'
            type='number'
            id='height'
            name='height'
            className='inline-block bg-white'
            value={height}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className='flex items-center space-x-2'>
        <Checkbox
          id='customSize'
          className='rounded-md border-crait-primary'
          checked={isCustomSize}
          onCheckedChange={() => {
            setFields((prev) => ({ ...prev, isCustomSize: !prev.isCustomSize }));
          }}
        />
        <Label
          htmlFor='customSize'
          className='text-xs font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
        >
          Save as custom size
        </Label>
      </div>
      <PlanGuard
        requiredPlan={determineRequiredPlan(Number(width), Number(height))}
        featureDescription={
          FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.featureDescription
        }
      >
        <CraitButton
          size='sm'
          className='w-full rounded-lg'
          type='submit'
          onClick={applyDimension}
          disabled={!isDimensionValid}
        >
          Confirm
        </CraitButton>
      </PlanGuard>
    </div>
  );
};
