import { Link } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import { RoutePaths } from '@/utils/route/constants';

import { NavSection } from './_components/NavSection';
// import { ProjectNameInput } from './_components/ProjectNameInput';
import { UndoRedoControl } from './_components/UndoRedoControl';
import { ZoomControl } from './_components/ZoomControl';
import CraitLogoIcon from '@/assets/icons/ai-scene-icons/craitLogo.svg?react';
import SeparatorIcon from '@/assets/icons/ai-scene-icons/separator.svg?react';
import { UndoRedoSceneCanvas } from 'components/ai-scene/create/editor/UndoRedoSceneCanvas';
import { ProjectNameInput } from 'components/ai-scene/create/editor/ProjectNameInput';
import { ProjectProvider } from 'pages/ai-scene/create/ProjectProvider';

interface WorkspaceHeaderProps {}

export const WorkspaceHeader: FC<WorkspaceHeaderProps> = () => {
  return (
    <section className='flex h-header w-full items-center justify-between border-b border-slate-200 bg-white px-6 md:justify-start'>
      <nav className='flex items-center gap-5'>
        <Link to={RoutePaths.BASE}>
          <CraitLogoIcon />
        </Link>
        <SeparatorIcon />
        <NavSection />
      </nav>
      {/* <ProjectNameInput /> */}
      <div className='h-[60%]'>
        <ProjectNameInput />
      </div>

      <div className='flex items-center gap-5 md:hidden'>
        <UndoRedoSceneCanvas />
        {/* <UndoRedoControl /> */}
        <SeparatorIcon />
        <ZoomControl />
      </div>
    </section>
  );
};
