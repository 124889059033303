import {
  AccountPaths,
  BrandLibraryPaths,
  CreativeSuitePaths,
  RoutePaths,
  WorkspacePaths,
} from '@/utils/route/constants';

import ClockIcon from 'assets/icons/clock.svg?react';
import ColorsIcon from 'assets/icons/colors.svg?react';
import FolderIcon from 'assets/icons/folder.svg?react';
import PackageIcon from 'assets/icons/package.svg?react';
import InviteMembersIcon from 'assets/icons/userPlus.svg?react';
import Image04Icon from 'assets/icons/image04.svg?react';
import Star06Icon from 'assets/icons/star006.svg?react';
import Image01Icon from 'assets/icons/image01.svg?react';
import LayoutGrid01Icon from 'assets/icons/layoutGrid01.svg?react';
import ZapCircleIcon from 'assets/icons/zapCircle.svg?react';
import BarChartLineIcon from 'assets/icons/barLineChart2.svg?react';

import { TPaymentProduct } from 'components/dashboard/payment/queries';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';

export interface NavItem {
  title: string;
  href: string;
  icon: React.ElementType;
  color?: string;
  isChidren?: boolean;
  children?: NavItem[];
  disabled?: boolean;
  requiredPlan?: TPaymentProduct['type'];
  featureDescription?: string;
}

export const NavItems: NavItem[] = [
  {
    title: 'Recents',
    icon: ClockIcon,
    href: `${RoutePaths.BASE}`,
    color: 'text-dark',
  },
  {
    title: 'Brand Kit',
    icon: ColorsIcon,
    href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_KIT}`,
    color: 'text-dark',
  },
  {
    title: 'Invite Members',
    icon: InviteMembersIcon,
    href: `/${RoutePaths.ACCOUNT}/${AccountPaths.PEOPLE}`,
    color: 'text-dark',
    requiredPlan: FeatureAccessRequirements.INVITE_MEMBERS.requiredPlan,
    featureDescription: FeatureAccessRequirements.INVITE_MEMBERS.featureDescription,
  },
  {
    title: 'Asset Library',
    icon: FolderIcon,
    href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`,
    color: 'text-dark',
    isChidren: true,
    children: [
      {
        title: 'Products',
        icon: PackageIcon,
        color: 'text-gray-500',
        href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`,
      },
      // {
      //   title: 'Banner',
      //   icon: ImageIndentLeftIcon,
      //   color: 'text-gray-500',
      //   href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}/${BrandLibraryPaths.BANNER}`,
      //   requiredPlan: FeatureAccessRequirements.BANNER_PAGE.requiredPlan,
      //   featureDescription: FeatureAccessRequirements.BANNER_PAGE.featureDescription,
      // },
      {
        title: 'Templates',
        icon: Image04Icon,
        color: 'text-gray-500',
        href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}/${BrandLibraryPaths.LAYOUT}`,
        requiredPlan: FeatureAccessRequirements.TEMPLATES_PAGE.requiredPlan,
        featureDescription: FeatureAccessRequirements.TEMPLATES_PAGE.featureDescription,
      },
      {
        title: 'Inspirations',
        icon: Star06Icon,
        color: 'text-gray-500',
        href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}/${BrandLibraryPaths.STYLES}`,
        requiredPlan: FeatureAccessRequirements.INSPIRATION_PAGE.requiredPlan,
        featureDescription: FeatureAccessRequirements.INSPIRATION_PAGE.featureDescription,
      },
    ],
  },

  {
    title: 'Creative Suite',
    icon: Image01Icon,
    href: '#',
    color: 'text-dark',
    isChidren: true,
    children: [
      {
        title: 'Projects',
        icon: LayoutGrid01Icon,
        color: 'text-gray-500',
        href: `/${RoutePaths.CREATIVE_SUITE}`,
      },
      {
        title: 'History',
        icon: ClockIcon,
        color: 'text-gray-500',
        href: `/${RoutePaths.CREATIVE_SUITE}/${CreativeSuitePaths.HISTORY}`,
      },
      {
        title: 'Saved Settings',
        icon: ZapCircleIcon,
        color: 'text-gray-500',
        href: `/${RoutePaths.WORKSPACE}/${WorkspacePaths.SAVED_SETTINGS}`,
      },
    ],
  },
  {
    title: 'Creative Insights',
    icon: BarChartLineIcon,
    href: '/',
    color: 'text-dark',
    isChidren: true,
    children: [],
    disabled: true,
  },
];
