import { useMemo } from 'react';
import { useGetColorsQuery } from '@/components/workspace/brand-library/brand-kit/queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { CreateColorDropdownWrapper } from './CreateColorDropdownWrapper';
import { EditColorDropdownWrapper } from './EditColorDropdownWrapper';

export const ColorContent = () => {
  const { data, isError, isLoading, refetch } = useGetColorsQuery({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load colors.</span>
    </RefetchView>
  );

  const colorItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    return data?.items.slice(0, 5).map((color) => {
      return (
        <div
          className='relative flex w-auto flex-col items-center gap-1.5 rounded-lg p-2'
          key={color.id + color.last_update_info.timestamp}
        >
          <div className='flex flex-col gap-1 text-center'>
            <EditColorDropdownWrapper {...color} refetch={refetch} />
          </div>
        </div>
      );
    });
  }, [data, isLoading, refetch]);

  return (
    <div className='flex w-full flex-col items-start gap-1'>
      <label className='text-[13px] font-semibold text-c-dark'>Color Palette</label>
      <div className='ml-[-6px] flex w-full flex-wrap gap-0 rounded-lg bg-white'>
        {errorView || colorItems}

        <CreateColorDropdownWrapper refetch={refetch} />
      </div>
    </div>
  );
};
