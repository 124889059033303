import { Toggle } from '@radix-ui/react-toggle';
import { Checkbox } from 'components/ui/checkbox';
import { Input } from 'components/ui/input';
import { ScrollArea } from 'components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';

const HEAD_CL =
  'border border-gray-200 border-collapse rounded-lg text-crait-primary font-medium h-9';
const CELL_CL = 'text-black font-normal py-1 border border-b-0 border-gray-200 border-collapse';

export const LayerView = () => {
  return (
    <ScrollArea className='h-[500px] w-[260px] rounded-xl'>
      <div className='border-slate-300 bg-white px-2 py-6'>
        <p className='mb-4 px-2 text-sm'>Select which areas could be edited by your team</p>
        <Table className='mx-auto rounded-lg bg-white p-2 text-crait-primary'>
          <TableHeader>
            <TableRow>
              <TableHead className={`${HEAD_CL} border-l-0`}></TableHead>
              <TableHead className={HEAD_CL}>Layer</TableHead>
              <TableHead className={`${HEAD_CL} border-r-0`}>Lock</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.from({ length: 10 }).map((_, i) => (
              <LayerItem key={i} />
            ))}
          </TableBody>
        </Table>
      </div>
    </ScrollArea>
  );
};

export const LayerItem = () => {
  return (
    <TableRow className='hover:bg-[rgba(105,186,200,0.10)] '>
      <TableCell className={`${CELL_CL} border-l-0 p-0 text-center`}>
        <Checkbox className='h-4 w-4' />
      </TableCell>
      <TableCell className={`${CELL_CL} px-2`}>
        <Input className='h-8 text-xs' />
      </TableCell>
      <TableCell className={`${CELL_CL} border-r-0 px-1.5`}>
        <Toggle
          className='group h-10 w-16 text-xs'
          onPressedChange={(state) => {
            console.log(state);
          }}
        >
          <span className='flex hidden w-full items-center gap-1 group-data-[state=on]:flex'>
            <span className='i-mdi-lock'></span>
            <span>Locked</span>
          </span>
          <span className='flex w-full items-center justify-start gap-1 group-data-[state=on]:hidden'>
            <span className='i-mdi-lock-open-outline'></span>
            <span>Open</span>
          </span>
        </Toggle>
      </TableCell>
    </TableRow>
  );
};
