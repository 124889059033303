import { isFabricText, serializeCanvas } from '@/utils/helper';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { useGetColorsQuery } from 'components/workspace/brand-library/brand-kit/queries';
import { FC, useCallback, useMemo } from 'react';

interface BrandPaletteProps {
  canvasInstance: fabric.Canvas | null;
  updateCanvasState: (canvasState: string) => void;
}
export const BrandPalette: FC<BrandPaletteProps> = ({ canvasInstance, updateCanvasState }) => {
  const { data, isError, isLoading, refetch } = useGetColorsQuery({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load colors.</span>
    </RefetchView>
  );

  const updateActiveTextColor = useCallback(
    (color: string) => {
      if (!canvasInstance) return;

      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject)) return;

      activeObject.set('fill', color);
      canvasInstance.requestRenderAll();
      updateCanvasState(serializeCanvas(canvasInstance));
    },
    [canvasInstance, updateCanvasState],
  );

  const colorItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-2 flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      );

    return data?.items.map((color) => {
      return (
        <div
          key={color.id}
          onClick={() => {
            updateActiveTextColor(color.hexCode);
          }}
          className={`flex w-auto cursor-pointer flex-col items-center gap-2.5 rounded-md`}
        >
          <span
            className='relative flex size-[32px] items-center gap-2 overflow-hidden rounded-full'
            style={{ backgroundColor: color.hexCode }}
          />
          {/* <div className='flex flex-col gap-1 text-center'>
            <span className='line-clamp-1 text-ellipsis'>{color.name}</span>
            <span className='line-clamp-1 text-ellipsis text-xs uppercase text-slate-500'>
              {color.hexCode}
            </span>
          </div> */}
        </div>
      );
    });
  }, [data, isLoading, updateActiveTextColor]);

  return (
    <div className='mt-2 flex flex-col gap-1'>
      <p className='text-main font-medium'>Brand Palette</p>
      <ScrollArea className='w-[258px]'>
        <div className='flex gap-2 rounded-lg'>{errorView || colorItems}</div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  );
};
