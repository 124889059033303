import { fabric } from 'fabric';

export const updateFilterValue = <T extends fabric.IBaseFilter>({
  value,
  valueKey,
  imgObject,
  filterType,
}: {
  imgObject: fabric.Image;
  filterType: new (...args: any[]) => T;
  valueKey: keyof T;
  value: any;
}): void => {
  if (!imgObject.filters) return;

  const filter = imgObject.filters.find((filter) => filter instanceof filterType) as T | undefined;
  if (filter) {
    filter[valueKey] = value;
    imgObject.applyFilters();
  }
};

export const toggleFilter = <T extends fabric.IBaseFilter>({
  imgObject,
  filterType,
  valueKey,
  value,
}: {
  imgObject: fabric.Image;
  filterType: new (...args: any[]) => T;
  valueKey: keyof T;
  value: number;
}): void => {
  if (!imgObject.filters) imgObject.filters = [];
  const filterIndex = imgObject.filters.findIndex((filter) => filter instanceof filterType);
  if (filterIndex > -1) {
    // Filter exists, toggle it off
    imgObject.filters.splice(filterIndex, 1);
  } else {
    // Filter doesn't exist, add it with the provided value
    const filter = new filterType({ [valueKey]: value } as any);
    imgObject.filters.push(filter);
  }

  imgObject.applyFilters();
};

export const applyNewFilter = <T extends fabric.IBaseFilter>({
  imgObject,
  filterType,
  valueKey,
  value,
}: {
  imgObject: fabric.Image;
  filterType: new (...args: any[]) => T;
  valueKey: keyof T;
  value: number;
}): 'add' | 'remove' => {
  if (!imgObject.filters) imgObject.filters = [];
  const filterIndex = imgObject.filters.findIndex((filter) => filter instanceof filterType);
  if (filterIndex > -1) {
    // Filter exists, remove it
    imgObject.filters.splice(filterIndex, 1);
    imgObject.applyFilters();
    return 'remove';
  }

  imgObject.filters = [];
  const filter = new filterType({ [valueKey]: value } as any);
  imgObject.filters.push(filter);
  imgObject.applyFilters();
  return 'add';
};

export const getUniqueFilterName = (name: string) => `filter-${name}`;
