import { FC, ReactNode } from 'react';
import { IconWrapper } from 'components/common/IconWrapper';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-slate-50';

interface ColorSettingsProps {
  children: ReactNode;
}

export const ColorSettings: FC<ColorSettingsProps> = ({ children }) => {
  return (
    <div className='mt-3 flex flex-col gap-3 py-1'>
      <div className='flex items-center justify-between'>
        {/* <p className='font-bold'>Fill</p> */}
        {/* <IconWrapper
          wrapperProps={{
            className: ICON_WRAPPER_CL,
          }}
          iconClass='i-mdi-plus-circle-outline size-4 hover:scale-125'
        /> */}
      </div>
      {children}
    </div>
  );
};
