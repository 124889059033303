import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ElementEndpoint } from '@/utils/fetch/constants';
import { getElementsApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';

type TElementUpdatePayload = {
  id: string;
  name: string;
  image: File;
  organizationId: number;
};

const updateElementDetail = async (props: TElementUpdatePayload) => {
  const baseUrl = getElementsApiUrl(props.organizationId, ElementEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  formData.append('elementName', props.name);
  formData.append('image', props.image);
  return apiClient.put<string>(url, formData);
};

export const useUpdateElementDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ElementEndpoint.BASE, organizationId }],
    mutationFn: (props: Omit<TElementUpdatePayload, 'organizationId'>) => {
      return updateElementDetail({ ...props, organizationId });
    },
  });
};

type TElementPartialUpdatePayload = {
  id: string;
  name?: string;
  image?: File;
  organizationId: number;
};

const updatePartialElementDetail = async (props: TElementPartialUpdatePayload) => {
  const baseUrl = getElementsApiUrl(props.organizationId, ElementEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  if (props.name) formData.append('elementName', props.name);
  if (props.image) formData.append('image', props.image);

  return apiClient.patch<string>(url, formData);
};

export const usePartialUpdateElementDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ElementEndpoint.BASE, organizationId, 'updatePartialElementDetail'],
    mutationFn: (props: Omit<TElementPartialUpdatePayload, 'organizationId'>) => {
      return updatePartialElementDetail({ ...props, organizationId });
    },
  });
};

type TElementUploadPayload = Omit<TElementUpdatePayload, 'id'>;
const uploadTemplate = async (props: TElementUploadPayload) => {
  const url = getElementsApiUrl(props.organizationId, ElementEndpoint.BASE);
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('image', props.image);
  return apiClient.post<string>(url, formData);
};

export const useUploadElement = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ElementEndpoint.BASE, organizationId }],
    mutationFn: (props: Omit<TElementUploadPayload, 'organizationId'>) => {
      return uploadTemplate({ ...props, organizationId });
    },
  });
};

type TDeleteElementPayload = {
  id: string;
  organizationId: number;
};

const deleteElementById = async (props: TDeleteElementPayload) => {
  const baseUrl = getElementsApiUrl(props.organizationId, ElementEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  return apiClient.delete<string>(url);
};

export const useDeleteElement = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ElementEndpoint.BASE, organizationId],
    mutationFn: (props: Omit<TDeleteElementPayload, 'organizationId'>) => {
      return deleteElementById({ ...props, organizationId });
    },
  });
};
