import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  INFINITE_QUERY_TYPE_KEY,
  TSearchResponse,
  TSearchSection,
} from 'components/ai-scene/side-menu/create-tool/helper/queries';

export const useResetInfiniteSearchCache = <T,>(
  searchSection: TSearchSection,
  maxPages: number,
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      // https://github.com/TanStack/query/discussions/3576#discussioncomment-2673531
      queryClient.setQueriesData<InfiniteData<TSearchResponse<T>>>(
        {
          queryKey: [{ searchSection, ...INFINITE_QUERY_TYPE_KEY }],
          exact: false,
        },
        (existingData) => {
          if (!existingData) return existingData;

          // Keep only the first N pages of data, remove the rest from the cache.
          return {
            pageParams: existingData.pageParams.slice(0, maxPages),
            pages: existingData.pages.slice(0, maxPages),
          };
        },
      );
    };
  }, [maxPages]);
};
