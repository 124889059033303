import { FC, useMemo, useState } from 'react';
import { Button } from 'components/ui/button';
import { useGetProductCategories } from '../helper/queries';
import {
  Command,
  CommandInput,
  CommandList,
  CommandItem,
  CommandEmpty,
  CommandGroup,
} from 'components/ui/command';
import { RefetchView } from '../helper/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Check } from 'lucide-react';

interface CategoryPopoverProps {
  selectedCategory: { name: string; id: string } | undefined;
  setSelectedCategory: (category: { name: string; id: string } | undefined) => void;
}

export const CategoryPopover: FC<CategoryPopoverProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const {
    data: categories,
    isError: categoriesError,
    isLoading: categoriesLoading,
    refetch: refetchCategories,
  } = useGetProductCategories();

  const categoryOptions = useMemo(() => {
    if (categoriesLoading) {
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );
    }

    if (!categories || categories.length === 0) {
      return <span>No categories found.</span>;
    }

    return (
      <>
        <CommandItem
          key='all'
          onSelect={() => {
            setSelectedCategory(undefined);
            setPopoverOpen(false);
          }}
        >
          <Check
            className={`mr-2 h-4 w-4 ${
              selectedCategory === undefined ? 'opacity-100' : 'opacity-0'
            }`}
          />
          All
        </CommandItem>
        {categories.map((category) => (
          <CommandItem
            key={category.id}
            onSelect={() => {
              if (selectedCategory?.id === category.id) {
                setSelectedCategory(undefined);
              } else {
                setSelectedCategory({ id: category.id, name: category.name });
              }
              setPopoverOpen(false);
            }}
          >
            <Check
              className={`mr-2 h-4 w-4 ${
                selectedCategory?.id === category.id ? 'opacity-100' : 'opacity-0'
              }`}
            />
            {category.name}
          </CommandItem>
        ))}
      </>
    );
  }, [categories, categoriesLoading, selectedCategory]);

  const errorView = categoriesError && (
    <RefetchView disabled={categoriesLoading} onClick={() => refetchCategories()}>
      <span>Failed to load categories.</span>
    </RefetchView>
  );

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={popoverOpen}
          className='border-none p-0'
        >
          <svg
            width='36'
            height='36'
            viewBox='0 0 36 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect x='0.5' y='0.5' width='35' height='35' rx='5.5' stroke='#EAEAEA' />
            <path
              d='M13 18H23M10.5 13H25.5M15.5 23H20.5'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[200px] p-0'>
        <Command>
          <CommandInput placeholder='Search category...' />
          <CommandList>
            <CommandEmpty>No category found.</CommandEmpty>
            <CommandGroup>{categoryOptions}</CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
      {errorView}
    </Popover>
  );
};
