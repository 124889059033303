import { cn } from '@/lib/utils';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';
import { FC, ReactNode } from 'react';

interface CanvasSoftHideProps {
  children: ReactNode;
}
export const OutpaintCanvasSoftHide: FC<CanvasSoftHideProps> = ({ children }) => {
  const outpaintLen = useAiSceneOutpaintStore((state) => state.outpaintData.length);

  return (
    <div
      className={cn('transition-opacity', {
        'opacity-0': outpaintLen > 0,
      })}
    >
      {children}
    </div>
  );
};
