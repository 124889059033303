import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Textarea } from 'components/ui/textarea';

export const CopyTextContent = () => {
  const narrativeOptions = [
    { label: 'Mystery of the Ancient Library', value: 'mysteryAncientLibrary' },
    { label: 'Space Odyssey: Beyond the Stars', value: 'spaceOdysseyBeyond' },
    { label: 'Enchanted Forest Chronicles', value: 'enchantedForestChronicles' },
  ];

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex gap-2'>
        <Input
          placeholder='Add CTA Text (Optional)'
          className='border-[#6b8084bb] bg-transparent '
        />
        <Input
          placeholder='Add Offer Text (Optional)'
          className='border-[#6b8084bb] bg-transparent'
        />
      </div>
      <Select onValueChange={(value) => {}}>
        <SelectTrigger className='border-[#6b8084bb] bg-transparent'>
          <SelectValue placeholder='Select narrative' />
        </SelectTrigger>
        <SelectContent className='bg-[#072428] text-white'>
          {narrativeOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Textarea
        placeholder='Enter reference text here'
        className='h-full max-h-[120px] min-h-[55px] w-full rounded-lg bg-transparent text-xs placeholder:text-white/40'
      />
      <Button className='w-full rounded-xl bg-crait-blue font-normal text-black'>
        Generate Copy
      </Button>
      <Textarea
        readOnly
        placeholder='Generated copy will appear here'
        className='h-full max-h-[120px] min-h-[80px] w-full rounded-lg bg-transparent text-xs placeholder:text-white/40'
      />
    </div>
  );
};
