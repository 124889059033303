import { Button } from 'components/ui/button';
import { AiSceneCanvas } from './canvas/AiSceneCanvas';
import { BulkCanvasWrapper } from './bulk-canvas/BulkCanvasWrapper';

import { FC, ReactNode } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

export const ImageEditor = () => {
  return (
    <section className='container relative h-full overflow-y-auto overflow-x-hidden bg-c-ground py-5 md:px-2'>
      <div className='flex h-full flex-col items-center gap-6 2xl:gap-4'>
        <AiSceneCanvas />
        <BulkCanvasWrapper />
      </div>
    </section>
  );
};

const DD_ITEM_CL = 'cursor-pointer items-center gap-1 rounded-lg text-xs';

interface ProjectMoreDotsProps {
  dotsIcon: ReactNode;
}
const ProjectMoreDots: FC<ProjectMoreDotsProps> = ({ dotsIcon }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' size='sm' className='h-full bg-gray-100 px-2'>
          {dotsIcon}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='center' className='w-auto border border-black'>
        <DropdownMenuItem className={DD_ITEM_CL}>
          <span className='i-mdi-bookmark-minus-outline size-5 bg-gray-500'></span> Save Settings
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className={DD_ITEM_CL}>
            <span className='i-mdi-star-outline size-5 bg-gray-500'></span> Favorite
          </DropdownMenuItem>
          {/*           <DropdownMenuItem className={DD_ITEM_CL}>
            <span className='i-mdi-file-move-outline size-5 bg-gray-500'></span> Use AI Banner
          </DropdownMenuItem>
          <DropdownMenuItem className={DD_ITEM_CL}>
            <span className='i-mdi-share-variant size-5 bg-gray-500'></span> Share
          </DropdownMenuItem> */}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className={DD_ITEM_CL}>
          <span className='i-mdi-trash-outline size-5 bg-gray-500'></span> Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
