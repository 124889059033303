import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ProjectEndpoint, SavedSettingsEndpoint } from '@/utils/fetch/constants';
import { getProjectsApiUrl, getSavedSettingsApiUrl } from '@/utils/fetch/helper';
import { RoutePaths } from '@/utils/route/constants';
import { useMutation } from '@tanstack/react-query';
import { ProjectTypes, TProject } from 'pages/workspace/projects/helper';

const deleteProject = async ({
  projectId,
  organizationId,
}: {
  projectId: string;
  organizationId: number;
}): Promise<void> => {
  const baseUrl = getProjectsApiUrl(organizationId, ProjectEndpoint.BASE);
  const url = `${baseUrl}/${projectId}`;
  await apiClient.delete(url);
};

export const useDeleteProjectMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ProjectEndpoint.BASE],
    mutationFn: (projectId: string) => {
      return deleteProject({ projectId, organizationId });
    },
  });
};

export const getRedirectUrlToCanvas = (project: TProject) => {
  const baseRedirectUrl = `/${RoutePaths.AI_SCENE}/${project.id}`;
  const redirectUrl = project.lastGenerationData
    ? `${baseRedirectUrl}/generation`
    : baseRedirectUrl;
  return redirectUrl;
};

export const getRedirectUrlAccordingToType = (projectId: string, projectType: string) => {
  const redirectUrl =
    projectType === ProjectTypes.AI_SCENE
      ? `/${RoutePaths.AI_SCENE}/${projectId}`
      : projectType === ProjectTypes.AI_EDIT
        ? `/${RoutePaths.AI_EDIT}/${projectId}`
        : `/${RoutePaths.AI_IMAGE}/${projectId}`;

  return redirectUrl;
};

type TDeleteSettingsPayload = {
  organizationId: number;
  settingsId: string;
};

const deleteSettings = async ({
  organizationId,
  settingsId,
}: TDeleteSettingsPayload): Promise<void> => {
  const baseUrl = getSavedSettingsApiUrl(organizationId, SavedSettingsEndpoint.BASE);
  const url = `${baseUrl}/${settingsId}`;
  await apiClient.delete(url);
};

export const useDeleteSettingsMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [SavedSettingsEndpoint.BASE],
    mutationFn: (settingsId: string) => {
      return deleteSettings({ settingsId, organizationId });
    },
  });
};

type TRenameSettingsPayload = {
  organizationId: number;
  settingsId: string;
  name: string;
};

const renameSettings = async (props: TRenameSettingsPayload): Promise<void> => {
  const baseUrl = getSavedSettingsApiUrl(props.organizationId, SavedSettingsEndpoint.BASE);
  const url = `${baseUrl}/${props.settingsId}`;
  await apiClient.put(url, { name: props.name });
};

export const useRenameSettingsMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [SavedSettingsEndpoint.BASE],
    mutationFn: (props: Omit<TRenameSettingsPayload, 'organizationId'>) => {
      return renameSettings({ ...props, organizationId });
    },
  });
};

type TRenameProjectPayload = {
  projectId: string;
  organizationId: number;
  name: string;
};

const renameProject = async (props: TRenameProjectPayload): Promise<TProject> => {
  const baseUrl = getProjectsApiUrl(props.organizationId, ProjectEndpoint.BASE);
  const url = `${baseUrl}/${props.projectId}`;
  const response = await apiClient.patch<TProject>(url, { name: props.name });
  return response.data;
};

export const useRenameProjectMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ProjectEndpoint.BASE],
    mutationFn: (props: Omit<TRenameProjectPayload, 'organizationId'>) => {
      return renameProject({ ...props, organizationId });
    },
  });
};
