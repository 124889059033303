import React from 'react';

export const ProductPhotoIcon: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='2' fill='#F5F5F5' />
    <g clipPath='url(#clip0_3700_2976)'>
      <g opacity='0.12'>
        <path
          d='M21.1857 22.6672H21.9341C22.6535 22.6672 23.0132 22.6672 23.2115 22.5172C23.3842 22.3865 23.4911 22.1868 23.504 21.9706C23.5188 21.7224 23.3193 21.4231 22.9202 20.8246L20.6903 17.4797C20.3606 16.9851 20.1958 16.7378 19.988 16.6517C19.8063 16.5763 19.6021 16.5763 19.4205 16.6517C19.2127 16.7378 19.0478 16.9851 18.7181 17.4797L18.1668 18.3066L21.1857 22.6672Z'
          fill='#777777'
        />
        <path
          d='M13.5447 14.4451L9.14007 20.8074C8.72301 21.4098 8.51449 21.711 8.52636 21.9615C8.5367 22.1797 8.64279 22.3821 8.81627 22.5148C9.01548 22.6672 9.38183 22.6672 10.1145 22.6672H21.1857L15.4936 14.4451C15.1663 13.9722 15.0026 13.7358 14.7981 13.6527C14.6193 13.58 14.4191 13.58 14.2402 13.6527C14.0358 13.7358 13.8721 13.9722 13.5447 14.4451Z'
          fill='#777777'
        />
      </g>
      <path
        d='M21.1858 22.6673H21.9343C22.6537 22.6673 23.0133 22.6673 23.2116 22.5173C23.3844 22.3867 23.4912 22.1869 23.5041 21.9707C23.519 21.7225 23.3194 21.4233 22.9204 20.8247L20.6905 17.4799C20.3608 16.9853 20.1959 16.738 19.9881 16.6518C19.8065 16.5765 19.6023 16.5765 19.4206 16.6518C19.2128 16.738 19.048 16.9853 18.7182 17.4798L18.167 18.3067M21.1858 22.6673L15.4936 14.4452C15.1663 13.9724 15.0026 13.7359 14.7981 13.6528C14.6193 13.5801 14.4191 13.5801 14.2402 13.6528C14.0358 13.7359 13.8721 13.9724 13.5447 14.4452L9.14007 20.8075C8.72301 21.4099 8.51449 21.7111 8.52636 21.9616C8.5367 22.1798 8.64279 22.3823 8.81627 22.515C9.01548 22.6673 9.38183 22.6673 10.1145 22.6673H21.1858ZM22.6673 11.5562C22.6673 12.7835 21.6724 13.7784 20.4451 13.7784C19.2178 13.7784 18.2229 12.7835 18.2229 11.5562C18.2229 10.3289 19.2178 9.33398 20.4451 9.33398C21.6724 9.33398 22.6673 10.3289 22.6673 11.5562Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3700_2976'>
        <rect
          width='17.7778'
          height='17.7778'
          fill='white'
          transform='translate(7.10938 7.11182)'
        />
      </clipPath>
    </defs>
  </svg>
);

export const VisualGuidanceIcon: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='2' fill='#F5F5F5' />
    <g clipPath='url(#clip0_3700_2984)'>
      <path
        opacity='0.12'
        d='M16.7404 9.33398L18.025 12.6739C18.2339 13.217 18.3383 13.4886 18.5007 13.717C18.6447 13.9195 18.8216 14.0963 19.024 14.2403C19.2525 14.4027 19.524 14.5072 20.0672 14.7161L23.4071 16.0007L20.0672 17.2852C19.524 17.4941 19.2525 17.5986 19.024 17.761C18.8216 17.905 18.6447 18.0818 18.5007 18.2843C18.3383 18.5127 18.2339 18.7843 18.025 19.3274L16.7404 22.6673L15.4558 19.3274C15.2469 18.7843 15.1425 18.5127 14.98 18.2843C14.8361 18.0818 14.6592 17.905 14.4568 17.761C14.2283 17.5986 13.9568 17.4941 13.4136 17.2852L10.0737 16.0007L13.4136 14.7161C13.9568 14.5072 14.2283 14.4027 14.4568 14.2403C14.6592 14.0963 14.8361 13.9195 14.98 13.717C15.1425 13.4886 15.2469 13.217 15.4558 12.6739L16.7404 9.33398Z'
        fill='#777777'
      />
      <path
        d='M10.4441 23.4081V19.7044M10.4441 12.297V8.59326M8.59229 10.4451H12.296M8.59229 21.5562H12.296M16.7404 9.334L15.4559 12.6739C15.247 13.217 15.1425 13.4886 14.9801 13.717C14.8361 13.9195 14.6592 14.0964 14.4568 14.2403C14.2284 14.4027 13.9568 14.5072 13.4137 14.7161L10.0738 16.0007L13.4137 17.2852C13.9568 17.4941 14.2284 17.5986 14.4568 17.761C14.6592 17.905 14.8361 18.0819 14.9801 18.2843C15.1425 18.5127 15.247 18.7843 15.4559 19.3274L16.7404 22.6673L18.025 19.3274C18.2339 18.7843 18.3384 18.5127 18.5008 18.2843C18.6447 18.0819 18.8216 17.905 19.0241 17.761C19.2525 17.5986 19.5241 17.4941 20.0672 17.2852L23.4071 16.0007L20.0672 14.7161C19.5241 14.5072 19.2525 14.4027 19.0241 14.2403C18.8216 14.0964 18.6447 13.9195 18.5008 13.717C18.3384 13.4886 18.2339 13.217 18.025 12.6739L16.7404 9.334Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3700_2984'>
        <rect
          width='17.7778'
          height='17.7778'
          fill='white'
          transform='translate(7.11084 7.11182)'
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowRightIcon: React.FC = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 15L12.5 10L7.5 5'
      stroke='#212121'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const ArrowLeftIcon: React.FC = () => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5 15.5L7.5 10.5L12.5 5.5'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const ImageStyleIcon: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='2' fill='#F5F5F5' />
    <g clipPath='url(#clip0_3700_2990)'>
      <path
        opacity='0.12'
        d='M8.5 16C8.5 20.1421 11.8579 23.5 16 23.5C17.2426 23.5 18.25 22.4926 18.25 21.25V20.875C18.25 20.5267 18.25 20.3525 18.2692 20.2063C18.4022 19.1967 19.1967 18.4022 20.2063 18.2692C20.3525 18.25 20.5267 18.25 20.875 18.25H21.25C22.4926 18.25 23.5 17.2426 23.5 16C23.5 11.8579 20.1421 8.5 16 8.5C11.8579 8.5 8.5 11.8579 8.5 16Z'
        fill='#777777'
      />
      <path
        d='M8.5 16C8.5 20.1421 11.8579 23.5 16 23.5C17.2426 23.5 18.25 22.4926 18.25 21.25V20.875C18.25 20.5267 18.25 20.3525 18.2692 20.2063C18.4022 19.1967 19.1967 18.4022 20.2063 18.2692C20.3525 18.25 20.5267 18.25 20.875 18.25H21.25C22.4926 18.25 23.5 17.2426 23.5 16C23.5 11.8579 20.1421 8.5 16 8.5C11.8579 8.5 8.5 11.8579 8.5 16Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.25 16.75C12.6642 16.75 13 16.4142 13 16C13 15.5858 12.6642 15.25 12.25 15.25C11.8358 15.25 11.5 15.5858 11.5 16C11.5 16.4142 11.8358 16.75 12.25 16.75Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 13.75C19.4142 13.75 19.75 13.4142 19.75 13C19.75 12.5858 19.4142 12.25 19 12.25C18.5858 12.25 18.25 12.5858 18.25 13C18.25 13.4142 18.5858 13.75 19 13.75Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 13C14.9142 13 15.25 12.6642 15.25 12.25C15.25 11.8358 14.9142 11.5 14.5 11.5C14.0858 11.5 13.75 11.8358 13.75 12.25C13.75 12.6642 14.0858 13 14.5 13Z'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3700_2990'>
        <rect width='18' height='18' fill='white' transform='translate(7 7)' />
      </clipPath>
    </defs>
  </svg>
);
