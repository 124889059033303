import { NavItems } from './constants';
import { SideNav } from './sidebar/SideNav';
import { Sidebar } from './sidebar/Sidebar';

export const MainSidebar = () => {
  return (
    <Sidebar>
      <SideNav items={NavItems} />
    </Sidebar>
  );
};
