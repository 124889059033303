import { GTM } from '@/utils/gtm';
import { RoutePaths } from '@/utils/route/constants';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useGoogleVerify } from 'pages/login/queries';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export const GoogleVerifyPage = () => {
  const { mutateAsync: verifyUser } = useGoogleVerify();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session');
  const isNewUser = searchParams.get('newUser');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyGoogle = async () => {
      try {
        if (!sessionId) throw new Error('Redirect URL not found.');
        await verifyUser(sessionId);
        if (isNewUser) GTM.sendNewUserSignupEvent();
      } catch (error) {
        toast.error('Failed to verify Google user.');
        navigate(`/${RoutePaths.LOGIN}`, { replace: true });
      }
    };

    verifyGoogle();
  }, [verifyUser, sessionId, navigate, isNewUser]);

  return (
    <div className='mt-10 flex w-full justify-center'>
      <LoadingSpinner />
    </div>
  );
};
