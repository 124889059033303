import { createContext, useContext } from 'react';
import { useParams } from 'react-router';
import { useUserStore } from '@/providers/user/hooks';
import { useQuery, useInfiniteQuery, InfiniteData, useQueryClient } from '@tanstack/react-query';
import { AiSceneGenerationEndpoint } from '@/utils/fetch/constants';
import invariant from 'tiny-invariant';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { getAiSceneGenerationApiUrl } from '@/utils/fetch/helper';
import { TScene } from 'components/ai-scene/review/queries';
import { TPaginatedResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { useEffect } from 'react';

type TGetGeneratedScenesPayload = {
  projectId: string;
  organizationId: number;
  pageId?: number;
};

const getAllScenes = async ({ projectId, organizationId, pageId }: TGetGeneratedScenesPayload) => {
  const baseUrl = getAiSceneGenerationApiUrl({
    organizationId,
    projectId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = pageId ? `${baseUrl}?pageId=${pageId}` : baseUrl;
  const response = await apiClient.get<TPaginatedResponse<TScene>>(url);
  return response.data;
};

const getGeneratedScenesQueryKey = (projectId: string, organizationId: number) => {
  return [
    { url: AiSceneGenerationEndpoint.BASE, organizationId, projectId, name: 'getGeneratedScenes' },
  ];
};

const GET_INFINITE_GENERATED_SCENES_QUERY_NAME = 'getInfiniteGeneratedScenes';

export const getInfiniteGeneratedScenesQueryKey = (projectId: string, organizationId: number) => {
  return [
    {
      url: AiSceneGenerationEndpoint.BASE,
      organizationId,
      projectId,
      name: GET_INFINITE_GENERATED_SCENES_QUERY_NAME,
    },
  ];
};

const _filterScenes = (data: TPaginatedResponse<TScene>) => {
  const sortedItems = data.items
    .sort((a, b) => b.creation_timestamp - a.creation_timestamp)
    .filter(
      (scene) =>
        scene.status === 'pending' || scene.status === 'in_progress' || scene.status === 'ready',
    );
  return { ...data, items: sortedItems };
};

export const useGetGeneratedScenes = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId } = useParams();
  invariant(projectId, 'projectId is required to get all scenes.');

  return useQuery({
    queryKey: getGeneratedScenesQueryKey(projectId, organizationId),
    queryFn: ({ queryKey }) => {
      invariant(queryKey[0].projectId, 'projectId is required');

      return getAllScenes(queryKey[0]);
    },
    select: _filterScenes,
    staleTime: 0,
  });
};

export const useGetInfiniteGeneratedScenes = ({ staleTime }: { staleTime?: number }) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId } = useParams();
  invariant(projectId, 'projectId is required to get all scenes.');

  return useInfiniteQuery({
    queryKey: getInfiniteGeneratedScenesQueryKey(projectId, organizationId),
    queryFn: ({ queryKey, pageParam }) => {
      invariant(queryKey[0].projectId, 'projectId is required');

      return getAllScenes({ ...queryKey[0], pageId: pageParam });
    },
    getNextPageParam: (lastPage, allPages, pageParams) => {
      return lastPage.currentPage < lastPage.totalPages ? pageParams + 1 : undefined;
    },
    placeholderData: (prev) => prev,
    select: (data) => {
      const allItems = data.pages.reduce((acc, page) => {
        const sortedItems = page.items
          .sort((a, b) => b.creation_timestamp - a.creation_timestamp)
          .filter(
            (scene) =>
              scene.status === 'pending' ||
              scene.status === 'in_progress' ||
              scene.status === 'ready',
          );
        return acc.concat(sortedItems);
      }, [] as TScene[]);

      return {
        ...data.pages[0],
        items: allItems,
      };
    },
    initialPageParam: 0,
    ...(typeof staleTime !== 'undefined' && { staleTime }),
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export const MAX_PAGES_IN_CACHE = 2;

export const useResetInfiniteGeneratedScenesCache = (maxPages: number = MAX_PAGES_IN_CACHE) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.setQueriesData<InfiniteData<TPaginatedResponse<TScene>, number>>(
        {
          queryKey: [{ name: GET_INFINITE_GENERATED_SCENES_QUERY_NAME }],
          exact: false,
        },
        (existingData) => {
          if (!existingData) return existingData;

          // Keep only the first N pages of data, remove the rest from the cache.
          return {
            pageParams: existingData.pageParams.slice(0, maxPages),
            pages: existingData.pages.slice(0, maxPages),
          };
        },
      );
    };
  }, [maxPages, queryClient]);
};

export const GeneratedScenesContext = createContext<TPaginatedResponse<TScene> | undefined>(
  undefined,
);

export const useGeneratedScenesCtx = () => {
  const ctx = useContext(GeneratedScenesContext);
  invariant(ctx, 'useGeneratedScenesCtx must be used within a GeneratedScenesProvider');

  return ctx;
};
