import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { MiscEndpoint, PaymentPlanEndpoint } from '@/utils/fetch/constants';
import { getPaymentPlanApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';

type TCreateCustomerResponse = { customer_id: string };

type TCreateCustomerPayload = {
  name: string;
  email: string;
  shipping_info?: {
    address_info: {
      address: string;
      city: string;
      country: string;
      line1: string;
      postal_code: string;
      state: string;
    };
  };
  address_info?: {
    address: string;
    city: string;
    country: string;
    line1: string;
    postal_code: string;
    state: string;
  };
};

const createCustomer = async ({
  organizationId,
  ...creationPayload
}: TCreateCustomerPayload & {
  organizationId: number;
}) => {
  const url = getPaymentPlanApiUrl(organizationId, PaymentPlanEndpoint.CUSTOMER);
  const response = await apiClient.post<TCreateCustomerResponse>(url, creationPayload);
  return response.data.customer_id;
};

export const useCreateCustomerMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [PaymentPlanEndpoint.CUSTOMER, organizationId],
    mutationFn: (props: TCreateCustomerPayload) => {
      return createCustomer({ organizationId, ...props });
    },
  });
};

type TCreateSubscriptionPayload = {
  price_id: string;
  customer_id: string;
};

type TCreateSubscriptionResponse = {
  subscription_id: string;
  client_secret: string;
};

const createSubscription = async ({
  organizationId,
  price_id,
  customer_id,
}: TCreateSubscriptionPayload & {
  organizationId: number;
}) => {
  const url = getPaymentPlanApiUrl(organizationId, PaymentPlanEndpoint.SUBSCRIPTION);
  const response = await apiClient.post<TCreateSubscriptionResponse>(url, {
    price_id,
    customer_id,
  });
  return response.data;
};

export const useCreateSubscriptionMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [PaymentPlanEndpoint.SUBSCRIPTION],
    mutationFn: (props: TCreateSubscriptionPayload) => {
      return createSubscription({ organizationId, ...props });
    },
  });
};

const upgradeSubscription = async ({
  organizationId,
  price_id,
}: {
  organizationId: number;
  price_id: string;
}) => {
  const url = `${getPaymentPlanApiUrl(organizationId, PaymentPlanEndpoint.SUBSCRIPTION)}`;
  const response = await apiClient.put<{ subscription_id: string }>(url, {
    price_id,
  });
  return response.data;
};

export const useUpgradeSubscriptionMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [PaymentPlanEndpoint.SUBSCRIPTION, 'upgrade'],
    mutationFn: (props: { price_id: string }) => {
      return upgradeSubscription({ organizationId, ...props });
    },
  });
};

export type TProrationAmountResponse = {
  subscription_id: string;
  priceId: string;
  currency: string;
  fullPriceInCents: number;
  unusedAmountInCents: number;
  totalAmountInCents: number;
};

const getProrationAmount = async (organizationId: number, priceId: string) => {
  const url = getPaymentPlanApiUrl(organizationId, PaymentPlanEndpoint.PRORATION_AMOUNT);
  const response = await apiClient.get<TProrationAmountResponse>(url, {
    params: {
      priceId,
    },
  });
  return response.data;
};

export const useGetProrationAmount = (priceId: string) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useQuery({
    queryKey: [{ url: PaymentPlanEndpoint.PRORATION_AMOUNT, organizationId, priceId }],
    queryFn: ({ queryKey }) => getProrationAmount(queryKey[0].organizationId, queryKey[0].priceId),
  });
};

type TPlanBenefits = {
  existingBenefits: string[];
  newBenefits: string[];
  futureBenefits: string[];
};

export type TStripePriceInfo = {
  paymentPeriod: 'monthly' | 'yearly';
  priceId?: string;
  price: string;
  priceInCents?: number;
  currency: string;
};

export type TPaymentProduct = {
  name: string;
  type: 'free' | 'starter' | 'professional' | 'team' | 'enterprise';
  description: string;
  benefits: TPlanBenefits;
  productId: string;
  prices: TStripePriceInfo[];
  isPurchasable: boolean;
};

export type TStripePlansResponseSchema = {
  plans: TPaymentProduct[];
};

const getPaymentPlans = async (organizationId: number) => {
  const url = getPaymentPlanApiUrl(organizationId, PaymentPlanEndpoint.PLANS);
  const response = await apiClient.get<TStripePlansResponseSchema>(url);
  return response.data.plans;
};

export const useGetPaymentPlans = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useQuery({
    queryKey: [{ url: PaymentPlanEndpoint.PLANS, organizationId }],
    queryFn: ({ queryKey }) => getPaymentPlans(queryKey[0].organizationId),
    staleTime: Infinity,
    placeholderData: (prev) => prev,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

type TApplyPromotionCodePayload = {
  organization_id: number;
  subscriptionId: string;
  priceId: string;
  promotionCode: string;
};

type TApplyPromotionCodeResponse = {
  subscription_id: string;
  isPromotionValid: boolean;
  priceId: string;
  currency: string;
  fullPriceInCents: number;
  discountedPriceInCents: number;
};

const applyPromotionCode = async ({
  organization_id,
  subscriptionId,
  priceId,
  promotionCode,
}: TApplyPromotionCodePayload) => {
  const url = getPaymentPlanApiUrl(organization_id, PaymentPlanEndpoint.APPLY_PROMOTION_CODE);
  const response = await apiClient.post<TApplyPromotionCodeResponse>(url, {
    subscriptionId,
    priceId,
    promotionCode,
  });
  return response.data;
};

export const useApplyPromotionCodeMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [PaymentPlanEndpoint.APPLY_PROMOTION_CODE],
    mutationFn: (props: Omit<TApplyPromotionCodePayload, 'organization_id'>) => {
      return applyPromotionCode({ organization_id: organizationId, ...props });
    },
  });
};

type TContactUsPayload = {
  name: string;
  email: string;
  message: string;
  companyName?: string;
  phoneNumber?: string;
};

const sendContactUsMessage = async (payload: TContactUsPayload) => {
  return apiClient.post(MiscEndpoint.CONTACT_US, payload);
};

export const useSendContactUsMessage = () => {
  return useMutation({
    mutationKey: [MiscEndpoint.CONTACT_US],
    mutationFn: sendContactUsMessage,
    onSuccess: () => {
      toast.success('Message sent successfully. We will get back to you soon!');
    },
  });
};
