import { FC, ReactNode } from 'react';
import { ProductContext, useGetProductById } from '../products/helper';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductProvider: FC<ProductProviderProps> = ({ children }) => {
  const product = useGetProductById();

  if (product.data) {
    return <ProductContext.Provider value={product.data}>{children}</ProductContext.Provider>;
  }

  if (product.isError) {
    return (
      <RefetchView
        disabled={product.isLoading}
        onClick={() => {
          product.refetch();
        }}
      >
        <span>Failed to load product.</span>
      </RefetchView>
    );
  }

  return (
    <div className='mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );
};
