import { Button } from 'components/ui/button';
import { IconWrapper } from 'components/common/IconWrapper';
import { TooltipItem } from 'components/common/TooltipItem';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import { ImageType, TImageType } from '@/utils/store/constants';
import invariant from 'tiny-invariant';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';
import { cancelDrawingMode } from '@/hooks/canvas/useEscToCancelDrawingMode';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125';
const BUTTON_CL = 'bg-white hover:bg-white';

export const BgToggleView = () => {
  const canvasInstance = useProductCreateStore((state) => state.canvasInstance);
  const [selectedBg, setSelectedBg] =
    useState<Extract<TImageType, 'transparent' | 'opaque'>>('transparent');
  const setDrawingMode = useProductCreateStore((state) => state.setDrawingMode);

  const toggleBg = (type: typeof selectedBg) => {
    try {
      if (!canvasInstance) return;

      const objects = canvasInstance.getObjects();
      const transparentObj = objects.find((obj) => obj.imageType === ImageType.TRANSPARENT);
      invariant(transparentObj, 'Image of type TRANSPARENT not found in canvas.');

      const opaqueObj = objects.find((obj) => obj.imageType === ImageType.OPAQUE);
      invariant(opaqueObj, 'Image of type OPAQUE not found in canvas.');

      const imageToHide = type === ImageType.OPAQUE ? transparentObj : opaqueObj;
      const imageToShow = type === ImageType.OPAQUE ? opaqueObj : transparentObj;

      imageToHide.set({ visible: false });
      imageToShow.set({ visible: true, opacity: 1, left: imageToHide.left, top: imageToHide.top });
      canvasInstance.requestRenderAll();
      setSelectedBg(type);
      if (type === ImageType.OPAQUE) {
        setDrawingMode(undefined);
        cancelDrawingMode(canvasInstance);
      }
    } catch (error) {
      toast.error('Error while toggling background.');
    }
  };

  return (
    <Button className={BUTTON_CL} variant='ghost' asChild>
      <div className='flex gap-2'>
        <TooltipItem
          side='bottom'
          trigger={
            <div onClick={() => toggleBg(ImageType.OPAQUE)}>
              <IconWrapper
                wrapperProps={{
                  className: cn(ICON_WRAPPER_CL, {
                    'outline outline-1 outline-black': selectedBg === 'opaque',
                  }),
                }}
                iconClass='i-mdi-image-outline size-4'
              />
            </div>
          }
        >
          Original
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <div onClick={() => toggleBg(ImageType.TRANSPARENT)}>
              <IconWrapper
                wrapperProps={{
                  className: cn(ICON_WRAPPER_CL, {
                    'outline outline-1 outline-black': selectedBg === 'transparent',
                  }),
                }}
                iconClass='i-mdi-image-size-select-large h-5 w-5'
              />
            </div>
          }
        >
          Background Removed
        </TooltipItem>
      </div>
    </Button>
  );
};
