import React, { FC } from 'react';

interface IconWrapperProps {
  wrapperProps?: React.HTMLAttributes<HTMLSpanElement>;
  iconProps?: React.SVGAttributes<HTMLSpanElement>;
  iconClass?: string;
}

export const IconWrapper: FC<IconWrapperProps> = ({ iconProps, wrapperProps, iconClass }) => {
  return (
    <span {...wrapperProps}>
      <span className={iconClass} {...iconProps}></span>
    </span>
  );
};
