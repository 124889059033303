import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import Logo from 'assets/icons/logo-dark.svg?react';
import { Link } from 'react-router-dom';

export const CraitLogo = () => {
  return (
    <Link to={`${RoutePaths.BASE}`}>
      <Logo className='h-6 w-[88px] ' />
    </Link>
  );
};
