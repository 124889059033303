import { FC, useState } from 'react';
import { TProductResponse } from '../helper/queries';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { TabOption, useSceneTypeParam } from '../../useSceneTypeParam';
import { useAiSceneBulkCreateStore } from '@/providers/ai-scene/bulk-create/hooks';
import { nanoid } from 'nanoid';
import { Modal } from 'components/ui/modal';

import PhotoEditorIcon from '@/assets/icons/ai-scene-icons/photoEditor.svg?react';
import { ProductPhotoEditorView } from '../upload-product-modal/ProductPhotoEditorView';
import { useMobileBottomMenuStore } from 'components/ai-scene/mobile-bottom-menu/useMobileBottomMenuStore';

interface ProductItemCardProps {
  product: TProductResponse;
  isSelected: boolean;
}

export const ProductItemCard: FC<ProductItemCardProps> = ({ product, isSelected }) => {
  const addAiSceneImage = useAiSceneCreateStore((state) => state.addAiSceneImage);
  const addBulkSceneImage = useAiSceneBulkCreateStore((state) => state.addProductImage);
  const closeMobileBottomMenu = useMobileBottomMenuStore((state) => state.closeMobileBottomMenu);
  const [imageType, setImageType] = useState<'opaque' | 'transparent'>('transparent');
  const [sceneType] = useSceneTypeParam();

  return (
    <div
      onClick={() => {
        closeMobileBottomMenu();
        const image = imageType === 'opaque' ? product.image.original : product.image.transparent;
        const productPayload = {
          image,
          id: product.id,
          type: 'product',
          imageName: product.name,
          imageProperties: product.properties ? product.properties : null,
        } as const;
        if (sceneType === TabOption.SINGLE) {
          addAiSceneImage(productPayload);
          return;
        }
        const bulkPayload = {
          ...productPayload,
          bulkImageId: nanoid(),
        };
        addBulkSceneImage(bulkPayload);
      }}
      className={`flex  w-fit cursor-pointer select-none flex-col items-center gap-[2px] rounded-lg  border hover:border  hover:border-black sm:w-[48%] ${
        isSelected ? 'border-black' : 'border-transparent'
      }`}
    >
      <div className='relative flex h-[132px] w-[132px] items-center gap-[2px] overflow-hidden rounded-lg sm:w-full'>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className='absolute right-1 top-1 z-10 flex size-6 cursor-pointer items-center justify-center rounded-full bg-[#212121] bg-opacity-50 md:hidden'
        >
          <Modal
            trigger={<PhotoEditorIcon />}
            titleElement='Product Photo Editor'
            modalContentClassName='max-w-[90%] max-h-[90%] pt-8 overflow-auto w-auto'
          >
            {({ closeModal }) => (
              <ProductPhotoEditorView closeModal={closeModal} product={product} />
            )}
          </Modal>
        </div>

        <img crossOrigin='anonymous' src={product.thumbnail.original} className='object-cover' />
        {product.thumbnail.transparent && (
          <img
            src={product.thumbnail.transparent}
            className={`absolute inset-0 h-full w-full bg-background object-contain ${
              imageType === 'opaque' ? 'opacity-0' : 'opacity-100'
            }  transition-opacity duration-500`}
          />
        )}
      </div>
      <p className='max-w-[120px] truncate text-[12px] font-medium sm:max-w-[88%]'>
        {product.name}
      </p>
    </div>
  );
};
