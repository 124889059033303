import { useState, useMemo } from 'react';

export const useCanvasImageLoadingStatus = () => {
  const [isLoading, setIsLoading] = useState(false);

  const loadingView = useMemo(() => {
    return <LoadingCanvasWrapper isLoading={isLoading} />;
  }, [isLoading]);

  return [setIsLoading, loadingView] as const;
};

const LoadingCanvasWrapper = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div
      className={`absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-white bg-opacity-50 ${
        isLoading ? 'flex' : 'hidden'
      }`}
    >
      Loading...
    </div>
  );
};
