import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useQuery } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { TPaginatedResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export interface Member {
  id: number;
  email: string;
  user_name: string;
  avatar: string;
  organization_ids: number[];
  user_status: string;
  title: string;
  authority: string;
  participation_timestamp: number;
  member_status: string;
}

const getPeople = async (params: TGetPeoplePayload): Promise<TPaginatedResponse<Member>> => {
  const baseUrl = getAccountApiUrl(params.organizationId, AccountEndpoint.GET_ACCOUNT_MEMBERS);
  const urlWithParams = `${baseUrl}?pageId=${params.pageId || 0}`;
  const response = await apiClient.get(urlWithParams);
  return response.data;
};

export const GET_PEOPLE_QUERY_KEY = 'getOrgPeople';

type TGetPeoplePayload = {
  organizationId: number;
  pageId: number;
};

export const useGetPeople = (params: Omit<TGetPeoplePayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useQuery({
    queryKey: [
      {
        url: AccountEndpoint.GET_ACCOUNT_MEMBERS,
        organizationId,
        _name: GET_PEOPLE_QUERY_KEY,
        ...params,
      },
    ],
    queryFn: ({ queryKey }) => getPeople(queryKey[0]),
    placeholderData: (prev) => prev,
  });
};

export const PeopleContext = createContext<
  | {
      data: TPaginatedResponse<Member>;
      pagination: PaginationState;
      setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
    }
  | undefined
>(undefined);

export const useGetPeopleCtx = () => {
  const people = useContext(PeopleContext);
  invariant(people, 'useGetPeopleCtx must be used within a PeopleProvider');

  return people;
};
