import { AccountEndpoint } from '@/utils/fetch/constants';
import { Breakpoints } from '@/utils/general/constant';
import { getRelativeDate, triggerBlurOnEnter } from '@/utils/helper';
import { RoutePaths } from '@/utils/route/constants';
import { useCraitStore } from '@/utils/store/store';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  INFINITE_QUERY_TYPE_KEY,
  TSearchResponse,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { ImageModal } from 'components/common/ImageModal';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Input } from 'components/ui/input';
import { useAccountStatistics } from 'pages/dashboard/profile/helper';
import {
  TProduct,
  TProducts,
  useDeleteProduct,
  usePartialUpdateProductDetail,
} from 'pages/workspace/brand-library/products/helper';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';
import { useWindowSize } from 'usehooks-ts';

const IMG_CL = `absolute inset-0 h-full w-full object-contain bg-white`;
const ProductField = {
  SKU: 'productCode',
  TITLE: 'productName',
} as const;

export const ProductCard: FC<TProducts['items'][number]> = (props) => {
  const [isImageToggled, setIsImageToggled] = useState(true);

  return (
    <div className='h-[260px] w-full max-w-[260px] overflow-hidden rounded-md object-cover md:h-[170px]'>
      <div className='relative h-[calc(100%-65px)]'>
        <div className='relative h-full w-full bg-white'>
          <ImageModal
            isClickDisabled={isImageToggled}
            header={
              <div className='flex justify-center gap-2'>
                <span>{props.product_code}</span> - <span>{props.name}</span>
              </div>
            }
            content={<img crossOrigin='anonymous' src={props.image.original} />}
          >
            <img
              crossOrigin='anonymous'
              src={props.thumbnail.original}
              className={`${IMG_CL} cursor-zoom-in`}
            />
          </ImageModal>
          <ImageModal
            isClickDisabled={!isImageToggled}
            header={
              <div className='flex justify-center gap-2'>
                <span>{props.product_code}</span> - <span>{props.name}</span>
              </div>
            }
            content={<img crossOrigin='anonymous' src={props.image.transparent} />}
          >
            {props.thumbnail.transparent && (
              <img
                crossOrigin='anonymous'
                src={props.thumbnail.transparent}
                className={`${IMG_CL} ${
                  isImageToggled ? 'z-10 cursor-zoom-in opacity-100' : '-z-[1] opacity-0'
                } transition-opacity duration-500`}
              />
            )}
          </ImageModal>
        </div>
        {props.thumbnail.transparent && (
          <div
            onClick={() => setIsImageToggled((isToggled) => !isToggled)}
            className='absolute right-3 top-3 z-10 flex size-8 cursor-pointer items-center justify-center rounded-lg bg-crait-bg'
          >
            {isImageToggled ? (
              <span className='i-mdi-image-size-select-large h-5 w-5' />
            ) : (
              <span className='i-mdi-image-size-select-actual h-5 w-5' />
            )}
          </div>
        )}
      </div>
      <div className='relative flex items-center px-0 py-3'>
        <div className='flex max-w-[80%] flex-col '>
          <div className='line-clamp-2  truncate text-main font-semibold md:text-[11px]'>
            {props.name}
          </div>
          <div className='text-[12px] font-normal text-[#777777] md:text-[11px]'>
            Updated at {getRelativeDate(props.last_update_info.timestamp)}
          </div>
        </div>
        <DropdownDot {...props} />
      </div>
    </div>
  );
};

const DropdownDot: FC<TProducts['items'][number]> = ({ id, image, name }) => {
  const navigate = useNavigate();
  const setPendingImageToDraw = useCraitStore((state) => state.setPendingImageToDraw);
  const deleteProductMutation = useDeleteProduct();
  const queryClient = useQueryClient();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const deleteProduct = async () => {
    try {
      await deleteProductMutation.mutateAsync(id);
      queryClient.setQueriesData<InfiniteData<TSearchResponse<TProduct>>>(
        {
          queryKey: [{ searchSection: 'products', ...INFINITE_QUERY_TYPE_KEY }],
          exact: false,
        },
        (existingData) => {
          if (!existingData) return existingData;

          const updatedPages = existingData.pages.map((page) => {
            const filteredItems = page.products.items.filter((product) => product.id !== id);
            const diff = page.products.items.length - filteredItems.length;

            return {
              ...page,
              products: {
                ...page.products,
                items: filteredItems,
                totalItems: page.products.totalItems - diff,
                itemCount: page.products.itemCount - diff,
              },
            };
          });

          return {
            ...existingData,
            pages: updatedPages,
          };
        },
      );
      queryClient.invalidateQueries({
        queryKey: [{ url: AccountEndpoint.GET_ACCOUNT_STATISTICS }],
        exact: false,
      });
    } catch (error) {
      console.error('Error deleting product', error);
      toast.error('Failed to delete product');
    }
  };

  return (
    <>
      {isDesktop ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className='flex cursor-pointer items-center justify-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-40 p-2'>
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1 hover:bg-gray-50'
              onClick={() => {
                navigate(id);
              }}
            >
              Edit
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className='cursor-pointer px-2 py-1 hover:bg-gray-50'
              onClick={() => {
                setPendingImageToDraw({
                  id,
                  title: name,
                  url: image.transparent,
                  type: 'product',
                });
                navigate(`/${RoutePaths.AI_SCENE}`);
              }}
            >
              Use to Generate
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem className='cursor-pointer px-2 py-1' onClick={deleteProduct}>
              Delete
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <MobileMenuSheet
          triggerButton={
            <span className='flex cursor-pointer items-center justify-center px-1 py-1'>
              <span className='i-mdi-dots-vertical h-5 w-5 bg-gray-500'></span>
            </span>
          }
        >
          <div
            className='cursor-pointer px-2 py-1 hover:bg-gray-50'
            onClick={() => {
              navigate(id);
            }}
          >
            Edit
          </div>
          <div
            className='cursor-pointer px-2 py-1 hover:bg-gray-50'
            onClick={() => {
              setPendingImageToDraw({
                id,
                title: name,
                url: image.transparent,
                type: 'product',
              });
              navigate(`/${RoutePaths.AI_SCENE}`);
            }}
          >
            Use to Generate
          </div>
          <div className='cursor-pointer px-2 py-1' onClick={deleteProduct}>
            Delete
          </div>
        </MobileMenuSheet>
      )}
    </>
  );
};
