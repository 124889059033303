import { useInView } from 'react-intersection-observer';
import { TLightStyleResponse, useGetInfiniteSearchResults } from '../../helper/queries';
import { useResetInfiniteSearchCache } from '../../visual-guidance/styles/useResetInfiniteSearchCache';
import { useEffect, useMemo } from 'react';
import { RefetchView } from '../../helper/RefetchView';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { LightStyleItems } from './LightStylesItems';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';

const MAX_PAGES_IN_CACHE = 2;

export const LightStyleSection = () => {
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TLightStyleResponse>({
    searchSection: 'lighting_styles',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.lightingStyles;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
  });
  useResetInfiniteSearchCache<TLightStyleResponse>('lighting_styles', MAX_PAGES_IN_CACHE);

  const lightingStyleId =
    useAiSceneCreateStore((state) => state.aiScenePromptTab.lightingStyleId?.id) || null;
  const updateAiScenePromptTab = useAiSceneCreateStore((state) => state.updateAiScenePromptTab);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allStyles = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.lightingStyles.items) || [];
  }, [infiniteData]);

  if (isError) {
    return (
      <RefetchView
        disabled={isLoading}
        onClick={() => {
          refetch();
        }}
      >
        <span className='text-[11px]'>Failed to load light style.</span>
      </RefetchView>
    );
  }

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex w-full items-end justify-between text-[11px] font-semibold'>
        <div className='flex items-center gap-[6px]'>
          <span>LIGHT STYLES ({lightingStyleId ? '1/1' : '0/1'})</span>
          {lightingStyleId && (
            <span
              className='cursor-pointer font-semibold text-[#E16236]'
              onClick={() => {
                updateAiScenePromptTab({ lightingStyleId: null });
              }}
            >
              x
            </span>
          )}
        </div>
        {/* <span className='text-[#E16236] underline'>SEE ALL</span> */}
      </div>
      <ScrollArea className='h-full w-full'>
        <div className='mb-2 flex items-start gap-x-2.5 gap-y-3 p-1'>
          <LightStyleItems lightStyles={allStyles || []} lightingStyleId={lightingStyleId} />
        </div>
        {hasNextPage && (
          <div className='relative flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}

        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  );
};
