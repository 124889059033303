import { FC, ReactNode, useState } from 'react';
import { cn } from '@/lib/utils';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';
import { BannerAccordions } from './BannerAccordions';

interface SidebarProps {
  children?: ReactNode;
  onDrawerToggle?: (isShown: boolean) => void;
}

export const AiBannerSidebar: FC<SidebarProps> = ({ onDrawerToggle }) => {
  const [isDrawerShown, setIsDrawerShown] = useState(true);

  const leftDrawerWidth = isDrawerShown ? 'w-[420px]' : 'w-[50px]';

  const toggleDrawer = () => {
    setIsDrawerShown((isShown) => !isShown);
    onDrawerToggle?.(!isDrawerShown);
  };

  const generateButton = (
    <Button onClick={() => {}} className='w-full bg-crait-blue text-black'>
      Crait
    </Button>
  );

  return (
    <aside
      className={cn(
        `${leftDrawerWidth} relative h-full bg-[#072428] text-white shadow-md transition-width delay-0 duration-300 ease-in-out`,
        {
          'delay-150': !isDrawerShown,
        },
      )}
    >
      <Button
        onClick={toggleDrawer}
        className={`absolute -right-3 top-8 z-10 h-8 w-8 transform rounded-full border border-slate-100 p-0`}
      >
        <span
          className={`i-mdi-chevron-right size-4 bg-white ${isDrawerShown ? 'rotate-180' : ''}`}
        />
      </Button>
      <section
        className={cn(
          `h-full w-[420px] opacity-100 transition-opacity delay-0 duration-300 ease-in-out`,
          {
            'opacity-0': !isDrawerShown,
            'delay-150': isDrawerShown,
          },
        )}
      >
        <div className='h-full py-12 pl-5 pr-8'>
          <ScrollArea className='h-[calc(100%-40px)] w-full pb-4'>
            <BannerAccordions />
          </ScrollArea>
          {generateButton}
        </div>
      </section>
    </aside>
  );
};
