import { useCallback, useRef, useEffect } from 'react';
import { toast } from 'sonner';
import { nanoid } from 'nanoid';
import { useUploadFileMutation } from 'pages/workspace/brand-library/product-edit/helper';
import { convertFabricCanvasToFile } from '@/utils/helper';
import invariant from 'tiny-invariant';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import {
  CanvasResolutionLimit,
  isCanvasResolutionOutOfBounds,
} from 'components/ai-scene/create/editor/canvas/helper';

export const useDimensionSelection = () => {
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const addOutpaintData = useAiSceneOutpaintStore((state) => state.addOutpaintData);
  const changeProcessingState = useAiSceneOutpaintStore((state) => state.changeProcessingState);
  const { mutateAsync: uploadFileAsync } = useUploadFileMutation();
  const aheadOfTimeUploadedImageUrl = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!canvasInstance) return;

    aheadOfTimeUploadedImageUrl.current = undefined;
  }, [canvasInstance]);

  const onDimensionSelected = useCallback(
    async (width: number, height: number): Promise<true | undefined> => {
      try {
        if (isCanvasResolutionOutOfBounds(width, height)) {
          toast.error(
            `Canvas size must be between ${CanvasResolutionLimit.MIN} and ${CanvasResolutionLimit.MAX} pixels.`,
          );
          return;
        }
        invariant(canvasInstance, 'Canvas instance not found.');

        let uploadedImageUrl = aheadOfTimeUploadedImageUrl.current;
        if (!uploadedImageUrl) {
          changeProcessingState('outpainting');
          const canvasFile = convertFabricCanvasToFile({
            canvas: canvasInstance,
            fileName: 'canvas-file',
            extension: 'png',
          });
          uploadedImageUrl = await uploadFileAsync({ file: canvasFile });
        }

        addOutpaintData({
          width,
          height,
          id: nanoid(),
          url: uploadedImageUrl,
        });
        changeProcessingState('idle');
        return true;
      } catch (error) {
        changeProcessingState('idle');
        console.error('Error while applying canvas dimension change', error);
        toast.error('Error while applying canvas dimension change');
      }
    },
    [addOutpaintData, uploadFileAsync, canvasInstance, changeProcessingState],
  );

  return { onDimensionSelected, aheadOfTimeUploadedImageUrl };
};
