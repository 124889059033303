import { ScrollArea } from 'components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { StylesTab } from './styles/StylesTab';
import { TemplatesTab } from './templates/TemplatesTab';
import { FC } from 'react';
import { useIsAiImageType } from 'pages/ai-scene/useIsAiImageType';

const SubTabOptions = {
  STYLES: 'styles',
  TEMPLATES: 'templates',
  ELEMENTS: 'elements',
} as const;

const SUB_TAB_CL =
  'h-[40px] w-[112px] p-0 rounded-none mb-[-1px] border-b-2 shadow-none text-[15px] font-semibold !bg-transparent data-[state=active]:text-black data-[state=active]:shadow-none data-[state=active]:border-black';

interface IVisualGuidanceMenuContentProps {
  children?: React.ReactNode;
  returnCreateToolClick: () => void;
}

export const VisualGuidanceMenuContent: FC<IVisualGuidanceMenuContentProps> = ({
  children,
  returnCreateToolClick,
}) => {
  const isAiImageType = useIsAiImageType();
  return (
    <section className='h-full text-xs'>
      <ScrollArea className={'h-[calc(100%-70px)]'}>
        <main className='flex h-full flex-col gap-3 pb-3'>
          <Tabs defaultValue={SubTabOptions.STYLES} className='h-full w-full'>
            <TabsList className=' flex h-full w-full justify-start rounded-none border-b-[1px] bg-transparent p-0'>
              <TabsTrigger className={SUB_TAB_CL} value={SubTabOptions.STYLES}>
                Inspirations
              </TabsTrigger>
              {!isAiImageType && (
                <TabsTrigger className={`${SUB_TAB_CL} w-[170px]`} value={SubTabOptions.TEMPLATES}>
                  Scene Templates
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value={SubTabOptions.STYLES} className='relative m-0'>
              <StylesTab returnCreateToolClick={returnCreateToolClick} />
            </TabsContent>
            <TabsContent value={SubTabOptions.TEMPLATES} className='relative m-0'>
              <TemplatesTab returnCreateToolClick={returnCreateToolClick} />
            </TabsContent>
          </Tabs>
        </main>
      </ScrollArea>
      {children}
    </section>
  );
};
