import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea } from 'components/ui/scroll-area';
import { useGetLogos } from 'components/workspace/brand-library/brand-kit/queries';
import { useMemo } from 'react';

export const LogoView = () => {
  const brandKitLogos = useBannerCreateStore((s) => s.brandKitLogos);
  const addBrandKitLogo = useBannerCreateStore((s) => s.addBrandKitLogo);
  const removeBrandKitLogo = useBannerCreateStore((s) => s.removeBrandKitLogo);
  const { data, isError, isLoading, refetch } = useGetLogos({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load logos.</span>
    </RefetchView>
  );

  const logoItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No logos found.</span>
        </RefetchView>
      );
    }

    return data.items.map((logo) => {
      const stringifiedId = logo.id.toString();
      const isSelected = brandKitLogos.some((l) => l.id === stringifiedId);

      return (
        <div
          key={logo.id}
          onClick={() => {
            if (isSelected) {
              removeBrandKitLogo(stringifiedId);
            } else {
              addBrandKitLogo({
                id: stringifiedId,
                name: logo.name,
                url: logo.image_url,
              });
            }
          }}
          className={`hover:border-1 flex  cursor-pointer select-none flex-col items-center gap-2.5 rounded-lg p-2 hover:outline hover:outline-crait-blue ${
            isSelected ? 'bg-crait-blue opacity-70' : ''
          }`}
        >
          <div className='relative flex h-[160px] w-full items-center gap-2 overflow-hidden rounded-lg'>
            <img crossOrigin='anonymous' src={logo.thumbnail} className='object-cover' />
          </div>
          <p className='line-clamp-1 text-ellipsis'>{logo.name}</p>
        </div>
      );
    });
  }, [data, isLoading, refetch, brandKitLogos, addBrandKitLogo, removeBrandKitLogo]);

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{brandKitLogos.length} Selected</span>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='grid grid-cols-3 gap-4 p-4'>{errorView || logoItems}</div>
      </ScrollArea>
    </div>
  );
};
