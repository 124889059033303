import { ProductCard } from '../ProductCard';
import { ScrollArea } from 'components/ui/scroll-area';
import { AnimatePresence, motion } from 'framer-motion';
import { useCategoryParam } from './useCategoryParam';
import { AddProductModal } from './upload/AddProductModal';
import { Command, CommandInput } from 'components/ui/command';
import { FC, ReactNode, useEffect, useMemo } from 'react';

import { useDebounceValue } from 'usehooks-ts';
import { CategoriesDropdown } from './CategoriesDropdown';

import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { appendPluralSuffixByNumber } from '@/utils/helper';
import { useGetInfiniteSearchResults } from 'components/ai-scene/side-menu/create-tool/helper/queries';
import { TProductResponse } from 'components/ai-scene/side-menu/create-tool/helper/queries';
import { useResetInfiniteSearchCache } from 'components/ai-scene/side-menu/create-tool/visual-guidance/styles/useResetInfiniteSearchCache';
import { RefetchView } from 'components/ai-scene/side-menu/create-tool/helper/RefetchView';

const MAX_PAGES_IN_CACHE = 2;

interface BrandProductsViewProps {
  children?: ReactNode;
}
export const BrandProductsView: FC<BrandProductsViewProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounceValue('', 200);
  const [activeCategoryId] = useCategoryParam();
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TProductResponse>({
    keyword: searchValue,
    categoryId: activeCategoryId !== null ? activeCategoryId : undefined,
    searchSection: 'products',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.products;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
    refetchOnMount: true,
  });
  useResetInfiniteSearchCache<TProductResponse>('products', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allProducts = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.products.items) || [];
  }, [infiniteData]);

  const isLoadingView = isLoading && (
    <div className='mx-auto mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load products.</span>
    </RefetchView>
  );

  const visibleItems = useMemo(
    () =>
      allProducts?.map((product) => (
        <motion.div key={product.id} layout>
          <ProductCard {...product} />
        </motion.div>
      )),
    [allProducts],
  );

  const hasAnyProducts = allProducts?.length && allProducts?.length > 0;
  const productItems =
    !hasAnyProducts && !isLoading ? <span>No products found.</span> : visibleItems;

  const categoriesDropdown = useMemo(() => <CategoriesDropdown />, []);
  const totalItemCount = infiniteData?.pages[0]?.products.totalItems || 0;

  return (
    <div className='h-full'>
      {children}
      <div className='mt-2 flex flex-wrap items-center justify-between gap-3'>
        <div className='flex items-center gap-3'>
          <h4 className='mb-1 flex items-center text-[26px] font-medium'>Products</h4>
          <span className='text-sm font-medium'>
            {totalItemCount} product{appendPluralSuffixByNumber(totalItemCount)}
          </span>
        </div>
        <div className='flex gap-2 lg:w-full lg:flex-wrap'>
          <div className='flex w-full gap-2 md:flex-col'>
            <Command className='flex h-10 w-[200px] justify-center rounded-lg border border-crait-dark-300 bg-transparent lg:w-full '>
              <CommandInput placeholder='Search product name' onValueChange={setSearchValue} />
            </Command>
            {categoriesDropdown}
          </div>
          <AddProductModal />
        </div>
      </div>
      <ScrollArea className={`mt-6 h-[calc(100%-80px)] pr-3`}>
        {isLoadingView || errorView}
        <div className='mt-10 grid max-w-[1440px] grid-cols-2 gap-6 gap-y-10 sm:gap-3 min-sm:grid-cols-4 min-md:grid-cols-3 min-lg:grid-cols-4 min-xl:grid-cols-4  min-3xl:grid-cols-5'>
          <AnimatePresence>{productItems}</AnimatePresence>
        </div>
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
