import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { BrandKitSubEndpoint } from '@/utils/fetch/constants';
import { getBrandKitApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TPaginatedResponse } from 'components/ai-scene/sidebar/settings-tab/queries';

export type TLogoResponse = {
  id: string;
  name: string;
  thumbnail: string;
  image_url: string;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
};

type TGetLogosPayload = {
  organizationId: number;
  pageId?: number;
};

export const getLogos = async (props: TGetLogosPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.LOGOS);
  const urlWithPage = `${url}?pageId=${props.pageId || 0}`;
  const response = await apiClient.get<TPaginatedResponse<TLogoResponse>>(urlWithPage);
  return response.data;
};

export const GET_LOGOS_QUERY_KEY = 'GET_LOGOS_QUERY';

export const useGetLogos = (props: Omit<TGetLogosPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [
      { url: BrandKitSubEndpoint.LOGOS, ...props, organizationId, _name: GET_LOGOS_QUERY_KEY },
    ],
    queryFn: ({ queryKey }) => {
      return getLogos(queryKey[0]);
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
  });
  return result;
};

type TUploadLogoPayload = {
  name: string;
  image: File;
  organizationId: number;
};

const uploadLogo = async (props: TUploadLogoPayload): Promise<TLogoResponse> => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.LOGOS);
  const formData = new FormData();
  formData.append('image', props.image);
  formData.append('name', props.name);
  const response = await apiClient.post<TLogoResponse>(url, formData);
  return response.data;
};

export const useUploadLogoMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.LOGOS, organizationId],
    mutationFn: (props: Omit<TUploadLogoPayload, 'organizationId'>) =>
      uploadLogo({
        ...props,
        organizationId,
      }),
  });
};

type TUpdateLogoPayload = TUploadLogoPayload & Pick<TLogoResponse, 'id'>;

const updateLogo = async (props: TUpdateLogoPayload) => {
  const baseUrl = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.LOGOS);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  formData.append('image', props.image);
  formData.append('name', props.name);
  const response = await apiClient.put<TLogoResponse>(url, formData);
  return response.data;
};

export const useUpdateLogo = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.LOGOS, organizationId],
    mutationFn: (props: Omit<TUpdateLogoPayload, 'organizationId'>) =>
      updateLogo({
        ...props,
        organizationId,
      }),
  });
};

type TPartialUpdateLogoPayload = Omit<TUploadLogoPayload, 'image'> & Pick<TLogoResponse, 'id'>;

const renameLogo = async (props: TPartialUpdateLogoPayload) => {
  const baseUrl = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.LOGOS);
  const url = `${baseUrl}/${props.id}`;
  const response = await apiClient.patch<TLogoResponse>(url, {
    name: props.name,
  });
  return response.data;
};

export const useRenameLogoMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.LOGOS, organizationId],
    mutationFn: (props: Omit<TPartialUpdateLogoPayload, 'organizationId'>) =>
      renameLogo({
        ...props,
        organizationId,
      }),
  });
};

export type TFontResponse = {
  id: string;
  name: string;
  fonts: Array<{
    id: string;
    fontFamily: string;
    fontStyle: string;
    fontWeight: number;
    url: string;
    format: string;
  }>;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
};

const getFonts = async (props: TGetLogosPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.FONTS);
  const urlWithPage = `${url}?pageId=${props.pageId || 0}`;
  const response = await apiClient.get<TPaginatedResponse<TFontResponse>>(urlWithPage);
  return response.data;
};

export const useGetFonts = (props: Omit<TGetLogosPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: BrandKitSubEndpoint.FONTS, ...props, organizationId }],
    queryFn: ({ queryKey }) => {
      return getFonts(queryKey[0]);
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
  });
  return result;
};

type TUploadFontPayload = {
  files: File[];
  organizationId: number;
};

const uploadFont = async (props: TUploadFontPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.FONTS);
  const formData = new FormData();
  props.files.forEach((file) => {
    formData.append('file', file);
  });
  const response = await apiClient.post<TFontResponse>(url, formData);
  return response.data;
};

export const useUploadFontMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.FONTS, organizationId],
    mutationFn: (props: Omit<TUploadFontPayload, 'organizationId'>) =>
      uploadFont({
        ...props,
        organizationId,
      }),
  });
};

export type TColorResponse = {
  id: string;
  name: string;
  hexCode: string;
  opacity: number;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
};

const getColors = async (props: TGetLogosPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.COLORS);
  const urlWithPage = `${url}?pageId=${props.pageId || 0}`;
  const response = await apiClient.get<TPaginatedResponse<TColorResponse>>(urlWithPage);
  return response.data;
};

export const GET_COLORS_QUERY_KEY = 'GET_COLORS_QUERY';

export const useGetColorsQuery = (props: Omit<TGetLogosPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [
      { url: BrandKitSubEndpoint.COLORS, ...props, organizationId, _name: GET_COLORS_QUERY_KEY },
    ],
    queryFn: ({ queryKey }) => {
      return getColors(queryKey[0]);
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
  });
  return result;
};

type TUploadColorPayload = {
  name: string;
  hexCode: string;
  opacity: number;
  organizationId: number;
};

const uploadColor = async (props: TUploadColorPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.COLORS);
  const response = await apiClient.post<TColorResponse>(url, {
    name: props.name,
    hexCode: props.hexCode,
    opacity: props.opacity,
  });
  return response.data;
};

export const useUploadColorMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.COLORS, organizationId],
    mutationFn: (props: Omit<TUploadColorPayload, 'organizationId'>) =>
      uploadColor({
        ...props,
        organizationId,
      }),
  });
};

type TUpdateColorPayload = TUploadColorPayload & Pick<TColorResponse, 'id'>;

const updateColor = async (props: TUpdateColorPayload) => {
  const baseUrl = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.COLORS);
  const url = `${baseUrl}/${props.id}`;
  const response = await apiClient.put<TColorResponse>(url, {
    name: props.name,
    hexCode: props.hexCode,
    opacity: props.opacity,
  });
  return response.data;
};

export const useUpdateColorMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.COLORS, organizationId],
    mutationFn: (props: Omit<TUpdateColorPayload, 'organizationId'>) =>
      updateColor({
        ...props,
        organizationId,
      }),
  });
};

type TPartialUpdateColorPayload = Partial<TUploadColorPayload> &
  Pick<TUploadColorPayload, 'organizationId'> &
  Pick<TColorResponse, 'id'>;

const partialUpdateColor = async (props: TPartialUpdateColorPayload) => {
  const baseUrl = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.COLORS);
  const url = `${baseUrl}/${props.id}`;
  const response = await apiClient.patch<TColorResponse>(url, {
    name: props.name || undefined,
    hexCode: props.hexCode || undefined,
    opacity: props.opacity || undefined,
  });
  return response.data;
};

export const usePartialUpdateColorMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [BrandKitSubEndpoint.COLORS, organizationId],
    mutationFn: (props: Omit<TPartialUpdateColorPayload, 'organizationId'>) =>
      partialUpdateColor({
        ...props,
        organizationId,
      }),
  });
};

type TRemoveColorPayload = {
  id: string;
  organizationId: number;
};

const removeColor = async (props: TRemoveColorPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.COLORS);
  await apiClient.delete<string>(`${url}/${props.id}`);
};

export const useRemoveColorMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: BrandKitSubEndpoint.COLORS, organizationId }],
    mutationFn: (props: Omit<TRemoveColorPayload, 'organizationId'>) =>
      removeColor({
        ...props,
        organizationId,
      }),
  });
};

const removeFont = async (props: TRemoveColorPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.FONTS);
  await apiClient.delete<string>(`${url}/${props.id}`);
};

export const useRemoveFontMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: BrandKitSubEndpoint.FONTS, organizationId }],
    mutationFn: (props: Omit<TRemoveColorPayload, 'organizationId'>) =>
      removeFont({
        ...props,
        organizationId,
      }),
  });
};

const removeLogo = async (props: TRemoveColorPayload) => {
  const url = getBrandKitApiUrl(props.organizationId, BrandKitSubEndpoint.LOGOS);
  await apiClient.delete<string>(`${url}/${props.id}`);
};

export const useRemoveLogoMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: BrandKitSubEndpoint.LOGOS, organizationId }],
    mutationFn: (props: Omit<TRemoveColorPayload, 'organizationId'>) =>
      removeLogo({
        ...props,
        organizationId,
      }),
  });
};
