import { Button } from 'components/ui/button';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCraitStore } from '@/utils/store/store';
import { nanoid } from 'nanoid';
import { useBulkRemoveBgMutation } from '../../bulk-products/table/queries';
import { TBulkProductItem } from 'pages/workspace/brand-library/bulk-products/provider/store';
import {
  BULK_PRODUCT_EXCEL_TITLES,
  IFileReaderUploadSuccess,
  TBulkProductExcel,
  convertExcelBufferToRecords,
  exportAsXLSX,
  getExcelFileArrayBuffer,
  getProductExcelMismatchCauses,
  hasCorrectHeaderRow,
  separateProductRecordsFromExcelHeader,
} from '../helper';
import { parseError } from '@/utils/error/error';
import { ExcelUploadInfo, TUploadStatus } from './ExcelUploadInfo';
import { ScrollArea } from 'components/ui/scroll-area';
import { toast } from 'sonner';
import { DragAndDrop } from './DragAndDrop';

interface UploadedExcelInfo {
  fileName: string;
  numOfRecords: number;
  rawFile: File | null;
  uploadedRecords: TBulkProductExcel[];
}

type TExcelUploadViewProps = {
  children: ReactNode;
};

export const ExcelUploadView: FC<TExcelUploadViewProps> = ({ children }) => {
  const navigate = useNavigate();
  const setUploadedBulkProducts = useCraitStore((state) => state.setUploadedBulkProducts);
  const bulkRemoveBgMutation = useBulkRemoveBgMutation();
  const [uploadStatus, setUploadStatus] = useState<TUploadStatus>('idle');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [excelInfo, setExcelInfo] = useState<UploadedExcelInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const downloadEmptyExcel = () => {
    const headers = [...BULK_PRODUCT_EXCEL_TITLES];
    const fileName = 'products_template.xlsx';
    exportAsXLSX({
      data: [],
      boilerplateData: [headers],
      fileName,
      excelTitle: 'products',
      onError: (message) => {
        toast.error(message);
      },
      onSuccess: () => {
        toast.success('Excel file downloaded successfully.');
      },
    });
  };

  const clearImportedFile = useCallback(() => {
    setUploadStatus('idle');
    setExcelInfo(null);
    setValidationErrors([]);
    if (!inputRef.current) return;
    inputRef.current.value = '';
  }, []);

  const onUploadFailed = useCallback(
    ({ fileName, message }: { fileName: string; message: string | string[] }) => {
      setUploadStatus('error');
      setValidationErrors(Array.isArray(message) ? message : [message]);
      setExcelInfo({ fileName } as UploadedExcelInfo);
    },
    [],
  );

  const validateExcelRecords = useCallback(
    ({ data, file }: IFileReaderUploadSuccess) => {
      try {
        setUploadStatus('loading');
        const rows = convertExcelBufferToRecords(data, BULK_PRODUCT_EXCEL_TITLES);
        const { records, headerRow, headerIndex } = separateProductRecordsFromExcelHeader(rows);

        if (!hasCorrectHeaderRow(BULK_PRODUCT_EXCEL_TITLES, headerRow)) {
          throw Error(
            `The file does not have the correct header row. Expected headers are ${BULK_PRODUCT_EXCEL_TITLES.join(
              ', ',
            )}.`,
          );
        }
        if (records.length < 1) throw Error('No records found in the file.');

        const mismatchedCauses = getProductExcelMismatchCauses({
          headerIndex,
          records,
        });
        if (mismatchedCauses.length > 0) {
          return onUploadFailed({
            fileName: file.name,
            message: mismatchedCauses,
          });
        }

        setExcelInfo({
          uploadedRecords: records,
          rawFile: file,
          fileName: file.name,
          numOfRecords: records.length,
        });
        setUploadStatus('success');
        setValidationErrors([]);
      } catch (err) {
        onUploadFailed({
          fileName: file.name,
          message: parseError(err).message,
        });
      }
    },
    [onUploadFailed],
  );

  const uploadProductsWithExcel = async () => {
    try {
      if (!excelInfo?.uploadedRecords) return;

      setIsSubmitting(true);
      const formattedProducts = excelInfo.uploadedRecords.map((record) => ({
        id: nanoid(),
        sku: record.SKU,
        name: record.Name,
        originalImage: record.Image,
        category: null,
      }));
      const processPayload = formattedProducts.map((product) => ({
        id: product.id,
        imageUrl: product.originalImage,
      }));
      const { data } = await bulkRemoveBgMutation.mutateAsync({
        products: processPayload,
      });
      const populatedResults = data.products
        .map((result) => {
          const originalProduct = formattedProducts.find((product) => product.id === result.id);
          if (!originalProduct) return null;

          return {
            ...originalProduct,
            transparentImage: result.imageUrl,
          };
        })
        .filter(Boolean) as TBulkProductItem[];

      setUploadedBulkProducts(populatedResults);
      navigate(`edit/${data.jobId}`);
    } catch (error) {
      toast.error('Failed to upload products.');
      clearImportedFile();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='relative mt-1 flex flex-col items-center gap-4'>
      <Button variant='link' className='text-xs' onClick={downloadEmptyExcel}>
        Download Excel Format
      </Button>
      <label
        htmlFor='excel-file'
        className='flex cursor-pointer flex-col items-center justify-center gap-1 text-sm'
      >
        <p className='text-base font-medium'>Choose or drop Excel files</p>
      </label>
      <DragAndDrop
        className='flex h-32 w-72 cursor-pointer'
        onFileDrop={(file) => {
          getExcelFileArrayBuffer({
            file,
            onError: onUploadFailed,
            onSuccess: validateExcelRecords,
          });
        }}
        onClick={() => inputRef.current?.click()}
      >
        <span className='i-mdi-download size-5' />
      </DragAndDrop>
      <input
        ref={inputRef}
        key={validationErrors.length}
        id='excel-file'
        type='file'
        accept='.xlsx'
        className='hidden'
        onChange={(e) => {
          const files = e.target.files;
          if (!files || files.length < 1) return;

          getExcelFileArrayBuffer({
            file: files[0],
            onError: onUploadFailed,
            onSuccess: validateExcelRecords,
          });
        }}
      />
      <ExcelUploadInfo
        fileName={excelInfo?.fileName || ''}
        status={uploadStatus}
        clearFile={clearImportedFile}
      />
      {validationErrors.length > 0 && (
        <ScrollArea className='max-h-[150px]'>
          <div className='divide-y'>
            {validationErrors.map((error) => (
              <p key={error} className='py-2 text-xs text-crait-green-500'>
                {error}
              </p>
            ))}
          </div>
        </ScrollArea>
      )}
      <div className='flex w-72 flex-col gap-3'>
        <Button
          className='w-full'
          disabled={!excelInfo?.rawFile || isSubmitting}
          onClick={uploadProductsWithExcel}
        >
          Upload
        </Button>
        {children}
      </div>
    </div>
  );
};
