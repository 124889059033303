import { TableCell, TableRow } from 'components/ui/table';
import { FC, useEffect, useMemo, useState } from 'react';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { PopoverView } from 'components/common/PopoverView';
import { ListSearchWithCreateButton } from '../../products-edit/ListSearchWithCreateButton';
import { TBulkProductItem } from 'pages/workspace/brand-library/bulk-products/provider/store';
import { TooltipItem } from 'components/common/TooltipItem';
import { useNavigate } from 'react-router';
import { useBulkProductsStore } from 'pages/workspace/brand-library/bulk-products/provider/helper';
import { useGetBulkItemRemoveBgProcessQuery } from './queries';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Skeleton } from 'components/ui/skeleton';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { toast } from 'sonner';
import {
  useCreateProductCategoryMutation,
  useGetProductCategories,
} from 'components/ai-scene/sidebar/settings-tab/queries';

const CELL_CL = 'border border-gray-200 border-collapse rounded-lg text-black font-normal py-2.5';

interface ProductItemProps {
  mutableData: Record<string, TBulkProductItem>;
  jobId: string;
}

export const ProductItem: FC<TBulkProductItem & ProductItemProps> = ({
  id,
  sku,
  name,
  category,
  originalImage,
  transparentImage,
  mutableData,
  jobId,
}) => {
  const navigate = useNavigate();
  const setProducts = useBulkProductsStore((state) => state.setProducts);
  const setSelectedProduct = useBulkProductsStore((state) => state.setSelectedProduct);
  const { data, isError, refetch, isLoading } = useGetBulkItemRemoveBgProcessQuery({
    jobId,
    itemId: id,
    isAlreadyProcessed: Boolean(transparentImage),
  });
  const { data: categories, refetch: refetchCategories } = useGetProductCategories();
  const createProductCategoryMutation = useCreateProductCategoryMutation();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(
    category || undefined,
  );

  const categoryOptions = useMemo(() => {
    return categories?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categories]);

  useEffect(() => {
    if (!data?.image.transparent) return;

    mutableData[id] = {
      ...mutableData[id],
      transparentImage: data.image.transparent,
      originalImage: data.image.original,
    };
  }, [data?.image.transparent, data?.image.original, mutableData, id]);

  const handleCategoryCreate = async (name: string) => {
    try {
      const newCategory = await createProductCategoryMutation.mutateAsync(name);
      toast.success('Category created');
      refetchCategories();
      setSelectedCategoryId(newCategory.id);
      mutableData[id] = { ...mutableData[id], category: newCategory.id };
    } catch (error) {
      console.error('Error creating category', error);
      toast.error('Failed to create category');
      throw error;
    }
  };

  const removeButton = (
    <PopoverView
      popoverContentProps={{
        className: 'p-2',
        align: 'end',
      }}
      trigger={
        <Button
          size='sm'
          variant='ghost'
          className='h-9 w-9 rounded-full border bg-white p-0 hover:scale-125'
        >
          <span className='i-mdi-trash-can-outline size-5 text-[#df565bcb]'></span>
        </Button>
      }
    >
      {(props) => (
        <div className='relative flex flex-col gap-3 p-3'>
          <Button
            onClick={() => {
              props.closePopover();
            }}
            size='sm'
            variant='ghost'
            className='absolute right-1 top-1 rounded-full px-2.5 py-1'
          >
            <span className='i-mdi-window-close size-5' />
          </Button>
          <p className='pr-6 text-sm font-medium text-gray-600'>
            Are you sure you want to delete this row?
          </p>
          <Button
            size='sm'
            className='h-8 rounded-xl bg-[#DF565B] py-1'
            onClick={() => {
              delete mutableData[id];
              const possiblyUpdatedProducts = Object.values(mutableData);
              setProducts(possiblyUpdatedProducts);
              props.closePopover();
            }}
          >
            Yes, delete
          </Button>
        </div>
      )}
    </PopoverView>
  );

  if (isError || data?.status === 'error') {
    return (
      <TableRow>
        <TableCell colSpan={6} className='py-0 text-center'>
          <RefetchView
            containerClass='p-0'
            disabled={isLoading}
            onClick={() => {
              refetch();
            }}
          >
            Error processing transparent image. SKU: {` "${sku}" `}, Name: {` "${name}" `}
          </RefetchView>
        </TableCell>
        <TableCell colSpan={1} className='text-center'>
          {removeButton}
        </TableCell>
      </TableRow>
    );
  }

  if (data?.status === 'in_progress' || data?.status === 'pending' || isLoading) {
    return (
      <TableRow className='relative h-[78px]'>
        <TableCell colSpan={7} className='py-0 text-center'>
          <div className='absolute left-0 top-0 z-10 flex size-full flex-col items-center justify-center gap-2'>
            <LoadingSpinner className='' />
          </div>
          <Skeleton className='mx-auto h-14 w-full bg-gray-200' />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow className='h-[78px] hover:bg-[rgba(105,186,200,0.10)]'>
      <TableCell className={CELL_CL} width={50}>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              size='sm'
              variant='ghost'
              className='h-9 w-9 rounded-full border bg-slate-100 p-0 hover:scale-125'
              onClick={() => {
                if (!mutableData[id].transparentImage) {
                  toast.warning('Please wait for the image to be processed');
                  return;
                }
                const possiblyUpdatedProducts = Object.values(mutableData);
                setProducts(possiblyUpdatedProducts);
                setSelectedProduct(mutableData[id]);
                navigate(id);
              }}
            >
              <span className='i-mdi-edit size-5 text-crait-green-300'></span>
            </Button>
          }
        >
          Edit
        </TooltipItem>
      </TableCell>
      <TableCell className={`${CELL_CL}`} width={140}>
        <img
          crossOrigin='anonymous'
          src={data?.image.original || originalImage}
          className='mx-auto h-14 w-14 rounded-md border border-gray-100'
        />
      </TableCell>
      <TableCell className={`${CELL_CL}`} width={100}>
        <img
          crossOrigin='anonymous'
          src={data?.image.transparent || transparentImage || undefined}
          className='mx-auto h-14 w-14 rounded-md border border-gray-100'
        />
      </TableCell>
      <TableCell className={CELL_CL} width={150}>
        <Input
          defaultValue={sku}
          onBlur={(e) => {
            mutableData[id] = { ...mutableData[id], sku: e.target.value };
          }}
        />
      </TableCell>
      <TableCell className={CELL_CL} width={180}>
        <Input
          defaultValue={name}
          onBlur={(e) => {
            mutableData[id] = { ...mutableData[id], name: e.target.value };
          }}
        />
      </TableCell>
      <TableCell className={CELL_CL} width={200}>
        <ListSearchWithCreateButton
          controlledValue={selectedCategoryId}
          options={categoryOptions || []}
          onItemSelect={(item) => {
            setSelectedCategoryId(item.value);
            mutableData[id] = { ...mutableData[id], category: item.value };
          }}
          onCategoryCreate={handleCategoryCreate}
        />
      </TableCell>
      <TableCell className={`${CELL_CL}`}>{removeButton}</TableCell>
    </TableRow>
  );
};
