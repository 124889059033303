type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
  gtag: (...args: any[]) => void;
};

declare const window: WindowWithDataLayer;

type TConsent = 'granted' | 'denied';

const sendPageviewEvent = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

const sendPurchaseEvent = (transactionId: string, valueInDollars: number) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        currency: 'USD',
        transaction_id: transactionId,
        value: valueInDollars,
      },
    },
  });
};

const sendNewUserSignupEvent = () => {
  window.dataLayer.push({
    event: 'newUserSignup',
  });
};

const sendInitiateCheckoutEvent = () => {
  window.dataLayer.push({
    event: 'initiateCheckout',
  });
};

const setConsentModeForAds = (consent: TConsent) => {
  window.gtag('consent', 'update', {
    ad_storage: consent,
    ad_user_data: consent,
    ad_personalization: consent,
  });
};

const setConsentModeForAnalytics = (consent: TConsent) => {
  window.gtag('consent', 'update', {
    analytics_storage: consent,
  });
};

export const GTM = {
  sendPageviewEvent,
  sendPurchaseEvent,
  sendNewUserSignupEvent,
  sendInitiateCheckoutEvent,
  setConsentModeForAds,
  setConsentModeForAnalytics,
};
