import { useState } from 'react';

import { Modal } from 'components/ui/modal';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '@/utils/general/constant';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { CField } from './ProfileInfoCard';
import { useGetCompanyInfoQuery } from '../helper';
import { WorkInfoForm } from './WorkInfoForm';

export const WorkInfoCard = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const { data, refetch } = useGetCompanyInfoQuery();
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  if (!data) return null;

  const handleOpenChange = (isOpen: boolean) => {
    setIsModalShown(isOpen);
  };

  return (
    <div className=' w-full gap-5 rounded-lg border p-4'>
      <div className='mb-6 flex w-full items-center justify-between'>
        <h2 className='text-[14px] font-bold'>Work Info</h2>
        <Button
          variant='link'
          onClick={() => setIsModalShown(true)}
          className='h-min p-0 text-[13px] font-semibold text-[#E16236] underline underline-offset-[1px]'
        >
          Edit
        </Button>
      </div>
      <div className='flex w-full items-start gap-7'>
        <div className='flex w-full flex-col gap-4'>
          <CField label='Company Name' value={data?.companyName} />
          <Separator />
          <CField
            label='Company Number'
            value={data?.phoneNumber ? `+${data?.phoneCountryCode} ${data?.phoneNumber}` : ''}
          />
          <Separator />
          <CField label='Industry' value={data?.industry} />
          <Separator />
          <CField label='Role' value={data?.employeeRole} />
        </div>
      </div>

      {isModalShown && (
        <>
          {isDesktop ? (
            <Modal
              open={isModalShown}
              onOpenChange={handleOpenChange}
              titleElement='Edit Work Info'
              modalContentClassName='max-w-[640px]'
            >
              {({ closeModal }) => (
                <WorkInfoForm refetch={refetch} data={data} onCloseModal={closeModal} />
              )}
            </Modal>
          ) : (
            <MobileMenuSheet
              isOpen={isModalShown}
              onOpenChange={handleOpenChange}
              titleElement='Edit Work Info'
              contentClassName='bg-white max-h-[85%]'
            >
              {({ closeModal }) => (
                <WorkInfoForm refetch={refetch} data={data} onCloseModal={closeModal} />
              )}
            </MobileMenuSheet>
          )}
        </>
      )}
    </div>
  );
};
