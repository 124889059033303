import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { TBannerStore } from './store';
import { BannerCreateContext } from './helper';

export const useBannerCreateStore = <T,>(
  selector: (state: TBannerStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(BannerCreateContext);
  if (!store) throw new Error('Missing BannerCreateContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
