import { PlanProtectedRoute } from 'components/plan-guard/PlanProtectedRoute';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { BrandTemplateView } from 'components/workspace/brand-library/template/BrandTemplateView';

export const BrandLibraryTemplatePage = () => {
  return (
    <PlanProtectedRoute requiredPlan={FeatureAccessRequirements.TEMPLATES_PAGE.requiredPlan}>
      <BrandTemplateView />
    </PlanProtectedRoute>
  );
};
