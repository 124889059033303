import { cn } from '@/lib/utils';
import { Input } from 'components/ui/input';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';
import { DragAndDrop } from './DragAndDrop';
import { Button } from 'components/ui/button';
import { useEventCallback } from 'usehooks-ts';
import UploadIcon from '@/assets/icons/ai-scene-icons/upload.svg?react';
import { useAccountCtx } from 'pages/dashboard/profile/helper';

const imageSchema = z.object({
  file: z.instanceof(File).refine((file) => /image\/(jpeg|jpg|png|webp)/.test(file.type), {
    message: 'Invalid file type. Accepted formats are JPG, JPEG, WebP and PNG.',
  }),
});

const MAX_FILE_SIZE_MB_ENTERPRISE = 20;
const MAX_FILE_SIZE_MB_DEFAULT = 10;
const MAX_IMAGE_DIMENSION_DEFAULT = 3000;

interface FileUploadInputProps {
  onFileUpload: (file: File | null, image: string | null) => void;
  titleElement?: ReactNode;
  defaultImage?: string;
  dropAreaContent?: ReactNode;
  containerClass?: string;
  checkExternalValidation?: () => boolean;
}

export const FileUploadInput: FC<FileUploadInputProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onFileUpload,
  titleElement,
  defaultImage,
  dropAreaContent,
  containerClass,
  checkExternalValidation,
  ...dropAreaProps
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(defaultImage || null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { plan: userPlan } = useAccountCtx();

  const MAX_FILE_SIZE_MB =
    userPlan === 'enterprise' ? MAX_FILE_SIZE_MB_ENTERPRISE : MAX_FILE_SIZE_MB_DEFAULT;
  const MAX_IMAGE_DIMENSION = userPlan === 'enterprise' ? null : MAX_IMAGE_DIMENSION_DEFAULT;

  const stableOnFileUpload = useEventCallback(onFileUpload);

  const resetInput = () => {
    if (inputRef.current) inputRef.current.value = '';
  };

  const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          if (width > height) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          } else {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], file.name, { type: file.type }));
          } else {
            reject(new Error('Failed to resize image.'));
          }
        }, file.type);
      };
      img.onerror = () => reject(new Error('Failed to load image.'));
      img.src = URL.createObjectURL(file);
    });
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file || (checkExternalValidation && !checkExternalValidation())) {
      resetInput();
      return;
    }
    validateAndUploadFile(file);
  };

  const validateAndUploadFile = async (file: File) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast(`File size should not exceed ${MAX_FILE_SIZE_MB}MB.`);
      resetInput();
      return;
    }

    const validationResult = imageSchema.safeParse({ file });
    if (!validationResult.success) {
      toast(validationResult.error.errors[0].message);
      resetInput();
      return;
    }

    const img = new Image();
    img.onload = async () => {
      if (
        MAX_IMAGE_DIMENSION &&
        (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION)
      ) {
        if (isMobile) {
          try {
            const resizedFile = await resizeImage(file, MAX_IMAGE_DIMENSION, MAX_IMAGE_DIMENSION);
            processFile(resizedFile);
          } catch (error) {
            toast('Error resizing image.');
            resetInput();
          }
        } else {
          toast(
            `Image dimensions should not exceed ${MAX_IMAGE_DIMENSION}x${MAX_IMAGE_DIMENSION} pixels.`,
          );
          resetInput();
        }
        return;
      }
      processFile(file);
    };
    img.src = URL.createObjectURL(file);
  };

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      setSelectedImage(result);
      stableOnFileUpload(file, result);
      resetInput();
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setSelectedImage(defaultImage || null);
  }, [defaultImage]);

  useEffect(() => {
    return () => {
      stableOnFileUpload(null, null);
    };
  }, [stableOnFileUpload]);

  const inputId = Math.random().toString(36).substring(2);

  return (
    <div className={`relative flex flex-col items-center gap-4 ${containerClass || ''}`}>
      <label
        className='absolute inset-0 z-10 h-full w-full cursor-pointer text-base font-medium'
        htmlFor={inputId}
      />
      {titleElement || <p className='text-base font-medium'>Choose image file</p>}
      <Input
        ref={inputRef}
        id={inputId}
        type='file'
        className='hidden'
        accept='image/*'
        onChange={onFileChange}
      />
      <DragAndDrop
        {...dropAreaProps}
        className={cn('relative z-20 flex h-32 w-72 cursor-pointer', dropAreaProps.className)}
        onFileDrop={(file) => {
          if (checkExternalValidation && !checkExternalValidation()) return;
          validateAndUploadFile(file);
        }}
        onClick={() => inputRef.current?.click()}
      >
        {selectedImage ? (
          <>
            <img
              src={selectedImage}
              alt='Preview'
              className='h-full w-full rounded-lg object-contain'
            />
            <Button
              variant='outline'
              onClick={(e) => {
                e.stopPropagation();
                resetInput();
                setSelectedImage(null);
                stableOnFileUpload(null, null);
              }}
              className='absolute right-1 top-1 z-30 h-8 w-8 transform rounded-full p-0'
            >
              <span className='i-mdi-close size-4 bg-black' />
            </Button>
          </>
        ) : (
          dropAreaContent || (
            <>
              <UploadIcon />
              <div className='flex flex-col items-center gap-2 border border-c-dark p-[6px]'>
                <span className='text-[13px] font-semibold'>
                  Drag image here or click to choose
                </span>
                <span className='text-[11px] font-normal text-gray-500'>
                  PNG, JPG OR JPEG, up to {MAX_FILE_SIZE_MB}MB
                </span>
              </div>
            </>
          )
        )}
      </DragAndDrop>
    </div>
  );
};
