import { FC } from 'react';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { TScene } from 'components/ai-scene/review/queries';

interface SceneAssetCardProps {
  scene: TScene;
  isSelectedByDefault?: boolean;
}

export const SceneAssetCard: FC<SceneAssetCardProps> = ({ scene, isSelectedByDefault }) => {
  const addAsset = useBannerCreateStore((s) => s.addAsset);
  const removeAsset = useBannerCreateStore((s) => s.removeAsset);

  return (
    <div
      onClick={() => {
        if (isSelectedByDefault) {
          removeAsset(scene.id);
        } else {
          addAsset({
            type: 'aiScene',
            id: scene.id,
            url: scene.imageUrl,
            name: scene.name,
          });
        }
      }}
      className={`hover:border-1 flex  cursor-pointer select-none flex-col items-center gap-2.5 rounded-lg p-2 hover:outline hover:outline-crait-blue ${
        isSelectedByDefault ? 'bg-crait-blue opacity-70' : ''
      }`}
    >
      <div className='relative flex h-[160px] w-full items-center gap-2 overflow-hidden rounded-lg'>
        <img crossOrigin='anonymous' src={scene.thumbnailUrl} className='object-cover' />
      </div>
      <p className='line-clamp-1 text-ellipsis'>{scene.name}</p>
    </div>
  );
};
