import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { useState } from 'react';
import fallbackImage from '@/assets/images/broken-image.svg';
import { useUploadImageMutation } from 'pages/workspace/brand-library/product-edit/helper';

interface ImageUrlUploadInputProps {
  onImageUrlSubmit: (url: string) => void;
}

export const ImageUrlUploadInput: React.FC<ImageUrlUploadInputProps> = ({ onImageUrlSubmit }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const uploadImageMutation = useUploadImageMutation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
    setImagePreview(null);
  };

  const validateImageUrl = async (url: string) => {
    try {
      const processedImage = await uploadImageMutation.mutateAsync({ url });
      setImagePreview(processedImage);
      return processedImage;
    } catch (error) {
      setImagePreview(fallbackImage);
      return false;
    }
  };

  const showPreview = async () => {
    const processedImage = await validateImageUrl(imageUrl);
    if (!processedImage) return;

    onImageUrlSubmit(processedImage);
  };

  return (
    <div className='flex flex-col gap-2'>
      <Input
        type='text'
        value={imageUrl}
        onChange={handleInputChange}
        placeholder='Enter image URL'
        className='mb-2 rounded border p-2 text-sm'
      />
      {imagePreview && (
        <img
          crossOrigin='anonymous'
          src={imagePreview}
          alt='Preview'
          className='h-40 w-full border object-contain'
        />
      )}
      {uploadImageMutation.isError && (
        <p className='text-sm text-red-500'>Invalid image URL. Please try again.</p>
      )}
      <Button
        variant='outline'
        className='w-full'
        onClick={showPreview}
        disabled={!imageUrl || uploadImageMutation.isPending}
      >
        Preview
      </Button>
    </div>
  );
};
