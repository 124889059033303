import { FC, ReactNode, useEffect, useRef } from 'react';
import { TCanvasOptions, useFabricCanvas } from '@/hooks/canvas/useFabricCanvas';
import { parseImageWithFabric } from 'components/ai-scene/create/editor/canvas/helper';
import { TAiSceneImage } from '@/providers/ai-scene/create/store';

interface CanvasItemProps {
  children?: ReactNode;
  canvasData: TAiSceneImage;
  options: TCanvasOptions;
  onCanvasRendered: (canvasInstance: fabric.Canvas) => void;
}

export const CanvasItem: FC<CanvasItemProps> = ({ canvasData, options, onCanvasRendered }) => {
  const { fabricCanvasRef, canvasElement } = useFabricCanvas(undefined, options);
  const isMounted = useRef(false);

  useEffect(() => {
    const fabricRef = fabricCanvasRef.current;
    if (!fabricRef || isMounted.current) return;

    isMounted.current = true;
    const drawImage = async () => {
      try {
        const {
          id,
          type,
          image: imageObject,
        } = await parseImageWithFabric({
          id: canvasData.id,
          image: canvasData.image,
          type: canvasData.type,
          maxHeight: fabricRef.getHeight(),
          maxWidth: fabricRef.getWidth(),
          placementBuffer: 0,
        });
        imageObject.latestSavedScaling = {
          scaleX: imageObject.scaleX || 1,
          scaleY: imageObject.scaleY || 1,
        };
        fabricRef.originalDimensions = {
          width: imageObject.width || imageObject.getScaledWidth(),
          height: imageObject.height || imageObject.getScaledHeight(),
        };

        fabricRef.add(imageObject);
        fabricRef.requestRenderAll();
        onCanvasRendered(fabricRef);
      } catch (error) {
        console.error(error);
      }
    };

    drawImage();
  }, [fabricCanvasRef, canvasData, onCanvasRendered]);

  return canvasElement;
};
