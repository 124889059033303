import { fabric } from 'fabric';
import { CUSTOM_OBJECT_PROPERTIES } from './store/constants';
import { format, formatDistanceToNow, fromUnixTime } from 'date-fns';
import {
  MAX_ORIGINAL_CANVAS_SIZE,
  MAX_VISIBLE_CANVAS_SIZE,
} from 'components/ai-scene/create/editor/canvas/helper';

export const getRandomImg = (minW: number, range: number = 10) => {
  const randomNumberInRange = Math.floor(Math.random() * range) + minW;
  return `https://picsum.photos/${randomNumberInRange}`;
};

export const convertImageUrlToFile = async (
  url: string,
  fileName?: string,
): Promise<File | null> => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], fileName || 'imageFile', { type: blob.type });
    return file;
  } catch (e) {
    console.error('Failed to convert image url to file', e);
    return null;
  }
};

export const convertImageFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result);
      };
    } catch (err) {
      reject(err);
    }
  });
};

export const isFabricText = (obj: fabric.Object): obj is fabric.Textbox => {
  return Boolean(obj.get('type')?.includes('text'));
};

export const downloadCanvasAsImage = ({
  canvasState,
  canvasDimensions,
  fileName,
}: {
  canvasState: string;
  canvasDimensions: { width: number; height: number };
  fileName: string;
}) => {
  const tempCanvas = new fabric.Canvas(document.createElement('canvas'), {
    width: canvasDimensions.width,
    height: canvasDimensions.height,
  });

  tempCanvas.loadFromJSON(canvasState, () => {
    const dataURL = tempCanvas.toDataURL({
      format: 'png',
      quality: 1,
    });
    downloadWithAnchor(dataURL, `${fileName}.png`);
    tempCanvas.dispose();
  });
};

export const exportFabricCanvas = (canvas: fabric.Canvas | fabric.StaticCanvas) => {
  canvas.backgroundColor = 'white';
  canvas.renderAll();
  const dataURL = canvas.toDataURL({
    format: 'png',
    quality: 1,
    width: canvas.getWidth(),
    height: canvas.getHeight(),
  });
  downloadWithAnchor(dataURL, 'test-download');
};

export const getCanvasImage = (canvas: fabric.Canvas, format: 'jpeg' | 'png') =>
  canvas.toDataURL({
    format,
    quality: 1,
  });

export const downloadWithAnchor = (url: string, fileName: string) => {
  const cleanedFileName = replaceDotsWithUnderscores(fileName);
  const link = document.createElement('a');
  link.download = cleanedFileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const downloadImageHandlingRedirect = async (url: string, fileName: string) => {
  try {
    // Fetch the image, allowing it to follow redirects
    const response = await fetch(url, { redirect: 'follow' });
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    downloadWithAnchor(blobUrl, fileName);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Failed to download the image:', error);
  }
};

export const mutateObjectToNonSelectable = (obj: fabric.Object) => {
  obj.set({
    selectable: false,
    evented: false,
    lockMovementX: true,
    lockMovementY: true,
  });
};

export const serializeCanvas = (canvas: fabric.Canvas) =>
  JSON.stringify(canvas.toJSON(CUSTOM_OBJECT_PROPERTIES));

export const customTimer = (nameTag: string = ''): (() => number) => {
  const startTime = performance.now();
  console.log(`${nameTag} timer started`);

  const stopTimer = (): number => {
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    console.log(`${nameTag} timer stopped: ${elapsedTime} milliseconds`);
    return elapsedTime;
  };

  return stopTimer;
};

export const removeBlackPixelsFromImage = (image: fabric.Image) => {
  const offScreenCanvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = offScreenCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2D context');
  }

  offScreenCanvas.width = image.width || MAX_VISIBLE_CANVAS_SIZE;
  offScreenCanvas.height = image.height || MAX_VISIBLE_CANVAS_SIZE;
  ctx.drawImage(image.getElement(), 0, 0);
  const imageData: ImageData = ctx.getImageData(
    0,
    0,
    offScreenCanvas.width,
    offScreenCanvas.height,
  );

  // Iterate over each pixel to set black pixels to transparent
  const len = imageData.data.length;
  for (let i = 0; i < len; i += 4) {
    // If the pixel is black (R, G, and B are all 0)
    if (imageData.data[i] === 0 && imageData.data[i + 1] === 0 && imageData.data[i + 2] === 0) {
      // Set alpha to 0 (transparent)
      imageData.data[i + 3] = 0;
    }
  }

  ctx.putImageData(imageData, 0, 0);
  return offScreenCanvas.toDataURL('image/png', 0.1);
};

export const formatEpochTs = (epochTs: number) => {
  return format(fromUnixTime(epochTs), 'yyyy-MM-dd HH:mm');
};

export const getRelativeDate = (epochTs: number) => {
  const date = new Date(epochTs * 1000);
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });
  const timeWithoutAbout = timeAgo.replace('about ', '');
  return timeWithoutAbout;
};

export const dataURLToBlob = (dataURL: string): Blob => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const downloadFabricImageObject = (imageObject: fabric.Image, fileName: string) => {
  const dataURL = imageObject.toDataURL({
    format: 'png',
    quality: 1,
  });
  downloadWithAnchor(dataURL, `${fileName}.png`);
};

export const scaleAndConvertFabricImageObjectToFile = (
  imageObject: fabric.Image,
  fileName: string,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = imageObject.width as number;
    tempCanvas.height = imageObject.height as number;
    const ctx = tempCanvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Failed to get 2D context'));
      return;
    }

    imageObject.clone((clonedObj: fabric.Image) => {
      clonedObj.set({ left: 0, top: 0 }).setCoords();
      clonedObj.scale(1);
      const tempFabricCanvas = new fabric.Canvas(tempCanvas, { enableRetinaScaling: false });
      tempFabricCanvas.add(clonedObj);
      tempFabricCanvas.renderAll();

      tempCanvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName, {
            type: 'image/png',
          });
          resolve(file);
        } else {
          reject(new Error('Blob conversion failed'));
        }
      }, 'image/png');
    });
  });
};

export const convertFabricCanvasToFile = ({
  canvas,
  fileName,
  extension,
  quality = 1,
}: {
  canvas: fabric.Canvas | fabric.StaticCanvas;
  fileName: string;
  extension: string;
  quality?: number;
}): File => {
  const url = canvas.toDataURL({
    quality,
    format: extension,
  });
  const canvasBlob = dataURLToBlob(url);
  const canvasFile = new File([canvasBlob], `${fileName}.${extension}`, {
    type: `image/${extension}`,
  });
  return canvasFile;
};

export const appendPluralSuffix = (arr: any[] | undefined, suffix = 's') => {
  if (!arr || arr.length < 2) return '';
  return suffix;
};

export const appendPluralSuffixByNumber = (num: number, suffix = 's') => {
  if (num > 1) return suffix;
  return '';
};

export const iife = <T>(fn: () => T): T => fn();

export const triggerBlurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    e.currentTarget.blur();
  }
};

export const hexToHexa = (hex: string, alpha: number): string => {
  let normalizedHex = hex.startsWith('#') ? hex.slice(1) : hex;

  // Handle shorthand notation and incomplete hex codes
  if (normalizedHex.length === 3) {
    normalizedHex = normalizedHex
      .split('')
      .map((char) => char + char)
      .join('');
  } else if (normalizedHex.length > 0 && normalizedHex.length < 6) {
    // Append '0' to make it 6 characters if it's in between 1 and 5
    normalizedHex = normalizedHex.padEnd(6, '0');
  } else if (normalizedHex.length > 6) {
    // If input exceeds 6 characters, truncate to the first 6 characters
    normalizedHex = normalizedHex.substring(0, 6);
  }

  // Ensure alpha is between 0 and 1
  const clampedAlpha = Math.max(0, Math.min(1, alpha));
  // Convert alpha to a 2-digit hexadecimal value
  const alphaHex = Math.round(clampedAlpha * 255)
    .toString(16)
    .padStart(2, '0');
  return `#${normalizedHex}${alphaHex}`.toUpperCase();
};

export const getPreviousPath = (pathname: string) => pathname.split('/').slice(0, -1).join('/');

export const filterPromiseAllSettled = (requests: PromiseSettledResult<any>[]) => {
  const failedRequests = requests.filter(
    (result) => result.status === 'rejected',
  ) as PromiseRejectedResult[];
  const successfulRequests = requests.filter(
    (result) => result.status === 'fulfilled',
  ) as PromiseFulfilledResult<unknown>[];

  const successfulResults = successfulRequests.map((result) => result.value);
  const failedRequestsReasons = failedRequests.map((result) => result.reason);
  const [hasFailedRequests, hasSuccessfulRequests] = [
    failedRequestsReasons.length > 0,
    successfulResults.length > 0,
  ];

  return {
    failedRequestsReasons,
    successfulResults,
    hasFailedRequests,
    hasSuccessfulRequests,
  } as const;
};

export const filterFulfilled = <T>(results: PromiseSettledResult<T>[]): T[] => {
  return results
    .filter((result): result is PromiseFulfilledResult<T> => result.status === 'fulfilled')
    .map((result) => result.value);
};

export const filterFailed = <T>(results: PromiseSettledResult<T>[]): unknown[] => {
  return results
    .filter((result): result is PromiseRejectedResult => result.status === 'rejected')
    .map((result) => result.reason);
};

export const getFabricObjectsAsFile = async ({
  canvasInstance,
  objects,
  fileName,
}: {
  canvasInstance: fabric.Canvas | fabric.StaticCanvas;
  objects: fabric.Object[];
  fileName?: string;
}): Promise<File> => {
  const canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: canvasInstance.getWidth(),
    height: canvasInstance.getHeight(),
  });
  const addObjectsPromises = objects.map(
    (obj) =>
      new Promise<void>((resolve) => {
        obj.clone((cloned: fabric.Object) => {
          canvas.add(cloned);
          resolve();
        });
      }),
  );

  await Promise.all(addObjectsPromises);
  canvas.renderAll();
  const canvasFile = convertFabricCanvasToFile({
    canvas,
    fileName: fileName || 'imageFile',
    extension: 'png',
  });
  return canvasFile;
};

export const cloneAndScaleCanvasWithObjects = async (
  existingCanvas: fabric.Canvas | fabric.StaticCanvas,
) => {
  const originalCanvasWidth = existingCanvas.originalDimensions?.width || MAX_ORIGINAL_CANVAS_SIZE;
  const originalCanvasHeight =
    existingCanvas.originalDimensions?.height || MAX_ORIGINAL_CANVAS_SIZE;
  const visibleCanvasWidth = existingCanvas.getWidth() || MAX_VISIBLE_CANVAS_SIZE;
  const visibleCanvasHeight = existingCanvas.getHeight() || MAX_VISIBLE_CANVAS_SIZE;
  const clonedCanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: originalCanvasWidth,
    height: originalCanvasHeight,
  });
  clonedCanvas.renderOnAddRemove = false;
  const addObjectsPromises = existingCanvas.getObjects().map(
    (obj, index) =>
      new Promise<void>((resolve) => {
        obj.clone((clonedObj: fabric.Object) => {
          scaleObjectToOriginalCanvas({
            object: clonedObj,
            originalCanvasWidth,
            originalCanvasHeight,
            visibleCanvasWidth,
            visibleCanvasHeight,
          });
          clonedCanvas.insertAt(clonedObj, index, false);
          resolve();
        });
      }),
  );

  await Promise.all(addObjectsPromises);
  clonedCanvas.renderAll();
  return clonedCanvas;
};

export const scaleObjectToOriginalCanvas = ({
  object,
  originalCanvasWidth,
  originalCanvasHeight,
  visibleCanvasWidth,
  visibleCanvasHeight,
}: {
  object: fabric.Object;
  originalCanvasWidth: number;
  originalCanvasHeight: number;
  visibleCanvasWidth: number;
  visibleCanvasHeight: number;
}) => {
  const actualScaleX = (originalCanvasWidth / visibleCanvasWidth) * (object.scaleX || 1);
  const actualScaleY = (originalCanvasHeight / visibleCanvasHeight) * (object.scaleY || 1);
  const adjustedLeft = (object.left || 0) * (originalCanvasWidth / visibleCanvasWidth);
  const adjustedTop = (object.top || 0) * (originalCanvasHeight / visibleCanvasHeight);
  object
    .set({
      scaleX: actualScaleX,
      scaleY: actualScaleY,
      left: adjustedLeft,
      top: adjustedTop,
    })
    .setCoords();
};

export const getConvertedImageUrl = async (imageUrl: string, outputFormat: 'jpeg' | 'png') => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.crossOrigin = 'Anonymous';

  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          resolve(url);
        } else {
          reject('Conversion failed');
        }
      }, `image/${outputFormat}`);
    };
    img.onerror = (error) => {
      reject('Image loading failed: ' + error);
    };
    img.src = imageUrl;
  });
};

export const loadCanvasFromJson = (canvas: fabric.StaticCanvas, json: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    canvas.loadFromJSON(json, (error: any) => {
      if (error) {
        reject(error);
      }

      resolve();
    });
  });
};

export const extractPathAndQueryFromURL = (urlString: string): string => {
  const url = new URL(urlString);
  const pathAndQuery: string = url.pathname + url.search;
  return pathAndQuery;
};

export const timestampToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = format(date, 'dd.MM.yyyy');
  return formattedDate;
};

export const replaceDotsWithUnderscores = (fileName: string): string => {
  return fileName.replace(/\./g, '_');
};

export function getFileNameWithoutExtension(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fileName;
  }
  return fileName.substring(0, lastDotIndex);
}
