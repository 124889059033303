import { cn } from '@/lib/utils';
import { Button } from 'components/ui/button';
import { FC, ReactNode } from 'react';

const DISABLED_TAB_CONTENT_CL = 'cursor-not-allowed bg-gray-100';

type TCsvUploadViewProps = {
  children: ReactNode;
};

export const CsvUploadView: FC<TCsvUploadViewProps> = ({ children }) => {
  return (
    <div className='relative mt-1 flex flex-col items-center gap-4'>
      <Button variant='link' className='text-xs'>
        Download CSV Format
      </Button>
      <p className='text-base font-medium'>Choose or drop CSV files</p>
      <div
        className={cn(
          'flex flex h-32 w-72 flex-col items-center justify-center gap-1 rounded-lg border-2 border-dashed bg-gray-100',
          DISABLED_TAB_CONTENT_CL,
        )}
      >
        <span className='i-mdi-download size-5' />
      </div>
      <div className='flex w-72 flex-col gap-3'>
        <Button className='w-full' disabled>
          Upload
        </Button>
        {children}
      </div>
    </div>
  );
};
