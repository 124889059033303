import React, { useEffect } from 'react';
import { AiSceneSideMenus, useMenuStore } from './store';
import { CreateToolContainer } from './create-tool/CreateToolContainer';
import { TextTool } from './text-tool/TextTool';
import { FiltersTool } from './filters-tool/FiltersTool';
import { AddLogoTool } from './add-logo-tool/AddLogoTool';

export const AiSceneSidemenu: React.FC = () => {
  const { activeMenu, setActiveMenu } = useMenuStore((state) => ({
    activeMenu: state.activeMenu,
    setActiveMenu: state.setActiveMenu,
  }));

  useEffect(() => {
    if (activeMenu === AiSceneSideMenus.EDIT_TOOL) {
      setActiveMenu(AiSceneSideMenus.CREATE_TOOL);
    }
  }, [activeMenu, setActiveMenu]);

  return (
    <div className='h-full bg-c-ground p-4'>
      {activeMenu === AiSceneSideMenus.CREATE_TOOL && <CreateToolContainer />}
      {activeMenu === AiSceneSideMenus.TEXT_TOOL && <TextTool />}
      {activeMenu === AiSceneSideMenus.FILTERS && <FiltersTool />}
      {activeMenu === AiSceneSideMenus.ADD_LOGO && <AddLogoTool />}
    </div>
  );
};
