import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useEffect, useMemo, useState } from 'react';

export const useSyncSelectedProducts = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const [drawnProductIds, setDrawnProductIds] = useState<string[]>([]);

  useEffect(() => {
    if (!canvasInstance) return;

    const addImageId = (e: fabric.IEvent) => {
      const drawnProductImageId = e.target?.imageType === 'product' && e.target.imageId;
      if (!drawnProductImageId) return;

      setDrawnProductIds((prev) => [...prev, drawnProductImageId]);
    };

    const removeImageId = (e: fabric.IEvent) => {
      const removedObject = e.target;
      if (removedObject?.imageType === 'product' && removedObject.imageId) {
        setDrawnProductIds((prev) => {
          // only remove one instance of the product
          const indexToRemove = prev.indexOf(removedObject.imageId as string);
          if (indexToRemove === -1) return prev;

          return [...prev.slice(0, indexToRemove), ...prev.slice(indexToRemove + 1)];
        });
      } else if (removedObject?.type === 'group') {
        const filteredIds = (removedObject as fabric.Group)._objects
          ?.filter((obj) => obj.imageType === 'product' && obj.imageId)
          .map((obj) => obj.imageId as string);
        if (filteredIds?.length) {
          setDrawnProductIds((prev) => {
            const idsToFilter = [...filteredIds];
            return prev.filter((id) => {
              const matchedIdx = idsToFilter.indexOf(id);
              // This instance of id is kept
              if (matchedIdx === -1) return true;
              // Remove the id from idsToFilter to ensure it's considered only once
              idsToFilter.splice(matchedIdx, 1);
              return false; // This instance of id is removed
            });
          });
        }
      }
    };

    canvasInstance.on('object:removed', removeImageId);
    canvasInstance.on('object:added', addImageId);

    return () => {
      canvasInstance.off('object:added', removeImageId);
      canvasInstance.off('object:removed', addImageId);
    };
  }, [canvasInstance]);

  return useMemo(() => drawnProductIds, [drawnProductIds]);
};
