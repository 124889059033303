import { ImageType, TImageType } from '@/utils/store/constants';
import { IObjectOptions } from 'fabric/fabric-impl';

export const MAIN_CANVAS_SIZE = 400;
export const CHILD_CANVAS_SIZE = 150;

export const getScaledOptionsForChildCanvas = (options: IObjectOptions) => {
  const scalePortion = CHILD_CANVAS_SIZE / MAIN_CANVAS_SIZE;
  const adjustedOptions: IObjectOptions = {
    left: (options.left || 0) * scalePortion,
    top: (options.top || 0) * scalePortion,
    scaleX: (options.scaleX || 1) * scalePortion,
    scaleY: (options.scaleY || 1) * scalePortion,
    angle: options.angle,
  };
  return adjustedOptions;
};

export const getReferenceScaleDiff = (referenceImageOptions: {
  latestSavedScaling: { scaleX: number; scaleY: number };
  scaleX: number;
  scaleY: number;
}) => {
  const referenceScaleDiffX =
    referenceImageOptions.scaleX / referenceImageOptions.latestSavedScaling.scaleX;
  const referenceScaleDiffY =
    referenceImageOptions.scaleY / referenceImageOptions.latestSavedScaling.scaleY;
  return { referenceScaleDiffX, referenceScaleDiffY };
};

export type TAdjustScaleOptions = {
  latestSavedScaling: { scaleX: number; scaleY: number };
  left: number;
  top: number;
  scaleX: number;
  scaleY: number;
  angle: number;
};

export const getChildCanvasImageOptions = ({
  childImageOptions,
  referenceImageOptions,
}: {
  referenceImageOptions: TAdjustScaleOptions;
  childImageOptions: Pick<TAdjustScaleOptions, 'scaleX' | 'scaleY'>;
}) => {
  const { referenceScaleDiffX, referenceScaleDiffY } = getReferenceScaleDiff(referenceImageOptions);
  const relativeScaleRatio = CHILD_CANVAS_SIZE / MAIN_CANVAS_SIZE;
  return {
    left: referenceImageOptions.left * relativeScaleRatio,
    top: referenceImageOptions.top * relativeScaleRatio,
    scaleX: referenceScaleDiffX * childImageOptions.scaleX,
    scaleY: referenceScaleDiffY * childImageOptions.scaleY,
    angle: referenceImageOptions.angle,
  };
};

export const synchronizeObjectsAcrossCanvases = (
  canvases: fabric.Canvas[],
  referenceImageOptions: TAdjustScaleOptions,
) => {
  Object.values(canvases).forEach((targetCanvas) => {
    const targetObjects = targetCanvas
      .getObjects()
      .filter((obj) => obj.imageType === ImageType.PRODUCT);
    if (targetObjects.length < 1) return;

    targetObjects.forEach((targetObject) => {
      const adjustedOptions = getChildCanvasImageOptions({
        referenceImageOptions,
        childImageOptions: {
          scaleX: targetObject.scaleX || 1,
          scaleY: targetObject.scaleY || 1,
        },
      });
      targetObject.set(adjustedOptions).setCoords();
    });
    targetCanvas.requestRenderAll();
  });
};

export const waitForImageToRenderOnCanvas = (
  canvas: fabric.Canvas,
  imageType: TImageType,
): Promise<fabric.Object> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const object = canvas.getObjects().find((obj) => obj.imageType === imageType);
      if (object) {
        clearInterval(interval);
        resolve(object);
      }
    }, 500);
  });
};
