import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { useGetLogos } from 'components/workspace/brand-library/brand-kit/queries';
import { FC, useMemo } from 'react';

interface LogoContentProps {
  drawLogo: (image: string, id: string) => void;
}

export const LogoContent: FC<LogoContentProps> = ({ drawLogo }) => {
  const { data, isError, isLoading, refetch } = useGetLogos({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load logos.</span>
    </RefetchView>
  );

  const logoItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-2 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No logos found.</span>
        </RefetchView>
      );
    }

    return (
      <div className='flex gap-2'>
        {data?.items.map((logo) => (
          <div
            key={logo.id}
            onClick={() => {
              drawLogo(logo.image_url, logo.id);
            }}
            className='flex cursor-pointer flex-col items-center gap-1.5'
          >
            <div className='h-[80px] w-[80px] overflow-hidden rounded-lg hover:border-2 hover:border-black'>
              <img
                crossOrigin='anonymous'
                src={logo.thumbnail}
                alt='adjust option'
                className='h-full w-full object-cover'
              />
            </div>
            <p className='line-clamp-2 text-ellipsis font-bold'>{logo.name}</p>
          </div>
        ))}
      </div>
    );
  }, [data, isLoading, refetch, drawLogo]);

  return (
    <ScrollArea className='w-full pb-4'>
      {errorView || logoItems}
      <ScrollBar orientation='horizontal' />
    </ScrollArea>
  );
};
