import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { fabric } from 'fabric';
import { toast } from 'sonner';

import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Slider } from 'components/ui/slider';
import { CraitButton } from 'components/common/CraitButton';
import { TooltipItem } from 'components/common/TooltipItem';
import MarkAreaIcon from '@/assets/icons/ai-scene-icons/addBrush.svg?react';
import RemoveMarkIcon from '@/assets/icons/ai-scene-icons/removeBrush.svg?react';
import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { HowItWorksBox } from 'components/ai-scene/side-menu/create-tool/visual-guidance/HowItWorksBox';
import { MagicEraseProSwitch } from './MagicEraseProSwitch';

import {
  DrawingCursorSize,
  applyCustomCursor,
  generateDrawingCursorUrl,
} from 'components/ai-scene/review-edit/actions/helper';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import {
  removeOtherPaths,
  removeTargetedPaths,
} from 'components/ai-scene/review-edit/canvas/helper';
import {
  cancelDrawingMode,
  useEscToCancelDrawingMode,
} from '@/hooks/canvas/useEscToCancelDrawingMode';
import { useMagicEraseMutation } from 'pages/workspace/brand-library/product-edit/helper';
import {
  cloneAndScaleCanvasWithObjects,
  convertFabricCanvasToFile,
  mutateObjectToNonSelectable,
  serializeCanvas,
} from '@/utils/helper';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white w-[142px] !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

export const MagicEraseMenu = ({ onBackClick }: { onBackClick: () => void }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isProEraser, setIsProEraser] = useState(false);

  const brushSize = useRef<number>(DrawingCursorSize.DEFAULT);

  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);

  useEscToCancelDrawingMode(canvasInstance);

  const { mutateAsync: magicErase } = useMagicEraseMutation();
  const { refetchScenes } = useRefetchGeneratedScenes();

  const { projectId, sceneId } = useParams();

  const cancelMagicEraseProcess = () => {
    if (!canvasInstance) return;
    cancelDrawingMode(canvasInstance);
    removeTargetedPaths({
      canvasInstance,
      drawingType: 'freeSelect',
      shouldRenderAfterwards: true,
    });
    setIsButtonDisabled(true);
  };

  const startMagicEraseProcess = async () => {
    try {
      if (!canvasInstance) return;
      invariant(projectId, 'Project id not found.');
      invariant(sceneId, 'Scene id not found.');

      changeProcessingState('erasing');
      const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvasInstance);
      const offscreenCanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
        width: scaledCanvas.getWidth(),
        height: scaledCanvas.getHeight(),
        renderOnAddRemove: false,
      });
      const scaledDrawnPaths = scaledCanvas
        .getObjects('path')
        .filter((path) => (path as any).drawingType === 'freeSelect');
      const clonedPathPromises = scaledDrawnPaths.map(
        (path) =>
          new Promise((resolve) => {
            path.clone((clonedPath: typeof path) => {
              clonedPath.set({
                fill: undefined,
                stroke: 'white',
              });
              offscreenCanvas.add(clonedPath);
              resolve(true);
            });
          }),
      );
      const setBgColorPromise = new Promise((resolve) => {
        offscreenCanvas.setBackgroundColor('black', () => {
          offscreenCanvas.renderAll();
          resolve(true);
        });
      });
      await Promise.all([...clonedPathPromises, setBgColorPromise]);
      const pathBlackWhiteMaskFile = convertFabricCanvasToFile({
        canvas: offscreenCanvas,
        fileName: 'path-mask',
        extension: 'jpeg',
      });
      await magicErase(
        {
          sceneId,
          projectId,
          mask: pathBlackWhiteMaskFile,
          isProEraser: isProEraser,
        },
        {
          onSuccess(data) {
            refetchScenes();
            toast.success('Magic erase applied successfully.');
          },
        },
      );
    } catch {
      toast.error('Failed to apply magic erase. Please try again.');
    } finally {
      changeProcessingState('idle');
      cancelMagicEraseProcess();
      enableMagicErase();
    }
  };

  useEffect(() => {
    if (!canvasInstance) return;

    const handlePathCreated = (event: fabric.IEvent) => {
      if (!('path' in event) || !event.path) return;

      const currentDrawingType = canvasInstance.currentDrawingType;
      if (currentDrawingType !== 'freeSelect') return;

      (event.path as any).drawingType = currentDrawingType;
      mutateObjectToNonSelectable(event.path as fabric.Object);
      setIsButtonDisabled(false);
    };

    const handlePathRemoved = (event: fabric.IEvent) => {
      if (event.target && event.target.type === 'path') {
        updateCanvasState(serializeCanvas(canvasInstance));
      }
    };

    canvasInstance.on('path:created', handlePathCreated);
    canvasInstance.on('object:removed', handlePathRemoved);
    return () => {
      canvasInstance.off('path:created', handlePathCreated);
      canvasInstance.off('object:removed', handlePathRemoved);
    };
  }, [canvasInstance, updateCanvasState]);

  const enableMagicErase = () => {
    try {
      if (!canvasInstance) return;

      canvasInstance.isDrawingMode = true;
      canvasInstance.currentDrawingType = 'freeSelect';
      removeOtherPaths({ canvasInstance, drawingType: 'freeSelect', shouldRenderAfterwards: true });

      canvasInstance.freeDrawingBrush.color = 'rgba(169, 246, 173, 0.7)';
      canvasInstance.freeDrawingBrush.width = brushSize.current;
      const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
      applyCustomCursor(canvasInstance, cursorUrl);
    } catch (error) {
      console.error('Error while enabling free select', error);
      toast.error('Error while enabling free select');
    }
  };

  useEffect(() => {
    enableMagicErase();
  }, [canvasInstance]);

  return (
    <div className='flex h-full w-[320px] flex-col gap-5 rounded-lg bg-white p-3 shadow-md'>
      <button
        onClick={() => {
          onBackClick();
          cancelMagicEraseProcess();
        }}
        className='flex h-9 gap-2 border-b border-[#EAEAEA] text-[15px] font-semibold'
      >
        <ArrowLeftIcon /> Magic Erase
      </button>
      <div className='flex flex-col gap-7'>
        <div className='flex flex-col gap-2'>
          <HowItWorksBox
            text='Clears and fills the selected part of the image with AI.'
            linkText="Don't show this again"
            parentComponent='MagicErase'
          />
          <h3 className='text-main font-semibold'>Select Tool</h3>
          <Tabs defaultValue='mark-area' className='w-[400px]'>
            <TabsList className='roundet-[6px] border border-[#F0F0F0] bg-white'>
              <TabsTrigger
                className={TAB_TRIGGER_CLASS}
                value='mark-area'
                onClick={enableMagicErase}
              >
                <MarkAreaIcon />
              </TabsTrigger>
              <TooltipItem
                side='bottom'
                trigger={
                  <TabsTrigger disabled={true} className={TAB_TRIGGER_CLASS} value='remove-mark'>
                    <RemoveMarkIcon />
                  </TabsTrigger>
                }
              >
                Coming Soon
              </TooltipItem>
            </TabsList>
          </Tabs>
        </div>

        <div className='flex cursor-pointer items-center justify-between'>
          <h3 className=' whitespace-nowrap text-main font-semibold'>Brush Size</h3>
          <Slider
            className='w-[50%] bg-crait-green-primary'
            step={5}
            min={DrawingCursorSize.MIN}
            max={DrawingCursorSize.MAX}
            defaultValue={[brushSize.current]}
            onValueChange={(values) => {
              if (!canvasInstance) return;

              brushSize.current = values[0];
              canvasInstance.freeDrawingBrush.width = brushSize.current;
              const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
              applyCustomCursor(canvasInstance, cursorUrl);
            }}
          />
        </div>

        <MagicEraseProSwitch isProEraser={isProEraser} setIsProEraser={setIsProEraser} />
        <CraitButton onClick={startMagicEraseProcess} disabled={isButtonDisabled}>
          Erase
        </CraitButton>
      </div>
    </div>
  );
};
