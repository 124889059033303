type TRemovePathPayload = {
  canvasInstance: fabric.Canvas;
  drawingType: fabric.Canvas['currentDrawingType'];
  shouldRenderAfterwards?: boolean;
};

export const removeOtherPaths = ({
  canvasInstance,
  drawingType,
  shouldRenderAfterwards,
}: TRemovePathPayload) => {
  const objects = canvasInstance.getObjects();
  const len = objects.length;
  for (let i = len - 1; i >= 0; i--) {
    const object = objects[i];
    if (object.type === 'path' && (object as any).drawingType !== drawingType) {
      canvasInstance.remove(object);
    }
  }
  if (shouldRenderAfterwards) {
    canvasInstance.requestRenderAll();
  }
};

export const removeTargetedPaths = ({
  canvasInstance,
  drawingType,
  shouldRenderAfterwards,
}: TRemovePathPayload) => {
  const objects = canvasInstance.getObjects();
  const len = objects.length;
  let hasPaths = false;

  for (let i = len - 1; i >= 0; i--) {
    const object = objects[i];
    if (object.type === 'path' && (object as any).drawingType === drawingType) {
      canvasInstance.remove(object);
      hasPaths = true;
    }
  }

  if (shouldRenderAfterwards && hasPaths) {
    canvasInstance.requestRenderAll();
  }
};
