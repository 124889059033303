import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import {
  AiSceneEditEndpoint,
  ImageProcessEndpoint,
  ProductEndpoint,
  UploadProcessEndpoint,
} from '@/utils/fetch/constants';
import {
  getAiSceneEditApiUrl,
  getImageProcessApiUrl,
  getProductsApiUrl,
  getUploadProcessApiUrl,
} from '@/utils/fetch/helper';
import {
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { TScene } from 'components/ai-scene/review/queries';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

export type TMagicEraseResult = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'magic_erase';
  sceneId: string;
  status: TScene['status'];
};

type TMagicErasePayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  mask: File;
  isProEraser?: boolean;
};

const applyMagicErase = async ({
  sceneId,
  projectId,
  organizationId,
  mask,
  isProEraser,
}: TMagicErasePayload) => {
  const subpath = isProEraser
    ? AiSceneEditEndpoint.MAGIC_ERASE_PRO
    : AiSceneEditEndpoint.MAGIC_ERASE_V2;

  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: subpath,
  });
  const formData = new FormData();
  formData.append('mask', mask);
  const response = await apiClient.post<{ result: TMagicEraseResult }>(url, formData);
  return response.data.result;
};

export const useMagicEraseMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneEditEndpoint.MAGIC_ERASE_V2, organizationId],
    mutationFn: (props: Omit<TMagicErasePayload, 'organizationId'>) => {
      return applyMagicErase({
        ...props,
        organizationId,
      });
    },
  });
};

type TGetEditProcessPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  versionId: string;
};

const getEditProcessResult = async ({
  sceneId,
  projectId,
  organizationId,
  versionId,
}: TGetEditProcessPayload) => {
  const baseUrl = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint._RAW,
  });
  const url = `${baseUrl}${versionId}`;
  const response = await apiClient.get<TMagicEraseResult>(url);
  return response.data;
};

export const useLazyEditProcessQuery = () => {
  const queryClient = useQueryClient();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const getEditProcessByVersionId = useCallback(
    (props: Omit<TGetEditProcessPayload, 'organizationId'>) => {
      return queryClient.fetchQuery({
        queryKey: [{ ...props, organizationId, event: 'getSceneProcessStatus' }],
        queryFn: () => {
          return getEditProcessResult({
            ...props,
            organizationId,
          });
        },
        staleTime: 0,
      });
    },
    [organizationId, queryClient],
  );

  return useMemo(() => ({ getEditProcessByVersionId }), [getEditProcessByVersionId]);
};

export const useGetEditProcessQuery = (versionId: string) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId, sceneId } = useParams();

  return useQuery({
    queryKey: [{ versionId, organizationId, event: 'getSceneProcessStatus', sceneId, projectId }],
    queryFn: ({ queryKey }) => {
      invariant(queryKey[0].projectId, 'projectId must be provided');
      invariant(queryKey[0].sceneId, 'sceneId must be provided');

      return getEditProcessResult({
        organizationId,
        versionId: queryKey[0].versionId,
        sceneId: queryKey[0].sceneId,
        projectId: queryKey[0].projectId,
      });
    },
    placeholderData: (prev) => prev,
    staleTime: 0,
    refetchInterval: (data) => {
      const loadingStatus = data?.state?.data?.status;
      return loadingStatus === 'in_progress' || loadingStatus === 'pending' ? 1000 : false;
    },
    refetchIntervalInBackground: true,
  });
};

export const useGetEditProcessQueries = (
  versionIds: string[],
  enabled: boolean,
): UseQueryResult<TAiSketchingResult>[] => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId, sceneId } = useParams();

  const queries = useQueries<TAiSketchingResult[]>({
    queries: versionIds.map((versionId) => ({
      queryKey: [{ versionId, organizationId, event: 'getSceneProcessStatus', sceneId, projectId }],
      queryFn: () => {
        invariant(projectId, 'projectId must be provided');
        invariant(sceneId, 'sceneId must be provided');

        return getEditProcessResult({
          versionId,
          organizationId,
          sceneId,
          projectId,
        });
      },
      staleTime: 0,
      gcTime: 0,
      refetchInterval: (data: any) => {
        const loadingStatus = data?.state?.data?.status;
        return loadingStatus === 'in_progress' || loadingStatus === 'pending' ? 1000 : false;
      },
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled,
    })),
  });

  return queries as UseQueryResult<TAiSketchingResult>[];
};

export const useGetEditProcessStatusMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: AiSceneEditEndpoint._RAW, organizationId, event: 'getEditProcessStatus' }],
    mutationFn: (props: Omit<TGetEditProcessPayload, 'organizationId'>) => {
      return getEditProcessResult({
        ...props,
        organizationId,
      });
    },
    gcTime: 0,
  });
};

export type TAiSketchingResult = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'ai_sketching';
  sceneId: string;
  status: string;
};

type TAiSketcingPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  mask: File;
  prompt: string;
  count: number;
};

const applyAiSketching = async ({
  sceneId,
  projectId,
  organizationId,
  mask,
  prompt,
  count,
}: TAiSketcingPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.MAGIC_EDIT,
  });
  const formData = new FormData();
  formData.append('mask', mask);
  formData.append('prompt', prompt);
  formData.append('count', count.toString());
  const response = await apiClient.post<{ results: TAiSketchingResult[] }>(url, formData);
  return response.data.results;
};

export const getSketchingMutationKey = (organizationId: number) => {
  return [{ url: AiSceneEditEndpoint.AI_SKETCH, organizationId }];
};

export const useAiSketchMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: getSketchingMutationKey(organizationId),
    mutationFn: (props: Omit<TAiSketcingPayload, 'organizationId'>) => {
      return applyAiSketching({
        ...props,
        organizationId,
      });
    },
    gcTime: 0,
  });
};

export type TPartialDetailResult = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'partial_detail_restoration';
  sceneId: string;
  status: string;
  identityStrength: number;
  isApproved: boolean;
};

type TPartialDetailPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  mask: File;
};

const applyPartialDetail = async ({
  sceneId,
  projectId,
  organizationId,
  mask,
}: TPartialDetailPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.PARTIAL_DETAIL,
  });
  const formData = new FormData();
  formData.append('mask', mask);
  const response = await apiClient.post<{ results: TPartialDetailResult[] }>(url, formData);
  return response.data.results;
};

export const getPartialDetailMutationKey = (organizationId: number) => {
  return [{ url: AiSceneEditEndpoint.PARTIAL_DETAIL, organizationId }];
};

export const usePartialDetailMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: getPartialDetailMutationKey(organizationId),
    mutationFn: (props: Omit<TPartialDetailPayload, 'organizationId'>) => {
      return applyPartialDetail({
        ...props,
        organizationId,
      });
    },
    gcTime: 0,
  });
};

type CanvasTransformation = {
  left: number | undefined;
  top: number | undefined;
  width: number | undefined;
  height: number | undefined;
  scale_x: number | undefined;
  scale_y: number | undefined;
};

type TApplyOutpaintPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  mask: File;
  transformation: CanvasTransformation;
};

const applyOutpaint = async ({
  sceneId,
  projectId,
  organizationId,
  mask,
  transformation,
}: TApplyOutpaintPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.OUTPAINT,
  });
  const formData = new FormData();
  formData.append('mask', mask);
  formData.append('transformation', JSON.stringify(transformation));

  const response = await apiClient.post<{ result: TScene }>(url, formData);
  return response.data.result;
};

export const useOutpaintMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneEditEndpoint.OUTPAINT, organizationId],
    mutationFn: (props: Omit<TApplyOutpaintPayload, 'organizationId'>) => {
      return applyOutpaint({
        ...props,
        organizationId,
      });
    },
  });
};

type TEditUserPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  image: File;
};

const editUser = async ({ sceneId, projectId, organizationId, image }: TEditUserPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.USER_EDIT,
  });
  const formData = new FormData();
  formData.append('image', image);
  const response = await apiClient.post<{ result: TScene }>(url, formData);
  return response.data.result;
};

export const getUserEditMutationKey = (organizationId: number) => [
  { url: AiSceneEditEndpoint.USER_EDIT, organizationId },
];

export const useEditUserMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { sceneId, projectId } = useParams();

  return useMutation({
    mutationKey: getUserEditMutationKey(organizationId),
    mutationFn: (image: File) => {
      invariant(sceneId, 'sceneId must be provided');
      invariant(projectId, 'projectId must be provided');

      return editUser({
        sceneId,
        projectId,
        organizationId,
        image,
      });
    },
  });
};

type TSaveScenePayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  versionId: string;
};

const applySaveScene = async ({
  sceneId,
  projectId,
  organizationId,
  versionId,
}: TSaveScenePayload) => {
  const baseUrl = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.SAVE,
  });
  const url = `${baseUrl}/${versionId}`;
  return apiClient.post<string>(url);
};

export const useSaveSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneEditEndpoint.SAVE, organizationId],
    mutationFn: (props: Omit<TSaveScenePayload, 'organizationId'>) => {
      return applySaveScene({
        ...props,
        organizationId,
      });
    },
  });
};

type TRemoveBackgroundPayload = {
  action: 'remove_background';
  imageUrl: string;
  previousMask?: File;
  editMask?: File;
  organizationId: number;
};

type TRemoveBackgroundResponse = {
  jobId: string;
  status: TScene['status'];
  maskUrl: string;
};

const removeBackground = async ({
  action,
  imageUrl,
  previousMask,
  editMask,
  organizationId,
}: TRemoveBackgroundPayload) => {
  const url = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.REMOVE_BG);
  const formData = new FormData();
  formData.append('action', action);
  formData.append('imageUrl', imageUrl);
  if (previousMask) formData.append('previousMask', previousMask);
  if (editMask) formData.append('editMask', editMask);
  const response = await apiClient.post<TRemoveBackgroundResponse>(url, formData);
  return response.data;
};

export const useRemoveBgMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ImageProcessEndpoint.REMOVE_BG, organizationId],
    mutationFn: (props: Omit<TRemoveBackgroundPayload, 'organizationId'>) => {
      return removeBackground({
        ...props,
        organizationId,
      });
    },
  });
};

type TGetRemoveBgStatusPayload = {
  jobId: string;
  organizationId: number;
};

const getRemoveBgStatus = async ({ jobId, organizationId }: TGetRemoveBgStatusPayload) => {
  const baseUrl = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.REMOVE_BG);
  const url = `${baseUrl}/${jobId}`;
  const response = await apiClient.get<TRemoveBackgroundResponse>(url);
  return response.data;
};

export const useGetRemoveBgStatusMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [
      { url: ImageProcessEndpoint.REMOVE_BG, organizationId, event: 'getRemoveBgStatus' },
    ],
    mutationFn: (jobId: string) => {
      return getRemoveBgStatus({
        jobId,
        organizationId,
      });
    },
  });
};

type TEnhanceQualityPayload = {
  imageUrl: string;
  organizationId: number;
};

const enhanceImageQuality = async ({ imageUrl, organizationId }: TEnhanceQualityPayload) => {
  const url = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.ENHANCE);
  const response = await apiClient.post<TRemoveBackgroundResponse>(url, {
    imageUrl,
  });
  return response.data;
};

export const useEnhanceImageMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ImageProcessEndpoint.ENHANCE, organizationId],
    mutationFn: (props: Omit<TEnhanceQualityPayload, 'organizationId'>) => {
      return enhanceImageQuality({
        ...props,
        organizationId,
      });
    },
  });
};

type TEnhanceQualityStatusResponse = {
  jobId: string;
  status: TScene['status'];
  url: string;
};

const getEnhanceQualityStatus = async ({ jobId, organizationId }: TGetRemoveBgStatusPayload) => {
  const baseUrl = getImageProcessApiUrl(organizationId, ImageProcessEndpoint.ENHANCE);
  const url = `${baseUrl}/${jobId}`;
  const response = await apiClient.get<TEnhanceQualityStatusResponse>(url);
  return response.data;
};

export const useGetEnhanceStatusMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ImageProcessEndpoint.ENHANCE, organizationId, event: 'getEnhanceStatus' }],
    mutationFn: (jobId: string) => {
      return getEnhanceQualityStatus({
        jobId,
        organizationId,
      });
    },
  });
};

type TEnhanceSceneQualityResponse = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'enhance_quality';
  sceneId: string;
  status: string;
};

type TEnhanceSceneQualityPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
};

const enhanceSceneQuality = async ({
  sceneId,
  projectId,
  organizationId,
}: TEnhanceSceneQualityPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.ENHANCE_QUALITY,
  });
  const response = await apiClient.post<{ result: TEnhanceSceneQualityResponse }>(url);
  return response.data.result;
};

export const useEnhanceSceneQualityMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [
      { url: AiSceneEditEndpoint.ENHANCE_QUALITY, organizationId, event: 'enhanceSceneQuality' },
    ],
    mutationFn: (props: Omit<TEnhanceSceneQualityPayload, 'organizationId'>) => {
      return enhanceSceneQuality({ ...props, organizationId });
    },
  });
};

type TEnhanceDetailsQualityResponse = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'enhance_details';
  sceneId: string;
  status: TScene['status'];
};

type TEnhanceDetailsQualityPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
};

const enhanceDetailsQuality = async ({
  sceneId,
  projectId,
  organizationId,
}: TEnhanceDetailsQualityPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.ENHANCE_DETAILS,
  });
  const response = await apiClient.post<{ result: TEnhanceDetailsQualityResponse }>(url);
  return response.data.result;
};

export const useEnhanceDetailsQualityMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [
      { url: AiSceneEditEndpoint.ENHANCE_DETAILS, organizationId, event: 'enhanceDetailsQuality' },
    ],
    mutationFn: (props: Omit<TEnhanceDetailsQualityPayload, 'organizationId'>) => {
      return enhanceDetailsQuality({ ...props, organizationId });
    },
  });
};

type TRemoveBackgroundCanvasImageResponse = {
  versionId: string;
  timestamp: number;
  imageUrl: string;
  action: 'enhance_details';
  sceneId: string;
  status: TScene['status'];
};

type TRemoveBackgroundCanvasImagePayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
};

const removeBackgroundCanvasImage = async ({
  sceneId,
  projectId,
  organizationId,
}: TRemoveBackgroundCanvasImagePayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.REMOVE_BACKGROUND,
  });
  const response = await apiClient.post<{ result: TRemoveBackgroundCanvasImageResponse }>(url);
  return response.data.result;
};

export const useRemoveBackgroundCanvasImageMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [
      {
        url: AiSceneEditEndpoint.REMOVE_BACKGROUND,
        organizationId,
        event: 'removeBackgroundCanvasImage',
      },
    ],
    mutationFn: (props: Omit<TRemoveBackgroundCanvasImagePayload, 'organizationId'>) => {
      return removeBackgroundCanvasImage({ ...props, organizationId });
    },
  });
};

type TUploadImagePayload = {
  image?: File;
  url?: string;
  organizationId: number;
};

const uploadImageToBucket = async ({ image, url, organizationId }: TUploadImagePayload) => {
  const apiUrl = getUploadProcessApiUrl(organizationId, UploadProcessEndpoint.UPLOAD_IMAGE);
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  if (url) {
    formData.append('url', url);
  }
  const response = await apiClient.post<{ url: string }>(apiUrl, formData);
  return response.data.url;
};

export const useUploadImageMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [UploadProcessEndpoint.UPLOAD_IMAGE, organizationId],
    mutationFn: (props: Omit<TUploadImagePayload, 'organizationId'>) => {
      return uploadImageToBucket({
        ...props,
        organizationId,
      });
    },
  });
};

type TUploadFilePayload = {
  file: File;
  organizationId: number;
};

const uploadFileToBucket = async ({ file, organizationId }: TUploadFilePayload) => {
  const apiUrl = getUploadProcessApiUrl(organizationId, UploadProcessEndpoint.UPLOAD_FILE);
  const formData = new FormData();
  formData.append('file', file);
  const response = await apiClient.post<{ url: string }>(apiUrl, formData);
  return response.data.url;
};

export const useUploadFileMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [UploadProcessEndpoint.UPLOAD_FILE, organizationId],
    mutationFn: (props: Omit<TUploadFilePayload, 'organizationId'>) => {
      return uploadFileToBucket({
        ...props,
        organizationId,
      });
    },
  });
};

type TIdentityControlPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  identityStrength: number;
};

const applyIdentityControl = async ({
  sceneId,
  projectId,
  organizationId,
  identityStrength,
}: TIdentityControlPayload) => {
  const url = getAiSceneEditApiUrl({
    organizationId,
    projectId,
    sceneId,
    subpath: AiSceneEditEndpoint.IDENTITY_CONTROL,
  });

  const response = await apiClient.post<{ result: TScene }>(url, {
    identityStrength,
  });
  return response.data.result;
};

export const useIdentityControlMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { sceneId, projectId } = useParams();

  return useMutation({
    mutationKey: [AiSceneEditEndpoint.IDENTITY_CONTROL, organizationId],
    mutationFn: (identityStrength: number) => {
      invariant(sceneId, 'sceneId must be provided');
      invariant(projectId, 'projectId must be provided');

      return applyIdentityControl({
        identityStrength,
        sceneId,
        projectId,
        organizationId,
      });
    },
  });
};

type TQuickUploadProductPayload = {
  originalImage: string;
  transparentImage: string;
  organizationId: number;
};

type TQuickUploadProductResponse = {
  id: string;
  name: string;
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  product_code: string;
  thumbnail: {
    original: string;
    transparent: string;
  };
  image: {
    original: string;
    transparent: string;
  };
  category: {
    id: string;
    name: string;
    createdAt: number;
  };
};

const quickUploadProduct = async ({
  originalImage,
  transparentImage,
  organizationId,
}: TQuickUploadProductPayload) => {
  const url = getProductsApiUrl(organizationId, ProductEndpoint.QUICK_UPLOAD);

  const payload = {
    originalImage,
    transparentImage,
  };

  const response = await apiClient.post<TQuickUploadProductResponse>(url, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

export const useQuickUploadProduct = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ProductEndpoint.QUICK_UPLOAD, organizationId],
    mutationFn: (props: Omit<TQuickUploadProductPayload, 'organizationId'>) => {
      return quickUploadProduct({
        ...props,
        organizationId,
      });
    },
  });
};
