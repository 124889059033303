import React, { useEffect, useState } from 'react';
import DeleteIcon from '@/assets/icons/ai-scene-icons/trash.svg?react';
import { toast } from 'sonner';
import { useNavigate, useLocation, useParams } from 'react-router';
import { useIsMounted } from 'usehooks-ts';
import { useDeleteSceneMutation } from '../../review/queries';
import { useRefetchGeneratedScenes } from '../../result-sidebar/queries';
import { Button } from 'components/ui/button';
import { TooltipItem } from 'components/common/TooltipItem';
import { useGetInfiniteGeneratedScenes } from 'pages/ai-scene/review/helpers';
import { useIsAiImageType } from 'pages/ai-scene/useIsAiImageType';
import { RoutePaths } from '@/utils/route/constants';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';

export const DeleteSceneAction: React.FC = () => {
  const deleteScene = useDeleteSceneMutation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { sceneId, projectId } = useParams();
  const { refetchScenes } = useRefetchGeneratedScenes();
  const [shouldFetchScenes, setShouldFetchScenes] = useState(false);
  const { refetch: fetchScenes } = useGetInfiniteGeneratedScenes({
    staleTime: 0,
  });
  const basePath = useAISceneOrImagePath();

  useEffect(() => {
    const fetchNewSceneId = async () => {
      const scenesResponse = await fetchScenes();
      const newSceneId = scenesResponse.data?.items[0]?.id;

      if (newSceneId) {
        const newPath = `/${basePath}/${projectId}/generation/${newSceneId}`;
        navigate(newPath, { replace: true });
      } else {
        const projectPath = `/${basePath}/${projectId}`;
        navigate(projectPath, { replace: true });
      }
    };

    if (shouldFetchScenes) {
      fetchNewSceneId();
      setShouldFetchScenes(false);
    }
  }, [shouldFetchScenes, fetchScenes, navigate, basePath, projectId]);

  const handleDeleteClick = async () => {
    try {
      if (!sceneId || !projectId) return;

      await deleteScene.mutateAsync({
        sceneId,
        projectId,
      });

      if (!isMounted()) return;
      refetchScenes();
      setShouldFetchScenes(true);
      toast.success('Scene deleted successfully.');
    } catch (error) {
      console.error('Failed to delete:', error);
      toast.error('Failed to delete the scene.');
    }
  };

  return (
    <TooltipItem
      side='bottom'
      trigger={
        <Button
          variant='ghost'
          size='sm'
          className='h-full bg-inherit p-0'
          onClick={handleDeleteClick}
        >
          <DeleteIcon />
        </Button>
      }
    >
      Delete
    </TooltipItem>
  );
};
