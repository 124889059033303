import React, { FC } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

interface SelectAuthorityProps {
  options: { label: string; value: string }[];
  contentProps?: React.ComponentPropsWithoutRef<typeof SelectContent>;
  triggerProps?: React.ComponentPropsWithoutRef<typeof SelectTrigger>;
}

export const SelectAuthority: FC<
  SelectAuthorityProps & React.ComponentPropsWithoutRef<typeof Select>
> = ({ options, contentProps, triggerProps, ...selectProps }) => {
  return (
    <Select {...selectProps}>
      <SelectTrigger className='h-9' {...triggerProps}>
        <SelectValue placeholder='Select authority' />
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
