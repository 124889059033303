import { AiBannerEditDrawer } from 'components/ai-banner/edit/AiBannerEditDrawer';
import { ImageEditor } from 'components/ai-banner/edit/ImageEditor';

export const AiBannerEdit = () => {
  return (
    <div className='relative flex h-[calc(100%-60px)] w-full'>
      <ImageEditor />
      <AiBannerEditDrawer />
    </div>
  );
};
