import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useState } from 'react';

import { HowItWorksBox } from 'components/ai-scene/side-menu/create-tool/visual-guidance/HowItWorksBox';
import { ProductImageTab } from './ProductImageTab';
import { PartialDetailTab } from './PartialDetailTab';
const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white w-[142px] !text-main font-medium text-c-dark data-[state=active]:border data-[state=active]:border-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

const RestoreMenuOptions = {
  PRODUCT_IMAGE: 'product-image',
  PARTIAL_DETAIL: 'partial-detail',
} as const;

export const RestoreMenu = ({ onBackClick }: { onBackClick: () => void }) => {
  const [activeTab, setActiveTab] = useState<string>(RestoreMenuOptions.PRODUCT_IMAGE);

  return (
    <div className='flex h-full w-[320px] flex-col gap-5 rounded-lg bg-white p-3 shadow-md'>
      <button
        onClick={onBackClick}
        className='flex h-9 gap-2 border-b border-[#EAEAEA] text-[15px] font-semibold'
      >
        <ArrowLeftIcon /> Restore
      </button>
      <div className='flex flex-col gap-7'>
        {activeTab === RestoreMenuOptions.PRODUCT_IMAGE && (
          <HowItWorksBox
            text='Restores the product details as the scale is increased. Scale of 1 restores your product exactly but may damage the scene realism.'
            linkText="Don't show this again"
            parentComponent='ProductImageRestore'
          />
        )}
        {activeTab === RestoreMenuOptions.PARTIAL_DETAIL && (
          <HowItWorksBox
            text='Restores the product details like text, face, hands & design patterns on selected areas but may damage the scene realism.'
            linkText="Don't show this again"
            parentComponent='PartialDetailRestore'
          />
        )}
        <Tabs
          defaultValue={RestoreMenuOptions.PRODUCT_IMAGE}
          className='flex flex-col gap-5'
          onValueChange={(value) => setActiveTab(value)}
        >
          <TabsList className='roundet-[6px] border border-[#F0F0F0] bg-white'>
            <TabsTrigger className={TAB_TRIGGER_CLASS} value={RestoreMenuOptions.PRODUCT_IMAGE}>
              Product Image
            </TabsTrigger>
            <TabsTrigger className={TAB_TRIGGER_CLASS} value={RestoreMenuOptions.PARTIAL_DETAIL}>
              Partial Detail
            </TabsTrigger>
          </TabsList>
          <TabsContent value={RestoreMenuOptions.PRODUCT_IMAGE}>
            <ProductImageTab />
          </TabsContent>
          <TabsContent value={RestoreMenuOptions.PARTIAL_DETAIL}>
            <PartialDetailTab />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
