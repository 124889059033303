import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { fabric } from 'fabric';

export const MAX_CANVAS_HISTORY = 5;
export const CUSTOM_OBJECT_PROPERTIES: Array<
  | keyof Pick<
      fabric.Object,
      | 'imageId'
      | 'imageType'
      | 'isSelectionIgnored'
      | 'imageProperties'
      | 'imageName'
      | 'imageProperties'
      | 'drawingType'
      | 'latestSavedScaling'
      | 'bulkImageId'
    >
  | keyof Pick<fabric.Canvas, 'originalDimensions'>
> = [
  'imageId',
  'imageType',
  'isSelectionIgnored',
  'imageName',
  'imageProperties',
  'drawingType',
  'latestSavedScaling',
  'originalDimensions',
  'bulkImageId',
];

export const ImageType = {
  TRANSPARENT: 'transparent',
  OPAQUE: 'opaque',
  ALPHA: 'alpha',
  TEMPLATE: 'template',
  PRODUCT: 'product',
  ELEMENT: 'element',
  LOGO: 'logo',
  // Not an existing type, but used for user uploaded images
  USER_PRODUCT: 'user-product',
} as const;

export type TImageType = (typeof ImageType)[keyof typeof ImageType];

export const COMMON_DIMENSIONS = [
  {
    id: 'square',
    text: 'Square (1:1) (768*768)',
    width: 768,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
  {
    id: 'landscape',
    text: 'Landscape (4:3) (768*576)',
    width: 768,
    height: 576,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
  {
    id: 'portrait',
    text: 'Portrait (3:4) (576*768)',
    width: 576,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
  {
    id: 'banner',
    text: 'Banner (4:1) (2048*512)',
    width: 2048,
    height: 512,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.requiredPlan,
  },
  {
    id: 'ig-post',
    text: 'Instagram Post (4:5) (928*1160)',
    width: 928,
    height: 1160,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.requiredPlan,
  },
  {
    id: 'ig-story',
    text: 'Instagram Story (9:16) (720*1280)',
    width: 720,
    height: 1280,
    requiredPlan: FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.requiredPlan,
  },
  {
    id: 'square-pro',
    text: 'Square (1:1) (1024*1024)',
    width: 1024,
    height: 1024,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
  {
    id: 'landscape-pro',
    text: 'Landscape (4:3) (1024*768)',
    width: 1024,
    height: 768,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
  {
    id: 'portrait-pro',
    text: 'Portrait (3:4) (768*1024)',
    width: 768,
    height: 1024,
    requiredPlan: FeatureAccessRequirements.FREE.requiredPlan,
  },
];
