import { useMutation } from '@tanstack/react-query';

import { AuthEndpoint } from '@/utils/fetch/constants';
import { useUserStore } from '@/providers/user/hooks';
import { User } from '@/providers/user/store';
import { updateTokensInCache } from '@/utils/fetch/helper';
import { apiClient, setAuthHeaders } from '@/utils/fetch/axiosConfig';

type PasswordResetCredentials = {
  token: string;
  password: string;
};

export const usePasswordReset = () => {
  const setUser = useUserStore((state) => state.setUser);

  return useMutation({
    mutationKey: [AuthEndpoint.RESET_PASSWORD],
    mutationFn: (credentials: PasswordResetCredentials) => {
      return apiClient.post<User>(`${AuthEndpoint.RESET_PASSWORD}`, {
        token: credentials.token,
        password: credentials.password,
      });
    },
    onSuccess: ({ data }) => {
      setUser(data);
      updateTokensInCache(data);
      setAuthHeaders({ accessToken: data.access_token });
    },
  });
};
