import React from 'react';
import { Button } from 'components/ui/button';
import { ArrowRightIcon, ProductPhotoIcon } from '../../side-menu/create-tool/CreateToolIcons';
import { useSceneImagesSrc } from '../../side-menu/useSceneImagesSrc';

interface ProductPhotoButtonProps {}

export const ProductPhotoButton: React.FC<ProductPhotoButtonProps> = () => {
  const { productImages } = useSceneImagesSrc();

  return (
    <Button
      variant='outline'
      className='flex h-[48px] w-full items-center justify-between rounded-md border border-gray-200 p-[6px] hover:bg-gray-50'
    >
      <div className='flex items-center gap-2'>
        {productImages.length > 0 ? (
          <div className='relative'>
            <img
              src={productImages[0]}
              alt='Product'
              className='h-8 w-8 rounded-[6px] bg-[#F5F5F5] object-contain'
            />
            {productImages.length > 1 && (
              <span className='absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-c-dark text-[9px] text-white'>
                {productImages.length}
              </span>
            )}
          </div>
        ) : (
          <ProductPhotoIcon />
        )}
        <span>Product Photo</span>
      </div>
      <ArrowRightIcon />
    </Button>
  );
};
