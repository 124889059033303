import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { useMemo } from 'react';
import { useGetColorsQuery } from '../queries';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { ColorItem } from './ColorItem';
import { EditColorModalWrapper } from './EditColorModalWrapper';
import { CreateColorModalWrapper } from './CreateColorModalWrapper';

export const ColorContent = () => {
  const { data, isError, isLoading, refetch } = useGetColorsQuery({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load colors.</span>
    </RefetchView>
  );

  const colorItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    return data?.items.map((color) => {
      return (
        <ColorItem key={color.id + color.last_update_info.timestamp} {...color} refetch={refetch}>
          <EditColorModalWrapper {...color} refetch={refetch} />
        </ColorItem>
      );
    });
  }, [data, isLoading, refetch]);

  return (
    <ScrollArea className='md:w-[90vw] md:whitespace-nowrap'>
      <div className='flex w-full flex-wrap gap-2 rounded-lg bg-white p-4 py-3  md:flex-nowrap'>
        <CreateColorModalWrapper refetch={refetch} />
        {errorView || colorItems}
      </div>
      <ScrollBar orientation='horizontal' />
    </ScrollArea>
  );
};
