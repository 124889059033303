import { SCROLL_CONTENT_CL } from 'components/common/layout/constants';
import { ProjectsTopLinks } from './_components/ProjectsTopLinks';
import { ProjectListContainer } from './_components/ProjectListContainer';

export const ProjectsView = () => {
  return (
    <section
      className={` ${SCROLL_CONTENT_CL} relative min-h-full  overflow-y-auto bg-white p-8 md:p-6`}
    >
      <aside className='flex flex-col items-start gap-5'>
        <h1 className=' text-left text-[26px] font-medium tracking-tighter'>Recent</h1>
        <ProjectsTopLinks />
      </aside>
      <ProjectListContainer />
    </section>
  );
};
