import { FC } from 'react';
import { ColorModal } from './ColorModal';
import { useUploadColorMutation } from '../queries';
import { Button } from 'components/ui/button';

interface CreateColorModalWrapperProps {
  refetch: () => void;
}
export const CreateColorModalWrapper: FC<CreateColorModalWrapperProps> = ({ refetch }) => {
  const uploadColorMutation = useUploadColorMutation();

  return (
    <div className='flex flex-col gap-2 rounded-md p-2'>
      <ColorModal
        title='Create Color'
        onSaveClicked={async (params) => {
          await uploadColorMutation.mutateAsync(params);
          refetch();
        }}
      >
        <div className='flex flex-col justify-center gap-1.5 rounded-md'>
          <Button asChild variant='ghost' className='cursor-pointer'>
            <div className='flex h-[54px] w-[54px] items-center justify-center rounded-md border'>
              <span className='i-mdi-plus text-[18px]'></span>
            </div>
          </Button>
          <div className='text-[12px] font-light'>Add New</div>
        </div>
      </ColorModal>
    </div>
  );
};
