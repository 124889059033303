import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ProjectEndpoint } from '@/utils/fetch/constants';
import { getProjectsApiUrl } from '@/utils/fetch/helper';
import {
  DefinedQueryObserverResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { TProject } from 'pages/workspace/projects/helper';
import { createContext, useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

const createProject = async (organizationId: number, type: TProject['type']): Promise<TProject> => {
  const response = await apiClient.post<TProject>(
    getProjectsApiUrl(organizationId, ProjectEndpoint.BASE),
    {
      project_type: type,
    },
  );
  return response.data;
};

export const useCreateProjectMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [ProjectEndpoint.BASE, organizationId],
    mutationFn: ({ type }: { type: TProject['type'] }) => createProject(organizationId, type),
  });
};

const getProjectById = async (organizationId: number, projectId: string): Promise<TProject> => {
  const baseUrl = getProjectsApiUrl(organizationId, ProjectEndpoint.BASE);
  const url = `${baseUrl}/${projectId}`;
  const response = await apiClient.get<TProject>(url, {
    params: {
      organization_id: organizationId,
      project_id: projectId,
    },
  });
  return response.data;
};

const GET_PROJECT_QUERY_NAME = 'getProject';
export const getUseGetProjectQueryKey = (organizationId: number, projectId: string | undefined) => {
  return [
    {
      url: ProjectEndpoint.BASE,
      organizationId,
      projectId,
      name: GET_PROJECT_QUERY_NAME,
    },
  ];
};

export const useGetProject = (options?: Partial<UseQueryOptions>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { projectId } = useParams();

  return useQuery({
    queryKey: getUseGetProjectQueryKey(organizationId, projectId),
    queryFn: ({ queryKey }) => {
      invariant(queryKey[0].projectId, 'projectId is required');
      return getProjectById(queryKey[0].organizationId, queryKey[0].projectId);
    },
    staleTime: options?.staleTime,
    gcTime: options?.gcTime,
    placeholderData: undefined,
  });
};

export const useLazyGetProject = () => {
  const queryClient = useQueryClient();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const fetchProject = useCallback(
    (projectId: string) => {
      return queryClient.fetchQuery({
        queryKey: [{ url: ProjectEndpoint.BASE, projectId, organizationId }],
        queryFn: ({ queryKey }) => {
          return getProjectById(queryKey[0].organizationId, queryKey[0].projectId);
        },
        retry: 0,
      });
    },
    [organizationId, queryClient],
  );

  return { fetchProject };
};

type TRenameProjectPayload = {
  projectId: string;
  projectName: string;
  organizationId: number;
};

const renameProject = async ({
  projectId,
  projectName,
  organizationId,
}: TRenameProjectPayload): Promise<TProject> => {
  const baseUrl = getProjectsApiUrl(organizationId, ProjectEndpoint.BASE);
  const url = `${baseUrl}/${projectId}`;
  const response = await apiClient.patch<TProject>(url, {
    name: projectName,
  });
  return response.data;
};

export const useRenameProjectMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [ProjectEndpoint.BASE, organizationId],
    mutationFn: (props: Omit<TRenameProjectPayload, 'organizationId'>) =>
      renameProject({ ...props, organizationId }),
  });
};

export const ProjectContext = createContext<
  DefinedQueryObserverResult<TProject, Error> | undefined
>(undefined);

export const useProjectDataCtx = () => {
  const ctx = useContext(ProjectContext);
  invariant(ctx, 'useProjectDataCtx must be used within a ProjectProvider');

  return ctx.data;
};

export const useAllProjectCtx = () => {
  const ctx = useContext(ProjectContext);
  invariant(ctx, 'useAllProjectCtx must be used within a ProjectProvider');

  return ctx;
};
