import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ProductEndpoint } from '@/utils/fetch/constants';
import { getProductsApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TScene } from 'components/ai-scene/result-sidebar/queries';
import {
  TPaginatedResponse,
  convertArrayToQueryParam,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

export type TProducts = TPaginatedResponse<TProduct>;

export type ProductProperties = {
  caption: string;
  face_present: boolean;
  hand_present: boolean;
  text_present: boolean;
  logo_present: boolean;
};

export type TProduct = {
  id: string;
  name: string;
  image: {
    original: string;
    transparent: string;
  };
  thumbnail: {
    original: string;
    transparent: string;
  };
  last_update_info: {
    timestamp: number;
    user_name: string;
  };
  product_code: string;
  category: {
    id: string;
    name: string;
  };
  properties?: ProductProperties | null;
};

type TGetProductsPayload = {
  organizationId: number;
  categoryIds?: string[];
  pageId?: number;
};
const getProducts = async ({
  pageId = 0,
  categoryIds,
  organizationId,
}: TGetProductsPayload): Promise<TProducts> => {
  const baseUrl = getProductsApiUrl(organizationId, ProductEndpoint.BASE);
  const categoryParams =
    categoryIds && categoryIds.length > 0
      ? convertArrayToQueryParam({ paramKey: 'categoryId', values: categoryIds, prefix: '&' })
      : '';
  const url = `${baseUrl}?page=${pageId}${categoryParams}`;
  const response = await apiClient.get(url);
  return response.data;
};

export const GET_PRODUCTS_QUERY_KEY = 'getProductsQueryKey';

export const useGetProducts = ({
  pageId,
  categoryIds,
}: Omit<TGetProductsPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [
      {
        url: ProductEndpoint.BASE,
        organizationId,
        pageId,
        categoryIds,
        _name: GET_PRODUCTS_QUERY_KEY,
      },
    ],
    queryFn: ({ queryKey }) => {
      return getProducts(queryKey[0]);
    },
    placeholderData: (prev) => prev,
  });
  return result;
};

type TProductUpdateBasePayload = {
  productCode: string | null;
  productName: string;
  categoryId: string | null;
  originalImage: string;
  transparentImage: string;
  productProperties: ProductProperties | null;
};

type TProductUpdatePathPayload = {
  organizationId: number;
  productId: string;
};

type TProductUpdatePayload = TProductUpdateBasePayload & TProductUpdatePathPayload;
const updateProductDetail = async (props: TProductUpdatePayload) => {
  const baseUrl = getProductsApiUrl(props.organizationId, ProductEndpoint.BASE);
  const url = `${baseUrl}/${props.productId}`;
  return apiClient.patch<TProduct>(url, {
    productName: props.productName,
    categoryId: props.categoryId,
    productCode: props.productCode,
    originalImage: props.originalImage,
    transparentImage: props.transparentImage,
    productProperties: props.productProperties,
  });
};

export const useUpdateProductDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ProductEndpoint.BASE, organizationId }],
    mutationFn: (props: Omit<TProductUpdatePayload, 'organizationId'>) => {
      return updateProductDetail({ ...props, organizationId });
    },
  });
};

type TProductPartialUpdatePayload = Partial<TProductUpdateBasePayload> & TProductUpdatePathPayload;
const updatePartialProductDetail = async (props: TProductPartialUpdatePayload) => {
  const baseUrl = getProductsApiUrl(props.organizationId, ProductEndpoint.BASE);
  const url = `${baseUrl}/${props.productId}`;

  return apiClient.patch<TProduct>(url, {
    productName: props.productName || undefined,
    categoryId: props.categoryId || undefined,
    productCode: props.productCode || undefined,
    originalImage: props.originalImage || undefined,
    transparentImage: props.transparentImage || undefined,
    productProperties: props.productProperties || undefined,
  });
};

export const usePartialUpdateProductDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ProductEndpoint.BASE, organizationId, 'updatePartialProductDetail'],
    mutationFn: (props: Omit<TProductPartialUpdatePayload, 'organizationId'>) => {
      return updatePartialProductDetail({ ...props, organizationId });
    },
  });
};

export type TProductUploadPayload = Omit<TProductUpdateBasePayload, 'id'> &
  Pick<TProductUpdatePathPayload, 'organizationId'>;
const uploadProduct = async (props: TProductUploadPayload) => {
  const url = getProductsApiUrl(props.organizationId, ProductEndpoint.BASE);
  const response = await apiClient.post<TProduct>(url, {
    productName: props.productName,
    categoryId: props.categoryId,
    productCode: props.productCode,
    originalImage: props.originalImage,
    transparentImage: props.transparentImage,
    productProperties: props.productProperties,
  });
  return response.data;
};

export const useUploadProduct = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ProductEndpoint.BASE, organizationId }],
    mutationFn: (props: Omit<TProductUploadPayload, 'organizationId'>) => {
      return uploadProduct({ ...props, organizationId });
    },
  });
};

const deleteProduct = async (props: TProductUpdatePathPayload) => {
  const baseUrl = getProductsApiUrl(props.organizationId, ProductEndpoint.BASE);
  const url = `${baseUrl}/${props.productId}`;
  return apiClient.delete(url);
};

export const useDeleteProduct = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ProductEndpoint.BASE, organizationId }],
    mutationFn: (productId: string) => {
      return deleteProduct({ productId, organizationId });
    },
  });
};

const getProductById = async (props: TProductUpdatePathPayload) => {
  const baseUrl = getProductsApiUrl(props.organizationId, ProductEndpoint.BASE);
  const url = `${baseUrl}/${props.productId}`;
  const response = await apiClient.get<TProduct>(url);
  return response.data;
};

export const GET_PRODUCT_BY_ID_KEY = 'getProductById';

export const useGetProductById = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const { productId } = useParams();

  return useQuery({
    queryKey: [
      { url: ProductEndpoint.BASE, organizationId, productId, _name: GET_PRODUCT_BY_ID_KEY },
    ],
    queryFn: ({ queryKey }) => {
      invariant(queryKey[0].productId, 'Product id is required');

      return getProductById({
        organizationId,
        productId: queryKey[0].productId,
      });
    },
    staleTime: Infinity,
    gcTime: 0,
  });
};

type TProductPropertiesPayload = {
  organizationId: number;
  transparentImage: string;
};

type TProductPropertiesProcessResponse = {
  processId: string;
  status: TScene['status'];
  properties?: ProductProperties | null;
};

const getProductProperties = async (props: TProductPropertiesPayload) => {
  const url = `${getProductsApiUrl(props.organizationId, ProductEndpoint.PROPERTIES)}`;

  const response = await apiClient.post<TProductPropertiesProcessResponse>(url, {
    transparentImage: props.transparentImage,
  });
  return response.data;
};

export const useGetProductProperties = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [{ url: ProductEndpoint.PROPERTIES, organizationId }],
    mutationFn: (props: Omit<TProductPropertiesPayload, 'organizationId'>) => {
      return getProductProperties({ ...props, organizationId });
    },
  });
};

type TGetProductPropertiesStatusPayload = {
  processId: string;
  organizationId: number;
};

const getProductPropertiesStatus = async ({
  processId,
  organizationId,
}: TGetProductPropertiesStatusPayload) => {
  const baseUrl = getProductsApiUrl(organizationId, ProductEndpoint.PROPERTIES);
  const url = `${baseUrl}/${processId}`;
  const response = await apiClient.get<TProductPropertiesProcessResponse>(url);
  return response.data;
};

export const useGetProductPropertiesStatusMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [
      { url: ProductEndpoint.PROPERTIES, organizationId, event: 'getProductPropertiesStatus' },
    ],
    mutationFn: (processId: string) => {
      return getProductPropertiesStatus({
        processId,
        organizationId,
      });
    },
  });
};

export const ProductContext = createContext<TProduct | undefined>(undefined);

export const useProductDataCtx = () => {
  const ctx = useContext(ProductContext);
  invariant(ctx, 'useProductDataCtx must be used within a ProductProvider');

  return ctx;
};
