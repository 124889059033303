import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { TAiSceneImage } from '../create/store';

export type TBulkSceneImage = TAiSceneImage & { bulkImageId: string };

export type TAiSceneBulkCreateStore = TAiSceneBulkCreateState & TAiSceneBulkCreateAction;

type TAiSceneBulkCreateState = {
  productImages: TBulkSceneImage[];
  mainCanvasState: string | null;
  additionalImage:
    | (Omit<TBulkSceneImage, 'imageType'> & {
        type: Exclude<TBulkSceneImage['type'], 'product'>;
      })
    | null;
  additionalText: { id: string; text: string } | null;
};

type TAiSceneBulkCreateAction = {
  addProductImage: (images: TBulkSceneImage | TBulkSceneImage[]) => void;
  addAdditionalImage: (image: TAiSceneBulkCreateState['additionalImage']) => void;
  removeAdditionalImage: (imageId: string) => void;
  addText: (id: string, text: string) => void;
  removeText: (id: string) => void;
  resetAll: () => void;
  updateMainCanvasState: (state: string) => void;
};

const initialState: TAiSceneBulkCreateState = {
  mainCanvasState: null,
  productImages: [],
  additionalImage: null,
  additionalText: null,
};

const useAiSceneBulkCreateSlice: ImmerStateCreator<TAiSceneBulkCreateStore> = (set) => ({
  ...initialState,
  addProductImage: (images) => {
    set(
      (state) => {
        if (Array.isArray(images)) {
          state.productImages = [...state.productImages, ...images];
        } else {
          state.productImages.push(images);
        }
      },
      false,
      'addProductImage',
    );
  },
  addAdditionalImage: (image) => {
    set(
      (state) => {
        state.additionalImage = image;
      },
      false,
      'addAdditionalImage',
    );
  },
  removeAdditionalImage: (imageId) => {
    set(
      (state) => {
        if (state.additionalImage?.id === imageId) {
          state.additionalImage = null;
        }
      },
      false,
      'removeAdditionalImage',
    );
  },
  addText: (id, text) => {
    set(
      (state) => {
        state.additionalText = { id, text };
      },
      false,
      'addText',
    );
  },
  removeText: (id) => {
    set(
      (state) => {
        if (state.additionalText?.id === id) {
          state.additionalText = null;
        }
      },
      false,
      'removeText',
    );
  },
  updateMainCanvasState: (newState) => {
    set(
      (state) => {
        state.mainCanvasState = newState;
      },
      false,
      'updateMainCanvasState',
    );
  },
  resetAll: () => {
    set(initialState), false, 'resetAll';
  },
});

export const createAiSceneBulkStore = (initProps?: Partial<TAiSceneBulkCreateStore>) => {
  return createStore<TAiSceneBulkCreateStore>()(
    devtools(
      immer((...props) => ({
        ...useAiSceneBulkCreateSlice(...props),
        ...initProps,
      })),
      { name: 'AiSceneBulkStore', trace: true },
    ),
  );
};
