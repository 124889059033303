export const INDUSTRIES = [
  'E-commerce',
  'Fashion',
  'Photography',
  'Marketing',
  'Design',
  'Technology',
  'Other',
];

export const ROLES = [
  'Photographer',
  'Creative Director',
  'Designer',
  'Marketing Specialist',
  'Product Manager',
  'Developer',
  'Other',
];

export const COUNTRIES = [
  {
    country_code: 'AL',
    country_en: 'Albania',
    country_cn: '阿尔巴尼亚',
    phone_code: 355,
  },
  {
    country_code: 'DZ',
    country_en: 'Algeria',
    country_cn: '阿尔及利亚',
    phone_code: 213,
  },
  {
    country_code: 'AF',
    country_en: 'Afghanistan',
    country_cn: '阿富汗',
    phone_code: 93,
  },
  {
    country_code: 'AR',
    country_en: 'Argentina',
    country_cn: '阿根廷',
    phone_code: 54,
  },
  {
    country_code: 'AE',
    country_en: 'United Arab Emirates',
    country_cn: '阿联酋',
    phone_code: 971,
  },
  {
    country_code: 'AW',
    country_en: 'Aruba',
    country_cn: '阿鲁巴',
    phone_code: 297,
  },
  {
    country_code: 'OM',
    country_en: 'Oman',
    country_cn: '阿曼',
    phone_code: 968,
  },
  {
    country_code: 'AZ',
    country_en: 'Azerbaijan',
    country_cn: '阿塞拜疆',
    phone_code: 994,
  },
  {
    country_code: 'EG',
    country_en: 'Egypt',
    country_cn: '埃及',
    phone_code: 20,
  },
  {
    country_code: 'ET',
    country_en: 'Ethiopia',
    country_cn: '埃塞俄比亚',
    phone_code: 251,
  },
  {
    country_code: 'IE',
    country_en: 'Ireland',
    country_cn: '爱尔兰',
    phone_code: 353,
  },
  {
    country_code: 'EE',
    country_en: 'Estonia',
    country_cn: '爱沙尼亚',
    phone_code: 372,
  },
  {
    country_code: 'AD',
    country_en: 'Andorra',
    country_cn: '安道尔',
    phone_code: 376,
  },
  {
    country_code: 'AO',
    country_en: 'Angola',
    country_cn: '安哥拉',
    phone_code: 244,
  },
  {
    country_code: 'AI',
    country_en: 'Anguilla',
    country_cn: '安圭拉',
    phone_code: 1264,
  },
  {
    country_code: 'AG',
    country_en: 'Antigua',
    country_cn: '安提瓜岛',
    phone_code: 1268,
  },
  {
    country_code: 'AT',
    country_en: 'Austria',
    country_cn: '奥地利',
    phone_code: 43,
  },
  {
    country_code: 'AU',
    country_en: 'Australia',
    country_cn: '澳大利亚',
    phone_code: 61,
  },
  {
    country_code: 'MO',
    country_en: 'Macau',
    country_cn: '中国澳门特别行政区',
    phone_code: 853,
  },
  {
    country_code: 'BB',
    country_en: 'Barbados',
    country_cn: '巴巴多斯',
    phone_code: 1246,
  },
  {
    country_code: 'PG',
    country_en: 'Papua New Guinea',
    country_cn: '巴布亚新几内亚',
    phone_code: 675,
  },
  {
    country_code: 'BS',
    country_en: 'The Bahamas',
    country_cn: '巴哈马',
    phone_code: 1242,
  },
  {
    country_code: 'PK',
    country_en: 'Pakistan',
    country_cn: '巴基斯坦',
    phone_code: 92,
  },
  {
    country_code: 'PY',
    country_en: 'Paraguay',
    country_cn: '巴拉圭',
    phone_code: 595,
  },
  {
    country_code: 'PS',
    country_en: 'Palestine',
    country_cn: '巴勒斯坦',
    phone_code: 970,
  },
  {
    country_code: 'BH',
    country_en: 'Bahrain',
    country_cn: '巴林',
    phone_code: 973,
  },
  {
    country_code: 'PA',
    country_en: 'Panama',
    country_cn: '巴拿马',
    phone_code: 507,
  },
  {
    country_code: 'BR',
    country_en: 'Brazil',
    country_cn: '巴西',
    phone_code: 55,
  },
  {
    country_code: 'BY',
    country_en: 'Belarus',
    country_cn: '白俄罗斯',
    phone_code: 375,
  },
  {
    country_code: 'BM',
    country_en: 'Bermuda',
    country_cn: '百慕大',
    phone_code: 1441,
  },
  {
    country_code: 'BG',
    country_en: 'Bulgaria',
    country_cn: '保加利亚',
    phone_code: 359,
  },
  {
    country_code: 'MP',
    country_en: 'Northern Mariana Islands',
    country_cn: '北马里亚纳群岛',
    phone_code: 1670,
  },
  {
    country_code: 'BJ',
    country_en: 'Benin',
    country_cn: '贝宁',
    phone_code: 229,
  },
  {
    country_code: 'BE',
    country_en: 'Belgium',
    country_cn: '比利时',
    phone_code: 32,
  },
  {
    country_code: 'IS',
    country_en: 'Iceland',
    country_cn: '冰岛',
    phone_code: 354,
  },
  {
    country_code: 'PL',
    country_en: 'Poland',
    country_cn: '波兰',
    phone_code: 48,
  },
  {
    country_code: 'BA',
    country_en: 'Bosnia and Herzegovina',
    country_cn: '波斯尼亚和黑塞哥维那',
    phone_code: 387,
  },
  {
    country_code: 'BO',
    country_en: 'Bolivia',
    country_cn: '玻利维亚',
    phone_code: 591,
  },
  {
    country_code: 'BZ',
    country_en: 'Belize',
    country_cn: '伯利兹',
    phone_code: 501,
  },
  {
    country_code: 'PW',
    country_en: 'Palau',
    country_cn: '帛琉',
    phone_code: 680,
  },
  {
    country_code: 'BW',
    country_en: 'Botswana',
    country_cn: '博茨瓦纳',
    phone_code: 267,
  },
  {
    country_code: 'BT',
    country_en: 'Bhutan',
    country_cn: '不丹',
    phone_code: 975,
  },
  {
    country_code: 'BF',
    country_en: 'Burkina Faso',
    country_cn: '布基纳法索',
    phone_code: 226,
  },
  {
    country_code: 'BI',
    country_en: 'Burundi',
    country_cn: '布隆迪',
    phone_code: 257,
  },
  {
    country_code: 'KP',
    country_en: 'North Korea',
    country_cn: '朝鲜',
    phone_code: 850,
  },
  {
    country_code: 'GQ',
    country_en: 'Equatorial Guinea',
    country_cn: '赤道几内亚',
    phone_code: 240,
  },
  {
    country_code: 'DK',
    country_en: 'Denmark',
    country_cn: '丹麦',
    phone_code: 45,
  },
  {
    country_code: 'DE',
    country_en: 'Germany',
    country_cn: '德国',
    phone_code: 49,
  },
  {
    country_code: 'TL',
    country_en: 'Timor-Leste',
    country_cn: '东帝汶',
    phone_code: 670,
  },
  {
    country_code: 'TG',
    country_en: 'Togo',
    country_cn: '多哥',
    phone_code: 228,
  },
  {
    country_code: 'DM',
    country_en: 'Dominica',
    country_cn: '多米尼加',
    phone_code: 1767,
  },
  {
    country_code: 'DO',
    country_en: 'Dominican Republic',
    country_cn: '多米尼加共和国',
    phone_code: 1809,
  },
  {
    country_code: 'RU',
    country_en: 'Russia',
    country_cn: '俄罗斯',
    phone_code: 7,
  },
  {
    country_code: 'EC',
    country_en: 'Ecuador',
    country_cn: '厄瓜多尔',
    phone_code: 593,
  },
  {
    country_code: 'ER',
    country_en: 'Eritrea',
    country_cn: '厄立特里亚',
    phone_code: 291,
  },
  {
    country_code: 'FR',
    country_en: 'France',
    country_cn: '法国',
    phone_code: 33,
  },
  {
    country_code: 'FO',
    country_en: 'Faroe Islands',
    country_cn: '法罗群岛',
    phone_code: 298,
  },
  {
    country_code: 'PF',
    country_en: 'French Polynesia',
    country_cn: '法属波利尼西亚',
    phone_code: 689,
  },
  {
    country_code: 'GF',
    country_en: 'French Guiana',
    country_cn: '法属圭亚那',
    phone_code: 594,
  },
  {
    country_code: 'PH',
    country_en: 'Philippines',
    country_cn: '菲律宾',
    phone_code: 63,
  },
  {
    country_code: 'FJ',
    country_en: 'Fiji',
    country_cn: '斐济',
    phone_code: 679,
  },
  {
    country_code: 'FI',
    country_en: 'Finland',
    country_cn: '芬兰',
    phone_code: 358,
  },
  {
    country_code: 'CV',
    country_en: 'Cape Verde',
    country_cn: '佛得角',
    phone_code: 238,
  },
  {
    country_code: 'FK',
    country_en: 'Falkland Islands',
    country_cn: '福克兰群岛',
    phone_code: 500,
  },
  {
    country_code: 'GM',
    country_en: 'The Gambia',
    country_cn: '冈比亚',
    phone_code: 220,
  },
  {
    country_code: 'CG',
    country_en: 'Republic of the Congo',
    country_cn: '刚果共和国',
    phone_code: 242,
  },
  {
    country_code: 'CD',
    country_en: 'Democratic Republic of the Congo',
    country_cn: '刚果民主共和国',
    phone_code: 243,
  },
  {
    country_code: 'CO',
    country_en: 'Colombia',
    country_cn: '哥伦比亚',
    phone_code: 57,
  },
  {
    country_code: 'CR',
    country_en: 'Costa Rica',
    country_cn: '哥斯达黎加',
    phone_code: 506,
  },
  {
    country_code: 'GD',
    country_en: 'Grenada',
    country_cn: '格林纳达岛',
    phone_code: 1473,
  },
  {
    country_code: 'GL',
    country_en: 'Greenland',
    country_cn: '格陵兰',
    phone_code: 299,
  },
  {
    country_code: 'GE',
    country_en: 'Georgia',
    country_cn: '格鲁吉亚',
    phone_code: 995,
  },
  {
    country_code: 'CU',
    country_en: 'Cuba',
    country_cn: '古巴',
    phone_code: 53,
  },
  {
    country_code: 'GP',
    country_en: 'Guadeloupe',
    country_cn: '瓜德罗普岛',
    phone_code: 590,
  },
  {
    country_code: 'GY',
    country_en: 'Guyana',
    country_cn: '圭亚那',
    phone_code: 592,
  },
  {
    country_code: 'HT',
    country_en: 'Haiti',
    country_cn: '海地',
    phone_code: 509,
  },
  {
    country_code: 'KR',
    country_en: 'South Korea',
    country_cn: '韩国',
    phone_code: 82,
  },
  {
    country_code: 'NL',
    country_en: 'Netherlands',
    country_cn: '荷兰',
    phone_code: 31,
  },
  {
    country_code: 'ME',
    country_en: 'Montenegro',
    country_cn: '黑山共和国',
    phone_code: 382,
  },
  {
    country_code: 'HN',
    country_en: 'Honduras',
    country_cn: '洪都拉斯',
    phone_code: 504,
  },
  {
    country_code: 'KI',
    country_en: 'Kiribati',
    country_cn: '基里巴斯',
    phone_code: 686,
  },
  {
    country_code: 'DJ',
    country_en: 'Djibouti',
    country_cn: '吉布提',
    phone_code: 253,
  },
  {
    country_code: 'KG',
    country_en: 'Kyrgyzstan',
    country_cn: '吉尔吉斯斯坦',
    phone_code: 996,
  },
  {
    country_code: 'GN',
    country_en: 'Guinea',
    country_cn: '几内亚',
    phone_code: 224,
  },
  {
    country_code: 'GW',
    country_en: 'Guinea-Bissau',
    country_cn: '几内亚比绍共和国',
    phone_code: 245,
  },
  {
    country_code: 'GH',
    country_en: 'Ghana',
    country_cn: '加纳',
    phone_code: 233,
  },
  {
    country_code: 'GA',
    country_en: 'Gabon',
    country_cn: '加蓬共和国',
    phone_code: 241,
  },
  {
    country_code: 'KH',
    country_en: 'Cambodia',
    country_cn: '柬埔寨',
    phone_code: 855,
  },
  {
    country_code: 'CZ',
    country_en: 'Czech Republic',
    country_cn: '捷克',
    phone_code: 420,
  },
  {
    country_code: 'ZW',
    country_en: 'Zimbabwe',
    country_cn: '津巴布韦',
    phone_code: 263,
  },
  {
    country_code: 'CM',
    country_en: 'Cameroon',
    country_cn: '喀麦隆',
    phone_code: 237,
  },
  {
    country_code: 'QA',
    country_en: 'Qatar',
    country_cn: '卡塔尔',
    phone_code: 974,
  },
  {
    country_code: 'KY',
    country_en: 'Cayman Islands',
    country_cn: '开曼群岛',
    phone_code: 1345,
  },
  {
    country_code: 'KM',
    country_en: 'Comoros',
    country_cn: '科摩罗',
    phone_code: 269,
  },
  {
    country_code: 'CI',
    country_en: "Côte d'Ivoire",
    country_cn: '科特迪瓦',
    phone_code: 225,
  },
  {
    country_code: 'KW',
    country_en: 'Kuwait',
    country_cn: '科威特',
    phone_code: 965,
  },
  {
    country_code: 'HR',
    country_en: 'Croatia',
    country_cn: '克罗地亚',
    phone_code: 385,
  },
  {
    country_code: 'KE',
    country_en: 'Kenya',
    country_cn: '肯尼亚',
    phone_code: 254,
  },
  {
    country_code: 'CK',
    country_en: 'Cook Islands',
    country_cn: '库克群岛',
    phone_code: 682,
  },
  {
    country_code: 'LV',
    country_en: 'Latvia',
    country_cn: '拉脱维亚',
    phone_code: 371,
  },
  {
    country_code: 'LS',
    country_en: 'Lesotho',
    country_cn: '莱索托',
    phone_code: 266,
  },
  {
    country_code: 'LA',
    country_en: 'Laos',
    country_cn: '老挝',
    phone_code: 856,
  },
  {
    country_code: 'LB',
    country_en: 'Lebanon',
    country_cn: '黎巴嫩',
    phone_code: 961,
  },
  {
    country_code: 'LT',
    country_en: 'Lithuania',
    country_cn: '立陶宛',
    phone_code: 370,
  },
  {
    country_code: 'LR',
    country_en: 'Liberia',
    country_cn: '利比里亚',
    phone_code: 231,
  },
  {
    country_code: 'LY',
    country_en: 'Libya',
    country_cn: '利比亞',
    phone_code: 218,
  },
  {
    country_code: 'LI',
    country_en: 'Liechtenstein',
    country_cn: '列支敦士登',
    phone_code: 423,
  },
  {
    country_code: 'RE',
    country_en: 'Réunion',
    country_cn: '留尼汪',
    phone_code: 262,
  },
  {
    country_code: 'LU',
    country_en: 'Luxembourg',
    country_cn: '卢森堡',
    phone_code: 352,
  },
  {
    country_code: 'RW',
    country_en: 'Rwanda',
    country_cn: '卢旺达',
    phone_code: 250,
  },
  {
    country_code: 'RO',
    country_en: 'Romania',
    country_cn: '罗马尼亚',
    phone_code: 40,
  },
  {
    country_code: 'MG',
    country_en: 'Madagascar',
    country_cn: '马达加斯加',
    phone_code: 261,
  },
  {
    country_code: 'MV',
    country_en: 'Maldives',
    country_cn: '马尔代夫',
    phone_code: 960,
  },
  {
    country_code: 'MT',
    country_en: 'Malta',
    country_cn: '马耳他',
    phone_code: 356,
  },
  {
    country_code: 'MW',
    country_en: 'Malawi',
    country_cn: '马拉维',
    phone_code: 265,
  },
  {
    country_code: 'MY',
    country_en: 'Malaysia',
    country_cn: '马来西亚',
    phone_code: 60,
  },
  {
    country_code: 'ML',
    country_en: 'Mali',
    country_cn: '马里',
    phone_code: 223,
  },
  {
    country_code: 'MK',
    country_en: 'Macedonia',
    country_cn: '马其顿',
    phone_code: 389,
  },
  {
    country_code: 'MH',
    country_en: 'Marshall Islands',
    country_cn: '马绍尔群岛',
    phone_code: 692,
  },
  {
    country_code: 'MQ',
    country_en: 'Martinique',
    country_cn: '马提尼克',
    phone_code: 596,
  },
  {
    country_code: 'YT',
    country_en: 'Mayotte',
    country_cn: '马约特',
    phone_code: 262,
  },
  {
    country_code: 'MU',
    country_en: 'Mauritius',
    country_cn: '毛里求斯',
    phone_code: 230,
  },
  {
    country_code: 'MR',
    country_en: 'Mauritania',
    country_cn: '毛里塔尼亚',
    phone_code: 222,
  },
  {
    country_code: 'US',
    country_en: 'United States',
    country_cn: '美国',
    phone_code: 1,
  },
  {
    country_code: 'MN',
    country_en: 'Mongolia',
    country_cn: '蒙古',
    phone_code: 976,
  },
  {
    country_code: 'MS',
    country_en: 'Montserrat',
    country_cn: '蒙特塞拉特',
    phone_code: 1664,
  },
  {
    country_code: 'BD',
    country_en: 'Bangladesh',
    country_cn: '孟加拉国',
    phone_code: 880,
  },
  {
    country_code: 'PE',
    country_en: 'Peru',
    country_cn: '秘鲁',
    phone_code: 51,
  },
  {
    country_code: 'FM',
    country_en: 'Federated States of Micronesia',
    country_cn: '密克罗尼西亚联邦',
    phone_code: 691,
  },
  {
    country_code: 'MM',
    country_en: 'Myanmar',
    country_cn: '缅甸',
    phone_code: 95,
  },
  {
    country_code: 'MD',
    country_en: 'Moldova',
    country_cn: '摩尔多瓦',
    phone_code: 373,
  },
  {
    country_code: 'MA',
    country_en: 'Morocco',
    country_cn: '摩洛哥',
    phone_code: 212,
  },
  {
    country_code: 'MC',
    country_en: 'Monaco',
    country_cn: '摩纳哥',
    phone_code: 377,
  },
  {
    country_code: 'MZ',
    country_en: 'Mozambique',
    country_cn: '莫桑比克',
    phone_code: 258,
  },
  {
    country_code: 'MX',
    country_en: 'Mexico',
    country_cn: '墨西哥',
    phone_code: 52,
  },
  {
    country_code: 'NA',
    country_en: 'Namibia',
    country_cn: '纳米比亚',
    phone_code: 264,
  },
  {
    country_code: 'ZA',
    country_en: 'South Africa',
    country_cn: '南非',
    phone_code: 27,
  },
  {
    country_code: 'SS',
    country_en: 'South Sudan',
    country_cn: '南苏丹',
    phone_code: 211,
  },
  {
    country_code: 'NR',
    country_en: 'Nauru',
    country_cn: '瑙鲁',
    phone_code: 674,
  },
  {
    country_code: 'NI',
    country_en: 'Nicaragua',
    country_cn: '尼加拉瓜',
    phone_code: 505,
  },
  {
    country_code: 'NP',
    country_en: 'Nepal',
    country_cn: '尼泊尔',
    phone_code: 977,
  },
  {
    country_code: 'NE',
    country_en: 'Niger',
    country_cn: '尼日尔',
    phone_code: 227,
  },
  {
    country_code: 'NG',
    country_en: 'Nigeria',
    country_cn: '尼日利亚',
    phone_code: 234,
  },
  {
    country_code: 'NU',
    country_en: 'Niue',
    country_cn: '纽埃',
    phone_code: 683,
  },
  {
    country_code: 'NO',
    country_en: 'Norway',
    country_cn: '挪威',
    phone_code: 47,
  },
  {
    country_code: 'NF',
    country_en: 'Norfolk Island',
    country_cn: '诺福克群岛',
    phone_code: 672,
  },
  {
    country_code: 'PT',
    country_en: 'Portugal',
    country_cn: '葡萄牙',
    phone_code: 351,
  },
  {
    country_code: 'JP',
    country_en: 'Japan',
    country_cn: '日本',
    phone_code: 81,
  },
  {
    country_code: 'SE',
    country_en: 'Sweden',
    country_cn: '瑞典',
    phone_code: 46,
  },
  {
    country_code: 'CH',
    country_en: 'Switzerland',
    country_cn: '瑞士',
    phone_code: 41,
  },
  {
    country_code: 'SV',
    country_en: 'El Salvador',
    country_cn: '萨尔瓦多',
    phone_code: 503,
  },
  {
    country_code: 'WS',
    country_en: 'Samoa',
    country_cn: '萨摩亚',
    phone_code: 685,
  },
  {
    country_code: 'RS',
    country_en: 'Serbia',
    country_cn: '塞尔维亚',
    phone_code: 381,
  },
  {
    country_code: 'SL',
    country_en: 'Sierra Leone',
    country_cn: '塞拉利昂',
    phone_code: 232,
  },
  {
    country_code: 'SN',
    country_en: 'Senegal',
    country_cn: '塞内加尔',
    phone_code: 221,
  },
  {
    country_code: 'CY',
    country_en: 'Cyprus',
    country_cn: '塞浦路斯',
    phone_code: 357,
  },
  {
    country_code: 'SC',
    country_en: 'Seychelles',
    country_cn: '塞舌尔',
    phone_code: 248,
  },
  {
    country_code: 'SA',
    country_en: 'Saudi Arabia',
    country_cn: '沙特阿拉伯',
    phone_code: 966,
  },
  {
    country_code: 'ST',
    country_en: 'Sao Tome and Principe',
    country_cn: '圣多美和普林西比民主共和国',
    phone_code: 239,
  },
  {
    country_code: 'SH',
    country_en: 'Saint Helena',
    country_cn: '圣赫勒拿',
    phone_code: 290,
  },
  {
    country_code: 'KN',
    country_en: 'Saint Kitts and Nevis',
    country_cn: '圣基茨和尼维斯',
    phone_code: 1869,
  },
  {
    country_code: 'LC',
    country_en: 'Saint Lucia',
    country_cn: '圣卢西亚',
    phone_code: 1758,
  },
  {
    country_code: 'SM',
    country_en: 'San Marino',
    country_cn: '圣马力诺',
    phone_code: 378,
  },
  {
    country_code: 'VC',
    country_en: 'Saint Vincent and the Grenadines',
    country_cn: '圣文森特和格林纳丁斯',
    phone_code: 1784,
  },
  {
    country_code: 'LK',
    country_en: 'Sri Lanka',
    country_cn: '斯里兰卡',
    phone_code: 94,
  },
  {
    country_code: 'SK',
    country_en: 'Slovakia',
    country_cn: '斯洛伐克',
    phone_code: 421,
  },
  {
    country_code: 'SI',
    country_en: 'Slovenia',
    country_cn: '斯洛文尼亚',
    phone_code: 386,
  },
  {
    country_code: 'SZ',
    country_en: 'Swaziland',
    country_cn: '斯威士兰',
    phone_code: 268,
  },
  {
    country_code: 'SD',
    country_en: 'Sudan',
    country_cn: '苏丹',
    phone_code: 249,
  },
  {
    country_code: 'SR',
    country_en: 'Suriname',
    country_cn: '苏里南',
    phone_code: 597,
  },
  {
    country_code: 'SB',
    country_en: 'Solomon Islands',
    country_cn: '所罗门群岛',
    phone_code: 677,
  },
  {
    country_code: 'SO',
    country_en: 'Somalia',
    country_cn: '索马里',
    phone_code: 252,
  },
  {
    country_code: 'TJ',
    country_en: 'Tajikistan',
    country_cn: '塔吉克斯坦',
    phone_code: 992,
  },
  {
    country_code: 'TW',
    country_en: 'Taiwan',
    country_cn: '台湾',
    phone_code: 886,
  },
  {
    country_code: 'TH',
    country_en: 'Thailand',
    country_cn: '泰国',
    phone_code: 66,
  },
  {
    country_code: 'TZ',
    country_en: 'Tanzania',
    country_cn: '坦桑尼亚',
    phone_code: 255,
  },
  {
    country_code: 'TO',
    country_en: 'Tonga',
    country_cn: '汤加',
    phone_code: 676,
  },
  {
    country_code: 'TC',
    country_en: 'Turks and Caicos Islands',
    country_cn: '特克斯和凯科斯群岛',
    phone_code: 1649,
  },
  {
    country_code: 'TT',
    country_en: 'Trinidad and Tobago',
    country_cn: '特立尼达和多巴哥',
    phone_code: 1868,
  },
  {
    country_code: 'TN',
    country_en: 'Tunisia',
    country_cn: '突尼斯',
    phone_code: 216,
  },
  {
    country_code: 'TV',
    country_en: 'Tuvalu',
    country_cn: '图瓦卢',
    phone_code: 688,
  },
  {
    country_code: 'TR',
    country_en: 'Turkey',
    country_cn: '土耳其',
    phone_code: 90,
  },
  {
    country_code: 'TM',
    country_en: 'Turkmenistan',
    country_cn: '土库曼斯坦',
    phone_code: 993,
  },
  {
    country_code: 'TK',
    country_en: 'Tokelau',
    country_cn: '托克劳',
    phone_code: 690,
  },
  {
    country_code: 'WF',
    country_en: 'Wallis and Futuna',
    country_cn: '瓦利斯和富图纳',
    phone_code: 681,
  },
  {
    country_code: 'VU',
    country_en: 'Vanuatu',
    country_cn: '瓦努阿图',
    phone_code: 678,
  },
  {
    country_code: 'GT',
    country_en: 'Guatemala',
    country_cn: '危地马拉',
    phone_code: 502,
  },
  {
    country_code: 'VE',
    country_en: 'Venezuela',
    country_cn: '委内瑞拉',
    phone_code: 58,
  },
  {
    country_code: 'BN',
    country_en: 'Brunei',
    country_cn: '文莱',
    phone_code: 673,
  },
  {
    country_code: 'UG',
    country_en: 'Uganda',
    country_cn: '乌干达',
    phone_code: 256,
  },
  {
    country_code: 'UA',
    country_en: 'Ukraine',
    country_cn: '乌克兰',
    phone_code: 380,
  },
  {
    country_code: 'UY',
    country_en: 'Uruguay',
    country_cn: '乌拉圭',
    phone_code: 598,
  },
  {
    country_code: 'UZ',
    country_en: 'Uzbekistan',
    country_cn: '乌兹别克斯坦',
    phone_code: 998,
  },
  {
    country_code: 'GR',
    country_en: 'Greece',
    country_cn: '希腊',
    phone_code: 30,
  },
  {
    country_code: 'ES',
    country_en: 'Spain',
    country_cn: '西班牙',
    phone_code: 34,
  },
  {
    country_code: 'HK',
    country_en: 'Hong Kong',
    country_cn: '中国香港特别行政区',
    phone_code: 852,
  },
  {
    country_code: 'SG',
    country_en: 'Singapore',
    country_cn: '新加坡',
    phone_code: 65,
  },
  {
    country_code: 'NC',
    country_en: 'New Caledonia',
    country_cn: '新喀里多尼亚',
    phone_code: 687,
  },
  {
    country_code: 'NZ',
    country_en: 'New Zealand',
    country_cn: '新西兰',
    phone_code: 64,
  },
  {
    country_code: 'HU',
    country_en: 'Hungary',
    country_cn: '匈牙利',
    phone_code: 36,
  },
  {
    country_code: 'SY',
    country_en: 'Syria',
    country_cn: '叙利亚',
    phone_code: 963,
  },
  {
    country_code: 'JM',
    country_en: 'Jamaica',
    country_cn: '牙买加',
    phone_code: 1876,
  },
  {
    country_code: 'AM',
    country_en: 'Armenia',
    country_cn: '亚美尼亚',
    phone_code: 374,
  },
  {
    country_code: 'YE',
    country_en: 'Yemen',
    country_cn: '也门',
    phone_code: 967,
  },
  {
    country_code: 'IQ',
    country_en: 'Iraq',
    country_cn: '伊拉克',
    phone_code: 964,
  },
  {
    country_code: 'IR',
    country_en: 'Iran',
    country_cn: '伊朗',
    phone_code: 98,
  },
  {
    country_code: 'IL',
    country_en: 'Israel',
    country_cn: '以色列',
    phone_code: 972,
  },
  {
    country_code: 'IT',
    country_en: 'Italy',
    country_cn: '意大利',
    phone_code: 39,
  },
  {
    country_code: 'IN',
    country_en: 'India',
    country_cn: '印度',
    phone_code: 91,
  },
  {
    country_code: 'ID',
    country_en: 'Indonesia',
    country_cn: '印度尼西亚',
    phone_code: 62,
  },
  {
    country_code: 'GB',
    country_en: 'United Kingdom',
    country_cn: '英国',
    phone_code: 44,
  },
  {
    country_code: 'JO',
    country_en: 'Jordan',
    country_cn: '约旦',
    phone_code: 962,
  },
  {
    country_code: 'VN',
    country_en: 'Vietnam',
    country_cn: '越南',
    phone_code: 84,
  },
  {
    country_code: 'ZM',
    country_en: 'Zambia',
    country_cn: '赞比亚',
    phone_code: 260,
  },
  {
    country_code: 'TD',
    country_en: 'Chad',
    country_cn: '乍得',
    phone_code: 235,
  },
  {
    country_code: 'GI',
    country_en: 'Gibraltar',
    country_cn: '直布罗陀',
    phone_code: 350,
  },
  {
    country_code: 'CL',
    country_en: 'Chile',
    country_cn: '智利',
    phone_code: 56,
  },
  {
    country_code: 'CF',
    country_en: 'Central African Republic',
    country_cn: '中非共和国',
    phone_code: 236,
  },
  {
    country_code: 'CN',
    country_en: 'China',
    country_cn: '中国',
    phone_code: 86,
  },
];
