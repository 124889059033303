import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { TooltipItem } from 'components/common/TooltipItem';
import { Input } from 'components/ui/input';
import {
  useGetProject,
  useProjectDataCtx,
  useRenameProjectMutation,
} from 'pages/ai-scene/create/helpers';
import { useLayoutEffect, useRef } from 'react';
import { toast } from 'sonner';
import invariant from 'tiny-invariant';

export const ProjectNameInput = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const activeProjectTitle = useAiSceneCreateStore((state) => state.activeProjectTitle);
  const updateActiveProjectTitle = useAiSceneCreateStore((state) => state.updateActiveProjectTitle);
  const renameScene = useRenameProjectMutation();

  const queryClient = useQueryClient();

  const projectBase = useGetProject({
    staleTime: Infinity,
    gcTime: 0,
  });
  const project = projectBase.data;

  const handleBlur = () => {
    if (!inputRef.current) return;
    invariant(project, 'Project ID is required to rename the project');

    const projectName = inputRef.current.value;
    renameScene.mutate(
      { projectId: project.id, projectName },
      {
        onSuccess: () => {
          updateActiveProjectTitle(projectName);
          queryClient.invalidateQueries({
            queryKey: [{ searchSection: 'projects' }],
            exact: false,
          });
        },
        onError: () => {
          toast.error('Failed to rename the project');
          if (activeProjectTitle && inputRef.current) {
            inputRef.current.value = activeProjectTitle || project.name || 'Untitled';
          }
        },
      },
    );
  };

  useLayoutEffect(() => {
    if (!inputRef.current || !activeProjectTitle || inputRef.current.value === activeProjectTitle) {
      return;
    }

    inputRef.current.value = activeProjectTitle;
  }, [activeProjectTitle]);

  if (!project) return null;

  return (
    <TooltipItem
      side='bottom'
      trigger={
        <div className='flex h-full w-[200px] items-center rounded-md border border-[#EAEAEA] bg-white text-xs'>
          <Input
            defaultValue={project.name}
            ref={inputRef}
            className='h-full w-full truncate border-none bg-transparent p-0 px-2 text-xs leading-none'
            onBlur={handleBlur}
          />
        </div>
      }
    >
      Edit Title
    </TooltipItem>
  );
};
