import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { FC } from 'react';
import { TBrandStyleResponse } from '../../helper/queries';
import { Modal } from 'components/ui/modal';

import ZoomIcon from '@/assets/icons/ai-scene-icons/zoom.svg?react';
import { CraitButton } from 'components/common/CraitButton';
import { Separator } from 'components/ui/separator';
import { BasicCard } from '../../visual-guidance/BasicCard';

interface BrandStyleItemsProps {
  brandStyles: TBrandStyleResponse[];
  brandStyleId: string | null;
}

export const BrandStyleItems: FC<BrandStyleItemsProps> = ({ brandStyles, brandStyleId }) => {
  const updateAiScenePromptTab = useAiSceneCreateStore((state) => state.updateAiScenePromptTab);

  const hasAnyStyles = brandStyles.length > 0;
  if (!hasAnyStyles) return <span className='text-[11px]'>No brand styles found.</span>;

  const selectedStyle = brandStyleId
    ? brandStyles.find((style) => style.id === brandStyleId)
    : null;

  return (
    <>
      {selectedStyle && (
        <BasicCard
          key={selectedStyle.id}
          name={selectedStyle.name}
          image={selectedStyle.thumbnail}
          onClick={() => {
            updateAiScenePromptTab({ brandStyleId: selectedStyle });
          }}
          imageWrapperClass={`h-[112px] outline outline-[4px] outline-[#80D962] hover:outline-[#80D962] hover:outline-[4px]`}
          contentClass='!w-[112px]'
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className='absolute right-1 top-1 z-10 flex size-6 cursor-pointer items-center justify-center rounded-full bg-[#212121] bg-opacity-50'
          >
            <Modal
              trigger={<ZoomIcon />}
              titleElement={selectedStyle.name}
              modalContentClassName='max-w-[90%] max-h-[90%] rounded-[8px] w-auto'
            >
              {({ closeModal }) => (
                <div className='flex flex-col gap-3 pb-3 pt-8'>
                  <div className='px-3'>
                    <img
                      src={selectedStyle.url}
                      className='h-[500px] w-[500px] rounded object-contain'
                    />
                  </div>
                  <Separator />
                  <div className='mx-3'>
                    <CraitButton
                      onClick={() => {
                        updateAiScenePromptTab({ brandStyleId: selectedStyle });
                        closeModal();
                      }}
                    >
                      Select
                    </CraitButton>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </BasicCard>
      )}
      {brandStyles.map((style) => (
        <BasicCard
          key={style.id}
          name={style.name}
          image={style.thumbnail}
          onClick={() => {
            updateAiScenePromptTab({ brandStyleId: style });
          }}
          imageWrapperClass={`h-[112px]`}
          contentClass='!w-[112px]'
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className='absolute right-1 top-1 z-10 flex size-6 cursor-pointer items-center justify-center rounded-full bg-[#212121] bg-opacity-50'
          >
            <Modal
              trigger={<ZoomIcon />}
              titleElement={style.name}
              modalContentClassName='max-w-[90%] max-h-[90%] rounded-[8px] w-auto'
            >
              {({ closeModal }) => (
                <div className='flex flex-col gap-3 pb-3 pt-8'>
                  <div className='px-3'>
                    <img src={style.url} className='h-[500px] w-[500px] rounded object-contain' />
                  </div>
                  <Separator />
                  <div className='mx-3'>
                    <CraitButton
                      onClick={() => {
                        updateAiScenePromptTab({ brandStyleId: style });
                        closeModal();
                      }}
                    >
                      Select
                    </CraitButton>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </BasicCard>
      ))}
    </>
  );
};
