import { FC } from 'react';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';
import { ProductPhotoButton } from './_components/ProductPhotoButton';
import { ProductPhotoMenu } from '../side-menu/create-tool/product-photo/ProductPhotoMenu';
import { useIsAiImageType } from 'pages/ai-scene/useIsAiImageType';
import { useMobileBottomMenuStore } from './useMobileBottomMenuStore';
import { PromptInputWithPopover } from './_components/PromptInputWithPopover';
import { MENU_SHEET_NAMES, menuItemsConfig } from './constants';
import { GenerationButton } from '../side-menu/create-tool/GenerationButton';

const BottomSheetMenuItem: FC<{
  label: string;
  Icon: React.ComponentType<any>;
  onClick?: () => void;
  isSelected: boolean;
}> = ({ label, Icon, onClick, isSelected }) => (
  <div
    onClick={onClick}
    className={`flex h-[60px] flex-col items-center justify-center ${
      isSelected ? 'text-black' : 'text-gray-500'
    }`}
  >
    <Icon className={`h-5 w-5 ${isSelected ? 'stroke-black' : 'stroke-crait-gray'}`} />
    <span className='text-xs'>{label}</span>
  </div>
);

export const MobileCreateSceneBottomMenu: FC = () => {
  const activeSheet = useMobileBottomMenuStore((state) => state.activeSheet);
  const setActiveSheet = useMobileBottomMenuStore((state) => state.setActiveSheet);

  const isAiImageType = useIsAiImageType();

  const handleMenuClick = (sheetName: string | null) => {
    if (activeSheet === sheetName) {
      setActiveSheet(null);
    } else {
      setActiveSheet(sheetName);
    }
  };

  return (
    <>
      <div className='fixed bottom-0 left-0 right-0 z-50 flex flex-col gap-2 bg-white shadow-md'>
        <div className='flex flex-col gap-2 p-2'>
          {!isAiImageType && (
            <MobileMenuSheet
              isOpen={activeSheet === MENU_SHEET_NAMES.PRODUCT}
              triggerButton={<ProductPhotoButton />}
              onOpenChange={() => handleMenuClick(MENU_SHEET_NAMES.PRODUCT)}
              titleElement={'Product Photo'}
              contentClassName='max-h-[84vh]'
            >
              <ProductPhotoMenu />
            </MobileMenuSheet>
          )}

          <PromptInputWithPopover activeSheet={activeSheet} setActiveSheet={setActiveSheet} />

          <GenerationButton />
        </div>

        <div className='flex h-[60px] items-center justify-around gap-8 overflow-x-auto overflow-y-hidden border-t-[1px] px-6'>
          {menuItemsConfig.map(({ label, icon: Icon, sheetName, component, title }) => (
            <MobileMenuSheet
              key={sheetName}
              triggerButton={
                <BottomSheetMenuItem
                  label={label}
                  Icon={Icon}
                  isSelected={activeSheet === sheetName}
                />
              }
              onOpenChange={() => handleMenuClick(sheetName)}
              titleElement={title}
              isOpen={activeSheet === sheetName}
              contentClassName='max-h-[84vh]'
            >
              {component}
            </MobileMenuSheet>
          ))}
        </div>
      </div>
    </>
  );
};
