import { useSearchParams } from 'react-router-dom';

export const useCategoryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeCategory = searchParams.get('category');

  const setCategoryParam = (category: string) => {
    setSearchParams({ category });
  };

  return [activeCategory, setCategoryParam] as const;
};
