import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { ProductCreateContext } from './helper';
import { TProductCreateStore } from './store';

export const useProductCreateStore = <T,>(
  selector: (state: TProductCreateStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(ProductCreateContext);
  if (!store) throw new Error('Missing ProductCreateContext.Provider in the tree');

  return useStoreWithEqualityFn(store, selector, equalityFn);
};
