import { Navigate, Outlet, useParams } from 'react-router';
import { useCraitStore } from '@/utils/store/store';
import { useBulkProductsStore } from './provider/helper';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { useEffect } from 'react';

export const BulkProductsPage = () => {
  const uploadedInitialProducts = useCraitStore((state) => state.uploadedBulkProducts);
  const setUploadedBulkProducts = useCraitStore((state) => state.setUploadedBulkProducts);
  const products = useBulkProductsStore((state) => state.products);
  const setProducts = useBulkProductsStore((state) => state.setProducts);
  const { jobId } = useParams();

  useEffect(() => {
    if (!uploadedInitialProducts || uploadedInitialProducts.length < 1) return;

    setProducts(uploadedInitialProducts);
    setUploadedBulkProducts(undefined);
  }, [uploadedInitialProducts, setProducts, setUploadedBulkProducts]);

  const hasAnyInitialProducts = uploadedInitialProducts && uploadedInitialProducts.length > 0;
  const hasAnyProducts = hasAnyInitialProducts || products.length > 0;
  if (!jobId || !hasAnyProducts) {
    return <Navigate to={`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`} replace />;
  }

  return <Outlet />;
};
