import React, { useState } from 'react';
import { TPaymentProduct } from '../queries';
import { Input } from 'components/ui/input';
import { useApplyPromotionCodeMutation } from '../queries';
import { useSubscriptionStore } from '@/providers/stripe/subscription/helper';
import { Button } from 'components/ui/button';
import { Check } from 'lucide-react';
import { toast } from 'sonner';

interface PaymentSummaryProps {
  planType: string;
  period: string;
  paymentPlans: TPaymentProduct[] | undefined;
}

const UNLIMITED_PRODUCT_ASSETS_KEY = 'Unlimited product assets';
const PRODUCT_ASSETS_KEY = 'product assets';

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  planType,
  period,
  paymentPlans,
}) => {
  if (!paymentPlans) return null;

  const subscriptionId = useSubscriptionStore((state) => state.subscriptionId);
  const [promotionCode, setPromotionCode] = useState('');
  const [isPromotionValid, setIsPromotionValid] = useState<boolean | null>(null);
  const [totalDue, setTotalDue] = useState<number | null>(null);
  const applyPromotionMutation = useApplyPromotionCodeMutation();

  const selectedPaymentPlan = paymentPlans.find(
    (plan) => plan.type === planType && plan.prices.some((price) => price.paymentPeriod === period),
  );

  const selectedPrice = selectedPaymentPlan?.prices.find((price) => price.paymentPeriod === period);

  if (!selectedPaymentPlan || !selectedPrice) {
    return <h1>Plan details not found</h1>;
  }

  const handleApplyPromotion = () => {
    if (!promotionCode || !subscriptionId || !selectedPrice.priceId) return;
    setIsPromotionValid(null);
    applyPromotionMutation.mutate(
      {
        subscriptionId: subscriptionId,
        priceId: selectedPrice.priceId,
        promotionCode: promotionCode,
      },
      {
        onSuccess: (data) => {
          if (data.isPromotionValid) {
            setIsPromotionValid(true);
            setTotalDue(data.discountedPriceInCents / 100);
          } else {
            setIsPromotionValid(false);
          }
        },
        onError: () => {
          toast.error('Error applying promotion');
        },
      },
    );
  };

  const handlePromotionCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromotionCode(event.target.value);
    if (isPromotionValid !== null) {
      setIsPromotionValid(null);
    }
  };

  const planName = `${selectedPaymentPlan.name} Plan ${
    period.charAt(0).toUpperCase() + period.slice(1)
  }`;
  const planPrice = selectedPrice.price;

  const credits = selectedPaymentPlan.benefits.existingBenefits.find(
    (benefit) => benefit.match(/\d+/)?.[0] || '0',
  );

  const productAssets = selectedPaymentPlan.benefits.existingBenefits.includes(
    UNLIMITED_PRODUCT_ASSETS_KEY,
  )
    ? UNLIMITED_PRODUCT_ASSETS_KEY
    : selectedPaymentPlan.benefits.existingBenefits.find((benefit) =>
        benefit.includes(PRODUCT_ASSETS_KEY),
      ) || PRODUCT_ASSETS_KEY;

  return (
    <>
      <div className='mb-4 flex items-center justify-between'>
        <h2 className='text-[13px] font-semibold'>{planName}</h2>
        <span className='text-[13px] font-semibold'>{planPrice}</span>
      </div>
      <div className='mb-4 text-[12px]'>
        <p className='capitalize'>{credits}</p>
        <p className='capitalize italic'>{productAssets}</p>
      </div>
      <div className='mb-3 flex items-center justify-between border-t pt-2 text-[13px] font-semibold'>
        <span>Subtotal</span>
        <span className='text-[13px] font-semibold'>{planPrice}</span>
      </div>
      <div className='flex h-[40px] flex-col justify-start gap-3 pt-2 text-[13px] font-semibold'>
        <div className='flex w-full items-center gap-2 '>
          <Input
            type='search'
            placeholder='Add promotion code'
            className={`flex-1 rounded-[6px] bg-white text-[13px] font-normal focus-visible:ring-0 ${
              isPromotionValid === false
                ? 'border-red-500 text-red-500'
                : isPromotionValid === true
                  ? 'border-green-500 text-green-500'
                  : ''
            }`}
            value={promotionCode}
            onChange={handlePromotionCodeChange}
            disabled={isPromotionValid === true}
          />
          <Button
            disabled={
              applyPromotionMutation.isPending || isPromotionValid === true || promotionCode === ''
            }
            variant='outline'
            size='sm'
            className='h-full border-[#f2f2f2] bg-crait-green-primary'
            onClick={handleApplyPromotion}
          >
            <Check className='h-4 w-4' />
          </Button>
        </div>
        {isPromotionValid === false && (
          <p className='-mt-2 text-left text-main font-medium text-red-500'>
            This code is invalid.
          </p>
        )}
        <div className='flex w-full justify-between'>
          <span>Total due</span>
          <span className='text-[13px] font-semibold'>
            {totalDue !== null ? `$${totalDue}` : `${planPrice}`}
          </span>
        </div>
      </div>
    </>
  );
};
