import { create } from 'zustand';

interface GenerationSceneStatusState {
  isGenerating: boolean;
  setIsGenerating: (value: boolean) => void;
}

export const useGenerationSceneStatusStore = create<GenerationSceneStatusState>((set) => ({
  isGenerating: false,
  setIsGenerating: (value: boolean) => set({ isGenerating: value }),
}));