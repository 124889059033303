import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Separator } from 'components/ui/separator';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import { useUploadProduct } from 'pages/workspace/brand-library/products/helper';
import { useUploadFileMutation } from 'pages/workspace/brand-library/product-edit/helper';
import {
  useCreateProductCategoryMutation,
  useGetProductCategories,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { ListSearchWithCreateButton } from '../products-edit/ListSearchWithCreateButton';
import { ProductCaptionInput } from '../products/ProductCaptionInput';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { ImageType } from '@/utils/store/constants';
import { scaleAndConvertFabricImageObjectToFile } from '@/utils/helper';
import invariant from 'tiny-invariant';
import { useProductProperties } from '../products/useProductProperties ';

const PRODUCT_BASE_ROUTE = `/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`;

const ProductField = {
  SKU: 'productCode',
  TITLE: 'productName',
} as const;

export type TDerivedCreateState = {
  originalImage: string;
  transparentImage: string;
} | null;

export const ProductCreateBox = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const skuRef = useRef<HTMLInputElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const canvasInstance = useProductCreateStore((state) => state.canvasInstance);
  const uploadProduct = useUploadProduct();
  const uploadFile = useUploadFileMutation();
  const { data: categories, refetch: refetchCategories } = useGetProductCategories();
  const createProductCategoryMutation = useCreateProductCategoryMutation();
  const queryClient = useQueryClient();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>();
  const { productProperties, handleCaptionChange, setProductProperties } = useProductProperties();

  const derivedState = state as TDerivedCreateState;

  if (!derivedState?.originalImage || !derivedState?.transparentImage) {
    return <Navigate to={PRODUCT_BASE_ROUTE} />;
  }

  const categoryOptions = useMemo(() => {
    return categories?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categories]);

  const navigateBack = () => {
    navigate(PRODUCT_BASE_ROUTE);
  };

  const createProduct = async () => {
    if (!canvasInstance || !derivedState) return;

    if (!titleRef.current?.value) {
      toast.error('Please fill "Product Name" field');
      return;
    }

    try {
      const transparentImage = canvasInstance
        .getObjects()
        .find((obj): obj is fabric.Image => obj.imageType === ImageType.TRANSPARENT);

      invariant(transparentImage, 'Transparent image not found');

      transparentImage.set({ visible: true });
      canvasInstance.requestRenderAll();

      const transparentImageAsFile = await scaleAndConvertFabricImageObjectToFile(
        transparentImage,
        'transparent.png',
      );

      const transparentImageUrl = await uploadFile.mutateAsync({ file: transparentImageAsFile });

      await uploadProduct.mutateAsync({
        productCode: skuRef.current?.value || '',
        productName: titleRef.current?.value,
        originalImage: derivedState.originalImage,
        transparentImage: transparentImageUrl,
        categoryId: selectedCategoryId || null,
        productProperties,
      });

      queryClient.invalidateQueries({ queryKey: [{ searchSection: 'products' }], exact: false });
      toast.success('Product created');
      navigateBack();
    } catch (error) {
      console.error('Error creating product', error);
      toast.error('Failed to upload product');
    }
  };

  const handleCategoryCreate = async (name: string) => {
    try {
      const newCategory = await createProductCategoryMutation.mutateAsync(name);
      toast.success('Category created');
      refetchCategories();
      setSelectedCategoryId(newCategory.id);
    } catch (error) {
      console.error('Error creating category', error);
      toast.error('Failed to create category');
    }
  };

  return (
    <div className='flex w-[300px] gap-2 rounded-lg bg-white px-3 pb-2 pt-3'>
      <div className='flex flex-col gap-3'>
        <div className='flex gap-2'>
          <div className='flex w-1/3 flex-col gap-2'>
            <Label htmlFor={ProductField.SKU} className='text-xs'>
              SKU
            </Label>
            <Input ref={skuRef} id={ProductField.SKU} name={ProductField.SKU} className='text-xs' />
          </div>
          <div className='flex w-2/3 flex-col gap-2'>
            <Label htmlFor={ProductField.TITLE} className='text-xs'>
              Product Name
            </Label>
            <Input
              ref={titleRef}
              id={ProductField.TITLE}
              name={ProductField.TITLE}
              className='text-xs'
            />
          </div>
        </div>
        <ProductCaptionInput
          transparentImage={derivedState.transparentImage}
          caption={productProperties.caption || ''}
          onCaptionChange={handleCaptionChange}
          setProductProperties={setProductProperties}
        />
        <p className='text-xs font-medium'>Category name</p>
        <ListSearchWithCreateButton
          options={categoryOptions || []}
          controlledValue={selectedCategoryId}
          onItemSelect={(option) => setSelectedCategoryId(option.value)}
          onCategoryCreate={handleCategoryCreate}
        />
        <Separator />
        <div className='flex flex-col gap-2'>
          <Button
            className='flex'
            onClick={createProduct}
            disabled={uploadFile.isPending || uploadProduct.isPending}
          >
            Save
          </Button>
          <Button className='flex' variant='outline' onClick={navigateBack}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
