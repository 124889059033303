import { UndoRedoProductCreateCanvas } from './canvas/UndoRedoProductCreateCanvas';
import { BgToggleView } from './canvas/BgToggleView';
import { FC, ReactNode } from 'react';
import { BrushView } from './canvas/BrushView';

interface ImageWrapperViewProps {
  children: ReactNode;
  canvasView: ReactNode;
}

export const ImageWrapperView: FC<ImageWrapperViewProps> = ({ children, canvasView }) => {
  return (
    <section className='relative m-auto h-full'>
      <div className='flex h-full flex-col items-center gap-6 overflow-y-auto px-4 pb-4 md:px-0'>
        <div className='flex items-center justify-between gap-4 md:gap-0'>
          <BgToggleView />
          <BrushView />
          <UndoRedoProductCreateCanvas />
        </div>
        <div className='relative flex max-h-[650px] w-full items-center justify-center'>
          {canvasView}
        </div>
        {children}
      </div>
    </section>
  );
};
