import { cn } from '@/lib/utils';
import React, { FC, useRef, useState } from 'react';

interface DragAndDropProps {
  onFileDrop: (file: File) => void;
}

export const DragAndDrop: FC<DragAndDropProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onFileDrop,
  ...props
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dropAreaRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    if (dropAreaRef.current && !dropAreaRef.current.contains(e.relatedTarget as Node)) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files ? e.dataTransfer.files[0] : null;
    if (!file) return;

    onFileDrop(file);
  };

  return (
    <div
      {...props}
      ref={dropAreaRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={cn(
        'flex flex-col items-center justify-center gap-1 rounded-lg border-2 border-dashed bg-gray-100',
        {
          'border-gray-200': !isDragging,
          'border-crait-green-primary': isDragging,
          'border-[3px]': isDragging,
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
