import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { ApplyDimension } from './ApplyDimension';
import { FC } from 'react';
import { useCanvasDimensionHandler } from './useCanvasDimensionHandler';
import { useCreateLayoutSizeMutation } from './queries';

interface CustomSizeMenuProps {
  onBackClick: () => void;
}

export const CustomSizeMenu: FC<CustomSizeMenuProps> = ({ onBackClick }) => {
  const { onDimensionSelected } = useCanvasDimensionHandler();
  const createLayoutSizeMutation = useCreateLayoutSizeMutation();

  const handleDimensionApplied = (props: {
    width: number;
    height: number;
    name: string;
    isCustomSize: boolean;
    resetInputs: () => void;
  }) => {
    const isApplied = onDimensionSelected(props.width, props.height);
    if (!isApplied) return;
    if (props.isCustomSize) {
      createLayoutSizeMutation.mutate({
        name: props.name,
        width: props.width,
        height: props.height,
      });
    }
    props.resetInputs();
    onBackClick();
  };

  return (
    <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md md:w-[98vw] md:shadow-none'>
      <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold'>
        <ArrowLeftIcon />
        Custom Size
      </button>
      <Separator />
      <ApplyDimension onDimensionApplied={handleDimensionApplied} />
    </div>
  );
};
