import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { TStyleSelection } from '@/providers/ai-banner/create/store';

const StyleOption = {
  ORGANIC: 'organic',
  BALANCED: 'balanced',
  ADVERT: 'advert',
} as const;

export const StyleContent = () => {
  const setStyle = useBannerCreateStore((s) => s.setStyle);

  return (
    <div>
      <RadioGroup
        onValueChange={(value: TStyleSelection) => {
          setStyle(value);
        }}
        className='text-sm'
      >
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={StyleOption.ORGANIC}
            id={StyleOption.ORGANIC}
            className='border border-white text-white'
          />
          <Label htmlFor={StyleOption.ORGANIC} className='text-md'>
            Organic
          </Label>
        </div>
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={StyleOption.BALANCED}
            id={StyleOption.BALANCED}
            className='border border-white text-white'
          />
          <Label htmlFor={StyleOption.BALANCED} className='text-md'>
            Balanced
          </Label>
        </div>
        <div className='flex items-center space-x-2'>
          <RadioGroupItem
            value={StyleOption.ADVERT}
            id={StyleOption.ADVERT}
            className='border border-white text-white'
          />
          <Label htmlFor={StyleOption.ADVERT} className='text-md'>
            Advert
          </Label>
        </div>
      </RadioGroup>
    </div>
  );
};
