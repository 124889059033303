import { apiClient } from '@/utils/fetch/axiosConfig';
import { AuthEndpoint } from '@/utils/fetch/constants';
import { useMutation } from '@tanstack/react-query';

type SignUpCredentials = {
  email: string;
  username: string;
  password: string;
};

interface SignUpResponse {
  status: string;
  url: string;
}

export const useSignUp = () => {
  return useMutation({
    mutationKey: [AuthEndpoint.SIGN_UP],
    mutationFn: (credentials: SignUpCredentials) => {
      return apiClient.post<SignUpResponse>(AuthEndpoint.SIGN_UP, {
        email: credentials.email,
        user_name: credentials.username,
        password: credentials.password,
      });
    },
  });
};
