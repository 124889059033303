import { FC, ReactNode } from 'react';
import { ImageModal } from 'components/common/ImageModal';

interface IBrandTemplateCardProps {
  id: string;
  title: string;
  image: string;
  previewImage: string;
  updatedDate: string;
  children: ReactNode;
}

export const BrandTemplateCard: FC<IBrandTemplateCardProps> = ({
  title,
  image,
  previewImage,
  updatedDate,
  children,
}) => {
  return (
    <div className=' max-w-[260px] overflow-hidden rounded-md '>
      <ImageModal
        header={
          <div className='flex max-w-[400px] items-start gap-2 truncate sm:max-w-[300px]'>
            {title}
          </div>
        }
        content={<img crossOrigin='anonymous' src={image} />}
      >
        <div className='relative h-[260px] md:h-[170px] '>
          <img
            crossOrigin='anonymous'
            src={previewImage}
            className='h-[260px] w-full  cursor-zoom-in rounded-lg object-cover md:h-[170px]'
          />
        </div>
      </ImageModal>

      <div className='relative flex items-center justify-between px-0 py-3'>
        <div className='flex max-w-[210px] flex-col md:max-w-[85%]'>
          <div className='line-clamp-2  truncate text-main font-semibold md:text-[11px]'>
            {title}
          </div>
          <div className='text-[12px] font-normal text-[#777777] md:text-[11px]'>
            Updated at {updatedDate}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
