import { useCallback, useEffect, useState } from 'react';
import { PROCESSING_STATUSES } from 'components/ai-scene/result-sidebar/queries';
import { FileUploadInput } from 'components/workspace/brand-library/products/upload/FileUploadInput';
import {
  useGetRemoveBgStatusMutation,
  useRemoveBgMutation,
  useUploadFileMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';
import {
  useGetProductProperties,
  useGetProductPropertiesStatusMutation,
  useUploadProduct,
} from 'pages/workspace/brand-library/products/helper';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { ProgressComponent } from '../../add-logo-tool/QuickUploadLogo';
import { useAccountStatistics } from 'pages/dashboard/profile/helper';
import { getValueOrFallback } from 'components/dashboard/profile/helper';
import {
  getNextPlanType,
  getPreviousPlanType,
  isPlanSufficient,
} from 'components/plan-guard/helper';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { FeatureAccessRequirements, PlanType } from 'components/plan-guard/constants';
import { useUpgradeWarningModalStore } from 'components/plan-guard/useUpgradeWarningModalStore';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getFileNameWithoutExtension } from '@/utils/helper';

export const getRandomIncrement = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const QuickUploadProduct = () => {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addAiSceneImage = useAiSceneCreateStore((state) => state.addAiSceneImage);
  const uploadFile = useUploadFileMutation();
  const removeImageBackground = useRemoveBgMutation();
  const { mutate: getRemoveBgStatusMutate } = useGetRemoveBgStatusMutation();
  const { mutate: uploadProduct } = useUploadProduct();
  const queryClient = useQueryClient();
  const { showUpgradeModal } = useUpgradeWarningModalStore();
  const startPropertyProcess = useGetProductProperties();
  const getProcessStatus = useGetProductPropertiesStatusMutation();
  const { data, refetch } = useAccountStatistics({
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  if (!data) return null;

  const productsRemaining = getValueOrFallback(data.products.remaining, 100);
  const requiredPlan = productsRemaining > 0 ? data.plan : getNextPlanType(data.plan);
  const isTrialPeriodActive = data.trial_period !== null;
  const modalRequiresPlan = isTrialPeriodActive ? getPreviousPlanType(requiredPlan) : requiredPlan;

  const checkUserPlanAndShowModal = useCallback((): boolean => {
    const isSufficient = isPlanSufficient(data.plan, requiredPlan as PlanType);

    if (!isSufficient) {
      showUpgradeModal(
        modalRequiresPlan,
        FeatureAccessRequirements.ADD_INSPIRATION_IMAGE.featureDescription,
      );
    }

    return isSufficient;
  }, [data.plan, showUpgradeModal]);

  const checkPlanAndProceed = usePlanCheck({
    requiredPlan: requiredPlan,
    onClick: () => {},
    featureDescription: FeatureAccessRequirements.ADD_PRODUCTS.featureDescription,
    usePreviousPlan: isTrialPeriodActive,
  });

  useEffect(() => {
    if (file) {
      uploadSingleProduct(file);
    }
  }, [file]);

  const uploadSingleProduct = async (file: File) => {
    try {
      setIsSubmitting(true);

      const originalImage = await uploadFile.mutateAsync({ file });
      const removeBgResponse = await removeImageBackground.mutateAsync({
        action: 'remove_background',
        imageUrl: originalImage,
      });

      const pollRemoveBgStatus = () => {
        getRemoveBgStatusMutate(removeBgResponse.jobId, {
          onSuccess: async (data) => {
            if (PROCESSING_STATUSES.includes(data.status)) {
              setTimeout(pollRemoveBgStatus, 1000);
              return;
            }
            if (data.status !== 'ready') {
              toast.error('Failed to upload image.');
              setIsSubmitting(false);
              return;
            }
            const propertiesResponse = await startPropertyProcess.mutateAsync({
              transparentImage: data.maskUrl,
            });

            const pollPropertiesStatus = () => {
              getProcessStatus.mutate(propertiesResponse.processId, {
                onSuccess: (statusData) => {
                  if (PROCESSING_STATUSES.includes(statusData.status)) {
                    setTimeout(pollPropertiesStatus, 1000);
                  } else if (statusData.status === 'ready') {
                    uploadProduct(
                      {
                        productCode: null,
                        productName: getFileNameWithoutExtension(file.name),
                        categoryId: null,
                        originalImage,
                        transparentImage: data.maskUrl,
                        productProperties: statusData?.properties || null,
                      },
                      {
                        onSuccess: (productData) => {
                          toast.success('Quick upload successful.');
                          queryClient.invalidateQueries({
                            queryKey: [{ searchSection: 'products' }],
                            exact: false,
                          });
                          queryClient.invalidateQueries({
                            queryKey: [{ url: AccountEndpoint.GET_ACCOUNT_STATISTICS }],
                            exact: false,
                          });
                          addAiSceneImage({
                            image: productData.image.transparent,
                            id: productData.id,
                            type: 'product',
                            imageName: productData.name,
                            imageProperties: productData.properties ? productData.properties : null,
                          });

                          setIsSubmitting(false);
                        },
                        onError: () => {
                          toast.error('Quick upload failed.');
                          setIsSubmitting(false);
                        },
                      },
                    );
                  } else {
                    toast.error('Failed to retrieve properties.');
                    setIsSubmitting(false);
                  }
                },
                onError: () => {
                  toast.error('Failed to retrieve properties status.');
                  setIsSubmitting(false);
                },
              });
            };
            pollPropertiesStatus();
          },
          onError: (err) => {
            console.error(err);
            toast.error('Failed to apply enhance. Please try again.');
            setIsSubmitting(false);
          },
        });
      };
      pollRemoveBgStatus();
    } catch (error) {
      toast.error('Failed to upload image.');
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className='h-[128px]'>
        <ProgressComponent />
      </div>
    );
  }

  return (
    <div onClick={checkPlanAndProceed}>
      <FileUploadInput
        onFileUpload={setFile}
        titleElement={<span className='hidden'></span>}
        checkExternalValidation={checkUserPlanAndShowModal}
        containerClass='md:w-full'
        className='md:w-full'
      />
    </div>
  );
};
