import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';

import { CreateButton } from './CreateButton';
import { CreaditViews } from './CreaditViews';
import { useMobilSidebarStore } from './useMobileSidebarStore';

interface SidebarProps {
  children: ReactNode;
}

export const Sidebar: FC<SidebarProps> = ({ children }) => {
  const { isMobilSidebarOpen, closeSidebar } = useMobilSidebarStore();
  const location = useLocation();

  useEffect(() => {
    closeSidebar();
  }, [location.pathname, closeSidebar]);

  return (
    <aside
      className={cn(
        `container z-[40] block h-full w-sidebar overflow-hidden border-r border-[#EAEAEA] bg-white px-4 py-4 pt-2.5 transition-transform duration-300 ease-in-out md:fixed md:h-[calc(100vh-var(--header-height))] md:w-full md:pb-[105px]`,
        isMobilSidebarOpen ? 'md:translate-x-0' : 'md:-translate-x-full',
      )}
    >
      <nav className={cn(`flex h-full flex-col  justify-between  gap-2`)}>
        <CreateButton />
        {children}
        <div className={cn(`mt-auto flex flex-col md:pb-[70px]`)}>
          <CreaditViews />
        </div>
      </nav>
    </aside>
  );
};
