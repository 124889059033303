import HelpCenterIcon from 'assets/icons/helpCenter.svg?react';
import { Link } from 'react-router-dom';

const HELP_LINK = 'https://help.crait.it/en';

export const HelpCenterButton = () => {
  return (
    <Link to={HELP_LINK} target='_blank' rel='noopener noreferrer'>
      <HelpCenterIcon />
    </Link>
  );
};
