import { appendPluralSuffixByNumber, timestampToDate, triggerBlurOnEnter } from '@/utils/helper';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';

import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { AddStyleModal } from './AddStyleModal';
import { StyleActions } from './StyleActions';
import { useResetInfiniteSearchCache } from 'components/ai-scene/sidebar/settings-tab/styles/useResetInfiniteSearchCache';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { BrandStyleCard } from './BrandStyleCard';
import {
  TStyleResponse,
  useGetInfiniteSearchResults,
} from 'components/ai-scene/side-menu/create-tool/helper/queries';
import { AnimatePresence } from 'framer-motion';

const MAX_PAGES_IN_CACHE = 2;

interface BrandStyleViewProps {
  children?: ReactNode;
}
export const BrandStyleView: FC<BrandStyleViewProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TStyleResponse>({
    keyword: searchValue,
    onlyOrgLibrary: true,
    searchSection: 'styles',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.styles;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 0,
    refetchOnMount: true,
  });
  useResetInfiniteSearchCache<TStyleResponse>('styles', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allStyles = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.styles.items) || [];
  }, [infiniteData]);

  const isLoadingView = isLoading && (
    <div className='mx-auto mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load inspirations.</span>
    </RefetchView>
  );

  const hasAnyStyles = allStyles.length > 0;
  const styleItems =
    !hasAnyStyles && !isLoading ? (
      <span>No inspirations found.</span>
    ) : (
      allStyles.map((style) => (
        <BrandStyleCard
          key={style.id}
          id={style.id}
          title={style.name}
          image={style.url}
          previewImage={style.thumbnail}
          updatedDate={timestampToDate(style.last_update_info.timestamp)}
        >
          <StyleActions {...style} refetch={refetch} />
        </BrandStyleCard>
      ))
    );

  const totalItemCount = infiniteData?.pages[0]?.styles.totalItems || 0;

  return (
    <div className='h-full'>
      {children}
      <div className='flex items-center justify-between gap-2 md:flex-col lg:flex-col lg:items-start'>
        <div className='flex items-center gap-3'>
          <h4 className='mb-1 flex items-center text-[26px] font-medium'>Inspiration</h4>
          <span className='text-sm font-medium'>
            {totalItemCount} inspiration{appendPluralSuffixByNumber(totalItemCount)}
          </span>
        </div>
        <div className='flex gap-2 md:w-full   md:flex-col-reverse'>
          <Command className='flex h-10 max-w-[200px] justify-center rounded-lg border border-crait-dark-300 bg-transparent md:max-w-full'>
            <CommandInput placeholder='Search inspiration name' onValueChange={setSearchValue} />
          </Command>
          <AddStyleModal />
        </div>
      </div>
      <ScrollArea className={`-mr-2 mt-6 h-[calc(100%-80px)] pr-2`}>
        {isLoadingView || errorView}
        <div className='mt-10 grid max-w-[1440px] grid-cols-2 gap-6 gap-y-10 sm:gap-3 min-sm:grid-cols-3 min-md:grid-cols-2 min-lg:grid-cols-3 min-xl:grid-cols-4  min-3xl:grid-cols-5'>
          <AnimatePresence>{styleItems}</AnimatePresence>
        </div>
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
