import { useCallback, useState, useEffect } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import {
  CanvasResolutionLimit,
  applyCanvasDimensionChange,
  calculateScaleToFit,
  centerImageInCanvas,
  isCanvasResolutionOutOfBounds,
} from 'components/ai-scene/create/editor/canvas/helper';
import { toast } from 'sonner';
import { serializeCanvas } from '@/utils/helper';

export const useCanvasDimensionHandler = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);

  const [canvasDimensions, setCanvasDimensions] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });

  const updateCanvasDimensions = useCallback(() => {
    if (canvasInstance) {
      setCanvasDimensions({
        width: canvasInstance?.originalDimensions?.width,
        height: canvasInstance?.originalDimensions?.height,
      });
    }
  }, [canvasInstance]);

  const onDimensionSelected = useCallback(
    (width: number, height: number): true | undefined => {
      try {
        if (!canvasInstance) return;

        if (isCanvasResolutionOutOfBounds(width, height)) {
          toast.error(
            `Canvas size must be between ${CanvasResolutionLimit.MIN} and ${CanvasResolutionLimit.MAX} pixels.`,
          );
          return;
        }

        applyCanvasDimensionChange({
          width,
          height,
          canvas: canvasInstance,
        });
        const templateImage = canvasInstance
          .getObjects()
          .find((obj) => obj.imageType === 'template') as fabric.Image;
        if (templateImage) {
          const imageOriginalDimensions = {
            width: templateImage.width || templateImage.getScaledWidth(),
            height: templateImage.height || templateImage.getScaledHeight(),
          };
          const imageScale = calculateScaleToFit(imageOriginalDimensions, {
            width: canvasInstance.getWidth(),
            height: canvasInstance.getHeight(),
          });
          templateImage.scale(imageScale);
          centerImageInCanvas(templateImage, canvasInstance);
        }

        canvasInstance.requestRenderAll();
        updateCanvasState(serializeCanvas(canvasInstance));
        updateCanvasDimensions();
        return true;
      } catch (error) {
        console.error('Error while applying canvas dimension change', error);
        toast.error('Error while applying canvas dimension change');
      }
    },
    [canvasInstance, updateCanvasState, updateCanvasDimensions],
  );

  useEffect(() => {
    updateCanvasDimensions();
  }, [canvasInstance, updateCanvasDimensions]);

  return { onDimensionSelected, canvasInstance, canvasDimensions };
};
