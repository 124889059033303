import { useEffect } from 'react';
import { fabric } from 'fabric';
import { serializeCanvas } from '@/utils/helper';

type UseRemoveSelectedObjectParams = {
  fabricCanvasRef: React.RefObject<fabric.Canvas>;
  updateCanvasState: (canvasState: string) => void;
  resetSceneCanvasState?: () => void;
  onProcessEnd?: (canvas: fabric.Canvas) => void;
};

export const useRemoveSelectedObject = ({
  fabricCanvasRef,
  updateCanvasState,
  resetSceneCanvasState,
  onProcessEnd,
}: UseRemoveSelectedObjectParams) => {
  useEffect(() => {
    const removeSelectedObjectPureFunction = (canvas: fabric.Canvas) => {
      const selectedObjects = canvas.getActiveObjects();
      if (selectedObjects.length < 1) return;

      selectedObjects.forEach((obj) => {
        canvas.remove(obj);
        if ('dispose' in obj && typeof obj.dispose === 'function') {
          obj.dispose();
        }
      });

      canvas.discardActiveObject();
      canvas.requestRenderAll();

      if (canvas.getObjects().length < 1) {
        resetSceneCanvasState?.();
      } else {
        updateCanvasState(serializeCanvas(canvas));
      }
      onProcessEnd?.(canvas);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      const canvas = fabricCanvasRef.current;
      if (!canvas) return;

      // Check if focus is within an input, textarea, or any element that shouldn't trigger canvas object removal
      const focusWithinExcludedElement =
        document.activeElement &&
        (document.activeElement.tagName === 'INPUT' ||
          document.activeElement.tagName === 'TEXTAREA' ||
          document.activeElement.getAttribute('contenteditable') === 'true');
      if (focusWithinExcludedElement) return;

      if (e.key === 'Delete' || e.key === 'Backspace') {
        removeSelectedObjectPureFunction(canvas);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [fabricCanvasRef, resetSceneCanvasState, updateCanvasState]);
};
