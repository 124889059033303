import { useState } from 'react';
import craitImage from '@/assets/images/auth-page.png';
import { useUserStore } from '@/providers/user/hooks';
import { RoutePaths } from '@/utils/route/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import Logo from 'assets/icons/coming_soon.svg?react';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Form, FormControl, FormItem, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Separator } from 'components/ui/separator';
import { AnimatedCraitImage } from 'pages/login/AnimatedCraitImage';
import { useGoogleLoginRedirect } from 'pages/login/queries';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';
import { LEGAL_LINKS } from './constants';
import { useSignUp } from './queries';
import { ValidationBullet } from './ValidationBullet';
import { AuthOverlayContent } from 'pages/login/AuthOverlayContent';
import { CraitButton } from 'components/common/CraitButton';
import GoogleIcon from 'assets/icons/google.svg?react';

export const PasswordErrors = {
  MIN_LENGTH: 'Password must be at least 8 characters.',
  NUMBER: 'Password must include at least one number.',
  UPPER: 'Password must include at least one uppercase letter.',
} as const;

const UsernameErrors = {
  MIN_LENGTH: 'Username must be at least 5 characters',
  MAX_LENGTH: 'Username must be at most 30 characters',
  NO_EMPTY_SPACE: 'Username cannot include spaces',
  ONLY_ALPHANUMERIC: 'Username can only contain Latin letters and numbers',
  NO_PUNCTUATION: 'Username cannot contain punctuation other than . and _',
} as const;

export const passwordSchema = z.string().superRefine((value, ctx) => {
  const errorMessages = [];
  if (value.length < 8) {
    errorMessages.push(PasswordErrors.MIN_LENGTH);
  }
  if (!/[0-9]/.test(value)) {
    errorMessages.push(PasswordErrors.NUMBER);
  }
  if (!/[A-Z]/.test(value)) {
    errorMessages.push(PasswordErrors.UPPER);
  }
  if (errorMessages.length > 0) {
    ctx.addIssue({
      code: 'custom',
      message: errorMessages.join(' '),
    });
  }
});

export const usernameSchema = z
  .string()
  .min(5, { message: UsernameErrors.MIN_LENGTH })
  .max(30, { message: UsernameErrors.MAX_LENGTH })
  .refine((s) => !s.includes(' '), UsernameErrors.NO_EMPTY_SPACE)
  .refine((value) => !value.match(/[^\x00-\x7F]/g)?.length, {
    message: UsernameErrors.ONLY_ALPHANUMERIC,
  })
  .refine((value) => !value.match(/[!"#$%&'()*+,/:;<=>?@[\\\]^`{|}~]/g)?.length, {
    message: UsernameErrors.NO_PUNCTUATION,
  });

const signUpSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  username: usernameSchema,
  agreeToTerms: z.boolean().refine((value) => value, { message: 'You must agree to the terms' }),
  password: passwordSchema,
});

export const SignUpPage = () => {
  const signUp = useSignUp();
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
  });
  const googleLogin = useGoogleLoginRedirect();
  const [statusMessage, setStatusMessage] = useState<{
    message: string;
    type: 'success' | 'error';
  } | null>(null);

  const getErrorMessage = (errorCode: number, errorMessage: string): string => {
    return errorCode === 409 || errorCode === 422 ? errorMessage : 'Failed to sign up.';
  };

  const [focusedField, setFocusedField] = useState<string | null>(null);

  if (user) return <Navigate replace to={RoutePaths.BASE} />;

  const onSubmit = (data: z.infer<typeof signUpSchema>) => {
    signUp.mutate(
      {
        email: data.email,
        username: data.username,
        password: data.password,
      },
      {
        onSuccess: () => {
          setStatusMessage({
            message: 'Activation email is sent to your email address.',
            type: 'success',
          });
        },
        onError: (error: any) => {
          const errorMessage = getErrorMessage(error.response.status, error.response.data.detail);
          setStatusMessage({ message: errorMessage, type: 'error' });
        },
      },
    );
  };

  const isAnyFieldDirtied = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <div className='relative flex size-full items-center justify-center bg-white'>
      <AnimatedCraitImage
        src={craitImage}
        className='absolute bottom-0 left-0 right-0 top-0 hidden  h-full w-full object-cover md:block'
      />
      <div className='flex h-full w-full'>
        <div className='relative flex h-full flex-1 items-center justify-center px-8'>
          <div className='w-full max-w-[400px] bg-white md:rounded-[20px] md:px-5 md:py-6'>
            <div className='mb-9 flex flex-col items-center gap-3 md:mb-2'>
              <Link to={`/${RoutePaths.LOGIN}`}>
                <Logo className='h-8 w-4' cursor='pointer' />
              </Link>
              <div className='flex flex-col items-center gap-2'>
                <h4 className='text-center text-[32px] font-medium'>Welcome to Crait!</h4>
                <p className='text-[12px] font-bold text-[#E16236]'>
                  SIGN UP to get 20 Free Photos!
                </p>
              </div>
            </div>
            <Button
              variant='outline'
              className='mb-4 flex w-full gap-2 rounded-lg'
              onClick={() => {
                googleLogin.mutate();
              }}
              disabled={googleLogin.isPending}
            >
              <GoogleIcon />
              <span>Continue with Google</span>
            </Button>
            <div className='mb-4 flex items-center justify-center'>
              <Separator className='w-full bg-gray-200' />
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='mb-7 flex flex-col gap-2 md:mb-4'
              >
                <Controller
                  name='username'
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className='relative space-y-0'>
                      {focusedField === 'username' && (
                        <div className='absolute -top-[145px] left-0 z-10 w-[280px] rounded-lg border border-gray-300 bg-white p-4 shadow-lg'>
                          <p className='mb-1 text-sm font-semibold'>Your username</p>
                          <div className='flex flex-col gap-1'>
                            <ValidationBullet
                              status={
                                !isAnyFieldDirtied
                                  ? 'idle'
                                  : form.formState.errors.username?.message?.includes(
                                        UsernameErrors.MIN_LENGTH,
                                      )
                                    ? 'error'
                                    : 'success'
                              }
                              text='Should be between 5 & 30 characters'
                            />

                            <ValidationBullet
                              status={
                                !isAnyFieldDirtied
                                  ? 'idle'
                                  : form.formState.errors.username?.message?.includes(
                                        UsernameErrors.NO_PUNCTUATION,
                                      )
                                    ? 'error'
                                    : 'success'
                              }
                              text='Can only contain "." or "_" as special characters'
                            />
                          </div>
                        </div>
                      )}
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Enter your username'
                          autoCapitalize='none'
                          className='rounded-lg  bg-white text-[13px] font-light focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0'
                          onFocus={() => setFocusedField('username')}
                          onBlur={() => setFocusedField(null)}
                          onChange={(e) => {
                            field.onChange(e);
                            form.trigger('username');
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />{' '}
                <Controller
                  name='email'
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Enter your email'
                          autoCapitalize='none'
                          className='rounded-lg  bg-white text-[13px] font-light focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0'
                          onFocus={() => setFocusedField('email')}
                          onBlur={() => setFocusedField(null)}
                        />
                      </FormControl>
                      <FormMessage className='pl-2 text-xs'>
                        {form.formState.errors.email?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <Controller
                  name='password'
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className='relative space-y-0'>
                      {focusedField === 'password' && (
                        <div
                          className='absolute left-0 z-10 w-[280px] rounded-lg border border-gray-300 bg-white p-4 shadow-lg'
                          style={{ top: '-130px' }}
                        >
                          <p className='mb-1 text-sm font-semibold'>Password requirements:</p>
                          <div className='flex flex-col gap-1'>
                            <ValidationBullet
                              status={
                                !isAnyFieldDirtied
                                  ? 'idle'
                                  : form.formState.errors.password?.message?.includes(
                                        PasswordErrors.MIN_LENGTH,
                                      )
                                    ? 'error'
                                    : 'success'
                              }
                              text='At least 8 characters'
                            />
                            <ValidationBullet
                              status={
                                !isAnyFieldDirtied
                                  ? 'idle'
                                  : form.formState.errors.password?.message?.includes(
                                        PasswordErrors.NUMBER,
                                      )
                                    ? 'error'
                                    : 'success'
                              }
                              text='At least 1 number'
                            />
                            <ValidationBullet
                              status={
                                !isAnyFieldDirtied
                                  ? 'idle'
                                  : form.formState.errors.password?.message?.includes(
                                        PasswordErrors.UPPER,
                                      )
                                    ? 'error'
                                    : 'success'
                              }
                              text='At least 1 uppercase letter'
                            />
                          </div>
                        </div>
                      )}
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Enter your password'
                          type='password'
                          className=' rounded-lg  bg-white text-[13px] font-light focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0'
                          onFocus={() => setFocusedField('password')}
                          onBlur={() => setFocusedField(null)}
                          onChange={(e) => {
                            field.onChange(e);
                            form.trigger('password');
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className='mt-5 flex items-center gap-2 md:mt-2'>
                  <Controller
                    name='agreeToTerms'
                    control={form.control}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Checkbox
                            className='size-5 rounded-md border-crait-primary md:size-4'
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <Label htmlFor='agreeToTerms' className='text-sm font-normal md:text-[11px]'>
                    By creating an account, I agree to Crait’s{' '}
                    <Link
                      to={LEGAL_LINKS.termsOfUse}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='underline underline-offset-2'
                    >
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                      to={LEGAL_LINKS.privacyPolicy}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='underline underline-offset-2'
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Label>
                </div>
              </form>
            </Form>
            <div className='flex flex-col gap-2.5'>
              {statusMessage && !signUp.isPending && (
                <p
                  className={`text-left text-xs ${
                    statusMessage.type === 'success' ? 'text-green-700' : 'text-red-500'
                  }`}
                >
                  {statusMessage.message}
                </p>
              )}
              <CraitButton
                onClick={form.handleSubmit(onSubmit)}
                className='rounded-lg'
                disabled={signUp.isPending || !form.formState.isValid || signUp.isSuccess}
              >
                Sign up
              </CraitButton>
              <span className='text-center text-xs'>
                By signing in, you agree to Crait.it Terms of Use and Privacy Policy.
              </span>
              <Button asChild variant='link'>
                <Link to={`/${RoutePaths.LOGIN}`}>
                  Already have an account? <span className='ml-1 text-[#E16236]'>Sign in</span>
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div className='relative block h-full w-full flex-1 overflow-hidden md:hidden'>
          <AnimatedCraitImage src={craitImage} className='h-full w-full object-cover' />
          <AuthOverlayContent />
        </div>
      </div>
    </div>
  );
};
