import { MailQuestion } from 'lucide-react';
import { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { GTM } from '@/utils/gtm';
import { RoutePaths } from '@/utils/route/constants';
import { useConfirmEmail } from './queries';

const EmailConfirmPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string | null;

  const navigate = useNavigate();
  const confirmEmail = useConfirmEmail();

  if (!token) return <Navigate replace to={RoutePaths.BASE} />;

  useEffect(() => {
    if (!confirmEmail) return;

    confirmEmail.mutate(
      { token },
      {
        onSuccess: () => {
          toast.success('Email confirmation is successful.');
          GTM.sendNewUserSignupEvent();
          navigate(`/${RoutePaths.ACCOUNT}`, {
            replace: true,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.error('Invalid or expired token. Please use the correct link or sign up again.');
          navigate(`/${RoutePaths.LOGIN}`, {
            replace: true,
          });
        },
      },
    );
  }, []);

  return (
    <div className='flex h-screen flex-col items-center justify-center bg-white'>
      <MailQuestion size={128} color='rgb(56,171,124)' />

      <p className='mb-2 mt-2 text-xl text-gray-600 '>Email confirmation is in progress...</p>
    </div>
  );
};

export { EmailConfirmPage };
