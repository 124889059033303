import { useState } from 'react';
import { Search } from 'lucide-react';

import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Input } from 'components/ui/input';
import { useDebounceValue } from 'usehooks-ts';
import { ProjectList } from './ProjectList';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white text-main md:text-[12px] font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

export const ProjectListContainer = () => {
  const [isOnlyMyProjects, setIsOnlyMyProjects] = useState(false);
  const [keyword, setKeyword] = useDebounceValue('', 200);

  return (
    <>
      <div className='mt-8 flex w-full justify-start gap-8 md:gap-2'>
        <div className='relative w-[360px] min-w-[171px]'>
          <Search className='absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground' />
          <Input
            type='search'
            placeholder='Search Projects'
            className=' rounded-[6px] bg-[#F0F0F0] bg-background pl-8 text-main font-medium md:text-[12px]'
            onChange={(event) => setKeyword(event.target.value)}
          />
        </div>
        <Tabs defaultValue='by-everyone' className='w-[400px] '>
          <TabsList className='roundet-[6px] border border-[#F0F0F0] bg-white'>
            <TabsTrigger
              className={TAB_TRIGGER_CLASS}
              onClick={() => setIsOnlyMyProjects(false)}
              value='by-everyone'
            >
              By Everyone
            </TabsTrigger>
            <TabsTrigger
              className={TAB_TRIGGER_CLASS}
              onClick={() => setIsOnlyMyProjects(true)}
              value='by-me'
            >
              By Me
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <ProjectList keyword={keyword} isOnlyMyProjects={isOnlyMyProjects} />
    </>
  );
};
