import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';

import MarkAreaIcon from '@/assets/icons/ai-scene-icons/addBrush.svg?react';
import RemoveMarkIcon from '@/assets/icons/ai-scene-icons/removeBrush.svg?react';
import { Slider } from 'components/ui/slider';
import { CraitButton } from 'components/common/CraitButton';
import { useEffect, useRef, useState } from 'react';
import {
  DrawingCursorSize,
  applyCustomCursor,
  generateDrawingCursorUrl,
} from 'components/ai-scene/review-edit/actions/helper';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import {
  removeOtherPaths,
  removeTargetedPaths,
} from 'components/ai-scene/review-edit/canvas/helper';
import { toast } from 'sonner';
import {
  cancelDrawingMode,
  useEscToCancelDrawingMode,
} from '@/hooks/canvas/useEscToCancelDrawingMode';
import { useAiSketchMutation } from 'pages/workspace/brand-library/product-edit/helper';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import {
  cloneAndScaleCanvasWithObjects,
  convertFabricCanvasToFile,
  mutateObjectToNonSelectable,
  serializeCanvas,
} from '@/utils/helper';
import { fabric } from 'fabric';
import { Textarea } from 'components/ui/textarea';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';
import { TooltipItem } from 'components/common/TooltipItem';
import { HowItWorksBox } from 'components/ai-scene/side-menu/create-tool/visual-guidance/HowItWorksBox';
import { Separator } from 'components/ui/separator';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] bg-white w-[142px] !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:text-c-dark';

export const MagicEditMenu = ({ onBackClick }: { onBackClick: () => void }) => {
  const [isPromptDisabled, setIsPromptDisabled] = useState(true);
  const brushSize = useRef<number>(DrawingCursorSize.DEFAULT);
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const promptContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEscToCancelDrawingMode(canvasInstance);
  const aiSketchMutation = useAiSketchMutation();
  const { projectId, sceneId } = useParams();
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const { refetchScenes } = useRefetchGeneratedScenes();
  const [imageNumber, setImageNumber] = useState(1);

  const cancelPrompt = () => {
    if (!canvasInstance) return;

    cancelDrawingMode(canvasInstance);
    removeTargetedPaths({
      canvasInstance,
      drawingType: 'freeSelect',
      shouldRenderAfterwards: true,
    });
    setIsPromptDisabled(true);
  };

  const sendPrompt = async () => {
    try {
      if (!canvasInstance) return;
      if (!textAreaRef.current?.value) {
        toast.info('Please enter a prompt.');
        return;
      }
      invariant(projectId, 'Project id not found.');
      invariant(sceneId, 'Scene id not found.');

      changeProcessingState('pending');
      const scaledCanvas = await cloneAndScaleCanvasWithObjects(canvasInstance);
      const offscreenCanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
        width: scaledCanvas.getWidth(),
        height: scaledCanvas.getHeight(),
        renderOnAddRemove: false,
      });
      const scaledDrawnPaths = scaledCanvas
        .getObjects('path')
        .filter((path) => (path as any).drawingType === 'freeSelect');
      const clonedPathPromises = scaledDrawnPaths.map(
        (path) =>
          new Promise((resolve) => {
            path.clone((clonedPath: typeof path) => {
              clonedPath.set({
                fill: undefined,
                stroke: 'white',
              });
              offscreenCanvas.add(clonedPath);
              resolve(true);
            });
          }),
      );
      const setBgColorPromise = new Promise((resolve) => {
        offscreenCanvas.setBackgroundColor('black', () => {
          offscreenCanvas.renderAll();
          resolve(true);
        });
      });
      await Promise.all([...clonedPathPromises, setBgColorPromise]);
      const pathBlackWhiteMaskFile = convertFabricCanvasToFile({
        canvas: offscreenCanvas,
        fileName: 'path-mask',
        extension: 'jpeg',
      });
      await aiSketchMutation.mutateAsync({
        sceneId,
        projectId,
        mask: pathBlackWhiteMaskFile,
        prompt: textAreaRef.current?.value,
        count: imageNumber,
      });
      refetchScenes();
      cancelPrompt();
    } catch (e) {
      console.error(e);
    } finally {
      changeProcessingState('idle');
    }
  };
  useEffect(() => {
    enableFreeSelect();
  }, []);

  useEffect(() => {
    if (!canvasInstance) return;

    const handlePathCreated = (event: fabric.IEvent) => {
      if (!('path' in event) || !event.path) return;

      const currentDrawingType = canvasInstance.currentDrawingType;
      if (currentDrawingType !== 'freeSelect') return;

      (event.path as any).drawingType = currentDrawingType;
      mutateObjectToNonSelectable(event.path as fabric.Object);
      setIsPromptDisabled(false);
      textAreaRef.current?.focus();
    };

    const handlePathRemoved = (event: fabric.IEvent) => {
      if (event.target && event.target.type === 'path') {
        updateCanvasState(serializeCanvas(canvasInstance));
      }
    };

    canvasInstance.on('path:created', handlePathCreated);
    canvasInstance.on('object:removed', handlePathRemoved);
    return () => {
      canvasInstance.off('path:created', handlePathCreated);
      canvasInstance.off('object:removed', handlePathRemoved);
    };
  }, [canvasInstance, updateCanvasState]);

  const enableFreeSelect = () => {
    try {
      if (!canvasInstance) return;

      canvasInstance.isDrawingMode = true;
      canvasInstance.currentDrawingType = 'freeSelect';
      removeOtherPaths({ canvasInstance, drawingType: 'freeSelect', shouldRenderAfterwards: true });

      canvasInstance.freeDrawingBrush.color = 'rgba(169, 246, 173, 0.7)';
      canvasInstance.freeDrawingBrush.width = brushSize.current;
      const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
      applyCustomCursor(canvasInstance, cursorUrl);
    } catch (error) {
      console.error('Error while enabling free select', error);
      toast.error('Error while enabling free select');
    }
  };

  return (
    <div className='relative flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md'>
      <button
        onClick={() => {
          onBackClick();
          cancelPrompt();
        }}
        className='flex h-9 gap-2  text-[15px] font-semibold'
      >
        <ArrowLeftIcon /> Magic Edit
      </button>
      <Separator />
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
          <HowItWorksBox
            text='Changes and fills the selected part of the image according to the given prompt.'
            linkText="Don't show this again"
            parentComponent='MagicEdit'
          />
          <h3 className='text-main font-semibold'>Select Tool</h3>
          <Tabs defaultValue='mark-area' className='w-[400px] '>
            <TabsList className='roundet-[6px] border border-[#F0F0F0] bg-white'>
              <TabsTrigger
                className={TAB_TRIGGER_CLASS}
                value='mark-area'
                onClick={enableFreeSelect}
              >
                <MarkAreaIcon />
              </TabsTrigger>
              <TooltipItem
                side='bottom'
                trigger={
                  <TabsTrigger disabled={true} className={TAB_TRIGGER_CLASS} value='remove-mark'>
                    <RemoveMarkIcon />
                  </TabsTrigger>
                }
              >
                Coming Soon
              </TooltipItem>
            </TabsList>
          </Tabs>
        </div>

        <div className='flex cursor-pointer items-center justify-between'>
          <h3 className=' whitespace-nowrap text-main font-semibold'>Brush Size</h3>
          <Slider
            className='w-[50%] bg-crait-green-primary'
            step={5}
            min={DrawingCursorSize.MIN}
            max={DrawingCursorSize.MAX}
            defaultValue={[brushSize.current]}
            onValueChange={(values) => {
              if (!canvasInstance) return;

              brushSize.current = values[0];
              canvasInstance.freeDrawingBrush.width = brushSize.current;
              const cursorUrl = generateDrawingCursorUrl(brushSize.current, 'green');
              applyCustomCursor(canvasInstance, cursorUrl);
            }}
          />
        </div>
        <div ref={promptContainerRef} className=' flex  transform flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <Label
              className={`text-main font-semibold ${isPromptDisabled ? 'text-gray-400' : ''}`}
              htmlFor='magicEditPrompt'
            >
              Describe what to add or change
            </Label>
            <Textarea
              id='magicEditPrompt'
              ref={textAreaRef}
              className='h-32 max-h-[200px] min-h-[128px] w-full rounded-lg border border-[#EAEAEA] bg-white'
              placeholder='Enter your prompt here'
              disabled={isPromptDisabled}
            />
          </div>

          <div className='flex cursor-pointer items-center justify-between'>
            <span className='text-main font-semibold'>Image Number</span>
            <Slider
              className='w-[50%] bg-crait-green-primary'
              max={4}
              min={1}
              step={1}
              value={[imageNumber]}
              defaultValue={[imageNumber]}
              onValueChange={(value) => setImageNumber(value[0])}
            />
          </div>
          <CraitButton className='w-full' onClick={sendPrompt} disabled={isPromptDisabled}>
            Crait
          </CraitButton>
        </div>
      </div>
    </div>
  );
};
