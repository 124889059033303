import { TAccordionSection } from 'components/ai-banner/sidebar/helpers';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useBrandSectionQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const section = (searchParams.get('section') as TAccordionSection) || '';

  const setSection = useCallback(
    (section: TAccordionSection) => {
      setSearchParams({ section });
    },
    [setSearchParams],
  );

  const closeSection = useCallback(() => {
    setSearchParams({ section: '' });
  }, [setSearchParams]);

  return useMemo(
    () => ({ section, setSection, closeSection }) as const,
    [section, setSection, closeSection],
  );
};
