import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export const useSyncSelectedTemplate = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const [drawnTemplate, setDrawnTemplate] = useState<{
    id: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    if (!canvasInstance) return;

    const templateImageOnCanvas = canvasInstance
      .getObjects()
      .find((obj): obj is fabric.Image => obj.imageType === 'template' && 'imageId' in obj);

    if (templateImageOnCanvas) {
      setDrawnTemplate({
        id: templateImageOnCanvas.imageId as string,
        name: templateImageOnCanvas.imageName as string,
      });
    }

    const addImageId = (e: fabric.IEvent) => {
      const drawnTemplateImage =
        e.target?.imageType === 'template' &&
        e.target.imageId &&
        e.target.imageName &&
        (e.target as fabric.Image);
      if (!drawnTemplateImage) {
        toast.error('Selected template could not be found.');
        return;
      }

      setDrawnTemplate({
        id: drawnTemplateImage.imageId as string,
        name: drawnTemplateImage.imageName as string,
      });
    };

    const removeImageId = (e: fabric.IEvent) => {
      const removedObject = e.target;
      if (removedObject?.imageType === 'template') {
        setDrawnTemplate(null);
      } else if (removedObject?.type === 'group') {
        (removedObject as fabric.Group)._objects?.forEach((obj) => {
          if (obj.imageType === 'template') {
            setDrawnTemplate(null);
          }
        });
      }
    };

    canvasInstance.on('object:added', addImageId);
    canvasInstance.on('object:removed', removeImageId);
    return () => {
      canvasInstance.off('object:added', addImageId);
      canvasInstance.off('object:removed', removeImageId);
    };
  }, [canvasInstance]);

  return useMemo(() => [drawnTemplate, setDrawnTemplate] as const, [drawnTemplate]);
};
