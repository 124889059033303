import React, { FC } from 'react';
import { ArrowRightIcon } from './CreateToolIcons';
import { PlanGuard } from 'components/plan-guard/PlanGuard';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';

type SizeButtonProps = {
  onSizeTabClick: () => void;
  canvasWidth: number | undefined;
  canvasHeight: number | undefined;
};

export const SizeButton: FC<SizeButtonProps> = ({ onSizeTabClick, canvasWidth, canvasHeight }) => {
  return (
    <PlanGuard
      requiredPlan={FeatureAccessRequirements.EDITOR_RESIZE_BUTTON.requiredPlan}
      featureDescription={FeatureAccessRequirements.EDITOR_RESIZE_BUTTON.featureDescription}
    >
      <div
        onClick={onSizeTabClick}
        className='flex h-[20px] cursor-pointer items-center justify-between'
      >
        <span className='text-[13px] font-semibold text-c-dark'>Size</span>
        <div className='flex items-center'>
          <span className='text-[13px] text-gray-600'>
            W: <span className='font-semibold text-c-dark'>{canvasWidth}</span> H:{' '}
            <span className='font-semibold text-c-dark'> {canvasHeight}</span>
          </span>
          <ArrowRightIcon />
        </div>
      </div>
    </PlanGuard>
  );
};
