import { PlanType, PlanTypes, PLAN_REQUIREMENTS } from './constants';

export const isPlanSufficient = (userPlan: PlanType, requiredPlan: PlanType): boolean => {
  const userPlanRank = Object.values(PlanTypes).find((plan) => plan.type === userPlan)?.rank ?? 0;
  const requiredPlanRank =
    Object.values(PlanTypes).find((plan) => plan.type === requiredPlan)?.rank ?? 0;
  return userPlanRank >= requiredPlanRank;
};

export const determineRequiredPlan = (width: number, height: number): PlanType => {
  const largerDimension = Math.max(width, height);

  const matchedRequirement = PLAN_REQUIREMENTS.find(
    (requirement) => largerDimension <= requirement.maxAllowedDimension,
  );

  if (matchedRequirement) return matchedRequirement.planType;

  return PlanTypes.FREE.type;
};

export const getNextPlanType = (currentPlan: PlanType): PlanType => {
  const plans = Object.values(PlanTypes);
  const currentPlanObject = plans.find((plan) => plan.type === currentPlan);

  if (!currentPlanObject) {
    return currentPlan;
  }

  const nextPlan = plans.find((plan) => plan.rank === currentPlanObject.rank + 1);

  if (!nextPlan) {
    return currentPlan;
  }

  return nextPlan.type;
};

export const getPreviousPlanType = (currentPlan: PlanType): PlanType => {
  const plans = Object.values(PlanTypes);
  const currentPlanObject = plans.find((plan) => plan.type === currentPlan);

  if (!currentPlanObject) {
    return currentPlan;
  }

  const previousPlan = plans.find((plan) => plan.rank === currentPlanObject.rank - 1);

  if (!previousPlan) {
    return currentPlan;
  }

  return previousPlan.type;
};
