import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router';
import { toast } from 'sonner';
import { ProjectContext, useGetProject } from './helpers';
import { RoutePaths } from '@/utils/route/constants';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { convertImageUrlToFile } from '@/utils/helper';
import { useSyncSelectedSettings } from './useSyncSelectedSettings';

interface ProjectProviderProps {
  children: ReactNode;
}

export const ProjectProvider: FC<ProjectProviderProps> = ({ children }) => {
  const project = useGetProject({
    staleTime: 0,
    gcTime: 0,
  });
  const updateAiScenePromptTab = useAiSceneCreateStore((s) => s.updateAiScenePromptTab);
  const addAiSceneSelectedStyles = useAiSceneCreateStore((state) => state.addAiSceneSelectedStyles);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  useSyncSelectedSettings();

  useEffect(() => {
    const generationData = project.data?.lastGenerationData;
    if (!generationData) return;

    const updateLocalStore = async () => {
      try {
        const inspirationalPhoto = generationData.parameters.inspirationalImageUrls[0];
        const inspirationalPhotoAsFile = inspirationalPhoto
          ? await convertImageUrlToFile(inspirationalPhoto, 'inspirationalPhoto')
          : undefined;

        updateAiScenePromptTab({
          prompt: generationData.parameters.prompt,
          negativePrompt: generationData.parameters.negativePrompt || undefined,
          inspirationalPhoto: inspirationalPhotoAsFile,
          faceTextComposition: generationData.parameters.faceTextComposition || false,
          enhancePrompt: generationData.parameters.enhancePrompt || false,
          brandStyleId: generationData.summary.brand_style || null,
          lightingStyleId: generationData.summary.lighting_style || null,
        });
        addAiSceneSelectedStyles(generationData.summary.styles || []);

        const prevCanvasData = generationData.serializedCanvasData;
        // There were cases where the canvas data was stored as a stringified 'undefined'.
        // It could be due to the testing environment, it can be removed after confirming.
        if (prevCanvasData && prevCanvasData !== 'undefined') {
          updateCanvasState(prevCanvasData);
        }
      } catch (e) {
        toast.error('Failed to update prompt tab with last generation data');
      }
    };
    updateLocalStore();
  }, [
    project.data?.lastGenerationData,
    updateAiScenePromptTab,
    addAiSceneSelectedStyles,
    updateCanvasState,
  ]);

  if (project.data) {
    return <ProjectContext.Provider value={project}>{children}</ProjectContext.Provider>;
  }

  if (project.isError) {
    (() => {
      toast.error('Could not find the project. Please try again.');
    })();
    return <Navigate to={`/${RoutePaths.WORKSPACE}`} />;
  }

  return (
    <div className='mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );
};
