import { useCallback, useMemo } from 'react';
import { fabric } from 'fabric';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import {
  useEnhanceImageMutation,
  useGetEnhanceStatusMutation,
  useUploadFileMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { toast } from 'sonner';
import { scaleAndConvertFabricImageObjectToFile, serializeCanvas } from '@/utils/helper';
import { PROCESSING_STATUSES } from 'components/ai-scene/review/queries';

export const useEnhanceImageQuality = (enhanceButton: HTMLButtonElement | null) => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  const { mutateAsync: uploadFileAsync } = useUploadFileMutation();
  const { mutateAsync: enhanceImageAsync } = useEnhanceImageMutation();
  const { mutate: getEraseStatusMutate } = useGetEnhanceStatusMutation();

  const enhanceQuality = useCallback(async () => {
    if (!canvasInstance) return;

    const selectedObject = canvasInstance.getActiveObject();
    try {
      const isElementOrProductImage =
        selectedObject?.imageType === 'element' || selectedObject?.imageType === 'product';
      if (!selectedObject || !isElementOrProductImage || !('setSrc' in selectedObject)) {
        toast.info('Please select an element or product image to enhance its quality');
        return;
      }

      enhanceButton?.setAttribute('disabled', 'true');
      const selectedObjectAsFile = await scaleAndConvertFabricImageObjectToFile(
        selectedObject as fabric.Image,
        'image-file.png',
      );
      canvasInstance.requestRenderAll();
      const uploadedUrl = await uploadFileAsync({ file: selectedObjectAsFile });
      const enhanceResponse = await enhanceImageAsync({ imageUrl: uploadedUrl });

      const pollEnhanceStatus = async () => {
        getEraseStatusMutate(enhanceResponse.jobId, {
          onSuccess: (data) => {
            if (PROCESSING_STATUSES.includes(data.status)) {
              setTimeout(pollEnhanceStatus, 1000);
              return;
            }
            if (data.status !== 'ready') {
              enhanceButton?.removeAttribute('disabled');
              toast.error('Failed to apply enhance. Please try again.');
              return;
            }

            (selectedObject as fabric.Image).setSrc(
              data.url,
              () => {
                canvasInstance.requestRenderAll();
                updateCanvasState(serializeCanvas(canvasInstance));
                enhanceButton?.removeAttribute('disabled');
              },
              { crossOrigin: 'anonymous' },
            );
          },
          onError: (err) => {
            enhanceButton?.removeAttribute('disabled');
            console.error(err);
            toast.error('Failed to apply enhance. Please try again.');
          },
        });
      };
      pollEnhanceStatus();
    } catch (error) {
      enhanceButton?.removeAttribute('disabled');
      console.error(error);
      toast.error('Failed to enhance image quality. Please try again.');
    }
  }, [canvasInstance, uploadFileAsync, enhanceImageAsync, updateCanvasState, enhanceButton]);

  return useMemo(() => enhanceQuality, [enhanceQuality]);
};
