import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { useBrandSectionQueryParams } from 'components/ai-banner/create/assets/useBrandSectionQueryParams';
import { Button } from 'components/ui/button';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';

export const TemplateContent = () => {
  const { setSection } = useBrandSectionQueryParams();
  const templates = useBannerCreateStore((s) => s.templates);
  const removeTemplate = useBannerCreateStore((s) => s.removeTemplate);

  return (
    <div>
      <Button
        className='w-full bg-crait-green-400'
        onClick={() => {
          setSection('template');
        }}
      >
        Select from Template Library
      </Button>
      {templates.length > 0 && (
        <ScrollArea className='w-[330px] py-4'>
          <div className='flex gap-4'>
            {templates.map((template) => (
              <div
                key={template.id}
                className='relative flex w-24 flex-col gap-2 overflow-hidden rounded-md'
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTemplate(template.id);
                  }}
                  className='absolute right-0.5 top-0.5 z-10 flex size-7 cursor-pointer items-center justify-center rounded-full bg-crait-dark-200'
                >
                  <span className='i-mdi-close size-4 bg-black' />
                </div>
                <img crossOrigin='anonymous' src={template.url} className='size-24 object-cover' />
                <p className='line-clamp-1 text-ellipsis text-xs'>{template.name}</p>
              </div>
            ))}
          </div>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      )}
    </div>
  );
};
