import { FC, useMemo } from 'react';
import { TStyleResponse } from '../../helper/queries';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';

interface ISelectedStyleListProps {}

export const SelectedStyleList: FC<ISelectedStyleListProps> = () => {
  const styleIds = useAiSceneCreateStore((state) => state.aiSceneSettingsTab.styleIds);
  const removeStyleId = useAiSceneCreateStore((state) => state.removeAiSceneSelectedStyles);

  if (!styleIds || styleIds.length < 1) return null;

  return (
    <ScrollArea className='h-[35px] w-[260px] sm:w-[90vw]'>
      <div className='flex gap-2'>
        {styleIds.map((style) => (
          <div
            key={style.id}
            onClick={() => {
              removeStyleId(style.id.toString());
            }}
            className='flex cursor-pointer items-center justify-center gap-1 text-nowrap rounded-md border border-black p-1 px-2 leading-none'
          >
            <span>{style.name}</span>
            <span className='i-mdi-window-close'>x</span>
          </div>
        ))}
      </div>
      <ScrollBar orientation='horizontal' />
    </ScrollArea>
  );
};
