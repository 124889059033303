import { FC, ReactNode } from 'react';
import { ScrollArea } from 'components/ui/scroll-area';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { PlanGuard } from 'components/plan-guard/PlanGuard';
import { COMMON_DIMENSIONS } from 'components/ai-scene/side-menu/create-tool/size/ResizeLayout';
import { useDimensionSelection } from './useDimensionSelectionForResults';
import { CustomCanvasSizes } from './CustomCanvasSizes';
import { useAiSceneOutpaintStore } from '@/providers/ai-scene/outpaint/hooks';
import { Checkbox } from 'components/ui/checkbox';
import { CraitButton } from 'components/common/CraitButton';
import { OUTPAINT_BUTTON_PORTAL_ID } from 'components/ai-scene/review-edit/canvas/OutpaintCanvasWrapper';

interface MultiResizeLayoutProps {
  children?: ReactNode;
}

export const MultiResizeLayout: FC<MultiResizeLayoutProps> = ({ children }) => {
  const { onDimensionSelected, aheadOfTimeUploadedImageUrl } = useDimensionSelection();
  const outpaintData = useAiSceneOutpaintStore((state) => state.outpaintData);
  const removeMultiOutpaintData = useAiSceneOutpaintStore((state) => state.removeMultiOutpaintData);
  const processingState = useAiSceneOutpaintStore((state) => state.processingState);

  const isDimensionInOutpaintData = (width: number, height: number) =>
    outpaintData.some((data) => data.width === width && data.height === height);

  const getIdFromOutpaintData = (width: number, height: number): string | undefined => {
    const data = outpaintData.find((data) => data.width === width && data.height === height);
    return data ? data.id : undefined;
  };

  const handleToggleSelection = (width: number, height: number) => {
    const id = getIdFromOutpaintData(width, height);
    if (id) {
      removeMultiOutpaintData([id]);
    } else {
      onDimensionSelected(width, height);
    }
  };

  return (
    <div className='relative flex h-full flex-col gap-3 overflow-hidden'>
      <ScrollArea viewportClassName='w-[96%] h-[95%]'>
        <CustomCanvasSizes
          outpaintData={outpaintData}
          onToggleSelection={handleToggleSelection}
          processingState={processingState}
        />
        <div className='flex flex-col gap-2 text-main font-semibold'>
          {COMMON_DIMENSIONS.map((size) => (
            <PlanGuard
              requiredPlan={size.requiredPlan}
              featureDescription={
                FeatureAccessRequirements.EDITOR_RESIZE_INPUT_ABOVE_1024.featureDescription
              }
              key={size.id}
            >
              <div
                className={`flex h-[44px] items-center gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 ${
                  processingState === 'outpainting'
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:bg-slate-100'
                }`}
                onClick={() =>
                  processingState !== 'outpainting' &&
                  handleToggleSelection(size.width, size.height)
                }
              >
                {outpaintData.length > 0 && (
                  <Checkbox
                    checked={isDimensionInOutpaintData(size.width, size.height)}
                    className='rounded-[3px] border-[#EAEAEA] data-[state=checked]:bg-crait-green-primary data-[state=checked]:text-black'
                  />
                )}
                <div className='flex w-6 justify-center'>
                  <size.icon />
                </div>
                <span>{size.text}</span>
              </div>
            </PlanGuard>
          ))}
        </div>
      </ScrollArea>

      {outpaintData.length === 0 && <CraitButton>Resize</CraitButton>}
      <div id={OUTPAINT_BUTTON_PORTAL_ID}></div>
    </div>
  );
};
