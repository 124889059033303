import { FC, ReactNode } from 'react';
import CustomSizeIcon from '@/assets/icons/ai-scene-icons/customSize.svg?react';
import {
  useDeleteLayoutSizeMutation,
  useGetLayoutSizesQuery,
} from 'components/ai-scene/side-menu/create-tool/size/queries';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { TOutpaintData } from '@/providers/ai-scene/outpaint/store';
import { Checkbox } from 'components/ui/checkbox';

interface CustomCanvasSizesProps {
  children?: ReactNode;
  outpaintData: TOutpaintData[];
  onToggleSelection?: (width: number, height: number) => void;
  processingState: string;
}

export const CustomCanvasSizes: FC<CustomCanvasSizesProps> = ({
  children,
  outpaintData,
  onToggleSelection,
  processingState,
}) => {
  const { data, refetch, isLoading } = useGetLayoutSizesQuery();
  const deleteLayoutSizeMutation = useDeleteLayoutSizeMutation();

  if (!data || !data.data.custom) return null;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const customSizes = data.data.custom;

  const isDimensionInOutpaintData = (width: number, height: number) =>
    outpaintData?.some((data) => data.width === width && data.height === height);

  return (
    <>
      {children}
      <div className='mb-2 flex flex-col gap-1 text-xs'>
        {customSizes.map((dimension) => (
          <div key={dimension.id} className='flex items-center gap-2'>
            <div
              className={`relative flex h-[44px] w-full items-center gap-1.5 rounded-[9px] border border-[#EAEAEA] px-2 py-1 ${
                processingState === 'outpainting'
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer hover:bg-slate-100'
              }`}
              onClick={() => {
                if (processingState !== 'outpainting') {
                  onToggleSelection?.(dimension.dimensions.x, dimension.dimensions.y);
                }
              }}
            >
              {outpaintData?.length > 0 ? (
                <Checkbox
                  checked={
                    isDimensionInOutpaintData(dimension.dimensions.x, dimension.dimensions.y) ||
                    false
                  }
                  className='rounded-[3px] border-[#EAEAEA] data-[state=checked]:bg-crait-green-primary data-[state=checked]:text-black'
                />
              ) : null}
              <div className='flex w-6 justify-center'>
                <CustomSizeIcon />
              </div>
              <div className='max-w-[200px] flex-1  overflow-hidden text-[13px] font-semibold'>
                <span className='truncate'>{dimension.name || 'Custom Size'} </span> (
                {dimension.dimensions.x} x {dimension.dimensions.y}px)
              </div>
              <div className='absolute right-1 h-4 w-4'>
                <span
                  className='i-mdi-trash-can-outline size-4 cursor-pointer text-gray-400'
                  onClick={(e) => {
                    if (processingState !== 'outpainting') {
                      e.stopPropagation();
                      deleteLayoutSizeMutation.mutate(
                        { layout_size_id: dimension.id },
                        {
                          onSuccess: () => {
                            refetch();
                          },
                          onError: (error) => {
                            console.error('Error deleting layout size:', error);
                          },
                        },
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
