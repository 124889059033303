import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { FC, useEffect, useRef, useState } from 'react';
import { Input } from 'components/ui/input';
import { toast } from 'sonner';
import { convertImageUrlToFile } from '@/utils/helper';

interface EditNameAndImageViewProps {
  defaultName: string;
  onSubmit: (name: string) => void;
  onClose: () => void;
  isSubmitDisabled?: boolean;
}

export const RenameProjectNameView: FC<EditNameAndImageViewProps> = ({
  defaultName,
  onSubmit,
  onClose,
  isSubmitDisabled,
}) => {
  const nameInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className='flex flex-col items-center gap-6'>
        <div className='flex w-72 flex-col gap-3'>
          <Input ref={nameInput} placeholder='Enter name' defaultValue={defaultName} />
          <Button
            className=' bg-crait-green-primary text-main w-full rounded-[10px] text-c-dark'
            disabled={isSubmitDisabled}
            onClick={() => {
              if (!nameInput.current) {
                toast.error('Please enter a name');
                return;
              }

              onSubmit(nameInput.current.value);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
};
