import * as React from 'react';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  RowData,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { ChevronDown } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { tableColumns } from './table.data';
import { useGetPeopleCtx } from 'pages/dashboard/people/helper';
import { useEffect, useRef, useState } from 'react';
import { useDeleteMember, useUpdateMember } from './helper';
import { toast } from 'sonner';
import { DataTablePagination } from 'components/workspace/projects/table/DataTablePagination';

type TEditedRows = Record<string, boolean>;

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    editedRows: TEditedRows;
    setEditedRows: React.Dispatch<React.SetStateAction<TEditedRows>>;
    revertData: (rowIndex: number) => void;
    saveRow: (rowIndex: number, row: TData, newValue?: string) => void;
    deleteRow: (rowIndex: number, row: TData) => void;
    isPlaceholderData?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    property:
      | {
          type: 'text';
        }
      | {
          type: 'email';
        }
      | {
          type: 'select';
          options: { label: string; value: string }[];
        };
  }
}

export const CustomPeopleTable = () => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { data: peopleData, pagination, setPagination } = useGetPeopleCtx();
  const [data, setData] = useState(() => peopleData.items || []);
  const [sorting, setSorting] = useState<SortingState>([]);
  const originalData = useRef(peopleData.items || []);
  const [editedRows, setEditedRows] = useState<TEditedRows>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const updateMember = useUpdateMember();
  const deleteMember = useDeleteMember();

  const table = useReactTable({
    data,
    columns: tableColumns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      pagination,
    },
    manualPagination: true,
    pageCount: peopleData.totalPages || 0,
    meta: {
      editedRows,
      setEditedRows,
      saveRow: (rowIdx, originalRow) => {
        updateMember.mutate(
          {
            title: originalRow.title,
            email: originalRow.email,
            authority: originalRow.authority,
          },
          {
            onSuccess: () => {
              originalData.current = originalData.current.map((row, index) =>
                index === rowIdx ? data[rowIdx] : row,
              );
              setEditedRows((prev) => ({
                ...prev,
                [rowIdx]: false,
              }));
              toast.success('Member updated successfully');
            },
            onError: () => {
              toast.error('Failed to update member. Please try again.');
            },
          },
        );
      },
      revertData: (rowIndex: number) => {
        setData((prev) => {
          return prev.map((row, index) => {
            if (index === rowIndex) {
              return originalData.current[rowIndex];
            }
            return row;
          });
        });
        setEditedRows((prev) => ({
          ...prev,
          [rowIndex]: false,
        }));
      },
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          }),
        );
      },
      deleteRow: (_, row) => {
        deleteMember.mutate(row.email, {
          onSuccess: () => {
            setData((prev) => prev.filter((member) => member.id !== row.id));
            toast.success('Member deleted successfully');
          },
          onError: () => {
            toast.error('Failed to delete member. Please try again.');
          },
        });
      },
    },
  });

  useEffect(() => {
    setData(peopleData.items || []);
    originalData.current = peopleData.items || [];
  }, [peopleData.items]);

  return (
    <div className='w-full'>
      <div className='flex items-center py-4'>
        <Input
          placeholder='Filter emails...'
          value={(table.getColumn('email')?.getFilterValue() as string) ?? ''}
          onChange={(event) => {
            table.getColumn('email')?.setFilterValue(event.target.value);
          }}
          className='max-w-[300px]'
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' className='ml-auto'>
              Columns <ChevronDown className='ml-2 size-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide() && column.columnDef.header)
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className='capitalize'
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {typeof column.columnDef.header === 'string' && column.columnDef.header
                      ? column.columnDef.header
                      : column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Table className='w-full rounded-xl border bg-white'>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.original.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{
                      minWidth: cell.column.columnDef.size,
                      maxWidth: cell.column.columnDef.size,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={tableColumns.length} className='h-24 text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className='pt-4'>
        <DataTablePagination table={table} />
      </div>
    </div>
  );
};
