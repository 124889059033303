import { isFabricText } from '@/utils/helper';
import { Button } from 'components/ui/button';
import { FC, useEffect, useRef } from 'react';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-slate-100';
const ACTIVE_CL = 'bg-slate-100';

interface FormatTextStyleProps {
  canvasInstance: fabric.Canvas | null;
}
export const FormatTextStyle: FC<FormatTextStyleProps> = ({ canvasInstance }) => {
  const boldRef = useRef<HTMLElement | null>(null);
  const underlineRef = useRef<HTMLElement | null>(null);
  const italicRef = useRef<HTMLElement | null>(null);
  const strikethroughRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!canvasInstance) return;

    const selectionHandler = () => {
      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject)) {
        toggleActiveSelection(boldRef, false);
        toggleActiveSelection(underlineRef, false);
        toggleActiveSelection(italicRef, false);
        toggleActiveSelection(strikethroughRef, false);
        return;
      }

      toggleActiveSelection(boldRef, activeObject.fontWeight === 'bold');
      toggleActiveSelection(underlineRef, Boolean(activeObject.underline));
      toggleActiveSelection(italicRef, activeObject.fontStyle === 'italic');
      toggleActiveSelection(strikethroughRef, Boolean(activeObject.linethrough));
    };

    canvasInstance.on('selection:created', selectionHandler);
    canvasInstance.on('selection:updated', selectionHandler);
    canvasInstance.on('selection:cleared', selectionHandler);

    return () => {
      canvasInstance.off('selection:created', selectionHandler);
      canvasInstance.off('selection:updated', selectionHandler);
      canvasInstance.off('selection:cleared', selectionHandler);
    };
  }, [canvasInstance]);

  const toggleActiveSelection = (
    refReference: React.RefObject<HTMLElement>,
    shouldEnable: boolean,
  ) => {
    if (!refReference.current) return;

    if (shouldEnable) {
      refReference.current.classList.add(ACTIVE_CL);
      return;
    }
    refReference.current.classList.remove(ACTIVE_CL);
  };

  const formatText = (format: 'bold' | 'underline' | 'italic' | 'strikethrough') => {
    if (!canvasInstance) return;

    const activeObject = canvasInstance.getActiveObject();
    if (!activeObject || !isFabricText(activeObject)) return;

    switch (format) {
      case 'bold': {
        const isBold = activeObject.fontWeight === 'bold';
        activeObject.set('fontWeight', isBold ? 'normal' : 'bold');
        toggleActiveSelection(boldRef, !isBold);
        break;
      }
      case 'underline': {
        activeObject.set('underline', !activeObject.underline);
        toggleActiveSelection(underlineRef, Boolean(activeObject.underline));
        break;
      }
      case 'italic': {
        const isItalic = activeObject.fontStyle === 'italic';
        activeObject.set('fontStyle', isItalic ? 'normal' : 'italic');
        toggleActiveSelection(italicRef, !isItalic);
        break;
      }
      case 'strikethrough': {
        activeObject.set('linethrough', !activeObject.linethrough);
        toggleActiveSelection(strikethroughRef, Boolean(activeObject.linethrough));
        break;
      }
    }
    canvasInstance.requestRenderAll();
  };

  return (
    <Button asChild variant='outline' className='w-3/5 rounded-lg bg-white p-1 hover:bg-white'>
      <div className='flex gap-1'>
        <span ref={boldRef} className={ICON_WRAPPER_CL} onClick={() => formatText('bold')}>
          <span className='i-mdi-format-bold size-4' />
        </span>
        <span
          ref={underlineRef}
          className={ICON_WRAPPER_CL}
          onClick={() => formatText('underline')}
        >
          <span className='i-mdi-format-underline size-4' />
        </span>
        <span ref={italicRef} className={ICON_WRAPPER_CL} onClick={() => formatText('italic')}>
          <span className='i-mdi-format-italic size-4' />
        </span>
        <span
          ref={strikethroughRef}
          className={ICON_WRAPPER_CL}
          onClick={() => formatText('strikethrough')}
        >
          <span className='i-mdi-format-strikethrough-variant size-4' />
        </span>
      </div>
    </Button>
  );
};
