import { EditDrawer } from 'components/ai-scene/create/edit-drawer/EditDrawer';
import { FiltersContent } from 'components/ai-scene/create/edit-drawer/FiltersContent';
import { TextSettings } from 'components/ai-scene/create/edit-drawer/TextSettings';
import { LogoContent } from 'components/ai-scene/create/edit-drawer/accordion-contents/LogoContent';
import { BrandPalette } from 'components/ai-scene/create/edit-drawer/color/BrandPalette';
import { ColorSettings } from 'components/ai-scene/create/edit-drawer/color/ColorSettings';
import { FillColorArea } from 'components/ai-scene/create/edit-drawer/color/FillColorArea';
import { Button } from 'components/ui/button';
import { useState } from 'react';

export const AiBannerEditDrawer = () => {
  const [isDrawerShown, setIsDrawerShown] = useState(false);

  return (
    <div className='relative h-full'>
      <div className='absolute right-0 h-full'>
        {!isDrawerShown && (
          <div className='pr-4 pt-4'>
            <Button
              className='w-[120px]'
              onClick={() => setIsDrawerShown((isShown) => !isShown)}
              variant='light'
            >
              Edit
            </Button>
          </div>
        )}
        {isDrawerShown && (
          <EditDrawer
            filterContent={<FiltersContent canvasInstance={null} updateCanvasState={() => null} />}
            colorContent={
              <ColorSettings>
                <FillColorArea canvasInstance={null} updateCanvasState={() => null} />
                <BrandPalette canvasInstance={null} updateCanvasState={() => null} />
              </ColorSettings>
            }
            logoContent={<LogoContent drawLogo={(image, id) => null} />}
          >
            <TextSettings canvasInstance={null}>
              <Button
                variant='ghost'
                onClick={() => setIsDrawerShown((isShown) => !isShown)}
                className={`h-8 w-8 transform rounded-full p-0`}
              >
                <span className={`i-mdi-close size-4 bg-black`}></span>
              </Button>
            </TextSettings>
          </EditDrawer>
        )}
      </div>
    </div>
  );
};
