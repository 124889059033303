import { FC, ReactNode, useRef } from 'react';
import { createProductCreateStore } from './store';
import { ProductCreateContext, TProductCreateContext } from './helper';

interface ProductCreateProviderProps {
  children: ReactNode;
}

export const ProductCreateProvider: FC<ProductCreateProviderProps> = ({ children }) => {
  const storeRef = useRef<TProductCreateContext>();

  if (!storeRef.current) {
    storeRef.current = createProductCreateStore();
  }

  return (
    <ProductCreateContext.Provider value={storeRef.current}>
      {children}
    </ProductCreateContext.Provider>
  );
};
