import { useRef, useState } from 'react';

import { toast } from 'sonner';

import { useUserStore } from '@/providers/user/hooks';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import { useSubscriptionStore } from '@/providers/stripe/subscription/helper';
import { GTM } from '@/utils/gtm';

import { useCreateSubscriptionMutation, useGetPaymentPlans, TPaymentProduct } from './queries';

import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Modal } from 'components/ui/modal';
import { PaymentProduct } from './_components/PaymentProduct';
import { PlanActionButton } from './_components/PlanActionButton';
import { DashboardPayment } from './_components/DashboardPayment';
import { PlanUpgrade } from './_components/PlanUpgrade';
import { PaymentSummary } from './_components/PaymentSummary';
import { Badge } from 'components/ui/badge';
import { PaymentTabOption } from './helper';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { getPreviousPlanType } from 'components/plan-guard/helper';
import { Breakpoints } from '@/utils/general/constant';
import { useWindowSize } from 'usehooks-ts';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

const TAB_TRIGGER_CLASS =
  'rounded-[3px] stroke-[#777777] w-[96px] flex justify-center gap-[6px] bg-white !text-main font-medium text-c-dark data-[state=active]:bg-[#F0F0F0] data-[state=active]:font-semibold data-[state=active]:text-c-dark  data-[state=active]:stroke-[#212121]';
const BADGE_CLASS =
  'absolute right-0  translate-x-20 bg-[#F5F5F5] text-[10px] font-semibold text-c-dark';

export const PlansView: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const { plan, expiry_timestamp: userPlanExpiry, trial_period } = useAccountCtx();
  const { data: paymentPlansData, isLoading } = useGetPaymentPlans();
  const createSubscriptionMutation = useCreateSubscriptionMutation();
  const setSubscriptionState = useSubscriptionStore((state) => state.setSubscriptionState);
  const { width } = useWindowSize();

  const isDesktop = width > Breakpoints.TABLET;

  const userPlan = trial_period ? getPreviousPlanType(plan) : plan;

  const [isProcessing, setIsProcessing] = useState(false);
  const selectedPeriod = useRef<(typeof PaymentTabOption)[keyof typeof PaymentTabOption]>(
    PaymentTabOption.MONTHLY,
  );
  const [isModalShown, setIsModalShown] = useState(false);
  const [planSelectionDetails, setPlanSelectionDetails] = useState({
    planType: '',
    period: '',
    priceId: '',
    price: 0,
    currentPlanName: '',
  });

  const isUpgrade = userPlan && userPlan !== 'free';

  const onPlanSelect = async (planType: string, priceId: string | undefined, price: number) => {
    if (!isUpgrade) GTM.sendInitiateCheckoutEvent();
    try {
      if (!user?.stripe_customer_id) {
        toast.error('Customer could not be found. Please update your payment method first.');
        return;
      }
      if (!planType || !priceId) {
        toast.error('Plan type or price could not be found. Please try again later.');
        return;
      }

      setIsProcessing(true);

      setPlanSelectionDetails({
        planType,
        period: selectedPeriod.current,
        priceId,
        price,
        currentPlanName: userPlan,
      });

      if (!isUpgrade) {
        const subscriptionData = await createSubscriptionMutation.mutateAsync({
          price_id: priceId,
          customer_id: user.stripe_customer_id,
        });

        setSubscriptionState(subscriptionData.subscription_id, subscriptionData.client_secret);
      }
      setIsModalShown(true);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while processing your request.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <section className='flex min-h-full flex-col items-center'>
      <div className='mb-5 text-center'>
        <span className='mb-1 inline-block text-[24px] font-semibold'>Plans for all sizes</span>
        <p className='text-[14px] font-normal text-[#8A8A8A]'>
          Simple pricing. No hidden fees. Advanced features for your business.
        </p>
      </div>

      {isLoading ? (
        <div className='flex justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Tabs
            defaultValue={selectedPeriod.current}
            onValueChange={(value) => {
              selectedPeriod.current =
                value as (typeof PaymentTabOption)[keyof typeof PaymentTabOption];
            }}
            className='h-full w-full rounded-2xl text-center'
          >
            <TabsList className='relative mr-2 rounded-[6px] border border-[#F0F0F0] bg-white'>
              <TabsTrigger className={TAB_TRIGGER_CLASS} value={PaymentTabOption.MONTHLY}>
                Monthly
              </TabsTrigger>
              <TabsTrigger className={TAB_TRIGGER_CLASS} value={PaymentTabOption.YEARLY}>
                Yearly
              </TabsTrigger>
              <Badge className={BADGE_CLASS}>SAVE %10</Badge>
            </TabsList>

            <TabsContent value={PaymentTabOption.MONTHLY} className='h-full w-full pt-2'>
              <div className='m-auto grid max-w-[1500px] gap-4 sm:!grid-cols-1 xl:grid-cols-2'>
                {paymentPlansData?.map((plan: TPaymentProduct) => {
                  const monthlyPrice =
                    plan.prices.find((price) => price.paymentPeriod === 'monthly')?.priceInCents ||
                    0;

                  return (
                    <PaymentProduct
                      {...plan}
                      key={plan.name + plan.productId}
                      paymentPeriod='monthly'
                    >
                      <PlanActionButton
                        plan={plan}
                        userPlan={userPlan}
                        userPlanExpiry={userPlanExpiry}
                        paymentPeriod='monthly'
                        isProcessing={isProcessing}
                        onPlanSelect={(planType, priceId) =>
                          onPlanSelect(planType, priceId, monthlyPrice)
                        }
                        price={monthlyPrice}
                      />
                    </PaymentProduct>
                  );
                })}
              </div>
            </TabsContent>
            <TabsContent value={PaymentTabOption.YEARLY} className='h-full w-full pt-2'>
              <div className='m-auto grid max-w-[1500px] gap-4 sm:!grid-cols-1 xl:grid-cols-2'>
                {paymentPlansData?.map((plan: TPaymentProduct) => {
                  const yearlyPrice =
                    plan.prices.find((price) => price.paymentPeriod === 'yearly')?.priceInCents ||
                    0;

                  return (
                    <PaymentProduct
                      {...plan}
                      key={plan.name + plan.productId}
                      paymentPeriod='yearly'
                    >
                      <PlanActionButton
                        plan={plan}
                        userPlan={userPlan}
                        userPlanExpiry={userPlanExpiry}
                        paymentPeriod='yearly'
                        isProcessing={isProcessing}
                        onPlanSelect={(planType, priceId) =>
                          onPlanSelect(planType, priceId, yearlyPrice)
                        }
                        price={yearlyPrice}
                      />
                    </PaymentProduct>
                  );
                })}
              </div>
            </TabsContent>
          </Tabs>

          {isModalShown &&
            (isDesktop ? (
              <Modal
                open={isModalShown}
                onOpenChange={setIsModalShown}
                titleElement={`${isUpgrade ? 'Upgrade' : 'Subscribe'}`}
                modalContentClassName={`p-5 ${
                  isUpgrade ? 'max-w-[400px] min-h-[330px]' : 'max-w-[938px] min-h-[562px]'
                }`}
              >
                {({ closeModal }) =>
                  isUpgrade ? (
                    <PlanUpgrade
                      currentPlanName={planSelectionDetails.currentPlanName}
                      newPlanName={planSelectionDetails.planType}
                      closeModal={closeModal}
                      priceId={planSelectionDetails.priceId}
                      period={planSelectionDetails.period}
                    />
                  ) : (
                    <DashboardPayment
                      closeModal={closeModal}
                      planType={planSelectionDetails.planType}
                      period={planSelectionDetails.period}
                    >
                      <PaymentSummary
                        planType={planSelectionDetails.planType}
                        period={planSelectionDetails.period}
                        paymentPlans={paymentPlansData}
                      />
                    </DashboardPayment>
                  )
                }
              </Modal>
            ) : (
              <MobileMenuSheet
                isOpen={isModalShown}
                onOpenChange={setIsModalShown}
                titleElement={`${isUpgrade ? 'Upgrade' : 'Subscribe'}`}
              >
                {({ closeModal }) =>
                  isUpgrade ? (
                    <PlanUpgrade
                      currentPlanName={planSelectionDetails.currentPlanName}
                      newPlanName={planSelectionDetails.planType}
                      closeModal={closeModal}
                      priceId={planSelectionDetails.priceId}
                      period={planSelectionDetails.period}
                    />
                  ) : (
                    <DashboardPayment
                      closeModal={closeModal}
                      planType={planSelectionDetails.planType}
                      period={planSelectionDetails.period}
                    >
                      <PaymentSummary
                        planType={planSelectionDetails.planType}
                        period={planSelectionDetails.period}
                        paymentPlans={paymentPlansData}
                      />
                    </DashboardPayment>
                  )
                }
              </MobileMenuSheet>
            ))}
        </>
      )}
    </section>
  );
};
