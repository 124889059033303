import { useLocation, NavLink } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { toast } from 'sonner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { ScrollArea } from 'components/ui/scroll-area';
import { PlanGuard } from 'components/plan-guard/PlanGuard';
import { type NavItem } from 'components/layout/constants';
import { MobileWarningContent } from 'components/common/MobileWarningContent';

interface SideNavProps {
  items: NavItem[];
  setOpen?: (open: boolean) => void;
  className?: string;
}

const CBorder = ({ additionalStyle }: { additionalStyle?: string }) => {
  return (
    <div
      className={`absolute right-[-16px]  z-[999] h-[28px] w-[3px] bg-black ${additionalStyle}`}
    ></div>
  );
};

export function SideNav({ items, setOpen, className }: SideNavProps) {
  const path = useLocation();

  const handleInviteMembersClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      toast(<MobileWarningContent />);
    }
  };

  return (
    <ScrollArea className='w-[247px] md:w-full'>
      <nav>
        {items.map((item) =>
          item.isChidren ? (
            <Accordion
              type='multiple'
              className='mt-4 space-y-2'
              defaultValue={['Creative Suite']}
              key={item.title}
              disabled={item.disabled}
            >
              <AccordionItem value={item.title} className='border-none !text-main'>
                <AccordionTrigger
                  className={cn(
                    buttonVariants({ variant: 'secondary' }),
                    'group relative h-9 w-[232px] flex-row-reverse justify-end rounded-lg p-2 pl-0 !text-main duration-200 hover:bg-muted hover:no-underline md:w-full',
                  )}
                >
                  <>
                    <div className='ml-2 flex items-center gap-2'>
                      <item.icon className={`h-[20px] w-[20px] stroke-c-dark`} />

                      <div className={cn('flex items-center gap-2 text-main duration-200')}>
                        {item.title}
                        {item.disabled && (
                          <div className='rounded-[100px] bg-gray-300 px-[6px] py-[3px] text-[10px] text-black'>
                            SOON
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </AccordionTrigger>
                <AccordionContent className='mb-0 mt-2 pb-0'>
                  {item.children?.map((child) => (
                    <PlanGuard
                      key={child.title}
                      requiredPlan={child.requiredPlan || 'free'}
                      featureDescription={child.featureDescription || ''}
                      showUpgradeIcon
                    >
                      <NavLink
                        key={child.title}
                        to={child.href}
                        className={cn(
                          buttonVariants({ variant: 'secondary' }),
                          'group relative ml-2 flex h-9 w-[223px] justify-start gap-2 rounded-lg px-2 py-4 hover:bg-muted md:w-[calc(100%-30px)]',
                          path.pathname === child.href
                            ? 'bg-muted font-semibold hover:bg-muted'
                            : '',
                        )}
                      >
                        <child.icon
                          className={`h-[20px] w-[20px] ${
                            path.pathname === child.href
                              ? 'stroke-c-dark font-semibold'
                              : 'stroke-crait-gray group-hover:stroke-black'
                          }`}
                        />
                        <div className='text-main'>{child.title}</div>
                        {path.pathname === child.href && <CBorder />}
                      </NavLink>
                    </PlanGuard>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <PlanGuard
              key={item.title}
              requiredPlan={item.requiredPlan || 'free'}
              featureDescription={item.featureDescription || ''}
              showUpgradeIcon
            >
              <NavLink
                key={item.title}
                to={item.href}
                onClick={
                  item.title === 'Invite Members'
                    ? handleInviteMembersClick
                    : () => {
                        if (setOpen) setOpen(false);
                      }
                }
                className={cn(
                  buttonVariants({ variant: 'secondary' }),
                  'group relative mt-0 flex h-9 w-[calc(100%-16px)] justify-start rounded-lg p-2 hover:bg-muted',
                  path.pathname === item.href ? 'bg-muted font-semibold hover:bg-muted' : '',
                )}
              >
                <item.icon
                  className={`h-[20px] w-[20px] ${
                    path.pathname === item.href
                      ? 'stroke-c-dark font-semibold'
                      : 'stroke-crait-gray group-hover:stroke-black'
                  }`}
                />
                <span className='ml-2 text-main'>{item.title}</span>
                {path.pathname === item.href && <CBorder />}
              </NavLink>
            </PlanGuard>
          ),
        )}
      </nav>
    </ScrollArea>
  );
}
