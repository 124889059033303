import { GTM } from '@/utils/gtm';
import { CraitButton } from 'components/common/CraitButton';
import { Modal } from 'components/ui/modal';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { CookiePreferencesModal } from './CookiePreferencesModal';
import { CookiePreferences } from './helper';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

const DEFAULT_PREFERENCES: CookiePreferences = {
  necessary: true,
  preferences: true,
  analytics: true,
  targeting: true,
};

export const COOKIE_PREFERENCES_KEY = 'cookiePreferences';
const COOKIE_DOMAIN = import.meta.env.MODE === 'development' ? undefined : '.crait.it';

const setConsentMode = (preferences: CookiePreferences) => {
  GTM.setConsentModeForAds(preferences.targeting ? 'granted' : 'denied');
  GTM.setConsentModeForAnalytics(preferences.analytics ? 'granted' : 'denied');
};

export const CookieConsentBanner: React.FC = () => {
  const [shouldDisplayBannerOnLoad, setShouldDisplayBannerOnLoad] = useState(false);
  const [isBannerCurrentlyVisible, setIsBannerCurrentlyVisible] = useState(false);
  const [cookiePreferences, setCookiePreferences] =
    useState<CookiePreferences>(DEFAULT_PREFERENCES);

  useEffect(() => {
    const cookieConsent = Cookies.get(COOKIE_PREFERENCES_KEY);
    if (!cookieConsent) {
      setShouldDisplayBannerOnLoad(true);
      setTimeout(() => setIsBannerCurrentlyVisible(true), 10);
    }
  }, []);

  const setCookieAndHideBanner = (preferences: CookiePreferences) => {
    Cookies.set(COOKIE_PREFERENCES_KEY, btoa(JSON.stringify(preferences)), {
      expires: 365 * 2,
      domain: COOKIE_DOMAIN,
      sameSite: 'Strict',
    });
    setConsentMode(preferences);
    setIsBannerCurrentlyVisible(false);
    setTimeout(() => setShouldDisplayBannerOnLoad(false), 300);
  };

  const handleAcceptAll = () => {
    const updatedPreferences: CookiePreferences = {
      necessary: true,
      preferences: true,
      analytics: true,
      targeting: true,
    };
    setCookieAndHideBanner(updatedPreferences);
  };

  const handleRejectAll = () => {
    const updatedPreferences: CookiePreferences = {
      necessary: true,
      preferences: false,
      analytics: false,
      targeting: false,
    };
    setCookieAndHideBanner(updatedPreferences);
  };

  const handleSavePreferences = () => {
    setCookieAndHideBanner(cookiePreferences);
  };

  if (!shouldDisplayBannerOnLoad) {
    return null;
  }

  return (
    <div
      className={`fixed bottom-0 left-0 z-50 flex h-[78px] w-full items-center justify-between bg-[#F5F5F5] p-4 text-c-dark transition-transform duration-300 sm:flex-col sm:gap-8 md:h-auto ${
        isBannerCurrentlyVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className='text-left sm:text-center'>
        <p className='text-[14px] font-semibold'>Our site uses cookies</p>
        <p className='text-[12px]'>
          Like most websites we use cookies to enhance your experience, analyze site traffic and
          deliver personalized content.
          <br />
          <span>
            Read our{' '}
            <a
              href='https://www.crait.it/cookie-policy'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'rgb(153, 153, 153)' }}
            >
              Cookie Policy
            </a>
          </span>
        </p>
      </div>

      <div className='flex w-[500px] gap-[10px] sm:w-[90%] md:flex-col'>
        <Modal
          trigger={
            <CraitButton className='min-w-[180px] bg-[#F5F5F5] md:hidden'>
              Cookie Preferences
            </CraitButton>
          }
          titleElement='Cookie Preferences'
          modalContentClassName='max-w-[720px]'
        >
          {() => (
            <CookiePreferencesModal
              preferences={cookiePreferences}
              setPreferences={setCookiePreferences}
            >
              <div className='flex gap-3 p-4 '>
                <CraitButton
                  onClick={handleRejectAll}
                  className='border border-c-dark bg-white px-4 py-2 text-black'
                >
                  Reject All
                </CraitButton>
                <CraitButton onClick={handleSavePreferences}> Confirm My Choices</CraitButton>
                <CraitButton onClick={handleAcceptAll}> Accept All</CraitButton>
              </div>
            </CookiePreferencesModal>
          )}
        </Modal>
        <MobileMenuSheet
          titleElement='Cookie Preferences'
          triggerButton={
            <CraitButton className='min-w-[180px] bg-[#F5F5F5]'>Cookie Preferences</CraitButton>
          }
          closeAfterClick={false}
        >
          <CookiePreferencesModal
            preferences={cookiePreferences}
            setPreferences={setCookiePreferences}
          >
            <div className='flex gap-3 p-4 md:flex-col'>
              <CraitButton
                onClick={handleRejectAll}
                className='border border-c-dark bg-white px-4 py-2 text-black'
              >
                Reject All
              </CraitButton>
              <CraitButton onClick={handleSavePreferences}> Confirm My Choices</CraitButton>
              <CraitButton onClick={handleAcceptAll}> Accept All</CraitButton>
            </div>
          </CookiePreferencesModal>
        </MobileMenuSheet>
        <CraitButton
          onClick={handleRejectAll}
          className='min-w-[125px] border border-[#EAEAEA] bg-[#F5F5F5] px-4 py-2 text-black'
        >
          <span>Reject All</span>
        </CraitButton>
        <CraitButton onClick={handleAcceptAll} className='min-w-[125px]'>
          Accept All
        </CraitButton>
      </div>
    </div>
  );
};
