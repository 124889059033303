import { MailQuestion } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useUnsubscribeEmail } from './queries';
import { Button } from 'components/ui/button';
import { RoutePaths } from '@/utils/route/constants';

export const EmailUnsubscribePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string | null;
  const [isUnsubscribing, setIsUnsubscribing] = useState(true);

  const navigate = useNavigate();
  const unsubscribeEmail = useUnsubscribeEmail();

  useEffect(() => {
    if (!token) {
      navigate(RoutePaths.BASE);
      return;
    }

    unsubscribeEmail.mutate(
      { token },
      {
        onSuccess: () => {
          toast.success("You've successfully unsubscribed from Crait's mailing list");
          setIsUnsubscribing(false);
        },
        onError: (error: any) => {
          toast.error(`Unsubscription failed: ${error.response?.data?.detail || 'Unknown error'}`);
          navigate(RoutePaths.BASE);
        },
      },
    );
  }, [token]);

  if (!token) return <Navigate replace to={RoutePaths.BASE} />;

  return (
    <div className='flex h-screen flex-col items-center justify-center bg-white'>
      <MailQuestion size={128} color='#C7F1B9' />
      <p className='mb-2 mt-2 text-xl text-gray-600'>
        {isUnsubscribing
          ? 'Unsubscribing from mailing list...'
          : "You've successfully unsubscribed from Crait's mailing list"}
      </p>
      <Button className='mt-4' color='primary' onClick={() => navigate(RoutePaths.BASE)}>
        Go Home
      </Button>
    </div>
  );
};
