import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { BrandPalette } from './BrandPalette';
import { ColorSettings } from 'components/ai-scene/create/edit-drawer/color/ColorSettings';
import { FillColorArea } from 'components/ai-scene/create/edit-drawer/color/FillColorArea';
import { AddText } from 'components/ai-scene/create/edit-drawer/text/AddText';
import { AdjustLineHeight } from 'components/ai-scene/create/edit-drawer/text/AdjustLineHeight';
import { AlignText } from 'components/ai-scene/create/edit-drawer/text/AlignText';
import { FontDetail } from 'components/ai-scene/create/edit-drawer/text/FontDetail';
import { FormatTextStyle } from 'components/ai-scene/create/edit-drawer/text/FormatTextStyle';
import { IconWrapper } from 'components/common/IconWrapper';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-md p-1.5 hover:bg-slate-50';

export const TextTool = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  return (
    <div className='w-[320px] rounded-lg bg-white p-4 shadow-md md:w-[95vw] md:shadow-none'>
      <h2 className='text-[15px] font-semibold md:hidden'>Text</h2>
      <Separator className='my-4' />

      <div className='flex w-full flex-col gap-2'>
        <div className='flex w-full gap-2'>
          <AddText canvasInstance={canvasInstance} />
          <AdjustLineHeight canvasInstance={canvasInstance} />
          <Button asChild variant='outline' className='rounded-lg bg-white px-1 hover:bg-white'>
            <div className='flex gap-1'>
              <IconWrapper
                wrapperProps={{
                  className: `${ICON_WRAPPER_CL} flex-1`,
                }}
                iconClass='i-mdi-arrow-left-right h-4 w-3.5'
              />
              <IconWrapper
                wrapperProps={{
                  className: `${ICON_WRAPPER_CL} flex-1`,
                }}
                iconClass='i-mdi-format-align-left h-4 w-3.5'
              />
              <IconWrapper
                wrapperProps={{
                  className: `${ICON_WRAPPER_CL} flex-1`,
                }}
                iconClass='i-mdi-square-rounded-outline h-4 w-3.5'
              />
            </div>
          </Button>
        </div>
        <FontDetail canvasInstance={canvasInstance} />
        <div className='flex gap-2'>
          <AlignText canvasInstance={canvasInstance} />
          <FormatTextStyle canvasInstance={canvasInstance} />
        </div>
      </div>

      <ColorSettings>
        <FillColorArea canvasInstance={canvasInstance} updateCanvasState={updateCanvasState} />
        <BrandPalette canvasInstance={canvasInstance} updateCanvasState={updateCanvasState} />
      </ColorSettings>
    </div>
  );
};
