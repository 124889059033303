import { useInView } from 'react-intersection-observer';
import { TBrandStyleResponse, useGetInfiniteSearchResults } from '../../helper/queries';
import { useResetInfiniteSearchCache } from '../../visual-guidance/styles/useResetInfiniteSearchCache';
import { useEffect, useMemo } from 'react';
import { RefetchView } from '../../helper/RefetchView';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { BrandStyleItems } from './BrandStylesItems';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';

const MAX_PAGES_IN_CACHE = 2;

export const BrandStyleSection = () => {
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TBrandStyleResponse>({
    searchSection: 'brand_styles',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.brandStyles;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
  });
  useResetInfiniteSearchCache<TBrandStyleResponse>('brand_styles', MAX_PAGES_IN_CACHE);

  const brandStyleId =
    useAiSceneCreateStore((state) => state.aiScenePromptTab.brandStyleId?.id) || null;
  const updateAiScenePromptTab = useAiSceneCreateStore((state) => state.updateAiScenePromptTab);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allStyles = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.brandStyles.items) || [];
  }, [infiniteData]);

  if (isError) {
    return (
      <RefetchView
        disabled={isLoading}
        onClick={() => {
          refetch();
        }}
      >
        <span className='text-[11px]'>Failed to load brand styles.</span>
      </RefetchView>
    );
  }

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex w-full items-end justify-between text-[11px] font-semibold'>
        <div className='flex items-center gap-[6px]'>
          <span className='h-4'>BRAND STYLES ({brandStyleId ? '1/1' : '0/1'})</span>
          {brandStyleId && (
            <span
              className='cursor-pointer text-[11px] font-semibold text-[#E16236]'
              onClick={() => {
                updateAiScenePromptTab({ brandStyleId: null });
              }}
            >
              x
            </span>
          )}
        </div>
        {/* <span className='text-[#E16236] underline'>SEE ALL</span> */}
      </div>

      <ScrollArea className='h-full w-full'>
        <div className='mb-2 flex items-start gap-x-2.5 gap-y-3 p-1'>
          <BrandStyleItems brandStyles={allStyles || []} brandStyleId={brandStyleId} />
        </div>
        {hasNextPage && (
          <div className='relative flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}

        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  );
};
