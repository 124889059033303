import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AiSceneSuggestionsEndpoint } from '@/utils/fetch/constants';
import { getAiSceneSuggestionsApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

type TDimensions = {
  x: number;
  y: number;
};

type TLayoutSize = {
  id: string;
  name: string;
  dimensions: TDimensions;
  status: string;
};

type TGetLayoutSizesResponse = {
  custom?: TLayoutSize[];
  common: TLayoutSize[];
};

const getLayoutSizes = async (organizationId: number) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.GET_LAYOUT_SIZES,
  });
  return apiClient.get<TGetLayoutSizesResponse>(url);
};

export const GET_LAYOUT_SIZES_QUERY_KEY = 'getLayoutSizesQueryKey';

export const useGetLayoutSizesQuery = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useQuery({
    queryKey: [
      {
        _name: GET_LAYOUT_SIZES_QUERY_KEY,
      },
    ],
    queryFn: () => {
      return getLayoutSizes(organizationId);
    },
  });
};

type TCreateLayoutSizePayload = {
  name: string;
  width: number;
  height: number;
};

const createLayoutSize = async ({
  organizationId,
  name,
  width,
  height,
}: TCreateLayoutSizePayload & {
  organizationId: number;
}) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.POST_LAYOUT_SIZE,
  });
  const response = await apiClient.post<TLayoutSize>(url, {
    name,
    width,
    height,
  });
  return response.data;
};

export const useCreateLayoutSizeMutation = () => {
  const queryClient = useQueryClient();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationFn: (payload: TCreateLayoutSizePayload) =>
      createLayoutSize({ ...payload, organizationId }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [{ _name: GET_LAYOUT_SIZES_QUERY_KEY }],
        exact: false,
      });
    },
    onError: (error) => {
      console.error('Mutation failed:', error);
    },
  });
};

const deleteLayoutSize = async ({
  organizationId,
  layout_size_id,
}: {
  organizationId: number;
  layout_size_id: string;
}) => {
  const url = getAiSceneSuggestionsApiUrl({
    organizationId,
    subpath: AiSceneSuggestionsEndpoint.DELETE_LAYOUT_SIZE,
  });
  const baseUrl = `${url}/${layout_size_id}`;
  await apiClient.delete(baseUrl);
};

export const useDeleteLayoutSizeMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneSuggestionsEndpoint.DELETE_LAYOUT_SIZE],
    mutationFn: ({ layout_size_id }: { layout_size_id: string }) => {
      return deleteLayoutSize({ organizationId, layout_size_id });
    },
  });
};
