import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { ACCORDION_ITEMS, TAccordionSection } from './helpers';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';

const _getSelectedCount = (length: number) => (length > 0 ? `(${length} Selected)` : undefined);
const _capitalizeText = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const BannerAccordions = () => {
  const assetsLen = useBannerCreateStore((s) => s.assets.length);
  const templatesLen = useBannerCreateStore((s) => s.templates.length);
  const dimensionsLen = useBannerCreateStore((s) => s.dimensions.length);
  const style = useBannerCreateStore((s) => s.style);
  const objective = useBannerCreateStore((s) => s.objective);
  const brandKitLen = useBannerCreateStore(
    (s) => s.brandKitColors.length + s.brandKitFonts.length + s.brandKitLogos.length,
  );

  const getSelectedCount = (type: TAccordionSection) => {
    switch (type) {
      case 'dimensions': {
        return _getSelectedCount(dimensionsLen);
      }
      case 'assets': {
        return _getSelectedCount(assetsLen);
      }
      case 'style': {
        return style && _capitalizeText(style);
      }
      case 'copy-text': {
        return;
      }
      case 'template': {
        return _getSelectedCount(templatesLen);
      }
      case 'objective': {
        return objective && _capitalizeText(objective);
      }
      case 'brandkit': {
        return _getSelectedCount(brandKitLen);
      }
      default:
        return;
    }
  };

  return (
    <Accordion type='single' collapsible className='flex w-full flex-col gap-2'>
      {ACCORDION_ITEMS.map((item) => {
        return (
          <AccordionItem
            key={item.value}
            value={item.value}
            className='w-full rounded-lg border border-slate-400'
          >
            <AccordionTrigger className='h-12 border-slate-500 py-2 pl-4 pr-2 font-bold underline-offset-[12px] data-[state=open]:underline'>
              <span>
                {item.title} &nbsp;
                <span className='font-medium text-gray-400'>{getSelectedCount(item.value)}</span>
              </span>
            </AccordionTrigger>
            <AccordionContent className='overflow-hidden px-4 pt-4'>
              {item.content}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
