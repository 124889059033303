import { CraitButton } from 'components/common/CraitButton';
import React from 'react';

interface OutpaintButtonProps {
  outpaintAllCanvases: () => Promise<void>;
  processingState: string;
}

export const OutpaintButton: React.FC<OutpaintButtonProps> = ({
  outpaintAllCanvases,
  processingState,
}) => {
  return (
    <CraitButton onClick={outpaintAllCanvases} disabled={processingState === 'outpainting'}>
      Resize
    </CraitButton>
  );
};
