import { Button } from 'components/ui/button';
import React, { useState } from 'react';
import { useGetProrationAmount, useUpgradeSubscriptionMutation } from '../queries';
import { toast } from 'sonner';
import { Link, useNavigate } from 'react-router-dom';
import { getTermsOfUseLink } from '../helper';
import { RoutePaths } from '@/utils/route/constants';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { formatCentsToDollars } from '../helper';

type PlanUpgradeProps = {
  currentPlanName: string;
  newPlanName: string;
  closeModal: () => void;
  priceId: string;
  period: string;
};

export const PlanUpgrade: React.FC<PlanUpgradeProps> = ({
  currentPlanName,
  newPlanName,
  closeModal,
  priceId,
  period,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const { data: prorationAmount, isLoading } = useGetProrationAmount(priceId);
  const { mutateAsync: upgradeSubscription, isPending } = useUpgradeSubscriptionMutation();

  const handleUpgrade = async () => {
    try {
      await upgradeSubscription({ price_id: priceId });
      toast.success('Subscription upgraded successfully.');
      navigate(`/${RoutePaths.ACCOUNT}`);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while upgrading your subscription.');
    }
  };

  if (isLoading || !prorationAmount)
    return (
      <div className='flex justify-center'>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className='pt-4'>
      <p className='text-[12px]'>Any unused fees will be credited towards to the new plan.</p>
      <div className='mt-4'>
        <div className='flex justify-between'>
          <span className='text-[13px] font-medium'>Unused</span>
          <span className='text-[13px] font-semibold'>
            -${formatCentsToDollars(Math.abs(prorationAmount.unusedAmountInCents))}
          </span>
        </div>
        <div className='text-[12px]'>From {currentPlanName}</div>
        <div className='mt-2 flex justify-between'>
          <span className='text-[13px] font-medium'>Price of plan</span>
          <span className='text-[13px] font-semibold'>
            +${formatCentsToDollars(prorationAmount.fullPriceInCents)} / {period}
          </span>
        </div>
        <div className='text-[12px] '>To {newPlanName}</div>

        <div className='mt-4 flex justify-between border-t pt-2'>
          <span className='text-[13px] font-medium'>Total</span>
          <div className='flex justify-between gap-2 '>
            <span className='text-[13px] text-gray-500  line-through'>
              ${formatCentsToDollars(prorationAmount.fullPriceInCents)}
            </span>
            <span className='text-[13px] font-semibold'>
              ${formatCentsToDollars(prorationAmount.totalAmountInCents)}
            </span>
          </div>
        </div>

        <div className='mt-4'>
          <label className='flex items-center gap-1 text-[12px]'>
            <input
              type='checkbox'
              className='mr-2'
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            I have read and agree to the{' '}
            <Link
              to={getTermsOfUseLink({ plan: newPlanName, period })}
              target='_blank'
              className='text-[12px] underline'
            >
              Terms of Use
            </Link>
            .
          </label>
        </div>
        <Button
          type='button'
          size='lg'
          className='mt-4 w-full rounded-lg bg-crait-green-primary px-4 py-2 font-bold text-c-dark'
          onClick={handleUpgrade}
          disabled={isPending || !isChecked}
        >
          {isPending ? 'Upgrading...' : 'Upgrade'}
        </Button>
      </div>
    </div>
  );
};
