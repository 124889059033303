import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';
import { useNavigate } from 'react-router';
import { Label } from 'components/ui/label';
import { FC, useMemo, useRef, useState, useEffect } from 'react';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import {
  usePartialUpdateProductDetail,
  useUpdateProductDetail,
} from 'pages/workspace/brand-library/products/helper';
import invariant from 'tiny-invariant';
import { toast } from 'sonner';
import { RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { ImageType } from '@/utils/store/constants';
import { scaleAndConvertFabricImageObjectToFile, triggerBlurOnEnter } from '@/utils/helper';
import { useUploadFileMutation } from 'pages/workspace/brand-library/product-edit/helper';
import {
  useCreateProductCategoryMutation,
  useGetProductCategories,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { useQueryClient } from '@tanstack/react-query';
import { ListSearchWithCreateButton } from 'components/workspace/brand-library/products-edit/ListSearchWithCreateButton';
import { TProductResponse } from '../helper/queries';
import { CraitButton } from 'components/common/CraitButton';
import { useProductProperties } from 'components/workspace/brand-library/products/useProductProperties ';
import { ProductCaptionInput } from 'components/workspace/brand-library/products/ProductCaptionInput';

const ProductField = {
  SKU: 'productCode',
  TITLE: 'productName',
} as const;

interface ProductEditBoxProps {
  closeModal: () => void;
  product: TProductResponse;
}

export const ProductEditBox: FC<ProductEditBoxProps> = ({ product, closeModal }) => {
  const navigate = useNavigate();
  const skuRef = useRef<HTMLInputElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const canvasInstance = useProductCreateStore((state) => state.canvasInstance);
  const updatePartialProductDetail = usePartialUpdateProductDetail();
  const updateProductDetail = useUpdateProductDetail();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(
    product.category.id,
  );
  const uploadFile = useUploadFileMutation();
  const createProductCategoryMutation = useCreateProductCategoryMutation();
  const { data: categories, refetch: refetchCategories } = useGetProductCategories();
  const [status, setStatus] = useState<'idle' | 'pending'>('idle');
  const queryClient = useQueryClient();

  const { productProperties, handleCaptionChange, setProductProperties } = useProductProperties();

  useEffect(() => {
    if (product.properties) {
      setProductProperties({ ...product.properties });
    }
  }, [product, setProductProperties]);

  const productId = product.id;

  const categoryOptions = useMemo(() => {
    return categories?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categories]);

  const updateProduct = async () => {
    try {
      if (!productId || !canvasInstance) return;

      setStatus('pending');
      const transparentImage = canvasInstance
        .getObjects()
        .find((obj) => obj.imageType === ImageType.TRANSPARENT);
      invariant(transparentImage, 'Transparent image not found');

      transparentImage.set({ visible: true });
      canvasInstance.requestRenderAll();
      const transparentImageAsFile = await scaleAndConvertFabricImageObjectToFile(
        transparentImage as fabric.Image,
        'transparent.png',
      );
      const transparentImageUrl = await uploadFile.mutateAsync({ file: transparentImageAsFile });
      await updateProductDetail.mutateAsync({
        productId,
        productCode: skuRef.current?.value || product.product_code,
        productName: titleRef.current?.value || product.name,
        originalImage: product.image.original,
        categoryId: selectedCategoryId || product.category.id,
        transparentImage: transparentImageUrl,
        productProperties,
      });
      queryClient.invalidateQueries({
        queryKey: [{ searchSection: 'products' }],
        exact: false,
      });
      closeModal();
      toast.success('Product updated');
    } catch (error) {
      console.error('Error updating product', error);
      toast.error('Failed to update product');
    } finally {
      setStatus('idle');
    }
  };

  const renameProduct = async (event: React.FocusEvent<HTMLInputElement>) => {
    try {
      const { value, name } = event.target;
      if (!name || !value) return;
      invariant(name === ProductField.SKU || name === ProductField.TITLE, 'Invalid field name');
      invariant(productId, 'Product id is required');

      const isSku = name === ProductField.SKU;
      const existingValue = isSku ? product.product_code : product.name;
      if (existingValue === value) return;

      await updatePartialProductDetail.mutateAsync({
        productId,
        [name]: value,
      });
    } catch (error) {
      console.error('Error updating product title', error);
      toast.error('Failed to update product title');
    }
  };

  const handleCategoryCreate = async (name: string) => {
    try {
      const newCategory = await createProductCategoryMutation.mutateAsync(name);
      toast.success('Category created');
      refetchCategories();
      setSelectedCategoryId(newCategory.id);
    } catch (error) {
      console.error('Error creating category', error);
      toast.error('Failed to create category');
      throw error;
    }
  };

  return (
    <div className='flex w-[300px] gap-2 rounded-lg bg-white px-3 pb-2 pt-3'>
      <div className='flex flex-col gap-2'>
        <div className='flex gap-2'>
          <div className='flex w-1/3 flex-col gap-2'>
            <Label htmlFor={ProductField.SKU} className='text-xs'>
              SKU
            </Label>
            <Input
              ref={skuRef}
              id={ProductField.SKU}
              name={ProductField.SKU}
              className='text-xs'
              defaultValue={product.product_code}
              onBlur={renameProduct}
              onKeyDown={triggerBlurOnEnter}
            />
          </div>
          <div className='flex w-2/3 flex-col gap-2 '>
            <Label htmlFor={ProductField.TITLE} className='text-xs'>
              Product Name
            </Label>
            <Input
              ref={titleRef}
              id={ProductField.TITLE}
              name={ProductField.TITLE}
              className='text-xs'
              defaultValue={product.name}
              onBlur={renameProduct}
              onKeyDown={triggerBlurOnEnter}
            />
          </div>
        </div>
        <ProductCaptionInput
          transparentImage={product.image.transparent}
          caption={productProperties.caption || product.properties?.caption || ''}
          onCaptionChange={handleCaptionChange}
          isEditMode={true}
        />
        <p className='text-xs font-medium'>Category name</p>
        <ListSearchWithCreateButton
          options={categoryOptions || []}
          onItemSelect={(option) => {
            setSelectedCategoryId(option.value);
          }}
          controlledValue={selectedCategoryId}
          onCategoryCreate={handleCategoryCreate}
        />
        <Separator />
        <div className='flex flex-col gap-2'>
          <CraitButton className='flex' onClick={updateProduct} disabled={status === 'pending'}>
            Done
          </CraitButton>
          <Button className='flex' variant='outline' onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
