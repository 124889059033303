import { Button } from 'components/ui/button';
import { FC, ReactNode, useState } from 'react';
import { FileUploadInput } from './FileUploadInput';
import { useNavigate } from 'react-router';
import { TDerivedCreateState } from '../../products-create/ProductCreateBox';
import { toast } from 'sonner';
import {
  useGetRemoveBgStatusMutation,
  useRemoveBgMutation,
  useUploadFileMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { PROCESSING_STATUSES } from 'components/ai-scene/review/queries';

type TFileUploadViewProps = {
  children: ReactNode;
};

export const FileUploadView: FC<TFileUploadViewProps> = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const uploadFile = useUploadFileMutation();
  const removeImageBackground = useRemoveBgMutation();
  const { mutate: getRemoveBgStatusMutate } = useGetRemoveBgStatusMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const uploadSingleProduct = async () => {
    try {
      if (!file) return;

      setIsSubmitting(true);
      const originalImage = await uploadFile.mutateAsync({ file });
      const removeBgResponse = await removeImageBackground.mutateAsync({
        action: 'remove_background',
        imageUrl: originalImage,
      });
      const pollRemoveBgStatus = () => {
        getRemoveBgStatusMutate(removeBgResponse.jobId, {
          onSuccess: (data) => {
            if (PROCESSING_STATUSES.includes(data.status)) {
              setTimeout(pollRemoveBgStatus, 1000);
              return;
            }
            if (data.status !== 'ready') {
              toast.error('Failed to upload image.');
              setIsSubmitting(false);
              return;
            }

            navigate('create', {
              state: { originalImage, transparentImage: data.maskUrl } as TDerivedCreateState,
            });
            setIsSubmitting(false);
          },
          onError: (err) => {
            console.error(err);
            toast.error('Failed to apply enhance. Please try again.');
            setIsSubmitting(false);
          },
        });
      };
      pollRemoveBgStatus();
    } catch (error) {
      toast.error('Failed to upload image.');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <FileUploadInput onFileUpload={setFile} />
      <div className='mx-auto mt-5 flex w-72 flex-col gap-3'>
        <Button className='w-full' disabled={!file || isSubmitting} onClick={uploadSingleProduct}>
          {isSubmitting ? 'Uploading' : 'Upload'}
        </Button>
        {children}
      </div>
    </div>
  );
};
