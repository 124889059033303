import { MAX_CANVAS_HISTORY } from '@/utils/store/constants';
import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { fabric } from 'fabric';

export type TAiSceneImage = { id: string; image: string };

export type TProductCreateStore = {
  canvasState: string;
  canvasInstance: fabric.Canvas | null;
  previousCanvasStates: string[];
  nextCanvasStates: string[];
  setCanvasInstance: (canvas: fabric.Canvas | null) => void;
  updateCanvasState: (newState: string) => void;
  undoCanvasState: () => void;
  redoCanvasState: () => void;
  resetCanvasState: () => void;
  drawingMode: fabric.Canvas['currentDrawingType'];
  setDrawingMode: (mode: fabric.Canvas['currentDrawingType']) => void;
};

const useProductCreateSlice: ImmerStateCreator<TProductCreateStore> = (set) => ({
  canvasInstance: null,
  setCanvasInstance: (canvas) => {
    set((state) => {
      state.canvasInstance = canvas as any;
    }),
      false,
      'setCanvasInstance';
  },
  canvasState: '',
  previousCanvasStates: [],
  nextCanvasStates: [],
  updateCanvasState: (newState) => {
    set(
      (state) => {
        if (!newState) {
          state.canvasState = newState;
          state.previousCanvasStates = [];
          state.nextCanvasStates = [];
          return;
        }

        // The current state must be saved to the history before state update,
        // otherwise the prev state will be same as the latest state.
        if (state.canvasState) {
          const newPrev = [...state.previousCanvasStates, state.canvasState].slice(
            -MAX_CANVAS_HISTORY,
          );
          // Add the current state to history only if it's not the initial setup
          state.previousCanvasStates = newPrev;
        }

        state.canvasState = newState;
        state.nextCanvasStates = [];
      },
      false,
      'updateCanvasState',
    );
  },
  undoCanvasState: () => {
    set(
      (state) => {
        if (state.previousCanvasStates.length === 0) return;

        // Add the current state to the start of next states before changing it.
        state.nextCanvasStates = [state.canvasState, ...state.nextCanvasStates].slice(
          0,
          MAX_CANVAS_HISTORY,
        );

        const previousState = state.previousCanvasStates[state.previousCanvasStates.length - 1];
        const newPreviousSceneCanvasStates = state.previousCanvasStates.slice(0, -1);

        state.canvasState = previousState;
        state.previousCanvasStates = newPreviousSceneCanvasStates;
      },
      false,
      'undoCanvasState',
    );
  },
  redoCanvasState: () => {
    set(
      (state) => {
        if (state.nextCanvasStates.length === 0) return;

        // Add the current state to the end of previous states before changing it
        state.previousCanvasStates = [...state.previousCanvasStates, state.canvasState].slice(
          -MAX_CANVAS_HISTORY,
        );

        const nextState = state.nextCanvasStates[0];
        const newNextSceneCanvasStates = state.nextCanvasStates.slice(1);

        state.canvasState = nextState;
        state.nextCanvasStates = newNextSceneCanvasStates;
      },
      false,
      'redoCanvasState',
    );
  },
  resetCanvasState: () => {
    set(
      (state) => {
        state.canvasState = '';
        state.previousCanvasStates = [];
        state.nextCanvasStates = [];
      },
      false,
      'resetCanvasState',
    );
  },
  drawingMode: undefined,
  setDrawingMode: (mode) => {
    set((state) => {
      state.drawingMode = mode;
    }),
      false,
      'setDrawingMode';
  },
});

export const createProductCreateStore = (initProps?: Partial<TProductCreateStore>) => {
  return createStore<TProductCreateStore>()(
    devtools(
      immer((...props) => ({
        ...useProductCreateSlice(...props),
        ...initProps,
      })),
      {
        name: 'ProductCreateStore',
        trace: true,
      },
    ),
  );
};
