import { Button } from 'components/ui/button';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';
import { useNavigate, useParams } from 'react-router';
import { AiSceneSideMenus, useMenuStore } from '../../side-menu/store';

export const MobileSceneEditPageBackButton = () => {
  const basePath = useAISceneOrImagePath();
  const navigate = useNavigate();
  const projectId = useParams().projectId;
  const { setActiveMenu } = useMenuStore((state) => ({
    setActiveMenu: state.setActiveMenu,
  }));

  const navigateToProjectCreatePage = () => {
    navigate(`/${basePath}/${projectId}`);
    setActiveMenu(AiSceneSideMenus.CREATE_TOOL);
  };

  return (
    <div className='fixed bottom-0 left-0 right-0 z-50  bg-white p-2 shadow-md'>
      <Button
        onClick={navigateToProjectCreatePage}
        variant='outline'
        className='w-full rounded-[10px] border-[#EAEAEA]'
      >
        Go Back to Craiting
      </Button>
    </div>
  );
};
