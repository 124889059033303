import create from 'zustand';

interface MobilSidebarState {
  isMobilSidebarOpen: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
}

export const useMobilSidebarStore = create<MobilSidebarState>((set) => ({
  isMobilSidebarOpen: false,
  toggleSidebar: () => set((state) => ({ isMobilSidebarOpen: !state.isMobilSidebarOpen })),
  closeSidebar: () => set({ isMobilSidebarOpen: false }),
}));
