import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

interface IModalViewProps {
  trigger?: ReactNode;
  titleElement: ReactNode;
  children: ReactNode | ((props: { closeModal: () => void }) => ReactElement | null);
  modalContentClassName?: string;
}

export const ProjectModalView: FC<
  IModalViewProps & Omit<React.ComponentPropsWithoutRef<typeof Dialog>, 'children'>
> = ({ trigger, children, titleElement, modalContentClassName, ...props }) => {
  const [isOpen, setIsOpen] = useState(props.open || false);

  useEffect(() => {
    setIsOpen(props.open || false);
  }, [props.open]);

  const childrenProp =
    typeof children === 'function'
      ? children({
          closeModal: () => {
            setIsOpen(false);
            props.onOpenChange?.(false);
          },
        })
      : children;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isShown) => {
        setIsOpen(isShown);
        props.onOpenChange?.(isShown);
      }}
    >
      {trigger && <DialogTrigger className='btn btn-primary '>{trigger}</DialogTrigger>}
      <DialogContent className={cn(' rounded-lg bg-white p-6', modalContentClassName)}>
        <DialogHeader>
          <DialogTitle className='text-lg font-medium'>{titleElement}</DialogTitle>
        </DialogHeader>
        {isOpen && childrenProp}
      </DialogContent>
    </Dialog>
  );
};
