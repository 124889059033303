import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AiSceneGenerationEndpoint } from '@/utils/fetch/constants';
import { getAiSceneGenerationApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TLastGenerationParams } from 'pages/workspace/projects/helper';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

export const PROCESSING_STATUSES: TScene['status'][] = ['pending', 'in_progress'];

export type TScene = {
  id: string;
  versionId: string;
  organizationId: number;
  projectId: string;
  creatorId: number;
  name: string;
  status: 'pending' | 'cancelled' | 'in_progress' | 'error' | 'ready' | 'deleted';
  // Original image to be used in canvas operations.
  imageUrl: string;
  // Light preview to be used in the img tag.
  thumbnailUrl: string;
  identityStrength: number;
  creation_timestamp: number;
  isSettingsSaved: boolean;
  isFavorited: boolean;
  generationParameters: TLastGenerationParams;
  parentSceneId: string;
  versionHistory: Array<{
    versionId: string;
    timestamp: number;
    imageUrl: string;
    action: string;
    thumbnailUrl: string;
  }>;
};

export const getSceneById = async ({
  sceneId,
  projectId,
  organizationId,
}: {
  sceneId: string;
  organizationId: number;
  projectId: string;
}): Promise<TScene> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}`;

  const response = await apiClient.get<TScene>(url, {
    params: {
      scene_id: sceneId,
      project_id: projectId,
      organization_id: organizationId,
    },
  });
  return response.data;
};

export const useLazyGetScene = () => {
  const queryClient = useQueryClient();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const fetchSceneById = useCallback(
    (sceneId: string, projectId: string) => {
      return queryClient.fetchQuery({
        queryKey: [{ url: AiSceneGenerationEndpoint.BASE, projectId, organizationId, sceneId }],
        queryFn: ({ queryKey }) => {
          return getSceneById({
            sceneId: queryKey[0].sceneId,
            projectId: queryKey[0].projectId,
            organizationId: queryKey[0].organizationId,
          });
        },
        staleTime: 0,
      });
    },
    [organizationId, queryClient],
  );

  return { fetchSceneById };
};

export const renameScene = async ({
  sceneId,
  projectId,
  organizationId,
  name,
}: {
  sceneId: string;
  projectId: string;
  organizationId: number;
  name: string;
}): Promise<void> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}`;
  await apiClient.patch(url, { name });
};

export const deleteScene = async ({
  sceneId,
  projectId,
  organizationId,
}: {
  sceneId: string;
  projectId: string;
  organizationId: number;
}): Promise<void> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}`;
  await apiClient.delete(url);
};

export const useDeleteSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE],
    mutationFn: ({ sceneId, projectId }: { sceneId: string; projectId: string }) => {
      return deleteScene({ sceneId, projectId, organizationId });
    },
  });
};

export const useRenameSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE],
    mutationFn: ({
      sceneId,
      projectId,
      name,
    }: {
      sceneId: string;
      projectId: string;
      name: string;
    }) => {
      return renameScene({ sceneId, projectId, organizationId, name });
    },
  });
};

export const favoriteScene = async ({
  sceneId,
  projectId,
  organizationId,
  action,
}: {
  sceneId: string;
  projectId: string;
  organizationId: number;
  action: 'add' | 'remove';
}): Promise<void> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}/favorite?action=${action}`;
  await apiClient.post(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const useFavoriteSceneMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE, 'favorite'],
    mutationFn: ({
      sceneId,
      projectId,
      action,
    }: {
      sceneId: string;
      projectId: string;
      action: 'add' | 'remove';
    }) => {
      return favoriteScene({ sceneId, projectId, organizationId, action });
    },
  });
};

type TSaveSceneSettingsPayload = {
  sceneId: string;
  projectId: string;
  organizationId: number;
  action: 'add' | 'remove';
};

export const saveSceneSettings = async ({
  sceneId,
  projectId,
  organizationId,
  action,
}: TSaveSceneSettingsPayload): Promise<void> => {
  const baseUrl = getAiSceneGenerationApiUrl({
    projectId,
    organizationId,
    subpath: AiSceneGenerationEndpoint.BASE,
  });
  const url = `${baseUrl}${sceneId}/save-settings?action=${action}`;
  return apiClient.get(url);
};

export const useSaveSceneSettingsMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [AiSceneGenerationEndpoint.BASE, 'save-settings-mutation'],
    mutationFn: (props: Omit<TSaveSceneSettingsPayload, 'organizationId'>) => {
      return saveSceneSettings({ organizationId, ...props });
    },
  });
};
