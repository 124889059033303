import { MenuItemView, TMenuOption } from 'components/common/MenuItemView';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'components/ui/button';
import { TooltipItem } from 'components/common/TooltipItem';
import { Input } from 'components/ui/input';

interface ListSearchWithCreateButtonProps {
  controlledValue?: string | undefined;
  defaultValue?: string;
  onItemSelect?: (option: TMenuOption) => void;
  options: { value: string; label: string }[];
  onCategoryCreate?: (name: string) => Promise<void>;
}
export const ListSearchWithCreateButton: FC<ListSearchWithCreateButtonProps> = ({
  controlledValue,
  onItemSelect,
  options,
  onCategoryCreate,
  defaultValue,
}) => {
  const [shouldShowNewCategoryInput, setShouldShowNewCategoryInput] = useState(false);
  const [listOptions, setListOptions] = useState<{ value: string; label: string }[]>(options);
  const addedInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setListOptions(options);
  }, [options]);

  const addedCategoryRefCallback = useCallback((node: HTMLInputElement | null) => {
    if (!node) return;

    node.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  return (
    <MenuItemView
      selectMessage='Select category'
      emptyMessage='No results.'
      inputPlaceholder='Search'
      options={listOptions}
      triggerProps={{
        className: 'justify-between w-full pr-2 text-xs',
      }}
      className='h-[220px]'
      onItemSelect={(option: TMenuOption) => onItemSelect?.(option)}
      controlledValue={controlledValue}
      defaultValue={defaultValue}
      footerButton={
        <div className='flex justify-center px-3 pb-1'>
          <Button
            onClick={() => setShouldShowNewCategoryInput(true)}
            size='sm'
            className='z-10 h-7 w-full rounded-lg bg-[#082c321a] text-xs text-crait-primary'
          >
            Add new category
          </Button>
        </div>
      }
    >
      {shouldShowNewCategoryInput && (
        <div ref={addedCategoryRefCallback} className='flex flex-col gap-1 px-2'>
          <Input placeholder='Enter category name' autoFocus ref={addedInputRef} />
          <div className='flex justify-center gap-1'>
            <TooltipItem
              side='bottom'
              className='mr-2'
              trigger={
                <Button
                  size='sm'
                  variant='outline'
                  onClick={async () => {
                    if (!addedInputRef.current?.value) return;

                    await onCategoryCreate?.(addedInputRef.current.value);
                    setShouldShowNewCategoryInput(false);
                  }}
                  className='rounded-full px-2 py-1'
                >
                  <span className='i-mdi-check h-5 w-5 text-green-600'></span>
                </Button>
              }
            >
              Save
            </TooltipItem>
            <TooltipItem
              side='bottom'
              className='mr-2'
              trigger={
                <Button
                  size='sm'
                  variant='outline'
                  onClick={() => {
                    setShouldShowNewCategoryInput(false);
                  }}
                  className='rounded-full px-2 py-1'
                >
                  <span className='i-mdi-close h-5 w-5'></span>
                </Button>
              }
            >
              Cancel
            </TooltipItem>
          </div>
        </div>
      )}
    </MenuItemView>
  );
};
