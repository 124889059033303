import React, { useEffect, useRef } from 'react';
import { Button } from 'components/ui/button';
import { fabric } from 'fabric';
import { serializeCanvas } from '@/utils/helper';
import { useEnhanceImageQuality } from '../useEnhanceImageQuality';

import EnhanceIcon from '@/assets/icons/ai-scene-icons/enhance.svg?react';
import CopyIcon from '@/assets/icons/ai-scene-icons/copy.svg?react';
import MirrorIcon from '@/assets/icons/ai-scene-icons/mirror.svg?react';
import BringToFrontIcon from '@/assets/icons/ai-scene-icons/bringFront.svg?react';
import SendToBackIcon from '@/assets/icons/ai-scene-icons/sendToBack.svg?react';
import TrashIcon from '@/assets/icons/ai-scene-icons/trash.svg?react';

import { TooltipItem } from 'components/common/TooltipItem';

const _getIsProductOrElement = (selectedObject: fabric.Object | null) =>
  selectedObject?.imageType === 'element' || selectedObject?.imageType === 'product';

const WIDGET_TOP_OFFSET = 80;

interface OverlayWidgetProps {
  fabricRef: fabric.Canvas | null;
  updateCanvasState: (state: string, caller?: string) => void;
}

export const OverlayWidget: React.FC<OverlayWidgetProps> = ({ fabricRef, updateCanvasState }) => {
  const widgetRef = useRef<HTMLDivElement>(null);
  const enhanceRef = useRef<HTMLButtonElement>(null);
  const enhanceQuality = useEnhanceImageQuality(enhanceRef.current);

  useEffect(() => {
    if (!fabricRef || !widgetRef.current) return;

    const updateWidget = (object: fabric.Object) => {
      if (!widgetRef.current) return;

      if (_getIsProductOrElement(object)) {
        enhanceRef.current?.classList.remove('hidden');
      } else {
        enhanceRef.current?.classList.add('hidden');
      }

      const rect = object.getBoundingRect();
      let top = rect.top - WIDGET_TOP_OFFSET;
      let left = rect.left + rect.width / 2;

      const canvasHeight = fabricRef.getHeight();
      const canvasWidth = fabricRef.getWidth();

      // Adjust the widget's position to prevent it from going outside the canvas
      if (top < 0) top = 0;
      if (top + widgetRef.current.clientHeight > canvasHeight)
        top = canvasHeight - widgetRef.current.clientHeight;
      if (left < widgetRef.current.clientWidth / 2) left = widgetRef.current.clientWidth / 2;
      if (left + widgetRef.current.clientWidth / 2 > canvasWidth)
        left = canvasWidth - widgetRef.current.clientWidth / 2;

      widgetRef.current.style.top = `${top}px`;
      widgetRef.current.style.left = `${left}px`;
      widgetRef.current.style.display = 'block';
    };

    const hideWidget = () => {
      if (!widgetRef.current) return;

      widgetRef.current.style.display = 'none';
    };

    const selectionHandler = () => {
      const activeObject = fabricRef.getActiveObject();
      const isSelectionIgnored = activeObject?.get('isSelectionIgnored');
      if (!activeObject || isSelectionIgnored) {
        hideWidget();
        if (isSelectionIgnored) fabricRef.discardActiveObject();
        return;
      }

      updateWidget(activeObject);
    };

    const objectEventHandler = (event: fabric.IEvent) => {
      const selectedObject = event.target as fabric.Object;
      const isSelectionIgnored = selectedObject?.get('isSelectionIgnored');
      if (!selectedObject || isSelectionIgnored) {
        hideWidget();
        if (isSelectionIgnored) fabricRef.discardActiveObject();
        return;
      }

      updateWidget(selectedObject);
    };

    fabricRef.on('object:moving', objectEventHandler);
    fabricRef.on('object:scaling', objectEventHandler);
    fabricRef.on('selection:created', selectionHandler);
    fabricRef.on('selection:updated', selectionHandler);
    fabricRef.on('selection:cleared', () => {
      if (widgetRef.current) {
        widgetRef.current.style.display = 'none';
      }
    });

    return () => {
      fabricRef.off('object:moving', objectEventHandler);
      fabricRef.off('object:scaling', objectEventHandler);
      fabricRef.off('selection:created', selectionHandler);
      fabricRef.off('selection:updated', selectionHandler);
      fabricRef.off('selection:cleared', () => {
        if (widgetRef.current) {
          widgetRef.current.style.display = 'none';
        }
      });
    };
  }, [fabricRef]);

  const mirrorSelectedObject = () => {
    if (!fabricRef) return;

    const selectedObject = fabricRef.getActiveObject();
    if (selectedObject) {
      selectedObject.set('flipX', !selectedObject.flipX);
      fabricRef.requestRenderAll();
      fabricRef.fire('object:modified', { target: selectedObject });
    }
  };

  const cloneSelectedObject = () => {
    if (!fabricRef) return;

    const selectedObject = fabricRef.getActiveObject();
    if (selectedObject) {
      selectedObject.clone((clonedObj: any) => {
        clonedObj.set({
          left: clonedObj.left + 30,
          top: clonedObj.top + 30,
          evented: true,
        });
        fabricRef.add(clonedObj);
        fabricRef.setActiveObject(clonedObj);
        clonedObj.setCoords();
        fabricRef.requestRenderAll();
        fabricRef.fire('object:modified', { target: clonedObj });
      });
    }
  };

  const removeSelectedObject = () => {
    if (!fabricRef) return;

    const selectedObject = fabricRef.getActiveObject();
    if (selectedObject) {
      fabricRef.remove(selectedObject);
      if ('dispose' in selectedObject && typeof selectedObject.dispose === 'function') {
        selectedObject.dispose();
      }
      fabricRef.requestRenderAll();
      updateCanvasState(serializeCanvas(fabricRef));
    }
  };

  const sendToBack = () => {
    if (!fabricRef) return;

    const selectedObject = fabricRef.getActiveObject();
    if (!selectedObject) return;

    const isBaseTemplateExist = fabricRef.getObjects().some((obj) => obj.imageType === 'template');
    const bottomOfStack = isBaseTemplateExist ? 1 : 0;
    fabricRef.moveTo(selectedObject, bottomOfStack);
    fabricRef.requestRenderAll();
    fabricRef.fire('object:modified', { target: selectedObject });
  };

  const bringToFront = () => {
    if (!fabricRef) return;

    const selectedObject = fabricRef.getActiveObject();
    if (!selectedObject) return;

    selectedObject.bringToFront();
    fabricRef.requestRenderAll();
    fabricRef.fire('object:modified', { target: selectedObject });
  };

  return (
    <div
      ref={widgetRef}
      style={{
        position: 'absolute',
        transform: 'translateX(-50%)',
        display: 'none',
      }}
    >
      <div className='flex w-full items-center gap-2 rounded-[7px] border border-slate-200 bg-white p-1'>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              ref={enhanceRef}
              variant='outline'
              onClick={enhanceQuality}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <EnhanceIcon />
            </Button>
          }
        >
          Enhance
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              variant='outline'
              onClick={mirrorSelectedObject}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <MirrorIcon />
            </Button>
          }
        >
          Mirror
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              variant='outline'
              onClick={cloneSelectedObject}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <CopyIcon />
            </Button>
          }
        >
          Copy
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              variant='outline'
              onClick={bringToFront}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <BringToFrontIcon />
            </Button>
          }
        >
          Bring Front
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              variant='outline'
              onClick={sendToBack}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <SendToBackIcon />
            </Button>
          }
        >
          Sent To Back
        </TooltipItem>
        <TooltipItem
          side='bottom'
          trigger={
            <Button
              variant='outline'
              onClick={removeSelectedObject}
              className={`flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[3px] border-none p-0 hover:bg-c-ground`}
            >
              <TrashIcon color='red' />
            </Button>
          }
        >
          Delete
        </TooltipItem>
      </div>
    </div>
  );
};
