import { useUserStore } from '@/providers/user/hooks';
import { RoutePaths } from '@/utils/route/constants';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useSilentRefresh } from './useSilentRefresh';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = useUserStore((state) => state.user);
  useSilentRefresh();

  if (!user) return <Navigate to={RoutePaths.LOGIN} />;

  return <>{children}</>;
};
