import { jwtDecode } from 'jwt-decode';
import { updateTokensInCache } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';
import { apiClient, setAuthHeaders } from '@/utils/fetch/axiosConfig';
import { AuthEndpoint } from '@/utils/fetch/constants';
import { User } from '@/providers/user/store';
import { useUserStore } from '@/providers/user/hooks';
import { useNavigate } from 'react-router';
import { RoutePaths } from '@/utils/route/constants';
import { toast } from 'sonner';

type LoginCredentials = {
  username: string;
  password: string;
};

const loginUser = async (credentials: LoginCredentials) => {
  const params = new URLSearchParams();
  params.append('username', credentials.username);
  params.append('password', credentials.password);
  return apiClient.post<User>(AuthEndpoint.LOGIN, params);
};

export const useLogin = () => {
  const setUser = useUserStore((state) => state.setUser);
  return useMutation({
    mutationKey: [AuthEndpoint.LOGIN],
    mutationFn: loginUser,
    onSuccess: ({ data }) => {
      setAuthHeaders({ sid: data.sid, accessToken: data.access_token });
      const accessExpiry = jwtDecode(data.access_token).exp as number;
      const { access_token, ...withoutAccessToken } = data;
      setUser({ ...withoutAccessToken, accessExpiry });
      updateTokensInCache({
        access_token,
        refresh_token: data.refresh_token,
        token_type: data.token_type,
      });
    },
  });
};

const getGoogleRedirectUrl = async () => {
  return apiClient.get<{ url: string }>(AuthEndpoint.GOOGLE_LOGIN);
};

export const useGoogleLoginRedirect = () => {
  return useMutation({
    mutationKey: [AuthEndpoint.LOGIN],
    mutationFn: getGoogleRedirectUrl,
    onSuccess: ({ data }) => {
      window.location.href = data.url;
    },
  });
};

const getGoogleVerifiedUser = async (sessionId: string) => {
  return apiClient.get<User>(AuthEndpoint.GOOGLE_OAUTH, { params: { session: sessionId } });
};

export const useGoogleVerify = () => {
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [AuthEndpoint.GOOGLE_OAUTH],
    mutationFn: getGoogleVerifiedUser,
    onSuccess: ({ data }) => {
      setAuthHeaders({ sid: data.sid, accessToken: data.access_token });
      const accessExpiry = jwtDecode(data.access_token).exp as number;
      const { access_token, ...withoutAccessToken } = data;
      setUser({ ...withoutAccessToken, accessExpiry });
      updateTokensInCache({
        access_token,
        refresh_token: data.refresh_token,
        token_type: data.token_type,
      });
      navigate(`/${RoutePaths.CREATIVE_SUITE}`, { replace: true });
    },
    onError: (err) => {
      console.error(err);
      toast.error('Failed to login with Google. Please try again.');
      navigate(`/${RoutePaths.LOGIN}`, { replace: true });
    },
  });
};
