import { useState } from 'react';

export const useOverflowGradient = () => {
  const [gradientState, setGradientState] = useState({ top: false, bottom: true });

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const topGradient = target.scrollTop > 0;
    const bottomGradient = target.scrollTop + target.clientHeight < target.scrollHeight;
    setGradientState({ top: topGradient, bottom: bottomGradient });
  };

  return { gradientState, onScroll };
};
