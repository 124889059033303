import { useEffect, useMemo, useRef, useState } from 'react';
import { GeneratedItem } from './GeneratedItem';
import { useNavigate, useParams } from 'react-router';
import { ScrollableImages } from './ScrollableImages';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useGetInfiniteGeneratedScenes,
  useResetInfiniteGeneratedScenesCache,
} from 'pages/ai-scene/review/helpers';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { RefetchView } from '../sidebar/settings-tab/RefetchView';
import { Separator } from 'components/ui/separator';

const ICON_WRAPPER_CL = 'inline-flex items-center rounded-md bg-gray-100 p-1';

export const GeneratedContents = () => {
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
  const navigate = useNavigate();
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();
  const sceneId = useParams().sceneId;

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, isError, refetch } =
    useGetInfiniteGeneratedScenes({
      staleTime: 0,
    });
  useResetInfiniteGeneratedScenesCache();

  const allItems = useMemo(() => data?.items || [], [data]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const generationList = useMemo(() => {
    return allItems.map((img, idx) => (
      <motion.div key={img.id} layout>
        <GeneratedItem
          key={img.id}
          {...img}
          imageNumber={idx + 1}
          onSelect={() => setSelectedImageId(img.id)}
          selectedClass={img.id === sceneId ? 'border-2 border-black' : 'opacity-50'}
        />
      </motion.div>
    ));
  }, [allItems, setSelectedImageId, selectedImageId, sceneId]);

  const loadingView = isLoading && (
    <div className='mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load scenes.</span>
    </RefetchView>
  );

  if (!data || data?.items.length === 0) {
    return null;
  }

  return (
    <motion.div
      className='relative h-[100vh] w-[240px] bg-white md:max-h-min md:w-[100vw] md:bg-c-ground md:pt-3'
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ duration: 0.5 }}
    >
      <div className=' flex flex-col gap-3 p-3 md:hidden'>
        <span className='text-[15px] font-semibold'>Results</span>
        <Separator />
      </div>
      <ScrollableImages customElement={<div className='invisible size-0' ref={scrollTargetRef} />}>
        {errorView || loadingView || (
          <div className='flex  w-[240px] flex-wrap justify-center gap-2 p-1 pb-36 md:w-[100vw] md:flex-nowrap md:justify-start md:overflow-x-auto md:bg-white md:p-1'>
            <AnimatePresence>{generationList}</AnimatePresence>
          </div>
        )}
        {hasNextPage && (
          <div className='relative -mt-24 mb-40 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96  w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollableImages>
    </motion.div>
  );
};
