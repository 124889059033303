import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { useParams } from 'react-router-dom';

import SaveSettingsIcon from '@/assets/icons/zapCircle.svg?react';
import SaveTemplateIcon from '@/assets/icons/image04.svg?react';
import SaveInspirationIcon from '@/assets/icons/star006.svg?react';

import SaveIcon from '@/assets/icons/ai-scene-icons/save.svg?react';
import { TooltipItem } from 'components/common/TooltipItem';
import { useDefinedSceneCtx } from 'pages/ai-scene/review-edit/helper';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useState, useEffect } from 'react';
import {
  useSaveSceneSettingsMutation,
  useSaveAsSceneInspirationMutation,
  useSaveAsSceneTemplateMutation,
} from 'components/ai-scene/result-sidebar/queries';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { useIsAiEditType } from 'pages/ai-scene/useIsAiEditType';

export const SaveSceneCanvas = ({ contentClassName }: { contentClassName?: string }) => {
  const { projectId, isSettingsSaved } = useDefinedSceneCtx();
  const saveSettings = useSaveSceneSettingsMutation();
  const saveInspiration = useSaveAsSceneInspirationMutation();
  const saveTemplate = useSaveAsSceneTemplateMutation();
  const queryClient = useQueryClient();
  const [isSavedSettingsLocalState, setIsSavedSettingsLocalState] = useState(isSettingsSaved);
  const [isSaveInspirationDisabled, setIsSaveInspirationDisabled] = useState(false);
  const [isSaveTemplateDisabled, setIsSaveTemplateDisabled] = useState(false);
  const { sceneId } = useParams<{ sceneId: string }>();
  const isAiEditType = useIsAiEditType();

  useEffect(() => {
    setIsSavedSettingsLocalState(isSettingsSaved);
    setIsSaveInspirationDisabled(false);
    setIsSaveTemplateDisabled(false);
  }, [isSettingsSaved, sceneId]);

  const handleSaveSettingsClick = async () => {
    try {
      if (!sceneId || !projectId || saveSettings.isPending) return;
      const nextIsSavedSettings = !isSavedSettingsLocalState;

      await saveSettings.mutateAsync({
        sceneId,
        projectId,
        action: nextIsSavedSettings ? 'add' : 'remove',
      });
      setIsSavedSettingsLocalState(nextIsSavedSettings);
      queryClient.invalidateQueries({
        queryKey: [{ url: '/api/saved-settings' }],
      });
      const toastMessage = nextIsSavedSettings
        ? 'Scene saved to settings'
        : 'Scene removed from settings';
      toast.success(toastMessage);
    } catch (error) {
      toast.error('Failed to save settings');
    }
  };

  const handleSaveInspirationClick = async () => {
    try {
      if (!sceneId || !projectId) return;

      await saveInspiration.mutateAsync({
        sceneId,
        projectId,
      });
      setIsSaveInspirationDisabled(true);
      toast.success('Scene saved as an inspiration');
    } catch (error) {
      toast.error('Failed to save as an inspiration');
    }
  };

  const handleSaveTemplateClick = async () => {
    try {
      if (!sceneId || !projectId) return;

      await saveTemplate.mutateAsync({
        sceneId,
        projectId,
      });
      setIsSaveTemplateDisabled(true);
      toast.success('Scene saved as a template');
    } catch (error) {
      toast.error('Failed to save as a template');
    }
  };

  const checkPlanAndProceedInspiration = usePlanCheck({
    requiredPlan: FeatureAccessRequirements.SAVE_AS_INSPIRATION.requiredPlan,
    onClick: handleSaveInspirationClick,
    featureDescription: FeatureAccessRequirements.SAVE_AS_INSPIRATION.featureDescription,
  });

  const checkPlanAndProceedTemplate = usePlanCheck({
    requiredPlan: FeatureAccessRequirements.SAVE_AS_TEMPLATES.requiredPlan,
    onClick: handleSaveTemplateClick,
    featureDescription: FeatureAccessRequirements.SAVE_AS_TEMPLATES.featureDescription,
  });

  return (
    <div className='flex items-center justify-center gap-2'>
      <DropdownMenu>
        <DropdownMenuTrigger className='focus-visible:outline-transparent'>
          <TooltipItem
            side='bottom'
            trigger={
              <div>
                <SaveIcon />
              </div>
            }
          >
            Save As
          </TooltipItem>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align='start'
          side='right'
          sideOffset={60}
          alignOffset={-10}
          className={`w-[232px] ${contentClassName}`}
        >
          {!isAiEditType && (
            <DropdownMenuItem
              asChild
              className='cursor-pointer !text-main !font-medium'
              onClick={handleSaveSettingsClick}
            >
              <div className={`flex gap-2`}>
                <SaveSettingsIcon
                  className={`stroke-gray-500 ${isSavedSettingsLocalState ? 'stroke-red-500' : ''}`}
                />
                {isSavedSettingsLocalState ? 'Remove Settings' : 'Save Settings'}
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            asChild
            className={`cursor-pointer !text-main !font-medium ${
              isSaveInspirationDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={checkPlanAndProceedInspiration}
            disabled={isSaveInspirationDisabled}
          >
            <div className={`flex gap-2`}>
              <SaveInspirationIcon className={`stroke-gray-500`} /> Save as an Inspiration
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem
            asChild
            className={`cursor-pointer !text-main !font-medium ${
              isSaveTemplateDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={checkPlanAndProceedTemplate}
            disabled={isSaveTemplateDisabled}
          >
            <div className={`flex gap-2`}>
              <SaveTemplateIcon className={`stroke-gray-500`} /> Save as a Template
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
