import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItemView } from 'components/common/MenuItemView';
import { FONTS_DATA, FONT_SIZES_DATA } from '../text/text.data';
import { isFabricText } from '@/utils/helper';

interface FontDetailProps {
  canvasInstance: fabric.Canvas | null;
}

export const FontDetail: FC<FontDetailProps> = ({ canvasInstance }) => {
  const [{ fontName, fontSize }, setVisibleFontDetail] = useState({
    fontName: '',
    fontSize: '',
  });

  const fontOptions = useMemo(
    () => FONTS_DATA.map((font) => ({ value: font.name, label: font.name })),
    [],
  );

  const fontSizeOptions = useMemo(
    () => FONT_SIZES_DATA.map((font) => ({ value: font.size, label: font.size })),
    [],
  );

  const updateFont = useCallback(
    (type: 'font' | 'size', value: string) => {
      if (!canvasInstance) return;

      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject)) return;

      switch (type) {
        case 'font':
          activeObject.set('fontFamily', value);
          setVisibleFontDetail((prev) => ({ ...prev, fontName: value }));
          break;
        case 'size':
          activeObject.set('fontSize', Number(value));
          setVisibleFontDetail((prev) => ({ ...prev, fontSize: value }));
          break;
      }

      canvasInstance.requestRenderAll();
    },
    [canvasInstance],
  );

  useEffect(() => {
    if (!canvasInstance) return;

    const selectionHandler = () => {
      const activeObject = canvasInstance.getActiveObject();
      if (!activeObject || !isFabricText(activeObject)) return;

      const activeObjectFontFamily = activeObject.get('fontFamily');
      const activeObjectFontSize = activeObject.get('fontSize');
      if (!activeObjectFontFamily || !activeObjectFontSize) return;

      setVisibleFontDetail({
        fontName: activeObjectFontFamily,
        fontSize: String(activeObjectFontSize),
      });
    };

    canvasInstance.on('selection:created', selectionHandler);
    canvasInstance.on('selection:updated', selectionHandler);

    return () => {
      canvasInstance.off('selection:created', selectionHandler);
      canvasInstance.off('selection:updated', selectionHandler);
    };
  }, [canvasInstance]);

  return (
    <div className='flex gap-1.5'>
      <MenuItemView
        selectMessage='Select a font'
        emptyMessage='No font found.'
        inputPlaceholder='Search'
        options={fontOptions}
        triggerProps={{
          className: 'bg-slate-50 justify-between w-3/4 pr-2 w-[180px] text-xs',
        }}
        onItemSelect={(option) => updateFont('font', option.value)}
        controlledValue={fontName}
      />
      <MenuItemView
        selectMessage='Size'
        options={fontSizeOptions}
        defaultValue='16'
        triggerProps={{
          className: 'bg-slate-50 justify-between  pr-2 w-full text-xs',
        }}
        popoverContentProps={{
          className: 'w-[80px] p-0',
          align: 'end',
        }}
        onItemSelect={(option) => updateFont('size', option.value)}
        controlledValue={fontSize}
      />
    </div>
  );
};
