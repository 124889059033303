import { useState, useEffect } from 'react';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';

export const useSceneImagesSrc = () => {
  const canvasInstance = useAiSceneCreateStore((state) => state.aiSceneCanvasInstance);
  const styleIds = useAiSceneCreateStore((state) => state.aiSceneSettingsTab.styleIds);

  const [visualGuidanceImages, setVisualGuidanceImages] = useState<string[]>([]);
  const [productImages, setProductImages] = useState<string[]>([]);
  const [templateImages, setTemplateImages] = useState<string[]>([]);

  const getSceneImgSrc = (imgType: string) => {
    const images =
      (canvasInstance
        ?.getObjects()
        ?.filter((obj) => obj.imageType === imgType) as fabric.Image[]) || [];
    return images.map((obj) => obj.getSrc());
  };

  const updateImages = () => {
    const templateImgs = getSceneImgSrc('template');
    const styleImg =
      styleIds && styleIds.length > 0 && styleIds[0].thumbnail ? [styleIds[0].thumbnail] : [];
    setVisualGuidanceImages([...templateImgs, ...styleImg]);
    setProductImages(getSceneImgSrc('product'));
    setTemplateImages(templateImgs);
  };

  useEffect(() => {
    updateImages();
    if (canvasInstance) {
      canvasInstance.on('object:added', updateImages);
      canvasInstance.on('object:removed', updateImages);

      return () => {
        canvasInstance.off('object:added', updateImages);
        canvasInstance.off('object:removed', updateImages);
      };
    }
  }, [canvasInstance, styleIds]);

  return { visualGuidanceImages, productImages, templateImages };
};
