import { FeatureAccessRequirements, PlanType } from 'components/plan-guard/constants';
import { isPlanSufficient } from 'components/plan-guard/helper';
import { useUpgradeWarningModalStore } from 'components/plan-guard/useUpgradeWarningModalStore';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { useAccountCtx } from 'pages/dashboard/profile/helper';
import React, { useCallback } from 'react';

import UpgradeWarningIcon from '@/assets/icons/upgareWarning.svg?react';

interface MagicEraseProSwitchProps {
  isProEraser: boolean;
  setIsProEraser: (value: boolean) => void;
}

export const MagicEraseProSwitch: React.FC<MagicEraseProSwitchProps> = ({
  isProEraser,
  setIsProEraser,
}) => {
  const { plan: userPlan } = useAccountCtx();
  const { showUpgradeModal } = useUpgradeWarningModalStore();

  const isSufficient = isPlanSufficient(
    userPlan,
    FeatureAccessRequirements.MAGIC_ERASE_PRO.requiredPlan as PlanType,
  );

  const checkUserPlanAndShowModal = useCallback((): boolean => {
    if (!isSufficient) {
      showUpgradeModal(
        FeatureAccessRequirements.MAGIC_ERASE_PRO.requiredPlan,
        FeatureAccessRequirements.MAGIC_ERASE_PRO.featureDescription,
      );
    }

    return isSufficient;
  }, [userPlan, showUpgradeModal]);

  return (
    <div className='flex justify-between'>
      <Label
        htmlFor='magicErasePro'
        className='flex items-center gap-2 whitespace-nowrap text-main font-semibold'
        onClick={() => {
          isSufficient && checkUserPlanAndShowModal();
        }}
      >
        Magic Eraser Pro+ {!isSufficient && <UpgradeWarningIcon />}
      </Label>
      <Switch
        className='h-[22px] data-[state=checked]:bg-crait-green-primary'
        thumbClassName='data-[state=checked]:bg-c-dark'
        checked={isProEraser}
        onCheckedChange={() => {
          isSufficient ? setIsProEraser(!isProEraser) : checkUserPlanAndShowModal();
        }}
        id='magicErasePro'
      />
    </div>
  );
};
