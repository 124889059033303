import { CraitLogo } from 'components/common/CraitLogo';
import { AvatarDropdown } from 'components/layout/header/AvatarDropdown';
import { HelpCenterButton } from 'components/layout/header/HelpCenterButton';
import { useMobilSidebarStore } from './sidebar/useMobileSidebarStore';
import { Button } from 'components/ui/button';

import MobileMenuOpenIcon from 'assets/icons/mobileMenuOpen.svg?react';
import MobileMenuCloseIcon from 'assets/icons/mobileMenuClose.svg?react';

export const MainHeader = () => {
  const { isMobilSidebarOpen, toggleSidebar } = useMobilSidebarStore();
  return (
    <div className='flex h-header w-full items-center justify-between border-b border-[#EAEAEA] px-6'>
      <div className='flex items-center gap-3'>
        <Button
          variant='outline'
          onClick={toggleSidebar}
          className='hidden border-none p-0 md:block'
        >
          {!isMobilSidebarOpen ? <MobileMenuOpenIcon /> : <MobileMenuCloseIcon />}
        </Button>
        <CraitLogo />
      </div>
      <div className='flex items-center justify-between gap-3'>
        <HelpCenterButton />
        <AvatarDropdown />
      </div>
    </div>
  );
};
