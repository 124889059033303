import { useCallback, useEffect, useRef, useMemo } from 'react';
import { fabric } from 'fabric';
import rotate from 'assets/icons/rotate.svg';
import {
  MAX_ORIGINAL_CANVAS_SIZE,
  MAX_VISIBLE_CANVAS_SIZE,
} from 'components/ai-scene/create/editor/canvas/helper';

export type TCanvasOptions = {
  dimensions?: {
    width: number;
    height: number;
  };
  selection?: boolean;
};

export const useFabricCanvas = (
  setCanvasInstance?: (instance: fabric.Canvas | null) => void,
  options?: TCanvasOptions,
  // It will cause the canvas to be reinitialized when the key changes.
  // This is needed especially when user is navigated to similar param without refresh.
  // Remember that React do not unmount existing components by default.
  forceResetKey?: string | null,
) => {
  const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null);

  useEffect(() => {
    if (!canvasElementRef.current) return;

    const { width, height } = options?.dimensions || {
      width: MAX_VISIBLE_CANVAS_SIZE,
      height: MAX_VISIBLE_CANVAS_SIZE,
    };

    fabricCanvasRef.current = new fabric.Canvas(canvasElementRef.current, {
      width,
      height,
      renderOnAddRemove: false,
      preserveObjectStacking: true,
      containerClass: 'bg-white',
      selection: options?.selection ?? true,
    });
    fabricCanvasRef.current.originalDimensions = options?.dimensions || {
      width: MAX_ORIGINAL_CANVAS_SIZE,
      height: MAX_ORIGINAL_CANVAS_SIZE,
    };
    setCanvasInstance?.(fabricCanvasRef.current);
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.cornerColor = 'white';
    fabric.Object.prototype.cornerSize = 14;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.borderColor = 'black';
    fabric.Object.prototype.cornerStrokeColor = 'black';
    fabric.Object.prototype.padding = 10;
    fabric.util.loadImage(
      rotate,
      function (img) {
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          x: 0,
          y: 0.5,
          offsetY: 30,
          cursorStyle: 'pointer',
          actionHandler: (fabric as any).controlsUtils.rotationWithSnapping,
          actionName: 'rotate',
          render: (ctx, left, top) => {
            ctx.drawImage(img, left - img.width / 2, top - img.height / 2, img.width, img.height);
          },
        });
      },
      null,
      'anonymous',
    );

    return () => {
      fabricCanvasRef.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCanvasInstance, forceResetKey]);

  const setDimensions = useCallback((width: number, height: number) => {
    fabricCanvasRef.current?.setDimensions({ width, height });
  }, []);

  const canvasElement = useMemo(() => <canvas ref={canvasElementRef} />, []);

  return useMemo(
    () =>
      ({
        canvasElementRef,
        fabricCanvasRef,
        setDimensions,
        canvasElement,
      }) as const,
    [setDimensions, canvasElement],
  );
};
