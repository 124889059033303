import { Button } from 'components/ui/button';
import { FC, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

import { toast } from 'sonner';
import { useNavigate } from 'react-router';
import { RoutePaths } from '@/utils/route/constants';
import { useCraitStore } from '@/utils/store/store';
import { MoreHorizontal } from 'lucide-react';

import UseSettingsIcon from 'assets/icons/useSettings.svg?react';
import AiEditIcon from 'assets/icons/aiEdit.svg?react';
import ShareIcon from 'assets/icons/share.svg?react';
import DeleteIcon from 'assets/icons/ai-scene-icons/trash.svg?react';
import DownloadIcon from 'assets/icons/download.svg?react';
import RenameIcon from '@/assets/icons/rename.svg?react';
import { DownloadModal } from './DownloadModal';
import { Modal } from 'components/ui/modal';
import {
  TScene,
  useDeleteSceneMutation,
  useRenameSceneMutation,
  useCopyToAiEditMutation,
} from 'components/ai-scene/result-sidebar/queries';
import { RenameSceneNameView } from './RenameSceneNameView';
import { getSceneSummary } from './helper';
import { useUserStore } from '@/providers/user/hooks';
import { downloadSceneWithCreditCheck } from './helper';
import { useCreateProjectMutation } from 'pages/ai-scene/create/helpers';
import { ProjectTypes } from 'pages/workspace/projects/helper';
import { MobileMenuSheet } from 'components/common/MobileMenuSheet';

const D_ITEM_CLASS = 'flex gap-2 p-2';
const SHEET_BUTTON_CLASS = 'flex justify-start gap-2 bg-white pl-0';

export const HistoryDots: FC<
  TScene & {
    refetch: () => void;
  }
> = ({ id, projectId, generationParameters, imageUrl, thumbnailUrl, name, refetch }) => {
  const navigate = useNavigate();
  const deleteScene = useDeleteSceneMutation();
  const copyToAiEdit = useCopyToAiEditMutation();
  const setPendingSettingsToUse = useCraitStore((state) => state.setPendingSettingsToUse);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRenameModalShown, setIsRenameModalShown] = useState(false);
  const renameScene = useRenameSceneMutation();
  const { mutateAsync: createProjectAsync } = useCreateProjectMutation();
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  const baseUrl =
    generationParameters.productIds.length > 0 ? RoutePaths.AI_SCENE : RoutePaths.AI_IMAGE;

  const fetchSceneSummary = async () => {
    try {
      const data = await getSceneSummary({ sceneId: id, projectId, organizationId });
      return data;
    } catch (error) {
      toast.error('Failed to use settings.');
      return null;
    }
  };

  const deleteSceneById = async () => {
    try {
      if (deleteScene.isPending) return;
      await deleteScene.mutateAsync({ projectId, sceneId: id });
      refetch();
      toast.success('Scene deleted successfully.');
    } catch (error) {
      console.error('error', error);
      toast.error('Failed to delete scene.');
    }
  };

  const handleShareClick = async () => {
    try {
      await navigator.clipboard.writeText(imageUrl);
      toast.success('Image copied to clipboard.');
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy the image.');
    }
  };

  const handleUseSettingsClick = async () => {
    try {
      const data = await fetchSceneSummary();
      if (!data) return;

      const parameters = {
        productIds: generationParameters.productIds,
        sceneDimensions: generationParameters.sceneDimensions,
        rawPrompt: generationParameters.rawPrompt,
        prompt: generationParameters.prompt,
        negativePrompt: generationParameters.negativePrompt,
        inspirationalImageUrls: generationParameters.inspirationalImageUrls,
        styleIds: generationParameters.styleIds,
        templateId: generationParameters.templateId,
        brandStyleId: generationParameters.brandStyleId,
        lightingStyleId: generationParameters.lightingStyleId,
        elements: generationParameters.elements,
        faceTextComposition: generationParameters.faceTextComposition,
        enhancePrompt: generationParameters.enhancePrompt,
        productPlacementImageUrl: generationParameters.productPlacementImageUrl,
        staticEditsImageUrl: generationParameters.staticEditsImageUrl,
      };
      const summary = {
        prompt: generationParameters.prompt,
        products: null,
        styles: data?.styles || null,
        template: data?.template || null,
        brand_style: data?.brand_style || null,
        lighting_style: data?.lighting_style || null,
      };

      setPendingSettingsToUse({
        summary,
        parameters,
      });

      navigate(`/${baseUrl}`);
    } catch (error) {
      toast.error('Failed to handle use settings click.');
    }
  };

  const handleDownloadClick = async () => {
    try {
      const response = await downloadSceneWithCreditCheck({
        sceneId: id,
        projectId,
        organizationId,
      });

      if (response.status === 'success') {
        setIsModalOpen(true);
      }
    } catch (error: any) {
      const errorMessage =
        error.response.status == 402
          ? 'Insufficient credits'
          : 'An error occurred while attempting to download the scene.';
      toast.error(errorMessage);
    }
  };

  const handleAiEditClick = async () => {
    try {
      const type = ProjectTypes.AI_EDIT;
      const project = await createProjectAsync({ type });
      const scene = await copyToAiEdit.mutateAsync({
        sceneId: id,
        projectId,
        editProjectId: project.id,
      });
      toast.success('Scene use to AI Edit successfully.');
      navigate(`/${RoutePaths.AI_EDIT}/${scene.projectId}/generation/${scene.id}`);
    } catch (error) {
      toast.error('Failed to use the scene to AI Edit.');
    }
  };

  return (
    <>
      {isRenameModalShown && (
        <Modal
          open={isRenameModalShown}
          onOpenChange={setIsRenameModalShown}
          titleElement={<div className='text-[16px] font-bold'>Rename Scene</div>}
          modalContentClassName='px-6 py-12 max-w-[420px]'
        >
          {({ closeModal }) => (
            <RenameSceneNameView
              defaultName={name}
              onClose={closeModal}
              isSubmitDisabled={renameScene.isPending}
              onSubmit={async (name) => {
                try {
                  if (renameScene.isPending) return;

                  await renameScene.mutateAsync({ sceneId: id, projectId: projectId, name });
                  refetch();
                  closeModal();
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to update logo');
                }
              }}
            />
          )}
        </Modal>
      )}
      <DownloadModal
        image={imageUrl}
        previewImage={thumbnailUrl}
        title={name}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <MobileMenuSheet
        titleElement={name}
        triggerButton={
          <Button
            variant='ghost'
            className='h-8 w-8 rounded-sm bg-inherit p-0 focus-visible:ring-0 focus-visible:ring-offset-0'
          >
            <MoreHorizontal size={20} color='#D9D9D9' className='h-4 w-4 ' />
          </Button>
        }
        closeAfterClick={true}
      >
        <div className='mt-4 flex flex-col justify-start gap-[6px] '>
          <Button className={SHEET_BUTTON_CLASS} variant='ghost' onClick={handleUseSettingsClick}>
            <UseSettingsIcon /> Use Settings
          </Button>
          <Button className={SHEET_BUTTON_CLASS} variant='ghost' onClick={handleAiEditClick}>
            <AiEditIcon />
            AI Edit
          </Button>
          <Button
            className={SHEET_BUTTON_CLASS}
            variant='ghost'
            onClick={() => setIsRenameModalShown(true)}
          >
            <RenameIcon />
            Rename
          </Button>
          <Button className={SHEET_BUTTON_CLASS} variant='ghost' onClick={handleShareClick}>
            <ShareIcon />
            Share
          </Button>
          <Button className={SHEET_BUTTON_CLASS} variant='ghost' onClick={handleDownloadClick}>
            <DownloadIcon />
            Download
          </Button>
          <Button
            className={`${SHEET_BUTTON_CLASS} text-[#E16236]`}
            variant='ghost'
            onClick={deleteSceneById}
            disabled={deleteScene.isPending}
          >
            <DeleteIcon /> Delete
          </Button>
        </div>
      </MobileMenuSheet>

      <div className='block md:hidden'>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 rounded-sm bg-inherit p-0 focus-visible:ring-0 focus-visible:ring-offset-0'
            >
              <span className='sr-only'>Open menu</span>
              <MoreHorizontal size={20} color='#D9D9D9' className='h-4 w-4 ' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent side='top' align='end' className='w-[180px] rounded-[10px]'>
            <DropdownMenuItem className={D_ITEM_CLASS} onClick={handleUseSettingsClick}>
              <UseSettingsIcon /> Use Settings
            </DropdownMenuItem>
            <DropdownMenuItem className={D_ITEM_CLASS} onClick={handleAiEditClick}>
              <AiEditIcon />
              AI Edit
            </DropdownMenuItem>
            <DropdownMenuItem className={D_ITEM_CLASS} onClick={() => setIsRenameModalShown(true)}>
              <RenameIcon />
              Rename
            </DropdownMenuItem>
            <DropdownMenuItem className={D_ITEM_CLASS} onClick={handleShareClick}>
              <ShareIcon />
              Share
            </DropdownMenuItem>
            <DropdownMenuItem className={D_ITEM_CLASS} onClick={handleDownloadClick}>
              <DownloadIcon />
              Download
            </DropdownMenuItem>
            <DropdownMenuItem
              className={D_ITEM_CLASS}
              onClick={deleteSceneById}
              disabled={deleteScene.isPending}
            >
              <DeleteIcon /> Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
