import { useGetProductCategories } from 'components/ai-scene/sidebar/settings-tab/queries';
import { useCategoryParam } from './useCategoryParam';
import { MenuItemView } from 'components/common/MenuItemView';
import { useMemo } from 'react';

export const CategoriesDropdown = () => {
  const [activeCategoryId, setActiveCategoryId] = useCategoryParam();
  const { data } = useGetProductCategories();

  const categoryOptions = useMemo(() => {
    return (
      data?.map((category) => ({
        value: category.id,
        label: category.name,
      })) || []
    );
  }, [data]);

  return (
    <MenuItemView
      selectMessage='Select category'
      emptyMessage='No results.'
      inputPlaceholder='Search'
      controlledValue={activeCategoryId || undefined}
      options={categoryOptions}
      triggerProps={{
        className:
          'bg-slate-50 justify-between w-[200px] lg:w-full pr-2 rounded-lg text-xs border-[#EAEAEA]',
      }}
      popoverContentProps={{ className: 'lg:w-max-content' }}
      containerClass='lg:w-full'
      className='h-[220px]'
      onItemSelect={(option) => setActiveCategoryId(option.value)}
    />
  );
};
