import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog';

interface OnboardingModalProps {
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onClose, children }) => {
  const onChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onChange}>
      <AlertDialogContent className='max-h-[720px] min-h-[480px] min-w-[560px] rounded-[20px] bg-white p-5'>
        <div>{children}</div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
