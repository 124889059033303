import { useGetPeopleCtx } from 'pages/dashboard/people/helper';
import { AddPeopleDialog } from './AddPeopleDialog';
import { CustomPeopleTable } from './table/CustomPeopleTable';

const CREDIT_CL = 'text-sm font-bold';
const TEXT_CL = 'text-sm font-medium text-[#8A8A8A]';

const CSpan = ({ children }: { children: React.ReactNode }) => (
  <span className={CREDIT_CL}>{children}</span>
);
const CText = ({ children }: { children: React.ReactNode }) => (
  <p className={TEXT_CL}>{children}</p>
);

export const DashboardPeople = () => {
  const { data } = useGetPeopleCtx();
  return (
    <div className='max-w-[1440px]'>
      <aside className='flex items-center justify-between'>
        <div className='mb-6'>
          <h4 className='mb-1 inline-block text-[26px] font-medium'>Members</h4>
          <p className='font-medium text-[#8A8A8A]'>Members your account is being billed for</p>
        </div>
        <div className='flex items-center gap-6'>
          <div className='flex gap-2'>
            <CText>Total Members:</CText>
            <CSpan>{data.totalItems}</CSpan>
          </div>
          <AddPeopleDialog />
        </div>
      </aside>
      <CustomPeopleTable />
    </div>
  );
};
