import { FC, useMemo, useState, useEffect } from 'react';
import { Separator } from 'components/ui/separator';
import { TProductResponse, useGetInfiniteSearchResults } from '../helper/queries';
import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { useDebounceValue } from 'usehooks-ts';
import { useInView } from 'react-intersection-observer';
import { useResetInfiniteSearchCache } from '../visual-guidance/styles/useResetInfiniteSearchCache';
import { ProductItemCard } from './ProductItemCard';
import { useSyncSelectedProducts } from './useSyncSelectedProducts';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { CategoryPopover } from './CategoryPopover';
import { RefetchView } from '../helper/RefetchView';
import { QuickUploadProduct } from './QuickUploadProduct';

const MAX_PAGES_IN_CACHE = 2;

export const ProductsTab: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    { name: string; id: string } | undefined
  >(undefined);
  const [keyword, setKeyword] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const drawnProductIds = useSyncSelectedProducts();

  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TProductResponse>({
    keyword: keyword,
    categoryId: selectedCategory?.id,
    searchSection: 'products',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.products;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 0,
    refetchOnMount: true,
  });

  useResetInfiniteSearchCache<TProductResponse>('products', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allProducts = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.products.items) || [];
  }, [infiniteData]);

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load products.</span>
    </RefetchView>
  );

  if (isLoading)
    return (
      <div className='mt-10 flex justify-center'>
        <LoadingSpinner />
      </div>
    );

  const noProductsView = allProducts.length === 0 && (
    <span className='text-[11px]'>No product found.</span>
  );

  return (
    <div className='h-[90%]'>
      <div className='mb-3 flex gap-4'>
        <Command className='flex h-10 justify-center rounded-lg bg-background'>
          <CommandInput placeholder='Search keywords' onValueChange={setKeyword} />
        </Command>
        <CategoryPopover
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
      {errorView}
      {noProductsView}
      <ScrollArea className='h-[96%] w-full'>
        <div className='flex flex-wrap gap-x-2.5 gap-y-3 p-1 md:justify-center'>
          {allProducts.map((product) => (
            <ProductItemCard
              key={product.id}
              product={product}
              isSelected={drawnProductIds.includes(product.id)}
            />
          ))}
        </div>
        {hasNextPage && (
          <div className='relative flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
