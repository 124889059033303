import { IconWrapper } from 'components/common/IconWrapper';
import { TooltipItem } from 'components/common/TooltipItem';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import './custom.style.scss';
import { PROCESSING_STATUSES, TScene, useFavoriteSceneMutation, useLazyGetScene } from './queries';
import { Skeleton } from 'components/ui/skeleton';
import { toast } from 'sonner';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { getInfiniteGeneratedScenesQueryKey } from 'pages/ai-scene/review/helpers';
import { useUserStore } from '@/providers/user/hooks';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { TPaginatedResponse } from '../sidebar/settings-tab/queries';
import { useIsMounted } from 'usehooks-ts';
import { downloadWithAnchor, getConvertedImageUrl } from '@/utils/helper';
import { AiSceneSideMenus, useMenuStore } from '../side-menu/store';
import { useAISceneOrImagePath } from 'pages/ai-scene/useAISceneOrImagePath';
import { downloadSceneWithCreditCheck } from 'components/workspace/history/helper';
import { useGenerationSceneStatusStore } from '../side-menu/create-tool/helper/useGenerationSceneStatusStore';

const ICON_WRAPPER_CL =
  'inline-flex cursor-pointer items-center rounded-full p-2 bg-black bg-opacity-50 text-white';

const ICONS_DATA = [
  {
    id: 'favorite',
    iconClass: 'i-mdi-bookmark-outline',
    tooltip: 'Save',
  },
  {
    id: 'download',
    iconClass: 'i-mdi-download-outline',
    tooltip: 'Download',
  },
] as const;

interface IGeneratedItemProps {
  imageNumber?: number;
  onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isVisibleByDefault?: boolean;
  size?: string;
  selectedClass?: string;
}

export const GeneratedItem: FC<IGeneratedItemProps & TScene> = ({
  id,
  name,
  imageUrl,
  projectId,
  status,
  imageNumber,
  onSelect,
  isVisibleByDefault,
  size,
  isFavorited,
  thumbnailUrl,
  versionHistory,
  selectedClass,
}) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const navigate = useNavigate();
  const { sceneId } = useParams();
  const { fetchSceneById } = useLazyGetScene();
  const isFetching = useRef(false);
  const [{ resultImage, resultStatus }, setImageResult] = useState({
    resultImage: thumbnailUrl,
    resultStatus: status,
  });
  const { setActiveMenu } = useMenuStore((state) => ({
    setActiveMenu: state.setActiveMenu,
  }));
  const favoriteScene = useFavoriteSceneMutation();
  const queryClient = useQueryClient();
  const isMounted = useIsMounted();
  const setIsGenerating = useGenerationSceneStatusStore((state) => state.setIsGenerating);

  const basePath = useAISceneOrImagePath();

  const iconsData = useMemo(() => {
    return ICONS_DATA.map((item) => {
      switch (item.id) {
        case 'favorite':
          return {
            ...item,
            tooltip: isFavorited ? 'Unsaved' : 'Save',
            iconClass: isFavorited ? 'i-mdi-bookmark text-[#E16236]' : item.iconClass,
          };
        default:
          return item;
      }
    });
  }, [isFavorited]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isFetching.current || !PROCESSING_STATUSES.includes(status)) return;

    const loadScene = async () => {
      try {
        if (!isMounted()) {
          clearTimeout(timeoutId);
          return;
        }

        isFetching.current = true;
        const scene = await fetchSceneById(id, projectId);
        if (PROCESSING_STATUSES.includes(scene.status)) {
          timeoutId = setTimeout(loadScene, 1000);
        } else {
          setImageResult({
            resultImage: scene.thumbnailUrl,
            resultStatus: scene.status,
          });
          if (sceneId === undefined) {
            navigate(`/${basePath}/${projectId}/generation/${id}`);
            setActiveMenu(AiSceneSideMenus.EDIT_TOOL);
          }
          setIsGenerating(false);
          queryClient.setQueryData<InfiniteData<TPaginatedResponse<TScene>>>(
            getInfiniteGeneratedScenesQueryKey(projectId, organizationId),
            (infiniteData) => {
              if (!infiniteData?.pages) return;

              const updatedPages = infiniteData.pages.map((page) => {
                const updatedItems = page.items.map((sceneItem) => {
                  if (sceneItem.id === id) return scene;
                  return sceneItem;
                });
                return {
                  ...page,
                  items: updatedItems,
                };
              });

              return {
                ...infiniteData,
                pages: updatedPages,
              };
            },
          );
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to retrieve scene. Please try again.');
      } finally {
        isFetching.current = false;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        loadScene();
      } else {
        clearTimeout(timeoutId);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    loadScene();

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [id, projectId, fetchSceneById, status, queryClient, organizationId, isMounted]);

  useEffect(() => {
    setImageResult({
      resultImage: thumbnailUrl,
      resultStatus: status,
    });
  }, [thumbnailUrl, status]);

  if (!resultImage && PROCESSING_STATUSES.includes(resultStatus)) {
    return (
      <div
        className={`relative flex size-[104px]  flex-col items-center justify-center rounded-lg border-2 ${
          size || ''
        }`}
      >
        <div className='absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform'>
          <LoadingSpinner className='text-crait-green-600' />
        </div>
        <Skeleton className='h-full w-full rounded-md bg-gray-300' />
      </div>
    );
  }
  if (!resultImage) {
    queryClient.setQueryData<InfiniteData<TPaginatedResponse<TScene>>>(
      getInfiniteGeneratedScenesQueryKey(projectId, organizationId),
      (infiniteData) => {
        if (!infiniteData?.pages) return;

        const updatedPages = infiniteData.pages.map((page) => {
          const updatedItems = page.items.filter((sceneItem) => sceneItem.id !== id);
          return {
            ...page,
            items: updatedItems,
          };
        });
        return {
          ...infiniteData,
          pages: updatedPages,
        };
      },
    );
    return null;
  }

  const latestKey = versionHistory?.[0]?.versionId || id;

  const handleDownload = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      const response = await downloadSceneWithCreditCheck({
        sceneId: id,
        projectId,
        organizationId,
      });
      if (response.status === 'success') {
        const convertedImageUrl = await getConvertedImageUrl(imageUrl, 'png');
        downloadWithAnchor(convertedImageUrl, name);
      }
    } catch (error: any) {
      const errorMessage =
        error.response.status == 402
          ? 'Insufficient credits'
          : 'An error occurred while attempting to download the scene.';
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          navigate(`/${basePath}/${projectId}/generation/${id}`);
          setActiveMenu(AiSceneSideMenus.EDIT_TOOL);
        }}
        className={`relative flex flex-col items-center justify-center rounded-lg border-2 hover:border-[#072428] ${
          size || ''
        } ${isVisibleByDefault ? 'cursor-default' : 'cursor-pointer'}  ${
          size ? '!w-auto' : 'size-[104px]'
        } ${selectedClass}`}
      >
        <div className='absolute right-1 top-1 z-10 flex justify-center gap-[2px]'>
          {iconsData.map((item) => (
            <TooltipItem
              key={item.iconClass}
              side={`${size ? 'top' : 'bottom'}`}
              trigger={
                <div
                  onClick={async (e) => {
                    try {
                      e.stopPropagation();
                      switch (item.id) {
                        case 'favorite': {
                          if (favoriteScene.isPending) return;

                          const nextIsFavorited = !isFavorited;
                          await favoriteScene.mutateAsync({
                            projectId,
                            sceneId: id,
                            action: nextIsFavorited ? 'add' : 'remove',
                          });
                          queryClient.setQueryData<InfiniteData<TPaginatedResponse<TScene>>>(
                            getInfiniteGeneratedScenesQueryKey(projectId, organizationId),
                            (infiniteData) => {
                              if (!infiniteData?.pages) return;

                              const updatedPages = infiniteData.pages.map((page) => {
                                const updatedItems = page.items.map((sceneItem) => {
                                  if (sceneItem.id !== id) return sceneItem;
                                  return {
                                    ...sceneItem,
                                    isFavorited: nextIsFavorited,
                                  };
                                });
                                return {
                                  ...page,
                                  items: updatedItems,
                                };
                              });

                              return {
                                ...infiniteData,
                                pages: updatedPages,
                              };
                            },
                          );
                          const toastMessage = nextIsFavorited
                            ? 'Scene is saved'
                            : 'Scene is unsaved';
                          toast.success(toastMessage);
                          break;
                        }
                        case 'download': {
                          await handleDownload(e);
                          break;
                        }
                        default:
                          break;
                      }
                    } catch (error) {
                      console.error(error);
                      toast.error('Action failed. Please try again.');
                    }
                  }}
                >
                  <IconWrapper
                    wrapperProps={{
                      className: `${ICON_WRAPPER_CL}`,
                    }}
                    iconClass={`${item.iconClass} ${size ? 'size-3' : 'size-3 hover:scale-125'}`}
                  />
                </div>
              }
            >
              {item.tooltip}
            </TooltipItem>
          ))}
        </div>
        <img
          onClick={onSelect}
          key={latestKey}
          crossOrigin='anonymous'
          src={resultImage}
          className='h-full w-full rounded-md object-cover'
        />
      </div>
    </>
  );
};