import { FC, ReactNode, useState, useEffect } from 'react';
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
} from '@/components/ui/sheet';
import { ScrollArea } from 'components/ui/scroll-area';

interface MobileMenuSheetProps {
  titleElement?: string;
  triggerButton?: ReactNode;
  children: ReactNode | ((props: { closeModal: () => void }) => ReactNode);
  closeAfterClick?: boolean;
  isOpen?: boolean;
  containerClass?: string;
  onOpenChange?: (isOpen: boolean) => void;
  contentClassName?: string;
  isDisabled?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right' | null | undefined;
}

export const MobileMenuSheet: FC<MobileMenuSheetProps> = ({
  titleElement,
  triggerButton,
  containerClass,
  children,
  closeAfterClick = false,
  isOpen: isOpenProp,
  onOpenChange,
  contentClassName,
  isDisabled,
  side,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProp || false);

  useEffect(() => {
    if (isOpenProp !== undefined) {
      setIsOpen(isOpenProp);
    }
  }, [isOpenProp]);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange?.(open);
  };

  const childrenContent = (
    <div className='mt-4 flex flex-col justify-start gap-[6px]'>
      {typeof children === 'function'
        ? children({
            closeModal: () => {
              setIsOpen(false);
              handleOpenChange(false);
            },
          })
        : children}
    </div>
  );

  return (
    <div className={`hidden md:block ${containerClass || ''}`}>
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        {triggerButton && (
          <SheetTrigger
            asChild
            className={isDisabled ? 'cursor-not-allowed opacity-50' : ''}
            onClick={(e) => isDisabled && e.preventDefault()}
          >
            <div>{triggerButton}</div>
          </SheetTrigger>
        )}
        <SheetContent
          side={side || 'bottom'}
          className={`max-h-[calc(100vh-140px)] overflow-y-auto rounded-tl-lg rounded-tr-lg bg-white px-4 pb-4 pt-6 ${
            contentClassName || ''
          }`}
        >
          <SheetHeader>
            <SheetTitle className='absolute left-4 top-3 max-w-[84%] truncate text-[16px] font-semibold'>
              {titleElement}
            </SheetTitle>
          </SheetHeader>
          <ScrollArea className='h-[calc(100%-20px)]'>
            {isOpen &&
              (closeAfterClick ? (
                <SheetClose asChild>{childrenContent}</SheetClose>
              ) : (
                childrenContent
              ))}
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </div>
  );
};
