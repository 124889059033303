import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { PeopleContext, useGetPeople } from './helper';
import { FC, ReactNode, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';

interface PeopleProviderProps {
  children: ReactNode;
}

export const PeopleProvider: FC<PeopleProviderProps> = ({ children }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const people = useGetPeople({
    pageId: pagination.pageIndex,
  });

  if (people.data) {
    return (
      <PeopleContext.Provider
        value={{
          data: people.data,
          pagination,
          setPagination,
        }}
      >
        {children}
      </PeopleContext.Provider>
    );
  }

  if (people.isError) {
    return <div>Error: {people.error.message}</div>;
  }

  return (
    <div className='mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );
};
