import { RoutePaths } from '@/utils/route/constants';
import { useLocation } from 'react-router-dom';

export const useAISceneOrImagePath = () => {
  const location = useLocation();
  const path = location.pathname;

  const basePath =
    [RoutePaths.AI_SCENE, RoutePaths.AI_EDIT, RoutePaths.AI_IMAGE].find((route) =>
      path.includes(route),
    ) || RoutePaths.AI_IMAGE;

  return basePath;
};
