import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Checkbox } from 'components/ui/checkbox';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { ScrollArea } from 'components/ui/scroll-area';
import { Separator } from 'components/ui/separator';
import { PLACEHOLDER_FONT_TEXT } from 'components/workspace/brand-library/brand-kit/constants';
import { useGetFonts } from 'components/workspace/brand-library/brand-kit/queries';
import { Fragment, useMemo } from 'react';

export const FontsView = () => {
  const brandKitFonts = useBannerCreateStore((s) => s.brandKitFonts);
  const addBrandKitFont = useBannerCreateStore((s) => s.addBrandKitFont);
  const removeBrandKitFont = useBannerCreateStore((s) => s.removeBrandKitFont);
  const { data, isError, isLoading, refetch } = useGetFonts({
    pageId: 0,
  });

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load fonts.</span>
    </RefetchView>
  );

  const fontItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.items || data.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No fonts found.</span>
        </RefetchView>
      );
    }

    return data.items.map((font, idx) => {
      const fontId = font.id.toString();
      const isSelectedBefore = brandKitFonts.some((f) => f.id === fontId);
      return (
        <Fragment key={font.id}>
          <div key={font.id} className='flex h-[72px] items-center justify-center gap-6 '>
            <Checkbox
              id={`font-checkbox-${font.id}`}
              className='h-6 w-6'
              checked={isSelectedBefore}
              onCheckedChange={(isChecked) => {
                if (isChecked) {
                  addBrandKitFont({
                    id: fontId,
                    name: font.name,
                  });
                } else {
                  removeBrandKitFont(fontId);
                }
              }}
            />
            <Label
              htmlFor={`font-checkbox-${font.id}`}
              className='flex w-full cursor-pointer flex-col gap-1'
            >
              <h4 className='text-lg font-medium'>{font.name}</h4>
              <Input
                className='flex h-full flex-1 items-center rounded-md border border-gray-200 px-4 text-xl font-normal'
                defaultValue={PLACEHOLDER_FONT_TEXT}
              />
            </Label>
          </div>
          {idx !== data.items.length - 1 && <Separator className='my-3' />}
        </Fragment>
      );
    });
  }, [data, isLoading, refetch, brandKitFonts, addBrandKitFont, removeBrandKitFont]);

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{brandKitFonts.length} Selected</span>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='flex w-full flex-col gap-2 rounded-md bg-white p-6 pb-9'>
          {errorView || fontItems}
        </div>
      </ScrollArea>
    </div>
  );
};
