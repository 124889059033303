import { FC } from 'react';
import { Separator } from 'components/ui/separator';
import { ApplyDimension } from 'components/ai-scene/side-menu/create-tool/size/ApplyDimension';
import { ArrowLeftIcon } from 'components/ai-scene/side-menu/create-tool/CreateToolIcons';
import { useDimensionSelection } from './useDimensionSelectionForResults';
import { useCreateLayoutSizeMutation } from 'components/ai-scene/side-menu/create-tool/size/queries';

interface CustomSizeProps {
  onBackClick: () => void;
}

export const CustomSize: FC<CustomSizeProps> = ({ onBackClick }) => {
  const { onDimensionSelected } = useDimensionSelection();
  const createLayoutSizeMutation = useCreateLayoutSizeMutation();

  const handleDimensionApplied = (props: {
    width: number;
    height: number;
    name: string;
    isCustomSize: boolean;
    resetInputs: () => void;
  }) => {
    const isApplied = onDimensionSelected(props.width, props.height);
    if (!isApplied) return;
    if (props.isCustomSize) {
      createLayoutSizeMutation.mutate({
        name: props.name,
        width: props.width,
        height: props.height,
      });
    }
    props.resetInputs();
    onBackClick();
  };

  return (
    <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 shadow-md'>
      <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold'>
        <ArrowLeftIcon />
        Custom Size
      </button>
      <Separator />
      <ApplyDimension onDimensionApplied={handleDimensionApplied} />
    </div>
  );
};
