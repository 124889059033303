import { Button } from 'components/ui/button';
import wild from 'assets/images/wild.png';
import { LayerView } from './LayerView';

export const ImageEditor = () => {
  return (
    <section className='container relative flex h-[calc(100%-60px)] items-center justify-center gap-6 bg-c-ground py-5'>
      <LayerView />
      <div className='flex h-full flex-col items-center justify-center gap-8 2xl:gap-6'>
        <div className='flex items-center justify-center overflow-hidden rounded-lg bg-white'>
          <img
            crossOrigin='anonymous'
            src={wild}
            className='size-[600px] max-w-full object-contain'
            alt='editable image'
          />
        </div>
        <div className='flex gap-2'>
          <Button className='h-[52px] bg-white hover:bg-white' variant='ghost' asChild>
            <div className='flex gap-2'>
              <span className='inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125'>
                <span className='i-mdi-undo-variant size-4'></span>
              </span>
              <span className='inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1 hover:scale-125'>
                <span className='i-mdi-redo-variant size-4'></span>
              </span>
            </div>
          </Button>
          <Button className='flex h-[52px] gap-2 bg-white' variant='ghost'>
            <span className='inline-flex items-center rounded-md bg-gray-100 p-1'>
              <span className='i-mdi-content-save-outline size-4'></span>
            </span>
            <span>Downloads</span>
          </Button>
        </div>
      </div>
    </section>
  );
};
