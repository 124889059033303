import { COMMON_DIMENSIONS } from '@/utils/store/constants';
import { ImmerStateCreator } from '@/utils/store/store';
import { TTemplateResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type TStyleSelection = 'organic' | 'balanced' | 'advert';
export type TObjectiveSelection = 'conversion' | 'awareness' | 'traffic';
export type TDimension = (typeof COMMON_DIMENSIONS)[number];

export type TBannerStore = TBannerState & TBannerStateAction;

type TAsset = {
  type: 'product' | 'aiScene';
  id: string;
  url: string;
  name: string;
};

export type TBannerState = {
  dimensions: TDimension[];
  assets: TAsset[];
  templates: TTemplateResponse[];
  style: TStyleSelection | null;
  objective: TObjectiveSelection | null;
  brandKitLogos: { id: string; name: string; url: string }[];
  brandKitColors: { id: string; hex: string; percent: number }[];
  brandKitFonts: { id: string; name: string }[];
};

export type TBannerStateAction = {
  addAsset: (asset: TAsset) => void;
  removeAsset: (id: string) => void;
  updateProductUrl: (id: string, url: string) => void;
  addTemplate: (template: TTemplateResponse) => void;
  removeTemplate: (id: string) => void;
  setStyle: (style: TBannerState['style']) => void;
  setObjective: (objective: TBannerState['objective']) => void;
  addDimensions: (dimensions: TDimension[]) => void;
  removeDimensions: (dimensionIds: string[]) => void;
  addBrandKitLogo: (logo: TBannerState['brandKitLogos'][0]) => void;
  removeBrandKitLogo: (id: string) => void;
  addBrandKitFont: (font: TBannerState['brandKitFonts'][0]) => void;
  removeBrandKitFont: (id: string) => void;
  addBrandKitColor: (color: TBannerState['brandKitColors'][0]) => void;
  removeBrandKitColor: (id: string) => void;
};

const initialState: TBannerState = {
  dimensions: [],
  assets: [],
  templates: [],
  style: null,
  objective: null,
  brandKitLogos: [],
  brandKitColors: [],
  brandKitFonts: [],
};

const useBannerSlice: ImmerStateCreator<TBannerStore> = (set) => ({
  ...initialState,
  addAsset: (asset) => {
    set((state) => {
      state.assets.push(asset);
    });
  },
  removeAsset: (id) => {
    set(
      (state) => {
        state.assets = state.assets.filter((asset) => asset.id !== id);
      },
      false,
      'removeAsset',
    );
  },
  updateProductUrl: (id, url) => {
    set(
      (state) => {
        state.assets = state.assets.map((asset) => {
          if (asset.id !== id) return asset;

          return {
            ...asset,
            url,
          };
        });
      },
      false,
      'updateProductUrl',
    );
  },
  addTemplate: (template) => {
    set(
      (state) => {
        state.templates.push(template);
      },
      false,
      'addTemplate',
    );
  },
  removeTemplate: (id) => {
    set(
      (state) => {
        state.templates = state.templates.filter((template) => template.id !== id);
      },
      false,
      'removeTemplate',
    );
  },
  setStyle: (style) => {
    set(
      (state) => {
        state.style = style;
      },
      false,
      'setStyle',
    );
  },
  setObjective: (objective) => {
    set(
      (state) => {
        state.objective = objective;
      },
      false,
      'setObjective',
    );
  },
  addDimensions: (dimensions) => {
    set(
      (state) => {
        state.dimensions.push(...dimensions);
      },
      false,
      'addDimensions',
    );
  },
  removeDimensions: (dimensionIds) => {
    set(
      (state) => {
        state.dimensions = state.dimensions.filter((d) => !dimensionIds.includes(d.id));
      },
      false,
      'removeDimensions',
    );
  },
  addBrandKitLogo: (logo) => {
    set(
      (state) => {
        state.brandKitLogos.push(logo);
      },
      false,
      'addBrandKitLogo',
    );
  },
  removeBrandKitLogo: (id) => {
    set(
      (state) => {
        state.brandKitLogos = state.brandKitLogos.filter((logo) => logo.id !== id);
      },
      false,
      'removeBrandKitLogo',
    );
  },
  addBrandKitFont: (font) => {
    set(
      (state) => {
        state.brandKitFonts.push(font);
      },
      false,
      'addBrandKitFont',
    );
  },
  removeBrandKitFont: (id) => {
    set(
      (state) => {
        state.brandKitFonts = state.brandKitFonts.filter((font) => font.id !== id);
      },
      false,
      'removeBrandKitFont',
    );
  },
  addBrandKitColor: (color) => {
    set(
      (state) => {
        state.brandKitColors.push(color);
      },
      false,
      'addBrandKitColor',
    );
  },
  removeBrandKitColor: (id) => {
    set(
      (state) => {
        state.brandKitColors = state.brandKitColors.filter((color) => color.id !== id);
      },
      false,
      'removeBrandKitColor',
    );
  },
});

export const createBannerStore = (initProps?: Partial<TBannerState>) => {
  return createStore<TBannerStore>()(
    devtools(
      immer((...props) => ({
        ...useBannerSlice(...props),
        ...initProps,
      })),
    ),
  );
};
