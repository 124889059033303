import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '@/lib/utils';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverClose = PopoverPrimitive.Close;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    // Solves the popover scroll issue in Dialog.
    // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
    container?: HTMLElement | null;
  }
>(({ className, align = 'center', sideOffset = 4, container, ...props }, ref) => (
  <PopoverPrimitive.Portal container={container}>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      /**
       * Fix for issue with scrolling:
       * @see https://github.com/shadcn-ui/ui/issues/607
       */
      onWheel={(e) => {
        e.stopPropagation();

        const isScrollingDown = e.deltaY > 0;

        if (isScrollingDown) {
          // Simulate arrow down key press
          e.currentTarget.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown' }));
        } else {
          // Simulate arrow up key press
          e.currentTarget.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowUp' }));
        }
      }}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverClose };
