import { FC, ReactNode, useEffect, useRef } from 'react';
import { createAiSceneResultStore } from './store';
import { AiSceneResultContext, TAiSceneResultContext } from './helper';
import { useUnmountWithResetContext } from '@/providers/store-resetter/StoreResetterProvider';

interface AiSceneResultProviderProps {
  children: ReactNode;
}

export const AiSceneResultProvider: FC<AiSceneResultProviderProps> = ({ children }) => {
  const storeRef = useRef<TAiSceneResultContext>();
  useUnmountWithResetContext(() => {
    storeRef.current = undefined;
  });

  if (!storeRef.current) {
    storeRef.current = createAiSceneResultStore();
  }

  return (
    <AiSceneResultContext.Provider value={storeRef.current}>
      {children}
    </AiSceneResultContext.Provider>
  );
};
