import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import {
  TProductResponse,
  useGetSearchResults,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Command, CommandInput } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { ProductAssetCard } from './ProductAssetCard';
import { useBannerCreateStore } from '@/providers/ai-banner/create/hooks';

export const ProductAssets = () => {
  const [keyword, setKeyword] = useDebounceValue('', 300);
  const { data, isError, isLoading, refetch } = useGetSearchResults<TProductResponse>({
    keyword,
    pageId: 0,
    searchSection: 'products',
    onlyMyProjects: false,
    staleTime: 30 * 1000,
  });
  const assets = useBannerCreateStore((s) => s.assets);

  const existingProducts = useMemo(() => {
    return assets.filter((asset) => asset.type === 'product');
  }, [assets]);

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load products.</span>
    </RefetchView>
  );

  const productItems = useMemo(() => {
    if (isLoading)
      return (
        <div className='mt-10 flex justify-center'>
          <LoadingSpinner />
        </div>
      );

    if (!data?.products?.items || data.products.items.length === 0) {
      return (
        <RefetchView
          disabled={isLoading}
          onClick={() => {
            refetch();
          }}
        >
          <span>No products found.</span>
        </RefetchView>
      );
    }

    return data.products.items.map((product) => {
      const isSelected = existingProducts.some((asset) => asset.id === product.id);
      return (
        <ProductAssetCard key={product.id} product={product} isSelectedByDefault={isSelected} />
      );
    });
  }, [data, isLoading, refetch, existingProducts]);

  return (
    <div className='h-[calc(100%-160px)] w-full pt-6'>
      <div className='mb-3 flex items-center justify-between'>
        <span>{existingProducts.length} Selected</span>
        <Command className='flex h-10 w-60 justify-center rounded-lg  border border-[#6b8084bb] bg-transparent'>
          <CommandInput
            placeholder='Search'
            onValueChange={(value) => {
              setKeyword(value);
            }}
          />
        </Command>
      </div>
      <ScrollArea className='h-[calc(100%-70px)]'>
        <div className='grid grid-cols-3 gap-4 p-4'>{errorView || productItems}</div>
      </ScrollArea>
    </div>
  );
};
