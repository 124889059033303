import { useEffect, useState } from 'react';
import { FileUploadInput } from 'components/workspace/brand-library/products/upload/FileUploadInput';
import { toast } from 'sonner';
import { ProgressComponent } from 'components/ai-scene/review-edit/side-menu/add-logo-tool/QuickUploadLogo';
import { useCreateAiEditSceneMutation } from '../queries';
import { useNavigate, useParams } from 'react-router';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';
import { RoutePaths } from '@/utils/route/constants';
import { getFileNameWithoutExtension } from '@/utils/helper';

export const QuickUploadAiEditImage = () => {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const projectId = useParams().projectId;
  const { refetchScenes } = useRefetchGeneratedScenes();
  const navigate = useNavigate();

  const createAiEditSceneMutation = useCreateAiEditSceneMutation();

  useEffect(() => {
    if (file) {
      getDimensionsAndUpload(file);
    }
  }, [file]);

  const getDimensionsAndUpload = async (file: File) => {
    try {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = async () => {
        const dimensions = {
          x: image.width,
          y: image.height,
        };

        await uploadSingleImage(file, dimensions);
      };
    } catch (error) {
      toast.error('Failed to get image dimensions.');
    }
  };

  const uploadSingleImage = async (file: File, dimensions: { x: number; y: number }) => {
    try {
      setIsSubmitting(true);

      const scene = await createAiEditSceneMutation.mutateAsync({
        projectId: projectId || '',
        image: file,
        name: getFileNameWithoutExtension(file.name),
        sceneDimensions: JSON.stringify(dimensions),
      });
      refetchScenes();
      navigate(`/${RoutePaths.AI_EDIT}/${scene.projectId}/generation/${scene.id}`);
      toast.success('Image upload successful.');

      setIsSubmitting(false);
    } catch (error) {
      toast.error('Failed to upload image.');
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className='h-[128px]'>
        <ProgressComponent />
      </div>
    );
  }

  return (
    <div>
      <FileUploadInput
        className='h-[154px]'
        onFileUpload={setFile}
        titleElement={<span className='hidden'></span>}
      />
    </div>
  );
};
