import Icon from '@/assets/icons/onboardingStepOne.svg?react';
import { ContinueButton } from '../_components/ContinueButton';

type WelcomeStepProps = {
  moveToNextStep: () => void;
};

export const WelcomeStep: React.FC<WelcomeStepProps> = ({ moveToNextStep }) => {
  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='mb-5'>
          <Icon />
        </div>
        <h4 className='mb-[10px] text-xl font-bold'>Welcome to crait!</h4>
        <p className='mb-5 text-center text-[14px] text-[#000000]'>
          Let’s finish setting up your account, so you can start creating effortlessly.
        </p>
      </div>

      <ContinueButton onClick={moveToNextStep} />
    </>
  );
};
