import { ScrollArea } from 'components/ui/scroll-area';
import { AddElementModal } from './AddElementModal';
import { Command, CommandInput } from 'components/ui/command';
import { BaseCard } from '../banner/BaseCard';
import { ElementActions } from './ElementActions';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { useDebounceValue } from 'usehooks-ts';
import {
  TTemplateResponse,
  useGetInfiniteSearchResults,
} from 'components/ai-scene/sidebar/settings-tab/queries';
import { usePartialUpdateElementDetail } from './helper';
import { toast } from 'sonner';
import { appendPluralSuffixByNumber, triggerBlurOnEnter } from '@/utils/helper';
import { useResetInfiniteSearchCache } from 'components/ai-scene/sidebar/settings-tab/styles/useResetInfiniteSearchCache';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

const MAX_PAGES_IN_CACHE = 2;

interface BrandElementsViewProps {
  children?: ReactNode;
}
export const BrandElementsView: FC<BrandElementsViewProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TTemplateResponse>({
    keyword: searchValue,
    onlyOrgLibrary: true,
    searchSection: 'elements',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.elements;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
  });
  useResetInfiniteSearchCache<TTemplateResponse>('elements', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allElements = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.elements.items) || [];
  }, [infiniteData]);
  const updatePartialElementDetail = usePartialUpdateElementDetail();

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load elements.</span>
    </RefetchView>
  );

  const renameElement = async ({
    id,
    newName,
    existingName,
  }: {
    id: string;
    newName: string;
    existingName: string;
  }) => {
    try {
      if (existingName === newName) return;
      await updatePartialElementDetail.mutateAsync({ id, name: newName });
      refetch();
      toast.success('Element name updated successfully.');
    } catch (error) {
      console.log(error);
      toast.error('Failed to update element name.');
    }
  };

  const hasAnyElements = allElements.length > 0;
  const elementItems =
    !hasAnyElements && !isLoading ? (
      <span>No elements found.</span>
    ) : (
      allElements.map((element) => (
        <BaseCard
          key={element.id}
          {...element}
          defaultValue={element.name}
          onKeyDown={triggerBlurOnEnter}
          onBlur={(e) => {
            if (!element.isOrganizationAsset) return;

            renameElement({
              id: element.id,
              newName: e.target.value,
              existingName: element.name,
            });
          }}
        >
          <ElementActions {...element} refetch={refetch} />
        </BaseCard>
      ))
    );

  const totalItemCount = infiniteData?.pages[0]?.elements.totalItems || 0;

  return (
    <div className='h-full'>
      {children}
      <div className='mt-2 flex items-center justify-between gap-2'>
        <span className='text-sm font-medium'>
          {totalItemCount} element{appendPluralSuffixByNumber(totalItemCount)} listed
        </span>
        <div className='flex gap-2'>
          <Command className='flex h-10 max-w-[200px] justify-center rounded-lg border border-crait-dark-300 bg-transparent'>
            <CommandInput
              placeholder='Search element name'
              onValueChange={(value) => {
                setSearchValue(value);
              }}
            />
          </Command>
          <AddElementModal />
        </div>
      </div>
      <ScrollArea className={`mt-6 h-[calc(100%-40px)]`}>
        <div className='flex flex-wrap gap-6 pb-28'>{errorView || elementItems}</div>{' '}
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
