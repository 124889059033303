import { User } from '@/providers/user/store';
import { getRefreshToken } from '../route/helper';
import {
  AccountEndpoint,
  ActivityEndpoint,
  AiSceneEditEndpoint,
  AiSceneGenerationEndpoint,
  AiSceneSuggestionsEndpoint,
  BannerEndpoint,
  BrandKitEndpoint,
  BrandKitSubEndpoint,
  ElementEndpoint,
  HistoryEndpoint,
  ImageProcessEndpoint,
  PaymentPlanEndpoint,
  ProductEndpoint,
  ProjectEndpoint,
  SavedSettingsEndpoint,
  SearchEndpoint,
  StyleEndpoint,
  TemplateEndpoint,
  UploadProcessEndpoint,
} from './constants';
import { jwtDecode } from 'jwt-decode';
import { LocalCacheKey, localCache } from '../localStorageUtility';

export const getAccountApiUrl = (
  organizationId: number,
  subpath: (typeof AccountEndpoint)[keyof typeof AccountEndpoint],
) => `${organizationId}${subpath}`;

export const getProjectsApiUrl = (
  organizationId: number,
  subpath: (typeof ProjectEndpoint)[keyof typeof ProjectEndpoint],
) => `${organizationId}${subpath}`;

export const getSingleProjectApiUrl = ({
  organizationId,
  projectId,
  subpath,
}: {
  organizationId: number;
  projectId: string;
  subpath: (typeof ProjectEndpoint)[keyof typeof ProjectEndpoint];
}) => `${organizationId}${subpath}${projectId}`;

export const getProductsApiUrl = (
  organizationId: number,
  subpath: (typeof ProductEndpoint)[keyof typeof ProductEndpoint],
) => `${organizationId}${subpath}`;

export const getStylesApiUrl = (
  organizationId: number,
  subpath: (typeof StyleEndpoint)[keyof typeof StyleEndpoint],
) => `${organizationId}${subpath}`;

export const getTemplatesApiUrl = (
  organizationId: number,
  subpath: (typeof TemplateEndpoint)[keyof typeof TemplateEndpoint],
) => `${organizationId}${subpath}`;

export const getBannersApiUrl = (
  organizationId: number,
  subpath: (typeof BannerEndpoint)[keyof typeof BannerEndpoint],
) => `${organizationId}${subpath}`;

export const getElementsApiUrl = (
  organizationId: number,
  subpath: (typeof ElementEndpoint)[keyof typeof ElementEndpoint],
) => `${organizationId}${subpath}`;

export const getSearchApiUrl = (
  organizationId: number,
  subpath: (typeof SearchEndpoint)[keyof typeof SearchEndpoint],
) => `${organizationId}${subpath}`;

export const getActivityApiUrl = (
  organizationId: number,
  subpath: (typeof ActivityEndpoint)[keyof typeof ActivityEndpoint],
) => `${organizationId}${subpath}`;

export const getAiSceneGenerationApiUrl = ({
  subpath,
  projectId,
  organizationId,
}: {
  organizationId: number;
  projectId: string;
  subpath: (typeof AiSceneGenerationEndpoint)[keyof typeof AiSceneGenerationEndpoint];
}) => `${organizationId}/ai-scene/${projectId}${subpath}`;

export const getAiSceneSuggestionsApiUrl = ({
  subpath,
  organizationId,
}: {
  organizationId: number;
  subpath: (typeof AiSceneSuggestionsEndpoint)[keyof typeof AiSceneSuggestionsEndpoint];
}) => `${organizationId}/ai-scene/suggestions/${subpath}`;

export const getAiSceneEditApiUrl = ({
  subpath,
  organizationId,
  projectId,
  sceneId,
}: {
  sceneId: string;
  organizationId: number;
  projectId: string;
  subpath: (typeof AiSceneEditEndpoint)[keyof typeof AiSceneEditEndpoint];
}) => `${organizationId}/ai-scene/${projectId}/${sceneId}/edit/${subpath}`;

export const getImageProcessApiUrl = (
  organizationId: number,
  subpath: (typeof ImageProcessEndpoint)[keyof typeof ImageProcessEndpoint],
) => `${organizationId}/image-processing/${subpath}`;

export const getUploadProcessApiUrl = (
  organizationId: number,
  subpath: (typeof UploadProcessEndpoint)[keyof typeof UploadProcessEndpoint],
) => `${organizationId}/upload/${subpath}`;

export const getHistoryApiUrl = (
  organizationId: number,
  subpath: (typeof HistoryEndpoint)[keyof typeof HistoryEndpoint],
) => `${organizationId}${subpath}`;

export const getSavedSettingsApiUrl = (
  organizationId: number,
  subpath: (typeof SavedSettingsEndpoint)[keyof typeof SavedSettingsEndpoint],
) => `${organizationId}${subpath}`;

export const getBrandKitApiUrl = (
  organizationId: number,
  subpath: (typeof BrandKitSubEndpoint)[keyof typeof BrandKitSubEndpoint],
) => `${organizationId}${BrandKitEndpoint.BASE}${subpath}`;

export const getPaymentPlanApiUrl = (
  organizationId: number,
  subpath: (typeof PaymentPlanEndpoint)[keyof typeof PaymentPlanEndpoint],
) => `${organizationId}${subpath}`;

type Tokens = Pick<User, 'access_token' | 'refresh_token' | 'token_type'>;
type QueuedRequest = {
  resolve: (tokens: Tokens) => void;
  reject: (error: Error) => void;
};

export const createTokenRefresher = () => {
  let isRefreshing = false;
  let queue: QueuedRequest[] = [];

  const processQueue = (error: Error | null, token: Tokens | null = null) => {
    queue.forEach(({ resolve, reject }) => {
      if (error) {
        reject(error);
      } else if (token) {
        resolve(token);
      }
    });
    queue = [];
  };

  const tokenRefresher = async (currentRefreshToken: string): Promise<Tokens> => {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const data = await getRefreshToken(currentRefreshToken);
        processQueue(null, data);
        return data;
      } catch (error) {
        processQueue(error as Error);
        throw error;
      } finally {
        isRefreshing = false;
      }
    }

    return new Promise((resolve, reject) => {
      queue.push({ resolve, reject });
    });
  };

  return { tokenRefresher };
};

export const updateTokensInCache = ({ access_token, refresh_token }: Tokens) => {
  const accessExpiry = jwtDecode(access_token).exp as number;
  localCache.set(LocalCacheKey.REFRESH_TOKEN, refresh_token);
  localCache.set(LocalCacheKey.EXPIRY, accessExpiry);
  localCache.set(LocalCacheKey.ACCESS_TOKEN, access_token);
};
