import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';
import { serializeCanvas } from '@/utils/helper';
import { useQueryClient } from '@tanstack/react-query';
import {
  PROCESSING_STATUSES,
  useRefetchGeneratedScenes,
} from 'components/ai-scene/result-sidebar/queries';
import { CraitButton } from 'components/common/CraitButton';
import { Slider } from 'components/ui/slider';
import { getSceneQueryKey, useGetScene } from 'pages/ai-scene/review-edit/helper';
import {
  useIdentityControlMutation,
  useGetEditProcessStatusMutation,
} from 'pages/workspace/brand-library/product-edit/helper';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import invariant from 'tiny-invariant';

export const ProductImageTab = () => {
  const [identityValue, setIdentityValue] = useState<number>(0);
  const { mutateAsync } = useIdentityControlMutation();
  const { mutate: getIdentityStatusMutate } = useGetEditProcessStatusMutation();
  const canvasInstance = useAiSceneResultStore((state) => state.aiSceneResultCanvasInstance);
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const updateCanvasState = useAiSceneResultStore((state) => state.updateAiSceneResultCanvasState);
  const queryClient = useQueryClient();
  const { refetchScenes } = useRefetchGeneratedScenes();

  const { data } = useGetScene();

  if (!data) {
    return null;
  }

  const { identityStrength, projectId, id, organizationId } = data;

  useEffect(() => {
    setIdentityValue(identityStrength);
  }, [identityStrength]);

  const applyIdentityControl = async () => {
    if (!canvasInstance || typeof identityValue === 'undefined') return;

    changeProcessingState('pending');

    try {
      const result = await mutateAsync(identityValue);
      const pollIdentityStatus = async () => {
        getIdentityStatusMutate(
          {
            sceneId: id,
            projectId,
            versionId: result.versionId,
          },
          {
            onSuccess: async (data) => {
              if (PROCESSING_STATUSES.includes(data.status)) {
                setTimeout(pollIdentityStatus, 1000);
                return;
              }
              if (data.status !== 'ready') {
                toast.error('Failed to apply identity control. Please try again.');
                changeProcessingState('idle');
                return;
              }

              const templateImage = canvasInstance
                .getObjects()
                .find((obj) => obj.imageType === 'template') as fabric.Image;
              invariant(templateImage, 'No base image found to revert magic erase.');
              templateImage.animate('opacity', 0.5, {
                duration: 200,
                onChange: canvasInstance.renderAll.bind(canvasInstance),
                onComplete: () => {
                  templateImage.setSrc(
                    data.imageUrl,
                    () => {
                      templateImage.animate('opacity', 1, {
                        duration: 200,
                        onChange: canvasInstance.renderAll.bind(canvasInstance),
                      });
                    },
                    { crossOrigin: 'anonymous' },
                  );
                },
              });
              queryClient.invalidateQueries({
                queryKey: getSceneQueryKey(projectId, organizationId, id),
              });
              updateCanvasState(serializeCanvas(canvasInstance));
              changeProcessingState('idle');
              refetchScenes();
            },
            onError: (err) => {
              console.error(err);
              toast.error('Failed to apply identity control. Please try again.');
              changeProcessingState('idle');
            },
          },
        );
      };
      pollIdentityStatus();
    } catch (e) {
      toast.error('Failed to apply identity control');
      console.error(e);
      changeProcessingState('idle');
    }
  };

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex cursor-pointer items-center justify-between'>
        <h3 className=' whitespace-nowrap text-main font-semibold'>Scale</h3>
        <Slider
          className='w-[50%] bg-crait-green-primary'
          min={0}
          step={0.1}
          max={1}
          value={[identityValue]}
          defaultValue={[identityValue]}
          onValueChange={(values) => {
            setIdentityValue(values[0]);
          }}
        />
      </div>
      <CraitButton onClick={applyIdentityControl} disabled={typeof identityValue === 'undefined'}>
        Restore
      </CraitButton>
    </div>
  );
};
