import { ImmerStateCreator } from '@/utils/store/store';
import { createStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface User {
  id: number;
  email: string;
  user_name: string;
  avatar: string;
  organization_ids: number[];
  user_status: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
  sid: string;
  useIdentityProvider: boolean;
  isOnboarded: boolean;
  stripe_customer_id: string | null;
}

export type TUserStore = {
  user:
    | (Omit<User, 'access_token'> & {
        accessExpiry?: number;
      })
    | null;
  setUser: (user: TUserStore['user']) => void;
  setAccessExpiry: (expiry: number) => void;
  setUserNameInfo: (user: Pick<User, 'user_name'>) => void;
  setUserOnbardingInfo: (user: Pick<User, 'isOnboarded'>) => void;
  setUserAccountInfo: (user: Pick<User, 'user_name' | 'email' | 'avatar'>) => void;
};

const useUserStore: ImmerStateCreator<TUserStore> = (set) => ({
  user: null,
  setUser: (user) => {
    set((state) => {
      state.user = user;
    }),
      false,
      'setUser';
  },
  setUserAccountInfo: ({ user_name, email, avatar }) => {
    set((state) => {
      if (state.user) {
        state.user.user_name = user_name;
        state.user.email = email;
        state.user.avatar = avatar;
      }
    }),
      false,
      'setUserAccountInfo';
  },
  //for onboarding
  setUserNameInfo: ({ user_name }) => {
    set((state) => {
      if (state.user) {
        state.user.user_name = user_name;
      }
    }),
      false,
      'setUserNameInfo';
  },
  setUserOnbardingInfo: ({ isOnboarded }) => {
    set((state) => {
      if (state.user) {
        state.user.isOnboarded = isOnboarded;
      }
    }),
      false,
      'setUserOnbardingInfo';
  },
  setAccessExpiry: (expiry) => {
    set((state) => {
      if (state.user) {
        state.user.accessExpiry = expiry;
      }
    }),
      false,
      'setAccessExpiry';
  },
});

export const createUserStore = (initProps?: Partial<TUserStore>) => {
  return createStore<TUserStore>()(
    devtools(
      persist(
        immer((...props) => ({
          ...useUserStore(...props),
          ...initProps,
        })),
        { name: 'UserStorePersist' },
      ),
      { name: 'UserStore', trace: true },
    ),
  );
};
