import { Link } from 'react-router-dom';
import { AccountPaths, RoutePaths } from '@/utils/route/constants';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

import { AvatarView } from './AvatarView';
import { useUserStore } from '@/providers/user/hooks';
import { localCache } from '@/utils/localStorageUtility';
import { toast } from 'sonner';

import Tag03Icon from 'assets/icons/_components/Tag03Icon';
import Settings02Icon from 'assets/icons/_components/Settings02Icon';
import Logout01Icon from 'assets/icons/_components/Logout01Icon';
import { Sheet, SheetClose, SheetContent, SheetTrigger } from 'components/ui/sheet';

const AVATAR_DROPDOWN_OPTION = [
  {
    label: 'Plans & Pricing',
    value: 'plan-and-pricing',
    path: `/${RoutePaths.ACCOUNT}/${AccountPaths.PAYMENT}`,
    icon: <Tag03Icon size={16} />,
    onClick: false,
  },
  {
    label: 'Settings',
    value: 'settings',
    path: `/${RoutePaths.ACCOUNT}`,
    icon: <Settings02Icon size={16} />,
    onClick: false,
  },
  {
    label: 'Sign Out',
    value: 'sign-out',
    onClick: true,
    icon: <Logout01Icon size={16} />,
  },
];

export const AvatarDropdown = () => {
  const setUser = useUserStore((state) => state.setUser);

  const onLogout = () => {
    setUser(null);
    localCache.removeAll();
    toast.success('Logged out successfully.');
  };

  return (
    <>
      <div className='flex items-center justify-center gap-2 md:hidden'>
        <DropdownMenu>
          <DropdownMenuTrigger className='w-full outline-none'>
            <AvatarView />
          </DropdownMenuTrigger>
          <DropdownMenuContent side='bottom' align='end' className='w-[232px]'>
            {AVATAR_DROPDOWN_OPTION.map((option) => (
              <DropdownMenuItem
                key={option.value}
                asChild
                className='cursor-pointer !text-main font-medium'
              >
                {option.onClick ? (
                  <button onClick={onLogout} className='flex w-full gap-2'>
                    <span>{option.icon}</span> {option.label}
                  </button>
                ) : (
                  option.path && (
                    <Link to={option.path} className='flex gap-2'>
                      <span>{option.icon}</span> {option.label}
                    </Link>
                  )
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className='m-auto hidden md:flex md:items-center'>
        <Sheet>
          <SheetTrigger className='w-full outline-none'>
            <AvatarView />
          </SheetTrigger>
          <SheetContent side='bottom' className='rounded-tl-lg rounded-tr-lg px-0'>
            <div className='mt-4 flex flex-col justify-start gap-[6px] md:gap-5 md:px-3'>
              {AVATAR_DROPDOWN_OPTION.map((option) => (
                <SheetClose asChild key={option.value}>
                  {option.onClick ? (
                    <div onClick={onLogout} className='curdor pointer flex w-full gap-2'>
                      <span>{option.icon}</span> {option.label}
                    </div>
                  ) : (
                    option.path && (
                      <Link to={option.path} className='flex gap-2'>
                        <span>{option.icon}</span> {option.label}
                      </Link>
                    )
                  )}
                </SheetClose>
              ))}
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};
