import { WorkspaceHeader } from 'components/workspace/header/WorkspaceHeader';
import { Outlet } from 'react-router';
import { AiSceneResultsSidebar } from 'components/ai-scene/AiSceneResultsSidebar';

export const AiScenePage = () => {
  return (
    <section
      className={`h-full min-h-[100vh] w-full overflow-hidden md:h-[calc(100vh-250px)] md:overflow-y-auto`}
    >
      <div className='dark'>
        <WorkspaceHeader />
      </div>
      <main
        className={`flex h-[calc(100vh-60px)] w-full bg-c-ground md:flex-col md:overflow-hidden md:overflow-y-auto md:pb-[300px]`}
      >
        <div className={`flex flex-1 items-center justify-center md:flex-none`}>
          <Outlet />
        </div>
        <AiSceneResultsSidebar />
      </main>
    </section>
  );
};
