import { SCROLL_CONTENT_CL } from 'components/common/layout/constants';
import { Checkbox } from 'components/ui/checkbox';
import { ScrollArea } from 'components/ui/scroll-area';
import { HistoryCard } from './HistoryCard';
import { RefetchView } from 'components/ai-scene/sidebar/settings-tab/RefetchView';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDebounceValue } from 'usehooks-ts';
import { useGetInfiniteSearchResults } from 'components/ai-scene/sidebar/settings-tab/queries';
import { TScene } from 'components/ai-scene/review/queries';
import { Command, CommandInput } from 'components/ui/command';
import { TooltipItem } from 'components/common/TooltipItem';
import { HistoryDots } from './HistoryDots';
import { useResetInfiniteSearchCache } from 'components/ai-scene/sidebar/settings-tab/styles/useResetInfiniteSearchCache';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { appendPluralSuffixByNumber, timestampToDate } from '@/utils/helper';

const MAX_PAGES_IN_CACHE = 2;

export const HistoryView = () => {
  const [isOnlyFavorites, setIsOnlyFavorites] = useState(false);
  const [keyword, setKeyword] = useDebounceValue('', 200);
  const { ref, inView } = useInView();
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    refetch,
  } = useGetInfiniteSearchResults<TScene>({
    keyword,
    onlyFavorites: isOnlyFavorites,
    searchSection: 'ai_scenes',
    nextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.aiScenes;
      return currentPage < totalPages ? currentPage : undefined;
    },
    staleTime: 30 * 1000,
  });
  useResetInfiniteSearchCache<TScene>('ai_scenes', MAX_PAGES_IN_CACHE);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const allScenes = useMemo(() => {
    return infiniteData?.pages.flatMap((page) => page.aiScenes.items) || [];
  }, [infiniteData]);

  const isLoadingView = isLoading && (
    <div className='mx-auto mt-10 flex justify-center'>
      <LoadingSpinner />
    </div>
  );

  const errorView = isError && (
    <RefetchView
      disabled={isLoading}
      onClick={() => {
        refetch();
      }}
    >
      <span>Failed to load scene history.</span>
    </RefetchView>
  );

  const visibleItems = useMemo(() => {
    return (
      <AnimatePresence>
        {allScenes.map((scene) => (
          <motion.div key={scene.id} layout>
            <HistoryCard
              id={scene.id}
              projectId={scene.projectId}
              title={scene.name}
              isStarred={scene.isFavorited}
              image={scene.imageUrl}
              previewImage={scene.thumbnailUrl}
              createdDate={timestampToDate(scene.creation_timestamp)}
            >
              <div className='flex items-center'>
                <TooltipItem
                  side='bottom'
                  trigger={
                    <div>
                      <HistoryDots
                        {...scene}
                        refetch={refetch}
                        imageUrl={scene.imageUrl}
                        thumbnailUrl={scene.thumbnailUrl}
                        name={scene.name}
                      />
                    </div>
                  }
                >
                  More Options
                </TooltipItem>
              </div>
            </HistoryCard>
          </motion.div>
        ))}
      </AnimatePresence>
    );
  }, [allScenes, refetch]);

  const hasAnyScenes = allScenes.length > 0;
  const historyItems =
    !hasAnyScenes && !isLoading ? (
      <span className='mt-6 w-full text-center'>No scene history found.</span>
    ) : (
      visibleItems
    );

  const totalItemCount = infiniteData?.pages[0]?.aiScenes.totalItems || 0;

  return (
    <section className={`${SCROLL_CONTENT_CL} relative overflow-y-hidden px-8 md:px-5`}>
      <aside className='mb-8 flex flex-col items-start gap-3'>
        <div className='flex items-center gap-3'>
          <h4 className='mb-1 flex items-center text-[26px] font-medium'>History</h4>
          <span className='text-sm font-medium'>
            {totalItemCount} scene{appendPluralSuffixByNumber(totalItemCount)}
          </span>
        </div>
        <div className='flex items-center justify-center gap-2'>
          <Command className='flex h-[40px]  w-[360px] justify-center rounded-lg border border-crait-dark-300 bg-[#F5F5F5] md:w-[300px]'>
            <CommandInput placeholder='Search scene name' onValueChange={setKeyword} />
          </Command>
          <div className='flex h-10 w-10 items-center justify-center rounded-[6px] border border-[#EAEAEA]'>
            <span
              className={`size-5 cursor-pointer i-mdi-${
                isOnlyFavorites ? 'bookmark text-[#E16236]' : 'bookmark-outline'
              }`}
              onClick={() => {
                setIsOnlyFavorites((prev) => !prev);
              }}
            ></span>
          </div>
        </div>
      </aside>
      <ScrollArea className={`h-[calc(90%)]`}>
        <div className='flex flex-wrap gap-6 pb-12 md:justify-center md:gap-3'>
          {isLoadingView || errorView || historyItems}
        </div>
        {hasNextPage && (
          <div className='relative mb-24 flex h-32 items-center justify-center'>
            <div ref={ref} className='absolute bottom-0 flex h-96 w-full justify-center' />
            <LoadingSpinner />
          </div>
        )}
      </ScrollArea>
    </section>
  );
};
