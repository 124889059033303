import { FC } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
} from '@/components/ui/sheet';
import { Link, useLocation } from 'react-router-dom';

import PackageIcon from 'assets/icons/package.svg?react';
import ClockIcon from 'assets/icons/clock.svg?react';
import ZapCircleIcon from 'assets/icons/zapCircle.svg?react';
import HomeIcon from 'assets/icons/home.svg?react';
import CirclePlus from 'assets/icons/circlePlus.svg?react';
import { CreativeSuitePaths, RoutePaths, WorkspacePaths } from '@/utils/route/constants';
import { NEW_PROJECT_OPTIONS } from './sidebar/CreateButton';
import { toast } from 'sonner';
import { MobileWarningContent } from 'components/common/MobileWarningContent';

const MenuItem: FC<{
  to: string;
  label: string;
  Icon: React.ComponentType<any>;
  isActive: boolean;
}> = ({ to, label, Icon, isActive }) => (
  <Link
    to={to}
    className={`flex h-[60px] flex-col items-center justify-center ${
      isActive ? 'border-t-2 border-black text-black' : 'text-gray-500'
    }`}
  >
    <div className='h-5 w-5'>
      <Icon className={`h-5 w-5 ${isActive ? 'stroke-black' : 'stroke-crait-gray'}`} />
    </div>
    <span className='text-xs'>{label}</span>
  </Link>
);

export const MobileBottomMenu: FC = () => {
  const location = useLocation();

  const checkIsActive = (path: string) => location.pathname === path;

  const handleAiEditClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      toast(<MobileWarningContent />);
    }
  };

  return (
    <div className='fixed bottom-0 left-0 right-0 z-50 flex h-[60px] items-center justify-around border-t-[1px] bg-white shadow-md min-md:hidden'>
      <MenuItem to='/' label='Home' Icon={HomeIcon} isActive={checkIsActive('/')} />

      <MenuItem
        to={`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`}
        label='Products'
        Icon={PackageIcon}
        isActive={checkIsActive(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_LIBRARY}`)}
      />

      <div className='relative'>
        <Sheet>
          <SheetTrigger asChild>
            <Button className='h-12 w-12 -translate-y-4 transform rounded-full bg-crait-green-primary p-0 text-green-800 shadow-lg'>
              <CirclePlus className='h-6 w-6' />
            </Button>
          </SheetTrigger>
          <SheetContent side='bottom' className='flex flex-col gap-6 rounded-t-lg bg-white'>
            <SheetHeader className='pb-4'>
              <SheetTitle className='absolute left-4 top-3 text-[16px]'>Create New</SheetTitle>
            </SheetHeader>
            {NEW_PROJECT_OPTIONS.map((option) => (
              <SheetClose key={option.value} asChild>
                {option.label === 'AI Edit' ? (
                  <Link
                    to={`/${option.path}`}
                    className='flex gap-2 text-[16px] font-medium'
                    onClick={handleAiEditClick}
                  >
                    <option.icon className={`${option.style} !h-6 !w-6`} /> {option.label}
                  </Link>
                ) : (
                  <Link to={`/${option.path}`} className='flex gap-2 text-[16px] font-medium'>
                    <option.icon className={`${option.style} !h-6 !w-6`} /> {option.label}
                  </Link>
                )}
              </SheetClose>
            ))}
          </SheetContent>
        </Sheet>
      </div>

      <MenuItem
        to={`/${RoutePaths.CREATIVE_SUITE}/${CreativeSuitePaths.HISTORY}`}
        label='History'
        Icon={ClockIcon}
        isActive={checkIsActive(`/${RoutePaths.CREATIVE_SUITE}/${CreativeSuitePaths.HISTORY}`)}
      />

      <MenuItem
        to={`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_KIT}`}
        label='Brand Kit'
        Icon={ZapCircleIcon}
        isActive={checkIsActive(`/${RoutePaths.WORKSPACE}/${WorkspacePaths.BRAND_KIT}`)}
      />
    </div>
  );
};
