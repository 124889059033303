import { FC } from 'react';

import { useCancelPlanMutation } from '../helper';
import { Button } from 'components/ui/button';
import { toast } from 'sonner';

type CancelPlanProps = {
  onCloseModal: () => void;
  refetch: () => void;
};

export const CancelPlan: FC<CancelPlanProps> = ({ onCloseModal, refetch }) => {
  const cancelPlan = useCancelPlanMutation();

  const onSubmit = () => {
    cancelPlan.mutate(undefined, {
      onSuccess: () => {
        toast.success('Plan successfully cancelled.');
        refetch();
        onCloseModal();
      },
      onError: () => {
        toast.error('Error cancelled the plan.');
      },
    });
  };
  return (
    <div className='flex flex-col gap-5 pb-4  pt-10'>
      <p className='px-4   text-[12px] font-normal'>
        Your plan will be downgraded to the free plan and you will lose access to premium features.
        Your current plan will remain active until the renewal date, after which it will be
        canceled.
      </p>
      <p className='px-4  text-[12px] font-normal'>Are you sure you want to proceed?</p>

      <div className='flex gap-[10px] px-4'>
        <Button
          onClick={onCloseModal}
          variant='outline'
          className='h-[44px] flex-1 rounded-[10px] border-[#EAEAEA]'
        >
          Keep
        </Button>
        <Button
          type='submit'
          variant='outline'
          className='h-[44px] flex-1 rounded-[10px] border-[#E16236] text-[#E16236]'
          disabled={cancelPlan.isPending}
          onClick={onSubmit}
        >
          {cancelPlan.isPending ? 'Cancelling' : 'Cancel'}
        </Button>
      </div>
    </div>
  );
};
