import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useFaceTextCompositionActivation } from './useFaceTextCompositionActivation';
import { useEffect } from 'react';

export const useFaceCompositionLogic = () => {
  const { hasUserManuallySetFaceTextComposition, updateAiScenePromptTab } = useAiSceneCreateStore(
    (state) => ({
      hasUserManuallySetFaceTextComposition:
        state.aiScenePromptTab.hasUserManuallySetFaceTextComposition,
      updateAiScenePromptTab: state.updateAiScenePromptTab,
    }),
  );

  const isFaceTextCompositionActive = useFaceTextCompositionActivation();

  useEffect(() => {
    if (!hasUserManuallySetFaceTextComposition) {
      updateAiScenePromptTab({ faceTextComposition: isFaceTextCompositionActive });
    }
  }, [isFaceTextCompositionActive, hasUserManuallySetFaceTextComposition, updateAiScenePromptTab]);
};
