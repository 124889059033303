import { useState, useCallback } from 'react';

export const useProductProperties = () => {
  const [productProperties, setProductProperties] = useState({
    caption: '',
    face_present: false,
    hand_present: false,
    text_present: false,
    logo_present: false,
  });

  const handleCaptionChange = useCallback((newCaption: string) => {
    setProductProperties((prev) => ({
      ...prev,
      caption: newCaption,
    }));
  }, []);

  return { productProperties, handleCaptionChange, setProductProperties };
};
