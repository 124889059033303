import React from 'react';

interface Tag03IconProps {
  size?: number;
  color?: string;
}

const Tag03Icon: React.FC<Tag03IconProps> = ({ size = 18, color = '#777777' }) => {
  return (
    <svg
      width={size}
      height={size + 2} // Yükseklik 2 piksel artırıldı
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.5 9.16669L10.1716 2.83826C9.73919 2.40588 9.523 2.18969 9.27071 2.03509C9.04703 1.89801 8.80317 1.797 8.54808 1.73576C8.26036 1.66669 7.95462 1.66669 7.34314 1.66669L4 1.66669M1.5 7.25002L1.5 8.89545C1.5 9.3031 1.5 9.50693 1.54605 9.69874C1.58688 9.8688 1.65422 10.0314 1.7456 10.1805C1.84867 10.3487 1.9928 10.4928 2.28105 10.7811L8.78105 17.2811C9.44108 17.9411 9.77109 18.2711 10.1516 18.3948C10.4864 18.5035 10.847 18.5035 11.1817 18.3948C11.5622 18.2711 11.8923 17.9411 12.5523 17.2811L14.6144 15.219C15.2744 14.5589 15.6044 14.2289 15.7281 13.8484C15.8368 13.5136 15.8368 13.1531 15.7281 12.8183C15.6044 12.4378 15.2744 12.1078 14.6144 11.4477L8.53105 5.3644C8.2428 5.07615 8.09867 4.93202 7.93048 4.82895C7.78135 4.73757 7.61878 4.67023 7.44872 4.6294C7.25691 4.58335 7.05308 4.58335 6.64543 4.58335H4.16667C3.23325 4.58335 2.76654 4.58335 2.41002 4.76501C2.09641 4.9248 1.84145 5.17977 1.68166 5.49337C1.5 5.84989 1.5 6.3166 1.5 7.25002Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Tag03Icon;
