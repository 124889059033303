import { useEnhanceDetailsQualityMutation } from 'pages/workspace/brand-library/product-edit/helper';
import { toast } from 'sonner';
import { useParams } from 'react-router';
import { usePlanCheck } from 'components/plan-guard/usePlanCheck';
import { FeatureAccessRequirements } from 'components/plan-guard/constants';
import EnhanceQualtyImage from 'src/assets/images/edit-menu/repair.png';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useRefetchGeneratedScenes } from 'components/ai-scene/result-sidebar/queries';
import { useAiSceneResultStore } from '@/providers/ai-scene/result/hooks';

export const RepairCanvasImageQuality = () => {
  const { mutateAsync: enhanceDetailsQualityMutateAsync, isPending } =
    useEnhanceDetailsQualityMutation();
  const { projectId, sceneId } = useParams<{ projectId: string; sceneId: string }>();
  const { refetchScenes } = useRefetchGeneratedScenes();
  const changeProcessingState = useAiSceneResultStore((state) => state.changeProcessingState);
  const processingState = useAiSceneResultStore((state) => state.processingState);

  const isProcessing = processingState !== 'idle';

  const enhanceDetails = async () => {
    try {
      if (!sceneId || !projectId) {
        toast.error('Failed to repair process. Scene or project not found.');
        return;
      }
      changeProcessingState('repairing');
      await enhanceDetailsQualityMutateAsync({
        sceneId,
        projectId,
      });
      toast.success('Successfully started the repair process.');
      refetchScenes();
    } catch (error) {
      console.error(error);
      toast.error('Failed to repair process.');
    } finally {
      changeProcessingState('idle');
    }
  };

  const checkPlanAndProceed = usePlanCheck({
    requiredPlan: FeatureAccessRequirements.REVIEW_EDIT_REPAIR_BUTTONS.requiredPlan,
    onClick: enhanceDetails,
    featureDescription: FeatureAccessRequirements.REVIEW_EDIT_REPAIR_BUTTONS.featureDescription,
  });

  return (
    <div
      className={`group flex  flex-col items-center justify-between gap-2 rounded-[9px] border-2 p-2 transition-colors duration-200 hover:border-black/60 ${
        isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={!isProcessing ? checkPlanAndProceed : undefined}
    >
      <div
        className={`relative h-[120px] w-[120px] overflow-hidden rounded-[3px] ${
          isProcessing ? 'opacity-50' : ''
        }`}
      >
        {processingState == 'repairing' && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <LoadingSpinner className='text-crait-green-600' />
          </div>
        )}
        <img
          src={EnhanceQualtyImage}
          alt='adjust option'
          className={`h-full w-full object-cover`}
        />
      </div>

      <p className={`text-[13px] font-semibold ${isProcessing ? 'opacity-50' : ''}`}>Repair</p>
    </div>
  );
};
