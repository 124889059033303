import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint } from '@/utils/fetch/constants';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';
import { Member } from 'pages/dashboard/people/helper';
import * as z from 'zod';

export const addUserFormSchema = z.object({
  email: z.string().email('Invalid email address.').min(1, 'Email is required.'),
  title: z.string().min(1, 'Title is required.'),
  authority: z.union([z.literal('admin'), z.literal('owner'), z.literal('normal')]),
});

export type TAddUserForm = z.infer<typeof addUserFormSchema>;
const addMember = async (orgId: number, userPayload: TAddUserForm) => {
  return apiClient.post<Member>(
    getAccountApiUrl(orgId, AccountEndpoint.ADD_ACCOUNT_MEMBER),
    userPayload,
  );
};

export const useAddMember = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.ADD_ACCOUNT_MEMBER, organizationId }],
    mutationFn: (userPayload: TAddUserForm) => {
      return addMember(organizationId, userPayload);
    },
  });
};
