import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { TemplateEndpoint } from '@/utils/fetch/constants';
import { getTemplatesApiUrl } from '@/utils/fetch/helper';
import { useMutation } from '@tanstack/react-query';

type TTemplateUpdatePayload = {
  id: string;
  name: string;
  image: File;
  organizationId: number;
};

const updateTemplateDetail = async (props: TTemplateUpdatePayload) => {
  const baseUrl = getTemplatesApiUrl(props.organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('image', props.image);
  return apiClient.put<string>(url, formData);
};

export const useUpdateTemplateDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [TemplateEndpoint.BASE, organizationId, 'updateTemplateDetail'],
    mutationFn: (props: Omit<TTemplateUpdatePayload, 'organizationId'>) => {
      return updateTemplateDetail({ ...props, organizationId });
    },
  });
};

type TTemplatePartialUpdatePayload = {
  id: string;
  name?: string;
  image?: File;
  organizationId: number;
};

const updatePartialTemplateDetail = async (props: TTemplatePartialUpdatePayload) => {
  const baseUrl = getTemplatesApiUrl(props.organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  const formData = new FormData();
  if (props.name) formData.append('name', props.name);
  if (props.image) formData.append('image', props.image);

  return apiClient.patch<string>(url, formData);
};

export const usePartialUpdateTemplateDetail = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [TemplateEndpoint.BASE, organizationId, 'updatePartialTemplateDetail'],
    mutationFn: (props: Omit<TTemplatePartialUpdatePayload, 'organizationId'>) => {
      return updatePartialTemplateDetail({ ...props, organizationId });
    },
  });
};

type TTemplateUploadPayload = Omit<TTemplateUpdatePayload, 'id'>;

const uploadTemplate = async (props: TTemplateUploadPayload) => {
  const baseUrl = getTemplatesApiUrl(props.organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}/`;
  const formData = new FormData();
  formData.append('name', props.name);
  formData.append('image', props.image);
  return apiClient.post<string>(url, formData);
};

export const useUploadTemplate = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [TemplateEndpoint.BASE, organizationId, 'uploadTemplate'],
    mutationFn: (props: Omit<TTemplateUploadPayload, 'organizationId'>) => {
      return uploadTemplate({ ...props, organizationId });
    },
  });
};

type TDeleteTemplatePayload = {
  id: string;
  organizationId: number;
};

const deleteTemplateById = async (props: TDeleteTemplatePayload) => {
  const baseUrl = getTemplatesApiUrl(props.organizationId, TemplateEndpoint.BASE);
  const url = `${baseUrl}/${props.id}`;
  return apiClient.delete<string>(url);
};

export const useDeleteTemplate = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [TemplateEndpoint.BASE, organizationId],
    mutationFn: (props: Omit<TDeleteTemplatePayload, 'organizationId'>) => {
      return deleteTemplateById({ ...props, organizationId });
    },
  });
};
