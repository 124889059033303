import { Navigate, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { toast } from 'sonner';
import { zodResolver } from '@hookform/resolvers/zod';

import Logo from 'assets/icons/coming_soon.svg?react';
import craitImage from '@/assets/images/auth-page.png';

import { Form, FormControl, FormItem, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { ValidationBullet } from 'pages/sign-up/ValidationBullet';
import { AnimatedCraitImage } from 'pages/login/AnimatedCraitImage';
import { IconWrapper } from 'components/common/IconWrapper';

import { useUserStore } from '@/providers/user/hooks';
import { RoutePaths } from '@/utils/route/constants';
import { usePasswordReset } from './queries';
import { PasswordErrors, passwordSchema } from 'pages/sign-up/SignUpPage';
import { AuthOverlayContent } from 'pages/login/AuthOverlayContent';
import { CraitButton } from 'components/common/CraitButton';

const passwordResetSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'The passwords must match.',
    path: ['confirmPassword'],
  });

export const PasswordResetPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string | null;
  const user = useUserStore((state) => state.user);

  const passwordReset = usePasswordReset();

  const form = useForm<z.infer<typeof passwordResetSchema>>({
    resolver: zodResolver(passwordResetSchema),
  });

  if (!token || user) return <Navigate replace to={RoutePaths.BASE} />;

  const onSubmit = (data: z.infer<typeof passwordResetSchema>) => {
    passwordReset.mutate(
      {
        token: token,
        password: data.password,
      },
      {
        onSuccess: () => {
          toast.success('Password reset successfully.');
        },
        onError: (error: any) => {
          const errorMessage = error.response.data.detail;
          toast.error(errorMessage);
        },
      },
    );
  };

  const isAnyFieldDirtied = Object.keys(form.formState.dirtyFields).includes('password');

  const ICON_WRAPPER_CL = 'inline-flex cursor-pointer items-center rounded-md bg-gray-100 p-1';

  return (
    <div className='flex size-full items-center justify-center bg-white'>
      <AnimatedCraitImage
        src={craitImage}
        className='absolute bottom-0 left-0 right-0 top-0  hidden h-full w-full object-cover md:block'
      />
      <div className='flex h-full w-full'>
        <div className='relative  flex  h-full flex-1 items-center justify-center px-8 md:px-4'>
          <div className='flex w-full max-w-[400px] flex-col items-center gap-6  bg-white md:rounded-[20px] md:px-5 md:pb-10 md:pt-2'>
            <div className='flex w-full justify-start'>
              <Button
                variant='outline'
                className='flex gap-2 border-none'
                onClick={() => {
                  navigate(`/${RoutePaths.LOGIN}`);
                }}
              >
                <IconWrapper
                  wrapperProps={{
                    className: ICON_WRAPPER_CL,
                  }}
                  iconClass='i-mdi-arrow-left size-4'
                />
                Back
              </Button>
            </div>
            <Link to={`/${RoutePaths.LOGIN}`}>
              <Logo className='h-8 w-4' cursor='pointer' />
            </Link>

            <h4 className='text-[32px] font-medium'>Reset Password</h4>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='mb-7 flex w-full flex-col gap-2'
              >
                <Controller
                  name='password'
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='New password'
                          type='password'
                          className='rounded-lg bg-background'
                          onChange={(e) => {
                            field.onChange(e);
                            // Perform instant validation
                            form.trigger('password');
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Controller
                  name='confirmPassword'
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Repeat new password'
                          type='password'
                          className='rounded-lg bg-background'
                        />
                      </FormControl>
                      <FormMessage className='!mt-1 pl-2 text-xs'>
                        {form.formState.errors.confirmPassword?.message}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <div className='mt-2 flex flex-col gap-2'>
                  <ValidationBullet
                    status={
                      !isAnyFieldDirtied
                        ? 'idle'
                        : form.formState.errors.password?.message?.includes(
                              PasswordErrors.MIN_LENGTH,
                            )
                          ? 'error'
                          : 'success'
                    }
                    text='At least 8 characters'
                  />
                  <ValidationBullet
                    status={
                      !isAnyFieldDirtied
                        ? 'idle'
                        : form.formState.errors.password?.message?.includes(PasswordErrors.NUMBER)
                          ? 'error'
                          : 'success'
                    }
                    text='At least 1 number'
                  />
                  <ValidationBullet
                    status={
                      !isAnyFieldDirtied
                        ? 'idle'
                        : form.formState.errors.password?.message?.includes(PasswordErrors.UPPER)
                          ? 'error'
                          : 'success'
                    }
                    text='At least 1 uppercase letter'
                  />
                </div>
              </form>
            </Form>
            <div className='flex w-full flex-col gap-2.5'>
              <CraitButton
                onClick={form.handleSubmit(onSubmit)}
                className='rounded-lg'
                disabled={passwordReset.isPending || !form.formState.isValid}
              >
                Continue
              </CraitButton>
            </div>
          </div>
        </div>
        <div className='relative block h-full w-full flex-1 overflow-hidden md:hidden'>
          <AnimatedCraitImage src={craitImage} className='h-full w-full object-cover ' />
          <AuthOverlayContent />
        </div>
      </div>
    </div>
  );
};
