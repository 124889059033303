import { Separator } from 'components/ui/separator';
import { ArrowLeftIcon } from '../CreateToolIcons';
import { Switch } from 'components/ui/switch';
import { EnhancePromptSwitch } from './EnhancePromptSwitch';
import { FaceCompositionSwitch } from './FaceCompositionSwitch';
import { Textarea } from 'components/ui/textarea';
import { NegativePrompt } from './NegativePrompt';

export const AdvancedSettingsMenu = ({ onBackClick }: { onBackClick: () => void }) => (
  <div className='flex h-full w-[320px] flex-col gap-3 rounded-lg bg-white p-3 py-6 shadow-md md:h-[400px] md:w-[94vw] md:shadow-none'>
    <button onClick={onBackClick} className='flex gap-2 text-[15px] font-semibold'>
      <ArrowLeftIcon />
      Advanced Settings
    </button>
    <Separator />
    <div>
      {/* <HowItWorksBox
        text='Select an inspiration or upload yours to set the vibe of your scene.'
        linkText="Don't show this again"
        onLinkClick={() => {}}
      /> */}
    </div>
    <div className=' flex flex-1 flex-col gap-4'>
      <EnhancePromptSwitch />
      <FaceCompositionSwitch />
      <NegativePrompt />
    </div>
  </div>
);
