import { IconWrapper } from 'components/common/IconWrapper';
import { Slider } from 'components/ui/slider';
import { useProductCreateStore } from '@/providers/brand-library/product-create/hooks';
import {
  removeOtherPaths,
  removeTargetedPaths,
} from 'components/ai-scene/review-edit/canvas/helper';
import { useState, useEffect } from 'react';
import { useEscToCancelDrawingMode } from '@/hooks/canvas/useEscToCancelDrawingMode';
import { serializeCanvas } from '@/utils/helper';
import { ImageType } from '@/utils/store/constants';
import { toast } from 'sonner';
import { fabric } from 'fabric';
import invariant from 'tiny-invariant';
import { removeCreatedPath, restoreCreatedPath } from './helper';
import { cn } from '@/lib/utils';
import {
  DrawingCursorSize,
  applyCustomCursor,
  generateDrawingCursorUrl,
} from 'components/ai-scene/review-edit/actions/helper';

const ICON_WRAPPER_CL = 'inline-flex cursor-pointer items-center rounded-md p-1 hover:scale-125';
const BUTTON_CL = 'flex items-center bg-white text-xs px-1 justify-center rounded-lg';

export const BrushView = () => {
  const drawingMode = useProductCreateStore((state) => state.drawingMode);
  const setDrawingMode = useProductCreateStore((state) => state.setDrawingMode);
  const canvasInstance = useProductCreateStore((state) => state.canvasInstance);
  const updateCanvasState = useProductCreateStore((state) => state.updateCanvasState);
  const [activeSlider, setActiveSlider] = useState('brush');
  const [cleanBrushSize, setCleanBrushSize] = useState<number>(DrawingCursorSize.DEFAULT);
  const [restoreBrushSize, setRestoreBrushSize] = useState<number>(DrawingCursorSize.DEFAULT);
  useEscToCancelDrawingMode(canvasInstance);

  useEffect(() => {
    const handlePathCreated = async (event: fabric.IEvent) => {
      if (!('path' in event) || !event.path || !canvasInstance) return;

      const path = event.path as fabric.Path;
      const currentDrawingType = canvasInstance.currentDrawingType;
      (path as any).drawingType = currentDrawingType;
      try {
        if (currentDrawingType === 'manualRestore') {
          await restoreCreatedPath(path, canvasInstance);
          updateCanvasState(serializeCanvas(canvasInstance));
          return;
        }

        await removeCreatedPath(path, canvasInstance);
        updateCanvasState(serializeCanvas(canvasInstance));
      } catch (error) {
        console.error('Error while creating path:', error);
        toast.error('Error while creating path.');
        removeTargetedPaths({ canvasInstance, drawingType: canvasInstance.currentDrawingType });
      }
    };

    const handlePathRemoved = (event: fabric.IEvent) => {
      if (!canvasInstance) return;

      if (event.target && event.target.type === 'path') {
        updateCanvasState(serializeCanvas(canvasInstance));
      }
    };

    canvasInstance?.on('path:created', handlePathCreated);
    canvasInstance?.on('object:removed', handlePathRemoved);
    return () => {
      canvasInstance?.off('path:created', handlePathCreated);
      canvasInstance?.off('object:removed', handlePathRemoved);
    };
  }, [canvasInstance, updateCanvasState]);

  const enableFreeDraw = (type: 'manualRestore' | 'manualErase') => {
    try {
      if (!canvasInstance || drawingMode === type) return;

      const objects = canvasInstance.getObjects();
      const opaqueImage = objects.find((obj) => obj.imageType === ImageType.OPAQUE) as fabric.Image;
      if (!opaqueImage) {
        toast.warning('Please upload an image before drawing.');
        return;
      }

      const isOpaqueToggled = opaqueImage.visible && opaqueImage.opacity === 1;
      if (isOpaqueToggled) {
        toast.warning('Please toggle the visibility of the transparent image before drawing.');
        return;
      }

      const isRestoring = type === 'manualRestore';
      if (isRestoring) {
        const transparentObj = objects.find(
          (obj) => obj.imageType === ImageType.TRANSPARENT,
        ) as fabric.Image;
        invariant(transparentObj, 'Transparent image not found in canvas.');

        opaqueImage.set({
          visible: true,
          opacity: 0.25,
        });
      }

      const isErasing = type === 'manualErase';
      if (isErasing) {
        opaqueImage.set({
          visible: false,
          opacity: 1,
        });
      }

      setDrawingMode(type);
      canvasInstance.isDrawingMode = true;
      canvasInstance.currentDrawingType = type;
      removeOtherPaths({ canvasInstance, drawingType: type, shouldRenderAfterwards: true });
      const brushColor = isRestoring ? '#81F0FF4D' : 'rgba(239, 138, 131, 0.7)';
      const brushSize = isRestoring ? restoreBrushSize : cleanBrushSize;
      const strokeColor = isRestoring ? 'green' : 'red';
      canvasInstance.freeDrawingBrush.color = brushColor;
      canvasInstance.freeDrawingBrush.width = brushSize;
      const cursorUrl = generateDrawingCursorUrl(brushSize, strokeColor);
      applyCustomCursor(canvasInstance, cursorUrl);
      setActiveSlider(type);
    } catch (error) {
      toast.error('Error while enabling free draw.');
    }
  };

  return (
    <>
      <div className='flex items-center gap-2 rounded-lg bg-white p-3 text-sm font-medium'>
        <div className='flex w-full items-center justify-start rounded-lg border border-[#EAEAEA] p-1 text-black'>
          <IconWrapper
            wrapperProps={{
              className: `${ICON_WRAPPER_CL} ${
                activeSlider === 'manualRestore' ? 'bg-gray-100' : ''
              }`,
              onClick: () => enableFreeDraw('manualRestore'),
            }}
            iconClass='i-mdi-brush h-5 w-5'
          />
          <IconWrapper
            wrapperProps={{
              className: `${ICON_WRAPPER_CL} ${
                activeSlider === 'manualErase' ? 'bg-gray-100' : ''
              }`,
              onClick: () => enableFreeDraw('manualErase'),
            }}
            iconClass='i-mdi-eraser h-5 w-5'
          />
        </div>
        <div>
          {activeSlider === 'manualRestore' && (
            <Slider
              step={5}
              min={DrawingCursorSize.MIN}
              max={DrawingCursorSize.MAX}
              defaultValue={[restoreBrushSize]}
              className='w-20'
              onValueChange={(values) => {
                if (!canvasInstance) return;
                setRestoreBrushSize(values[0]);
                canvasInstance.freeDrawingBrush.width = values[0];
                if (drawingMode === 'manualRestore') {
                  const cursorUrl = generateDrawingCursorUrl(values[0], 'green');
                  applyCustomCursor(canvasInstance, cursorUrl);
                }
              }}
            />
          )}
          {activeSlider === 'manualErase' && (
            <Slider
              step={5}
              min={DrawingCursorSize.MIN}
              max={DrawingCursorSize.MAX}
              defaultValue={[cleanBrushSize]}
              className='w-20'
              onValueChange={(values) => {
                if (!canvasInstance) return;
                setCleanBrushSize(values[0]);
                canvasInstance.freeDrawingBrush.width = values[0];
                if (drawingMode === 'manualErase') {
                  const cursorUrl = generateDrawingCursorUrl(values[0], 'red');
                  applyCustomCursor(canvasInstance, cursorUrl);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
