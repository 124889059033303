import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { AccountEndpoint, AuthEndpoint, PaymentPlanEndpoint } from '@/utils/fetch/constants';
import { getPaymentPlanApiUrl } from '@/utils/fetch/helper';
import { getAccountApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery } from '@tanstack/react-query';

type TUpdatePasswordPayload = {
  currentPassword: string;
  newPassword: string;
};
const updatePassword = async (payload: TUpdatePasswordPayload): Promise<string> => {
  return apiClient.put(AuthEndpoint.UPDATE_PASSWORD, payload);
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationKey: [AuthEndpoint.UPDATE_PASSWORD],
    mutationFn: updatePassword,
  });
};

type TUpdateAccountInfoPayload = {
  userName: string;
  email: string;
  avatar: File | undefined;
  organizationId: number;
};

type TUpdateAccountInfoResponse = {
  id: number;
  email: string;
  user_name: string;
  avatar: string;
  organization_ids: number[];
  user_status: string;
};

const updateAccountInfo = async (
  payload: TUpdateAccountInfoPayload,
): Promise<TUpdateAccountInfoResponse> => {
  const url = getAccountApiUrl(payload.organizationId, AccountEndpoint.UPDATE_ACCOUNT_INFO);
  const formData = new FormData();
  formData.append('userName', payload.userName);
  formData.append('email', payload.email);
  if (payload.avatar) {
    formData.append('avatar', payload.avatar, payload.avatar.name);
  }
  const response = await apiClient.put(url, formData);
  return response.data;
};

export const useUpdateAccountInfoMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.UPDATE_ACCOUNT_INFO, organizationId }],
    mutationFn: (payload: Omit<TUpdateAccountInfoPayload, 'organizationId'>) =>
      updateAccountInfo({ ...payload, organizationId }),
  });
};

type TUpdateUserNamePayload = {
  userName: string;
  organizationId: number;
};

const updateUserName = async (
  payload: TUpdateUserNamePayload,
): Promise<TUpdateAccountInfoResponse> => {
  const url = getAccountApiUrl(payload.organizationId, AccountEndpoint.UPDATE_ACCOUNT_INFO);
  const formData = new FormData();
  formData.append('userName', payload.userName);

  const response = await apiClient.put(url, formData);
  return response.data;
};

export const useUpdateUserNameMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.UPDATE_ACCOUNT_INFO, organizationId }],
    mutationFn: (payload: Omit<TUpdateUserNamePayload, 'organizationId'>) =>
      updateUserName({ ...payload, organizationId }),
  });
};

type TDeleteAccountPayload = {
  password: string;
  organizationId: number;
};

type TDeleteAccountResponse = {
  status: string;
};

const deleteAccount = async (payload: TDeleteAccountPayload): Promise<TDeleteAccountResponse> => {
  const url = getAccountApiUrl(payload.organizationId, AccountEndpoint.DELETE_ACCOUNT);

  const response = await apiClient.delete(url, { data: { password: payload.password } });
  return response.data;
};

export const useDeleteAccountMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.DELETE_ACCOUNT, organizationId }],
    mutationFn: (payload: Omit<TDeleteAccountPayload, 'organizationId'>) =>
      deleteAccount({ ...payload, organizationId }),
  });
};

type TCancelPlanPayload = {
  organizationId: number;
};

type TCancelPlanResponse = {
  status: string;
};

const cancelPlan = async (payload: TCancelPlanPayload): Promise<TCancelPlanResponse> => {
  const url = getPaymentPlanApiUrl(payload.organizationId, PaymentPlanEndpoint.SUBSCRIPTION);

  const response = await apiClient.delete(url);
  return response.data;
};

export const useCancelPlanMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: PaymentPlanEndpoint.SUBSCRIPTION, organizationId }],
    mutationFn: () => cancelPlan({ organizationId }),
  });
};

type TCompanyInfoResponse = {
  companyName: string;
  phoneNumber: string;
  phoneCountryCode: number;
  industry: string;
  employeeRole: string;
};

const getCompanyInfo = async (organizationId: number): Promise<TCompanyInfoResponse> => {
  const url = getAccountApiUrl(organizationId, AccountEndpoint.GET_ACCOUNT_COMPANY_INFO);
  const response = await apiClient.get(url);
  return response.data;
};

export const useGetCompanyInfoQuery = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useQuery({
    queryKey: [AccountEndpoint.GET_ACCOUNT_COMPANY_INFO, organizationId],
    queryFn: () => getCompanyInfo(organizationId),
    retry: false,
  });
};

type TUpdateCompanyInfoPayload = {
  companyName: string;
  phoneNumber: string;
  phoneCountryCode: number;
  industry: string;
  employeeRole: string;
  organizationId: number;
};

const updateCompanyInfo = async (
  payload: TUpdateCompanyInfoPayload,
): Promise<TCompanyInfoResponse> => {
  const url = getAccountApiUrl(payload.organizationId, AccountEndpoint.PUT_ACCOUNT_COMPANY_INFO);
  const response = await apiClient.put(url, {
    companyName: payload.companyName,
    phoneNumber: payload.phoneNumber,
    phoneCountryCode: payload.phoneCountryCode,
    industry: payload.industry,
    employeeRole: payload.employeeRole,
  });
  return response.data;
};

export const useUpdateCompanyInfoMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  return useMutation({
    mutationKey: [{ url: AccountEndpoint.PUT_ACCOUNT_COMPANY_INFO, organizationId }],
    mutationFn: (payload: Omit<TUpdateCompanyInfoPayload, 'organizationId'>) =>
      updateCompanyInfo({ ...payload, organizationId }),
  });
};

export const calculateRemainingPercentage = (
  total: number | null,
  remaining: number | null,
): number => {
  if (total === null || remaining === null) {
    return 100;
  }
  const percentage = (remaining / total) * 100;
  return parseFloat(percentage.toFixed(2));
};

export function getValueOrFallback<T, U>(value: T | null, fallback: U): T | U {
  return value === null ? fallback : value;
}
