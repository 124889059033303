import { useUserStore } from '@/providers/user/hooks';
import { apiClient } from '@/utils/fetch/axiosConfig';
import { ActivityEndpoint, ProjectEndpoint } from '@/utils/fetch/constants';
import { getActivityApiUrl, getProjectsApiUrl } from '@/utils/fetch/helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TPaginatedResponse } from 'components/ai-scene/sidebar/settings-tab/queries';
import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export const ProjectTypes = {
  AI_SCENE: 'ai_scene',
  AI_IMAGE: 'ai_image',
  AI_BANNER: 'ai_banner',
  AI_EDIT: 'ai_edit',
} as const;

export type TProject = {
  id: string;
  name: string;
  thumbnail: string;
  image: string;
  last_update_info: LastUpdateInfo;
  type: (typeof ProjectTypes)[keyof typeof ProjectTypes];
  contributors: number[];
  lastGenerationData: TLastGenerationData | undefined;
};

interface LastUpdateInfo {
  timestamp: number;
  user_name: string;
}

export type TLastGenerationSummary = {
  prompt: string | null;
  products: TProduct[] | null;
  styles: TStyle[] | null;
  template: TTemplate | null;
  brand_style: TBrandStyle | null;
  lighting_style: TLightingStyle | null;
};

export type TLastGenerationParams = {
  sceneDimensions: {
    x: number;
    y: number;
  };
  productIds: string[];
  rawPrompt: string;
  prompt: string;
  negativePrompt: string;
  inspirationalImageUrls: string[];
  styleIds: string[];
  templateId: string;
  brandStyleId: string;
  lightingStyleId: string;
  elements: TElement[];
  faceTextComposition: boolean;
  enhancePrompt: boolean;
  productPlacementImageUrl: string | null;
  staticEditsImageUrl: string | null;
};

export type TLastGenerationData = {
  summary: TLastGenerationSummary;
  parameters: TLastGenerationParams;
  serializedCanvasData: string | null;
};

type TElement = {
  id: string;
  position: {
    x: number;
    y: number;
  };
  scale: {
    x: number;
    y: number;
  };
  flip: {
    x: boolean;
    y: boolean;
  };
  rotation: number;
};

interface TProduct {
  id: string;
  name: string;
}

interface TStyle {
  id: string;
  name: string;
  thumbnail: string;
}

interface TTemplate {
  id: string;
  name: string;
  thumbnail: string;
}

interface TBrandStyle {
  id: string;
  name: string;
  thumbnail: string;
}

interface TLightingStyle {
  id: string;
  name: string;
  thumbnail: string;
}

const getProjects = async ({
  organizationId,
  onlyMyProjects,
}: TUseGetProjectsPayload): Promise<TPaginatedResponse<TProject>> => {
  const baseUrl = getProjectsApiUrl(organizationId, ProjectEndpoint.BASE);
  const queryParams = new URLSearchParams({ onlyMyProjects: String(onlyMyProjects) });
  const url = onlyMyProjects ? `${baseUrl}?${queryParams.toString()}` : baseUrl;
  const response = await apiClient.get(url);
  return response.data;
};

type TUseGetProjectsPayload = {
  onlyMyProjects?: boolean;
  organizationId: number;
};

export const useGetProjects = ({
  onlyMyProjects,
}: Omit<TUseGetProjectsPayload, 'organizationId'>) => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: [{ url: ProjectEndpoint.BASE, organizationId, onlyMyProjects }],
    queryFn: ({ queryKey }) => {
      return getProjects(queryKey[0]);
    },
    placeholderData: (prev) => {
      if (prev) return prev;

      return {
        items: [],
        currentPage: 1,
        totalPages: 1,
        itemCount: 0,
        totalItems: 0,
      };
    },
  });
  return result;
};

export const ProjectsContext = createContext<TPaginatedResponse<TProject> | undefined>(undefined);
export const useGetProjectsCtx = () => {
  const paginatedResponse = useContext(ProjectsContext);
  invariant(paginatedResponse, 'useGetProjectsCtx must be used within a ProjectsProvider');

  return paginatedResponse?.items;
};

export type TActivity = {
  id: string;
  isRead: boolean;
  timestamp: number;
  memberId: number;
  avatar: string;
  content: string;
  url: string;
};

const getActivities = async (organizationId: number): Promise<TActivity[]> => {
  const response = await apiClient.get(
    getActivityApiUrl(organizationId, ActivityEndpoint.GET_ACTIVITIES),
  );
  return response.data;
};

export const getUseActivitiesQueryKey = (organizationId: number) => {
  return [{ url: ActivityEndpoint.GET_ACTIVITIES, organizationId }];
};

export const useActivities = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const result = useQuery({
    queryKey: getUseActivitiesQueryKey(organizationId),
    queryFn: ({ queryKey }) => {
      return getActivities(queryKey[0].organizationId);
    },
    placeholderData: [],
  });
  return result;
};

export const ActivityContext = createContext<TActivity[] | undefined>(undefined);
export const useActivitiesCtx = () => {
  const currentUser = useContext(ActivityContext);
  invariant(currentUser, 'useActivitiesCtx must be used within a ActivityProvider');

  return currentUser;
};

const markActivityAsRead = async (organizationId: number, activityId: string): Promise<string> => {
  const baseUrl = getActivityApiUrl(organizationId, ActivityEndpoint.GET_ACTIVITIES);
  const url = `${baseUrl}${activityId}/read`;
  await apiClient.get(url);
  return activityId;
};

export const useReadAndInvalidateMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ActivityEndpoint.GET_ACTIVITIES],
    mutationFn: (activityId: string) => {
      return markActivityAsRead(organizationId, activityId);
    },
    onSuccess: (activityId) => {
      queryClient.setQueryData(
        [{ url: ActivityEndpoint.GET_ACTIVITIES, organizationId }],
        (oldData: TActivity[] | undefined) => {
          if (oldData) {
            return oldData.map((activity) => {
              return activity.id === activityId ? { ...activity, isRead: true } : activity;
            });
          }
          return oldData;
        },
      );
    },
  });
};

export const useMarkAsReadMutation = () => {
  const organizationId = useUserStore((state) => state.user?.organization_ids[0]) || 0;

  return useMutation({
    mutationKey: [ActivityEndpoint.GET_ACTIVITIES],
    mutationFn: (activityId: string) => {
      return markActivityAsRead(organizationId, activityId);
    },
  });
};
