import { useSyncCanvasState } from '@/hooks/canvas/useSyncCanvasState';
import { useFabricCanvas } from '@/hooks/canvas/useFabricCanvas';
import { OverlayWidget } from './OverlayWidget';
import { useAiSceneCreateStore } from '@/providers/ai-scene/create/hooks';
import { useRemoveSelectedObject } from '@/hooks/canvas/useRemoveSelectedObject';
import { useFabricEventHandlers } from '@/hooks/canvas/useFabricEventHandlers';
import { useDrawUploadedImage } from './useDrawUploadedImages';
import { useDrawPendingImageFromWorkspace } from './useDrawPendingImageFromWorkspace';
import { cn } from '@/lib/utils';
import { useIsBulkSceneCreated } from 'components/ai-scene/sidebar/useIsBulkSceneCreated';

export const AiSceneCanvas = () => {
  const setCanvasInstance = useAiSceneCreateStore((state) => state.setAiSceneCanvasInstance);
  const { fabricCanvasRef, canvasElement } = useFabricCanvas(setCanvasInstance);
  const canvasState = useAiSceneCreateStore((state) => state.canvasState);
  const updateCanvasState = useAiSceneCreateStore((state) => state.updateSceneCanvasState);
  const resetSceneCanvasState = useAiSceneCreateStore((state) => state.resetSceneCanvasState);
  const isBulkSceneCreated = useIsBulkSceneCreated();

  useSyncCanvasState(fabricCanvasRef, canvasState);
  useFabricEventHandlers({ updateCanvasState, canvasInstance: fabricCanvasRef });
  useRemoveSelectedObject({
    fabricCanvasRef,
    resetSceneCanvasState,
    updateCanvasState,
  });
  useDrawUploadedImage(fabricCanvasRef);
  useDrawPendingImageFromWorkspace(fabricCanvasRef);

  return (
    <div
      className={cn('relative opacity-100 transition-opacity', {
        'pointer-events-none': isBulkSceneCreated,
        'opacity-0': isBulkSceneCreated,
        absolute: isBulkSceneCreated,
      })}
    >
      {canvasElement}
      <OverlayWidget fabricRef={fabricCanvasRef.current} updateCanvasState={updateCanvasState} />
    </div>
  );
};
