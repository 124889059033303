import { StateCreator, create } from 'zustand';
import { createSelectors } from './createSelectors';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { nanoid } from 'nanoid';
import localForage from 'localforage';
import { TImageType } from './constants';
import { TSavedSettingsResponse } from 'components/workspace/saved-settings/helper';
import { TBulkProductItem } from 'pages/workspace/brand-library/bulk-products/provider/store';

// https://github.com/pmndrs/zustand/discussions/1796#discussioncomment-5826280
export type ImmerStateCreator<T> = StateCreator<T, [['zustand/immer', never], never], [], T>;

type StoreState = {
  savedCanvasDimensions: { id: string; width: number; height: number }[];
  pendingImageToDraw: { id: string; title: string; url: string; type: TImageType } | undefined;
  pendingSettingsToUse: Pick<TSavedSettingsResponse, 'parameters' | 'summary'> | undefined;
  uploadedBulkProducts: Array<TBulkProductItem> | undefined;
};

type StoreActions = {
  resetAll: () => void;
  saveCustomCanvasDimensions: (
    dimensions: Omit<StoreState['savedCanvasDimensions'][0], 'id'>,
  ) => void;
  removeCustomCanvasDimensions: (id: string) => void;
  setPendingImageToDraw: (image: StoreState['pendingImageToDraw']) => void;
  setPendingSettingsToUse: (settings: StoreState['pendingSettingsToUse']) => void;
  setUploadedBulkProducts: (products: StoreState['uploadedBulkProducts']) => void;
};

export const useCraitStoreBase = create<StoreState & StoreActions>()(
  devtools(
    persist(
      immer((set, get, store) => ({
        savedCanvasDimensions: [{ id: nanoid(), width: 600, height: 600 }],
        saveCustomCanvasDimensions: (dimensions) => {
          set((state) => {
            const newDimensions = { ...dimensions, id: nanoid() };
            state.savedCanvasDimensions.push(newDimensions);
          }),
            false,
            'saveCustomCanvasDimensions';
        },
        pendingImageToDraw: undefined,
        setPendingImageToDraw: (image) => {
          set((state) => {
            state.pendingImageToDraw = image;
          }),
            false,
            'setPendingImageToDraw';
        },
        pendingSettingsToUse: undefined,
        setPendingSettingsToUse: (image) => {
          set((state) => {
            state.pendingSettingsToUse = image;
          }),
            false,
            'setPendingSettingsToUse';
        },
        removeCustomCanvasDimensions: (id) => {
          set((state) => {
            state.savedCanvasDimensions = state.savedCanvasDimensions.filter(
              (dimension) => dimension.id !== id,
            );
          }),
            false,
            'removeCustomCanvasDimensions';
        },
        resetAll: () => {
          set((state) => {
            state.savedCanvasDimensions = [{ id: nanoid(), width: 600, height: 600 }];
          }),
            false,
            'resetAll';
        },
        uploadedBulkProducts: undefined,
        setUploadedBulkProducts: (products) => {
          set((state) => {
            state.uploadedBulkProducts = products;
          }),
            false,
            'setUploadedBulkProducts';
        },
      })),
      {
        name: 'craitStore',
        getStorage: () => localForage as never,
      },
    ),
  ),
);

export const useCraitStore = createSelectors(useCraitStoreBase);
