import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { FC, ReactNode } from 'react';
import { SceneContext, useGetScene } from './helper';
import { Navigate, useLocation } from 'react-router';
import { toast } from 'sonner';
import { getPreviousPath, iife } from '@/utils/helper';
import { Skeleton } from 'components/ui/skeleton';

interface SceneProviderProps {
  children: ReactNode;
}

export const SceneProvider: FC<SceneProviderProps> = ({ children }) => {
  const scene = useGetScene();
  const { pathname } = useLocation();
  const previousPage = getPreviousPath(pathname);

  if (scene.isError) {
    iife(() => {
      toast.error('Could not find the scene. Please try again.');
    });
    return <Navigate to={previousPage} />;
  }

  if (scene.data) {
    return <SceneContext.Provider value={scene}>{children}</SceneContext.Provider>;
  }

  const loadingView = (
    <div
      className={`relative mx-auto mt-8 flex h-[calc(100%-50px)] max-h-[600px] w-[600px] flex-col items-center justify-center rounded-lg border-2`}
    >
      <div className='absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform'>
        <LoadingSpinner className='text-crait-green-600' />
      </div>
      <Skeleton className='h-full w-full rounded-md bg-gray-300' />
    </div>
  );

  return <div className='flex h-[calc(100%-100px)]'>{loadingView}</div>;
};
